import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from "Services/Translator";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import { FormModal } from '@f2w/form';
import { useAssetsTable } from "Pages/Asset/hooks/useAssetsTable";
import { ActiveView, ObjectViewModalServices, useObjectViewModalContext } from "Components/ObjectViewModal";
import { useAssetForm } from "Pages/Asset/hooks/useAssetForm";
import { useLinkAssetToCreditorForm } from "Pages/Asset/hooks/useLinkAssetToCreditorForm";
import { useCreateRoomForm } from "Pages/Asset/hooks/useCreateRoomForm";
import { usePortfolio } from "Components/Base/PortfolioContext";
const AssetPage = ({ assetServices, openUpsertModal, objectViewModalServices = ObjectViewModalServices }) => {
    const { openViewModalObj, activeView } = useObjectViewModalContext();
    const updateRoomsRef = React.useRef();
    React.useEffect(() => {
        if (openUpsertModal) {
            assetForm.openForm();
        }
    }, [openUpsertModal]);
    React.useEffect(() => {
        if (!activeView?.id) {
            instance.refresh();
        }
    }, [activeView?.id]);
    const portfolio = usePortfolio();
    const assetForm = useAssetForm({
        portfolio,
        updateAsset: assetServices.updateAsset,
        getAssetById: assetServices.getAssetById,
        createAsset: assetServices.createAsset,
        onClose: () => {
            instance.refresh();
        }
    });
    const roomForm = useCreateRoomForm({
        portfolio: portfolio,
        updateRoomsRef,
        onSave: () => instance.refresh(),
        addRentalObjectRoom: objectViewModalServices.addRentalObjectRoom
    });
    const linkAssetToCreditorForm = useLinkAssetToCreditorForm({
        linkAssetToCreditor: assetServices.linkAssetToCreditor,
        onSubmit: () => {
            instance.refresh();
        }
    });
    const instance = useAssetsTable({
        updateRoomsRef,
        filters: {},
        assetServices,
        onViewAsset: (asset) => {
            openViewModalObj({
                id: asset.id,
                view: ActiveView.ASSET,
                parentRentalObjectId: asset.rentalObject.id
            });
        },
        onViewRoom: (roomId, rentalObjectId) => {
            openViewModalObj({
                id: roomId,
                view: ActiveView.RENTAL_OBJECT_ROOM,
                parentRentalObjectId: rentalObjectId
            });
        },
        onLinkCreditorInvoice: ({ id }) => {
            linkAssetToCreditorForm.openForm(id);
        },
        onCreateAsset: () => assetForm.openForm(-1),
        onCreateRoom: () => roomForm.openForm(-1),
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, title: translate('assets.title'), showFilters: false }), _jsx(FormModal, { modal: assetForm }), _jsx(FormModal, { modal: roomForm }), _jsx(FormModal, { modal: linkAssetToCreditorForm })] }));
};
export default AssetPage;
