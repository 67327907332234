import Editor from '@f2w/ckeditor-build';
import './TextEditor.styles.scss';
const defaultToolbar = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontColor',
    '|',
    'removeFormat',
];
export const EditorVariants = {
    simple: {
        editor: Editor,
    },
    block: {
        editor: Editor,
        config: {
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                ],
            },
            toolbar: [
                ...defaultToolbar,
                '|',
                'heading',
                '|',
                'bulletedList',
                'numberedList',
            ],
        },
    },
    document: {
        editor: Editor,
        config: {
            balloonToolbar: [
                ...defaultToolbar,
                '|',
                'heading',
                '|',
                'bulletedList',
                'numberedList',
                'placeholder'
            ],
            toolbar: [
                ...defaultToolbar,
                '|',
                'heading',
                '|',
                'bulletedList',
                'numberedList',
                'placeholder'
            ],
            heading: {
                options: [
                    { model: 'paragraph', title: 'Text', class: 'ck-heading-document_text' },
                    { model: 'heading1', view: 'h1', title: 'Titel', class: 'ck-heading-document_title' },
                    { model: 'heading2', view: 'h2', title: 'Untertitel', class: 'ck-heading-document_subtitle' },
                ],
            },
        },
    },
    full: {
        editor: Editor,
        config: {
            balloonToolbar: [
                ...defaultToolbar,
                '|',
                'heading',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'link',
                '|',
                'insertTable'
            ],
            toolbar: [
                ...defaultToolbar,
                '|',
                'heading',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'link',
                '|',
                'insertTable'
            ],
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                ],
            },
            link: {
                addTargetToExternalLinks: true,
            },
            table: {
                contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells',
                ],
            },
        },
    },
};
