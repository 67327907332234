import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import { Modal as ModalNew } from 'Components/Modal';
import { Button } from 'Components/Atoms/Button';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import generateRoute from 'Services/Router';
import { ErrorMessage, Field, Formik } from 'formik';
import FormSelect from 'Components/Atoms/Form/FormSelect';
import FormErrorMessage from 'Components/Atoms/Form/FormErrorMessage';
import * as yup from 'yup';
import { UseStyled } from '@f2w/theme';
import Spinner from 'Components/Spinner';
import { css } from 'styled-components';
import moment from 'moment';
const SpinnerWrapper = UseStyled.styled('div', ({ theme: { palette, rem } }) => css `
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffffbf;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 100;
    border-radius: ${rem(16)};
`);
const FormContent = UseStyled.styled('div', () => ({
    display: 'flex',
    gap: '20px',
    padding: '20px 0'
}));
const ButtonWrapper = UseStyled.styled('div', () => ({
    display: 'flex',
    gap: '10px',
}));
const MonthBlock = UseStyled.styled('div', () => ({
    flexGrow: 1
}));
const monthOptions = [
    { value: 1, label: translate('month.january') },
    { value: 2, label: translate('month.february') },
    { value: 3, label: translate('month.march') },
    { value: 4, label: translate('month.april') },
    { value: 5, label: translate('month.may') },
    { value: 6, label: translate('month.june') },
    { value: 7, label: translate('month.july') },
    { value: 8, label: translate('month.august') },
    { value: 9, label: translate('month.september') },
    { value: 10, label: translate('month.october') },
    { value: 11, label: translate('month.november') },
    { value: 12, label: translate('month.december') },
];
const FormValidation = yup.object().shape({
    monthDate: yup
        .string()
        .required(translate('form.validation.fieldIsRequired')),
});
export const AutomaticInvoicingModal = ({ contractId, onCloseModal, autoInvoicingServices: { generateAutoInvoiceForPeriodAndContract, getAutoInvoiceDatesForContract } }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [initialFormData, setInitialFormData] = React.useState();
    const [yearOptions, setYearOptions] = React.useState([]);
    const [invoicesGenerated, setInvoicesGenerated] = React.useState([]);
    const formRef = React.useRef();
    const handleSubmit = React.useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [formRef]);
    const { success, warning, error } = useToastDispatcherApi();
    React.useEffect(() => {
        if (contractId) {
            getAutoInvoiceDatesForContract(contractId).then(availableMonths => {
                const _yearOptions = {};
                availableMonths.reverse().forEach(({ date: _date, hasInvoice }) => {
                    const date = moment(_date);
                    if (date.isValid()) {
                        const yearKey = `_${date.year()}`;
                        const monthOption = {
                            value: date.format('yyyy-M'),
                            label: date.format('MMMM YY'),
                        };
                        if (hasInvoice) {
                            monthOption.isDisabled = true;
                            monthOption.label = `${monthOption.label} (${translate('contract.autoInvoicing.hasInvoice')})`;
                        }
                        (_yearOptions[yearKey] = _yearOptions[yearKey] ?? {
                            label: date.year().toString(),
                            options: []
                        }).options.push(monthOption);
                    }
                });
                setYearOptions(Object.values(_yearOptions));
                setInitialFormData({
                    monthDate: undefined,
                });
                setModalOpen(true);
            })
                .catch((err) => {
                error({
                    timeout: 5,
                    message: translate('contract.autoInvoicing.create.error')
                });
            });
        }
    }, [contractId]);
    const closeModal = React.useCallback(() => {
        setModalOpen(false);
        setInvoicesGenerated([]);
        onCloseModal();
    }, []);
    const generateAutoInvoicing = React.useCallback(({ monthDate }) => {
        const date = moment(monthDate, 'YYYY-M');
        setIsLoading(true);
        generateAutoInvoiceForPeriodAndContract(date.month() + 1, date.year(), contractId)
            .then(response => {
            if (response.invoices.length === 0) {
                warning({
                    timeout: 5,
                    message: translate('contract.firstInvoice.noInvoiceGenerated.content', {
                        month: date.format('MMMM'),
                        year: date.year()
                    })
                });
            }
            else {
                success({
                    timeout: 5,
                    message: translate('contract.firstInvoice.invoicesGenerated')
                });
            }
            setInvoicesGenerated(response.invoices);
        })
            .catch(() => {
            error({
                timeout: 5,
                message: translate('contract.autoInvoicing.create.error')
            });
        })
            .finally(() => setIsLoading(false));
    }, [contractId]);
    if (!contractId) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(ModalNew, { show: modalOpen, size: { maxWidth: "500px" }, onHide: closeModal, children: [_jsx(ModalNew.Header, { closeButton: !isLoading, onHide: closeModal, children: _jsx(ModalNew.Title, { children: translate('contract.autoInvoice.title') }) }), _jsxs(ModalNew.Body, { children: [isLoading && _jsx(SpinnerWrapper, { children: _jsx(Spinner, { isVisible: true }) }), invoicesGenerated.length > 0
                        ? invoicesGenerated
                            .map(({ invoiceId: debitorInvoice, supportsQr, ...props }) => ({
                            invoiceId: debitorInvoice,
                            ...props,
                            documents: [
                                {
                                    title: translate('contract.firstInvoice.invoice'),
                                    href: generateRoute('download_debitor_invoice', { debitorInvoice }),
                                },
                                !supportsQr ? [] : [
                                    {
                                        title: translate('contract.firstInvoice.invoiceWithQr'),
                                        href: generateRoute('download_debitor_invoice', { debitorInvoice, includeQR: 1 }),
                                    }, {
                                        title: translate('contract.firstInvoice.invoiceWithQrSlip'),
                                        href: generateRoute('create_qr_payment_slip', { debitorInvoice }),
                                    }, {
                                        title: translate('contract.firstInvoice.invoiceWithQrSlipNoBg'),
                                        href: generateRoute('create_qr_payment_slip', { debitorInvoice, background: 0 }),
                                    }
                                ]
                            ].flat(2)
                        }))
                            .map(({ contractName, name, invoiceId, documents }) => (_jsxs("div", { children: [_jsxs("div", { children: [contractName, " - ", name] }), _jsx("ul", { children: documents.map((p, index) => (_jsx("li", { children: _jsx("a", { href: p.href, target: "_blank", title: p.title, children: p.title }) }, `${invoiceId}-${{ index }}`))) })] }, `${invoiceId}`)))
                        : (initialFormData ? (_jsx("div", { children: _jsx(Formik, { innerRef: formRef, initialValues: initialFormData, onSubmit: generateAutoInvoicing, validationSchema: FormValidation, children: _jsx(_Fragment, { children: _jsx(FormContent, { children: _jsxs(MonthBlock, { children: [_jsx("label", { htmlFor: 'month', children: translate('contract.autoInvoicing.month') }), _jsx(Field, { component: FormSelect, name: 'monthDate', isSearchable: true, options: yearOptions }), _jsx(ErrorMessage, { component: FormErrorMessage, name: 'monthDate' })] }) }) }) }) })) : null)] }), _jsx(ModalNew.Footer, { children: _jsxs(ButtonWrapper, { children: [_jsx(Button, { disabled: isLoading, "$color": '', onClick: closeModal, children: translate('btn.close') }), invoicesGenerated.length === 0 && _jsx(Button, { disabled: isLoading, onClick: handleSubmit, children: translate('btn.confirm') })] }) })] }));
};
