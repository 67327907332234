import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from "Atoms/Layout/components";
import { translateAdmin } from "Services/App";
import { Icon } from "Atoms/Icon";
import React from "react";
import { FlexCol, FlexRow } from "Atoms/Layout";
import { Text } from "Atoms/Text";
import { Badge } from "Atoms/Badge";
import { IntegrationType } from "Pages/Administration/Customers/enums";
import { useActiveViewContext } from "Pages/Administration/context/ActiveViewContext";
import { ActiveView } from "Pages/Administration/enums";
export const IntegrationsTab = ({ view }) => {
    const integrations = view.data.integrations;
    const { setActiveView } = useActiveViewContext();
    const onClickHandler = React.useCallback((customerId, customerName, integration) => {
        switch (integration.type) {
            case IntegrationType.HANDOVER_APP: {
                setActiveView(ActiveView.HANDOVER_COST_OVERVIEW, {
                    customerId: customerId,
                    customerName: customerName,
                    integrationActive: integration.isActive,
                });
            }
        }
    }, []);
    return (_jsx(Section, { children: _jsx(FlexCol, { children: integrations.map((integration, index) => (_jsxs(FlexRow, { alignCenter: true, pt: 16, pb: 16, bb: index + 1 === integrations.length ? 'none' : '1px solid #D7D8DB', style: { cursor: integration.type === IntegrationType.HANDOVER_APP ? 'pointer' : 'default' }, onClick: () => onClickHandler(view.data.id, view.data.name, integration), children: [_jsx(Text, { flexGrow: 1, body: 400, neutral: 900, children: integration.name }), _jsxs(FlexRow, { alignCenter: true, gap: 16, children: [_jsx(Badge, { "$color": integration.isActive ? 'success' : 'danger', children: translateAdmin(`customer.integration.${integration.isActive ? 'active' : 'notActive'}`) }), _jsx(Text, { body: 400, neutral: 100, children: integration.records }), _jsx(Icon, { icon: 'chevron-right', size: 24 })] })] }, index))) }) }));
};
