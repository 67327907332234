import { useForm } from "@f2w/form";
import { ResetPasswordType } from "Pages/Auth/types/ResetPasswordType";
import { translate } from "Services/App";
export const useResetPasswordForm = ({ resetPassword, verificationToken, onSave }) => useForm({
    name: 'reset-password-form',
    type: () => new ResetPasswordType(),
    onSave: (values) => {
        return resetPassword(values, verificationToken).then(onSave);
    },
    successMessage: translate('account.password.changed'),
});
