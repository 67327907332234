import client from "Utils/client";
import axios from "axios";
import moment from "moment";
import generateRoute from "Services/Router";
import { uploadFile } from "Services/UploadManager";
export const deleteDamage = async (id) => {
    return await client.delete(generateRoute('api.damages.delete', { damage: id }));
};
export const deleteDamageUpload = async (damageId, uploadId) => {
    return await client.delete(generateRoute('api.damages.upload.delete', { damage: damageId, upload: uploadId }));
};
export const getDamages = async (params) => {
    const { data } = await client.get(generateRoute('api.damages.list'), { params });
    return data;
};
export const getDamageUploads = async (damageId) => {
    const { data } = await client.get(generateRoute('api.damages.uploads', { damage: damageId }));
    return data;
};
export const getDamage = async (damageId) => {
    const { data } = await client.get(generateRoute('damage.get.api', { damageId }));
    return data;
};
function prepareFormData(damage) {
    const { uploads, dateOfDamage: dateOfDamageObject, ...damageData } = damage;
    const dateOfDamage = dateOfDamageObject ? moment(dateOfDamageObject).format('YYYY-MM-DD HH:mm:ss') : null;
    let keepUploadIds = [];
    const formData = new FormData();
    for (const upload of uploads) {
        if (!upload.file) {
            upload.id && keepUploadIds.push(upload.id);
            continue;
        }
        formData.append('files[]', upload.file);
    }
    formData.append("model", new Blob([
        JSON.stringify({ ...damageData, dateOfDamage, keepUploadIds })
    ], { type: 'application/json' }));
    return formData;
}
export const createDamage = async (damage) => {
    return await axios.post(generateRoute('damage.create.api'), prepareFormData(damage), {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};
export const updateDamage = async (damage) => {
    return await axios.post(generateRoute('damage.update.api', { id: damage.id }), prepareFormData(damage), {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};
export const updateDamageStatus = async (damageId, status) => {
    return await client.patch(generateRoute('damage.update_status.api', { id: damageId, status }));
};
export async function uploadDamageFile(damageId, file) {
    return uploadFile('/_uploader/damages/upload', file, { damageId })
        .then(({ data }) => data);
}
