function extend(...a) {
    return Object.assign({}, ...a);
}
let ee = extend({
    foo: 12,
    $100: '#D7D8DB',
    $200: '#B5B8BD',
    $300: '#999BA1',
    deep: {
        error: '#ff1c1c',
        dirty: '#FED69A',
        success: '#19e619',
    },
}, {
    $200: 'red',
    $400: '#7A7E87',
    $500: '#5B606B',
    $600: '#434954',
    deep: {
        error: 'yellow',
    },
});
export {};
