import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cleanObj } from '@f2w/utils';
import { BaseMenuItem, NestedMenu, RenderMode, } from './AbstractMenu';
import { Dropdown } from '../../Dropdown';
import generateRoute from 'Services/Router';
export class MenuDivider extends BaseMenuItem {
    _render(props) {
        return _jsx(Dropdown.Divider, { ...props });
    }
}
export class MenuHeader extends NestedMenu {
    getProps() {
        return {
            ...super.getProps(),
            children: this.label,
        };
    }
    _renderItem(props) {
        if (this.hasDropdown()) {
            return _jsx(Dropdown.Toggle, { label: this.label });
        }
        return _jsx(Dropdown.Header, { ...props, children: this.label });
    }
}
export class MenuLink extends NestedMenu {
    constructor(options) {
        super(options);
    }
    __init(parentPath) {
        super.__init(parentPath);
        const { url, route, routeParams } = this.options;
        this._url = route ? generateRoute(route, cleanObj(routeParams)) : url ?? '#';
    }
    isLink() {
        return true;
    }
    _renderItem(props) {
        const shouldRenderToggle = this.hasDropdown() && this._renderMode !== RenderMode.Flat;
        if (shouldRenderToggle) {
            if (this.url === '#')
                return _jsx(_Fragment, { children: _jsx(Dropdown.Toggle, { icon: this.options.icon, ...props }) });
            return _jsxs(_Fragment, { children: [_jsx(Dropdown.Item, { icon: this.options.icon, ...props }), _jsx(Dropdown.Toggle, { split: true })] });
        }
        return _jsx(Dropdown.Item, { icon: this.options.icon, ...props });
    }
    get args() {
        return this.options.args;
    }
    get url() {
        return this._url ?? '#';
    }
    getProps() {
        return {
            target: this.options.target || undefined,
            ...super.getProps(),
            href: this.url,
            label: this.label,
        };
    }
}
