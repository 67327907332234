import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import proj4 from 'proj4';
import { RFeature, RLayerTile, RLayerVector, RMap, RStyle } from 'rlayers';
import { Point } from 'ol/geom';
import { register } from 'ol/proj/proj4';
import styled from 'styled-components';
import pin from './Icons/pin.jpg';
import 'ol/ol.css';
proj4.defs("EPSG:21781", "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs");
register(proj4);
const mapCenter = [659659.67, 182155.61];
export function GeoAdminMap({ mapPin, ...rest }) {
    return (_jsx(GeoAdminMap.Root, { ...rest, children: _jsxs(RMap, { height: '100%', width: '100%', initial: {
                center: mapPin ? [mapPin.lon, mapPin.lat] : mapCenter,
                zoom: mapPin ? 18 : 7.5,
            }, projection: 'EPSG:21781', children: [_jsx(RLayerTile, { url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg' }), _jsxs(RLayerVector, { zIndex: 10, children: [_jsx(RStyle.RStyle, { children: _jsx(RStyle.RIcon, { scale: 0.2, src: pin, anchor: [0.5, 0.8] }) }), _jsx(RFeature, { geometry: mapPin ? new Point([mapPin.lon, mapPin.lat]) : undefined })] })] }) }));
}
GeoAdminMap.Root = styled.div `
    position: relative;
    pointer-events: revert;
    width: 100%;
    height: 100%;
    padding-top: 30%;
      border-radius: 16px;
      overflow: hidden;

    > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
`;
