import React from 'react';
import humanizeString from 'Utils/humanizeString';
import { isFunction, isObject, OrFunction, toArray, toObject } from 'Utils/types';
import mergeOptions, { mergeColumnOptions, mergeColumnPluginOptions, mergePluginOptions } from './mergeOptions';
import { columnTypes as ColumnTemplates } from './columnTypes';
import { forColumns } from './utils';
import InstanceBuilder from './InstanceBuilder';
import { forPlugins } from '../plugins';
export function factory() {
    const Builder = (initOptions) => {
        const builder = {
            get options() {
                return initOptions();
            },
            get columns() {
                return this.options['columns'];
            },
            factory: (newOpts) => Builder(() => newOpts(initOptions())),
            extend: (newOpts) => Builder(() => mergeOptions(initOptions, newOpts)),
            createOptions: (...opts) => {
                return optionsFactory(opts.length ? mergeOptions(initOptions, ...opts) : initOptions());
            },
            create: (...newOpts) => {
                const opts = builder.createOptions(...newOpts);
                return InstanceBuilder(opts);
            },
            useCreateTable: (opts, initialOptions) => {
                const model = React.useMemo(() => builder.create(OrFunction(initialOptions)), []);
                return model.create(opts);
            },
            useTable: (props, initialOptions) => {
                const model = React.useMemo(() => builder.create(OrFunction(initialOptions)), []);
                return model.useTable(props);
            },
        };
        return builder;
    };
    return Builder;
}
function optionsFactory(a, ...args) {
    const _table = mergeOptions(a, ...args);
    if (_table.columns) {
        forColumns(_table.columns, (name, _column) => {
            if (!_column.id) {
                _column.id = name;
            }
            if (_column.id[0] === '$' || _column.isCustom) {
                _column = Object.assign({
                    isCustom: true,
                    type: 'actions',
                }, _column);
                if (typeof ColumnTemplates[name] === 'function') {
                    _column = mergeColumnOptions(ColumnTemplates[name](_column, _table), _column);
                }
                if (!isObject(_column)) {
                    return undefined;
                }
            }
            else {
                if (!_column.accessor) {
                    _column.accessor = _column.id;
                }
                if (!_column.hasOwnProperty('Header')) {
                    _column['Header'] = humanizeString(_column.id);
                }
            }
            if (_column['hide'] || _column['isSubTable']) {
                const hiddenColumns = _table?.initialState?.hiddenColumns || [];
                hiddenColumns.push(_column.id);
                Object.assign(_table, {
                    initialState: {
                        ...toObject(_table.initialState),
                        hiddenColumns: [...hiddenColumns, ...toArray(_table?.initialState?.hiddenColumns)].filter((value, index, self) => self.indexOf(value) === index),
                    },
                });
            }
            forPlugins((pluginName, plugin) => {
                const enabled = _column?.[pluginName]?.enabled ?? !!_column[pluginName];
                if (enabled) {
                    _column[pluginName] = { ...toObject(_column[pluginName]), enabled };
                    _table[pluginName] = { ...toObject(_table[pluginName]), enabled };
                }
                else {
                    _column[pluginName] = null;
                    delete _column[pluginName];
                }
            });
            return _column;
        });
    }
    if (_table.useGroupBy) {
        _table.useExpanded = { ...toObject(_table?.useExpanded), enabled: true };
    }
    forPlugins((pluginName, plugin) => {
        const enabled = _table[pluginName]?.enabled ?? !!_table[pluginName];
        if (enabled) {
            _table[pluginName] = { ...toObject(_table[pluginName]), enabled };
            if (isFunction(plugin.initOptions)) {
                _table[pluginName] = mergePluginOptions(plugin, plugin.initOptions(_table[pluginName], _table), _table[pluginName]);
            }
        }
        else {
            _table[pluginName] = null;
            delete _table[pluginName];
        }
    });
    forColumns(_table.columns, (name, _column) => {
        forPlugins((pluginName, plugin) => {
            if (_column[pluginName]?.enabled) {
                if (isFunction(plugin.initColumnOptions)) {
                    _column[pluginName] = mergeColumnPluginOptions(plugin, plugin.initColumnOptions(_column[pluginName], _column), _column[pluginName]);
                }
            }
        });
        return _column;
    });
    return _table;
}
