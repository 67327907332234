import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import { getGlobalContext } from 'Services/GlobalContext';
import Portfolio from 'Models/Portfolio';
import { AbstractFormType, Form, FormProvider, SelectType, useForm } from '@f2w/form';
import AccountingNoPropertyPageStyled from 'Pages/Accounting/AccountingNoPropertyPageStyled';
import { Title } from '@f2w/data-table/layout/PageOverviewStyles';
import { redirectToPropertyScope } from "Services/ScopeManager";
const { Body, Content } = AccountingNoPropertyPageStyled;
class PropertySelectType extends AbstractFormType {
    _useField(props) {
        super._useField(props);
        React.useEffect(() => {
            if (this.field.value.propertyId) {
                redirectToPropertyScope(this.field.value.propertyId);
            }
        }, [this.field.value.propertyId]);
    }
    buildFields() {
        const { portfolioData } = getGlobalContext();
        const portfolio = new Portfolio(portfolioData);
        return {
            propertyId: new SelectType({
                label: translate('accounting.property.warning.text'),
                required: true,
                searchable: true,
                placeholder: translate('baseData.property.select') + '...',
                choiceOptions: () => portfolio.getProperties().map(property => ({
                    label: property.name,
                    value: property.id
                }))
            }),
        };
    }
}
const AccountingNoPropertyPage = ({}) => {
    const propertySelectorForm = useForm({
        name: "property-selector-form",
        initialValues: { propertyId: null },
        type: () => new PropertySelectType()
    });
    return (_jsxs(Body, { children: [_jsx(Title, { children: translate('accounting.property.warning.title') }), _jsx(Content, { children: _jsx(FormProvider, { form: propertySelectorForm, children: _jsx(Form, { noValidate: true, children: propertySelectorForm.render() }) }) })] }));
};
export default AccountingNoPropertyPage;
