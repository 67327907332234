import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import PatchNotesModal from "./PatchNotesModal";
const PatchNotesController = ({ patchNote }) => {
    const [activePatchNote, setActivePatchNote] = useState(patchNote);
    useEffect(() => {
        const listener = (e) => {
            const patchNote = e.detail.patchNote;
            setActivePatchNote(patchNote);
        };
        global.addEventListener("PREVIEW_PATCH_NOTE", listener);
        return () => global.removeEventListener('PREVIEW_PATCH_NOTE', listener);
    }, []);
    return (_jsx(_Fragment, { children: activePatchNote && _jsx(PatchNotesModal, { patchNote: activePatchNote, onHide: () => setActivePatchNote(null) }) }));
};
export default PatchNotesController;
