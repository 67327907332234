import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { ModalNew } from "Components/Modal";
import { translate } from "Services/Translator";
import { Button } from "Components/Atoms/Button";
import DamageModalContext from "./DamageModalContext";
import EditDamageForm from "Pages/Damage/Edit/components/EditDamageForm/EditDamageForm";
import { getRentalObjectRoomsWithAssets } from "Services/RentalObjectManager";
import { createDamage, getDamage, updateDamage } from "Services/DamageManager";
import { useToastDispatcherApi } from "Components/Dispatcher";
import Spinner from "Components/Spinner";
import moment from "moment";
const DamageEditModal = ({ damageId, onSaved, onHide }) => {
    const [show, setShow] = useState(true);
    const [damage, setDamage] = useState(damageId ? undefined : null);
    const [roomsWithAssets, setRoomsWithAssets] = useState(undefined);
    const loadRoomsWithAssets = async (rentalObjectId) => {
        const roomsWithAssets = rentalObjectId ? await getRentalObjectRoomsWithAssets(rentalObjectId) : [];
        setRoomsWithAssets(roomsWithAssets);
    };
    useEffect(() => {
        if (!damageId) {
            return;
        }
        getDamage(damageId)
            .then(async (damage) => {
            setDamage({
                ...damage,
                dateOfDamage: damage.dateOfDamage ? moment(damage.dateOfDamage).toDate() : null,
            });
        });
    }, [damageId]);
    const toastApi = useToastDispatcherApi();
    let activeForm = null;
    const setActiveForm = (form) => activeForm = form;
    const onSaveChanges = async () => {
        await activeForm.submitForm();
    };
    const closeModalHandler = () => {
        setShow(false);
        onHide();
    };
    return (_jsx(DamageModalContext.Provider, { value: { setActiveForm }, children: _jsxs(ModalNew, { show: show, onHide: closeModalHandler, size: {
                maxWidth: 'clamp(200px, 80vw, 1000px)',
                maxHeight: 'clamp(400px, 90vh, 800px)',
                height: "96vh"
            }, children: [_jsx(ModalNew.Header, { closeButton: true, children: _jsx(ModalNew.Title, { children: damage ? translate('damage.edit') : translate('damages.create') }) }), _jsx(ModalNew.Body, { children: damage === undefined
                        ? _jsx(Spinner, { isVisible: true })
                        : _jsx(EditDamageForm, { damage: damage, roomsWithAssets: roomsWithAssets, onChangeRentalObject: loadRoomsWithAssets, onSubmit: async (damage) => {
                                try {
                                    if (damage.id) {
                                        await updateDamage(damage);
                                    }
                                    else {
                                        await createDamage(damage);
                                    }
                                    onSaved(damage);
                                }
                                catch (e) {
                                    console.error(e);
                                    toastApi.error({ message: translate('generalError.loadingFormData'), timeout: 5, showRemove: true });
                                }
                            } }) }), _jsxs(ModalNew.Footer, { children: [_jsx(Button, { "$color": 'link', onClick: () => closeModalHandler(), children: translate('btn.close') }), _jsx(Button, { "$color": 'primary', type: "submit", onClick: onSaveChanges, children: translate('btn.save') })] })] }) }));
};
export default DamageEditModal;
