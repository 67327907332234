import { AbstractFormType, BooleanType, DateType } from "@f2w/form";
import { PortfolioSelectType } from "Pages/Rent/forms/PortfolioSelectType";
import { translate } from "Services/Translator";
export class RenterMirrorDateExportFormType extends AbstractFormType {
    buildFields() {
        return {
            date: new DateType({
                label: translate('accounting.reportingDate'),
            }),
            portfolio: new PortfolioSelectType({
                label: false,
                inheritData: true,
            }),
            anonymized: new BooleanType({
                label: translate('rent.anonymized'),
                required: false,
                defaultValue: false
            }),
            vacancyOnly: new BooleanType({
                label: translate('rent.vacancyOnly'),
                required: false,
                defaultValue: false
            }),
        };
    }
}
