import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from 'Atoms/Icon';
import { Tbody, Td, TdActions, Tr } from 'Atoms/Table';
import { Section } from 'Atoms/Layout/components';
import { useCallback } from 'react';
import { FlexCol } from 'Atoms/Layout';
import { Text } from 'Atoms/Text';
import { Badge } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import moment from 'moment';
import { AddressState } from 'Pages/Person/enums';
import { useModalDispatcherConfirm, useToastDispatcherApi } from "Components/Dispatcher";
const getStateBadge = (state) => {
    switch (state) {
        case AddressState.UPCOMING:
            return _jsx(Badge, { "$size": 'md', "$color": 'primaryOutline', children: translate('person.address.upcoming') });
        case AddressState.ACTIVE:
            return _jsx(Badge, { "$size": 'md', "$color": 'successOutline', children: translate('person.address.active') });
        case AddressState.PAST:
            return _jsx(Badge, { "$size": 'md', "$color": 'darkOutline', children: translate('person.address.past') });
    }
};
const olderAddressExists = (addresses, targetAddress) => {
    return addresses.find((address) => address.id !== targetAddress.id
        && moment(address.effectiveFrom).isSameOrBefore(targetAddress.effectiveFrom)) !== undefined;
};
export const AddressesTab = ({ group: { data: { pastAddresses = [], id } }, deletePersonAddress, view }) => {
    const { success, error } = useToastDispatcherApi();
    const onDeleteConfirm = useCallback(async (personId, addressId) => {
        await deletePersonAddress(personId, addressId)
            .then(() => {
            view.updateData((data) => ({
                pastAddresses: data.pastAddresses.filter(pastAddress => pastAddress.id !== addressId)
            }));
            success({
                message: translate('person.address.delete.success'),
                timeout: 5
            });
        }).catch(({ response }) => {
            const errorMessage = (response.status === 422)
                ? response.data.message
                : translate('person.details.validation.addressDeleteFailed');
            error({
                timeout: 5,
                message: errorMessage
            });
        });
    }, []);
    const onDeletePersonAddress = useModalDispatcherConfirm({
        help: translate('person.address.delete.confirm'),
        message: translate('person.address.delete.areYouSure'),
        onConfirm: onDeleteConfirm
    });
    if (pastAddresses?.length === 0) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Section.Table, { hover: false, variant: 'list', stickyHead: 0, colSpan: 4, children: _jsx(Tbody, { children: pastAddresses.map(pastAddress => (_jsxs(Tr, { children: [_jsx(Td, { min: true, children: _jsx(Icon, { icon: 'map-pin', size: 24 }) }), _jsx(Td, { style: { width: "100%" }, children: _jsxs(FlexCol, { children: [_jsxs(Text, { body: 500, children: [pastAddress.street, " ", pastAddress.houseNumber, ", ", pastAddress.postalCode, " ", pastAddress.city, ", ", translate('country.' + pastAddress.country)] }), _jsx(Text, { pretitle: 400, neutral: 300, children: pastAddress.effectiveFrom && (_jsxs(_Fragment, { children: [translate('person.address_effective_from'), " ", moment(pastAddress.effectiveFrom).format('DD.MM.YYYY')] })) })] }) }), _jsx(Td, { align: 'left', children: getStateBadge(pastAddress.state) }), _jsx(TdActions, { id: pastAddress.id, sticky: 'right', quickActions: [
                            {
                                icon: 'edit',
                                $size: 'lg',
                                title: translate('btn.edit'),
                                onClick: async () => {
                                    await view.updateData({
                                        pastAddress: {
                                            id: pastAddress.id,
                                            street: pastAddress.street ?? '',
                                            houseNumber: pastAddress.houseNumber ?? '',
                                            postalCode: pastAddress.postalCode ?? '',
                                            city: pastAddress.city ?? '',
                                            country: pastAddress.country ?? '',
                                            effectiveFrom: pastAddress.effectiveFrom ?? undefined,
                                        }
                                    });
                                    view.openForm('pastAddress');
                                }
                            },
                            {
                                icon: 'trash',
                                $size: 'lg',
                                title: translate('btn.delete'),
                                disabled: pastAddress.state === AddressState.ACTIVE
                                    && !olderAddressExists(pastAddresses, pastAddress),
                                onClick: () => onDeletePersonAddress(id, pastAddress.id)
                            }
                        ] })] }, pastAddress.id))) }) }));
};
