import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { OrFunction } from 'Utils/types';
import { BaseType, ObjectValueType, } from '../../core';
import { ObjectField } from '../../theme';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import useStableMemo from '@restart/hooks/useStableMemo';
import { setDebugVariable } from '@f2w/utils';
export class BaseObjectType extends BaseType {
    constructor(options) {
        super(options);
    }
    static useValue(props, deps = []) {
        const dispatchUpdate = useForceUpdate();
        const { value, type } = useStableMemo(() => {
            const { skipEmpty, initialValue, options, ...rest } = { ...OrFunction(props) };
            if (!initialValue && skipEmpty) {
                return {
                    type: null,
                    value: null
                };
            }
            const type = new this(options);
            const valueType = type.init({
                initialValue,
                ...rest,
                context: {
                    dispatchUpdate,
                },
            });
            return {
                type,
                value: valueType
            };
        }, deps);
        setDebugVariable({ fw_value: value, fw_type: type });
        return value;
    }
}
export class ObjectType extends BaseObjectType {
    specs = ObjectValueType.define({
        type: this,
        shape: () => ({})
    });
    constructor(fields, options) {
        super(options);
        this.specs.setShape(fields);
        this.__construct();
    }
    createValue(props) {
        return this.specs.createValueType(this, props);
    }
    _renderForm(valueType, props) {
        if (valueType.specs.inheritView || valueType.isRoot) {
            return _jsx(_Fragment, { children: valueType.renderChildren(null, {}) });
        }
        return _jsx(ObjectField, { children: valueType.renderChildren(null, {}), ...props });
    }
    static create(fields, options) {
        const type = new ObjectType(fields, options);
        return type;
    }
}
export class BaseFormType extends BaseObjectType {
    _renderWidget(props) {
        return props.field.renderChildren(null, {});
    }
    _renderForm(valueType, props) {
        if (valueType.specs.inheritView || valueType.isRoot) {
            return _jsx(_Fragment, { children: this._renderWidget(props) });
        }
        return _jsx(ObjectField, { children: this._renderWidget(props), ...props });
    }
}
export class BaseFormTypeOld extends BaseObjectType {
    specs;
    constructor(options) {
        super(options);
        const prev = this.__getSpecsProps();
        this.specs = ObjectValueType.define({
            type: this,
            shape: () => this.buildFields(),
            defaultOptions: () => {
                const opts = prev?.defaultOptions?.();
                return {
                    getResolvedValue: (v, t) => this.getResolvedValue(v, t),
                    ...opts,
                };
            },
        });
        this.__construct();
    }
    __getSpecsProps(props) {
        return { ...props };
    }
    getResolvedValue(value, valueType) {
        return value;
    }
    createValue(props) {
        return this.specs.createValueType(this, props);
    }
    _renderWidget(props) {
        return props.field.renderChildren(null, {});
    }
    _renderForm(valueType, props) {
        if (valueType.specs.inheritView || valueType.isRoot) {
            return _jsx(_Fragment, { children: this._renderWidget(props) });
        }
        return _jsx(ObjectField, { children: this._renderWidget(props), ...props });
    }
}
