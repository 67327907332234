import { useForm as __useForm } from '@f2w/form';
import { useDispatcherApi } from 'Components/Dispatcher';
import React, { useCallback, useMemo, useRef } from 'react';
import { useEventCallback } from '@restart/hooks';
import { translate } from 'Services/Translator';
export function StepForm() {
}
export function useStepForm({ stepId, onClose: _onClose, onUpdate: _onUpdate, steps, }) {
    const dispatcherApi = useDispatcherApi();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({});
    const currentStep = useMemo(() => {
        const stepIds = Object.keys(steps);
        const index = stepIds.indexOf(String(stepId));
        return {
            step: stepIds[index],
            nextStep: stepIds[index + 1],
            prevStep: stepIds[index - 1],
        };
    }, [stepId]);
    const formRef = useRef();
    const stepRef = useRef();
    const { updateData, type, name, } = stepRef.current = { ...steps[stepId], ...currentStep };
    const form = formRef.current = __useForm({
        name,
        type,
        updateData,
        initialValues,
        enableReinitialize: true,
    });
    const handleLoad = useEventCallback(async () => {
        let form = formRef.current;
        let step = stepRef.current;
        if (step?.loadData && form?.loading === false) {
            form.setLoading(true);
            return step.loadData()
                .then(async (response) => {
                await setInitialValues(response);
                await setModalOpen(true);
            })
                .finally(() => {
                formRef.current?.setLoading(false);
            });
        }
    });
    const handleSave = useCallback(async (close = true, ignoreEmpty) => {
        let form = formRef.current;
        if (!form)
            return;
        const isTouched = form.isTouched;
        if (close && !isTouched && stepRef.current.nextStep) {
            return await handleClose(true);
        }
        if (!ignoreEmpty || form.dirty) {
            const response = await form.submitForm();
            form = formRef.current;
            const isValid = form?.isValid && response && 'data' in response;
            if (!isValid)
                return;
            if (!close)
                await _onUpdate(response.data, stepRef.current);
        }
        if (close)
            await handleClose(true);
    }, [stepId, _onUpdate]);
    const handleClose = useCallback(async (force) => {
        if (force || !formRef.current?.dirty || await dispatcherApi.modal.confirmAsync({
            message: translate('formModal.confirm.message'),
            help: translate('formModal.confirm.help'),
            btnConfirm: translate('formModal.confirm.btn.confirm'),
            btnCancel: translate('formModal.confirm.btn.cancel'),
        })) {
            await _onClose();
        }
    }, [_onClose]);
    return {
        modalOpen: modalOpen,
        form: form,
        currentStep: currentStep,
        loadForm: handleLoad,
        closeForm: handleClose,
        saveForm: handleSave,
    };
}
