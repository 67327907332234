import { translate } from "Services/Translator";
export const options = [
    { value: -10, label: translate('baseData.rentalObject.floor.basement', { floor: 10 }) },
    { value: -9, label: translate('baseData.rentalObject.floor.basement', { floor: 9 }) },
    { value: -8, label: translate('baseData.rentalObject.floor.basement', { floor: 8 }) },
    { value: -7, label: translate('baseData.rentalObject.floor.basement', { floor: 7 }) },
    { value: -6, label: translate('baseData.rentalObject.floor.basement', { floor: 6 }) },
    { value: -5, label: translate('baseData.rentalObject.floor.basement', { floor: 5 }) },
    { value: -4, label: translate('baseData.rentalObject.floor.basement', { floor: 4 }) },
    { value: -3, label: translate('baseData.rentalObject.floor.basement', { floor: 3 }) },
    { value: -2, label: translate('baseData.rentalObject.floor.basement', { floor: 2 }) },
    { value: -1, label: translate('baseData.rentalObject.floor.basement', { floor: 1 }) },
    { value: 'ground', label: translate('baseData.rentalObject.floor.ground') },
    { value: 1, label: translate('baseData.rentalObject.floor.upper', { floor: 1 }) },
    { value: 2, label: translate('baseData.rentalObject.floor.upper', { floor: 2 }) },
    { value: 3, label: translate('baseData.rentalObject.floor.upper', { floor: 3 }) },
    { value: 4, label: translate('baseData.rentalObject.floor.upper', { floor: 4 }) },
    { value: 5, label: translate('baseData.rentalObject.floor.upper', { floor: 5 }) },
    { value: 6, label: translate('baseData.rentalObject.floor.upper', { floor: 6 }) },
    { value: 7, label: translate('baseData.rentalObject.floor.upper', { floor: 7 }) },
    { value: 8, label: translate('baseData.rentalObject.floor.upper', { floor: 8 }) },
    { value: 9, label: translate('baseData.rentalObject.floor.upper', { floor: 9 }) },
    { value: 10, label: translate('baseData.rentalObject.floor.upper', { floor: 10 }) },
];
