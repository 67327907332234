import { AbstractFormType, PasswordRepeatType, PasswordType } from '@f2w/form';
import { translate } from 'Services/Translator';
export class ChangePasswordType extends AbstractFormType {
    buildFields() {
        return {
            currentPassword: new PasswordType({
                label: translate('user.oldPassword'),
                nullable: true,
                required: true,
                displayStrengthIndicator: false,
            }),
            newPassword: new PasswordRepeatType({
                required: true,
                min: 6,
                inheritData: false,
            }),
        };
    }
}
