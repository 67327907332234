import useEventCallback from '@restart/hooks/useEventCallback';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import useCommittedRef from '@restart/hooks/useCommittedRef';
import useTimeout from '@restart/hooks/useTimeout';
import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useUncontrolled } from 'uncontrollable';
import { triggerBrowserReflow } from '../Base';
import { forEach } from '../Base/ElementChildren';
import { isBool } from '@f2w/utils';
const SWIPE_THRESHOLD = 40;
export function useCarousel(uncontrolledProps, ref = undefined) {
    const { fade = false, loop = true, pause, interval: _interval, activeIndex, onSelect, onSlide, onSlid, onKeyDown, onMouseOver, onMouseOut, onTouchStart, onTouchMove, onTouchEnd, disableKeyboard, disableTouch, children, ...rest } = useUncontrolled(uncontrolledProps, {
        activeIndex: 'onSelect',
    });
    const nextDirectionRef = useRef(null);
    const [direction, setDirection] = useState('next');
    const [paused, setPaused] = useState(false);
    const [isSliding, setIsSliding] = useState(false);
    const [renderedActiveIndex, setRenderedActiveIndex] = useState(activeIndex || 0);
    let interval = (_interval && (isBool(_interval) ? 5000 : _interval || null));
    if (!isSliding && activeIndex !== renderedActiveIndex) {
        if (nextDirectionRef.current) {
            setDirection(nextDirectionRef.current);
        }
        else {
            setDirection((activeIndex || 0) > renderedActiveIndex ? 'next' : 'prev');
        }
        if (!fade) {
            setIsSliding(true);
        }
        setRenderedActiveIndex(activeIndex || 0);
    }
    useEffect(() => {
        if (nextDirectionRef.current) {
            nextDirectionRef.current = null;
        }
    });
    let numChildren = 0;
    let activeChildInterval;
    forEach(children, (child, index) => {
        ++numChildren;
        if (index === activeIndex) {
            activeChildInterval = child.props.interval;
        }
    });
    const activeChildIntervalRef = useCommittedRef(activeChildInterval);
    const prev = useCallback((event) => {
        if (isSliding) {
            return;
        }
        let nextActiveIndex = renderedActiveIndex - 1;
        if (nextActiveIndex < 0) {
            if (!loop) {
                return;
            }
            nextActiveIndex = numChildren - 1;
        }
        nextDirectionRef.current = 'prev';
        if (onSelect) {
            onSelect(nextActiveIndex, event);
        }
    }, [isSliding, renderedActiveIndex, onSelect, loop, numChildren]);
    const next = useEventCallback((event) => {
        if (isSliding) {
            return;
        }
        let nextActiveIndex = renderedActiveIndex + 1;
        if (nextActiveIndex >= numChildren) {
            if (!loop) {
                return;
            }
            nextActiveIndex = 0;
        }
        nextDirectionRef.current = 'next';
        if (onSelect) {
            onSelect(nextActiveIndex, event);
        }
    });
    const elementRef = useRef();
    useImperativeHandle(ref, () => ({ element: elementRef.current, prev, next }));
    const slideDirection = direction === 'next' ? 'left' : 'right';
    useUpdateEffect(() => {
        if (!fade) {
            return;
        }
        if (onSlide) {
            onSlide(renderedActiveIndex, slideDirection);
        }
        if (onSlid) {
            onSlid(renderedActiveIndex, slideDirection);
        }
    }, [renderedActiveIndex]);
    const handleEnter = useCallback((node) => {
        triggerBrowserReflow(node);
        if (onSlide) {
            onSlide(renderedActiveIndex, slideDirection);
        }
    }, [onSlide, renderedActiveIndex, slideDirection]);
    const handleEntered = useCallback(() => {
        setIsSliding(false);
        if (onSlid) {
            onSlid(renderedActiveIndex, slideDirection);
        }
    }, [onSlid, renderedActiveIndex, slideDirection]);
    const handleKeyDown = useCallback((event) => {
        if (!disableKeyboard && !/input|textarea/i.test(event.target.tagName)) {
            switch (event.key) {
                case 'ArrowLeft':
                    event.preventDefault();
                    prev(event);
                    return;
                case 'ArrowRight':
                    event.preventDefault();
                    next(event);
                    return;
                default:
            }
        }
        if (onKeyDown) {
            onKeyDown(event);
        }
    }, [disableKeyboard, onKeyDown, prev, next]);
    const handleMouseOver = useCallback((event) => {
        if (pause === 'hover') {
            setPaused(true);
        }
        if (onMouseOver) {
            onMouseOver(event);
        }
    }, [pause, onMouseOver]);
    const handleMouseOut = useCallback((event) => {
        setPaused(false);
        if (onMouseOut) {
            onMouseOut(event);
        }
    }, [onMouseOut]);
    const touchStartXRef = useRef(0);
    const touchDeltaXRef = useRef(0);
    const touchUnpauseTimeout = useTimeout();
    const handleTouchStart = useCallback((event) => {
        touchStartXRef.current = event.touches[0].clientX;
        touchDeltaXRef.current = 0;
        if (pause === 'hover') {
            setPaused(true);
        }
        if (onTouchStart) {
            onTouchStart(event);
        }
    }, [pause, onTouchStart]);
    const handleTouchMove = useCallback((event) => {
        if (event.touches && event.touches.length > 1) {
            touchDeltaXRef.current = 0;
        }
        else {
            touchDeltaXRef.current =
                event.touches[0].clientX - touchStartXRef.current;
        }
        if (onTouchMove) {
            onTouchMove(event);
        }
    }, [onTouchMove]);
    const handleTouchEnd = useCallback((event) => {
        if (!disableTouch) {
            const touchDeltaX = touchDeltaXRef.current;
            if (Math.abs(touchDeltaX) > SWIPE_THRESHOLD) {
                if (touchDeltaX > 0) {
                    prev(event);
                }
                else {
                    next(event);
                }
            }
        }
        if (pause === 'hover') {
            touchUnpauseTimeout.set(() => {
                setPaused(false);
            }, interval || undefined);
        }
        if (onTouchEnd) {
            onTouchEnd(event);
        }
    }, [disableTouch, pause, prev, next, touchUnpauseTimeout, interval, onTouchEnd]);
    const shouldPlay = interval != null && !paused && !isSliding;
    const intervalHandleRef = useRef();
    const nextWhenVisible = useEventCallback(() => {
        if (!document.hidden && isVisible(elementRef.current)) {
            next();
        }
    });
    useEffect(() => {
        if (!shouldPlay) {
            return undefined;
        }
        intervalHandleRef.current = window.setInterval(document.visibilityState ? nextWhenVisible : next, activeChildIntervalRef.current ?? interval ?? undefined);
        return () => {
            if (intervalHandleRef.current !== null) {
                clearInterval(intervalHandleRef.current);
            }
        };
    }, [shouldPlay, next, activeChildIntervalRef, interval, nextWhenVisible]);
    const select = useCallback((index) => {
        uncontrolledProps.onSelect?.(index, null);
    }, [uncontrolledProps.onSelect]);
    const rootProps = {
        ref: elementRef,
        onKeyDown: handleKeyDown,
        onMouseOver: handleMouseOver,
        onMouseOut: handleMouseOut,
        onTouchStart: handleTouchStart,
        onTouchMove: handleTouchMove,
        onTouchEnd: handleTouchEnd,
        ...rest
    };
    const api = {
        activeIndex,
        renderedActiveIndex,
        prev,
        next,
        select,
        onSelect,
        direction,
        slideDirection,
        numChildren,
        fade,
        pause,
        loop,
        interval: _interval,
    };
    return {
        rootProps,
        ...api,
        handleEnter,
        handleEntered,
        children,
    };
}
function isVisible(element) {
    if (!element ||
        !element.style ||
        !element.parentNode ||
        !element.parentNode.style) {
        return false;
    }
    const elementStyle = getComputedStyle(element);
    return (elementStyle.display !== 'none' &&
        elementStyle.visibility !== 'hidden' &&
        getComputedStyle(element.parentNode).display !== 'none');
}
