import { mapObjectEntries, omit, omitKeys, pick, pickKeys } from '@f2w/utils';
export class ObjectMap {
    _shape;
    _keys;
    constructor(shape) {
        this._set(shape);
    }
    _set(shape) {
        this._shape = { ...shape };
        this._keys = Object.keys(this._shape);
    }
    get fields() {
        return this._shape;
    }
    get size() {
        return this._keys.length;
    }
    get(key) {
        return this._shape[key];
    }
    keys() {
        return this._keys;
    }
    values() {
        return Object.values(this._shape);
    }
    pickKeys(keys) {
        return pickKeys(this._shape, keys);
    }
    omitKeys(keys) {
        return omitKeys(this._shape, keys);
    }
    pick(keys) {
        return new ObjectMap(pick(this._shape, keys));
    }
    omit(keys) {
        return new ObjectMap(omit(this._shape, keys));
    }
    forEach(cb) {
        this.keys().forEach(key => cb(this.get(key), key));
    }
    map(cb) {
        return this.keys().map(key => cb(this.get(key), key));
    }
    mapEntries(cb) {
        return mapObjectEntries(this._shape, (key, value) => {
            return cb(value, key);
        });
    }
}
