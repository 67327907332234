import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import generateRoute from "Services/Router";
import { translate } from "Services/Translator";
import { UseStyled } from "@fw/theme";
import { css } from "styled-components";
const StyledBanner = UseStyled.styled('div', ({ theme: { palette, typography, rem } }) => (css `
        background: ${palette.warning.$100};
        color: ${palette.warning.$900};
        border: 1px solid ${palette.warning.$500};
        border-radius: ${rem(8)};
        padding: ${rem(18)} ${rem(26)};
        margin-bottom: ${rem(10)};
        ${typography.medium.fontStyles}
    `));
const UsersLimitExceededBanner = () => {
    return (_jsxs(StyledBanner, { children: [translate('accountLimits.tooManyUsers'), _jsx("div", { dangerouslySetInnerHTML: { __html: translate('accountLimits.tooManyUsersActivate')
                        .replace('{link}', '<a href="' + generateRoute('subscriptions') + '">')
                        .replace('{/link}', '</a>')
                } })] }));
};
export default UsersLimitExceededBanner;
