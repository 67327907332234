import React, { useCallback, useState } from 'react';
import { useForm as __useForm } from './useForm';
import { useDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { useEventCallback } from '@restart/hooks';
import { isString, setDebugVariable, toObject } from '@f2w/utils';
import { sleep } from 'Utils/delay';
export function useFormModal({ onLoad, onSave, initialShow = false, ignoreConfirm, ...formConfig }) {
    const [showModal, _setShowModal] = useState(initialShow);
    const [loading, setLoading] = useState(false);
    const initialDataRef = React.useRef(initialShow ? {} : null);
    const activeFormRef = React.useRef(null);
    const { toast: toastApi, modal: modalApi } = useDispatcherApi();
    const _doConfirm = useCallback(async () => {
        const props = {};
        return !props ? true : await modalApi.confirmAsync({
            message: translate('formModal.confirm.message'),
            help: translate('formModal.confirm.help'),
            btnConfirm: translate('formModal.confirm.btn.confirm'),
            btnCancel: translate('formModal.confirm.btn.cancel'),
            ...(isString(props) ? { message: props } : toObject(props)),
        });
    }, []);
    const _closeForm = useCallback((force) => {
        if (force || showModal) {
            activeFormRef.current = null;
            _setShowModal(false);
            formConfig.onClose?.();
            return true;
        }
    }, [showModal, _setShowModal]);
    const _onSubmit = useCallback(async (values, util, type) => {
        if (onSave) {
            return onSave(values, util, type);
        }
        return null;
    }, [onSave, loading, setLoading]);
    const useForm = useCallback((enableReinitialize) => {
        return activeFormRef.current = __useForm({
            ...formConfig,
            onSubmit: _onSubmit,
            enableReinitialize,
            initialValues: (initialDataRef?.current ?? {}),
        });
    }, [_onSubmit]);
    const loadForm = useCallback(async (id) => {
        if (!loading) {
            if (!id) {
                initialDataRef.current = {};
                _setShowModal(true);
                return null;
            }
            setLoading(true);
            return onLoad(id)
                .then(openForm)
                .catch(e => {
                if (!e.isHandled) {
                    toastApi?.error({
                        message: e?.message || 'Error loading form data',
                    });
                }
            })
                .finally(() => {
                setLoading(false);
            });
        }
    }, [onLoad, _setShowModal, showModal, setLoading, loading]);
    const openForm = useCallback(async (data) => {
        if (!loading) {
            initialDataRef.current = data || {};
            _setShowModal(true);
        }
    }, [_setShowModal, loading]);
    const closeForm = useCallback(async () => {
        if (ignoreConfirm || (!activeFormRef?.current?.dirty || (await _doConfirm()))) {
            if (_closeForm()) {
                await sleep(100);
                formConfig.onAfterClose?.();
                return true;
            }
        }
    }, [ignoreConfirm, _doConfirm, _closeForm, activeFormRef]);
    const saveForm = useEventCallback(async (close) => {
        if (!activeFormRef?.current || activeFormRef?.current?.loading) {
            return;
        }
        const success = await activeFormRef.current.submitForm?.();
        if (success && close && activeFormRef.current.isValid) {
            await new Promise(r => setTimeout(r, 300));
            _closeForm();
        }
        else {
        }
    });
    const resetForm = useEventCallback((e) => {
        activeFormRef?.current?.loading || activeFormRef.current?.handleReset?.(e);
    });
    const api = {
        useForm,
        loading,
        setLoading,
        activeData: initialDataRef?.current,
        show: showModal,
        openForm: loadForm,
        openNewForm: openForm,
        closeForm,
        saveForm,
        resetForm,
    };
    setDebugVariable('fw_formmodal', api);
    return api;
}
