import { jsx as _jsx } from "react/jsx-runtime";
import { CustomerStatus, CustomerType, SubscriptionStatus } from "Pages/Administration/Customers/enums";
import { Badge } from "Atoms/Badge";
import { translateAdmin } from "Services/Translator";
import { Div } from "Atoms/Layout";
export const getTypeBadge = (type) => {
    switch (type) {
        case CustomerType.DEMO: {
            return _jsx(Badge, { children: translateAdmin('customer.type.demo') });
        }
        case CustomerType.TRIAL: {
            return _jsx(Badge, { children: translateAdmin('customer.type.trial') });
        }
        case CustomerType.TEST: {
            return _jsx(Badge, { children: translateAdmin('customer.type.test') });
        }
        case CustomerType.PAID: {
            return _jsx(Badge, { "$color": 'success', children: translateAdmin('customer.type.paid') });
        }
        case CustomerType.PENDING: {
            return _jsx(Badge, { "$color": 'warning', children: translateAdmin('customer.type.pending') });
        }
        default: return _jsx(Div, { children: translateAdmin('notAvailable') });
    }
};
export const getStatusBadge = (status) => {
    switch (status) {
        case CustomerStatus.ACTIVE: {
            return _jsx(Badge, { "$color": 'success', children: translateAdmin('customer.status.active') });
        }
        case CustomerStatus.DISABLED: {
            return _jsx(Badge, { "$color": 'warning', children: translateAdmin('customer.status.disabled') });
        }
        case CustomerStatus.TERMINATED: {
            return _jsx(Badge, { "$color": 'danger', children: translateAdmin('customer.status.terminated') });
        }
        case CustomerStatus.TO_DELETE: {
            return _jsx(Badge, { "$color": 'danger', children: translateAdmin('customer.status.toDelete') });
        }
        case CustomerStatus.DELETED: {
            return _jsx(Badge, { "$color": 'dark', children: translateAdmin('customer.status.deleted') });
        }
        case CustomerStatus.CHURNED: {
            return _jsx(Badge, { "$color": 'dark', children: translateAdmin('customer.status.churned') });
        }
        default: return _jsx(Div, { children: translateAdmin('notAvailable') });
    }
};
export const getSubscriptionStatusBadge = (status) => {
    const text = translateAdmin(`customer.subscription.status.${status.toLowerCase()}`);
    switch (status) {
        case SubscriptionStatus.ENDED: return _jsx(Badge, { "$color": 'dark', children: text });
        case SubscriptionStatus.PAST: return _jsx(Badge, { "$color": 'darkOutline', children: text });
        case SubscriptionStatus.CHURNED: return _jsx(Badge, { "$color": 'danger', children: text });
        case SubscriptionStatus.ACTIVE: return _jsx(Badge, { "$color": 'success', children: text });
        case SubscriptionStatus.UPCOMING: return _jsx(Badge, { "$color": 'primaryOutline', children: text });
    }
};
