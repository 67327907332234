import ReactDom from 'react-dom';
import './LayouEntry';
import '../pages';
import { entryPoints } from './registerEntrypoint';
import { isDevEnv } from '@f2w/utils';
if (isDevEnv())
    console.log('render entrypoints', entryPoints);
for (let meta of Object.values(entryPoints)) {
    ReactDom.render(meta.render(), meta.el);
}
