import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalNew } from 'Components/Modal';
import React from 'react';
import { translate } from 'Services/Translator';
import { Button } from 'Components/Atoms/Button';
import DocumentTemplateServices from '../../services/documentTemplateServices';
import { UseStyled } from '@f2w/theme';
import Spinner from 'Components/Spinner';
import { ErrorMessage, Field, Formik } from "formik";
import FormSelect from "Components/Atoms/Form/FormSelect";
import FormErrorMessage from "Components/Atoms/Form/FormErrorMessage";
import * as yup from "yup";
const ModalBodyContainer = UseStyled.styled('div', () => ({}));
const FormValidation = yup.object().shape({
    propertiesIds: yup
        .mixed()
        .required(translate('form.validation.fieldIsRequired'))
});
export const CloneTemplateModal = ({ templateToClone, onClose, onCloneSuccess }) => {
    const [loading, setLoading] = React.useState(false);
    const [cloningTemplateLoading, setCloningTemplateLoading] = React.useState(false);
    const [availableProperties, setAvailableProperties] = React.useState([]);
    React.useEffect(() => {
        if (templateToClone) {
            setLoading(true);
            DocumentTemplateServices.getAvailablePropertiesForTemplate(templateToClone.templateInstanceKey)
                .then(properties => setAvailableProperties(properties))
                .finally(() => setLoading(false));
        }
        else {
            setAvailableProperties([]);
            setLoading(false);
            setCloningTemplateLoading(false);
        }
    }, [templateToClone]);
    const formRef = React.useRef();
    const handleSubmit = React.useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [formRef]);
    const propertyOptions = React.useMemo(() => {
        return availableProperties.map(property => ({
            value: property.id,
            label: property.name
        }));
    }, [availableProperties]);
    const onCloneConfirmed = React.useCallback(({ propertiesIds }) => {
        setCloningTemplateLoading(true);
        DocumentTemplateServices
            .cloneTemplateForProperties(templateToClone.id, propertiesIds)
            .then(onCloneSuccess)
            .finally(() => setCloningTemplateLoading(false));
    }, [templateToClone, onCloneSuccess]);
    if (loading) {
        return (_jsx(_Fragment, {}));
    }
    return (_jsxs(ModalNew, { show: !!templateToClone, size: { maxWidth: '500px' }, children: [_jsxs(ModalNew.Header, { closeButton: !cloningTemplateLoading, onHide: onClose, children: [_jsx(ModalNew.Title, { children: translate('template.cloneForProperty') }), _jsx(Spinner, { isVisible: cloningTemplateLoading })] }), _jsx(ModalNew.Body, { children: _jsx(ModalBodyContainer, { children: _jsx(Formik, { innerRef: formRef, initialValues: {}, onSubmit: onCloneConfirmed, validationSchema: FormValidation, children: _jsxs(_Fragment, { children: [_jsx("label", { htmlFor: 'propertiesIds', children: translate('template.availableProperties') }), _jsx(Field, { component: FormSelect, isMulti: true, maxMenuHeight: '200px', name: 'propertiesIds', options: propertyOptions }), _jsx(ErrorMessage, { component: FormErrorMessage, name: 'propertiesIds' })] }) }) }) }), _jsxs(ModalNew.Footer, { children: [_jsx(Button, { "$color": 'secondary-outline', "$size": 'md', onClick: onClose, style: { marginRight: '0.5rem' }, disabled: cloningTemplateLoading, children: translate('btn.cancel') }), _jsx(Button, { "$color": 'primary-outline', "$size": 'md', onClick: handleSubmit, disabled: cloningTemplateLoading, children: translate('btn.save') })] })] }));
};
