import { ContactType } from "Pages/Administration/Customers/types/EditCustomerType";
import useForceUpdate from "@restart/hooks/useForceUpdate";
import { useEffect } from "react";
import { AbstractFormType, StringType } from "@f2w/form";
import { translate } from "Services/Translator";
export class EditCustomerPublicType extends AbstractFormType {
    _useField(props) {
        super._useField(props);
        const dispatchUpdate = useForceUpdate();
        useEffect(() => {
            this.field.value.billingContact.sameAsCustomerContact = this.field.value.billingContactSameAsCustomer;
            dispatchUpdate();
        }, []);
        useEffect(() => {
            this.field.value.billingContactSameAsCustomer = this.field.value.billingContact.sameAsCustomerContact;
        }, [this.field.value.billingContact.sameAsCustomerContact]);
    }
    buildFields() {
        return {
            name: new StringType({
                label: translate('customer.name')
            }),
            contact: new ContactType({
                label: false,
                title: translate('customer.contactAddress'),
                wrapWithBorder: true,
                isBillingContact: false
            }),
            billingContact: new ContactType({
                label: false,
                title: translate('customer.invoiceAddress'),
                wrapWithBorder: true,
                isBillingContact: true
            })
        };
    }
}
