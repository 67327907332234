import generateRoute from "Services/Router";
import client from "Utils/client";
export var AuditLogServices;
(function (AuditLogServices) {
    AuditLogServices.listAuditLog = async (params) => {
        const route = generateRoute('api.auditLog.list');
        return client
            .get(route, { params })
            .then(response => response.data);
    };
})(AuditLogServices || (AuditLogServices = {}));
