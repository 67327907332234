import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { Cell, Label, Legend, Pie, PieChart as BasePieChart, ResponsiveContainer, Sector } from 'recharts';
import { useTheme } from '@f2w/theme';
import styled from 'styled-components';
import { formatMoney, formatPercentage } from 'Services/NumberHelper';
import { isNum } from 'Utils/types';
const PieChartRoot = styled.div `
  width: 100%;
  min-height: 150px;

  .recharts-text.recharts-label,
  .fwa-chart-label {
    color: red;
    text-anchor: middle;
    text-align: center;
    ${p => p.theme.typography.pretitle.css()};

  }
`;
export const PieChart = forwardRef(({ data, config, totalInfo, Legend: BaseLegendContent, colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0088FEBB', '#D7D8DB', '#00C49FBB', '#FFBB28BB', '#FF8042BB'] }, ref) => {
    const { palette, typography } = useTheme();
    const [activeIndex, setActiveIndex] = React.useState(undefined);
    const onEnter = React.useCallback((e, index) => {
        setActiveIndex(index);
    }, []);
    const onLeave = React.useCallback((_, index) => {
        setActiveIndex(undefined);
    }, []);
    const renderTotalInfo = React.useCallback(({ title, value, percent, viewBox: { cx, cy }, ...props }) => {
        return !totalInfo ? null : (_jsx(PieInfo, { cx: cx, cy: cy, title: totalInfo.title || "Total", value: totalInfo.value, percent: totalInfo.percent }));
    }, [totalInfo]);
    const renderActiveInfo = React.useCallback((props) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        return (_jsxs("g", { style: { color: palette.dark.$600, }, children: [_jsx(PieInfo, { cx: cx, cy: cy, title: payload.name, value: value, percent: percent }), _jsx(Sector, { cx: cx, cy: cy, innerRadius: innerRadius - 4, outerRadius: outerRadius + 4, startAngle: startAngle, endAngle: endAngle, fill: fill })] }));
    }, []);
    const pieProps = {
        cx: 80,
        cy: "50%",
        innerRadius: 82,
        outerRadius: 90,
        fill: "#8884d8",
        startAngle: 90,
        endAngle: 450,
        minAngle: 20,
        dataKey: "value",
        nameKey: 'name',
        ...config,
    };
    const pieWidth = pieProps.outerRadius * 2 + 40 + 20;
    const dataKey = pieProps.dataKey;
    let sum = 0;
    if (Array.isArray(data)) {
        sum = data.reduce((carry, row) => {
            return carry + row[dataKey];
        }, 0);
    }
    const isEmpty = sum <= 0;
    let actualData = data;
    if (isEmpty) {
        actualData = [{ [pieProps.nameKey]: '', [pieProps.dataKey]: 0.000000001 }, ...data];
    }
    const LegendContent = ({ payload: basePayload, ...props }) => {
        if (!BaseLegendContent) {
            return null;
        }
        const payload = basePayload.filter(({ payload }) => !!payload.name);
        return _jsx(BaseLegendContent, { activeIndex: activeIndex, ...props, payload: payload });
    };
    return (_jsx(PieChartRoot, { ref: ref, children: _jsx(ResponsiveContainer, { minHeight: 340, children: _jsxs(BasePieChart, { margin: { left: 20, right: 20 }, children: [_jsx(Legend, { wrapperStyle: {
                            width: `calc(100% - ${pieWidth}px)`,
                            overflow: 'hidden',
                        }, layout: "vertical", align: "right", verticalAlign: "middle", onMouseEnter: isEmpty ? onEnter : null, onMouseLeave: isEmpty ? onLeave : null, content: !LegendContent ? undefined : _jsx(LegendContent, { activeIndex: activeIndex }) }), _jsxs(Pie, { ...pieProps, ...config, data: actualData, activeIndex: activeIndex, activeShape: !isEmpty ? renderActiveInfo : null, onMouseEnter: !isEmpty ? onEnter : null, onMouseLeave: !isEmpty ? onLeave : null, children: [activeIndex !== undefined ? null :
                                _jsx(Label, { position: "center", content: renderTotalInfo }), actualData.map((entry, index) => (_jsx(Cell, { strokeWidth: 0, fill: !!entry.name ? colors[index % colors.length] : "#eee" }, `cell-${index}`)))] })] }) }) }));
});
const PieInfo = ({ cx, cy, title, value, percent }) => {
    const { palette, typography } = useTheme();
    const moneyFormat = "$0,0";
    return _jsxs("g", { style: { color: palette.dark.$600, }, children: [_jsx("text", { x: cx, y: cy, dy: -25, textAnchor: "middle", fill: "currentColor", style: typography.medium.css(), children: title }), _jsx("text", { x: cx, y: cy, dy: 7, textAnchor: "middle", fill: "currentColor", style: typography.body.css({ fontWeight: 600 }), children: isNum(value) ? formatMoney(value, moneyFormat) : value }), _jsx("text", { x: cx, y: cy, dy: 38, textAnchor: "middle", fill: "currentColor", style: typography.small.css(), children: isNum(percent) ? formatPercentage(percent, "0%") : percent })] });
};
