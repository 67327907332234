import * as rt from 'react-table';
import { useCallback, useMemo, useRef } from 'react';
import { functionalUpdate, isSameObject } from 'Utils/publicUtils';
import { isEqual } from 'lodash';
import { isEmptyObject } from 'jquery';
export const useRowState = {
    initOptions: (options, table) => ({
        autoResetRowState: false,
        initialState: { rowState: {} },
    }),
    hook: hooks => {
        rt.useRowState(hooks);
        hooks.prepareRow.push((row, { instance }) => {
            row.$status = instance.getStatusProps
                ? { ...instance.getStatusProps({ ...instance, row }) }
                : ({
                    type: row.state?.$status,
                    message: row.state?.$statusMessage
                });
        });
        hooks.getRowProps.push((iProps, { row, instance }) => ({
            ...iProps,
            status: row.$status?.type,
        }));
        hooks.useInstance.push(instance => {
            const { createStateManager = useStateManager, state: { rowState, selectedRowIds }, data, setRowState, } = instance;
            instance.updateApi = createStateManager({
                setRowState: setRowState,
                loadedData: data,
                state: rowState,
                selectedRowIds,
            }, instance);
        });
    },
};
export function useStateManager({ setRowState, state: rowState, loadedData = [], selectedRowIds = {}, }) {
    const dirtyIdsRef = useRef([]);
    const lastUpdatedId = useRef(null);
    const selectedIds = useMemo(() => Object.keys(selectedRowIds), [selectedRowIds]);
    const getState = useCallback((id) => {
        const { cellState, ...state } = rowState?.[id] ?? {};
        return state;
    }, [rowState]);
    const setState = useCallback((id, updater) => {
        setRowState(id, (prev) => {
            lastUpdatedId.current = id;
            return functionalUpdate(updater, prev);
        });
    }, [rowState]);
    const _addDirty = useCallback((id) => {
        dirtyIdsRef.current.includes(id) || dirtyIdsRef.current.push(id);
    }, []);
    const _removeDirty = useCallback((id) => {
        dirtyIdsRef.current = dirtyIdsRef.current.filter(v => v != id);
    }, []);
    const isLastUpdated = useCallback((id) => lastUpdatedId.current == id, [lastUpdatedId]);
    const getLoadedData = useCallback((id) => loadedData.find(v => v.id == id), [loadedData]);
    const getUpdateData = useCallback((id, withInitial) => {
        const state = getState(id);
        const updateData = withInitial ? {
            ...state?.initial,
            ...state?.update,
        } : state?.update;
        if (updateData) {
            const { assignment, ...update } = updateData;
            return { ...update, ...assignment };
        }
    }, [getState]);
    const getData = useCallback((id) => {
        return {
            ...getLoadedData(id),
            ...getUpdateData(id, true),
        };
    }, [getUpdateData, getLoadedData]);
    const updateData = useCallback((id, props) => {
        setState(id, ({ ...prev }) => {
            let { $status: _1, $statusMessage: _2, ...updateData } = props;
            const { $status, $statusMessage } = { ...prev, ...props };
            const state = {
                ...prev,
                $status,
                $statusMessage,
            };
            if (!isEmptyObject(updateData)) {
                state.update = { ...state.initial, ...state.update, ...updateData };
                state.$isDirty = isEqual(state.update, state.initial);
                state.$isDirty ? _addDirty(id) : _removeDirty(id);
            }
            lastUpdatedId.current = id;
            return state;
        });
    }, [setState]);
    const update = useCallback((id, updater) => {
        setState(id, (prev) => {
            const update = functionalUpdate(updater, prev?.update);
            const $isDirty = !isSameObject(update, prev.initial);
            lastUpdatedId.current = id;
            $isDirty ? _addDirty(id) : _removeDirty(id);
            return {
                ...prev,
                $isDirty,
                $status: $isDirty ? 'warning' : undefined,
                update,
            };
        });
    }, [updateData]);
    const isDirty = useCallback((id) => getState(id)?.$isDirty, [getState]);
    const remove = useCallback((id) => {
        if (Array.isArray(id))
            return id.forEach(v => remove(v));
        if (rowState?.[id]) {
            _removeDirty(id);
            setState(id, (prev) => ({}));
        }
    }, [rowState, setState]);
    const removeAll = useCallback(() => {
        Object.keys(rowState).forEach(id => remove(id));
    }, [rowState]);
    const reset = useCallback((id = dirtyIdsRef.current, newState) => {
        if (Array.isArray(id))
            return id.forEach(v => reset(v));
        if (rowState?.[id]) {
            setState(id, ({ initial, ...prev }) => {
                _removeDirty(id);
                return {
                    ...newState,
                    initial,
                    update: initial,
                };
            });
        }
    }, [rowState, setState]);
    const resetAll = useCallback(() => {
        Object.keys(rowState).forEach(id => reset(id));
    }, [rowState]);
    return {
        _addDirty,
        _removeDirty,
        dirtyIds: dirtyIdsRef.current,
        dirtyCount: dirtyIdsRef.current?.length || 0,
        selectedIds,
        lastUpdatedId: lastUpdatedId.current,
        isLastUpdated,
        setState,
        getState,
        getLoadedData,
        getUpdateData,
        getData,
        update,
        updateData,
        isDirty,
        remove,
        removeAll,
        reset,
        resetAll,
    };
}
