import { tokenBuilder } from './createTokens';
export const sizeToken = tokenBuilder()({
    xs: {
        styles: ({ typography }, {}) => ({
            ...typography.small.fontStyles,
            paddingX: 8,
            paddingY: 2,
            height: 26,
            borderRadius: 6,
        }),
    },
    sm: {
        styles: ({ typography }) => ({
            ...typography.small.fontStyles,
            paddingX: 12,
            paddingY: 4,
            height: 32,
            borderRadius: 6,
        }),
    },
    md: {
        isDefault: true,
        styles: ({ typography }) => ({
            ...typography.medium.fontStyles,
            paddingX: 16,
            paddingY: 6,
            height: 40,
            borderRadius: 8,
        }),
    },
    lg: {
        styles: ({ typography }) => ({
            ...typography.body.fontStyles,
            paddingX: 16,
            paddingY: 8,
            height: 48,
            borderRadius: 8,
        }),
    },
});
