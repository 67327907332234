export const mimeTypes = {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'image/bmp': ['.bmp'],
    'image/gif': ['.gif'],
    'image/heic': ['.heic'],
    'image/webp': ['.webp'],
    'text/calendar': ['.ics'],
    'text/csv': ['.csv'],
    'text/xml': ['.xml'],
    'text/plain': ['.txt'],
    'video/3gpp': ['.3gp'],
    'video/3gpp2': ['.3g2'],
    'video/mp2t': ['.ts'],
    'video/mp4': ['.mp4'],
    'video/mpeg': ['.mpeg'],
    'video/ogg': ['.ogv'],
    'video/webm': ['.webm'],
    'video/x-msvideo': ['.avi'],
    'audio/3gpp': ['.3gp'],
    'audio/3gpp2': ['.3g2'],
    'audio/aac': ['.aac'],
    'audio/midi': ['.mid, .midi'],
    'audio/x-midi': ['.mid, .midi'],
    'audio/mpeg': ['.mp3'],
    'audio/ogg': ['.oga'],
    'audio/opus': ['.opus'],
    'audio/wav': ['.wav'],
    'audio/webm': ['.weba'],
    'font/otf': ['.otf'],
    'font/ttf': ['.ttf'],
    'font/woff': ['.woff'],
    'font/woff2': ['.woff2'],
    'application/pdf': ['pdf'],
    'application/xml': ['.xml'],
    'application/msword': ['doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['docx'],
    'application/vnd.ms-excel': ['xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['xlsx'],
    'application/vnd.ms-powerpoint': ['ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['pptx'],
    'application/vnd.oasis.opendocument.text': ['odt'],
    'application/vnd.oasis.opendocument.spreadsheet': ['ods'],
    'application/vnd.oasis.opendocument.presentation': ['odp'],
};
