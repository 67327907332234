import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { ContentTemplateNew, SplitViewTemplate } from '@f2w/view';
import { Header } from 'Atoms/Layout/components';
import { translate } from 'Services/Translator';
import { DateType } from '@f2w/form';
const HeaderTemplate = ({ view: { data: rent } }) => {
    return _jsx(Header.Avatar, { pretitle: `${rent.property.name} ∙ ${rent.rentalObject.name}`, title: _jsxs(_Fragment, { children: [DateType.format(rent.startDate), " - ", rent.endDate ? DateType.format(rent.endDate) : translate('contract.ends.notLimited')] }) });
};
export const RentTemplate = (props) => {
    return _jsx(SplitViewTemplate, { ...props, Template: ContentTemplateNew, leftSide: {
            groups: ['general'],
            Header: HeaderTemplate,
        }, rightSide: {
            groups: ['debitorInvoices'],
        } });
};
