import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTable } from '@f2w/data-table';
import { Dunning, DunningLevel } from 'Pages/Dunning/types';
import { translate } from 'Services/Translator';
import { DateType, DecimalType } from '@f2w/form';
import { Badge, Button, ButtonToolbar, MoneyText, OverlayTrigger, SafeAnchor, Select, Text, Tooltip, } from 'Components/Atoms';
import { MaxDunningLevelFilter } from 'Pages/Dunning/components/MaxDunningLevelFilter';
import { OverdueTenantsFilter } from 'Pages/Dunning/components/OverdueTenantsFilter';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { RentalObjectCard } from 'Components/App/card';
import moment from 'moment';
const getDunningLevelBadge = (dunningLevel, dunningLevelLastCreatedAt) => {
    if (dunningLevel === DunningLevel.ZERO) {
        return _jsx(Badge, { "$color": 'success', children: translate('dunning.levelZero') });
    }
    let dunningData;
    switch (dunningLevel) {
        case DunningLevel.ONE: {
            dunningData = {
                color: 'primary',
                label: translate('dunning.levelOne')
            };
            break;
        }
        case DunningLevel.TWO: {
            dunningData = {
                color: 'warning',
                label: translate('dunning.levelTwo')
            };
            break;
        }
        case DunningLevel.THREE: {
            dunningData = {
                color: 'danger',
                label: translate('dunning.levelThree')
            };
        }
    }
    if (!dunningLevelLastCreatedAt) {
        return _jsx(Badge, { "$color": dunningData.color, children: dunningData.label });
    }
    return (_jsx(OverlayTrigger, { placement: 'top', overlay: (_jsx(Tooltip, { id: 'dunning-level-last-created-at-tooltip', children: translate('dunning.levelLastCreatedAt', {
                date: moment(dunningLevelLastCreatedAt).format('DD.MM.YYYY'),
            }) })), children: _jsx(Badge, { "$color": dunningData.color, children: dunningData.label }) }));
};
const calculateNextReminder = (currentLevel) => {
    return Math.min(DunningLevel.THREE.valueOf(), currentLevel.valueOf() + 1);
};
const isReminderOptionDisabled = (optionValue, nextPossibleReminder) => {
    return Math.abs(optionValue || 0) < Math.abs(calculateNextReminder(nextPossibleReminder) || 0);
};
const BaseTable = DataTable.factory()(() => ({
    tableId: 'Dunning',
    manualControl: true,
    useSortBy: {
        initialState: {
            sortBy: [
                {
                    id: 'overdueAmount',
                    desc: true
                }
            ]
        }
    },
    useGlobalFilter: {},
    usePagination: {},
    getId: row => row.debitorId,
    useGroupBy: {
        hidePlaceholder: false,
        initialState: { groupBy: ['name'] },
        subTable: true,
    },
    useFilters: {
        initialState: {
            filters: [
                {
                    id: 'onlyOverdueTenants',
                    value: 1
                }
            ]
        }
    },
    RowActions: ({ openPersonAccountStatement, openDebitorView, row: { id, original: data, values } }) => {
        if (data?.rentalObject) {
            return (_jsx(ActionsDropdown, { id: data.debitorId, scrollable: true, quickActions: [
                    {
                        icon: 'eye',
                        title: translate('debitorInvoice.view'),
                        onClick: () => openDebitorView(data.debitorId)
                    },
                ] }));
        }
        return _jsx(_Fragment, { children: _jsx(ActionsDropdown, { id: id, scrollable: true, quickActions: [
                    {
                        icon: 'eye',
                        title: translate('dunning.accountStatement'),
                        onClick: () => openPersonAccountStatement(values.person)
                    },
                ] }) });
    },
    BulkActions: ({ openPersonAccountStatementReportsExportModal, openCreateRemindersForm, state, getSelectedReminders }) => {
        const count = Object.keys(state.selectedRowIds)?.length;
        return (_jsxs(ButtonToolbar, { children: [_jsxs(Button, { icon: 'bell', variant: 'outline', disabled: count === 0, onClick: () => openCreateRemindersForm(getSelectedReminders()), children: [translate('reminderProcess.sendReminders'), " ", count ? `(${count})` : ''] }), _jsx(Button, { icon: 'download', disabled: count === 0, onClick: openPersonAccountStatementReportsExportModal, children: translate('dunning.accountStatement') })] }));
    },
    useRowSelect: {
        onAllRowsSelectChange: ({ setRowState, rows }, isSelected) => {
            rows.forEach(row => {
                setRowState(row.id, (prev) => ({
                    ...prev,
                    personId: isSelected ? row?.values?.person?.personId : undefined
                }));
            });
        },
        onRowSelectChange: ({ row, setRowState }, isSelected) => {
            setRowState(row.id, (prev) => ({
                ...prev,
                personId: isSelected
                    ? (row?.isGrouped ? row?.values : row?.original)?.person?.personId
                    : undefined
            }));
        }
    },
    useRowState: {
        autoResetRowState: false,
        initialRowStateAccessor: (row) => {
            return row.original ? {
                reminderState: calculateNextReminder(row.original.dunningLevel).valueOf(),
            } : {};
        }
    },
    columns: {
        $rowExpand: {
            sticky: 'right',
        },
        $rowSelect: {
            sticky: 'left',
        },
        onlyOverdueTenants: {
            Header: translate('dunning.tenant'),
            hide: true,
            useFilters: {
                Filter: OverdueTenantsFilter
            }
        },
        name: {
            Header: translate('bank.persons'),
            accessor: v => v.person.name,
            useSortBy: {},
            Cell: ({ openPersonInfo, row: { values: { person } }, value }) => (_jsx(_Fragment, { children: !person ? value : (_jsx(SafeAnchor, { onClick: () => openPersonInfo(person), children: value })) })),
        },
        person: {
            hide: true,
            accessor: v => v.person,
            aggregate: values => values[0] ?? null,
        },
        balanceAmount: {
            Header: translate('person.balanceAmount'),
            accessor: v => v.person.balanceAmount,
            aggregate: values => values[0] ?? null,
            useSortBy: {},
            Cell: ({ value }) => _jsx(MoneyText, { negative: true, value: value })
        },
        overdueAmount: {
            Header: translate('person.overdueAmount'),
            accessor: v => v.person.overdueAmount,
            aggregate: values => values[0] ?? null,
            useSortBy: {},
            Cell: ({ value }) => _jsx(MoneyText, { negative: true, value: value })
        },
        openInvoiceCount: {
            Header: translate('dunning.openInvoiceCount'),
            accessor: v => v.person.openInvoiceCount,
            aggregate: values => values[0] ?? null,
            Cell: ({ value }) => _jsx(Text, { children: value })
        },
        overdueInvoiceCount: {
            Header: translate('dunning.overdueInvoiceCount'),
            accessor: v => v.person.overdueInvoiceCount,
            aggregate: values => values[0] ?? null,
            Cell: ({ value } = { value: null }) => {
                return _jsx(Text, { children: value });
            }
        },
        maxDunningLevel: {
            Header: translate('dunning.maxDunningLevel'),
            accessor: v => v.person.maxDunningLevel,
            aggregate: values => values[0] ?? null,
            useSortBy: {},
            useFilters: {
                Filter: MaxDunningLevelFilter
            },
            Cell: ({ value }) => _jsx(_Fragment, { children: getDunningLevelBadge(value) })
        },
        rentalObjectName: {
            Header: translate('dunning.rentalObject'),
            isSubTable: true,
            Cell: ({ row: { original } }) => (_jsx(RentalObjectCard, { rentalObjectName: original.rentalObject.name, rentalObjectId: original.rentalObject.id, buildingName: original.rentalObject.buildingName })),
        },
        label: {
            Header: translate('dunning.label'),
            isSubTable: true,
        },
        amount: {
            Header: translate('dunning.amount'),
            isSubTable: true,
            Cell: ({ row: { original } }) => (_jsx(_Fragment, { children: _jsxs(Text, { nowrap: true, children: ["CHF ", _jsx(Text, { primary: 500, children: DecimalType.useFormat(original?.openAmount) }), _jsxs(Text, { children: ["/", DecimalType.useFormat(original?.amount)] })] }) }))
        },
        dueTo: {
            Header: translate('dunning.dueTo'),
            isSubTable: true,
            Cell: ({ value } = { value: null }) => {
                return _jsx(Text, { children: DateType.useFormat(value) });
            }
        },
        dueSince: {
            Header: translate('dunning.dueSince'),
            Cell: ({ value }) => _jsxs(Text, { nowrap: true, ...(value < 0 ? { dark: 900 } : { error: 400 }), children: [value, " ", value === 1 ? translate('dunning.nbDay') : translate('dunning.nbDays')] }),
            isSubTable: true,
        },
        currentDunningLevel: {
            Header: translate('dunning.currentDunningLevel'),
            accessor: 'dunningLevel',
            Cell: ({ value, row: { original: { dunningLevelLastCreatedAt } } }) => getDunningLevelBadge(value, dunningLevelLastCreatedAt),
            isSubTable: true,
        },
        dunningLevel: {
            Header: translate('dunning.nextDunningLevel'),
            Cell: ({ row }) => {
                if (!row.isSelected) {
                    return _jsx(_Fragment, {});
                }
                const { state: { reminderState }, setState } = row;
                return (_jsx(Select, { useMaxContentWidth: true, value: reminderState, placeholder: translate('reminderProcess.reminder.select'), menuPlacement: 'auto', menuShouldBlockScroll: true, menuPortalTarget: document.body, options: Dunning.reminderOptions.map(opt => ({
                        ...opt,
                        isUnactive: isReminderOptionDisabled(opt.value, row.original.dunningLevel),
                    })), onChange: (opt) => {
                        row.toggleRowSelected(true);
                        setState((oldState) => ({
                            ...oldState,
                            reminderState: opt ? opt.value : 0
                        }));
                    } }));
            },
            isSubTable: true,
        },
    },
}));
export const DunningGroupedDataTable = Object.assign(BaseTable, {
    use: ({ listDunning, openPersonAccountStatementReportsExportModal, openPersonInfo, openCreateRemindersForm, openDebitorView, openPersonAccountStatement }) => {
        const table = DunningGroupedDataTable.useTable({
            openPersonInfo,
            openCreateRemindersForm,
            openDebitorView,
            openPersonAccountStatement,
            openPersonAccountStatementReportsExportModal,
            getSelectedReminders: () => {
                const selectedReminders = [];
                for (const [rowId, selected] of Object.entries(table.state.selectedRowIds)) {
                    if (selected) {
                        selectedReminders.push({
                            id: Number(rowId),
                            state: table.state.rowState?.[rowId]?.reminderState,
                        });
                    }
                }
                return selectedReminders;
            },
        }, () => ({
            getData: (props) => listDunning(props?.request),
        }));
        return table;
    },
});
