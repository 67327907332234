import { ScalarField } from '@f2w/form';
import { createImage } from '@f2w/utils';
export class ImageField extends ScalarField {
    getBinaryFile() {
        return this._state.image ?? null;
    }
    _updateValue({ value, ...props }) {
        if (value instanceof Blob) {
            if (value !== this.state.image) {
                if (this.state.image) {
                    URL.revokeObjectURL(this.value);
                }
                this.state.image = value;
                value = URL.createObjectURL(value);
                createImage(value).then(image => {
                    this._type.options.onImageChange?.(image, this._type);
                });
            }
            else {
                value = this.state.value;
            }
        }
        else if (this.state.image) {
            URL.revokeObjectURL(this.value);
            this._state.image = null;
        }
        super._updateValue({
            value,
            ...props
        });
    }
}
