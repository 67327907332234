import generateRoute from "Services/Router";
import client from "Utils/client";
export const activateIntegration = () => {
    const route = generateRoute('handover.integration.activate');
    return client.post(route);
};
export async function getHandoverUsers() {
    const route = generateRoute('api.handover.users');
    const response = await client.get(route);
    return response?.data ?? [];
}
export async function getHandoverIntegration() {
    const route = generateRoute('api.handover.integration');
    const response = await client.get(route);
    return response?.data ?? [];
}
export default {
    activateIntegration,
};
