import { AbstractObjectType } from './CompoundType';
import { lowerFirst } from 'lodash';
export class BaseAbstractFormType extends AbstractObjectType {
    formName = lowerFirst(this.constructor.name?.replace?.(/(Form|Type)$/, ''));
    constructor(options, parent) {
        super(options, parent);
        this._initFields(this.buildFields());
    }
    getFieldGroups() {
        return undefined;
    }
    getGroupFieldKeys(...args) {
        const groups = this.getFieldGroups();
        return args.flat(3).map(name => groups[name] ? Object.keys(groups[name]) : []).flat(3);
    }
    getGroupData(name, ...keys) {
        const group = this.getGroupFieldKeys(name, ...keys);
        return this.pickValue(group);
    }
    renderGroup(name, ...keys) {
        const group = this.getGroupFieldKeys(name, ...keys);
        return group ? this.renderChildren(group) : null;
    }
    create(props) {
        this._createInternal({
            name: this.formName,
            ...props
        });
        return this;
    }
    static cast(value, ignoreDefaults) {
        const type = new this({});
        return type.cast(value, ignoreDefaults);
    }
}
export class AbstractFormType extends BaseAbstractFormType {
    constructor(options, parent) {
        super(options, parent);
    }
}
