import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { RentalObjectAddRoomType } from "Components/ObjectViewModal/forms/RentalObjectAddRoomType";
import { Button } from "Atoms/Button";
import { translate } from "Services/Translator";
export const useRentalObjectAddRoomForm = ({ addRentalObjectRoom }) => useFormModal({
    type: () => new RentalObjectAddRoomType(),
    name: 'rental-object-add-room',
    title: translate('rentalObject.addRoom'),
    onLoad: async (id) => ({ id }),
    onSave: (values) => {
        return addRentalObjectRoom(values.id, values.abstractRoomId);
    },
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, disabled: loading, onClick: () => saveForm(true), children: translate('rentalObject.addRoom') })] });
    },
});
