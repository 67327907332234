import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Div } from "Atoms/Layout";
import { useActiveViewContext } from "Pages/Administration/context/ActiveViewContext";
import { useEffect } from "react";
import { useCustomerSubscriptionView } from "Pages/Administration/Customers/hooks/useCustomerSubscriptionView";
import { useUpdateSubscriptionForm } from "Pages/Administration/Customers/hooks/useUpdateSubscriptionForm";
import { FormModal } from "@f2w/form";
export const CustomerSubscriptionView = ({ getSubscriptionDetails, updateSubscription, loadSubscription, deleteSubscription }) => {
    const { id } = useActiveViewContext();
    const customerSubscriptionView = useCustomerSubscriptionView({
        getSubscriptionDetails,
        onEdit: () => updateSubscriptionForm.openForm(id),
        deleteSubscription
    });
    const updateSubscriptionForm = useUpdateSubscriptionForm({
        onSave: () => customerSubscriptionView.load({ id }),
        updateSubscription,
        loadSubscription
    });
    useEffect(() => {
        if (id) {
            customerSubscriptionView.load({ id });
        }
    }, [id]);
    return (_jsxs(Div, { h: '100%', children: [customerSubscriptionView.render(), _jsx(FormModal, { modal: updateSubscriptionForm })] }));
};
