import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Div, FlexRow } from 'Atoms/Layout';
import { Button } from 'Atoms/Button';
import { getCroppedImg } from './helpers';
import { translate } from 'Services/Translator';
import { useEventCallback } from '@restart/hooks';
import { functionalUpdate } from 'Utils/publicUtils';
import styled from 'styled-components';
import { RangePicker } from '../RangePicker';
import { toNum } from 'Utils';
export const ImageCropper = ({ imageSrc, onSave, onCancel, managerRef, ...props }) => {
    const cropperRef = managerRef ?? useRef();
    const cropper = cropperRef.current = useCropper({
        imageSrc: imageSrc,
        aspect: 1,
        objectFit: 'cover',
        cropShape: 'round',
        showGrid: true,
        style: {
            containerStyle: {
                border: 'var(--cropper-mask-border)',
            },
            cropAreaStyle: {
                border: 'var(--cropper-mask-border)',
                color: 'var(--cropper-mask-color)',
            }
        },
        ...props
    });
    const step = 0.05;
    return (_jsxs(StyledCropper.Root, { children: [_jsxs(FlexRow, { justifyCenter: true, gap: 10, children: [_jsx(Button, { outline: true, color: "error", label: translate('form.control.image.action.cancel'), onClick: () => onCancel?.() }), _jsx(Button, { outline: true, icon: 'save', label: translate('form.control.image.action.save'), onClick: () => cropper.getImage().then(image => onSave?.(image)) })] }), _jsx(Div, { w: '100%', pt: '100%', relative: true, children: _jsx(Cropper, { ...cropper.getProps() }) }), _jsxs(StyledCropper.Zoom, { children: [_jsx(Button, { neutral: true, icon: 'image', disabled: cropper.minusZoomDisabled(), onClick: () => cropper.minusZoom(step), style: { fontSize: 19 } }), _jsx(Div, { flexGrow: 1, children: _jsx(RangePicker, { value: cropper.zoom, min: cropper.minZoom, max: cropper.maxZoom, step: step, onChange: cropper.setZoom }) }), _jsx(Button, { neutral: true, icon: 'image', disabled: cropper.plusZoomDisabled(), onClick: () => cropper.plusZoom(step), style: { fontSize: 33, width: 49 } })] })] }));
};
var StyledCropper;
(function (StyledCropper) {
    StyledCropper.Zoom = styled.div `
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 24px;
      color: var(--fw-color-neutral-400);
    `;
    StyledCropper.Root = styled.div `
      --cropper-mask-color: rgba(255, 255, 255, 0.7);
      --cropper-mask-border: 1px solid rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 10px;
    `;
})(StyledCropper || (StyledCropper = {}));
function useCropper({ imageSrc: imageSrc, ...initialProps }) {
    const [state, _setState] = useState({
        crop: { x: 0, y: 0 },
        zoom: 1,
        rotation: 0,
    });
    const props = {
        ...Cropper.defaultProps,
        aspect: 1,
        image: imageSrc,
        ...initialProps,
        ...state,
    };
    const updateState = useEventCallback((value) => {
        _setState(prev => {
            return functionalUpdate(value, prev);
        });
    });
    const [croppedAreaPixels, _setCroppedArea] = useState(null);
    const setZoom = useEventCallback((zoom) => updateState({ zoom }));
    const updateZoom = useCallback((_step, multiplier = 1) => _setState(({ zoom: _zoom, ...prev }) => {
        let step = (toNum(_step) || 0.05) * multiplier;
        let zoom = (toNum(_zoom) || 1) + step;
        zoom = Math.max(props.minZoom, Math.min(props.maxZoom, zoom));
        return {
            ...prev,
            zoom,
        };
    }), [props.minZoom, props.maxZoom]);
    const plusZoom = useCallback((step) => updateZoom(step, 1), [updateZoom]);
    const minusZoom = useCallback((step) => updateZoom(step, -1), [updateZoom]);
    const minusZoomDisabled = useCallback(() => state.zoom == props.minZoom, [state.zoom, props.minZoom]);
    const plusZoomDisabled = useCallback(() => state.zoom == props.maxZoom, [state.zoom, props.maxZoom]);
    const setCrop = useEventCallback((crop) => updateState({ crop }));
    const setRotation = useEventCallback((rotation) => updateState({ rotation }));
    const setCropArea = useEventCallback((croppedArea, croppedAreaPixels) => {
        _setCroppedArea(croppedAreaPixels);
    });
    const getImage = useCallback(async () => {
        try {
            return await getCroppedImg(imageSrc, croppedAreaPixels);
        }
        catch (e) {
            console.error(e);
        }
    }, [imageSrc, croppedAreaPixels]);
    const getImageUrl = useCallback(async () => {
        try {
            const croppedImage = await getImage();
            return URL.createObjectURL(croppedImage);
        }
        catch (e) {
            console.error(e);
        }
    }, [getImage]);
    return {
        ...state,
        get minZoom() {
            return props.minZoom;
        },
        get maxZoom() {
            return props.maxZoom;
        },
        plusZoom,
        minusZoom,
        minusZoomDisabled,
        plusZoomDisabled,
        setZoom,
        setCrop,
        setRotation,
        getImage,
        getImageUrl,
        getProps(_props) {
            return {
                style: {},
                classes: {},
                ..._props,
                ...props,
                onCropComplete: setCropArea,
                onZoomChange: setZoom,
                onCropChange: setCrop,
                onRotationChange: setRotation,
            };
        },
    };
}
