import generateRoute from "Services/Router";
import client from "Utils/client";
export const getDebitorInvoices = async (params) => {
    const url = generateRoute('api.debitorInvoices.list');
    const response = await client.get(url, { params });
    return response.data;
};
export const getDebitorInvoiceDetails = (id) => {
    const route = generateRoute('debitorInvoices.details', {
        id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const getDebitorInvoiceById = (id) => {
    const route = generateRoute('api.debitorInvoices.byId', {
        debitorInvoice: id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const createDebitorInvoice = (createInvoiceDto) => {
    const route = generateRoute('api.debitorInvoices.create');
    return client.post(route, createInvoiceDto);
};
export const remindDebitorInvoice = (debitorInvoiceId) => {
    const route = generateRoute('api.debitorInvoices.remind', {
        debitorInvoice: debitorInvoiceId
    });
    return client.post(route);
};
export const deleteDebitorPayment = (debitorInvoiceId, paymentId) => {
    const route = generateRoute('api.debitorInvoices.deletePayment', {
        debitorInvoice: debitorInvoiceId,
        payment: paymentId,
    });
    return client
        .delete(route)
        .then(response => response.data);
};
export const deleteDebitorInvoice = (id) => {
    const route = generateRoute('api.debitorInvoices.delete', {
        debitorInvoice: id
    });
    return client.delete(route);
};
export const updateDebitorInvoice = (id, updateInvoiceDto) => {
    const route = generateRoute('api.debitorInvoices.update', {
        debitorInvoice: id
    });
    return client.put(route, updateInvoiceDto);
};
export const updateDebitorInvoiceGeneralData = (id, data) => {
    const route = generateRoute('api.debitorInvoices.edit.general', {
        debitorInvoiceId: id
    });
    return client
        .post(route, data)
        .then(response => response.data);
};
export const bulkDeleteDebitorInvoices = (ids) => {
    const route = generateRoute('debitorInvoices.bulkDelete');
    return client
        .delete(route, { data: { ids: ids } });
};
export const getNumberOfInvoicesWithPayments = (ids) => {
    const route = generateRoute('debitorInvoices.withPayments');
    return client
        .post(route, { ids: ids })
        .then(response => response.data);
};
export const createManualPayment = (id, data) => {
    const route = generateRoute('api.debitorInvoices.newPayment', {
        debitorInvoice: id
    });
    return client.post(route, data);
};
export const createSplitPayment = (debitorInvoiceId, data) => {
    const route = generateRoute('api.debitorInvoices.splitPayment', {
        debitorInvoiceId
    });
    return client.post(route, data);
};
export const getPayments = (debitorInvoiceId) => {
    const route = generateRoute('api.debitorInvoice.payments', {
        debitorInvoice: debitorInvoiceId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const loadPaymentData = (id) => {
    const route = generateRoute('api.debitorInvoices.manualPaymentData', {
        debitorInvoice: id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const getSendDebitorData = (id) => {
    const route = generateRoute('api.debitorInvoices.sendData', {
        debitorInvoice: id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const sendDebitor = (id, data) => {
    const route = generateRoute('api.debitorInvoices.send', {
        debitorInvoice: id
    });
    return client.post(route, data);
};
