import { createUnitHelper } from '../utils';
export class TypographyVariant {
    #options;
    #extraStyles;
    fontSize;
    lineHeight;
    letterSpacing;
    borderRadius;
    fontFamily;
    fontWeight;
    constructor(options, typography) {
        const { fontSize, lineHeight, letterSpacing = 0, borderRadius, fontWeight, fontFamily, ...styles } = options;
        const unit = createUnitHelper(fontSize, typography.unit.htmlFontSize);
        this.#options = options;
        this.#extraStyles = styles;
        this.borderRadius = unit.create('px', borderRadius || typography.borderRadius.value, typography.outputUnit);
        this.fontSize = unit.create('px', fontSize, typography.outputUnit);
        this.lineHeight = unit.create('em', lineHeight || typography.lineHeight.value, 'em');
        this.letterSpacing = unit.create('%', letterSpacing, 'em');
        this.fontFamily = fontFamily || typography.fontFamily;
        this.fontWeight = (fontWeight && typography.fontWeight[fontWeight]) || typography.fontWeight.$400;
        this.css = this.css.bind(this);
        this.cssSize = this.cssSize.bind(this);
    }
    get options() {
        return this.#options;
    }
    css(props) {
        return {
            ...this.fontStyles,
            ...props
        };
    }
    cssSize(props) {
        return {
            ...this.fontSizeStyles,
            ...props
        };
    }
    get fontSizeStyles() {
        const { fontSize, lineHeight, letterSpacing, fontWeight } = this;
        return {
            fontSize: fontSize.cssValue,
            lineHeight: lineHeight.cssValue,
            letterSpacing: letterSpacing.cssValue,
            fontWeight: fontWeight,
            ...this.#extraStyles,
        };
    }
    get fontStyles() {
        const { fontFamily } = this;
        return {
            fontFamily: fontFamily,
            ...this.fontSizeStyles,
        };
    }
    get extraStyles() {
        return this.#extraStyles;
    }
}
