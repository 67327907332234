import { isString, printValue, toArray } from '@f2w/utils';
export class FormValidationError extends Error {
    constructor(m) {
        super(m);
        this.name = "FormError";
        this.message = `${m}`;
        Object.setPrototypeOf(this, FormValidationError.prototype);
    }
    sayHello() {
        return "hello " + this.message;
    }
}
export class ValidationEvent {
    constructor(m) {
    }
}
let strReg = /\$\{\s*(\w+)\s*\}/g;
export class ValidationError extends Error {
    value;
    path;
    type;
    errors;
    params;
    inner;
    static formatError(message, params) {
        const path = params.label || params.path || 'this';
        if (path !== params.path)
            params = { ...params, path };
        if (typeof message === 'string')
            return message.replace(strReg, (_, key) => printValue(params[key]));
        if (typeof message === 'function')
            return message(params);
        return message;
    }
    static isError(err) {
        return err && err.name === 'ValidationError';
    }
    static createError(error) {
        if (ValidationError.isError(error))
            return error;
        if (isString(error))
            return new ValidationError(error);
    }
    static throwError(error) {
        if ((error = ValidationError.createError(error)))
            throw error;
    }
    constructor(errorOrErrors, value, field, type) {
        super();
        this.name = 'ValidationError';
        this.value = value;
        this.path = field;
        this.type = type;
        this.errors = [];
        this.inner = [];
        toArray(errorOrErrors).forEach((err) => {
            if (ValidationError.isError(err)) {
                this.errors.push(...err.errors);
                this.inner = this.inner.concat(err.inner.length ? err.inner : err);
            }
            else {
                this.errors.push(err);
            }
        });
        this.message =
            this.errors.length > 1
                ? `${this.errors.length} errors occurred`
                : this.errors[0];
        if (Error.captureStackTrace)
            Error.captureStackTrace(this, ValidationError);
    }
}
