import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { Table } from '@f2w/data-table/table-types';
import { EllipsisText } from 'Atoms/Text';
import { DeleteIcon } from 'Components/App/Icons';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { Button } from 'Atoms/Button';
import { Icon } from 'Atoms/Icon';
import { BankAccountTypeFilter } from '../components/BankAccountTypeFilter';
import { IbanType } from '@f2w/form';
import { BankAccountType } from 'Pages/BankAccount/enums';
import { Div } from 'Atoms/Layout';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
const createTextColumn = ({ translationKey, size = 8, formatter, ...rest }) => {
    return {
        Header: translate(translationKey),
        Cell: ({ value }) => _jsx(EllipsisText, { children: formatter ? formatter(value) : value }),
        size,
        useSortBy: {},
        ...rest
    };
};
const BankAccountTableModel = Table.factory()(() => ({
    tableId: 'BankAccount',
    manualControl: false,
    Actions: ({ openCreateForm }) => (_jsxs(Button, { onClick: openCreateForm, title: translate('btn.new'), children: [_jsx(Icon, { icon: 'plus' }), " ", translate('new')] })),
    RowActions: ({ row: bankAccount, openDetailsView, deleteBankAccount, openMergeModal }) => (_jsxs(_Fragment, { children: [_jsxs(ActionsDropdown, { id: bankAccount.id, quickActions: [
                    {
                        icon: "eye",
                        title: translate('bankAccount.view'),
                        onClick: () => {
                            openDetailsView(bankAccount.id);
                        }
                    },
                ], children: [_jsxs(ActionsDropdown.Link, { onClick: () => openMergeModal(bankAccount.original), children: [_jsx(Icon, { size: 20, icon: 'merge' }), " ", _jsx("span", { children: translate('bankAccount.merge') })] }), _jsxs(ActionsDropdown.Link, { href: "#", confirm: translate('bankAccount.delete.confirmation'), onClick: () => deleteBankAccount(bankAccount.original.id), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('bankAccount.remove') })] })] }), bankAccount.original.type === BankAccountType.RENT && (_jsx(Div, { pl: 10, children: _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: 'qr-tooltip', children: bankAccount.original.hasQrIntegration
                            ? translate('bank.qrIntegration.complete')
                            : translate('bank.qrIntegration.incomplete') }), children: _jsx(Icon, { color: bankAccount.original.hasQrIntegration ? undefined : '#D7D8DB', icon: 'qrCode', size: 24 }) }) }))] })),
    columns: {
        $actions: {
            sticky: 'left',
        },
        type: createTextColumn({
            translationKey: 'bank.type',
            useFilters: {
                Filter: BankAccountTypeFilter,
            },
            Cell: ({ value }) => (_jsx(_Fragment, { children: translate(`bank.type.${value.toLowerCase()}`) }))
        }),
        label: createTextColumn({ translationKey: 'bank.label' }),
        iban: createTextColumn({
            minWidth: 220,
            maxWidth: 220,
            translationKey: 'bank.iban',
            formatter: val => IbanType.useFormat(val),
        }),
        qrIban: createTextColumn({
            minWidth: 220,
            maxWidth: 220,
            translationKey: 'bank.qrIban',
            formatter: val => IbanType.useFormat(val),
        }),
        ownerName: createTextColumn({ translationKey: 'bank.ownerName' }),
        longName: createTextColumn({ translationKey: 'bank.longBankName' }),
    },
    useGlobalFilter: {},
    usePagination: {},
}));
export default BankAccountTableModel;
