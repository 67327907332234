import { useRef } from 'react';
import { Upload } from './Upload';
import { useAppContext } from 'Components/Base';
import useForceUpdate from '@restart/hooks/useForceUpdate';
export * from './Upload';
export class UploadManager {
    static use(iProps) {
        const { uploadManager } = useAppContext();
        const props = Upload.createProps(iProps);
        return {
            options: props.options,
            upload: (...files) => uploadManager.current.upload(props, ...files)
        };
    }
    static useProvider(defaultOptions) {
        const uploadRef = useRef(null);
        const forceUpdate = useForceUpdate();
        const api = {
            forceUpdate,
            isVisible: !!uploadRef.current,
            upload: (props, ...files) => {
                const job = uploadRef.current = uploadRef.current || new Upload(props, api);
                job.add(files);
                return job.process(props);
            },
            close: () => {
                const upload = uploadRef.current;
                if (upload) {
                    uploadRef.current = null;
                    upload.clear();
                }
            },
        };
        return [
            api,
            uploadRef.current,
        ];
    }
}
