import { translate } from "Services/Translator";
export var DunningLevel;
(function (DunningLevel) {
    DunningLevel[DunningLevel["ZERO"] = 0] = "ZERO";
    DunningLevel[DunningLevel["ONE"] = 1] = "ONE";
    DunningLevel[DunningLevel["TWO"] = 2] = "TWO";
    DunningLevel[DunningLevel["THREE"] = 3] = "THREE";
})(DunningLevel || (DunningLevel = {}));
export var Dunning;
(function (Dunning) {
    Dunning.transformPersonToDebitor = (data) => data
        .map(({ debitorInvoices: d, ...person }) => d.map((debitor) => ({ ...debitor, person })))
        .flat(2);
    Dunning.reminderOptions = [
        { value: 1, label: translate('reminderProcess.reminder.first') },
        { value: -1, label: translate('reminderProcess.reminder.firstAll') },
        { value: 2, label: translate('reminderProcess.reminder.second') },
        { value: -2, label: translate('reminderProcess.reminder.secondAll') },
        { value: 3, label: translate('reminderProcess.reminder.third') },
        { value: -3, label: translate('reminderProcess.reminder.thirdAll') },
    ];
})(Dunning || (Dunning = {}));
