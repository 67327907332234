import generateRoute from "Services/Router";
import client from "Utils/client";
import moment from "moment";
export const getTasks = async () => {
    const route = generateRoute('task.list.api', {});
    const { data } = await client.get(route);
    return data;
};
export const getTask = async (id) => {
    const route = generateRoute('task.get.api', { id });
    const { data } = await client.get(route);
    return Promise.resolve({
        id: data.id,
        title: data.title,
        description: data.description,
        priority: data.priority,
        dueTo: data.dueTo ? moment(data.dueTo).toDate() : null,
        assignee: data.assignee
    });
};
export const createTask = async (dto) => {
    const route = generateRoute('task.create.api', {});
    return client.post(route, dto);
};
export const updateTask = async (dto) => {
    const route = generateRoute('task.update.api', { id: dto.id });
    return client.patch(route, dto);
};
export const toggleTaskComplete = async (id, complete) => {
    const route = generateRoute('task.toggle-complete.api', {
        id,
        complete: complete ? 1 : 0
    });
    return client.patch(route);
};
export const removeTask = async (id) => {
    const route = generateRoute('task.remove.api', { id });
    return client.delete(route);
};
