import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { ReAssignCustomerDocumentType } from "Pages/CustomerDocuments/types/ReAssignCustomerDocumentType";
import { Button, ButtonToolbar } from "Atoms/Button";
import { translate } from "Services/App";
export const useReAssignCustomerDocumentForm = ({ onSave, reAssignMultipleCustomerDocuments }) => useFormModal({
    name: 're-assign-customer-document-form',
    type: () => new ReAssignCustomerDocumentType(),
    title: translate('customerDocuments.reAssign.title'),
    onLoad: async (ids) => {
        return {
            ids
        };
    },
    onSave: ({ ids, ...rest }) => {
        return reAssignMultipleCustomerDocuments(ids, rest)
            .then(onSave);
    },
    Footer: ({ saveForm, closeForm, form: { loading, values } }) => {
        return _jsxs(ButtonToolbar, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, onClick: async () => await saveForm(true), children: values.propertyId
                        ? translate('customerDocuments.button.assign')
                        : translate('customerDocuments.button.unAssign') })] });
    },
});
