import { useMemo } from 'react';
import { isNum, isObject, isString } from '@f2w/utils';
export function useStickyCss(props) {
    return useMemo(() => {
        let styles;
        if (props === true) {
            styles = {
                zIndex: 2,
                left: 0,
            };
        }
        else if (isObject(props)) {
            styles = {
                zIndex: 30,
                ...props,
            };
            if (!(props.hasOwnProperty('left') || props.hasOwnProperty('right'))) {
                styles.left = 0;
            }
        }
        else if (isNum(props) || isString(props)) {
            styles = {
                zIndex: 30,
                top: props,
                left: 0,
            };
        }
        return styles ? {
            position: 'sticky',
            ...styles,
        } : undefined;
    }, [props]);
}
