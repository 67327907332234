import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, FormProvider, useForm } from "@f2w/form";
import { FlexCol, FlexRow } from "Atoms/Layout";
import { translate } from "Services/Translator";
import React, { useEffect, useState } from "react";
import { ProfileEditType } from "Pages/Profile/Profile/ProfileEditType";
import ProfileService from "Pages/Profile/services/ProfileService";
import { SpinnerNew } from 'Atoms/Spinner';
import { SubmitButton } from "Pages/Profile/components/SubmitButton";
import { dispatchCustomEvent } from "Services/EventManager";
export const EditProfileForm = () => {
    const [profile, setProfile] = useState();
    useEffect(() => {
        ProfileService.getProfile().then((data) => {
            data.image = data.image.payload;
            setProfile(data);
        });
    }, []);
    if (!profile) {
        return _jsx(SpinnerNew, {});
    }
    return (_jsx(InnerForm, { data: profile }));
};
const InnerForm = ({ data }) => {
    const [loading, setLoading] = React.useState(false);
    const profileForm = useForm({
        name: 'profile-edit-form',
        type: () => new ProfileEditType(),
        title: translate('profile'),
        initialValues: data,
        onSave: async (values, formikHelpers, type) => {
            const file = type.children.image.field.getBinaryFile();
            const fieldValue = type.children.image.field.value;
            let image = { state: 'existing' };
            if (file !== null) {
                const uploadResponseData = await ProfileService.uploadProfilePicture(file);
                image = {
                    state: 'new',
                    payload: uploadResponseData.filename,
                };
            }
            else if (fieldValue === null) {
                image = { state: 'empty' };
            }
            const response = await ProfileService.updateProfile({
                ...values,
                image
            });
            if (response.status === 200) {
                const data = {
                    ...response.data,
                    image: response.data?.image?.payload,
                };
                dispatchCustomEvent('fw.profileUpdated', {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    jobTitle: data.jobTitle,
                    image: data.image,
                });
                return Promise.resolve({
                    updateValues: data,
                });
            }
        },
    });
    return (_jsx(FlexCol, { style: { width: 540, marginBottom: 20 }, children: _jsx(FormProvider, { form: profileForm, children: _jsxs(Form, { noValidate: true, children: [profileForm.render(), _jsx(FlexRow, { justifyContent: "flex-end", children: _jsx(SubmitButton, { updateLoadingState: setLoading, form: profileForm, loading: loading }) })] }) }) }));
};
