export var TransactionAssignmentType;
(function (TransactionAssignmentType) {
    TransactionAssignmentType["DEBITOR_INVOICE"] = "debitorInvoice";
    TransactionAssignmentType["CREDITOR_INVOICE"] = "creditorInvoice";
    TransactionAssignmentType["MANUAL"] = "manual";
})(TransactionAssignmentType || (TransactionAssignmentType = {}));
export var TransactionStatusType;
(function (TransactionStatusType) {
    TransactionStatusType["PENDING"] = "PENDING";
    TransactionStatusType["PRE_ASSIGNED"] = "PRE_ASSIGNED";
    TransactionStatusType["ASSIGNED"] = "ASSIGNED";
    TransactionStatusType["IGNORED"] = "IGNORED";
    TransactionStatusType["MANUAL"] = "MANUAL";
})(TransactionStatusType || (TransactionStatusType = {}));
export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus.isEditable = status => [
        TransactionStatusType.PRE_ASSIGNED,
        TransactionStatusType.PENDING,
        TransactionStatusType.IGNORED
    ].includes(status);
    TransactionStatus.isAssignable = status => [
        TransactionStatusType.PRE_ASSIGNED,
        TransactionStatusType.PENDING
    ].includes(status);
})(TransactionStatus || (TransactionStatus = {}));
export var UpdateTransactionDtoAction;
(function (UpdateTransactionDtoAction) {
    UpdateTransactionDtoAction["ASSIGN"] = "assign";
    UpdateTransactionDtoAction["IGNORE"] = "ignore";
    UpdateTransactionDtoAction["UNIGNORE"] = "unignore";
})(UpdateTransactionDtoAction || (UpdateTransactionDtoAction = {}));
export class UpdateTransactionDto {
    action;
    id;
    date;
    description;
    amount;
    status;
    assignmentType;
    property;
    debitorInvoice;
    creditorInvoice;
    creditAccount;
    debitAccount;
}
