import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { SvgSymbol } from 'Components/Chart/components/SvgSymbol';
import { formatPercentage } from 'Services/NumberHelper';
import { adaptEventsOfChild } from 'recharts/lib/util/types';
import { isUndefined, toObject } from 'Utils/types';
const Root = styled.div `
  ${p => p.theme.typography.body.css()};
    // color: ${p => p.theme.palette.black};

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    //flex: 1;

    li {
      padding: 6px 0;
      vertical-align: middle;
    }
  }
`;
const Content = styled.li `
  display: flex;
  flex-direction: column;

  h4 {
    margin: 9px 0;
    ${p => p.theme.typography.pretitle.css()};
  }
`;
const Value = styled.div `
  display: flex;
  //width: 100%;
  > div {
    white-space: nowrap;
    width: 50%;

    &:nth-child(2) {
      text-align: right;
    }
  }
`;
const LegendListContentItem = (props) => {
};
export const LegendListContent = (props = {}) => {
    const { payload, } = props;
    return (_jsxs(Root, { children: [_jsx("p", { children: payload[0].payload['date'] }), _jsx("ul", { children: payload.map((entry, i) => {
                    const { payload: data, name, value, percent, color, unit, dataKey } = entry;
                    const key = dataKey.split('.')[0];
                    const dataGroup = toObject(data[key]);
                    return (_jsxs(Content, { ...adaptEventsOfChild(props, entry, i), children: [_jsx(SvgSymbol, { color: color, size: 24 }), _jsx("h4", { children: name }), _jsxs(Value, { children: [isUndefined(dataGroup.formattedValue)
                                        ? _jsxs("div", { children: [_jsx("span", { children: dataGroup.value }), _jsxs("span", { children: [" ", unit] })] })
                                        : _jsx("div", { children: dataGroup.formattedValue }), !(percent) ? null :
                                        _jsx("div", { children: _jsx("span", { children: formatPercentage(percent, '0%') }) })] })] }, `item-${i}`));
                }) })] }));
};
