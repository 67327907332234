import { UseStyled, useTheme } from "@f2w/theme";
import { css } from "styled-components";
const CheckoutSuccessBase = UseStyled.styled('div', () => ({}));
const Body = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${rem(40)};
        height: ${rem(700)};
        color:  ${palette.success.$500};
        margin: auto;
        max-width: ${rem(800)};
        text-align: center;
    `;
});
const GreenCircle = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        padding: ${rem(89)};
        background:  ${palette.success.$50};
        border-radius: ${rem(168)};
    `;
});
const CheckoutSuccessText = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        ${typography.h6.fontStyles};
        color:  ${palette.neutral.$500};
    `;
});
const CheckoutSuccessStyled = Object.assign(CheckoutSuccessBase, {
    Body,
    GreenCircle,
    CheckoutSuccessText
});
export default CheckoutSuccessStyled;
