import { isObject, isString } from 'Utils/types';
export function parseJson(value) {
    try {
        value = JSON.parse(value);
    }
    catch (e) {
    }
    return value;
}
export function parseJsonObject(value) {
    if (isString(value)) {
        value = parseJson(value);
    }
    return isObject(value) ? value : {};
}
