import { jsx as _jsx } from "react/jsx-runtime";
import { Table } from "@f2w/data-table/table-types";
import { useCallback, useMemo, useState } from "react";
import { translate } from "Services/Translator";
import { formatAmount } from "Services/NumberHelper";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { Select } from "Atoms/Select";
const BaseTable = Table.factory()(() => ({
    useDropzone: {
        onDropAccepted: (files, { addFiles }) => addFiles(files),
        onDropRejected: (files, { onFilesRejected }) => onFilesRejected(),
        onDrop: () => { },
        dropzoneOptions: {
            accept: [
                'image/*',
                'application/pdf'
            ],
            maxSize: 5242880,
            minSize: 0
        }
    },
    columns: {
        fileName: {
            Header: translate('creditor.upload.fileName')
        },
        type: {
            Header: translate('creditor.upload.type')
        },
        size: {
            Header: translate('creditor.upload.size')
        },
        propertyId: {
            Header: translate('creditor.upload.property'),
            Cell: ({ row: { index }, value, properties, setProperty }) => {
                return (_jsx(Select, { options: properties, value: value, isSearchable: true, isClearable: true, menuPortalTarget: document.body, onChange: (newValue) => {
                        setProperty(index, newValue?.value);
                    } }));
            }
        }
    }
}));
export const CreditorUploadDataTable = Object.assign(BaseTable, {
    use({ onFilesRejected }) {
        const portfolio = usePortfolio();
        const [data, setData] = useState([]);
        const addFiles = useCallback((files) => {
            const newRows = files.map(file => {
                const splitName = file.name.split('.');
                return ({
                    file: file,
                    fileName: file.name,
                    size: `${formatAmount(file.size / 1000)} KB`,
                    type: splitName[splitName.length - 1].toUpperCase()
                });
            });
            setData(oldData => ([
                ...oldData,
                ...newRows
            ]));
        }, []);
        const setProperty = useCallback((index, propertyId) => {
            setData(oldData => {
                const newData = [...oldData];
                newData[index].propertyId = propertyId;
                return newData;
            });
        }, []);
        const properties = portfolio.getProperties();
        const propertyOptions = useMemo(() => {
            return properties.map(property => ({
                label: property.name,
                value: property.id
            }));
        }, [properties]);
        return CreditorUploadDataTable.useCreateTable({
            data,
            addFiles,
            properties: propertyOptions,
            setProperty,
            onFilesRejected
        });
    }
});
