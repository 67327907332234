import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, Tbody, Td, Th, Thead, Tr } from "Atoms/Table";
import { translate } from "Services/Translator";
import { EmailType, PhoneType } from "@f2w/form";
import { Badge } from "Atoms/Badge";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
import { FlexCol, FlexRow } from "Atoms/Layout";
import { Icon } from "Atoms/Icon";
import { OverlayTrigger, Tooltip } from "Atoms/Overlay";
import moment from "moment/moment";
import { CustomerType } from "Pages/Administration/Customers/enums";
import { getGlobalContext } from "Services/GlobalContext";
import { NavigationSettings } from "Services/NavigationHelper";
const UserBadge = ({ user }) => {
    if (user.emailVerifiedAt === null) {
        return _jsx(Badge, { "$color": 'warning', children: translate('customer.user.notVerified') });
    }
    return user.disabled
        ? _jsx(Badge, { "$color": 'danger', children: translate('customer.user.disabled') })
        : _jsx(Badge, { "$color": 'success', children: translate('customer.user.active') });
};
export const CustomerUserTable = ({ users, customerType, enableCustomerUser, disableCustomerUser, editCustomerUser, resendCustomerUserInvitationEmail, }) => {
    const { roles } = getGlobalContext();
    const settings = NavigationSettings.use();
    return (_jsxs(Table, { variant: "content", borderRadius: false, border: false, rowDivider: false, children: [_jsxs(Thead, { children: [_jsx(Th, {}), _jsx(Th, { children: translate('customer.user.id') }), _jsx(Th, { children: translate('customer.user.name') }), _jsx(Th, { children: translate('customer.user.email') }), _jsx(Th, { children: translate('customer.user.phoneNumber') }), _jsx(Th, { children: translate('customer.user.userRoles') }), _jsx(Th, { children: translate('customer.user.status') })] }), _jsx(Tbody, { children: users.map((user) => (_jsxs(Tr, { children: [_jsx(Td, { children: _jsx(ActionsDropdown, { quickActions: [
                                    {
                                        icon: user.disabled ? 'user-check' : 'user-x',
                                        title: user.disabled
                                            ? translate('customer.user.enable')
                                            : translate('customer.user.disable'),
                                        onClick: () => {
                                            user.disabled
                                                ? enableCustomerUser(user.id)
                                                : disableCustomerUser(user.id);
                                        }
                                    },
                                    {
                                        icon: 'edit',
                                        title: translate('customer.user.edit'),
                                        onClick: () => editCustomerUser(user.id)
                                    },
                                    {
                                        icon: 'impersonate',
                                        disabled: !user.hasGrantedImpersonation && !roles.includes('ROLE_SUPER_ADMIN'),
                                        href: user.hasGrantedImpersonation || roles.includes('ROLE_SUPER_ADMIN') ? `/?_switch_user=${encodeURIComponent(user.email)}` : undefined,
                                        target: '_self',
                                        title: translate('customer.user.impersonate'),
                                        onClick: () => {
                                            settings.setCollapsed(false);
                                        }
                                    },
                                    {
                                        icon: 'mail',
                                        title: translate('customer.user.resendInvitationEmail'),
                                        onClick: () => resendCustomerUserInvitationEmail(user.id),
                                        hidden: !(user.emailVerifiedAt === null && customerType !== CustomerType.PENDING),
                                    },
                                ] }) }), _jsx(Td, { children: user.id }), _jsxs(Td, { children: [user.firstName, " ", user.lastName] }), _jsx(Td, { children: EmailType.format(user.email) }), _jsx(Td, { children: user.phoneNumber ? PhoneType.format(user.phoneNumber) : translate('notAvailable') }), _jsx(Td, { children: user.roles.join(', ') }), _jsx(Td, { children: _jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsx(OverlayTrigger, { placement: 'top-end', overlay: _jsx(Tooltip, { id: `tooltip-user-${user.id}`, children: _jsxs(FlexCol, { justifyFlexStart: true, children: [_jsxs(FlexRow, { children: [translate('customer.user.tooltip.createdAt'), ": ", moment(user.createdAt).format('DD.MM.YYYY HH:mm')] }), _jsxs(FlexRow, { children: [translate('customer.user.tooltip.emailVerifiedAt'), ": ", user.emailVerifiedAt !== null
                                                                ? moment(user.emailVerifiedAt).format('DD.MM.YYYY HH:mm')
                                                                : translate('notAvailable')] }), _jsxs(FlexRow, { children: [translate('customer.user.tooltip.lastLoginAt'), ": ", user.lastLoginAt !== null
                                                                ? moment(user.lastLoginAt).format('DD.MM.YYYY HH:mm')
                                                                : translate('notAvailable')] }), _jsxs(FlexRow, { children: [translate('customer.user.tooltip.hubspotId'), ": ", user.hubspotId ?? translate('notAvailable')] })] }) }), children: _jsx(Icon, { icon: 'info' }) }), _jsx(UserBadge, { user: user })] }) })] }, user.id))) })] }));
};
