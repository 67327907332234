import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteIcon, EditIcon } from "Components/App/Icons";
import { translate } from "Services/Translator";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
const Actions = ({ cell, row, refresh, onEditDamage, onDeleteDamage }) => {
    const value = cell.value;
    return (_jsxs(ActionsDropdown, { id: row.id, children: [_jsxs(ActionsDropdown.Link, { onClick: (e) => {
                    e.preventDefault();
                    onEditDamage(value);
                }, children: [_jsx(EditIcon, {}), " ", _jsx("span", { children: translate('damage.edit') })] }), _jsxs(ActionsDropdown.Link, { onClick: (e) => {
                    e.preventDefault();
                    onDeleteDamage(value, refresh);
                }, children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('damage.delete') })] })] }));
};
export default Actions;
