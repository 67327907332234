import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Button } from 'Components/Atoms/Button';
import { Content, Header, Section } from 'Atoms/Layout/components';
import { ButtonToolbar, Title } from 'Components/Atoms';
export function FormView({ view, view: { form }, formChildren, }) {
    return (_jsxs(Content, { children: [_jsxs(FormHeader, { sticky: 0, children: [_jsx(Title, { as: 'h4', children: view.activeGroup?.title }), _jsxs(ButtonToolbar, { gap: 5, children: [_jsx(Button.Cancel, { onClick: () => view.closeForm() }), _jsx(Button.Save, { loading: form.loading, onClick: () => view.saveForm() })] })] }), _jsxs(Section, { children: [form?.status?.error && _jsx(FormError, { dangerouslySetInnerHTML: { __html: form?.status?.error } }), formChildren] })] }));
}
export function FormTemplate({ title, children, form, onClose, onSave, onCloseLabel, onSaveLabel }) {
    return (_jsxs(Content, { children: [_jsxs(FormHeader, { sticky: 0, children: [_jsx(Title, { as: 'h4', children: title }), _jsxs(ButtonToolbar, { gap: 5, children: [onClose && _jsx(Button.Cancel, { onClick: onClose, label: onCloseLabel }), onSave && _jsx(Button.Save, { loading: form.loading, onClick: onSave, label: onSaveLabel })] })] }), _jsxs(Section, { children: [form?.status?.error && _jsx(FormError, { dangerouslySetInnerHTML: { __html: form.status.error } }), children] })] }));
}
export { FormView as DefaultFormTemplate };
const FormError = styled.div `
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 8px;
  ${p => p.theme.typography.medium.fontSizeStyles};
  color: ${p => p.theme.palette.error.$600};
  background-color: ${p => p.theme.palette.error.$50};
  border: 1px solid ${p => p.theme.palette.error.$200};
`;
const FormHeader = styled(Header) `
    && {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${p => p.theme.palette.dark.$100};
      min-height: 70px;
      padding: 16px var(--fw-padding-x);
      margin-bottom: var(--fw-padding-y);

      ${Title} {
        ${p => p.theme.typography.body.fontStyles};
        font-weight: 500;
      }
    }
`;
