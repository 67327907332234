import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useRef } from 'react';
import { getUuid } from '@f2w/utils';
const Id = createContext(() => {
    throw new TypeError('Please wrap your application with IdProvider');
});
export const useIdGetter = (prefix = 'id') => {
    const ref = useRef();
    if (!ref.current) {
        const me = { id: 0, get: () => `${prefix}-${me.id++}` };
        ref.current = me;
    }
    return ref.current.get;
};
export const IdProvider = ({ prefix = 'id', children, }) => {
    const get = useIdGetter(prefix);
    return _jsx(Id.Provider, { value: get, children: children });
};
export const useUuid = (customId) => useMemo(() => (customId || getUuid()), [customId]);
export const useId = () => {
    const getter = useContext(Id);
    const ref = useRef();
    if (!ref.current)
        ref.current = getter();
    return ref.current;
};
export const useGetId = () => {
    const getter = useContext(Id);
    const base = useRef();
    if (!base.current)
        base.current = getter();
    return useIdGetter(base.current);
};
