import { jsx as _jsx } from "react/jsx-runtime";
import { AbstractType, } from './AbstractType';
import * as Schema from '../schema';
import { Div } from 'Components/Atoms';
import classnames from 'classnames';
import { isObject } from 'Utils/types';
import { ObjectField } from '../form';
import { FormTheme } from '../theme';
export class AbstractObjectType extends AbstractType {
    children = Object.create(null);
    constructor({ inherit, ...options } = {}, parent) {
        super({
            inheritData: inherit ?? false,
            inheritView: inherit ?? false,
            ...options,
        }, parent, Schema.object());
    }
    _initFields(fields) {
        this.children = fields;
        this.mutate((schema) => {
            const schemaFields = {};
            for (let name in fields) {
                const child = fields[name];
                if (child.isInherited()) {
                    Object.assign(schemaFields, child.schema.fields);
                }
                else {
                    schemaFields[name] = child.schema;
                }
            }
            schema.shape(schemaFields);
        });
    }
    _createField(props) {
        return new ObjectField(this, props);
    }
    _createInternal(props) {
        super._createInternal(props);
        this.forChildren((child, name) => child._createInternal({ name, parent: this }));
    }
    isObject() {
        return true;
    }
    setFormik(form) {
        if (this._context) {
            this._context.form = form;
        }
    }
    closeForm() {
        if (this._context?.form) {
            this.field.reset();
            this._context.form = null;
            return true;
        }
    }
    isDirty() {
        return !!this.getFormik()?.dirty;
    }
    cast(value, ignoreDefaults) {
        if (!isObject(value)) {
            if (this.isNullable)
                return null;
            value = undefined;
        }
        const resolved = {
            ...this.getDefaultValue(),
            ...value
        };
        for (let [key, child] of Object.entries(this.children)) {
            if (child.isInherited()) {
                Object.assign(resolved, child.cast(resolved, ignoreDefaults));
            }
            else {
                resolved[key] = child.cast(resolved[key], ignoreDefaults);
            }
        }
        return resolved;
    }
    getDefaultValue() {
        return this.schema.getDefault();
    }
    getInitialData() {
        return this._context.data;
    }
    getResolvedValue() {
        return this.pickValue();
    }
    pickValue(...args) {
        let data = {};
        const fields = args.length === 0 ? Object.keys(this.children) : args.flat(4);
        fields.forEach(key => {
            const child = this.getChild(key);
            if (child instanceof AbstractObjectType && child.options.inheritData) {
                Object.assign(data, child.getResolvedValue());
            }
            else {
                data[key] = child?.getResolvedValue();
            }
        });
        if (this.options.transform) {
            data = this.options.transform(data);
        }
        return data;
    }
    get $() {
        return this.children;
    }
    getChild(name) {
        if (this.children[name]) {
            return this.children[name];
        }
        console.warn(`Child with name ${name} not found. Available children are: ${Object.keys(this.children).join(', ')}`);
    }
    renderChild(name, props) {
        return this.getChild(name)?.render(props);
    }
    renderChildren(names) {
        return (names ? names : Object.keys(this.children))
            .flat(3)
            .map(name => this.renderChild(name));
    }
    _render(props) {
        if (this.options.inheritView || this.isRoot) {
            return this._renderWidget();
        }
        return super._render({
            ...props,
            as: FormTheme.Group.Row,
            variant: this.options.widget
        });
    }
    _renderLabel(props) {
        return super._renderLabel({ ...props, as: FormTheme.Group.Label });
    }
    _renderError(props) {
        return super._renderError({ ...props, as: FormTheme.Group.Error });
    }
    _renderWidget(iProps) {
        const _field = this.field;
        const props = {
            field: _field,
            id: `${_field.id}--widget`,
            className: classnames(iProps?.className),
            style: iProps?.style,
            children: this.renderChildren(),
        };
        return _jsx(Div, { ...props }, props.id);
    }
    forChildren(cb) {
        const children = this.children;
        for (const key in this.children) {
            cb(children[key], key);
        }
    }
    mapChildren(getValue) {
        const data = {};
        this.forChildren((c, name) => {
            const value = getValue(c);
            if (value !== undefined) {
                if (c instanceof AbstractObjectType && c.options.inheritData) {
                    value && Object.assign(data, value);
                }
                else
                    data[name] = value;
            }
        });
        return data;
    }
    createOld({ context = {
        get data() {
            return this.form?.initialValues ?? {};
        },
        form: null
    }, ...props }) {
        this._createInternal({ context, ...props });
        return this;
    }
}
