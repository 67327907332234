import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { useEventCallback } from '@restart/hooks';
export const Form = forwardRef(({ formValue, ...props }, ref) => {
    const { action, ...rest } = props;
    const _action = action || '#';
    const handleSubmit = useEventCallback((event) => {
        formValue?.submit();
    });
    const handleReset = useEventCallback((event) => {
        formValue?.reset();
    });
    return (_jsx("form", { ref: ref, noValidate: true, onSubmit: handleSubmit, onReset: handleReset, action: _action, ...rest }));
});
Form.displayName = 'Form';
