import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { Icon } from 'Atoms/Icon';
import { useTheme } from '@f2w/theme';
import axios from 'axios';
import generateRoute from 'Services/Router';
export const ActionStepList = styled.div(() => {
    return css `
        flex: 1;
    `;
});
const ActionStepWraper = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
    margin-bottom: ${rem(16)};
    display: flex;
    gap:${rem(24)};
    align-items: center;
    `;
});
const getActionStateColors = (palette) => ({
    "pending": palette.dark.$100,
    "active": palette.primary.$500,
    "completed": palette.success.$500
});
const ActionStepState = ({ stepNumber, state }) => (_jsx(ActionStepStateWrapper, { state: state, children: _jsx(Icon, { icon: "check" }) }));
const ActionStepStateWrapper = styled.div(({ state }) => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${getActionStateColors(palette)[state]};
        color: ${palette.white};
        border-radius: 50%;
        width: ${rem(26)};
        height: ${rem(26)};
        min-width: ${rem(26)};
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        padding: ${rem(5)};
    `;
});
const ActionStepName = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        a {
            color: currentColor;
        }
    `;
});
export const ActionStep = ({ stepNumber, state, url = "#", children, taskCompletion }) => {
    return (_jsxs(ActionStepWraper, { children: [_jsx(ActionStepState, { state: state, stepNumber: stepNumber }), _jsx(ActionStepName, { children: _jsx("a", { href: url, target: "_blank", onClick: () => {
                        axios.post(generateRoute('api.userOnboarding.startTask', { task: taskCompletion.task }));
                    }, children: children }) })] }));
};
