export const options = [
    { value: "0.0", label: "0" },
    { value: "1.0", label: "1" },
    { value: "1.5", label: "1.5" },
    { value: "2.0", label: "2" },
    { value: "2.5", label: "2.5" },
    { value: "3.0", label: "3" },
    { value: "3.5", label: "3.5" },
    { value: "4.0", label: "4" },
    { value: "4.5", label: "4.5" },
    { value: "5.0", label: "5" },
    { value: "5.5", label: "5.5" },
    { value: "6.0", label: "6" },
    { value: "6.5", label: "6.5" },
    { value: "7.0", label: "7" },
    { value: "7.5", label: "7.5" },
    { value: "8.0", label: "8" },
    { value: "8.5", label: "8.5" },
    { value: "9.0", label: "9" },
    { value: "9.5", label: "9.5" },
    { value: "10.0", label: "10" },
    { value: "10.5", label: "10.5" },
    { value: "11.0", label: "11" },
    { value: "11.5", label: "11.5" },
    { value: "12.0", label: "12" },
    { value: "12.5", label: "12.5" },
    { value: "13.0", label: "13" },
    { value: "13.5", label: "13.5" },
    { value: "14.0", label: "14" },
    { value: "14.5", label: "14.5" },
    { value: "15.0", label: "15" },
    { value: "15.5", label: "15.5" },
    { value: "16.0", label: "16" },
    { value: "16.5", label: "16.5" },
    { value: "17.0", label: "17" },
    { value: "17.5", label: "17.5" },
    { value: "18.0", label: "18" },
    { value: "18.5", label: "18.5" },
    { value: "19.0", label: "19" },
    { value: "19.5", label: "19.5" },
    { value: "20.0", label: "20" },
];
