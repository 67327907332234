import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { dump } from 'Utils/logger';
import { Box, Button, Col, FlexCol, FlexRow, JsonCode, JsonTree, Nav, Row, Tab } from 'Atoms/index';
import { FormProvider } from '../FormContext';
import { Form as FormRoot } from 'formik';
export const FormDebugInfo = ({ form, compact, ...props }) => !compact
    ? _jsx(FormDebugTabs, { form: form, ...props })
    : _jsxs(FlexCol, { b: "solid 1px #ddd", my: 10, p: 10, w: '100%', gap: 20, style: { overflow: 'auto' }, children: [_jsx(JsonCode, { title: "Values", value: dump(form.values, false) }), _jsx(JsonCode, { title: "Errors", value: form.errors, indent: 2 }), _jsx(JsonCode, { title: "Touched", value: form.touched, indent: 2 })] });
export const FormDebugTabs = ({ form, additionalTabs, styles = {
    b: 'solid 1px #ddd',
    maxh: '100vh',
} }) => {
    const formDump = form.getType().field.__dump();
    const resolvedValue = form.getType().getResolvedValue();
    return (_jsx(DebugTabs, { styles: styles, tabs: [
            ...(additionalTabs || []),
            {
                key: 'formik-dump',
                name: 'Dump',
                content: () => _jsxs(_Fragment, { children: [_jsx(JsonCode, { title: "Values", value: form.values, indent: 2, undefinedAsNull: true }), _jsx(JsonCode, { title: "Initial values", value: form.initialValues, indent: 2, undefinedAsNull: true }), _jsx(JsonCode, { title: "Errors", value: form.errors, indent: 2, undefinedAsNull: true }), _jsx(JsonCode, { title: "Touched", value: form.touched, indent: 2, undefinedAsNull: true })] })
            },
            {
                key: 'form',
                name: 'Fields',
                content: () => _jsx(JsonTree, { dataObject: formDump, expanded: true })
            },
            {
                key: 'resolved',
                name: 'Resolved',
                content: () => _jsx(_Fragment, { children: _jsx(JsonCode, { title: "Resolved", value: dump(resolvedValue, false), indent: 2 }) })
            },
            {
                key: 'formik',
                name: 'Json Dump',
                content: () => _jsx(JsonTree, { dataObject: {
                        values: form.values,
                        initialValues: form.initialValues,
                        resolved: resolvedValue,
                        errors: form.errors,
                        touched: form.touched,
                    }, expanded: true })
            },
        ] }));
};
export const DebugTabs = ({ tabs, styles = {
    b: 'solid 1px #ddd',
    maxh: '100vh',
} }) => {
    const { ids, defaultKey } = useMemo(() => {
        const cachedKey = localStorage.getItem('__fw:form-debug-activeKey');
        const ids = tabs.map((tab, i) => `rtab-${i}`);
        let defaultKey = cachedKey && ids.includes(cachedKey)
            ? cachedKey
            : ids[tabs.findIndex(tab => tab.isDefault) ?? 0] ?? ids[0];
        return {
            ids,
            defaultKey,
        };
    }, []);
    return (_jsx(Box, { w: '100%', ...styles, style: { overflow: 'auto', position: 'sticky', top: 0 }, children: _jsxs(Tab.Container, { id: 'form-debug', defaultActiveKey: defaultKey, onSelect: (val) => {
                localStorage.setItem('__fw:form-debug-activeKey', val);
            }, children: [_jsx(Nav, { variant: "tabs", position: 'top', className: "flex-column", sticky: 'top', style: {
                        background: 'white',
                        paddingTop: '4px'
                    }, children: tabs.map((tab, i) => _jsx(Nav.Link, { eventKey: ids[i], children: tab.name }, `tab-link-${i}`)) }), _jsx(Tab.Content, { style: { padding: '20px' }, children: tabs.map((tab, i) => _jsx(Tab.Pane, { eventKey: ids[i], children: tab.content() }, `tab-content-${i}`)) })] }) }));
};
export const FormDebugLayout = ({ form, children }) => (_jsx(FormProvider, { form: form, children: _jsx(FormRoot, { noValidate: true, children: _jsxs(Row, { children: [_jsx(Col, { sm: 6, children: _jsx(FormDebugInfo, { form: form }) }), _jsxs(Col, { sm: 6, children: [children, _jsx(FlexRow, { justifyContent: "space-between", children: _jsxs(FlexRow, { gap: 20, ml: "auto", children: [_jsx(Button, { outline: true, color: 'error', type: "reset", label: 'Reset' }), _jsx(Button, { label: 'Save', onClick: () => {
                                            form.resetForm({ ...form.values });
                                        } }), _jsx(Button, { type: "submit", label: 'Submit' })] }) })] })] }) }) }));
