import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import AccountingRecordServices from "./services/accountingRecordServices";
import { useAccountingRecordSheetTable } from "./hooks/useAccountingRecordSheetTable";
import useAccountingRecordForm from "Pages/Accounting/AccountingRecord/hooks/useAccountingRecordForm";
import { FormModal } from "@f2w/form";
import { useOpeningBalanceForm } from "Pages/Accounting/OpeningBalance/OpeningBalanceForm";
import { subscribeToCustomEvent, unsubscribeFromCustomEvent } from "Services/EventManager";
export const AccountingRecordSheetPage = ({ propertyId, fiscalYearId, initialDateRange, initialFinancialAccountId, accountingRecordServices, onDateFilterChange, }) => {
    const incomeForm = useOpeningBalanceForm({
        accountingRecordServices: AccountingRecordServices,
        onSaved: () => { onSave(); },
    });
    const { instance } = useAccountingRecordSheetTable(propertyId, fiscalYearId, initialFinancialAccountId, initialDateRange, accountingRecordServices, (id) => { accountingRecordForm.openForm(id); }, incomeForm);
    const accountingRecordForm = useAccountingRecordForm({
        propertyId: 1,
        onSave: () => onSave(),
        createAccountingRecord: AccountingRecordServices.createAccountingRecord,
        updateAccountingRecord: AccountingRecordServices.updateAccountingRecord,
        getAccountingRecordById: accountingRecordServices.getAccountingRecordById,
    });
    const dateFilterValue = instance.state.filters.find(({ id }) => id === 'date')?.value;
    useEffect(() => {
        onDateFilterChange(dateFilterValue);
    }, [dateFilterValue]);
    const onSave = React.useCallback(() => {
        instance.refresh();
    }, [instance]);
    const updateOnOpeningBalanceUpdated = (e) => {
        instance.refresh();
    };
    useEffect(() => {
        subscribeToCustomEvent('fw.openingBalanceUpdated', updateOnOpeningBalanceUpdated);
        return () => {
            unsubscribeFromCustomEvent('fw.openingBalanceUpdated', updateOnOpeningBalanceUpdated);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { title: null, content: null, instance: instance, rootStyles: { padding: 0 } }), _jsx(FormModal, { modal: accountingRecordForm }), _jsx(FormModal, { modal: incomeForm, size: { maxWidth: '455px' } })] }));
};
