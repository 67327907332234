import generateRoute from "Services/Router";
import client from "Utils/client";
import axios from 'axios';
const getProfile = () => {
    const route = generateRoute('api.profile.get');
    return client
        .get(route)
        .then(response => response.data);
};
const updateProfile = (data) => {
    return client.put(generateRoute('api.profile.update'), data);
};
const uploadProfilePicture = (file) => {
    const formData = new FormData();
    formData.append('avatar', file);
    return axios.post('/_uploader/gallery/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then(response => response.data);
};
const changePassword = (data) => {
    data.newPassword = data.newPassword.password;
    return client.post(generateRoute('api.profile.changePassword'), data);
};
export default {
    getProfile,
    updateProfile,
    uploadProfilePicture,
    changePassword,
};
