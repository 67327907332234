import { AbstractFormType, MoneyType } from '@f2w/form';
import { translate } from 'Services/Translator';
export class OpeningBalanceType extends AbstractFormType {
    buildFields() {
        return {
            value: new MoneyType({
                label: translate('accounting.account.openingBalance.value'),
                required: true,
            }),
        };
    }
}
