import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { formatMoney } from "Services/NumberHelper";
import { translate } from "Services/Translator";
import CheckoutSubscriptionCartStyled from './CheckoutSubscriptionCartStyled';
import { calcExtraLimits, calcTotalIncrements, getDefaultPriceIncrements, calcSubtotalPrice, calcTaxes, getVatPercent } from "Services/SubscriptionCalculatorService";
const { SectionTitle, PlanRow, MoneyStyle, MoneyTotalStyle, QuantityPlanNameSection, QuantityStyle } = CheckoutSubscriptionCartStyled;
const SubscriptionPlanExtras = ({ increments }) => _jsx(_Fragment, { children: Object.keys(increments).map((key) => {
        let { quantity, price } = increments[key];
        if (key === 'dmsLimitInMBs') {
            quantity /= 1000;
            key = 'dmsLimitInGBs';
        }
        const name = translate('subscriptions.' + key);
        if (quantity <= 0) {
            return null;
        }
        return (_jsxs(PlanRow, { children: [_jsxs(QuantityPlanNameSection, { children: [_jsxs(QuantityStyle, { children: ["+", quantity] }), " ", name, " x 12 ", translate('months')] }), _jsx(MoneyStyle, { children: formatMoney(price) })] }, key));
    }) });
const CheckoutSubscriptionCart = ({ subscriptionPlan, limits }) => {
    const extraLimits = calcExtraLimits(subscriptionPlan.limits, limits);
    const totalPriceIncrements = calcTotalIncrements(extraLimits, getDefaultPriceIncrements());
    const subTotal = calcSubtotalPrice(subscriptionPlan.price, totalPriceIncrements);
    const vatPercent = getVatPercent();
    const taxes = calcTaxes(subTotal);
    const totalPrice = subTotal + taxes;
    return (_jsxs("div", { children: [_jsx(SectionTitle, { children: translate('subscriptions.yourOrder') }), _jsxs("div", { children: [_jsxs(PlanRow, { children: [_jsxs("div", { children: [subscriptionPlan.name, " x 12 ", translate('months')] }), _jsx(MoneyStyle, { children: formatMoney(subscriptionPlan.price) })] }), _jsx(SubscriptionPlanExtras, { increments: totalPriceIncrements }), _jsxs(PlanRow, { children: [_jsxs("div", { children: [translate('subscriptions.vat'), " (", vatPercent, "%)"] }), _jsx(MoneyStyle, { children: formatMoney(taxes) })] })] }), _jsx("hr", {}), _jsxs(PlanRow, { children: [_jsx("div", { children: translate('subscriptions.total') }), _jsx(MoneyTotalStyle, { children: formatMoney(totalPrice) })] })] }));
};
export default CheckoutSubscriptionCart;
