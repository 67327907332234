import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
var DataType;
(function (DataType) {
    DataType["ARRAY"] = "array";
    DataType["OBJECT"] = "object";
    DataType["STRING"] = "string";
    DataType["NUMBER"] = "number";
    DataType["BOOLEAN"] = "boolean";
    DataType["NULL"] = "null";
    DataType["UNDEFINED"] = "undefined";
})(DataType || (DataType = {}));
var ExpandState;
(function (ExpandState) {
    ExpandState["OPENED"] = "opened";
    ExpandState["CLOSED"] = "closed";
})(ExpandState || (ExpandState = {}));
;
const TreeContext = createContext({
    maxDepth: 0,
    expandedTreeState: ExpandState.CLOSED,
});
export const JsonTree = (props) => {
    const { expanded, depth, currentDepth, dataKey, objectKey, } = props;
    const { expandedTreeState, maxDepth } = useContext(TreeContext);
    const initExpandedState = expanded || expandedTreeState === ExpandState.OPENED
        ? ExpandState.OPENED
        : ExpandState.CLOSED;
    const [expandedState, setExpandedState] = useState(initExpandedState);
    const data = getData(props);
    const dataType = getDataType(data);
    const dataValue = getDataValue(data, dataType);
    const computedKey = dataKey
        ? `${dataKey}`
        : `${objectKey}`;
    const onHandleClick = (e) => {
        e.preventDefault();
        const newState = expandedState === ExpandState.OPENED
            ? ExpandState.CLOSED
            : ExpandState.OPENED;
        setExpandedState(newState);
    };
    const dataTypeClass = [
        'data__type',
        `data__type--${dataType}`,
        `data__type--${expandedState}`,
    ].join(' ');
    const dataHandleClass = dataType === DataType.ARRAY || dataType === DataType.OBJECT
        ? ['data__list-handle', `data__list-handle--${expandedState}`].join(' ')
        : 'data__no-list';
    const dataListItemsClass = [
        'data__list-items',
        `data__list-items--${expandedState}`,
    ].join(' ');
    const dataValueClass = () => {
        const dataValueClass = [
            'data__value',
        ];
        if (dataType !== DataType.OBJECT && dataType !== DataType.ARRAY) {
            dataValueClass.push('data__value--primitive', `data__value--${dataType}`);
        }
        if (dataType === DataType.ARRAY) {
            dataValueClass.push('data__value--array');
        }
        if (dataType === DataType.OBJECT) {
            dataValueClass.push('data__value--object');
        }
        return dataValueClass.join(' ');
    };
    const TopLevelList = useMemo(() => (_jsx(TreeContext.Provider, { value: {
            expandedTreeState: expanded
                ? ExpandState.OPENED
                : ExpandState.CLOSED,
            maxDepth: depth,
        }, children: dataType === DataType.OBJECT || dataType === DataType.ARRAY
            ? (_jsx("div", { className: dataListItemsClass, children: getDataList(data, dataType, 1, computedKey) }))
            : null })), [computedKey, data, dataListItemsClass, dataType, depth, expanded]);
    const NestedList = useMemo(() => (dataType === DataType.OBJECT || dataType === DataType.ARRAY
        ? (_jsx("div", { className: dataListItemsClass, children: maxDepth !== 0 && currentDepth > maxDepth
                ? (_jsx("div", { children: "..." }))
                : getDataList(data, dataType, currentDepth, computedKey) }))
        : null), [computedKey, currentDepth, data, dataListItemsClass, dataType, maxDepth]);
    return (_jsxs(Root, { className: dataTypeClass, children: [_jsxs("span", { className: dataHandleClass, onClick: onHandleClick, children: [_jsx("span", { className: "data__key", children: objectKey }), _jsx("span", { className: dataValueClass(), children: dataValue })] }), currentDepth === undefined ? _jsx(_Fragment, { children: TopLevelList }) : _jsx(_Fragment, { children: NestedList })] }));
};
JsonTree.defaultProps = {
    dataObject: null,
    dataString: undefined,
    objectKey: 'DATA',
    expanded: undefined,
    depth: 0,
    currentDepth: undefined,
    dataKey: undefined,
};
const getData = (props) => {
    const { dataObject, dataString } = props;
    if (typeof dataObject === 'boolean')
        return dataObject;
    if (typeof dataObject === 'number')
        return dataObject;
    if (dataObject) {
        return dataObject;
    }
    if (dataString) {
        try {
            return JSON.parse(dataString);
        }
        catch (error) {
            return `ERROR: ${error.message}`;
        }
    }
    return null;
};
const getDataType = (data) => {
    if (data === null)
        return DataType.NULL;
    if (data != null
        && !Array.isArray(data)
        && typeof data === 'object')
        return DataType.OBJECT;
    if (Array.isArray(data))
        return DataType.ARRAY;
    if (typeof data === 'string')
        return DataType.STRING;
    if (typeof data === 'number')
        return DataType.NUMBER;
    if (typeof data === 'boolean')
        return DataType.BOOLEAN;
    return DataType.UNDEFINED;
};
const getDataValue = (data, dataType) => {
    switch (dataType) {
        case DataType.BOOLEAN: {
            return `${data}`;
        }
        case DataType.NULL: {
            return 'null';
        }
        case DataType.ARRAY: {
            const dataArray = data;
            return `Array[${dataArray.length}]`;
        }
        case DataType.OBJECT: {
            const dataObject = data;
            const objectLength = Object.keys(dataObject).length;
            return objectLength
                ? 'Object'
                : 'Object (empty)';
        }
        case DataType.UNDEFINED: {
            return 'undefined';
        }
        default:
            return data;
    }
};
const getDataList = (data, dataType, currentDepth, dataKey) => {
    const computedCurrentDepth = currentDepth || currentDepth === 0
        ? currentDepth + 1
        : 0;
    switch (dataType) {
        case DataType.OBJECT: {
            const dataObject = data;
            return Object.entries(dataObject)
                .map(([key, value]) => {
                const computedKey = `${dataKey}-${key}-${computedCurrentDepth}`;
                return (_jsx(JsonTree, { dataObject: value, objectKey: key, currentDepth: computedCurrentDepth, dataKey: computedKey }, computedKey));
            });
        }
        case DataType.ARRAY: {
            const dataArray = data;
            return dataArray.map((value, idx) => {
                const computedKey = `${dataKey}-${idx}-${computedCurrentDepth}`;
                return (_jsx(JsonTree, { dataObject: value, objectKey: idx, currentDepth: computedCurrentDepth, dataKey: computedKey }, computedKey));
            });
        }
        default:
            return null;
    }
};
const Root = styled.div `
  &.data__type {
    position: relative;
    font-family: courier;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.125;
    white-space: pre;
  }
  &.data__type--opened:before {
    content: '';
    position: absolute;
    height: calc(100% - 1em);
    top: 1em;
    left: calc(.25em / 2);
    border-left: 1px dotted #ccc;
  }
  .data__key {
    color: #d23a00;
    display: inline-block;
    padding-right: .5em;
  }
  .data__key:after {
    content: ':';
    color: #666;
  }
  .data__value--string {
    color: #0b800b;
  }
  .data__value--string:before,
  .data__value--string:after {
    content: '"';
    color: #666;
  }
  .data__value--number {
    color: royalblue;
  }
  .data__value--boolean {
    color: #c524aa;
  }
  .data__value--null {
    color: #ccc;
  }
  .data__value--object,
  .data__value--array {
    color: #666;
  }
  .data__list-handle:before {
    content: '';
    height: 0;
    border-style: solid;
    border-width: .25em .45em;
    border-color: transparent transparent transparent #666;
    display: inline-block;
    vertical-align: middle;
  }
  .data__list-handle--opened:before {
    transform: rotate(90deg) translate3d(.25em, .25em, 0);
  }
  .data__list-handle:hover {
    cursor: pointer;
  }
  .data__list-items {
    margin-left: 1.5em;
    display: none;
  }
  .data__list-items--opened {
    display: block;
  }
`;
