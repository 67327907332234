import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Box, FlexCol, FlexRow } from 'Atoms/Layout';
import { Button } from 'Atoms/Button';
import { JsonCode } from 'Atoms/Text';
import { translate } from 'Services/Translator';
import * as DataTable from '../DataTable';
import { SelectRows } from '../components/SelectRows';
import { ActionDropdownComponents, ActionsDropdown } from '../components/ActionsDropdown';
import { Content, TableActions, TableFilters } from './PageOverviewStyles';
import { LockScreen } from 'Atoms/LockScreen';
import { PageHeader } from 'Components/Layout';
function PageOverview({ instance: table, locked, title, content, Filters, showFilters: initialExpandFilters, children, debug, actionDropdown, initialBulkActions, rootStyles, inlineFilters, _renderFilters: _initialRenderFilters, _renderActions: _initialRenderActions, EmptyContent, hideEmpty, ...props }) {
    const [_expandFilters, _setExpandFilters] = React.useState(initialExpandFilters !== undefined ? initialExpandFilters : (!!table.state.filters && table.state.filters.length > 0));
    const enableFilters = !inlineFilters && (Filters || !_initialRenderFilters && table.hasPlugin('useFilters'));
    const enableGlobalFilters = table.hasPlugin('useGlobalFilter');
    const showFilters = inlineFilters || enableFilters || enableGlobalFilters;
    const tableDropdownProps = useMemo(() => ({
        ...ActionDropdownComponents,
        instance: table,
    }), []);
    const headerToolbar = useMemo(() => (_initialRenderActions?.() || table?.renderActions?.()), [table.renderActions, _initialRenderActions]);
    const renderFilters = useCallback(() => {
        return Filters ? _jsx(Filters, {}) : _jsx(DataTable.Filter, { inline: inlineFilters });
    }, [Filters, inlineFilters]);
    const renderActions = useCallback(() => {
        if (_initialRenderFilters) {
            return _initialRenderFilters();
        }
        const bulkActions = table?.renderBulkActions?.();
        return showFilters || enableGlobalFilters || bulkActions ? (_jsxs(TableActions, { children: [_jsxs(FlexRow, { justifyContent: 'left', alignItems: inlineFilters ? 'end' : 'center', gap: 10, flexGrow: 1, flexShrink: 1, flexWrap: true, children: [(enableGlobalFilters || enableFilters) && (_jsxs(FlexRow, { justifyContent: "left", alignItems: inlineFilters ? 'end' : 'center', flexGrow: 1, minw: 200, maxw: 400, children: [enableGlobalFilters && _jsx(DataTable.GlobalSearch, {}), (!initialExpandFilters && enableFilters) && _jsx(Button, { variant: "link", color: 'dark', label: translate('table.filters'), icon: 'filter', "$size": "lg", onClick: () => _setExpandFilters(!_expandFilters) })] })), _jsx(FlexRow, { justifyContent: 'left', alignItems: inlineFilters ? 'end' : 'center', flexGrow: 1, flexWrap: true, gap: 10, children: inlineFilters && renderFilters() })] }), _jsxs(FlexRow, { ml: 'auto', justifyContent: 'right', alignItems: 'center', gap: 0, children: [bulkActions, table._render(initialBulkActions), actionDropdown && (_jsx(ActionsDropdown, { id: 'page-overview-actions-dropdown', drop: 'down', style: { marginLeft: 10 }, buttonProps: {
                                variant: 'outline',
                                color: 'dark',
                                rounded: false,
                                label: translate('table.actions.title')
                            }, children: actionDropdown(tableDropdownProps) }))] })] })) : null;
    }, [_initialRenderFilters, showFilters, enableFilters, enableGlobalFilters, table.renderBulkActions, _expandFilters, initialBulkActions, actionDropdown]);
    const { hasData, _render, } = table;
    if (locked) {
        rootStyles = {
            ...rootStyles,
            overflow: 'hidden',
        };
    }
    return (_jsx(DataTable.Provider, { instance: table, children: _jsxs(FlexCol, { className: "fw-pageoverview", minh: 0, maxh: '100%', gap: 24, style: rootStyles ?? undefined, children: [locked && _jsx(LockScreen.Page, { message: locked }), (!hasData && hideEmpty || EmptyContent)
                    ? _render(EmptyContent)
                    : (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: title, side: headerToolbar }), !content ? null : _jsx(Content, { children: content }), children, renderActions(), enableFilters && _jsx(TableFilters, { show: _expandFilters, children: _jsx(FlexRow, { gap: 10, children: renderFilters() }) }), _jsx(SelectRows, { instance: table }), _jsxs(FlexCol, { minh: props.scroll ? 0 : undefined, children: [_jsx(DataTable.Table, { minHeight: 400, ...props }), _jsx(DataTable.Pagination, { instance: table })] }), debug && _jsx(Box, { w: '100%', children: _jsx(JsonCode, { value: {
                                        rowState: table.state?.rowState || {},
                                        selectedRowIds: table.state?.selectedRowIds,
                                    }, indent: true }) })] }))] }) }));
}
PageOverview.Root = styled.div ``;
export default PageOverview;
