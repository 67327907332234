import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { Select as DropdownSelect } from "Atoms/Select";
import { DocumentArea } from "Pages/DocumentTemplates/enums/DocumentArea";
const options = Object.values(DocumentArea).map(area => ({
    label: translate(`document_area.title.${area}`),
    value: area
}));
export function AreaFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: options, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
}
