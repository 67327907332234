import { useCallback, useState } from 'react';
import { translate } from 'Services/Translator';
import { optionsPresets } from './optionsPresets';
import { useEventCallback } from '@restart/hooks';
import useTimeout from '@restart/hooks/useTimeout';
import { tryFunction } from 'Utils';
export const useModalDispatcher = (props) => {
    const [_isActive, _setIsActive] = useState(false);
    const [_options, _setOptions] = useState({});
    const showModal = useEventCallback(() => _setIsActive(true));
    const hideModal = useEventCallback(() => _setIsActive(false));
    const { set } = useTimeout();
    const setOptions = ({ onConfirm, onCancel, _args = [], ...rest }) => {
        const props = {
            ...rest
        };
        if (onConfirm)
            props.onConfirm = () => {
                tryFunction(onConfirm, _args);
                set(() => hideModal(), 100);
            };
        if (onCancel !== false || !props.onConfirm)
            props.onCancel = () => {
                tryFunction(onCancel, _args);
                hideModal();
            };
        _setOptions(props);
    };
    const _openModal = async ({ asyncLoad, ...options }) => {
        if (asyncLoad) {
            setOptions({
                loading: true,
                message: translate('modalDispatcher.preparing'),
                ...options,
            });
            showModal();
            try {
                const newOptions = await asyncLoad(optionsPresets, options);
                setOptions(newOptions);
            }
            catch (e) {
                setOptions({
                    message: translate('modalDispatcher.asyncError'),
                });
                console.error(e);
            }
        }
        else {
            setOptions(options);
            showModal();
        }
    };
    const confirmAsync = async ({ ...options }) => {
        return new Promise((resolve, reject) => {
            _openModal({
                ...options,
                onConfirm: (...args) => {
                    resolve(true);
                },
                onCancel: (...args) => {
                    resolve(false);
                },
            });
        });
    };
    const create = (defaultOptions) => {
        return (options, ...args) => {
            return _openModal({
                ...defaultOptions,
                ...options,
                _args: args,
            });
        };
    };
    const _deprecatedApi = useCallback((props) => {
        _openModal({
            btnCancel: translate('Cancel'),
            btnConfirm: translate('Delete'),
            icon: 'alert-circle',
            ...props,
        });
    }, []);
    return {
        _deprecatedApi,
        isActive: _isActive,
        show: _openModal,
        confirmAsync,
        close: hideModal,
        create,
        options: _options,
        templates: optionsPresets,
    };
};
