import { jsx as _jsx } from "react/jsx-runtime";
import useStableMemo from '@restart/hooks/useStableMemo';
import { useViewType } from './useViewType';
export function useStepView(getType, { id, save, DefaultFormTemplate, DefaultTemplate, saveStep, ...options }, getGroups) {
    const view = useViewType(getType, {
        ...options,
        id,
        closeFormOnSave: false,
        save: async (values) => {
            const { activeGroup: group, step: stepApi, type, } = view;
            const data = type.getGroupData(group.key);
            view.updateData(data);
            if (stepApi.isLastStep()) {
                if (save) {
                    const data = type.getGroupData(stepApi.stepNames);
                    await save(data, view);
                }
            }
            else {
                saveStep && await saveStep(data, view);
                setTimeout(() => stepApi.nextStep(), 50);
            }
        },
        DefaultFormTemplate: (props) => (_jsx(DefaultFormTemplate, { ...props })),
        DefaultTemplate: (props) => (_jsx(DefaultTemplate, { ...props })),
        enableForms: true,
    }, getGroups);
    return Object.assign(view, {
        step: useStepApi(view),
    });
}
function useStepApi(view) {
    return useStableMemo(() => {
        const groups = view.groups;
        const steps = Object.keys(groups);
        view._state.activeGroupName = steps[0];
        const api = {
            currentStep: 0,
            lastFinishedStep: 0,
            minStep: 0,
            maxStep: steps.length - 1,
            stepNames: steps,
            isFirstStep: () => api.currentStep === 0,
            isLastStep: () => api.currentStep === api.maxStep,
            hasStep: (index) => !steps[index],
            getStepName: (index = api.currentStep) => steps[index] ?? steps[api.currentStep],
            setStep: (number) => {
                api.setStepIndex(number - 1);
            },
            setStepIndex: (index) => {
                api.currentStep = Math.min(api.maxStep, Math.max(api.minStep, index));
                view.setActiveGroup(api.getStepName());
            },
            prevStep: () => {
                api.currentStep = api.currentStep > 0 ? api.currentStep - 1 : null;
                view.setActiveGroup(api.getStepName());
            },
            nextStep: () => {
                const nextStep = api.currentStep < api.maxStep ? api.currentStep + 1 : null;
                api.lastFinishedStep = Math.max(nextStep, api.lastFinishedStep);
                api.currentStep = nextStep;
                view.setActiveGroup(api.getStepName());
            },
        };
        return api;
    }, []);
}
