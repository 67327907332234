import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { OrFunction } from '@f2w/utils';
import { layoutSlot } from '../LayoutTheme';
import { ModalClose, useModalContext } from '../../Modal';
const LayoutRoot = styled.div `

    [data-part="header"] {
        grid-area: header;
        border-bottom: 1px solid var(--fw-color-neutral-100);
        justify-content: center;
        padding: var(--layout__padding-y) var(--layout__padding-x);
        display: flex;
    }

    [data-part="footer"] {
        grid-area: footer;
        border-top: 1px solid var(--fw-color-neutral-100);
        padding: var(--layout__padding-y) var(--layout__padding-x);

        > * {
            justify-items: end;
            justify-content: end;
        }
    }

    *${layoutSlot.partCss('side', 'main', 'preview')} {
        padding: var(--layout__padding-x);
        overflow: auto;
    }

    [data-part="side"] {
        grid-area: side;
        border-right: 1px solid var(--fw-color-neutral-100);
        //padding: var(--layout__padding-x);
    }

    [data-part="main"] {
        grid-area: main;
    }

    [data-part="preview"] {
        grid-area: preview;
        border-left: 1px solid var(--fw-color-neutral-100);
        background: var(--fw-color-neutral-50);
        //padding: var(--layout__padding-x);
        //overflow: auto;
    }

    & {
        display: grid;
        flex: 1;
        height: 100%;
        grid-template-columns: 280px minmax(400px, 800px) auto;
        grid-template-rows: max-content 1fr auto;
        grid-template-areas:
"header header header"
"side main preview"
"footer footer preview";

        background: var(--fw-color-white);
        pointer-events: auto;
    }
`;
function WizardLayout(props) {
    const { header: header, footer: footer, side: side, preview: preview, children: main, } = props;
    return (_jsxs(LayoutRoot, { children: [_jsx(WizardLayout.Header, { children: OrFunction(header) }), side && _jsx(WizardLayout.Side, { children: OrFunction(side) }), _jsx(WizardLayout.Main, { children: OrFunction(main) }), preview && _jsx(WizardLayout.Preview, { children: OrFunction(preview) }), footer && _jsx(WizardLayout.Footer, { children: OrFunction(footer) })] }));
}
(function (WizardLayout) {
    function Root(props) {
        return (_jsx(LayoutRoot, { ...layoutSlot.props('root', props) }));
    }
    WizardLayout.Root = Root;
    function Header({ children, ...rest }) {
        const context = useModalContext();
        return (_jsxs("header", { ...layoutSlot.props('header', rest), children: [children, context?.onHide && _jsx(ModalClose, {})] }));
    }
    WizardLayout.Header = Header;
    function Side(props) {
        return (_jsx("aside", { ...layoutSlot.props('side', props) }));
    }
    WizardLayout.Side = Side;
    function Main(props) {
        return (_jsx("main", { ...layoutSlot.props('main', props) }));
    }
    WizardLayout.Main = Main;
    function Preview(props) {
        return (_jsx("aside", { ...layoutSlot.props('preview', props) }));
    }
    WizardLayout.Preview = Preview;
    function Footer(props) {
        return (_jsx("footer", { ...layoutSlot.props('footer', props) }));
    }
    WizardLayout.Footer = Footer;
})(WizardLayout || (WizardLayout = {}));
export { WizardLayout };
