import { useFormModal } from "@f2w/form";
import { AncillaryExpenseAccountType } from "../types/AncillaryExpenseAccountType";
import { translate } from "Services/Translator";
import * as AccountServices from "../services/accountServices";
export const useAncillaryExpenseAccountForm = ({ onSaved, accountServices = AccountServices }) => {
    return useFormModal({
        name: 'ancillary-expense-account-form',
        type: () => new AncillaryExpenseAccountType(),
        title: ({ values }) => values.id
            ? translate('ancillaryExpenseAccount.edit')
            : translate('ancillaryExpenseAccount.create'),
        onLoad: async (id) => {
            if (!id) {
                return undefined;
            }
            const data = await accountServices.getAccount(id);
            return {
                id: data.id,
                propertyId: data.property.id,
                name: data.name,
                collectionType: data.collectionType,
                isShownInContract: data.isShownInContract,
                isUsedInCalculation: data.isUsedInCalculation,
                financialAccounts: data
                    .financialAccounts
                    .map(financialAccount => ({ financialAccountId: financialAccount.id })),
                settlementType: data.settlementType,
                unit: data.unit,
                costType: data.costType ? "_" + data.costType : null
            };
        },
        onSave: (values) => {
            const costType = values.costType ? values.costType.substring(1) : null;
            const financialAccounts = values.financialAccounts ?? [];
            const mappedValues = {
                ...values,
                costType,
                financialAccounts: financialAccounts
                    .filter(financialAccount => !!financialAccount.financialAccountId)
                    .map(financialAccount => financialAccount.financialAccountId)
            };
            if (values.id) {
                return accountServices
                    .updateAccount(values.id, mappedValues)
                    .then(onSaved);
            }
            else {
                return accountServices
                    .createAccount(mappedValues)
                    .then(onSaved);
            }
        }
    });
};
