import { UseStyled, useTheme } from "@f2w/theme";
import { css } from "styled-components";
import { Button } from "Components/Atoms";
const CheckoutSubscriptionPlanBase = UseStyled.styled('div', () => ({}));
const Body = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        padding: ${rem(24)} ${rem(80)} ${rem(80)} ${rem(96)};
    `;
});
const Sections = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        margin-top: ${rem(16)};
        margin-left:  ${rem(16)};
        display: flex;
        justify-content: space-between;
        gap: ${rem(40)};
    `;
});
const SectionPayInfo = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        flex: 3;
        padding: ${rem(32)} ${rem(32)} ${rem(32)} 0;
    `;
});
const SectionYourOrder = UseStyled.styled('div', () => (css `
        flex: 2;
    `));
const SectionYourOrderFrame = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
    border: 1px solid ${palette.neutral.$100};
    border-radius: ${rem(8)};
    min-width: ${rem(350)};
    padding: ${rem(32)};
    `;
});
const SectionTitle = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        color:  ${palette.neutral.$900};
        margin-bottom: ${rem(32)};
        ${typography.large.css({ fontWeight: 500 })};
    `;
});
const SectionPayInfoStrongText = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        color:  ${palette.neutral.$800};
        margin-bottom: ${rem(16)};
        ${typography.medium.css({ fontWeight: 600 })};
    `;
});
const InvoiceSection = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        align-items: center;
        gap: ${rem(16)};
        color:  ${palette.neutral.$900};
        margin-bottom: ${rem(32)};
        ${typography.medium.fontStyles};
        border: 1px solid ${palette.neutral.$100};
        border-radius: ${rem(8)};
        padding: ${rem(24)};
    `;
});
const ConfirmButtonSection = UseStyled.styled('div', () => (css `
        display: flex;
        ${Button} {
            flex: 1;
        }
    `));
const CheckoutSubscriptionPlanStyled = Object.assign(CheckoutSubscriptionPlanBase, {
    Body,
    Sections,
    SectionPayInfo,
    SectionTitle,
    SectionPayInfoStrongText,
    SectionYourOrder,
    SectionYourOrderFrame,
    InvoiceSection,
    ConfirmButtonSection
});
export default CheckoutSubscriptionPlanStyled;
