import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AbstractFormType, renderContent, SelectType, StringType } from '@f2w/form';
import { translate } from 'Services/Translator';
import { Col, Row } from 'Components/Atoms';
import React from 'react';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { DistributionKeyServices } from '../services';
import { DistributionKeyValuesTable } from '../table';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { toNum } from 'Utils';
import useStableMemo from '@restart/hooks/useStableMemo';
const getPropertyOptions = (allProperties) => {
    const list = new Map();
    allProperties.forEach(({ id: value, name: label, mandate }) => {
        if (!mandate?.id)
            return;
        const group = list.get(mandate?.id) ?? { label: mandate.name, options: [] };
        group.options.push({ label, value });
        list.set(mandate?.id, group);
    });
    return Array.from(list.values());
};
export class DistributionKeyType extends AbstractFormType {
    __getDefaultOptions() {
        return {
            distributionKeyServices: DistributionKeyServices,
            ...super.__getDefaultOptions(),
        };
    }
    properties = [];
    buildFields() {
        return {
            propertyId: new SelectType({
                label: translate('property'),
                required: true,
                searchable: true,
                confirm: ({ prev }) => {
                    if (Object.keys(this.value.valueIds ?? {})?.length) {
                        return {
                            message: translate('ancillaryExpenseDistributionKey.propertyConfirm.message'),
                            help: translate('ancillaryExpenseDistributionKey.propertyConfirm.help'),
                        };
                    }
                },
                isVisible: () => this.value.id === undefined,
                choiceOptions: () => this.properties,
            }),
            title: new StringType({
                label: translate('ancillaryExpense.distributionKeys.title'),
                required: true
            }),
            keyType: new SelectType({
                label: translate('ancillaryExpense.distributionKeys.type'),
                required: true,
                choices: {
                    equal: translate('ancillaryExpense.distributionKeys.equal'),
                    rooms: translate('ancillaryExpenseDistributionKey.keyType.rooms'),
                    area: translate('ancillaryExpenseDistributionKey.keyType.area'),
                    volume: translate('ancillaryExpenseDistributionKey.keyType.volume'),
                    custom: translate('ancillaryExpenseDistributionKey.keyType.custom'),
                },
            }),
        };
    }
    getDefaultValue() {
        return {
            valueIds: {},
            ...super.getDefaultValue(),
        };
    }
    getSubmitData() {
        const { keyType, propertyId, title, valueIds, } = this.value;
        const values = DistributionKeyType.isEditableType(keyType)
            ? Object.keys(valueIds).map(key => ({
                rentalObjectId: toNum(key),
                value: toNum(valueIds[key].value),
            }))
            : undefined;
        return {
            propertyId,
            keyType,
            title,
            values
        };
    }
    _useField(props) {
        super._useField(props);
        const [properties, setProperties] = React.useState([]);
        this.properties = properties;
        const portfolio = usePortfolio();
        React.useEffect(() => {
            const allProperties = portfolio.getProperties();
            setProperties(getPropertyOptions(allProperties));
        }, []);
    }
    _renderWidget() {
        const { propertyId } = this.value;
        return _jsxs(_Fragment, { children: [_jsx("div", { children: this.renderChild('propertyId') }), _jsxs(Row, { children: [_jsx(Col, { md: 8, children: this.renderChild('title') }), _jsx(Col, { md: 4, children: this.renderChild('keyType') })] }), propertyId && renderContent(DistributionKeyType.RentalObjectValuesTable, {
                    type: this,
                })] });
    }
    valuesManager = DistributionKeyType.rentalObjectValuesApi(this);
    static RentalObjectValuesTable({ type }) {
        const { distributionKeyServices } = type.options;
        const { propertyId, keyType } = type.field.value;
        const valuesApi = useStableMemo(() => DistributionKeyType.rentalObjectValuesApi(type), []);
        const table = DistributionKeyValuesTable.use({
            valuesApi,
            keyType,
            isEditable: DistributionKeyType.isEditableType(keyType),
            propertyId,
            distributionKeyServices,
        });
        return (_jsx(PageOverview, { instance: table, inlineFilters: true }));
    }
    static isEditableType(keyType) {
        return keyType === DistributionKeyType.KeyType.CUSTOM;
    }
}
(function (DistributionKeyType) {
    let KeyType;
    (function (KeyType) {
        KeyType["EQUAL"] = "equal";
        KeyType["ROOMS"] = "rooms";
        KeyType["AREA"] = "area";
        KeyType["VOLUME"] = "volume";
        KeyType["CUSTOM"] = "custom";
    })(KeyType = DistributionKeyType.KeyType || (DistributionKeyType.KeyType = {}));
    function rentalObjectValuesApi(type) {
        const api = {
            isDirty: (id) => {
                const value = api.getData(id)?.value;
                return value != undefined && value != api.getInitial(id)?.value;
            },
            getInitial: (id) => {
                return type.field.initialValue.valueIds[id] ?? {};
            },
            getData: (id) => {
                return type.field.value.valueIds[id] ?? {};
            },
            update: (id, value) => {
                type.getFormik().setFieldValue(`valueIds[${id}]`, value, false);
            },
            reset: () => {
                type.getFormik().setFieldValue(`valueIds`, type.field.initialValue.valueIds, false);
            },
        };
        return api;
    }
    DistributionKeyType.rentalObjectValuesApi = rentalObjectValuesApi;
})(DistributionKeyType || (DistributionKeyType = {}));
