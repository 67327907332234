import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from "react";
import { Section } from "Atoms/Layout/components";
import { Tab, Tabs } from "Atoms/Tabs";
import { translate } from "Services/App";
import { BankingFileView, BankingTransactionView } from "./components";
import { BankingFileService } from "./services";
import BankAccountServices from "Pages/BankAccount/services/bankAccountServices";
import { Title } from "Atoms/Text";
import { Div } from "Atoms/Layout";
export const BankingReconciliationPage = ({ bankingUploadServices: _bankingUploadServices = BankingFileService, bankAccountServices: _bankAccountServices = BankAccountServices, }) => {
    const transactionsTableRef = useRef(null);
    const [activeKey, setActiveKey] = useState('uploads');
    const goToTransactionTab = useCallback((uploadId) => {
        if (transactionsTableRef.current) {
            transactionsTableRef.current.setFilter('upload', uploadId);
            setActiveKey('transactions');
        }
    }, [transactionsTableRef.current !== null]);
    return (_jsxs(Section, { children: [_jsx(Div, { pb: 33, children: _jsx(Title, { as: 'h1', h3: true, children: translate('bankingReconciliation.title') }) }), _jsxs(Tabs, { id: "banking-reconciliation-tabs", onSelect: (v) => setActiveKey(v), defaultActiveKey: "uploads", activeKey: activeKey, children: [_jsx(Tab, { className: "mt-4", eventKey: "uploads", title: translate('bankingReconciliation.uploadFiles.title'), children: _jsx(BankingFileView, { transactionsTableRef: transactionsTableRef, goToTransactionTab: goToTransactionTab, getUploads: _bankingUploadServices.getUploads, upload: _bankingUploadServices.upload, getBankAccounts: _bankAccountServices.getBankAccounts, deleteUpload: _bankingUploadServices.deleteUpload }) }), _jsx(Tab, { className: "mt-4", eventKey: "transactions", title: translate('bankingReconciliation.assignTransactions.title'), children: _jsx(BankingTransactionView, { transactionsTableRef: transactionsTableRef }) })] })] }));
};
