import * as rt from 'react-table';
export const useResizeColumns = {
    initOptions: (options, table) => ({
        autoResetResize: false,
        disableResizing: false,
        defaultColumn: {
            disableResizing: true,
        },
    }),
    createColumnOptions: ({ enabled, ...options }, column, defaultColumn) => {
        if (enabled)
            options.disableResizing = false;
        return options;
    },
    hook: hooks => {
        rt.useResizeColumns(hooks);
    },
};
