import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Badge, Button, ButtonToolbar, SafeAnchor } from 'Components/Atoms';
import { useModalDispatcherConfirmWithChangingProps, useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { DataTable } from '@f2w/data-table';
import generateRoute from 'Services/Router';
import { StatusFilter } from '../components/CalculationPage/components/StatusFilter';
import { CalculationState } from '../enums';
import { CalculationServices } from '../services';
import moment from 'moment';
export const AncillaryExpenseCalculationsTable = DataTable.factory()(() => {
    const getStatusBadge = (status) => {
        switch (status) {
            case CalculationState.DRAFT:
                return _jsx(Badge, { "$color": "secondary", children: translate('ancillaryExpense.calculation.draft') });
            case CalculationState.BILLED:
                return _jsx(Badge, { "$color": "success", children: translate('ancillaryExpense.calculation.billed') });
        }
    };
    return {
        tableId: 'Calculations',
        RowActions: ({ row: { original: { id, isDeletable, status, numInvoices } }, refresh, onViewCalculationClicked, onEditCalculationClicked, onDeleteClicked, onCreateMissingInvoicesClicked, onRevertBillingClicked, onBillCalculationClicked, }) => {
            const { Link } = DataTable.Actions;
            const renderActionByStatus = React.useCallback((status) => {
                switch (status) {
                    case CalculationState.DRAFT: {
                        return (_jsx(Link, { label: translate('ancillaryExpenseCalculation.actions.bill'), icon: 'check', onClick: () => onBillCalculationClicked(id, refresh) }));
                    }
                    case CalculationState.BILLED: {
                        return (_jsxs(_Fragment, { children: [_jsx(Link, { label: translate('ancillaryExpenseCalculation.actions.createMissingInvoices'), icon: 'invoice', onClick: () => onCreateMissingInvoicesClicked(id, refresh) }), onRevertBillingClicked && _jsx(Link, { label: translate('ancillaryExpenseCalculation.actions.revertBilling'), icon: 'invoice', onClick: () => onRevertBillingClicked(id, refresh) })] }));
                    }
                }
            }, []);
            return (_jsxs(DataTable.Actions, { id: id, quickActions: [
                    {
                        icon: "eye",
                        title: translate('ancillaryExpenseCalculation.actions.view'),
                        onClick: () => {
                            onViewCalculationClicked(id);
                        }
                    },
                    status === CalculationState.DRAFT ? {
                        icon: "edit",
                        title: translate('ancillaryExpenseCalculation.actions.edit'),
                        onClick: () => {
                            onEditCalculationClicked(id);
                        }
                    } : null,
                ], children: [isDeletable && (_jsx(Link, { icon: 'trash-2', label: translate('ancillaryExpenseCalculation.actions.delete'), onClick: () => onDeleteClicked(id, numInvoices, refresh) })), renderActionByStatus(status)] }));
        },
        Actions: ({}) => (_jsx(ButtonToolbar, { children: _jsx(Button, { icon: 'plus', label: translate('new'), title: translate('ancillaryExpenseCalculation.actions.new'), href: generateRoute('ancillaryExpenses.calculation.wizard') }) })),
        manualControl: true,
        useGlobalFilter: {},
        usePagination: {},
        columns: {
            $actions: {
                sticky: 'left',
            },
            property: {
                Header: translate('ancillaryExpenseCalculation.table.property'),
                useSortBy: {},
                Cell: ({ row: { original: { property } }, onPropertyClick }) => (_jsx("div", { children: _jsx(SafeAnchor, { onClick: (event => {
                            event.preventDefault();
                            onPropertyClick(property.id);
                        }), children: property.name }) })),
            },
            period: {
                Header: translate('ancillaryExpenseCalculation.table.period'),
                useSortBy: {},
                Cell: ({ value }) => _jsxs("div", { children: [moment(value.from).format('DD.MM.YYYY'), " \u2013 ", moment(value.to).format('DD.MM.YYYY')] }),
            },
            status: {
                Header: translate('ancillaryExpenseCalculation.table.status'),
                useSortBy: {},
                Cell: ({ value }) => getStatusBadge(value),
                useFilters: {
                    Filter: StatusFilter,
                },
            },
        },
    };
});
export const useAncillaryExpenseCalculationsTable = ({ onPropertyClick, calculationServices = CalculationServices, }) => {
    const { success, error } = useToastDispatcherApi();
    const resolveSuccess = React.useCallback((message, refresh) => () => {
        success({ timeout: 2, message });
        refresh();
    }, []);
    const resolveError = React.useCallback((message) => () => {
        error({ timeout: 5, message });
    }, []);
    const onDeleteAncillaryExpenseCalculation = React.useCallback((calculationId, refresh) => {
        calculationServices.deleteCalculation(calculationId)
            .then(resolveSuccess(translate('ancillaryExpenseCalculation.delete.success'), refresh))
            .catch((error) => {
            if ([400, 422].includes(error.response.status)) {
                resolveError(error.response.data.message)();
            }
            else {
                resolveError(translate('ancillaryExpenseCalculation.delete.error'))();
            }
        });
    }, []);
    const onDeleteConfirmation = useModalDispatcherConfirmWithChangingProps({
        icon: 'alert-triangle',
        onConfirm: onDeleteAncillaryExpenseCalculation,
        message: translate('ancillaryExpenseCalculation.message.areYouSure'),
        help: translate('ancillaryExpenseCalculation.message.deleteConfirmation'),
        btnConfirm: translate('btn.confirm'),
    });
    const onDelete = React.useCallback((calculationId, numInvoices, refresh) => {
        if (numInvoices > 0) {
            onDeleteConfirmation([calculationId, refresh], {
                help: translate('ancillaryExpenseCalculation.message.deleteConfirmationInvoicesPresent', {
                    numInvoicesWithPayments: numInvoices,
                }),
            });
        }
        else {
            onDeleteConfirmation([calculationId, refresh]);
        }
    }, []);
    const onViewCalculationClicked = React.useCallback((calculationId) => {
        window.location.href = generateRoute('ancillaryExpenses.calculation.details', { ancillaryExpenseCalculation: calculationId });
    }, []);
    const onEditCalculationClicked = React.useCallback((calculationId) => {
        window.location.href = generateRoute('ancillaryExpenses.calculation.wizard', { calculation: calculationId });
    }, []);
    const onBillAncillaryExpenseCalculation = React.useCallback((calculationId, refresh) => {
        calculationServices.createCalculationBill(calculationId)
            .then(resolveSuccess(translate('ancillaryExpenseCalculation.bill.success'), refresh))
            .catch((requestError) => {
            if (requestError.response.status === 400) {
                error({
                    timeout: 5,
                    message: requestError.response.data.message,
                });
            }
            else {
                resolveError(translate('ancillaryExpenseCalculation.bill.error'));
            }
        });
    }, []);
    const onCreateMissingInvoicesForAncillaryExpenseCalculation = React.useCallback((calculationId, refresh) => {
        calculationServices.createCalculationMissingInvoices(calculationId)
            .then(resolveSuccess(translate('ancillaryExpenseCalculation.createMissingInvoices.success'), refresh))
            .catch(resolveError(translate('ancillaryExpenseCalculation.createMissingInvoices.error')));
    }, []);
    const onCalculationRevertBilling = React.useCallback((calculationId, refresh) => {
        calculationServices.revertCalculationBilling(calculationId)
            .then(resolveSuccess(translate('ancillaryExpenseCalculation.revertBilling.success'), refresh))
            .catch(resolveError(translate('ancillaryExpenseCalculation.revertBilling.error')));
    }, []);
    const instance = AncillaryExpenseCalculationsTable.useTable({
        onPropertyClick,
        onDeleteClicked: onDelete,
        onViewCalculationClicked: onViewCalculationClicked,
        onEditCalculationClicked: onEditCalculationClicked,
        onBillCalculationClicked: onBillAncillaryExpenseCalculation,
        onCreateMissingInvoicesClicked: onCreateMissingInvoicesForAncillaryExpenseCalculation,
        onRevertBillingClicked: onCalculationRevertBilling,
    }, () => ({
        getData: ({ request }) => calculationServices.listCalculations(request)
    }));
    return {
        instance,
    };
};
