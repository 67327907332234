import { useFormModal } from "@f2w/form";
import { BulkManualPaymentType } from "Pages/Creditor/forms/BulkManualPaymentType";
import { translate } from "Services/Translator";
export const useBulkManualPaymentFormModal = (creditorServices, onClose) => useFormModal({
    name: 'bulk-manual-payment-form',
    title: ({ values: { ids = [] } }) => translate('creditor.manualPayments.title', {
        numberOfInvoices: ids.length
    }),
    type: () => new BulkManualPaymentType(),
    onLoad: async (ids) => {
        const data = await creditorServices.loadPaymentData(ids[0]);
        return {
            ids,
            creditAccountId: data.creditAccountId
        };
    },
    onSave: async ({ ids, ...values }) => {
        const createDto = {
            paidAt: values.paidAt,
            creditAccountId: Number(values.creditAccountId),
            creditorInvoiceIds: ids
        };
        return creditorServices
            .bulkManualPayments(createDto)
            .then(onClose);
    }
});
