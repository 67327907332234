export const isPropertyMatchingQuery = (query, property) => {
    const regex = new RegExp(query, 'iu');
    return !!([property.name, property.zip, property.city]
        .join(' ')
        .match(regex));
};
export const isBuildingMatchingQuery = (query, building) => {
    const regex = new RegExp(query, 'iu');
    return !!([building.name, building.zip, building.city]
        .join(' ')
        .match(regex));
};
export const isRentalObjectMatchingQuery = (query, rentalObject, portfolio, withContract = false) => {
    const regex = new RegExp(query, 'iu');
    const building = portfolio.getBuildingById(rentalObject.buildingId);
    if (isBuildingMatchingQuery(query, building)) {
        return true;
    }
    if (withContract) {
        const contract = portfolio.getContractsByRentalObjectId(rentalObject.id)[0] ?? null;
        if (contract && isContractMatchingQuery(query, contract, portfolio)) {
            return true;
        }
    }
    return !!([
        rentalObject.name,
    ]
        .join(' ')
        .match(regex));
};
export const isContractMatchingQuery = (query, contract, portfolio) => {
    const regex = new RegExp(query, 'iu');
    const rentalObject = portfolio.getRentalObjectById(contract.rentalObjectId);
    const building = portfolio.getBuildingById(rentalObject.buildingId);
    if (isBuildingMatchingQuery(query, building)) {
        return true;
    }
    return !!([
        (contract ? contract.tenants.join(" & ") : "")
    ]
        .join(' ')
        .match(regex));
};
