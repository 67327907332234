import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Modal } from 'Components/Modal';
import { OrFunction } from 'Utils';
export function DetailViewModal({ view, title, onClose, }) {
    const onHide = React.useCallback(() => {
        view.closeForm(() => {
            onClose && onClose();
        });
    }, [onClose]);
    return (_jsxs(Modal, { show: true, size: "detailView", onHide: onHide, children: [_jsx(Modal.Header, { closeButton: true, onHide: onHide, children: title && _jsx(Modal.Title, { children: OrFunction(title) }) }), view.render()] }));
}
