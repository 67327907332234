import generateRoute from "Services/Router";
import client from "Utils/client";
const listAssets = (params) => {
    const route = generateRoute('api.assets.list');
    return client
        .get(route, { params })
        .then(response => response.data);
};
const getAssetById = (id) => {
    const route = generateRoute('api.assets.byId', {
        asset: id
    });
    return client
        .get(route)
        .then(response => response.data);
};
const getAssetsDropdownData = () => {
    const route = generateRoute('assets.dropdownData');
    return client
        .get(route)
        .then(response => response.data);
};
const getAssetsByRentalObjectForDropdown = (rentalObjectId) => {
    const route = generateRoute('api.assets.dropdownByRentalObject', {
        rentalObject: rentalObjectId
    });
    return client
        .get(route)
        .then(response => response.data);
};
const createAsset = (createAssetDto) => {
    const route = generateRoute('api.assets.create');
    return client
        .post(route, createAssetDto)
        .then(response => response.data);
};
const updateAsset = (assetId, updateAssetDto) => {
    const route = generateRoute('api.assets.update', {
        asset: assetId
    });
    return client.put(route, updateAssetDto);
};
const deleteAsset = (assetId) => {
    const route = generateRoute('api.assets.delete', {
        asset: assetId
    });
    return client.delete(route);
};
const deleteAssets = (assetIds) => {
    const route = generateRoute('api.assets.deleteMultiple');
    return client.delete(route, { data: { assetIds } });
};
const linkAssetToCreditor = async (assetId, creditorInvoiceId) => {
    const route = generateRoute('api.assets.linkToCreditorInvoice', {
        asset: assetId,
        creditorInvoice: creditorInvoiceId
    });
    return client
        .put(route);
};
const getCreditorsAvailableToAsset = async (assetId) => {
    const route = generateRoute('api.assets.getLinkableCreditors', {
        asset: assetId
    });
    return client
        .get(route)
        .then(res => res.data);
};
export default {
    listAssets,
    getAssetById,
    getAssetsDropdownData,
    createAsset,
    updateAsset,
    deleteAsset,
    deleteAssets,
    getAssetsByRentalObjectForDropdown,
    getCreditorsAvailableToAsset,
    linkAssetToCreditor
};
