import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AbstractFormType, DateType } from "@f2w/form";
import { FlexCol, FlexRow } from "Atoms/Layout";
import moment from "moment/moment";
export class DateRangeType extends AbstractFormType {
    buildFields() {
        return {
            start: new DateType({
                label: 'Datum von',
                min: this.options.maxDateRange.start,
                max: this.options.maxDateRange.end,
                constraints: {
                    test: ['is-before-end', 'Von muss vor Bis liegen', (currentValue) => {
                            return this.field.value.end
                                ? moment(currentValue).isSameOrBefore(moment(this.field.value.end))
                                : true;
                        }]
                }
            }),
            end: new DateType({
                label: 'Datum bis',
                min: this.options.maxDateRange.start,
                max: this.options.maxDateRange.end,
                constraints: {
                    test: ['is-after-start', 'Von muss vor Bis liegen', (currentValue) => {
                            return this.field.value.start
                                ? moment(currentValue).isSameOrAfter(moment(this.field.value.start))
                                : true;
                        }]
                }
            }),
        };
    }
    _renderWidget() {
        return _jsx(_Fragment, { children: _jsxs(FlexRow, { justifyContent: 'space-between', gap: 20, children: [_jsx(FlexCol, { style: { width: '100%' }, children: this.renderChild('start') }), _jsx(FlexCol, { style: { width: '100%' }, children: this.renderChild('end') })] }) });
    }
}
