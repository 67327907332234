import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
export const SubmitButton = ({ updateLoadingState, loading, form }) => {
    return (_jsx(Button, { type: 'submit', onClick: async (event) => {
            event.preventDefault();
            updateLoadingState(true);
            await form.submitForm();
            updateLoadingState(false);
        }, loading: loading, disabled: loading || !form.dirty, children: translate('btn.save') }));
};
