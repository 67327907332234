import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { tryFunction } from 'Utils';
import { ToastMessage } from './ToastMessage';
import useStableMemo from '@restart/hooks/useStableMemo';
export function ToastDispatcher({ innerRef }) {
    const { messages } = innerRef.current = useToastDispatcher();
    return (_jsx(ToastDispatcherRoot, { className: 'fw-v2', children: messages.map((message, i) => (_jsx(ToastMessage, { ...message }, message.id))) }));
}
const ToastDispatcherRoot = styled.div `
  position: fixed;
  z-index: 100100900;
  top: 0;
  left: 100%;
  width: 620px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;

  > div {
    margin: 10px auto 0 0;
    padding-right: 20px;
    max-width: 100%;
    transition: transform 250ms ease-out;
    transform-origin: left;
    transform: translateX(0%);
    &.fw-toast-message-show {
      transform: translateX(-100%);
    }
  }
`;
const useToastDispatcher = () => {
    const [messages, setMessages] = React.useState([]);
    const idRef = useRef(1);
    const addMessage = useCallback(({ id, onClose, ...props }) => {
        const message = {
            id: id || `msg-${idRef.current++}`,
            ...props,
            onRemove: () => {
                removeMessage(message.id);
                tryFunction(props.onRemove);
                tryFunction(onClose);
            },
        };
        setMessages(prevState => [...prevState, message]);
    }, [setMessages]);
    const removeMessage = (...ids) => {
        setMessages(prev => {
            const messages = prev.filter((m) => !ids.includes(m.id));
            if (messages.length === 0)
                idRef.current = 0;
            return messages;
        });
    };
    const clear = () => {
        setMessages([]);
    };
    const types = useStableMemo(() => ToastMessage.create2({
        error: (props) => addMessage({ type: "error", ...props }),
        success: (props) => addMessage({ type: "success", ...props }),
        warning: (props) => addMessage({ type: "warning", ...props }),
    }), []);
    return {
        messages,
        add: addMessage,
        remove: removeMessage,
        clear,
        ...types,
    };
};
