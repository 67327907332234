import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from "styled-components";
import { useTheme, UseStyled } from "@f2w/theme";
import { translate } from 'Services/Translator';
const ProgressBarPercent = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        font-size: 14px;
        line-height: 150%;
        margin-top: ${rem(8)};

    `;
});
const GettingStartedProgressBar = UseStyled.Fn(({ progress, className }) => {
    return (_jsxs("div", { className: className, children: [_jsx("div", { className: "progress", children: _jsx("div", { className: "progress-bar", role: "progressbar", style: { width: progress + "%" } }) }), _jsx(ProgressBarPercent, { children: translate("start.progressStatus", { progress: progress }) })] }));
}, {});
export default GettingStartedProgressBar;
