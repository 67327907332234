import { useFormModal } from "@f2w/form";
import { translateAdmin } from "Services/App";
import { UpsertSubscriptionType } from "Pages/Administration/Customers/types/UpsertSubscriptionType";
export const useUpdateSubscriptionForm = ({ onSave, updateSubscription, loadSubscription }) => useFormModal({
    name: 'update-subscription-form',
    type: () => new UpsertSubscriptionType(),
    title: translateAdmin('customer.subscription.edit'),
    onLoad: async (id) => ({
        id,
        ...(await loadSubscription(id))
    }),
    onSave: async (values) => {
        return updateSubscription(values.id, {
            range: {
                start: values.start,
                end: values.end,
            },
            typeId: values.typeId,
            oneTimeDiscount: values.oneTimeDiscount,
            notes: values.notes,
        }).then(onSave);
    }
});
