import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { InputTheme, useInputOnChange } from './base';
import { useEventCallback } from '@restart/hooks';
import { Button } from 'Atoms/Button/Button';
import styled from 'styled-components';
export const Number = ({ children, onChange: _onChange, ...props }) => {
    const inputRef = React.createRef();
    const handleBlur = useEventCallback((event) => {
        const value = parseFloat(event.currentTarget.value);
        if (props.min != null && value <= props.min) {
            inputRef.current?.stepUp();
            inputRef.current?.stepDown();
        }
        else {
            inputRef.current?.stepDown();
            inputRef.current?.stepUp();
        }
        _onChange(inputRef.current.value, event);
    });
    const handleChange = useInputOnChange(_onChange);
    const stepUp = useEventCallback((e) => {
        inputRef.current?.stepUp();
        _onChange(inputRef.current.value);
    });
    const stepDown = useEventCallback((e) => {
        inputRef.current?.stepDown();
        _onChange(inputRef.current.value);
    });
    return (_jsx(InputTheme.Provider, { as: Root, ...props, children: ({ inputProps }) => {
            return _jsxs(_Fragment, { children: [_jsx(Button, { tabIndex: -1, onClick: stepDown, variant: 'link', color: 'primary', icon: 'minus' }), _jsx(InputTheme.Main, { children: _jsx(InputTheme.Input, { ...inputProps, type: 'number', onChange: handleChange, onBlur: handleBlur, ref: inputRef }) }), _jsx(Button, { tabIndex: -1, onClick: stepUp, variant: 'link', color: 'primary', icon: 'plus' })] });
        } }));
};
const Root = styled.div `
    max-width: 140px;
    min-width: 100px;
    ${InputTheme.Input} {
      text-align: center;
    }
    ${InputTheme.Main} {
      padding: 0;
    }
`;
function fixNumber(value, step, min = 0) {
    if (value < 0) {
        return roundStep(Math.abs(value), step);
    }
}
function roundStep(value, step, min = 0) {
    const decimals = step.toString().split('.')[1]?.length;
    let precision = 1;
    if (decimals) {
        precision = Math.pow(10, decimals);
        step = step * precision;
        min = min * precision;
        value = value * precision;
    }
    return (Math.round((value - min) / step) * step + min) / precision;
}
