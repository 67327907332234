import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatAmount } from "Services/NumberHelper";
import { translate } from "Services/Translator";
const BalanceSection = ({ titledAmounts }) => {
    return (_jsxs("table", { children: [_jsx("thead", { children: _jsx("tr", { children: _jsx("th", { children: translate('accounting.balance') }) }) }), _jsx("tbody", { children: titledAmounts.map(({ title, amount, important }, key) => (_jsxs("tr", { style: {
                        borderBottom: "1px solid #F4F4F4",
                        fontWeight: important ? '500' : 'normal'
                    }, children: [_jsx("td", { style: { padding: 8, width: "100%" }, children: title }), _jsx("td", { style: { padding: 8, width: "2rem" }, children: "CHF" }), _jsx("td", { className: "text-right", style: { padding: 8 }, children: formatAmount(amount) })] }, key))) })] }));
};
export default BalanceSection;
