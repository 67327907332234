import { SimpleType } from '../core';
import { translate } from 'Services/Translator';
export class ReferenceType extends SimpleType.MaskedString {
    constructor(options, parent) {
        super({
            label: translate('form.type.qrReference.label'),
            ...options,
            constraints: {
                min: [14, translate('form.type.qrReference.error')],
                max: [27, translate('form.type.qrReference.error')],
                ...options?.constraints,
            },
            mask: () => {
                const definitions = {
                    '#': { mask: /[\dA-Z]/ },
                };
                return {
                    prepareChar: str => str.toUpperCase(),
                    mask: [
                        { mask: '00 00000 00000 00000 00000 00000' },
                        { mask: '{RF}00 #### #### #### #### #### #', definitions }
                    ]
                };
            }
        }, parent);
    }
    async validate(value) {
        const validAsQrReference = ReferenceType.validateQrChecksum(value ?? '');
        const validAsCreditorReference = ReferenceType.validateCreditorRemainder(`${value ?? ''}`);
        if (!validAsQrReference && !validAsCreditorReference) {
            this.throwValidationError(translate('form.type.qrReference.error'));
        }
        return super.validate(value);
    }
}
(function (ReferenceType) {
    const Matrix = [
        [0, 9, 4, 6, 8, 2, 7, 1, 3, 5],
        [9, 4, 6, 8, 2, 7, 1, 3, 5, 0],
        [4, 6, 8, 2, 7, 1, 3, 5, 0, 9],
        [6, 8, 2, 7, 1, 3, 5, 0, 9, 4],
        [8, 2, 7, 1, 3, 5, 0, 9, 4, 6],
        [2, 7, 1, 3, 5, 0, 9, 4, 6, 8],
        [7, 1, 3, 5, 0, 9, 4, 6, 8, 2],
        [1, 3, 5, 0, 9, 4, 6, 8, 2, 7],
        [3, 5, 0, 9, 4, 6, 8, 2, 7, 1],
        [5, 0, 9, 4, 6, 8, 2, 7, 1, 3],
    ];
    const Position = [
        0,
        9,
        8,
        7,
        6,
        5,
        4,
        3,
        2,
        1
    ];
    const CreditorReferenceModulo = 97n;
    const ValidCreditorReferenceRemainder = 1;
    const CreditorReferenceCharacterValueOffset = 10;
    function validateQrChecksum(referenceNumber) {
        const calculateChecksum = (numberArray, carry = 0) => {
            const checksumVal = Matrix[carry][numberArray.shift()];
            return numberArray.length ? calculateChecksum(numberArray, checksumVal) : checksumVal;
        };
        const hasInvalidCharacters = referenceNumber.match(/[^0-9 ]/);
        if (hasInvalidCharacters) {
            return false;
        }
        const numberArray = referenceNumber.replaceAll(/[^0-9]/ig, '').split('');
        if (numberArray.length > 27) {
            return false;
        }
        const checksumNumber = Number(numberArray.pop());
        return Position[calculateChecksum(numberArray, 0)] === checksumNumber;
    }
    ReferenceType.validateQrChecksum = validateQrChecksum;
    function validateCreditorRemainder(creditorReference) {
        const preparedReference = `${creditorReference.substring(4)}${creditorReference.substring(0, 4)}`;
        const transformedReference = preparedReference
            .split('')
            .map(calculateCreditorReferenceCharacterValue)
            .join('');
        const remainder = BigInt(transformedReference) % CreditorReferenceModulo;
        return Number(remainder) === ValidCreditorReferenceRemainder;
    }
    ReferenceType.validateCreditorRemainder = validateCreditorRemainder;
    function calculateCreditorReferenceCharacterValue(char) {
        const preparedChar = char.toUpperCase();
        if (!preparedChar.match('^[A-Z]$')) {
            return char;
        }
        const value = (preparedChar.charCodeAt(0) - 'A'.charCodeAt(0)) + CreditorReferenceCharacterValueOffset;
        return value.toString();
    }
})(ReferenceType || (ReferenceType = {}));
