import generateRoute from "Services/Router";
import { paramsSerializer } from "Utils/client";
import { SortDirection } from "Types/DataTable/Sort";
export const generateExportUrl = (routeName, { filters, sortBy, globalFilter, }) => {
    const url = generateRoute(routeName);
    const mappedFilters = {};
    const mappedSorts = {};
    filters?.forEach(filter => {
        mappedFilters[filter.id] = filter.value;
    });
    sortBy?.forEach(sort => {
        mappedSorts[sort.id] = sort.desc ? SortDirection.DESC : SortDirection.ASC;
    });
    const query = paramsSerializer({
        sort: mappedSorts,
        filters: mappedFilters,
        query: globalFilter || ''
    });
    return `${url}?${query}`;
};
