import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { useDropdownToggle } from 'react-overlays/DropdownToggle';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import { Button } from 'Atoms/Button/Button';
import useWrappedRefWithWarning from 'Components/Base/useWrappedRefWithWarning';
import { dropdownSlot } from '../Dropdown.theme';
const prefix = 'fw-dropdown-toggle';
export const DropdownToggle = forwardRef(({ split, className, as: Component = Button, nocaret, caret = !nocaret, ...props }, ref) => {
    const [toggleProps, meta] = useDropdownToggle();
    toggleProps.ref = useMergedRefs(toggleProps.ref, useWrappedRefWithWarning(ref, 'DropdownToggle'));
    return (_jsx(Component, { ...dropdownSlot.attr('toggle'), className: classNames(className, prefix, split && `${prefix}-split`, caret && 'fw-caret'), ...toggleProps, ...props }));
});
DropdownToggle.displayName = 'DropdownToggle';
