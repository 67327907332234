import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import AuthCodeInput from "Components/Atoms/AuthCodeInput/AuthCodeInput";
import { Button } from "Components/Atoms/Button";
import FormErrorMessage from "Components/Atoms/Form/FormErrorMessage";
import { translate } from "Services/Translator";
import styled, { css } from "styled-components";
import GoogleAuthLogo from "Pages/TwoFactorAuth/GoogleAuthLogo";
import { useTheme } from "@f2w/theme";
import client from "Utils/client";
import TwoFactorAuthManagementStyled from "Pages/TwoFactorAuth/TwoFactorAuthManagement/TwoFactorAuthManagementStyled";
const { Wrapper, Heading, Actions } = TwoFactorAuthManagementStyled;
const CodeLabel = styled.div(() => {
    const { typography } = useTheme();
    return css `
        ${typography.large.fontStyles};
    `;
});
const TwoFactorAuthLoginForm = ({ checkPathUrl, logoutUrl, authenticationError }) => {
    const [authCode, setAuthCode] = useState("");
    const [errorMessage, setErrorMessage] = useState(authenticationError);
    const [loading, setLoading] = useState(false);
    const submitCode = async (code) => {
        setErrorMessage(null);
        setLoading(true);
        try {
            const response = await client.post(checkPathUrl, { _auth_code: code });
            window.location.href = response.data.redirectUrl ?? '/';
        }
        catch (error) {
            const status = error.response.status;
            if (status === 400) {
                setErrorMessage(error.response.data.message);
                return;
            }
            throw error;
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (authCode.replace(/\s/g, '').length !== 6) {
            return;
        }
        submitCode(authCode);
    }, [authCode]);
    return (_jsxs(Wrapper, { children: [_jsx(GoogleAuthLogo, {}), _jsx(Heading, { style: { marginBottom: 0 }, children: translate('2fa.login.title') }), _jsx(CodeLabel, { children: translate('2fa.login.codeLabel') }), _jsx(AuthCodeInput, { autoFocus: true, defaultValue: authCode, onChange: (value) => {
                    setAuthCode(value);
                    setErrorMessage(null);
                }, length: 6 }), !!errorMessage && _jsx(FormErrorMessage, { children: errorMessage }), _jsx("input", { name: "_auth_code", type: "hidden", value: authCode }), _jsxs(Actions, { children: [_jsx(Button, { variant: "neutral", href: logoutUrl, children: translate('user.logout') }), _jsx(Button, { type: "submit", "$size": "lg", onClick: () => submitCode(authCode), loading: loading, children: "Login" })] })] }));
};
export default TwoFactorAuthLoginForm;
