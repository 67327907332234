import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { translate } from "Services/Translator";
import DamageStatusSelect from "Pages/Damage/components/DamageStatusSelect";
const InlineDamageStatusEdit = ({ status, onChange }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    if (isEditMode) {
        return (_jsx(DamageStatusSelect, { value: status, onChange: (value) => {
                onChange(value);
                setIsEditMode(false);
            }, onBlur: () => setIsEditMode(false), menuPortalTarget: document.body, "$size": "sm", useMaxContentWidth: true, defaultMenuIsOpen: true, autoFocus: true }));
    }
    return (_jsx("span", { onClick: () => setIsEditMode(true), style: {
            marginRight: "20px",
            padding: "0 2px",
            cursor: "pointer",
            borderBottom: "1px dashed #CED1DB",
            whiteSpace: "nowrap"
        }, children: translate(status ? `damageStatus.${status}` : 'notAvailable') }));
};
export default InlineDamageStatusEdit;
