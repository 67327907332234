import { useForm } from "@f2w/form";
import { LoginType } from "Pages/Auth/types/LoginType";
export const useLoginForm = ({ login, csrfToken, toastApi, onForgotPassword }) => useForm({
    name: 'login-form',
    type: () => new LoginType({
        onForgotPassword
    }),
    successMessage: null,
    onSave: (values) => {
        return login({ ...values, csrfToken })
            .then(data => {
            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        })
            .catch((error) => {
            if (error.isAxiosError) {
                toastApi.error({
                    message: error.response?.data?.message,
                });
                return;
            }
            throw error;
        });
    }
});
