import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Title } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { generateDocument } from 'Services/DocumentGenerator';
import { Header, Section } from 'Atoms/Layout/components';
import moment from 'moment';
import ViewModal from 'Components/ObjectViewModal/components/ViewModalStyled';
export const GenerateDocumentLink = ({ document, id, sourceClass, ...props }) => (_jsxs(_Fragment, { children: [_jsx(ViewModal.DocumentLink, { ...props, label: document.label, onDownload: () => {
                generateDocument(document.template, sourceClass, id.toString());
            } }), document.isMandated && _jsx(ViewModal.DocumentLink, { ...props, label: document.mandatedDocumentName, onDownload: () => {
                generateDocument(document.template, sourceClass, id.toString(), true);
            } })] }));
export const DocumentsTab = ({ group: { title, data: contract, data: { documents = [], modifications = [], }, }, view: { dispatcher } }) => {
    return (_jsxs(_Fragment, { children: [_jsx(Header.Sticky, { children: _jsx(Title, { h6: true, as: 'h3', children: title }) }), _jsx(Section, { children: documents.map((document, index) => (_jsx(GenerateDocumentLink, { document: document, id: contract.id, sourceClass: 'App\\Entity\\Contract\\Contract' }, `document-${index}`))) }), modifications.map(modification => (_jsxs(React.Fragment, { children: [_jsx(Header, { children: _jsx(Title, { large: 500, as: 'h4', children: translate('contract.details.modification.documents', {
                                validFrom: moment(modification.validFrom).format('DD.MM.YYYY'),
                            }) }) }), _jsx(Section, { pt: 0, children: modification.documents.map(document => (_jsx(GenerateDocumentLink, { document: document, id: modification.id, sourceClass: 'App\\Entity\\Contract\\Modification\\Modification' }, document.template))) })] }, modification.id)))] }));
};
