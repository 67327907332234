import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import createChainedFunction from '../Base/createChainedFunction';
import styled from 'styled-components';
const BaseAnchor = styled.a ``;
function isTrivialHref(href) {
    return !href || href?.trim() === '#';
}
export const SafeAnchor = React.forwardRef(({ as: Component = BaseAnchor, disabled, nested, onKeyDown, active, ...props }, ref) => {
    const handleClick = (event) => {
        const { href, onClick } = props;
        if (disabled || isTrivialHref(href))
            event.preventDefault();
        if (disabled)
            return event.stopPropagation();
        if (onClick)
            onClick(event);
        if (nested)
            event.stopPropagation();
    };
    const handleKeyDown = (event) => {
        if (event.key === ' ') {
            event.preventDefault();
            handleClick(event);
        }
    };
    if (isTrivialHref(props.href)) {
        props.role = props.role || 'button';
        props.href = props.href || '#';
    }
    if (disabled) {
        props.tabIndex = -1;
        props['aria-disabled'] = true;
    }
    return (_jsx(Component, { ref: ref, ...props, onClick: handleClick, onKeyDown: createChainedFunction(handleKeyDown, onKeyDown) }));
});
SafeAnchor.displayName = 'SafeAnchor';
