export var Category;
(function (Category) {
    Category[Category["FINANCE"] = 1] = "FINANCE";
    Category[Category["CONTRACTS"] = 2] = "CONTRACTS";
    Category[Category["CORRESPONDENCE"] = 3] = "CORRESPONDENCE";
    Category[Category["LOGS"] = 4] = "LOGS";
    Category[Category["PERSONAL_INFORMATION"] = 5] = "PERSONAL_INFORMATION";
    Category[Category["STATE_DOCUMENTS"] = 6] = "STATE_DOCUMENTS";
    Category[Category["REPAIRS_AND_DAMAGE"] = 7] = "REPAIRS_AND_DAMAGE";
    Category[Category["LOGISTICS"] = 8] = "LOGISTICS";
    Category[Category["PICTURES"] = 9] = "PICTURES";
})(Category || (Category = {}));
