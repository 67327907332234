import { jsx as _jsx } from "react/jsx-runtime";
import { DataTable } from '@f2w/data-table';
import { Text } from 'Atoms/Text';
import React, { useEffect } from 'react';
import { getHandoverUsers } from 'Pages/Handover/services/HandoverIntegrationService';
import { translate } from 'Services/Translator';
const BaseTable = DataTable.factory()(() => ({
    tableId: 'HandoverUsers',
    manualControl: false,
    usePagination: {},
    useSortBy: {},
    useGlobalFilter: {},
    columns: {
        name: {
            Header: translate('handovers.integrationUsers.columns.name'),
            useSortBy: {},
            useGlobalFilter: {},
            accessor: u => u.firstName + ' ' + u.lastName,
            Cell: ({ row }) => _jsx(Text, { nowrap: true, value: row.original.firstName + ' ' + row.original.lastName }),
        },
        email: {
            Header: translate('handovers.integrationUsers.columns.email'),
            useSortBy: {},
            useGlobalFilter: {},
        },
        registration: {
            Header: translate('handovers.integrationUsers.columns.registration'),
            Cell: ({ row }) => {
                const isRegistered = row.original.isRegistered;
                const registrationLink = row.original.registrationLink;
                if (isRegistered) {
                    return translate('handovers.integrationUsers.registrationStatus.registered');
                }
                else {
                    return (_jsx("a", { href: registrationLink, target: '_blank', children: translate('handovers.integrationUsers.registrationStatus.register') }));
                }
            }
        }
    }
}));
export const HandoverUsersTable = Object.assign(BaseTable, {
    use: ({ usersCreated }) => {
        console.log(usersCreated);
        const [users, _setUsers] = React.useState([]);
        const loadData = React.useCallback(() => {
            return getHandoverUsers()
                .then((results) => {
                _setUsers(results);
            });
        }, []);
        const table = BaseTable.useCreateTable({
            data: users,
            isLoading: false,
        });
        useEffect(() => {
            if (usersCreated) {
                loadData();
            }
        }, [usersCreated]);
        return table;
    },
});
