import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTable } from "@f2w/data-table";
import { translate } from "Services/Translator";
import { SettlementType } from "Pages/AncillaryExpense/enums/SettlementType";
import { Div } from "Atoms/Layout";
import { OverlayTrigger, Tooltip as BaseTooltip } from "Atoms/Overlay";
import { CollectionType } from "Pages/AncillaryExpense/enums/CollectionType";
import { Icon } from "Atoms/Icon";
const BaseTable = DataTable.factory()(() => ({
    manualControl: false,
    columns: {
        $rowSelect: {},
        name: {
            Header: translate('ancillaryExpenseAccount.name')
        },
        financialAccounts: {
            Header: translate('ancillaryExpenseAccount.financialAccounts'),
            Cell: ({ value }) => value
                .map((financialAccount) => financialAccount.position)
                .join(', ')
        },
        settlementType: {
            Header: translate('ancillaryExpenseAccount.settlementType'),
            Cell: ({ value }) => (_jsx(Div, { textNoWrap: true, children: value === SettlementType.ALLOCATION_FORMULA
                    ? translate('ancillaryExpenseAccount.settlement.allocation_formula')
                    : translate('ancillaryExpenseAccount.settlement.consumption') }))
        },
        info: {
            Header: '',
            Cell: ({ row: { original: { settlementType, collectionType, financialAccounts } } }) => (_jsx(OverlayTrigger, { overlay: _jsx(BaseTooltip, { id: 'tooltip-ancillary-setup-assistant', children: _jsx(Div, { style: { textAlign: 'left' }, children: _jsxs("ul", { style: { paddingLeft: '15px', marginBottom: 0 }, children: [_jsxs("li", { children: [translate('ancillaryExpenseAccount.settlement'), ":\u00A0", settlementType === SettlementType.ALLOCATION_FORMULA
                                            ? translate('ancillaryExpenseAccount.settlement.allocation_formula')
                                            : translate('ancillaryExpenseAccount.settlement.consumption')] }), _jsxs("li", { children: [translate('ancillaryExpenseAccount.collectionType'), ":\u00A0", collectionType === CollectionType.FIXED
                                            ? translate('ancillaryExpenseAccount.collection.fixed')
                                            : translate('ancillaryExpenseAccount.collection.floating')] }), _jsxs("li", { children: [translate('ancillaryExpenseAccount.financialAccounts'), ":"] }), _jsx("ul", { style: { paddingLeft: '30px' }, children: financialAccounts.map((financialAccount) => _jsxs("li", { children: [financialAccount.position, " - ", financialAccount.label] }, financialAccount.id)) })] }) }) }), children: _jsx(Icon, { icon: 'help-circle', size: 24 }) }))
        }
    }
}));
export const AncillaryExpensePresetAccountDataTable = Object.assign(BaseTable, {
    use: ({ data }) => {
        return AncillaryExpensePresetAccountDataTable.useCreateTable({
            data
        });
    }
});
