import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Bar, BarChart as BaseBarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { useTheme } from '@f2w/theme';
import { ChartTooltip } from 'Components/Chart/components/ChartTooltip';
import { useEventCallback } from '@restart/hooks';
import classNames from 'classnames';
export const BarChartOffset = {
    padding: { left: 20, right: 10 },
    margin: { top: 0, right: 0, left: 40, bottom: 100 },
};
export const BarChart = ({ data, config, variant: _variant = 'fill', barColor: _barColor = 'primary', className, offset = BarChartOffset, }) => {
    const theme = useTheme();
    const variant = variants[_variant] ?? variants.fill;
    const [current, setCurrent] = React.useState({});
    const { palette } = theme;
    const color = palette[_barColor] ?? palette.primary;
    const barSize = variant.barSize || 30;
    const onMouseEnter = useEventCallback((e, index) => setCurrent({ index, id: e.dataKey }));
    const onMouseLeave = useEventCallback(() => setCurrent({}));
    const children = variant.Component({
        onMouseEnter,
        onMouseLeave,
        theme,
        color,
    });
    return (_jsx(ResponsiveContainer, { width: "100%", height: "100%", minHeight: 340, className: classNames(className), children: _jsxs(BaseBarChart, { data: data, margin: {
                ...BarChartOffset.margin,
                ...offset?.margin,
            }, barSize: barSize, barGap: variant.barGap ?? (-0.5 * barSize), reverseStackOrder: true, children: [_jsx(CartesianGrid, { stroke: palette.neutral.$100, fill: palette.white, vertical: false }), _jsx(XAxis, { dataKey: "name", axisLine: false, angle: -45, padding: {
                        ...BarChartOffset.padding,
                        ...offset?.padding,
                    }, dy: 5, minTickGap: -150, textAnchor: 'end', tick: {} }), _jsx(Tooltip, { ...{
                        config,
                        meta: {
                            ...current,
                            bars: config.bars,
                            activeData: data?.[current.index] ?? null,
                        },
                        isAnimationActive: false,
                        offset: 20,
                        cursor: { fill: 'transparent' },
                        wrapperStyle: { outline: 'none', border: 'none' },
                        content: TooltipContent,
                    } }), children] }) }));
};
const TooltipContent = ({ meta: { activeData }, config, label, }) => {
    return _jsxs(ChartTooltip.Root, { active: !!activeData, children: [_jsx(ChartTooltip.Title, { children: label }), activeData && _jsx("table", { children: _jsx("tbody", { children: config?.bars?.map((bar, key) => (_jsxs("tr", { children: [_jsx("th", { children: bar.name }), _jsx("td", { align: 'right', children: bar?.getValue?.(activeData) })] }, `cell-${key}`))) }) })] });
};
const variants = {
    fill: {
        barSize: 20,
        barGap: -10,
        Component: ({ color, theme: { palette }, ...props }) => {
            const commonProps = {
                ...props,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: 0,
                cursor: 'pointer',
            };
            return _jsxs(_Fragment, { children: [_jsx(Bar, { ...{
                            ...commonProps,
                            dataKey: `in`,
                            fill: color.$200,
                        } }), _jsx(Bar, { ...{
                            ...commonProps,
                            dataKey: `out`,
                            fill: color.$500,
                        } })] });
        },
    },
    fill2: {
        barSize: 20,
        barGap: -10,
        Component: ({ color, theme: { palette }, ...props }) => {
            const commonProps = {
                ...props,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: 0,
                cursor: 'pointer',
            };
            return _jsxs(_Fragment, { children: [_jsx(Bar, { ...{
                            ...commonProps,
                            dataKey: `out`,
                            fill: color.$500,
                        } }), _jsx(Bar, { ...{
                            ...commonProps,
                            dataKey: `in`,
                            fillOpacity: .7,
                            fill: color.$200,
                        } })] });
        },
    },
    fillStroke: {
        barSize: 30,
        barGap: -28,
        Component: ({ color, theme: { palette }, ...props }) => {
            const commonProps = {
                ...props,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                cursor: 'pointer',
            };
            return _jsxs(_Fragment, { children: [_jsx(Bar, { ...{
                            ...commonProps,
                            dataKey: `in`,
                            barSize: 26,
                            fill: color.$200,
                            stroke: color.$400,
                            strokeWidth: 2,
                        } }), _jsx(Bar, { ...{
                            ...commonProps,
                            dataKey: `out`,
                            fill: color.$500,
                            fillOpacity: .3,
                            stroke: color.$500,
                            strokeOpacity: .8,
                            strokeWidth: 0,
                        } })] });
        },
    },
    fillStroke2: {
        barSize: 30,
        barGap: -30,
        Component: ({ color, theme: { palette }, ...props }) => {
            const commonProps = {
                ...props,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                cursor: 'pointer',
            };
            return _jsxs(_Fragment, { children: [_jsx(Bar, { ...{
                            ...commonProps,
                            dataKey: `in`,
                            fill: color.$100,
                            stroke: palette.white,
                            strokeWidth: 4,
                        } }), _jsx(Bar, { ...{
                            ...commonProps,
                            dataKey: `out`,
                            fill: 'transparent',
                            stroke: color.$500,
                            strokeWidth: 2,
                        } })] });
        },
    },
};
