import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { AbstractFormType, ImageType, StringType } from '@f2w/form';
import { AlignmentType, AlignmentTypeEnum } from 'Pages/DocumentTemplates/GlobalSettings/types/AlignmentType';
import { LogoSizeType } from 'Pages/DocumentTemplates/GlobalSettings/types/LogoSizeType';
import { LogoPositionType } from 'Pages/DocumentTemplates/GlobalSettings/types/LogoPositionType';
import { translate } from 'Services/Translator';
export class DocumentTemplateGlobalSettingsType extends AbstractFormType {
    getUpsertData() {
        const logoPosition = this.value.logoPosition.alignment;
        let logoSettings = {
            logoPosition,
            logoWidth: this.value.logoSize.width,
            logoHeight: this.value.logoSize.height,
            logoOffsetLeft: 0,
            logoOffsetTop: 0,
            logo: this.children.logo.value,
        };
        if (logoPosition === AlignmentTypeEnum.CUSTOM) {
            logoSettings.logoOffsetLeft = this.value.logoPosition.offset.left;
            logoSettings.logoOffsetTop = this.value.logoPosition.offset.top;
        }
        return ({
            header: this.value.header,
            headerAlignment: this.value.headerAlignment,
            ...logoSettings,
            addressAlignment: this.value.addressAlignment,
            footerAlignment: this.value.footerAlignment,
        });
    }
    buildFields() {
        return {
            header: new StringType({
                label: translate('globalTemplateSettings.header'),
                required: false,
                constraints: {
                    max: 255,
                },
            }),
            headerAlignment: new AlignmentType({
                label: translate('globalTemplateSettings.headerAlignment'),
                required: false,
                defaultValue: AlignmentTypeEnum.LEFT,
            }),
            logo: new ImageType({
                required: false,
                onImageLoad: async (img, t) => {
                    this.children.logoSize.setImageMeta(img, false);
                },
                onImageChange: async (img, t) => {
                    this.children.logoSize.setImageMeta(img, true);
                },
            }),
            logoSize: new LogoSizeType({
                label: translate('globalTemplateSettings.logoSize'),
                isVisible: () => {
                    return !!this.children?.logo?.field?.value;
                },
            }),
            logoPosition: new LogoPositionType(),
            addressAlignment: new AlignmentType({
                required: false,
                label: translate('globalTemplateSettings.addressAlignment'),
                includeCenter: false,
                defaultValue: AlignmentTypeEnum.LEFT,
            }),
            footerAlignment: new AlignmentType({
                required: false,
                label: translate('globalTemplateSettings.footerAlignment'),
                defaultValue: AlignmentTypeEnum.RIGHT,
            }),
        };
    }
    _renderWidget(props) {
        return (_jsx(_Fragment, { children: super._renderWidget({ style: { display: 'flex', flexDirection: 'column', gap: 25 }, ...props }) }));
    }
}
