import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, SimpleType } from '../core';
import { translate } from 'Services/Translator';
import PasswordStrengthIndicator from 'Components/Atoms/PasswordStrengthIndicator/PasswordStrengthIndicator';
import { cleanObj } from 'Utils';
export class PasswordType extends SimpleType.ExtendString {
    constructor(options, parent) {
        super({
            ...options,
            constraints: {
                max: 255,
                ...options?.constraints,
            }
        }, parent);
    }
    async validate(value) {
        await super.validate(value);
        const repeatType = this.options?.repeat?.();
        if (repeatType && repeatType.field.value !== this.field.value) {
            this.throwValidationError(translate('account.passwordsMustMatch'));
        }
    }
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: translate('Password'),
            displayStrengthIndicator: true,
        };
    }
    getWidgetProps() {
        return {
            ...super.getWidgetProps(),
            type: 'password',
        };
    }
    _renderWidget(props) {
        return _jsxs(_Fragment, { children: [super._renderWidget(props), this.options.displayStrengthIndicator && _jsx(PasswordStrengthIndicator, { password: this.field.value ?? '' })] });
    }
}
export class PasswordRepeatType extends AbstractFormType {
    constructor(options, parent) {
        super({ inherit: true, label: translate('Password'), ...options }, parent);
    }
    buildFields() {
        const { nullable, required, min } = this.options;
        return {
            password: new PasswordType({
                label: translate('Password'),
                nullable, required,
                constraints: cleanObj({
                    min: min && [min, translate('account.register.password.rules', { min })],
                    max: [255, translate('form.validation.noLongerThan', { length: 255 })],
                }),
            }),
            repeatPassword: new PasswordType({
                label: translate('Repeat Password'),
                nullable,
                required,
                displayStrengthIndicator: false,
                repeat: () => this.children.password,
            }),
        };
    }
}
