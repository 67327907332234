import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import { Table } from '@f2w/data-table/table-types';
import { Button } from 'Components/Atoms/Button';
import { Icon } from 'Components/Atoms/Icon';
import { translate } from 'Services/Translator';
import Actions from './DebitorDataTableActions';
import DebitorInvoiceState from 'Pages/Debitor/components/DebitorInvoiceState';
import { formatAmount, formatMoney } from 'Services/NumberHelper';
import { UseStyled } from '@f2w/theme';
import { css } from 'styled-components';
import generateRoute from 'Services/Router';
import ReminderStateSelect from 'Pages/Debitor/components/ReminderStateSelect';
import { DebitorInvoiceStateFilter } from 'Pages/Debitor/components/DebitorInvoiceStateFilter';
import Tooltip from 'Components/Tooltip';
import { PersonsField as PersonsCell, RentalObjectCard, } from 'Components/App/card';
import { DateType } from '@f2w/form';
import { DebitorInvoiceOverdueFilter } from './DebitorInvoiceOverdueFilter';
const AmountCell = UseStyled.styled('div', ({ theme: { palette, rem } }) => css `
    strong {
        color: ${palette.primary.$500};
        font-size: ${rem(16)};
        font-weight: 500;
        letter-spacing: 0.02em;
    }
`);
const PaymentsCell = UseStyled.styled('div', () => ({
    display: "flex",
    alignItems: "center"
}));
const PaymentsCellQuantity = UseStyled.styled('a', ({ theme: { palette, rem } }) => ({
    display: 'block',
    color: palette.primary.$500,
    fontSize: rem(16),
    fontWeight: 400,
    paddingRight: rem(16),
    borderRight: `1px solid ${palette.neutral.$100}`
}));
const PaymentsCellDueTo = UseStyled.styled('div', ({ theme: { palette, rem } }) => css `
    color: ${palette.neutral.$900};
    padding-left: ${rem(16)};

    label {
        display: block;
        font-weight: 500;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        font-size: ${rem(10)};
        margin-bottom: 0;
    }

    span {
        font-weight: 400;
        font-size: ${rem(14)};
    }
`);
const PaymentsOverlay = ({ debitorInvoice }) => _jsxs(_Fragment, { children: [_jsx("div", { children: _jsx("strong", { children: translate('debitor.payments') }) }), debitorInvoice.payments.map((payment, i) => _jsxs("div", { children: [moment(payment.date).format('DD.MM.YYYY'), "\u00A0 / ", formatMoney(payment.amount), "\u00A0 / ", payment.account ? payment.account.label + ' - ' + payment.account.position : null] }, `payment-tooltip-${i}`))] });
const model = Table.factory()(() => ({
    tableId: 'Debitor',
    FiltersLayout: ({ renderFilter }) => (_jsxs(_Fragment, { children: [renderFilter('state', { minw: 180, maxw: 300 }), renderFilter('overdueOnly', { minw: 180, maxw: 300 })] })),
    BulkActions: ({ state: { selectedRowIds, }, bulkDelete, refresh, toggleAllRowsSelected }) => {
        const ids = Object.keys(selectedRowIds);
        return (_jsx("div", { children: _jsxs(Button, { disabled: ids.length === 0, color: 'error', variant: 'outline', onClick: () => bulkDelete([ids.map(id => parseInt(id)), refresh, toggleAllRowsSelected], {
                    help: translate('debitorInvoices.message.bulkDeleteConfirmation', {
                        numberOfInvoices: ids.length
                    })
                }), children: [_jsx(Icon, { icon: 'trash-2' }), " ", translate('debitorInvoices.bulkDelete')] }) }));
    },
    Actions: ({ onNewDebitorClicked }) => (_jsx("div", { children: _jsx(Button, { "$size": 'lg', color: 'primary', icon: 'plus', label: translate('new'), title: translate('debitor.new'), onClick: onNewDebitorClicked }) })),
    RowActions: Actions,
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    initialState: {
        pageSize: 25,
        sortBy: [
            { id: 'id', desc: true }
        ]
    },
    columns: {
        $rowSelect: {
            sticky: 'left',
        },
        $actions: {
            sticky: 'left',
        },
        propertyName: {
            Header: translate('debitor.property'),
            accessor: () => 'property.name',
            width: 400,
            Cell: ({ row: { original: { building, rentalObject } } }) => {
                return _jsx(RentalObjectCard, { rentalObjectName: rentalObject.name, rentalObjectId: rentalObject.id, buildingName: building.name });
            },
            useSortBy: {},
        },
        reference: {
            Header: translate('debitor.reference'),
            Cell: ({ row: { original: { state, reference, property } } }) => {
                if (reference === null) {
                    return translate('notAvailable');
                }
                if (state.key === "draft") {
                    return reference;
                }
                return (_jsx("a", { href: generateRoute('view_accounting.journal', {
                        query: reference,
                    }) + `&_for=property-${property.id}`, target: '_blank', children: reference }));
            },
        },
        label: {
            Header: translate('debitor.label'),
            accessor: 'label',
            useSortBy: {},
            Cell: ({ value: label }) => {
                const parts = label.split(/(^Mietzins \d\d\.\d\d\.\d\d\d\d - \d\d\.\d\d\.\d\d\d\d: )/);
                if (parts.length !== 3) {
                    return label;
                }
                return _jsxs(_Fragment, { children: [_jsx("span", { style: { color: "#57607F" }, children: parts[1] }), " ", _jsx("br", {}), " ", parts[2]] });
            }
        },
        personName: {
            Header: translate('debitor.person'),
            width: 150,
            accessor: (debitorInvoice) => debitorInvoice.person?.name,
            Cell: ({ row: { original: { person } } }) => {
                if (!person) {
                    return null;
                }
                return _jsx(PersonsCell, { persons: [person] });
            },
        },
        state: {
            Header: translate('debitor.state'),
            accessor: (debitorInvoice) => debitorInvoice.state.name,
            Cell: ({ row }) => {
                const debitorInvoice = row.original;
                return (_jsx(DebitorInvoiceState, { state: debitorInvoice.state.key }));
            },
            useFilters: {
                Filter: DebitorInvoiceStateFilter
            },
        },
        rentPeriod: {
            accessor: (debitorInvoice) => debitorInvoice.rentPeriod ? debitorInvoice.rentPeriod.from : null,
            useFilters: {},
            hide: true
        },
        personId: {
            useFilters: {},
            hide: true
        },
        rentId: {
            useFilters: {},
            hide: true
        },
        overdueOnly: {
            Header: translate('debitor.overdue'),
            useFilters: {
                Filter: DebitorInvoiceOverdueFilter
            },
            hide: true,
        },
        amountRemaining: {
            Header: () => _jsxs("span", { children: [translate('debitor.amount'), _jsx("br", {}), translate('debitor.remainingTotal')] }),
            accessor: 'amountRemaining',
            cellProps: { noWrap: true },
            Cell: ({ row }) => (_jsxs(AmountCell, { children: ["CHF ", _jsx("strong", { children: formatAmount(row.original.amountRemaining) }), "/", _jsx("span", { children: formatAmount(row.original.amountGross) })] })),
            useSortBy: {},
        },
        paymentDueTo: {
            Header: translate('debitor.payments'),
            accessor: (debitorInvoice) => debitorInvoice.conditions.dueTo,
            Cell: ({ row }) => {
                const debitorInvoice = row.original;
                const urlParams = { invoiceId: debitorInvoice.id };
                return _jsxs(PaymentsCell, { children: [_jsx(PaymentsCellQuantity, { href: generateRoute('list_debitor_invoices', urlParams) + '#tab_payments_content', children: debitorInvoice.nbPayments > 0 ?
                                _jsx(Tooltip, { placement: "top", overlay: _jsx(PaymentsOverlay, { debitorInvoice: debitorInvoice }), children: _jsx("div", { children: debitorInvoice.nbPayments }) })
                                :
                                    0 }), _jsx(PaymentsCellDueTo, { children: debitorInvoice.dueTo && _jsxs(_Fragment, { children: [_jsx("label", { children: translate('debitor.paymentDueTo') }), _jsx("span", { children: DateType.format(debitorInvoice.dueTo) })] }) })] });
            },
            useSortBy: {},
        },
        reminderState: {
            Header: translate('debitor.reminderState'),
            accessor: 'reminderState',
            width: 75,
            useSortBy: {},
            Cell: (instance) => {
                const debitorInvoice = instance.row.original;
                const id = debitorInvoice.id;
                return (_jsx(ReminderStateSelect, { useMaxContentWidth: true, menuShouldBlockScroll: true, menuPortalTarget: document.body, value: debitorInvoice.reminderState, onChange: async ({ value }) => {
                        instance.dataResponse.updateResults((data) => {
                            if (data.id === id) {
                                data.reminderState = value;
                            }
                            return data;
                        });
                        try {
                            await instance.updateReminderState(debitorInvoice.id, value);
                        }
                        catch (e) {
                            instance.refresh();
                        }
                    } }));
            }
        },
    },
}));
export default model;
