import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef, useMemo } from "react";
import { useTheme } from "@f2w/theme";
import { PieChart } from "Components/Chart/PieChart";
import { toNum } from "Utils/types";
import { FinancialsPieChartLegend } from "./FinancialsPieChartLegend";
import { formatMoney } from "Services/NumberHelper";
const moneyFormat = "$0,0";
export const FinancialsPieChart = forwardRef(({ data }, ref) => {
    const { palette, typography } = useTheme();
    const config = useMemo(() => {
        return {
            dataKey: 'investmentValue.value',
        };
    }, []);
    const totalData = React.useMemo(() => {
        const value = data.reduce((prev, item) => prev + toNum(item.investmentValue.value), 0);
        return {
            title: "Total",
            value: formatMoney(value, moneyFormat),
            percent: 1,
        };
    }, [data]);
    return (_jsx(PieChart, { colors: ["#9F78ED", "#16AC88", "#FCA524", "#4766FF", "#FA3A3A", "#D7D8DB"], data: data, config: config, totalInfo: totalData, Legend: FinancialsPieChartLegend }));
});
