import { OrFunction } from 'Utils/types';
import defineOptionsModifiers from './options/OptionsModifiers';
import { translate } from 'Services/Translator';
class ValueSpecs {
    _props;
    constructor(props) {
        this._props = props;
    }
    extend(props) {
        this._props = props;
        return this;
    }
    specs() {
        return {};
    }
    static create(props) {
        const specs = new ValueSpecs({});
        return specs;
    }
}
export function createSpecsApi(props) {
    const mutate = (fn) => {
        const _s = _this.getSchema();
        _this._schema = fn(_s) ?? _s;
    };
    const _this = {
        _schema: null,
        _store: Object.create(null),
        _initialOptions: null,
        optionsApi: defineOptionsModifiers(p => props.getOptionsModifiers?.({ ...p, mutate }), p => props.getConstraints({ ...p, mutate })),
        getInitialOptions(opts) {
            if (!_this._initialOptions || opts) {
                const defaultOptions = OrFunction(props.defaultOptions);
                _this._initialOptions = {
                    ...defaultOptions,
                    ...opts,
                    constraints: {
                        ...defaultOptions?.constraints,
                        ...opts?.constraints
                    }
                };
            }
            return _this._initialOptions;
        },
        createSchema() {
            let schema = props.schemaType()
                .label('This')
                .typeError(translate('form.validation.fieldIsRequired'));
            if (props.getSchema)
                _this._schema = props.getSchema(_this._schema);
            return _this._schema;
        },
        getSchema() {
            if (!_this._schema) {
                _this._schema = props.schemaType()
                    .label('This')
                    .typeError(translate('form.validation.fieldIsRequired'));
                if (props.getSchema)
                    _this._schema = props.getSchema(_this._schema);
            }
            return _this._schema;
        },
        clearSchema() {
            _this._schema = null;
        },
        clearOptions() {
            _this.optionsApi.clear();
        },
        clearAll() {
            _this.clearSchema();
            _this.clearOptions();
        },
    };
    return {
        store: _this._store,
        _dump: () => ({
            initialOptions: _this._initialOptions,
            options: { ..._this.optionsApi.options },
            privateOptions: {
                ..._this.optionsApi.own,
            },
            validators: _this.optionsApi.validators,
        }),
        get schema() {
            return _this.getSchema();
        },
        get initialOptions() {
            return _this.getInitialOptions();
        },
        get options() {
            return _this.optionsApi.options;
        },
        init(opts) {
            _this.clearAll();
            _this.optionsApi.update(_this.getInitialOptions(opts));
        },
        update(opts) {
            _this.optionsApi.update(opts);
        },
        reset() {
            _this.clearSchema();
            _this.clearOptions();
            _this.optionsApi.update(_this.getInitialOptions());
        },
        resetSchema() {
            _this.clearSchema();
            _this.optionsApi.update(_this.optionsApi.options);
        },
    };
}
