import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { formatMoney } from 'Services/NumberHelper';
import { Text } from '../Text';
export const MoneyText = ({ value, negative, positive }) => {
    const color = useMemo(() => {
        if (value > 0)
            return positive ? positive === true ? 'success.$500' : (positive || undefined) : undefined;
        if (value < 0)
            return negative ? negative === true ? 'error.$500' : (negative || undefined) : undefined;
        return undefined;
    }, [value, negative, positive]);
    return (_jsx(Text, { nowrap: true, colorName: color, children: formatMoney(value) }));
};
