import classnames from 'classnames';
const handleBaseProps = (props, column) => {
    if (column.type)
        props['type'] = column.type;
    if (column.sticky)
        props.sticky = column.sticky;
    return {
        ...props,
        style: { ...column?.style, ...props.style },
        className: classnames(column.className, props.className),
    };
};
const handleSizeProps = (props, column) => {
    const sizeStyles = {};
    if (column.width && column.width !== 150)
        sizeStyles.width = column.width;
    if (column.minWidth)
        sizeStyles.minWidth = column.minWidth;
    if (column.hasOwnProperty('maxWidth') && column.maxWidth !== 9007199254740991)
        sizeStyles.maxWidth = column.maxWidth;
    return handleBaseProps({
        ...props,
        style: { ...props.style, ...sizeStyles },
    }, column);
};
export const ColumnCellProps = (hooks) => {
    hooks.getHeaderProps.push((props, meta) => {
        return handleSizeProps(props, meta.column);
    });
    hooks.getCellProps.push((props, meta) => {
        return handleBaseProps(props, meta.cell.column);
    });
};
export const PreTableHook = (hooks) => {
};
export const PostTableHook = (hooks) => {
    ColumnCellProps(hooks);
    hooks.useInstanceBeforeDimensions.push((instance) => {
        const { dataResponse, manualPagination, } = instance;
        if (manualPagination && dataResponse) {
            Object.assign(instance, {
                pageCount: dataResponse.totalPages,
            });
        }
    });
    hooks.useInstance.push((instance) => {
        const { dataResponse, manualControl, rows, state: { pageIndex }, } = instance;
        if (manualControl && dataResponse) {
            Object.assign(instance, {
                pageRows: rows,
                pageIndex: dataResponse.currentPage - 1,
                pageTotalElements: dataResponse.totalElements,
            });
        }
        else {
            Object.assign(instance, {
                pageRows: instance.page || instance.rows,
                pageIndex,
                pageTotalElements: rows?.length ?? 0,
            });
        }
    });
    hooks.visibleColumns.push((columns, meta) => {
        const colsStart = [];
        const colsEnd = [];
        columns = columns.filter(c => {
            if (c.sticky) {
                c.sticky === 'right' ? colsEnd.push(c) : colsStart.push(c);
                return false;
            }
            return true;
        });
        return [
            ...colsStart,
            ...columns,
            ...colsEnd,
        ];
    });
};
