import client from "Utils/client";
import generateRoute from "Services/Router";
export const orderSubscription = async (subscriptionPlanOptionId, limits, billingDetails) => {
    const { data } = await client.post(generateRoute('api.subscription.order'), {
        subscriptionPlanOptionId,
        limits,
        billingContact: billingDetails
    });
    return data;
};
