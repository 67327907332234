import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import { FlexCol, FlexRow, Title } from 'Components/Atoms';
import { Header, Section } from 'Atoms/Layout/components';
import { Text } from 'Atoms/Text';
export const PersonsTab = ({ group: view, }) => {
    const { contractParties, residents } = view.data;
    return (_jsxs(_Fragment, { children: [_jsx(Header, { children: _jsx(Title, { large: 500, as: 'h4', children: translate('contract.details.contractParties') }) }), _jsx(Section, { children: contractParties.map(contractParty => (_jsx(React.Fragment, { children: _jsxs(FlexRow, { mt: 20, justifySpaceBetween: true, children: [_jsx(Text, { neutral: 500, medium: true, children: translate('contract.details.contractParty') }), _jsx(Text, { neutral: 900, medium: true, children: contractParty.name })] }) }, contractParty.id))) }), _jsx(Section.Divider, { withPadding: true, size: 1, spacing: '20px' }), _jsx(Header, { children: _jsx(Title, { large: 500, as: 'h4', children: translate('contract.details.residents') }) }), _jsx(Section, { children: _jsx(FlexCol, { gap: 20, children: residents.map(resident => (_jsx(React.Fragment, { children: _jsxs(FlexRow, { justifySpaceBetween: true, children: [_jsx(Text, { neutral: 500, medium: true, children: translate('contract.details.resident') }), _jsx(Text, { neutral: 900, medium: true, children: resident.name })] }) }, resident.id))) }) })] }));
};
