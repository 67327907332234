import { useFormModal } from '@f2w/form';
import { translate } from 'Services/Translator';
import { OpeningBalanceType } from 'Pages/Accounting/OpeningBalance/OpeningBalanceType';
import { dispatchCustomEvent } from "Services/EventManager";
export const useOpeningBalanceForm = ({ onSaved, accountingRecordServices: { setOpeningBalance, getOpeningBalance } }) => {
    return useFormModal({
        name: 'opening-balance-form',
        title: () => translate('accounting.account.openingBalance.edit'),
        type: () => new OpeningBalanceType(),
        onSave: ({ value, fiscalYearId, financialAccountId }) => {
            return setOpeningBalance(financialAccountId, fiscalYearId, value)
                .then(() => {
                dispatchCustomEvent('fw.openingBalanceUpdated', {
                    fiscalYearId: fiscalYearId,
                    financialAccountId: financialAccountId,
                });
                onSaved();
            });
        },
        onLoad: async (props) => ({
            ...await getOpeningBalance(props.financialAccountId, props.fiscalYearId),
            ...props
        })
    });
};
