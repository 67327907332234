import styled from 'styled-components';
export const CardDeck = styled.div.attrs(() => ({
    className: 'card-deck'
})) ``;
export const CardGroup = styled.div.attrs(() => ({
    className: 'card-group'
})) ``;
export const CardColumns = styled.div.attrs(() => ({
    className: 'card-columns',
})) ``;
