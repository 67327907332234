import { getIn, setIn } from 'formik';
import { isScalar, isString } from 'Utils/types';
import humanizeString from 'Utils/humanizeString';
const formatPath = (prefix, name) => isScalar(prefix) && isScalar(name) ? `${prefix}[${name}]` : (prefix ?? name ?? null);
export class FormField {
    isVisible = true;
    _props = Object.create(null);
    _state = Object.create(null);
    _schema;
    _children;
    constructor(type, { initialValue, ...props }, initialState = {}) {
        this._type = type;
        this._state = initialState;
        this._schema = type.schema.clone();
        this._children = new Map();
        const fallbackLabel = isString(type.options.label) ? type.options.label : humanizeString(props.name);
        this._props = {
            ...props,
            showLabel: !(type.options.label === false || this.isRoot),
            label: () => props.label?.() ?? fallbackLabel,
        };
        this._updatePath();
        if (this.parent) {
            this.parent._addChild(this);
        }
    }
    _updatePath() {
        const { name } = this._props;
        this._props.id = `${this.parent?.id ?? 'fw-form'}-${name}`;
        this._props.path = formatPath(this.parent?.path, name);
    }
    get props() {
        return this._props;
    }
    get state() {
        return this._state;
    }
    updateState(meta) {
        Object.assign(this._state, meta);
    }
    cast(value) {
        return this._type.cast(value);
    }
    _addChild(child) {
        this._children.set(child.key, child);
    }
    _removeChild(key) {
        if (this._children.has(key)) {
            this._children.delete(key);
        }
    }
    reset() {
        Object.assign(this._state, {
            value: undefined,
            validatedValue: undefined,
            validated: undefined,
            error: undefined,
            touched: undefined,
        });
        if (!this.isCompound) {
            this.setTouched(false, false);
        }
        this._children.forEach(child => {
            child.reset();
        });
    }
    updateError(key, value) {
        const state = this.state;
        if (!value) {
            if (state.error?.[key]) {
                state.error[key] = null;
                delete state.error[key];
                if (!Object.keys(state.error).length) {
                    this.parent?.updateError?.(this.key);
                    state.error = null;
                    delete state.error;
                }
            }
        }
        else {
            if (!state.error) {
                this.parent?.updateError?.(this.key, state.error = {
                    [this.key]: {}
                });
            }
            this._children.forEach(child => {
                this.updateError(child.key);
            });
            state.error[key] = value;
        }
    }
    get value() {
        return this.getFormikValue();
    }
    get initialValue() {
        return this.getFormikInitialValue();
    }
    setValue(newValue, shouldValidate) {
        this.formik.setFieldValue(this.path, newValue, shouldValidate);
    }
    setInitialValue(newValue, shouldValidate) {
        setIn(this.formik.initialValues, this.path, newValue);
        this.formik.setFieldValue(this.path, newValue, shouldValidate);
    }
    setInternalValue(newValue) {
        this.setInitialValue(newValue);
    }
    get touched() {
        return (this.state.touched || this.getFormikTouched());
    }
    setTouched(value, shouldValidate) {
        if (this.isCompound) {
            this._children.forEach(child => {
                child.setTouched(value, shouldValidate);
            });
        }
        else {
            this.state.touched = value;
            this.formik.setFieldTouched(this.path, value, shouldValidate);
        }
    }
    setError(value) {
        this.state.validated = true;
        this.state.error = value;
        this.state.validatedValue = this.value;
        this.setTouched(true);
        this.formik.setFieldError(this.path, value);
    }
    get isValidated() {
        return this.state.validated;
    }
    get showError() {
        return this.touched && this.isValidated && !!this.error;
    }
    get error() {
        return this.state.error;
    }
    __dump() {
        const { path, id, name, label, ...rp } = this._props;
        return {
            path,
            touched: undefined,
            initialValue: this.initialValue,
            value: undefined,
            validated: undefined,
            error: undefined,
            ...this.state,
            ...rp,
        };
    }
    use(props) {
        return this;
    }
    getRoot() {
        return this._type.getRoot().field;
    }
    get parent() {
        return this._type.parent?.field;
    }
    get key() {
        return this._props.name;
    }
    get name() {
        return this._props.path;
    }
    get path() {
        return this._props.path;
    }
    get id() {
        return this._props.id;
    }
    get showLabel() {
        return this._props?.showLabel ?? true;
    }
    set showLabel(value) {
        this._props.showLabel = value;
    }
    get label() {
        return this._props._label ?? this._props.label();
    }
    set label(value) {
        this._props._label = value;
    }
    get isRoot() {
        return !this.parent;
    }
    get isRequired() {
        return this._type.schema.spec?.presence === 'required';
    }
    get isOptional() {
        return this._type.schema.spec?.presence === 'optional';
    }
    get isNullable() {
        return this._type.schema.spec?.nullable;
    }
    shouldValidate(value) {
        return !this.isValidated || value !== this.state.validatedValue;
    }
    async validate(force) {
        if (this.isCompound) {
            const p = [];
            this._children.forEach(child => {
                p.push(child.validate(force));
            });
            await Promise.allSettled(p);
        }
        else {
            if (force)
                this.state.validated = false;
            this.setTouched(true, true);
        }
        return this.getFormikError();
    }
    async _validate(value, force) {
        const type = this._type;
        if (force || this.shouldValidate(value)) {
            const newError = await type.validate(value);
            this.state.error = newError;
            this.state.validated = true;
            return newError;
        }
        return this.state.error;
    }
    getFormikTouched() {
        return this.getIn(this.formik.touched);
    }
    getFormikValue() {
        return this.getIn(this.formik.values);
    }
    getFormikInitialValue() {
        return this.getIn(this.formik.initialValues);
    }
    getFormikError() {
        return this.getIn(this.formik.errors);
    }
    setIn(object, path, value = undefined) {
        return setIn(object, path ?? this.path, value);
    }
    getIn(values, path) {
        return getIn(values, path ?? this.path);
    }
    get formik() {
        return this._type.getFormik();
    }
    log(message, ...args) {
        console.log.apply(window.console, [`${message}[${this.key}]`, ...args]);
    }
}
export class AbstractSimpleField extends FormField {
    _updateValue({ value, updateInitial, shouldValidate = !updateInitial, skipTouched = !shouldValidate, ignoreEvents, }) {
        if (value !== this.state.value || !this.state.touched) {
            this.state.value = value;
            const typeValue = this.cast(value);
            if (typeValue !== this.value) {
                if (updateInitial)
                    this.parent.initialValue[this.key] = typeValue;
                if (!skipTouched)
                    this.state.touched = true;
                if (shouldValidate && this._type.options.deps)
                    this._type.options.deps?.()?.forEach(dep => {
                        if (dep?.field && dep.field.id !== this.id) {
                            dep.field.state.validated = false;
                            dep.field.state.touched = true;
                        }
                    });
                super.setValue(typeValue, shouldValidate);
                ignoreEvents || this._type.options.onChange?.(typeValue, this._type);
            }
        }
    }
    updateValue(value, options) {
        this._updateValue({ value, ...options });
    }
    setValue(value, shouldValidate = true, skipTouched = !shouldValidate) {
        this._updateValue({ value, shouldValidate, skipTouched });
    }
    setInternalValue(value) {
        this._updateValue({ value, updateInitial: true });
    }
}
