import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ModalNew } from "Components/Modal";
import { translate } from "Services/Translator";
import { Div, FlexCol } from "Atoms/Layout";
import { Select } from "Atoms/Select";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { AncillaryExpensePresetAccountDataTable } from "Pages/AncillaryExpense/table/AncillaryExpensePresetAccountDataTable";
import { Button, ButtonToolbar } from "Components/Atoms";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { DataTable } from "@f2w/data-table";
export const ChoosePresetAccountsModal = ({ onClose, getAllPresetAccounts, createAccountsUsingPresetForProperties }) => {
    const [presetAccounts, setPresetAccounts] = React.useState([]);
    const [propertyOptions, setPropertyOptions] = React.useState([]);
    const [selectedPropertyIds, setSelectedPropertyIds] = React.useState([]);
    const [submitting, setSubmitting] = React.useState(false);
    const portfolio = usePortfolio();
    const instance = AncillaryExpensePresetAccountDataTable.use({
        data: presetAccounts
    });
    React.useEffect(() => {
        void getAllPresetAccounts()
            .then(presetAccounts => {
            const properties = portfolio.getProperties();
            setPropertyOptions(properties.map(property => ({
                label: property.name,
                value: property.id
            })));
            setPresetAccounts(presetAccounts);
        });
    }, []);
    const { success } = useToastDispatcherApi();
    const onCreateClicked = React.useCallback(() => {
        setSubmitting(true);
        const presetAccountIds = instance
            .selectedFlatRows
            .map(row => Number(row.id));
        void createAccountsUsingPresetForProperties(selectedPropertyIds, presetAccountIds)
            .then(() => {
            const selectedPropertyNames = propertyOptions
                .filter(propertyOption => selectedPropertyIds.includes(propertyOption.value))
                .map(propertyOption => propertyOption.label)
                .join(', ');
            success({
                message: translate('ancillaryExpenseAccount.createUsingPreset.success', {
                    propertyNames: selectedPropertyNames
                }),
            });
            onClose();
        })
            .catch(() => setSubmitting(false));
    }, [selectedPropertyIds.length, instance.selectedFlatRows.length]);
    return (_jsxs(ModalNew, { show: true, size: { maxWidth: '60%', maxHeight: '800px', minHeight: '600px' }, onHide: () => !submitting && onClose(), children: [_jsx(ModalNew.Header, { closeButton: true, onHide: () => !submitting && onClose(), children: _jsx(ModalNew.Title, { children: translate('ancillaryExpenseAccount.setupAssistant') }) }), _jsx(ModalNew.Body, { children: _jsxs(FlexCol, { gap: 24, children: [_jsx(Div, { children: _jsx(DataTable.SimpleTable, { instance: instance, height: '330px', scroll: true }) }), _jsxs(Div, { children: [_jsx("label", { htmlFor: "setupAssistantProperties", style: { fontWeight: 500 }, children: translate('ancillaryExpenseAccount.selectProperties') }), _jsx(Select, { id: "setupAssistantProperties", options: propertyOptions, isSearchable: true, isMulti: true, container: true, onChange: (selectedOptions) => {
                                        setSelectedPropertyIds(selectedOptions.map(selectedOption => selectedOption.value));
                                    } })] })] }) }), _jsx(ModalNew.Footer, { children: _jsxs(ButtonToolbar, { children: [_jsx(Button, { disabled: submitting, variant: 'outline', onClick: onClose, children: translate('btn.cancel') }), _jsx(Button, { disabled: submitting || !selectedPropertyIds.length || !instance.selectedFlatRows.length, onClick: onCreateClicked, children: translate('ancillaryExpenseAccount.btn.createSelectedAccounts', {
                                numAccounts: instance.selectedFlatRows.length
                            }) })] }) })] }));
};
