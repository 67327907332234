import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import { TaskPriority } from "Types/Task";
import { translate } from "Services/Translator";
const options = [
    { value: TaskPriority.LOW, label: translate('task.priority.low') },
    { value: TaskPriority.NORMAL, label: translate('task.priority.normal') },
    { value: TaskPriority.HIGH, label: translate('task.priority.high') },
];
const TaskPrioritySelect = ({ ...props }) => {
    return (_jsx(Select, { options: options, ...props }));
};
export default TaskPrioritySelect;
