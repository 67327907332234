import { hsbToHex } from '../utils';
const createColor = (color) => {
    return color;
};
const createStateColor = (color) => {
    return color;
};
const tokens = {
    primary: createStateColor({
        $10: hsbToHex(230, 2, 100),
        $25: hsbToHex(230, 4, 100),
        $50: hsbToHex(230, 7, 100),
        $60: hsbToHex(230, 10, 100),
        $70: hsbToHex(230, 13, 100),
        $80: hsbToHex(230, 16, 100),
        $90: hsbToHex(230, 19, 100),
        $100: hsbToHex(230, 22, 100),
        $150: hsbToHex(230, 28, 100),
        $200: hsbToHex(230, 33, 100),
        $300: hsbToHex(230, 48, 100),
        $400: hsbToHex(230, 58, 100),
        $500: hsbToHex(230, 72, 100),
        $600: hsbToHex(230, 72, 85),
        $700: hsbToHex(230, 72, 71),
        $800: hsbToHex(230, 72, 55),
        $900: hsbToHex(230, 72, 42),
    }),
    secondary: createStateColor({
        $10: hsbToHex(260, 2, 100),
        $25: hsbToHex(260, 4, 100),
        $50: hsbToHex(260, 6, 99),
        $60: hsbToHex(260, 9, 99),
        $70: hsbToHex(260, 12, 99),
        $80: hsbToHex(260, 14, 98),
        $90: hsbToHex(260, 16, 98),
        $100: hsbToHex(260, 18, 97),
        $150: hsbToHex(260, 22, 96),
        $200: hsbToHex(260, 27, 96),
        $300: hsbToHex(260, 41, 94),
        $400: hsbToHex(260, 49, 93),
        $500: hsbToHex(260, 63, 91),
        $600: hsbToHex(260, 63, 83),
        $700: hsbToHex(260, 63, 65),
        $800: hsbToHex(260, 63, 50),
        $900: hsbToHex(260, 63, 38),
    }),
    warning: createStateColor({
        $10: hsbToHex(35, 2, 100),
        $25: hsbToHex(35, 4, 100),
        $50: hsbToHex(35, 9, 100),
        $60: hsbToHex(35, 9, 99),
        $70: hsbToHex(35, 12, 99),
        $80: hsbToHex(35, 14, 98),
        $90: hsbToHex(35, 16, 98),
        $100: hsbToHex(35, 26, 100),
        $150: hsbToHex(35, 22, 96),
        $200: hsbToHex(35, 39, 100),
        $300: hsbToHex(35, 57, 100),
        $400: hsbToHex(35, 68, 100),
        $500: hsbToHex(35, 86, 100),
        $600: hsbToHex(35, 86, 89),
        $700: hsbToHex(35, 86, 70),
        $800: hsbToHex(35, 86, 55),
        $900: hsbToHex(35, 86, 42),
    }),
    success: createStateColor({
        $10: hsbToHex(166, 2, 100),
        $25: hsbToHex(166, 4, 100),
        $50: hsbToHex(166, 6, 97),
        $60: hsbToHex(166, 9, 99),
        $70: hsbToHex(166, 12, 99),
        $80: hsbToHex(166, 14, 98),
        $90: hsbToHex(166, 16, 98),
        $100: hsbToHex(166, 20, 90),
        $150: hsbToHex(166, 22, 96),
        $200: hsbToHex(166, 32, 85),
        $300: hsbToHex(166, 50, 78),
        $400: hsbToHex(166, 63, 74),
        $500: hsbToHex(166, 87, 67),
        $600: hsbToHex(166, 87, 62),
        $700: hsbToHex(166, 87, 48),
        $800: hsbToHex(166, 87, 37),
        $900: hsbToHex(166, 87, 28),
    }),
    error: createStateColor({
        $10: hsbToHex(0, 2, 100),
        $25: hsbToHex(0, 4, 100),
        $50: hsbToHex(0, 8, 100),
        $60: hsbToHex(0, 9, 99),
        $70: hsbToHex(0, 12, 99),
        $80: hsbToHex(0, 14, 98),
        $90: hsbToHex(0, 16, 98),
        $100: hsbToHex(0, 23, 100),
        $150: hsbToHex(0, 22, 96),
        $200: hsbToHex(0, 35, 100),
        $300: hsbToHex(0, 51, 100),
        $400: hsbToHex(0, 61, 98),
        $500: hsbToHex(0, 78, 100),
        $600: hsbToHex(0, 78, 88),
        $700: hsbToHex(0, 78, 70),
        $800: hsbToHex(0, 78, 54),
        $900: hsbToHex(0, 78, 41),
    }),
    neutral: createStateColor({
        $10: hsbToHex(0, 0, 98),
        $25: hsbToHex(0, 0, 97),
        $50: hsbToHex(0, 0, 95.5),
        $60: hsbToHex(0, 0, 94),
        $70: hsbToHex(225, 1, 93),
        $80: hsbToHex(225, 1, 91),
        $90: hsbToHex(225, 1, 89),
        $100: hsbToHex(225, 2, 86),
        $150: hsbToHex(222, 2, 80),
        $200: hsbToHex(218, 4, 74),
        $300: hsbToHex(225, 5, 63),
        $400: hsbToHex(222, 10, 53),
        $500: hsbToHex(221, 15, 42),
        $600: hsbToHex(219, 20, 33),
        $700: hsbToHex(220, 25, 23),
        $800: hsbToHex(219, 30, 18),
        $900: hsbToHex(220, 26, 9),
    }),
    dark: createColor({
        $10: '#f9f9f9',
        $25: '#f7f7f7',
        $50: '#f4f4f4',
        $100: '#d7d8db',
        $200: '#b5b8bd',
        $300: '#999ba1',
        $400: '#7a7e87',
        $500: '#5b606b',
        $600: '#434954',
        $700: '#2c313b',
        $800: '#20252e',
        $900: '#111317',
    }),
    light: createColor({
        $900: '#F4F4F4',
        $800: '#D7D8DB',
        $700: '#B5B8BD',
        $600: '#999BA1',
        $500: '#7A7E87',
        $400: '#5B606B',
        $300: '#434954',
        $200: '#2C313B',
        $100: '#20252E',
        $50: '#111317',
    }),
};
export default tokens;
const colorsOld = {
    neutral: {
        $50: '#F4F4F4',
        $100: '#D7D8DB',
        $200: '#B5B8BD',
        $300: '#999BA1',
        $400: '#7A7E87',
        $500: '#5B606B',
        $600: '#434954',
        $700: '#2C313B',
        $800: '#20252E',
        $900: '#111317',
    },
    dark: {
        $50: '#F4F4F4',
        $100: '#D7D8DB',
        $200: '#B5B8BD',
        $300: '#999BA1',
        $400: '#7A7E87',
        $500: '#5B606B',
        $600: '#434954',
        $700: '#2C313B',
        $800: '#20252E',
        $900: '#111317',
    },
    primary: createStateColor({
        $10: '#fafaff',
        $25: '#f5f6ff',
        $50: '#EDF0FF',
        $60: '#e6eaff',
        $70: '#dee4ff',
        $80: '#d6deff',
        $90: '#cfd7ff',
        $100: '#C6D0FF',
        $150: '#b8c5ff',
        $200: '#AAB9FF',
        $300: '#8498FF',
        $400: '#6C85FF',
        $500: '#4766FF',
        $502: hsbToHex(230, 72, 100),
        $600: '#415DE8',
        $700: '#3248B5',
        $800: '#27388C',
        $900: '#1E2B6B',
    }),
    secondary: {
        $50: '#f2edfc',
        $100: '#DACBF8',
        $200: '#C8B1F4',
        $300: '#AF8EF0',
        $400: '#9F78ED',
        $500: '#8756E8',
        $600: '#7B4ED3',
        $700: '#603DA5',
        $800: '#4A2F80',
        $900: '#392461',
    },
    success: {
        $50: '#E8F7F3',
        $100: '#B7E5DA',
        $200: '#94D9C8',
        $300: '#63C7AF',
        $400: '#45BDA0',
        $500: '#16AC88',
        $600: '#149D7C',
        $700: '#107A61',
        $800: '#0C5F4B',
        $900: '#094839',
    },
    warning: {
        $50: '#FFF6E9',
        $100: '#FEE3BB',
        $200: '#FED69A',
        $300: '#FDC36C',
        $400: '#FDB750',
        $500: '#FCA524',
        $600: '#E59621',
        $700: '#B3751A',
        $800: '#8B5B14',
        $900: '#6A450F',
    },
    error: {
        $50: '#FFEBEB',
        $100: '#FDC2C2',
        $200: '#FDA4A4',
        $300: '#FC7B7B',
        $400: '#FB6161',
        $500: '#FA3A3A',
        $600: '#E43535',
        $700: '#B22929',
        $800: '#8A2020',
        $900: '#691818',
    },
};
