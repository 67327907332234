import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useContext } from 'react';
import classNames from 'classnames';
import TabContext from 'Components/Base/TabContext';
import SelectableContext, { makeEventKey } from 'Components/Base/SelectableContext';
import { Fade } from 'Components/Transition';
function useTabContext(props) {
    const context = useContext(TabContext);
    if (!context)
        return props;
    const { activeKey, getControlledId, getControllerId, ...rest } = context;
    const shouldTransition = props.transition !== false && rest.transition !== false;
    const key = makeEventKey(props.eventKey);
    return {
        ...props,
        active: props.active == null && key != null
            ? makeEventKey(activeKey) === key
            : props.active,
        id: getControlledId(props.eventKey),
        'aria-labelledby': getControllerId(props.eventKey),
        transition: shouldTransition && (props.transition || rest.transition || Fade),
        mountOnEnter: props.mountOnEnter != null ? props.mountOnEnter : rest.mountOnEnter,
        unmountOnExit: props.unmountOnExit != null ? props.unmountOnExit : rest.unmountOnExit,
    };
}
export const TabPane = forwardRef((props, ref) => {
    const { className, active, onEnter, onEntering, onEntered, onExit, onExiting, onExited, mountOnEnter, unmountOnExit, transition: Transition, as2: Component = 'div', eventKey: _, ...rest } = useTabContext(props);
    const prefix = 'tab-pane';
    if (!active && !Transition && unmountOnExit)
        return null;
    let pane = (_jsx(Component, { ...rest, ref: ref, role: "tabpanel", "aria-hidden": !active, className: classNames(className, prefix, { active }) }));
    if (Transition)
        pane = (_jsx(Transition, { in: active, onEnter: onEnter, onEntering: onEntering, onEntered: onEntered, onExit: onExit, onExiting: onExiting, onExited: onExited, mountOnEnter: mountOnEnter, unmountOnExit: unmountOnExit, children: pane }));
    return (_jsx(TabContext.Provider, { value: null, children: _jsx(SelectableContext.Provider, { value: null, children: pane }) }));
});
TabPane.displayName = 'TabPane';
export default TabPane;
