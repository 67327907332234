import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from "Components/Atoms";
import { translate } from "Services/Translator";
import { HandoverProtocolItemLevel } from "Pages/Handover/enums/HandoverProtocolItemLevel";
const HandoverProtocolItemLevelBadge = ({ level }) => {
    const colors = {
        [HandoverProtocolItemLevel.NEW]: 'success',
        [HandoverProtocolItemLevel.EXCESS]: 'danger',
        [HandoverProtocolItemLevel.NORMAL]: 'warning',
    };
    return (_jsx(Badge, { "$color": colors[level], children: translate(`handovers.protocolItem.level.${level}`) }));
};
export default HandoverProtocolItemLevelBadge;
