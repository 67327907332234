import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, BooleanType, SelectType, StringType, TextType } from '@f2w/form';
import { translate } from 'Services/Translator';
import { useEffect, useState } from 'react';
export var ContractRole;
(function (ContractRole) {
    ContractRole["TENANT"] = "tenant";
    ContractRole["MANAGEMENT"] = "management";
    ContractRole["OWNER"] = "owner";
    ContractRole["FACILITY_MANAGER"] = "facility_manager";
})(ContractRole || (ContractRole = {}));
export class NameplateOrderType extends AbstractFormType {
    partiesOptions = {
        tenant: {
            label: translate('quickschild.integrationView.orderForm.enum.tenant'),
            value: ContractRole.TENANT,
        },
        management: {
            label: translate('quickschild.integrationView.orderForm.enum.management'),
            value: ContractRole.MANAGEMENT,
        },
        owner: {
            label: translate('quickschild.integrationView.orderForm.enum.owner'),
            value: ContractRole.OWNER,
        },
        facilityManager: {
            label: translate('quickschild.integrationView.orderForm.enum.facilityManager'),
            value: ContractRole.FACILITY_MANAGER,
            isDisabled: false,
        }
    };
    fixedSelects = {
        property: [],
        rentalObject: [],
        contract: [],
    };
    _useField(props) {
        super._useField(props);
        const [orderData, setOrderData] = useState(this.field.value.orderData);
        const [partiesOptions, setPartiesOptions] = useState(this.partiesOptions);
        this.partiesOptions = partiesOptions;
        this.fixedSelects = {
            property: orderData !== null ? [orderData.property] : [],
            rentalObject: orderData !== null ? [orderData.rentalObject] : [],
            contract: orderData !== null ? [orderData.contract] : [],
        };
        useEffect(() => {
            if (orderData === null) {
                return;
            }
            setPartiesOptions({
                ...partiesOptions,
                facilityManager: {
                    ...partiesOptions.facilityManager,
                    isDisabled: !orderData.property.hasFacilityManager,
                    label: orderData.property.hasFacilityManager
                        ? translate('quickschild.integrationView.orderForm.enum.facilityManager')
                        : translate('quickschild.integrationView.orderForm.enum.facilityManagerNotDefined')
                }
            });
        }, [orderData]);
    }
    buildFields() {
        return {
            propertyId: new SelectType({
                label: translate('quickschild.integrationView.orderForm.fields.property'),
                choiceOptions: () => {
                    return this.fixedSelects.property.map(row => ({ label: row.name, value: row.id }));
                },
                disabled: true,
            }),
            rentalObjectId: new SelectType({
                label: translate('quickschild.integrationView.orderForm.fields.rentalObject'),
                choiceOptions: () => this.fixedSelects.rentalObject.map(row => ({ label: row.name, value: row.id })),
                disabled: true,
            }),
            contractId: new SelectType({
                label: translate('quickschild.integrationView.orderForm.fields.contract'),
                choiceOptions: () => this.fixedSelects.contract.map(row => ({ label: row.name, value: row.id })),
                disabled: true,
            }),
            invoiceTo: new SelectType({
                label: translate('quickschild.integrationView.orderForm.fields.invoiceTo'),
                choiceOptions: () => [
                    this.partiesOptions.tenant,
                    this.partiesOptions.management,
                    this.partiesOptions.owner,
                ],
                defaultValue: ContractRole.TENANT,
            }),
            nameplatesTo: new SelectType({
                label: translate('quickschild.integrationView.orderForm.fields.nameplatesTo'),
                choiceOptions: () => [
                    this.partiesOptions.facilityManager,
                    this.partiesOptions.tenant,
                    this.partiesOptions.management,
                ],
            }),
            questionsTo: new SelectType({
                label: translate('quickschild.integrationView.orderForm.fields.questionsTo'),
                choiceOptions: () => [
                    this.partiesOptions.facilityManager,
                    this.partiesOptions.tenant,
                    this.partiesOptions.management,
                ],
                defaultValue: ContractRole.MANAGEMENT,
            }),
            row1: new StringType({
                label: translate('quickschild.integrationView.orderForm.fields.row1'),
                required: true,
                limit: 30,
            }),
            row2: new StringType({
                label: translate('quickschild.integrationView.orderForm.fields.row2'),
                limit: 30,
                required: false,
                nullable: false,
            }),
            remarks: new TextType({
                label: translate('quickschild.integrationView.orderForm.fields.remarks'),
                required: false,
                nullable: false,
            }),
            acceptTerms: new BooleanType({
                required: true,
                constraints: {
                    isTrue: translate('form.validation.fieldIsRequired'),
                },
                content: (_jsxs(_Fragment, { children: [translate('quickschild.integrationView.orderForm.fields.acceptTermsBeforeLink'), "\u00A0", _jsx("a", { href: 'https://www.quickschild.ch/datenschutz', target: '_blank', children: translate('quickschild.integrationView.orderForm.fields.acceptTermsLink') })] })),
                defaultValue: false,
            }),
        };
    }
}
