import { FormError, useFormModal } from '@f2w/form';
import { translate } from "Services/Translator";
import { UserType } from "Pages/User/Overview/form/UserType";
import UserServices from "Pages/User/Overview/services/userServices";
import { useToastDispatcherApi } from "Components/Dispatcher";
export const useUserForm = ({ createUser = UserServices.createUser, updateUser = UserServices.updateUser, getUserById = UserServices.getUserById, onSave }) => {
    const { error } = useToastDispatcherApi();
    return useFormModal({
        name: 'user-form',
        type: () => new UserType(),
        title: ({ values }) => {
            return values?.id ? translate('user.edit') : translate('users.create');
        },
        onLoad: async (id) => {
            if (id) {
                return getUserById(id);
            }
            return null;
        },
        onSave: async (data, utils, type) => {
            try {
                const response = await (data.id
                    ? updateUser(data.id, data)
                    : createUser(data));
                if (response.data?.redirect) {
                    window.location.href = response.data.redirect;
                    return;
                }
                onSave(data, utils, type);
            }
            catch (e) {
                const message = e.response.status === 422 ? e.response.data.message : 'form.response.error';
                error({
                    message: message,
                });
                throw new FormError(message);
            }
        },
    });
};
