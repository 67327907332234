import generateRoute from "Services/Router";
import client from "Utils/client";
export var NameplateApi;
(function (NameplateApi) {
    NameplateApi.order = async (values) => {
        const route = generateRoute('api.quickschild.orderNameplate');
        return client.post(route, values);
    };
    NameplateApi.getMetadata = async (contractId) => {
        const response = await client.get(generateRoute('api.quickschild.contractMetadata', { contract: contractId }));
        return response.data;
    };
})(NameplateApi || (NameplateApi = {}));
