import { useFormModal } from '@f2w/form';
import { ServiceProviderType } from '../types';
import { translate } from 'Services/Translator';
export const useServiceProviderForm = ({ onSubmit, getServiceProvider, createServiceProvider, updateServiceProvider }) => useFormModal({
    type: () => new ServiceProviderType(),
    name: "service-provider-form",
    title: (id) => {
        return id
            ? translate('serviceProvider.edit')
            : translate('serviceProviders.create');
    },
    onLoad: async (id) => {
        if (id) {
            return {
                id,
                ...(await getServiceProvider(id))
            };
        }
        return {
            id
        };
    },
    onSave: (values) => {
        if (values.id) {
            return updateServiceProvider(values.id, values).then(onSubmit);
        }
        return createServiceProvider(values).then(onSubmit);
    },
});
