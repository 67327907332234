import generateRoute from "Services/Router";
import client from "Utils/client";
export const listPropertiesForDropdownAction = () => {
    const listPropertiesForDropdownRoute = generateRoute('list_properties_for_dropdown');
    return client
        .get(listPropertiesForDropdownRoute)
        .then(response => response.data);
};
export const listFinancialAccountsForDropdown = () => {
    const listFinancialAccountsForDropdownRoute = generateRoute('list_financial_accounts_for_dropdown');
    return client
        .get(listFinancialAccountsForDropdownRoute)
        .then(response => response.data);
};
export default {
    listPropertiesForDropdownAction,
    listFinancialAccountsForDropdown
};
