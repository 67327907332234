import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Button, FlexBox, Icon, Text, TextRoot, Title } from 'Components/Atoms';
import { isString } from 'Utils';
import { translate } from 'Services/Translator';
export var ViewModalStyled;
(function (ViewModalStyled) {
    ViewModalStyled.ObjectItem = styled('div') `
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 12px;
      margin: 0 -10px;
      border-radius: 12px;

      color: var(--fw-color-neutral-700);

      cursor: pointer;
      &:hover {
        background-color: var(--fw-color-dark-25);
      }

      ${p => p.disabled ? {
        pointerEvents: 'none',
    } : null};
    `;
    const DocumentLinkRoot = styled.a `
      display: flex;
      gap: 10px;
      margin: 8px 0;
      padding: 2px 12px;
      margin: 4px -10px;
      border-radius: 8px;
      //cursor: pointer;
      align-items: center;
      min-height: 40px;

      color: var(--fw-color-dark-700);
      ${p => p.theme.typography.medium.fontSizeStyles};

      &:hover {
        color: var(--fw-color-primary-500);
        background-color: var(--fw-color-dark-25);
      }

      ${TextRoot} {
        flex: 1;
      }

      ${Icon} {
        font-size: calc(1em + 2px);
      }
    `;
    ViewModalStyled.DocumentLink = ({ label, path, onDelete, onDeleteConfirm, onDownload, icon = 'file-text', ...props }) => {
        if (isString(onDownload)) {
            props.href = onDownload;
            props.download = label;
        }
        else {
            props.href = '#';
            props.onClick = (e) => {
                e.preventDefault();
                onDownload?.();
            };
        }
        const bp = {
            $size: 'sm',
            nested: true,
            linkFill: true,
        };
        return (_jsxs(DocumentLinkRoot, { ...props, children: [_jsx(Icon, { icon: icon, size: 'calc(1em + 4px)' }), _jsx(Text, { medium: true, children: label }), onDelete && _jsx(Button, { ...bp, color: 'error', icon: 'trash-2', onClick: onDelete, confirm: onDeleteConfirm ?? translate('confirm.message.delete') }), _jsx(Icon, { icon: 'download' })] }));
    };
    ViewModalStyled.ContentBlock = styled.div `
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: ${p => p.divider ? '20px' : '8px'};
      padding-bottom: ${p => p.divider ? '14px' : 0};
      border-bottom: ${p => p.divider ? '1px' : 0} solid #D7D8DB;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      ${p => p.theme.typography.medium.fontSizeStyles};

      ${Title} {
        display: block;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px
      }
    `;
    ViewModalStyled.Box = styled(FlexBox) `
      border: 1px solid ${p => p.theme.palette.neutral.$100};
      border-radius: 8px;
      padding: 24px;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      gap: 15px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* neutral/neutral-500 */
      color: #565D6B;
    `;
    ViewModalStyled.Bold = styled.div `
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      display: flex;
    `;
})(ViewModalStyled || (ViewModalStyled = {}));
export default ViewModalStyled;
