import client from "Utils/client";
import generateRoute from "Services/Router";
const getRentDetails = (rentId) => {
    return client
        .get(generateRoute('api.rents.details', { rent: rentId }))
        .then(response => response.data);
};
const updateRent = (rentId, data) => {
    return client
        .post(generateRoute('api.rents.edit', { rent: rentId }), data)
        .then(response => response.data);
};
const downloadFile = (response) => {
    const fileName = response.headers['X-Download-File-Name'.toLowerCase()];
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURI(fileName));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};
const downloadRenterMirrorDateReport = async (data) => {
    const { type, anonymized, ...payload } = data;
    const response = await client.get(generateRoute('api.rents.export.dateReport.' + type, {
        ...payload,
        anonymized: anonymized ? 1 : 0,
        vacancyOnly: data.vacancyOnly ? 1 : 0,
    }), { responseType: 'blob' });
    downloadFile(response);
};
const downloadRenterMirrorDateRangeReport = async (data) => {
    const { type, anonymized, ...payload } = data;
    const response = await client.get(generateRoute('api.rents.export.dateRangeReport.' + type, { ...payload, anonymized: anonymized ? 1 : 0 }), { responseType: 'blob' });
    downloadFile(response);
};
const downloadRenterMirrorTenantListReport = async (data) => {
    const { type, ...payload } = data;
    const response = await client.get(generateRoute('api.rents.export.tenantList.' + type, { ...payload }), { responseType: 'blob' });
    downloadFile(response);
};
export default {
    getRentDetails,
    updateRent,
    downloadRenterMirrorDateReport,
    downloadRenterMirrorDateRangeReport,
    downloadRenterMirrorTenantListReport
};
