import * as rt from 'react-table';
import { toArray } from '@f2w/utils';
export const usePagination = {
    stateCache: ['pageSize'],
    initOptions: (opts) => {
        return ({
            autoResetPage: false,
            paginationShowFirstLast: false,
            pageSizes: [10, 25, 50],
            pageBoundaryCount: 1,
            pageSiblingCount: 3,
            _pageSiblingCount: {},
            initialState: {
                pageSize: 10, pageIndex: 0,
            },
        });
    },
    createOptions: (opts, { manualControl, tableId, initialState }) => {
        const pageSizes = toArray(opts.pageSizes);
        const pageSize = opts.initialState?.pageSize;
        if (!pageSizes.includes(pageSize))
            pageSizes.push(pageSize);
        return ({
            manualPagination: !!manualControl,
            autoResetPage: manualControl,
            pageSizes,
        });
    },
    hook: hooks => {
        rt.usePagination(hooks);
    },
};
