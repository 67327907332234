import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowLeftIcon, ArrowRightIcon } from 'Components/App/Icons';
import { Button } from 'Components/Atoms';
import { useState } from 'react';
import SubscriptionPlanStyled from './SelectSubscriptionPlanStyled';
import { formatMoney } from "Services/NumberHelper";
import { translate } from "Services/Translator";
import { calcSubtotalPriceForSubscriptionPlan, getDefaultPriceIncrements } from "Services/SubscriptionCalculatorService";
import { formatBytes } from "@f2w/utils";
const { Body, Heading, HeadingTitle, Plans, Plan, LimitButton, ButtonSeeMore, PerMonth, ButtonSection, ButtonGap, VariablePlanSection, VariablePlanForm, PlanDetails, PlanCell, PlanTitle, PlanPrice, PlanInfo, PlanButton, PlanData, ButtonUpgrade, VariablePlanTitle, VariablePlanPrice, TitlePriceSection } = SubscriptionPlanStyled;
const IconPlus = () => {
    return (_jsxs("svg", { width: "17", height: "16", viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M8.3999 3.33334V12.6667", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M3.7334 8H13.0667", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }));
};
const IconMinus = () => {
    return (_jsx("svg", { width: "17", height: "16", viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M3.7334 8H13.0667", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }));
};
const SelectSubscriptionPlan = ({ subscriptionPlans, currentSubscriptionPlan, limits, onUpgrade, onGoBack }) => {
    const [selectedPlan, setSelectedPlan] = useState(currentSubscriptionPlan);
    const [selectedLimits, setSelectedLimits] = useState(limits);
    const defaultPriceIncrements = getDefaultPriceIncrements();
    const onSelectPlan = (plan) => {
        setSelectedPlan(plan);
        setSelectedLimits(plan.limits);
    };
    const onChangeLimit = (attribute, isPositive) => {
        let step = attribute in defaultPriceIncrements ? defaultPriceIncrements[attribute].quantity : 0;
        if (!isPositive) {
            step = -step;
        }
        setSelectedLimits((selectedLimits) => {
            const newValue = selectedLimits[attribute] + step;
            if (selectedPlan.limits[attribute] > newValue) {
                return selectedLimits;
            }
            return ({ ...selectedLimits, [attribute]: newValue });
        });
    };
    const onIncrementLimit = (attribute) => onChangeLimit(attribute, true);
    const onDecrementLimit = (attribute) => onChangeLimit(attribute, false);
    let hasPlanChanged = false;
    if (currentSubscriptionPlan && selectedPlan) {
        hasPlanChanged = (currentSubscriptionPlan.id !== selectedPlan.id)
            || JSON.stringify(limits) !== JSON.stringify(selectedLimits);
    }
    else {
        hasPlanChanged = selectedPlan && !currentSubscriptionPlan;
    }
    return (_jsxs(Body, { children: [_jsxs(Heading, { children: [_jsxs(Button, { onClick: () => onGoBack(), "$size": 'sm', color: 'primary', variant: 'link', children: [_jsx(ArrowLeftIcon, {}), translate('subscriptions.backButton')] }), _jsx(HeadingTitle, { children: translate('subscriptions.headingTitle') })] }), _jsxs(Plans, { children: [_jsxs(Plan, { children: [_jsx(PlanCell, {}), _jsx(PlanCell, { children: translate('subscriptions.rentalObjects') }), _jsx(PlanCell, { children: translate('subscriptions.users') }), _jsx(PlanCell, { children: translate('subscriptions.storage') })] }), subscriptionPlans.map((plan) => {
                        const isSelected = plan.id === selectedPlan?.id;
                        const limits = isSelected ? selectedLimits : plan.limits;
                        return (_jsx(SubscriptionPlan, { plan: plan, limits: limits, isCurrent: currentSubscriptionPlan !== null && currentSubscriptionPlan.id === plan.id, isSelected: isSelected, onSelectPlan: onSelectPlan, onIncrementLimit: onIncrementLimit, onDecrementLimit: onDecrementLimit }, plan.optionId));
                    })] }), _jsx(ButtonSeeMore, { children: _jsx(Button, { href: "https://www.fairwalter.com/preise", target: "_blank", variant: 'link', "$size": 'md', color: 'primary', children: translate('subscriptions.seeMoreButton') }) }), hasPlanChanged && _jsx(PlanModification, { fromPlan: currentSubscriptionPlan, toPlan: selectedPlan, limits: selectedLimits }), _jsx(ButtonUpgrade, { children: _jsx(Button, { disabled: !hasPlanChanged, onClick: () => onUpgrade(selectedPlan, selectedLimits), variant: "fill", "$size": "md", color: "primary", children: translate('subscriptions.upgradeButton') }) })] }));
};
const SubscriptionPlan = ({ plan, limits, onSelectPlan: onSelect, onIncrementLimit, onDecrementLimit, isCurrent, isSelected = false }) => {
    const { isDmsLimitExpansible, isNbRentalObjectsExpansible } = plan;
    const buttonTypes = {
        default: { color: "primary", variant: "outline", children: translate('subscriptions.select') },
        current: { color: "primary", variant: "outline", children: translate('subscriptions.select') },
        selected: { color: "primary", variant: "fill", children: translate('subscriptions.selected') },
    };
    let type = isSelected ? "selected" : (isCurrent ? 'current' : 'default');
    const buttonProps = buttonTypes[type];
    return (_jsxs(_Fragment, { children: ["  ", _jsxs(Plan, { isCurrent: isCurrent, isSelected: isSelected, children: [_jsx(PlanCell, { children: _jsxs(PlanDetails, { children: [_jsxs("div", { children: [_jsx(PlanTitle, { children: plan.name }), _jsxs(PlanPrice, { children: [formatMoney(plan.price / 12, "$0,0"), _jsxs(PerMonth, { children: ["/", translate('subscriptions.month')] })] }), _jsx(PlanInfo, { children: plan.description })] }), _jsx(PlanButton, { children: _jsx(Button, { "$size": 'sm', ...buttonProps, onClick: () => onSelect(plan) }) })] }) }), _jsx(PlanCell, { children: _jsxs(PlanData, { children: [limits.nbRentalObjects, _jsx(ButtonSection, { children: isSelected && isNbRentalObjectsExpansible && _jsxs(_Fragment, { children: [_jsx(ButtonGap, { children: _jsx(LimitButton, { onClick: () => onDecrementLimit('nbRentalObjects'), children: _jsx(IconMinus, {}) }) }), _jsx(LimitButton, { onClick: () => onIncrementLimit('nbRentalObjects'), children: _jsx(IconPlus, {}) })] }) })] }) }), _jsx(PlanCell, { children: _jsx(PlanData, { children: limits.nbUsers }) }), _jsx(PlanCell, { children: _jsxs(PlanData, { children: [formatBytes(limits?.dmsLimitInBytes), _jsx(ButtonSection, { children: isSelected && isDmsLimitExpansible && _jsxs(_Fragment, { children: [_jsx(ButtonGap, { children: _jsx(LimitButton, { onClick: () => onDecrementLimit('dmsLimitInBytes'), children: _jsx(IconMinus, {}) }) }), _jsx(LimitButton, { onClick: () => onIncrementLimit('dmsLimitInBytes'), children: _jsx(IconPlus, {}) })] }) })] }) })] })] }));
};
const VariablePlan = ({ plan, limits }) => {
    const { nbRentalObjects, nbUsers, dmsLimitInBytes } = limits;
    const totalMonthlyPrice = calcSubtotalPriceForSubscriptionPlan(plan, limits) / 12;
    let diffLimits = null;
    const hasLimitsChanged = JSON.stringify(plan.limits) !== JSON.stringify(limits);
    if (hasLimitsChanged) {
        diffLimits = {
            nbUsers: limits.nbUsers - plan.limits.nbUsers,
            nbRentalObjects: limits.nbRentalObjects - plan.limits.nbRentalObjects,
            dmsLimitInBytes: limits.dmsLimitInBytes - plan.limits.dmsLimitInBytes,
        };
    }
    const PlanExtra = ({ diff, dimension = null }) => {
        if (diff <= 0) {
            return null;
        }
        const dimensionInsert = dimension ? ' ' + dimension : '';
        return (_jsxs("span", { style: { fontSize: ".7em", padding: 1, color: "#16AC88" }, children: ["+", diff, dimensionInsert] }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(TitlePriceSection, { children: [_jsxs(VariablePlanTitle, { children: [plan.name, " ", translate('subscriptions.plan')] }), _jsxs(VariablePlanPrice, { children: [formatMoney(totalMonthlyPrice), _jsxs(PerMonth, { children: ["/", translate('subscriptions.month')] })] })] }), _jsxs("div", { children: [nbRentalObjects || '∞', " ", translate('subscriptions.rentalObjects'), " ", !!diffLimits?.nbRentalObjects && _jsx(PlanExtra, { diff: diffLimits?.nbRentalObjects })] }), _jsxs("div", { children: [nbUsers || '∞', " ", translate('subscriptions.users')] }), _jsxs("div", { children: [formatBytes(limits?.dmsLimitInBytes) || '∞', " ", translate('subscriptions.storage'), " ", !!diffLimits?.dmsLimitInBytes && _jsx(PlanExtra, { diff: formatBytes(diffLimits?.dmsLimitInBytes) })] })] }));
};
const PlanModification = ({ fromPlan, toPlan, limits }) => {
    return (_jsx(_Fragment, { children: _jsxs(VariablePlanSection, { children: [fromPlan && _jsxs(_Fragment, { children: [_jsx(VariablePlanForm, { children: _jsx(VariablePlan, { plan: fromPlan, limits: fromPlan.limits }) }), _jsx(ArrowRightIcon, { size: 24 })] }), _jsx(VariablePlanForm, { children: _jsx(VariablePlan, { plan: toPlan, limits: limits }) })] }) }));
};
export default SelectSubscriptionPlan;
