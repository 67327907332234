import { jsx as _jsx } from "react/jsx-runtime";
import PageOverview from '@f2w/data-table/layout/PageOverview';
import useBankingReconciliationTransactionsTable from 'Pages/BankingReconciliation/table/BankingTransactionDataTable';
export const BankingTransactionView = ({ transactionsTableRef }) => {
    const table = useBankingReconciliationTransactionsTable();
    if (transactionsTableRef && transactionsTableRef.current === null) {
        transactionsTableRef.current = table;
    }
    return (_jsx(PageOverview, { instance: table, showFilters: true }));
};
