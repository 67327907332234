import { jsx as _jsx } from "react/jsx-runtime";
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { translate } from 'Services/App';
import { BaseDataServices } from './services';
import { PropertyDataTableType } from './table/PropertyDataTableType';
import { useOpenRentalObject } from './hooks/useOpenRentalObject';
export const PropertyPage = (props) => {
    useOpenRentalObject(props);
    const table = PropertyDataTableType.useTable({}, () => ({
        manualControl: true,
        getData: ({ request }) => BaseDataServices.getBaseDataPropertiesList(request),
    }));
    return (_jsx(PageOverview, { instance: table, variant: 'content', title: translate("propertyPage.title"), inlineFilters: true }));
};
