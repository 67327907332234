import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useCallback, useEffect, useState } from "react";
import { rentalObjectTypeOptions } from "Enums/RentalObjectType";
import { Section } from "Atoms/Layout/components";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { Text } from "Atoms/Text";
import { getGlobalContext, translate } from "Services/App";
import { Checkbox, Input, Select, TextArea } from "Atoms/Input/controls";
import { Table, Tbody, Td, Th, Thead, Tr } from "Atoms/Table";
import { RentalObjectViewFormType } from "Components/ObjectViewModal/forms/RentalObjectViewFormType";
import { NumberOfRoomsOptions } from "Components/Molecules/NumberOfRooms";
import { Button, ButtonToolbar } from "Components/Atoms";
import { Tier } from "Pages/Administration/Customers/enums";
import { Card } from "Atoms/Card";
export const WaitingListTab = ({ view, waitingListServices, updatePersonWaitingListNotes }) => {
    const { waitingListNotes: initialWaitingListNotes, id } = view.data;
    const [waitingListNotes, setWaitingListNotes] = useState(initialWaitingListNotes);
    const { customer: { currentSubscriptionTier } } = getGlobalContext();
    const [loading, setLoading] = useState(true);
    const [buildingMap, setBuildingMap] = useState(new Map());
    const [type, setType] = useState();
    const [numberOfRooms, setNumberOfRooms] = useState();
    const [zip, setZip] = useState();
    const [onlySelected, setOnlySelected] = useState(false);
    const grabBuildings = useCallback((type, numberOfRooms, zip) => {
        const params = {
            filters: {
                type,
                numberOfRooms,
            }
        };
        if (zip) {
            params.query = zip;
        }
        setLoading(true);
        waitingListServices.getBuildingsForWaitingList(id, params).then(data => {
            const buildingMap = new Map();
            data.forEach(building => {
                let atLeastOneSelected = false;
                const allSelected = building
                    .rentalObjects
                    .map(ro => !!ro.waitingListId)
                    .filter(waitingListId => {
                    if (waitingListId) {
                        atLeastOneSelected = true;
                    }
                    return waitingListId;
                })
                    .length === building.rentalObjects.length;
                buildingMap.set(building.id, {
                    ...building,
                    isSelected: allSelected,
                    isIndeterminate: atLeastOneSelected
                });
            });
            setBuildingMap(buildingMap);
        }).finally(() => setLoading(false));
    }, []);
    useEffect(() => {
        grabBuildings(type, numberOfRooms, zip);
    }, [type, numberOfRooms, zip]);
    const updatePersonWorkNotes = useCallback(async (notes, view) => {
        await updatePersonWaitingListNotes(id, notes)
            .then(() => {
            view.updateData({
                waitingListNotes: notes
            });
            view.setState({
                ...view.state,
                editWaitingListNotesEnabled: false
            });
        });
    }, []);
    const toggleBuildingSelected = useCallback((building, checked) => {
        if (checked) {
            const rentalObjectIdsNotChecked = building
                .rentalObjects
                .filter(ro => !ro.waitingListId)
                .map(ro => ro.id);
            waitingListServices.addPersonToMultipleWaitingList(rentalObjectIdsNotChecked, id)
                .then(data => {
                const waitingListMap = new Map();
                data.forEach(wl => waitingListMap.set(wl.rentalObjectId, wl.id));
                setBuildingMap((prev) => {
                    const updatedBuildingMap = new Map(prev);
                    const updatedBuilding = updatedBuildingMap.get(building.id);
                    updatedBuildingMap.set(building.id, {
                        ...updatedBuilding,
                        isSelected: true,
                        isIndeterminate: false,
                        rentalObjects: updatedBuilding.rentalObjects.map(ro => ({
                            ...ro,
                            waitingListId: waitingListMap.get(ro.id) ?? ro.waitingListId,
                            numOfPeopleOnWaitingList: ro.numOfPeopleOnWaitingList + (waitingListMap.has(ro.id) ? 1 : 0)
                        }))
                    });
                    return updatedBuildingMap;
                });
            });
        }
        else {
            const waitingListIds = building
                .rentalObjects
                .map(ro => ro.waitingListId)
                .filter(waitingListId => !!waitingListId);
            waitingListServices.removeMultiple(waitingListIds)
                .then(() => {
                setBuildingMap((prev) => {
                    const updatedBuildingMap = new Map(prev);
                    const updatedBuilding = updatedBuildingMap.get(building.id);
                    updatedBuildingMap.set(building.id, {
                        ...updatedBuilding,
                        isSelected: false,
                        isIndeterminate: false,
                        rentalObjects: updatedBuilding.rentalObjects.map(ro => ({
                            ...ro,
                            waitingListId: undefined,
                            numOfPeopleOnWaitingList: ro.numOfPeopleOnWaitingList - (ro.waitingListId ? 1 : 0),
                        }))
                    });
                    return updatedBuildingMap;
                });
            });
        }
    }, []);
    const updateRentalObjectWaitingListId = useCallback((buildingId, rentalObjectId, waitingListId) => {
        setBuildingMap((prev) => {
            const updatedBuildingMap = new Map(prev);
            const updatedBuilding = updatedBuildingMap.get(buildingId);
            const updatedRentalObjects = updatedBuilding.rentalObjects.map(ro => ({
                ...ro,
                waitingListId: ro.id === rentalObjectId
                    ? waitingListId
                    : ro.waitingListId,
                numOfPeopleOnWaitingList: ro.id === rentalObjectId
                    ? ro.numOfPeopleOnWaitingList + (waitingListId ? 1 : -1)
                    : ro.numOfPeopleOnWaitingList
            }));
            const selectedRentalObjects = updatedRentalObjects
                .map(ro => ro.waitingListId)
                .filter(waitingListId => !!waitingListId)
                .length;
            updatedBuildingMap.set(buildingId, {
                ...updatedBuilding,
                isSelected: waitingListId
                    ? selectedRentalObjects === updatedRentalObjects.length
                    : false,
                isIndeterminate: selectedRentalObjects > 0,
                rentalObjects: updatedRentalObjects
            });
            return updatedBuildingMap;
        });
    }, []);
    const toggleRentalObjectSelected = useCallback((buildingId, rentalObject, checked) => {
        if (checked) {
            waitingListServices.save(rentalObject.id, id)
                .then(data => {
                updateRentalObjectWaitingListId(buildingId, rentalObject.id, data.id);
            });
        }
        else {
            waitingListServices.deleteWaitingList(rentalObject.waitingListId)
                .then(() => {
                updateRentalObjectWaitingListId(buildingId, rentalObject.id, undefined);
            });
        }
    }, []);
    return (_jsxs(Section, { children: [_jsxs(FlexCol, { gap: 5, pb: 16, children: [!view.state.editWaitingListNotesEnabled && (_jsx(Card, { children: _jsx(Card.HtmlContent, { title: translate('person.details.waitingListNotes'), value: initialWaitingListNotes }) })), view.state.editWaitingListNotesEnabled && (_jsxs(_Fragment, { children: [_jsx(Text, { children: translate('person.details.waitingListNotes') }), _jsx(TextArea, { value: waitingListNotes, onChange: setWaitingListNotes, rows: 5 }), _jsxs(ButtonToolbar, { style: { paddingTop: '10px', justifyContent: 'flex-end' }, children: [_jsx(Button.Save, { onClick: async () => await updatePersonWorkNotes(waitingListNotes, view), disabled: initialWaitingListNotes === waitingListNotes }), _jsx(Button, { color: 'dark', variant: 'linkFill', onClick: () => view.setState({
                                            ...view.state,
                                            editWaitingListNotesEnabled: false
                                        }), children: translate('Cancel') })] })] }))] }), _jsxs(FlexRow, { gap: 16, pb: 24, children: [_jsx(Div, { flexGrow: 1, children: _jsx(Select, { value: type, isClearable: true, useMaxContentWidth: true, options: rentalObjectTypeOptions, placeholder: translate('baseData.rentalObject.type'), onChange: newValue => setType(newValue?.value) }) }), _jsx(Div, { flexGrow: 1, children: _jsx(Select, { value: numberOfRooms, isClearable: true, useMaxContentWidth: true, options: NumberOfRoomsOptions, onChange: newValue => setNumberOfRooms(newValue?.value), placeholder: translate('rentalObject.numberOfRooms') }) }), _jsx(Div, { flexGrow: 1, children: _jsx(Input, { value: zip || '', onChange: setZip, placeholder: translate('createProperty.property.zip') }) }), _jsx(Div, { flexGrow: 1, children: _jsx(Select, { value: onlySelected, useMaxContentWidth: true, options: [
                                {
                                    label: translate('all'),
                                    value: false
                                },
                                {
                                    label: translate('person.waitingList.onlySelected'),
                                    value: true
                                }
                            ], onChange: newValue => setOnlySelected(!!newValue?.value), placeholder: translate('person.waitingList.onlySelected') }) })] }), _jsxs(Table, { variant: 'section', isLoading: loading && currentSubscriptionTier !== Tier.PRIVATE, children: [_jsxs(Thead, { children: [_jsx(Th, {}), _jsx(Th, { children: translate('rentalObject') }), _jsx(Th, { children: translate('baseData.createProperty.rentalObject.type') }), _jsx(Th, { children: translate('rentalObject.area') }), _jsx(Th, { children: translate('rentalObject.floor') }), _jsx(Th, { children: translate('numberOfRooms') })] }), _jsx(Tbody, { children: (Array.from(buildingMap.values())).filter(b => {
                            if (onlySelected) {
                                return b.isSelected || b.isIndeterminate;
                            }
                            return true;
                        }).map((building) => (_jsxs(Fragment, { children: [_jsx(Tr, { children: _jsx(Td, { colSpan: 6, children: _jsxs(FlexRow, { gap: 12, children: [_jsx(Checkbox, { checked: building.isSelected, indeterminate: !building.isSelected && building.isIndeterminate, onChange: (value) => toggleBuildingSelected(building, value) }), building.houseNumber ? `${building.street} ${building.houseNumber}` : building.street, ", ", building.zip, " ", building.city] }) }) }), building.rentalObjects.filter(ro => {
                                    if (onlySelected) {
                                        return !!ro.waitingListId;
                                    }
                                    return true;
                                }).map(rentalObject => (_jsxs(Tr, { children: [_jsx(Td, { children: _jsxs(FlexRow, { gap: 8, justifyCenter: true, alignCenter: true, children: [rentalObject.numOfPeopleOnWaitingList > 0 && (_jsx(FlexRow, { justifyCenter: true, alignCenter: true, px: 9, py: 4, brd: 32, bg: 'primary.$50', children: _jsx(Text, { small: 400, primary: 500, children: rentalObject.numOfPeopleOnWaitingList }) })), _jsx(Div, { pl: rentalObject.numOfPeopleOnWaitingList === 0 ? 34 : 0, children: _jsx(Checkbox, { checked: !!rentalObject.waitingListId, onChange: (value) => toggleRentalObjectSelected(building.id, rentalObject, value) }) })] }) }), _jsx(Td, { children: rentalObject.name }), _jsx(Td, { children: translate(`rentalObjectType.${rentalObject.type}`) }), _jsxs(Td, { children: [rentalObject.area, "m", _jsx("sup", { children: "2" })] }), _jsx(Td, { children: RentalObjectViewFormType.getFloorTrans(rentalObject.floor) }), _jsx(Td, { children: rentalObject.numberOfRooms })] }, rentalObject.id)))] }, building.id))) })] })] }));
};
