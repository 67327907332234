import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Section } from 'Atoms/Layout/components';
import { Tbody, Td, TdActions, Th, Thead, Tr } from "Atoms/Table";
import { translate } from "Services/Translator";
import { DateType, MoneyType } from "@f2w/form";
import DebitorInvoiceState from "Pages/Debitor/components/DebitorInvoiceState";
import generateRoute from "Services/Router";
import { Button } from "Atoms/Button";
export const DebitorInvoicesTab = ({ group: { data: { invoices = [] }, data, }, }) => {
    return invoices.length > 0 && _jsxs(_Fragment, { children: [_jsxs(Section.Table, { stickyHead: 0, colSpan: 4, children: [_jsxs(Thead, { children: [_jsx(Th, { children: translate('rent.details.invoices.columns.status') }), _jsx(Th, { children: translate('rent.details.invoices.columns.dueDate') }), _jsx(Th, { children: translate('rent.details.invoices.columns.amount') }), _jsx(Th, { children: " " })] }), _jsx(Tbody, { children: invoices.map(invoice => (_jsxs(Tr, { children: [_jsx(Td, { children: _jsx(DebitorInvoiceState, { size: 'sm', state: invoice.state }) }), _jsx(Td, { children: invoice.dueTo
                                        ? DateType.format(invoice.dueTo)
                                        : translate('notAvailable') }), _jsx(Td, { children: MoneyType.format(invoice.amount) }), _jsx(TdActions, { id: invoice.id, sticky: 'right', quickActions: [
                                        {
                                            icon: 'eye',
                                            title: translate('btn.view'),
                                            href: generateRoute('list_debitor_invoices', { invoiceId: invoice.id }),
                                            target: '_blank'
                                        },
                                    ] })] }, `invoice-table-row-${invoice.id}`))) })] }), _jsx(Section, { sticky: { bottom: 0 }, style: { textAlign: 'right' }, children: _jsx(Button, { label: translate('rent.details.invoices.seeAll'), href: generateRoute('list_debitor_invoices', { rentId: data.id }), target: '_blank' }) })] });
};
