import { jsx as _jsx } from "react/jsx-runtime";
import ReactSelect from "react-select";
import { SearchIcon } from "Components/App/Icons";
import classes from "./Select.module.scss";
const customStyles = {
    menu: base => ({
        ...base,
        position: 'initial',
        background: 'transparent',
        boxShadow: 'none',
    }),
    input: provided => ({
        ...provided,
        color: 'var(--color-primary-dark)',
    }),
    control: provided => ({
        ...provided,
        color: 'var(--color-primary-dark)',
        background: 'var(--color-dark-navigation-bg)',
        border: 'none',
        boxShadow: 'none',
        "&:hover": {
            border: 'none',
            boxShadow: 'none',
        }
    }),
    option: (provided, { isFocused }) => ({
        ...provided,
        color: 'var(--color-grey-4)',
        borderRadius: 'var(--border-radius-standard)',
        background: isFocused ? 'var(--color-dark-2)' : 'transparent'
    }),
};
const Select = (props) => {
    return (_jsx(ReactSelect, { styles: customStyles, ...props, components: {
            IndicatorSeparator: null,
            DropdownIndicator: () => _jsx("div", { className: classes.dropdownIndicator, children: _jsx(SearchIcon, {}) }),
            ...props.components
        } }));
};
export default Select;
