import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, BooleanType, CollectionType, DateType, MoneyType, NumberType, ReferenceType, SelectType, StringType, } from '@f2w/form';
import { translate } from 'Services/Translator';
import { Button, Div, FlexCol, FlexRow, Icon, Td, Tfoot } from 'Components/Atoms';
import React from 'react';
import BankAccountServices from 'Pages/BankAccount/services/bankAccountServices';
import { BankAccountType } from 'Pages/BankAccount/enums/BankAccountType';
import { BookingType, InvoiceRelevancy } from 'Pages/Creditor/enums';
import generateRoute from 'Services/Router';
import moment from 'moment';
import { formatMoney } from 'Services/NumberHelper';
import client from 'Utils/client';
import { orFunction } from "@f2w/utils";
export class ProcessingType extends AbstractFormType {
    bankAccounts = [];
    bankAccountMap = new Map();
    _useField(props) {
        super._useField(props);
        const [bankAccounts, setBankAccounts] = React.useState([]);
        const [bankAccountMap, setBankAccountMap] = React.useState(new Map());
        this.bankAccounts = bankAccounts;
        this.bankAccountMap = bankAccountMap;
        React.useEffect(() => {
            BankAccountServices
                .getBankAccountsForDropdown()
                .then(response => {
                const mappedBankAccounts = [];
                const bankAccountMap = new Map();
                response.forEach(bankAccount => {
                    if (bankAccount.hasQrIBAN || bankAccount.hasRegularIBAN) {
                        mappedBankAccounts.push({
                            label: bankAccount.personName
                                ? bankAccount.personName + '' + ' ' + bankAccount.number
                                : bankAccount.name,
                            value: bankAccount.id
                        });
                        bankAccountMap.set(bankAccount.id, bankAccount);
                    }
                });
                setBankAccountMap(bankAccountMap);
                setBankAccounts(mappedBankAccounts);
            });
        }, []);
        React.useEffect(() => {
            if (this.field.value.receiptDate) {
                this.children.dueTo.field.setValue(moment(this.field.value.receiptDate).add(30, 'd').format('YYYY-MM-DD'));
            }
        }, [this.field.value.receiptDate]);
    }
    buildFields() {
        const isDisabledForAncillary = () => {
            return (this.value?.isFromAncillary === true);
        };
        return {
            isFromAncillary: new BooleanType({
                isVisible: () => false,
                required: false,
            }),
            creditorName: new StringType({
                label: translate('creditor.creditorName'),
                required: false,
                getProps: () => ({ disabled: isDisabledForAncillary() })
            }),
            label: new StringType({
                label: translate('creditor.label'),
                required: true,
                getProps: () => ({ disabled: isDisabledForAncillary() })
            }),
            bookingType: new SelectType({
                label: translate('creditor.bookingType'),
                required: true,
                choices: {
                    [BookingType.COMBINED]: translate('creditor.bookingType.entireInvoice'),
                    [BookingType.SEPARATED]: translate('creditor.bookingType.perPosition'),
                },
                defaultValue: BookingType.COMBINED,
                getProps: () => ({ isDisabled: isDisabledForAncillary() })
            }),
            positions: new ProcessingType.Positions({
                label: translate('debitor.positions'),
                actions: { add: true, delete: true },
                widget: 'table',
                renderFooter: true,
                getProps: () => ({ disabled: isDisabledForAncillary() }),
                isFromAncillary: () => this.value?.isFromAncillary,
                getCreditorAmount: () => this.field.value.grossAmount,
                prototype: () => new ProcessingType.Position({
                    disabled: isDisabledForAncillary()
                }),
                isVisible: () => this.field.value.bookingType === BookingType.SEPARATED
            }),
            accountingDate: new DateType({
                label: translate('creditor.accountingDate.label'),
                required: true,
                isVisible: () => this.field.value.bookingType === BookingType.COMBINED
            }),
            amount: new MoneyType({
                label: translate('creditor.totalAmount'),
                required: true,
                isVisible: () => this.field.value.bookingType === BookingType.COMBINED
            }),
            recipientBankAccountId: new ProcessingType.CreatableBankAccount({
                label: translate('invoice.recipientBankAccount'),
                required: true,
                choiceOptions: () => this.bankAccounts,
                searchable: true,
            }),
            referenceNumber: new ReferenceType({
                label: translate('creditor.qrReferenceNumber'),
                required: true,
                isVisible: () => {
                    if (this.bankAccountMap.has(this.field.value.recipientBankAccountId)) {
                        return this.bankAccountMap.get(this.field.value.recipientBankAccountId).hasQrIBAN;
                    }
                    return false;
                },
                constraints: {
                    typeError: translate('form.validation.fieldIsRequired'),
                },
            }),
            receiptDate: new DateType({
                label: translate('invoice.date'),
                required: true
            }),
            dueTo: new DateType({
                label: translate('invoice.dueTo'),
                required: true
            }),
            comment: new StringType({
                label: translate('creditor.comment'),
                required: false
            }),
            invoiceRelevancy: new SelectType({
                label: translate('creditor.invoiceRelevancy'),
                required: false,
                clearable: true,
                choices: {
                    [InvoiceRelevancy.NORMAL_EXPENDITURE]: translate('creditor.relevancy.normalExpenditure'),
                    [InvoiceRelevancy.ANCILLARY_EXPENSE]: translate('creditor.relevancy.ancillaryExpense'),
                }
            })
        };
    }
}
(function (ProcessingType) {
    async function updateData(id, data) {
        const route = generateRoute('api.creditorInvoices.processingStep', {
            creditorInvoice: id
        });
        return client.put(route, data).then(res => res.data);
    }
    ProcessingType.updateData = updateData;
    class CreatableBankAccount extends SelectType {
        _renderWidget(props) {
            return (_jsxs(FlexRow, { gap: 10, children: [_jsx(Div, { flexGrow: 1, minw: 0, children: super._renderWidget(props) }), _jsxs(Button, { variant: 'outline', target: '_blank', href: generateRoute('bank_accounts_overview_page', {
                            openCreateModal: BankAccountType.CREDIT
                        }), children: [_jsx(Icon, { icon: 'plus' }), " ", translate('btn.add')] })] }));
        }
    }
    ProcessingType.CreatableBankAccount = CreatableBankAccount;
    class Position extends AbstractFormType {
        buildFields() {
            const isDisabled = orFunction(this.options.disabled);
            return {
                id: new NumberType({
                    isVisible: () => false,
                    required: false,
                    disabled: isDisabled,
                }),
                label: new StringType({
                    label: translate('invoice.position.label'),
                    required: true,
                    disabled: isDisabled,
                }),
                amount: new MoneyType({
                    label: translate('invoice.position.amount'),
                    required: true,
                    disabled: isDisabled,
                }),
                accountingDate: new DateType({
                    label: translate('creditor.positions.accountingDate.placeholder'),
                    required: true,
                    disabled: isDisabled,
                })
            };
        }
    }
    ProcessingType.Position = Position;
    class Positions extends CollectionType {
        _renderFooter() {
            let totalAmount;
            if (this.options.isFromAncillary?.()) {
                totalAmount = this.options.getCreditorAmount?.();
            }
            else {
                totalAmount = this.field.value?.reduce((sum, position) => Number(position['amount'] ?? 0) + sum, 0);
            }
            return (_jsxs(Tfoot, { style: { borderTop: '2px solid #d7d8db' }, children: [_jsx(Td, { children: _jsx("strong", { children: translate('total') }) }), _jsx(Td, { colSpan: 3, children: _jsx(FlexCol, { gap: 2, children: _jsx(Div, { children: formatMoney(totalAmount) }) }) })] }));
        }
    }
    ProcessingType.Positions = Positions;
})(ProcessingType || (ProcessingType = {}));
