import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import UsersTableModel from './components/Table/UserTableModel';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import { blockUser, getUsers, unBlockUser } from 'Services/UserServices';
import { useUserForm } from 'Pages/User/Overview/hooks/useUserForm';
import { FormModal } from '@f2w/form';
import { hasExceededNbUsersLimit } from 'Services/GlobalContext';
import UsersLimitExceededBanner from 'Components/App/AccountLimits/UsersLimitExceededBanner';
import { useUserDeleteApi } from 'Pages/User/Overview/hooks/useUserDeleteApi';
function useUsersTable({ onEditUser, onCreateUser, onBlockUser, onUnblockUser, onDeleteUser, hitLimit }) {
    const tableModel = React.useMemo(() => UsersTableModel.create({
        manualControl: true,
        initialState: {
            pageSize: 25
        }
    }), []);
    const getData = React.useCallback(async ({ request }) => {
        return getUsers(request);
    }, []);
    return tableModel.createRemote({
        getData,
        onCreateUser,
        onEditUser,
        onBlockUser,
        onUnblockUser,
        onDeleteUser,
        hitLimit,
    });
}
const UserListPage = ({ userServices }) => {
    const toastApi = useToastDispatcherApi();
    const hitLimit = hasExceededNbUsersLimit();
    const onDeleteUser = useUserDeleteApi({
        getUserDeletionSummary: userServices.getUserDeletionSummary,
        deleteUser: userServices.deleteUser,
        onSuccess: () => instance.refresh()
    });
    const instance = useUsersTable({
        onEditUser: ({ id }) => {
            userForm.openForm(id);
        },
        onCreateUser: () => {
            userForm.openForm();
        },
        onDeleteUser: onDeleteUser,
        onBlockUser: async (user, refresh) => {
            await blockUser(user.id);
            refresh();
        },
        onUnblockUser: async (user, refresh) => {
            try {
                await unBlockUser(user.id);
            }
            catch (error) {
                const status = error.response.status;
                if (status === 400) {
                    toastApi.error({ message: error.response.data.message, timeout: 10, showRemove: true });
                    return;
                }
                throw error;
            }
            finally {
                refresh();
            }
        },
        hitLimit,
    });
    const userForm = useUserForm({
        onSave: () => onSave(),
        ...userServices
    });
    const onSave = React.useCallback(() => {
        instance.refresh();
    }, [instance]);
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, title: translate('users.title'), content: hitLimit && _jsx(UsersLimitExceededBanner, {}) }), _jsx(FormModal, { modal: userForm })] }));
};
export default UserListPage;
