import generateRoute from "Services/Router";
import client from "Utils/client";
const listProcesses = (params) => {
    const route = generateRoute('api.process.list');
    return client
        .get(route, { params })
        .then(response => response.data);
};
const deleteProcess = (id) => {
    const route = generateRoute('delete_process', {
        process: id
    });
    return client.delete(route);
};
export default {
    listProcesses,
    deleteProcess
};
