import React from 'react';
import { isFunction } from 'Utils/types';
export function renderContent(content, props) {
    if (React.isValidElement(content)) {
        return React.cloneElement(content, props);
    }
    if (isFunction(content)) {
        return React.createElement(content, props);
    }
    return null;
}
