import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef } from 'react';
import classNames from 'classnames';
import BaseOverlay from 'react-overlays/Overlay';
import safeFindDOMNode from 'react-overlays/safeFindDOMNode';
import usePopperMarginModifiers from 'Components/Base/usePopperMarginModifiers';
import { Fade } from 'Components/Transition';
const defaultProps = {
    transition: Fade,
    rootClose: false,
    show: false,
    placement: 'top',
};
function wrapRefs(props, arrowProps) {
    const { ref } = props;
    const { ref: aRef } = arrowProps;
    props.ref = ref.__wrapped || (ref.__wrapped = (r) => ref(safeFindDOMNode(r)));
    arrowProps.ref =
        aRef.__wrapped || (aRef.__wrapped = (r) => aRef(safeFindDOMNode(r)));
}
export function Overlay({ children: overlay, transition, popperConfig = {}, ...outerProps }) {
    const popperRef = useRef({});
    const [ref, marginModifiers] = usePopperMarginModifiers();
    const actualTransition = transition === true ? Fade : transition || null;
    return (_jsx(BaseOverlay, { ...outerProps, ref: ref, popperConfig: {
            ...popperConfig,
            modifiers: marginModifiers.concat(popperConfig.modifiers || []),
        }, transition: actualTransition, children: ({ props: overlayProps, arrowProps, show, update, forceUpdate: _, placement, state, ...props }) => {
            wrapRefs(overlayProps, arrowProps);
            const popper = Object.assign(popperRef.current, {
                state,
                scheduleUpdate: update,
                placement,
                outOfBoundaries: state?.modifiersData.hide?.isReferenceHidden || false,
            });
            if (typeof overlay === 'function')
                return overlay({
                    ...props,
                    ...overlayProps,
                    placement,
                    show,
                    ...(!transition && show && { className: 'show' }),
                    popper,
                    arrowProps,
                });
            return React.cloneElement(overlay, {
                ...props,
                ...overlayProps,
                placement,
                arrowProps,
                popper,
                className: classNames(overlay.props.className, !transition && show && 'show'),
                style: {
                    ...overlay.props.style,
                    ...overlayProps.style,
                },
            });
        } }));
}
Overlay.defaultProps = defaultProps;
