import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useCallback } from 'react';
import Transition, { ENTERED, ENTERING } from 'react-transition-group/Transition';
import CSSTransition from 'react-transition-group/CSSTransition';
import css from 'dom-helpers/css';
import transitionEnd from 'dom-helpers/transitionEnd';
import triggerBrowserReflow from 'Components/Base/triggerBrowserReflow';
import './Transition.styles.scss';
export const AnimateCss = React.forwardRef(({ children, type, className, ...props }, ref) => {
    return (_jsx(CSSTransition, { ref: ref, addEndListener: transitionEndListener, classNames: {
            appear: '__show',
            appearActive: '__show-active',
            appearDone: '__show-done',
            exit: '__hide',
            exitActive: '__hide-active',
            exitDone: '__hide-done',
        }, ...props, children: (state, innerProps) => {
            return React.cloneElement(children, {
                ...innerProps,
                className: classNames(`fw-${type || 'fade'}`, children.props.className),
            });
        } }));
});
const defaultProps = {
    in: false,
    timeout: 300,
};
export const Fade = React.forwardRef(({ className, onEnter, children, ...props }, ref) => {
    const handleEnter = useCallback((node, isAppearing) => {
        triggerBrowserReflow(node);
        onEnter?.(node, isAppearing);
    }, [onEnter]);
    return (_jsx(Transition, { ref: ref, addEndListener: transitionEndListener, ...props, onEnter: handleEnter, children: (status, innerProps) => {
            return React.cloneElement(children, {
                ...innerProps,
                className: classNames('fade', className, children.props.className, {
                    'show': [ENTERING, ENTERED].includes(status),
                }),
            });
        } }));
});
Fade.defaultProps = defaultProps;
Fade.displayName = 'Fade';
const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};
export const AnimateS = ({ timeout, className, appear, ...props }) => {
    const defaultStyle = {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 0,
    };
    return (_jsx(Transition, { ...props, timeout: 300, children: (state) => (_jsx("div", { style: {
                ...defaultStyle,
                ...transitionStyles[state]
            }, children: "I'm A fade Transition!" })) }));
};
function parseDuration(node, property) {
    const str = css(node, property) || '';
    const mult = str.indexOf('ms') === -1 ? 1000 : 1;
    return parseFloat(str) * mult;
}
export function transitionEndListener(element, handler) {
    const duration = parseDuration(element, 'transitionDuration');
    const delay = parseDuration(element, 'transitionDelay');
    const remove = transitionEnd(element, (e) => {
        if (e.target === element) {
            remove();
            handler(e);
        }
    }, duration + delay);
}
