import { jsx as _jsx } from "react/jsx-runtime";
import { useViewType } from '@f2w/view';
import HandoverService from 'Services/HandoverService';
import { HandoverType } from 'Pages/Handover/components/HandoverView/component/HandoverType';
import { HandoverTemplate } from 'Pages/Handover/components/HandoverTemplate';
import { translate } from 'Services/Translator';
import { GeneralTab } from 'Pages/Handover/components/HandoverView/component/GeneralTab';
import { MoveInTab } from 'Pages/Handover/components/HandoverView/component/MoveInTab';
import { MoveOutTab } from 'Pages/Handover/components/HandoverView/component/MoveOutTab';
import { DocumentPreviewTemplate } from 'Pages/Handover/components/HandoverView/component/DocumentPreview';
export const useHandoverView = ({ getHandoverDetails: loadData = HandoverService.getHandoverDetails, }) => useViewType(() => new HandoverType(), {
    id: 'handover-view',
    load: async (id) => {
        const data = await loadData(id);
        return {
            ...data,
        };
    },
    DefaultTemplate: props => _jsx(HandoverTemplate, { ...props }),
}, () => ({
    general: {
        name: translate('handovers.detailView.tabs.general'),
        Content: (props) => _jsx(GeneralTab, { ...props }),
        hasForm: false,
        showHeader: true,
    },
    moveOut: {
        name: translate('handovers.detailView.tabs.moveOut'),
        Content: (props) => _jsx(MoveOutTab, { ...props }),
        showHeader: true,
    },
    moveIn: {
        name: translate('handovers.detailView.tabs.moveIn'),
        Content: (props) => _jsx(MoveInTab, { ...props }),
        showHeader: true,
    },
    filePreview: {
        name: '',
        Content: (props) => _jsx(DocumentPreviewTemplate, { view: props.view }),
        showHeader: false,
    }
}));
