import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, DateType, useFormModal } from '@f2w/form';
import { translate } from 'Services/Translator';
import moment from 'moment';
import { Button } from 'Atoms/Button';
class PersonAccountStatementReportsExportType extends AbstractFormType {
    buildFields() {
        return {
            fromDate: new DateType({
                label: translate('fromDate'),
                required: false,
                defaultValue: () => moment().startOf('year').format('YYYY-MM-DD'),
                getProps: () => {
                    return {
                        isClearable: true
                    };
                },
                constraints: {
                    test: ['is-before-end', translate('dunning.accountStatements.export.rangeError'), (currentValue) => {
                            return this.field.value.toDate && currentValue
                                ? moment(currentValue).isSameOrBefore(moment(this.field.value.toDate))
                                : true;
                        }]
                }
            }),
            toDate: new DateType({
                label: translate('untilDate'),
                required: false,
                defaultValue: () => moment().endOf('year').format('YYYY-MM-DD'),
                getProps: () => {
                    return {
                        isClearable: true
                    };
                },
                constraints: {
                    test: ['is-after-start', translate('dunning.accountStatements.export.rangeError'), (currentValue) => {
                            return this.field.value.fromDate && currentValue
                                ? moment(currentValue).isSameOrAfter(moment(this.field.value.fromDate))
                                : true;
                        }]
                }
            })
        };
    }
}
export const usePersonAccountStatementReportsExportForm = ({ getPersonIds, getPersonAccountStatementReports }) => useFormModal({
    name: 'person-account-statement-reports-export-form',
    title: translate('dunning.accountStatements.export'),
    type: () => new PersonAccountStatementReportsExportType(),
    successMessage: '',
    errorMessage: translate('dunning.accountStatements.error'),
    onSave: (values) => {
        return getPersonAccountStatementReports(getPersonIds(), values.fromDate, values.toDate);
    },
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('btn.download') })] });
    },
});
