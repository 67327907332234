import { isObjectLike } from 'Utils';
import { CompoundState, CompoundValue, } from '../base';
export class ArrayState extends CompoundState {
    getInitialConfig() {
        const { index, ...prev } = super.getInitialConfig();
        return CompoundState.createConfig({
            ...prev,
            index: {
                ...index,
                selected: {
                    getValue: (set) => set.size === this.getChildrenSize(),
                    onAdd: (keys) => {
                        this.updateChildren({
                            childrenOrKeys: keys,
                            ignoredKeys: ['selected']
                        });
                    },
                    onRemove: (keys) => {
                        this.indexes.hasError.updateAll(keys, false);
                        this.indexes.validated.updateAll(keys, false);
                        this.updateParent();
                    },
                    onChange: (keys, add, indexType) => {
                        this._updateParentSelected(this.isAllSelected());
                    },
                }
            }
        });
    }
    onAddChild(child) {
        this._nextIndexKey++;
        this.indexes.selected.add(child);
    }
    onRemoveChild(child) {
        this.indexes.selected.remove(child);
    }
    _updateParentSelected(add, child = this.parentManager) {
        if (child?.['parentManager'] instanceof ArrayState)
            return child['parentManager'].indexes.selected.update(child.key, add);
        if (child?.['parentManager'])
            return this._updateParentSelected(add, child?.['parentManager']);
    }
    getActiveChildrenSize() {
        return this.children.filter(c => (this._self().isPartialSelected(c) || this._self().isSelected(c))).length;
    }
    isAllSelected() {
        return this.indexes.selected.get();
    }
    isSomeSelected() {
        return this.indexes.selected.size > 0;
    }
    get value() {
        return this.data.value;
    }
    get children() {
        return [...this.childrenMap.values()];
    }
    _nextIndexKey = 0;
    getNextIndexKey() {
        return this._nextIndexKey.toString();
    }
    _type() {
        return super._type();
    }
    _getOrCreate(key) {
        if (key && this.has(key)) {
            return this.get(key);
        }
        key = this.getNextIndexKey();
        const proto = this._type().createProtoType(key);
        const child = proto.createValue({ key });
        this.register(key, child);
        return child;
    }
}
export class ArrayValue extends CompoundValue {
    _state;
    constructor(type, props) {
        super(type, props);
        this._state = new ArrayState({
            getSelf: () => this,
            onIndexChange: ({ propName, ...props }) => {
                switch (propName) {
                    case 'selected':
                        this.options.selectable?.onChange?.(this, props);
                        break;
                }
            },
        });
    }
    get manager() {
        return this._state;
    }
    get indexes() {
        return this._state.indexes;
    }
    get protoType() {
        return this.specs.proto;
    }
    _selectableChild(keyOrChild) {
        return this.options.selectable?.getChild?.(this._state.get(keyOrChild));
    }
    isPartialSelected(keyOrChild) {
        const childArray = this._selectableChild(keyOrChild);
        if (childArray) {
            return childArray.manager.isSomeSelected() && !childArray.manager.isAllSelected();
        }
    }
    isSelected(keyOrChild) {
        const childArray = this._selectableChild(keyOrChild);
        if (false === childArray?.isEmpty) {
            return childArray.manager.isSomeSelected();
        }
        else {
            return this._state.indexes.selected.has(keyOrChild);
        }
    }
    updateSelected(keyOrChild, add = !this.isSelected(keyOrChild), skipRender) {
        let updated;
        const childArray = this._selectableChild(keyOrChild);
        if (childArray) {
            if (childArray?.hasChildren) {
                childArray.forEach(c => {
                    childArray.updateSelected(c.key, add, true);
                });
            }
        }
        updated = this._state.indexes.selected.update(keyOrChild, add);
        if (!skipRender) {
            this._dispatchUpdate();
        }
    }
    isEnabled(keyOrChild) {
        return !this.options.selectable || (this.isSelected(keyOrChild));
    }
    getResolvedValue(options) {
        return this._state.map(child => {
            if (this.isEnabled(child)) {
                return child.getResolvedValue(options);
            }
        });
    }
    get children() {
        return this._state.children;
    }
    mapSelected(cb) {
        return this._state.indexes.selected.map(key => cb(this._state.get(key), key));
    }
    push(value) {
        const child = this._state._getOrCreate();
        child.reset(value, false, true);
        this._handleUpdate();
    }
    pushAll(values) {
        values.forEach(value => {
            const child = this._state._getOrCreate();
            child.reset(value, false, true);
        });
        this._handleUpdate();
    }
    remove(key) {
        if (this._state.remove(key)) {
            this._handleUpdate();
        }
    }
    reset(initialValue, shouldValidate, skipRender) {
        const value = isObjectLike(initialValue) ? initialValue : this.specs.getDefault();
        if (Array.isArray(value)) {
            value.forEach((val, key) => {
                const child = this._state._getOrCreate(key);
                child.reset(val, false, true);
            });
        }
        return this._handleUpdate(skipRender, shouldValidate);
    }
    setValue(value, shouldValidate, skipRender) {
        value = isObjectLike(value) ? value : this.specs.getDefault();
        if (Array.isArray(value)) {
            value.forEach((val, key) => {
                let child = this._state._getOrCreate(key);
                child.setValue(val, false, true);
            });
        }
        return this._handleUpdate(skipRender, shouldValidate);
    }
    updateValue(value, shouldValidate, skipRender) {
        return this;
        if (Array.isArray(value)) {
            value.forEach(key => {
                if (this.has(key)) {
                    this.get(key).updateValue(value[key], shouldValidate, true);
                }
                else {
                    this.push(value[key]);
                }
            });
        }
        else {
            this.forEach(child => child.updateValue(undefined, shouldValidate, true));
        }
        return this._handleUpdate(skipRender, shouldValidate);
    }
    setTouched(value, shouldValidate, skipRender) {
        if (Array.isArray(value)) {
            value.forEach((val, key) => this.get(key)?.setTouched(val, false, true));
        }
        else {
            this.forEach(child => child.setTouched(!!value, false, true));
        }
        return this._handleUpdate(skipRender, shouldValidate);
    }
    setError(value, skipRender) {
        if (Array.isArray(value)) {
            value.forEach((val, key) => this.get(key)?.setError(val, true));
        }
        else {
            this.forEach(child => child.setError(!!value, true));
        }
        return this._handleUpdate(skipRender);
    }
}
