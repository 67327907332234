import { useMemo } from 'react';
import { translate } from 'Services/Translator';
import { isString, range } from 'Utils';
import { MenuBuilder, MenuLink } from 'Components/Atoms/Dropdown/helpers';
class InvoiceAction extends MenuLink {
}
class InvoiceActions {
    props;
    constructor(props) {
        this.props = props;
    }
    get defaultProps() {
        const { mandateName, reminder, withQr, ...props } = this.props;
        return props;
    }
    reminders(invoice) {
        return range(this.props.reminder ?? 0, 1).map(reminder => this.createInvoice({ ...invoice.args, reminder }));
    }
    invoices() {
        const { withQr, mandateName, } = this.props;
        return [
            ...this.invoiceTypes({}),
            ...this.invoiceTypes2(withQr, { withQr }),
            ...this.invoiceTypes2(mandateName, { mandateName }),
            ...this.invoiceTypes2((mandateName && withQr), { mandateName, withQr }),
        ].flat(3).filter(v => v);
    }
    invoiceTypes2(_if, initialArgs) {
        return this.invoiceTypes(initialArgs, _if);
    }
    invoiceTypes(initialArgs, condition = true) {
        const { allPersons, allParties, ...args } = {
            ...this.defaultProps,
            ...initialArgs,
        };
        return !condition ? [] : [
            this.createInvoice(args),
            allPersons && this.createInvoice({ ...args, allPersons }),
            allParties && this.createInvoice({ ...args, allParties }),
        ].flat(3).filter(v => v);
    }
    createInvoice({ mandateName, ...initialArgs }) {
        const args = {
            invoiceId: this.props.invoiceId,
            printable: this.props.printable ?? false,
            background: this.props.background,
            mandateName: isString(mandateName) ? mandateName : (mandateName ? this.props.mandateName : undefined),
            ...initialArgs,
        };
        let transKey = args.withQr ? 'qr' : 'pdf';
        if (args.allParties) {
            transKey += `.allParties`;
        }
        else if (args.allPersons) {
            transKey += `.allPersons`;
        }
        return new InvoiceAction({
            args,
            props: {
                target: '_blank'
            },
            translationKey: args.reminder > 0 ? `invoice.reminder.state.${transKey}` : `invoice.download.${transKey}`,
            translationParams: args.reminder > 0 ? { number: args.reminder } : {},
            route: 'download_debitor_invoice',
            routeParams: {
                debitorInvoice: args.invoiceId,
                allParties: args.allParties,
                allPersons: args.allPersons,
                includeQR: args.withQr,
                mandated: args.mandateName ? true : undefined,
                reminder: args.reminder,
                printable: args.printable,
                background: args.background,
            },
        });
    }
    paymentSlip(initialArgs) {
        const args = {
            invoiceId: this.props.invoiceId,
            ...initialArgs,
            type: 'paymentSlip',
        };
        const { printable, invoiceId: debitorInvoice } = args;
        return !this.props.withQr ? null : MenuBuilder.link({
            args,
            translationKey: printable ? 'qr.paymentSlip.printable' : 'qr.paymentSlip',
            route: 'create_qr_payment_slip',
            routeParams: {
                debitorInvoice,
                printable
            },
        });
    }
}
export function useDebitorInvoiceActions(props) {
    const { invoiceId, withQr } = props;
    return useMemo(() => {
        const menu = MenuBuilder.create(`debitor-invoice-${invoiceId}`);
        const mandated = menu.builder();
        const notMandated = menu.builder();
        const actionsBuilder = new InvoiceActions(props);
        actionsBuilder.invoices().forEach(link => {
            let reminders = actionsBuilder.reminders(link);
            link.add(reminders);
            link.options.dropdown = { drop: 'right' };
            if (link.args.mandateName) {
                mandated.add(link);
            }
            else {
                notMandated.add(link);
            }
        });
        menu.divider();
        menu.add(notMandated);
        if (mandated.hasChildren()) {
            menu.header(translate('invoice.download.mandated', { name: props.mandateName }));
            menu.add(mandated);
        }
        if (withQr) {
            menu.divider();
            menu.add(actionsBuilder.paymentSlip({ printable: false }));
            menu.add(actionsBuilder.paymentSlip({ printable: true }));
        }
        return menu.init();
    }, [invoiceId, props]);
}
