import generateRoute, { generate } from "Services/Router";
import client from "Utils/client";
export var CustomerServices;
(function (CustomerServices) {
    CustomerServices.getCustomers = (params) => {
        const route = generate('admin.api.customers.list');
        return client
            .get(route, { params })
            .then(res => res.data);
    };
    CustomerServices.getActiveCustomers = async () => {
        const route = generate('api.admin.customers.getActive');
        return client
            .get(route)
            .then(res => res.data);
    };
    CustomerServices.toggleCustomerStatus = (toggleStatusRequest) => {
        const route = generate('admin.api.customers.toggleDisabledStatus');
        return client
            .put(route, toggleStatusRequest)
            .then(response => response.data);
    };
    CustomerServices.enableCustomerUser = (userId) => {
        const route = generate('admin.api.users.enable', {
            user: userId
        });
        return client.put(route);
    };
    CustomerServices.disableCustomerUser = (userId) => {
        const route = generate('admin.api.users.disable', {
            user: userId
        });
        return client.put(route);
    };
    CustomerServices.getCustomerUserEditData = (userId) => {
        const route = generateRoute('admin.api.user.getEditData', {
            user: userId
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    CustomerServices.updateCustomerUserEditData = (userId, data) => {
        const route = generateRoute('admin.api.user.update', {
            user: userId
        });
        return client.put(route, data);
    };
    CustomerServices.getCustomerUserRoles = (userId) => {
        const route = generateRoute('admin.api.users.getUserRoles', {
            user: userId
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    CustomerServices.getCustomerUserMandates = (userId) => {
        const route = generateRoute('admin.api.users.getMandates', {
            user: userId
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    CustomerServices.getCustomerDetails = (id) => {
        const route = generateRoute('admin.api.customers.details', {
            customer: id
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    CustomerServices.editCustomer = (id, data) => {
        const route = generateRoute('admin.api.customers.edit', {
            customer: id
        });
        return client
            .put(route, data)
            .then(response => response.data);
    };
    CustomerServices.resendCustomerRegistrationEmail = (id) => {
        return client.post(generateRoute('admin.api.customer.resendRegistrationEmail', { customer: id }));
    };
    CustomerServices.resendCustomerUserInvitationEmail = (id) => {
        return client.post(generateRoute('admin.api.users.resendInvitationEmail', { user: id }));
    };
    CustomerServices.deleteSelectedCustomers = (ids) => {
        const route = generateRoute('api.admin.customers.deleteSelectedDisabled');
        return client.delete(route, {
            data: { customerIds: ids }
        });
    };
})(CustomerServices || (CustomerServices = {}));
export var HandoverServices;
(function (HandoverServices) {
    HandoverServices.listHandoverCostOverview = async (params) => {
        const route = generate('api.admin.handoverProtocols.costOverview');
        const { data } = await client.get(route, { params });
        return data;
    };
    HandoverServices.invoiceHandoverProtocols = async (ids) => {
        const route = generate('api.admin.handoverProtocols.invoice');
        return client.post(route, { ids });
    };
    HandoverServices.dropInvoiceHandoverProtocols = async (ids) => {
        const route = generate('api.admin.handoverProtocols.dropInvoice');
        return client.post(route, { ids });
    };
})(HandoverServices || (HandoverServices = {}));
export var CustomerSubscriptionTypeServices;
(function (CustomerSubscriptionTypeServices) {
    CustomerSubscriptionTypeServices.getAllActiveSubscriptionPlans = async () => {
        const route = generate('admin.api.customerSubscriptionTypes.getActive');
        const { data } = await client.get(route);
        return data;
    };
})(CustomerSubscriptionTypeServices || (CustomerSubscriptionTypeServices = {}));
export var CustomerSubscriptionServices;
(function (CustomerSubscriptionServices) {
    CustomerSubscriptionServices.getCreateSubscriptionSuggestion = async (customerId) => {
        const route = generate('admin.api.customerSubscriptions.getCreateSuggestion', {
            customer: customerId
        });
        const { data } = await client.get(route);
        return data;
    };
    CustomerSubscriptionServices.getUpgradeSubscriptionSuggestion = async (customerId) => {
        const route = generate('admin.api.customerSubscriptions.getUpgradeSuggestion', {
            customer: customerId
        });
        const { data } = await client.get(route);
        return data;
    };
    CustomerSubscriptionServices.getDowngradeSubscriptionSuggestion = async (customerId) => {
        const route = generate('admin.api.customerSubscriptions.getDowngradeSuggestion', {
            customer: customerId
        });
        const { data } = await client.get(route);
        return data;
    };
    CustomerSubscriptionServices.createSubscription = async (customerId, isUpgrade, data) => {
        const route = generate('admin.api.customerSubscriptions.create', {
            customer: customerId,
            isUpgrade: isUpgrade ? 1 : 0
        });
        return client.post(route, data);
    };
    CustomerSubscriptionServices.updateSubscription = async (subscriptionId, data) => {
        const route = generate('admin.api.customerSubscriptions.update', {
            subscription: subscriptionId
        });
        return client.put(route, data);
    };
    CustomerSubscriptionServices.loadSubscription = async (subscriptionId) => {
        const route = generate('admin.api.customerSubscriptions.getForUpdate', {
            subscription: subscriptionId
        });
        const { data } = await client.get(route);
        return data;
    };
    CustomerSubscriptionServices.deleteSubscription = async (subscriptionId) => {
        const route = generate('admin.api.customerSubscriptions.delete', {
            customerSubscription: subscriptionId
        });
        return client.delete(route);
    };
    CustomerSubscriptionServices.getSubscriptionDetails = async (customerSubscriptionId) => {
        const route = generate('admin.api.customerSubscriptions.get', {
            customerSubscription: customerSubscriptionId
        });
        const { data } = await client.get(route);
        return data;
    };
})(CustomerSubscriptionServices || (CustomerSubscriptionServices = {}));
