import { forwardRef, } from 'react';
import baseStyled from 'styled-components';
import { Unit } from '../system/utils';
export function styledModule(components) {
    return components();
}
const trimChar = (string, charToRemove = ' ') => {
    while (string.charAt(0) == charToRemove) {
        string = string.substring(1);
    }
    while (string.charAt(string.length - 1) == charToRemove) {
        string = string.substring(0, string.length - 1);
    }
    return string;
};
const trimPrefix = (value) => {
    value = trimChar(value ?? '', '-');
    return value ? `--${value}` : value;
};
export function cssVars(obj, parentPrefix = '', resolveValue, vars = {}) {
    const resolve = (obj, parentKey) => {
        for (const key of Object.keys(obj)) {
            let path = cssVars.transformKey(key);
            let value = obj[key];
            if (parentKey)
                path = `${parentKey}-${path}`;
            if (typeof value === 'object') {
                resolve(value, path);
                continue;
            }
            path = trimPrefix(path);
            value = resolveValue
                ? resolveValue(value)
                : typeof value === 'number' ? Unit.setUnit(value, 'px') : value;
            if (value != null)
                vars[path] = value;
        }
    };
    obj && resolve(obj, trimPrefix(parentPrefix));
    return vars;
}
(function (cssVars) {
    cssVars.transformKey = (key) => {
        return key
            .replace(/[^\d\w-]/g, '')
            .replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
    };
})(cssVars || (cssVars = {}));
export var UseStyled;
(function (UseStyled) {
    function styled(component, ...styles) {
        return styledWithAttrs(component, null, ...styles);
    }
    UseStyled.styled = styled;
    function css(...styles) {
        return styles;
    }
    UseStyled.css = css;
    function styledWithAttrs(component, attrsFn, first, ...styles) {
        let styledComponent = baseStyled(component);
        if (attrsFn)
            styledComponent = styledComponent.attrs(attrsFn);
        return styledComponent(first || {}, ...styles);
    }
    UseStyled.styledWithAttrs = styledWithAttrs;
    function Fn(component, ...styles) {
        return FnWithAttrs(component, null, ...styles);
    }
    UseStyled.Fn = Fn;
    function FnWithAttrs(component, attrsFn, ...styles) {
        return styledWithAttrs(component, attrsFn, ...styles);
    }
    UseStyled.FnWithAttrs = FnWithAttrs;
    function RefFn(component, ...styles) {
        return RefFnWithAttrs(component, null, ...styles);
    }
    UseStyled.RefFn = RefFn;
    function RefFnWithAttrs(component, attrsFn, ...styles) {
        return styledWithAttrs(forwardRef(component), attrsFn, ...styles);
    }
    UseStyled.RefFnWithAttrs = RefFnWithAttrs;
})(UseStyled || (UseStyled = {}));
