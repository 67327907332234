import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { DataTable } from '@f2w/data-table';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import moment from 'moment/moment';
import { Div } from 'Atoms/Layout';
import { formatMoney } from 'Services/NumberHelper';
export const PaymentTable = DataTable.factory()(() => ({
    tableId: 'Debitor/Payment',
    manualControl: false,
    useSortBy: false,
    usePagination: {
        hideInfo: true
    },
    initialState: {
        pageSize: 5
    },
    RowActions: ({ row: { original: { id } }, onPaymentDelete, deletePayment, dispatcher }) => (_jsx(ActionsDropdown, { id: id, quickActions: [
            {
                icon: 'trash',
                color: 'error',
                title: translate('payment.delete'),
                confirm: {
                    message: translate('payment.delete.title'),
                    help: translate('payment.delete.text'),
                    btnConfirm: translate('btn.confirm')
                },
                onClick: () => {
                    return deletePayment(id)
                        .then(() => {
                        dispatcher.toast.success({
                            timeout: 2,
                            message: translate('payment.delete.successFlash')
                        });
                        return onPaymentDelete(id);
                    })
                        .catch(({ response }) => {
                        const errorMessage = (response.status === 422)
                            ? response.data.message
                            : translate('payment.delete.errorFlash');
                        dispatcher.toast.error({
                            timeout: 5,
                            message: errorMessage
                        });
                    });
                }
            }
        ] })),
    columns: {
        paidAt: {
            Header: translate('payment.paidAt'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ? moment(value).format('DD.MM.YYYY') : translate('notAvailable') }))
        },
        accountingLabel: {
            Header: translate('accountingRecord.label'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ?? translate('notAvailable') }))
        },
        amount: {
            Header: translate('payment.amount'),
            Cell: ({ value }) => (_jsx(Div, { textNoWrap: true, children: value !== undefined ? formatMoney(value) : translate('notAvailable') }))
        },
        debitAccountDescription: {
            Header: translate('debitAccount'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ?? translate('notAvailable') }))
        }
    },
}));
