import util from "util";
import { isObject } from "Utils/types";
export function dump(value, inline = true) {
    if (!isObject(value)) {
        return "";
    }
    const keys = Object.keys(value);
    if (!keys.length) {
        return "";
    }
    const maxLength = 4 + keys.map(v => v.length).reduce((p, c) => Math.max(p, c));
    return keys.map(key => {
        const name = key.trim() ? `${key}:` : '';
        return [name.padEnd(maxLength), inline ? formatDumpInline(value[key]) : formatDump(value[key])].join("");
    }).join("\n");
}
(function (dump) {
    dump.inspect = util.inspect;
})(dump || (dump = {}));
export const formatDump = (...args) => util.format(...args);
export const formatDumpInline = (...args) => formatDump(...args).replaceAll(/[\s\n\t]+/gi, " ");
