import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { convertOptionsToValues } from 'Utils/select';
import { Select } from 'Components/Atoms';
import { useEventCallback } from '@restart/hooks';
import { isNumLike, orFunction, OrFunction, toArray, toNum } from 'Utils/types';
import { AbstractType } from './AbstractType';
import * as Schema from '../schema';
import { ReferenceField } from '../form';
import { CollectionType } from './CollectionType';
import { translate } from 'Services/Translator';
import { AbstractSimpleType } from './SimpleType';
export class AbstractChoiceType extends AbstractType {
    constructor(options, parent) {
        super(options, parent, options?.multiple ? new Schema.ArraySchema(new Schema.MixedSchema()) : new Schema.MixedSchema());
    }
    __getDefaultOptions() {
        return {
            label: true,
            ...super.__getDefaultOptions(),
        };
    }
    __configureOptions(options) {
        if (options.multiple) {
        }
        if (!options.choiceOptions && options.choices) {
            const resolved = OrFunction(options.choices);
            options.choiceOptions = Array.isArray(resolved) ? resolved : Object.keys(resolved).map((key) => ({
                value: isNumLike(key) ? toNum(key) : key,
                label: options.choices[key],
            }));
        }
        return options;
    }
    _createField(props) {
        return new ReferenceField(this, {
            ...props,
        });
    }
    get choiceOptions() {
        return toArray(OrFunction(this.options.choiceOptions));
    }
    cast(value, ignoreDefaults) {
        if (this.options.multiple) {
            if (!Array.isArray(value) || !value.length) {
                if (this.isNullable)
                    return null;
                if (!ignoreDefaults) {
                    return this.cast(this.schema.getDefault(), true);
                }
                return (this.isRequired ? undefined : []);
            }
            return value;
        }
        return AbstractSimpleType.prototype.cast.call(this, value, true);
    }
    getWidgetProps() {
        const inputProps = {
            name: this.field.path,
            defaultValue: this.field.initialValue,
            value: this.field.value ?? null,
            options: this.field.options,
        };
        return {
            ...inputProps,
            ...AbstractChoiceType.getEventProps(this.field),
        };
    }
    static enumToChoices(enumValue) {
        return Object.values(enumValue).map((value) => ({
            label: translate('mortgage.type.' + value),
            value: value,
        }));
    }
    static getEventProps(_field) {
        const onChange = useCallback((option) => {
            _field.setTouched(true, false);
            const value = convertOptionsToValues(option);
            _field.setValue(value);
        }, []);
        const onBlur = useEventCallback((event) => {
            _field.setTouched(true, true);
        });
        return {
            onChange,
            onBlur,
            id: `${_field.id}--widget`,
        };
    }
}
export class SelectType extends AbstractChoiceType {
    constructor(options) {
        super({
            ...options,
        });
    }
    getWidgetProps() {
        return {
            defaultValue: this.field.initialValue ?? null,
            ...super.getWidgetProps(),
            isMulti: this.options.multiple,
            isClearable: this.options.clearable == null ? this.field.isNullable : this.options.clearable,
            menuPlacement: this.options?.menuPlacement || 'auto',
            isSearchable: this.options.searchable,
            placeholder: this.options.placeholder || translate('select.placeholder'),
            isDisabled: orFunction(this.options.disabled),
            $variant: this.field.showError ? 'error' : 'primary',
            confirm: this.options.confirm,
            container: true,
            ...this.options?.getProps?.(),
        };
    }
    _renderWidget(props) {
        return _jsx(Select, { ...{
                ...this.getWidgetProps(),
                $size: this.parent?.parent instanceof CollectionType ? 'sm' : this.options.size,
                ...props
            } });
    }
}
