import { AbstractFormType, CollectionType, DateType, MoneyType, NumberType, SelectType, StringType } from "@f2w/form";
import { translate } from "Services/Translator";
import { useFinancialAccountsHook } from "Hooks/useFinancialAccountsHook";
class SendDebitorPositionType extends AbstractFormType {
    buildFields() {
        return {
            id: new NumberType({
                isVisible: () => false
            }),
            label: new StringType({
                label: translate('accounting.record.label'),
            }),
            amount: new MoneyType({
                label: translate('accounting.record.value')
            }),
            creditAccountId: new SelectType({
                label: translate('finances.creditAccount'),
                searchable: true,
                choiceOptions: () => this.options?.financialAccounts?.()
            })
        };
    }
}
export class SendDebitorType extends AbstractFormType {
    financialAccounts = [];
    _useField(_props) {
        const { groupedFinancialAccountOptions } = useFinancialAccountsHook({
            propertyId: this.field.value.propertyId
        });
        this.financialAccounts = groupedFinancialAccountOptions;
    }
    buildFields() {
        return {
            label: new StringType({
                label: translate('accounting.record.label'),
            }),
            bookingDate: new DateType({
                label: translate('accounting.record.booking.date')
            }),
            positions: new CollectionType({
                label: false,
                prototype: () => new SendDebitorPositionType({
                    financialAccounts: () => this.financialAccounts
                }),
                widget: "table"
            })
        };
    }
}
