import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { translate } from 'Services/Translator';
import DamagesTableModel from './components/Table/DamageTableModel';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { deleteDamage, getDamages, updateDamageStatus } from 'Services/DamageManager';
import { useModalDispatcherConfirm, useToastDispatcherApi } from 'Components/Dispatcher';
import DamageStatusSelect from 'Pages/Damage/components/DamageStatusSelect';
import DamageEditModal from 'Pages/Damage/Edit/DamageEditModal';
import { useObjectViewModalContext } from 'Components/ObjectViewModal';
function useDamagesTable({ onEditDamage, onChangeDamageStatus, onCreateDamage, onDeleteDamage }) {
    const tableModel = React.useMemo(() => DamagesTableModel.create({
        manualControl: true,
        initialState: {
            pageSize: 25
        }
    }), []);
    const getData = React.useCallback(async ({ request }) => {
        return getDamages(request);
    }, []);
    return tableModel.createRemote({
        getData,
        onCreateDamage,
        onEditDamage,
        onDeleteDamage,
        onChangeDamageStatus,
    });
}
const DamageListPage = ({ openUpsertModal }) => {
    const { openViewModalObj } = useObjectViewModalContext();
    const [selectedDamage, setSelectedDamage] = useState(undefined);
    const [showEditModal, setShowEditModal] = useState(openUpsertModal);
    const toastApi = useToastDispatcherApi();
    const onDeleteConfirm = useModalDispatcherConfirm({
        onConfirm: async ({ id }, refresh) => {
            try {
                await deleteDamage(id);
            }
            catch (e) {
                toastApi.error({ message: translate('damages.delete.error'), timeout: 5, showRemove: true });
                console.error(e);
            }
            finally {
                refresh();
            }
        },
        message: translate('damages.delete.message'),
        help: translate('damages.delete.help'),
    });
    const instance = useDamagesTable({
        onEditDamage: (damage) => {
            setSelectedDamage(damage);
            setShowEditModal(true);
        },
        onChangeDamageStatus: async (damageId, status) => {
            await updateDamageStatus(damageId, status);
        },
        onCreateDamage: () => {
            setSelectedDamage(null);
            setShowEditModal(true);
        },
        onDeleteDamage: async (damage, refresh) => {
            await onDeleteConfirm(damage, refresh);
        },
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, title: translate('Damages'), inlineFilters: true, Filters: () => {
                    const statusFilter = instance.state.filters.find(({ id }) => id === 'status')?.value;
                    return _jsx(_Fragment, { children: _jsxs("div", { children: [_jsxs("label", { htmlFor: "damage-status-filter", children: [translate('damage.status'), ":"] }), _jsx("div", { id: "damage-status-filter", style: { display: "inline-block", minWidth: 270, marginLeft: "2rem" }, children: _jsx(DamageStatusSelect, { isClearable: true, value: statusFilter, onChange: (status) => {
                                            instance.setFilter('status', status ? status.value : null);
                                        } }) })] }) });
                } }), showEditModal && _jsx(DamageEditModal, { damageId: selectedDamage ? selectedDamage.id : null, onSaved: () => { instance.refresh(); setShowEditModal(false); }, onHide: () => setShowEditModal(false) })] }));
};
export default DamageListPage;
