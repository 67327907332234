import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ReAssignCustomerDocumentType } from "Pages/CustomerDocuments/types/ReAssignCustomerDocumentType";
import { SelectType } from "@f2w/form";
import { translate } from "Services/App";
import { useEffect, useMemo } from "react";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { DateRangeType } from "Pages/Rent/forms/DateRangeType";
import { categoryOptions } from "Pages/CustomerDocuments/utils";
export class ExportCustomerDocumentsType extends ReAssignCustomerDocumentType {
    mandates = [];
    properties = [];
    _useField(props) {
        super._useField(props);
        const portfolio = usePortfolio();
        this.mandates = useMemo(() => {
            return portfolio.getMandates()
                .map(m => ({
                label: m.name,
                value: m.id
            }));
        }, []);
        this.properties = useMemo(() => {
            if (!this.field.value.mandateId) {
                return [];
            }
            return portfolio.getPropertiesByMandateId(this.field.value.mandateId)
                .map(p => ({
                label: p.name,
                value: p.id
            }));
        }, [this.field.value.mandateId]);
        useEffect(() => {
            if (this.field.value.propertyId && portfolio.getPropertyById(this.field.value.propertyId).mandate.id !== this.field.value.mandateId) {
                this.children.propertyId.field.setValue(undefined);
                this.children.buildingId.field.setValue(undefined);
                this.children.rentalObjectId.field.setValue(undefined);
                this.children.contractId.field.setValue(undefined);
            }
        }, [this.field.value.mandateId]);
    }
    buildFields() {
        const { propertyId, buildingId, rentalObjectId, contractId } = super.buildFields();
        propertyId.isVisible = () => this.field.value.mandateId !== null;
        return {
            mandateId: new SelectType({
                label: translate('property.mandate'),
                required: false,
                searchable: true,
                choiceOptions: () => this.mandates
            }),
            propertyId,
            buildingId,
            rentalObjectId,
            contractId,
            category: new SelectType({
                label: translate('documentsManager.documents.category'),
                required: false,
                clearable: true,
                searchable: true,
                choiceOptions: () => categoryOptions
            }),
            createdAtRange: new DateRangeType({
                label: false,
                required: false,
                type: 'day',
                fromDateLabel: translate('customerDocuments.export.createdAt.from'),
                toDateLabel: translate('customerDocuments.export.createdAt.to'),
                defaultValue: () => ({
                    start: undefined,
                    end: undefined
                })
            })
        };
    }
    _renderWidget(_iProps) {
        return (_jsxs(_Fragment, { children: [this.renderChild('mandateId'), this.renderChild('propertyId'), this.renderChild('buildingId'), this.renderChild('rentalObjectId'), this.renderChild('contractId'), this.renderChild('category'), this.renderChild('createdAtRange')] }));
    }
}
