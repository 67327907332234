import { translate } from 'Services/App';
export const uploaderConfigs = {
    customer_documents: {
        maxSize: '8MiB',
        accept: [
            'text/plain',
            'image/jpeg',
            'image/png',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.presentation',
        ],
    },
    document_templates: {
        maxSize: '8MiB',
        accept: [
            'image/jpeg',
            'image/png',
        ],
        messages: {
            fileType: () => translate('imageUploadManager.error.fileType'),
            server: () => translate('imageUploadManager.error.server'),
        }
    },
};
