import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FlexCol, FlexRow, Icon } from 'Components/Atoms';
import { AbstractFormType, FormTheme, NumberType } from '@f2w/form';
import { translate } from 'Services/Translator';
import classnames from "classnames";
function getWidth(height, aspectRatio) {
    return Math.round(height * aspectRatio);
}
function getHeight(width, aspectRatio) {
    return Math.round(width / aspectRatio);
}
function getMeta(img) {
    let maxWidth = 169;
    let maxHeight = 25;
    const maxAspectRatio = maxWidth / maxHeight;
    const aspectRatio = img.width / img.height;
    if (aspectRatio > maxAspectRatio) {
        maxHeight = getHeight(maxWidth, aspectRatio);
    }
    else if (aspectRatio < maxAspectRatio) {
        maxWidth = getWidth(maxHeight, aspectRatio);
    }
    let minWidth = 1;
    let minHeight = 1;
    if (aspectRatio > 1) {
        minWidth = getWidth(minHeight, aspectRatio);
    }
    else if (aspectRatio < 1) {
        minHeight = getHeight(minWidth, aspectRatio);
    }
    const width = Math.min(maxWidth, img.width);
    const height = getHeight(width, aspectRatio);
    return {
        aspectRatio,
        minWidth,
        maxWidth,
        minHeight,
        maxHeight,
        width,
        height,
    };
}
export class LogoSizeType extends AbstractFormType {
    setImageMeta(img, changeWidth = false) {
        const options = getMeta(img);
        const { width, height, } = this.children;
        this.options.aspectRatio = options.aspectRatio;
        width.options.max = options.maxWidth;
        width.options.min = options.minWidth;
        height.options.max = options.maxHeight;
        height.options.min = options.minHeight;
        if (changeWidth) {
            this.children.width.field.setValue(options.width);
        }
        else {
            this.children.width.field.setTouched(true);
        }
    }
    buildFields() {
        return {
            width: new NumberType({
                label: translate('globalTemplateSettings.width'),
                step: 1,
                onChange: (value, t) => {
                    this.children.height.field.updateValue(getHeight(value, this.options.aspectRatio), { ignoreEvents: true });
                },
            }),
            height: new NumberType({
                label: translate('globalTemplateSettings.height'),
                step: 1,
                onChange: (value, t) => {
                    this.children.width.field.updateValue(getWidth(value, this.options.aspectRatio), { ignoreEvents: true });
                },
            }),
        };
    }
    _renderLabel(iProps) {
        const props = {
            ...iProps,
            field: this.field,
            id: `${this.field.id}--label`,
            className: classnames('fw-form__label', iProps?.className),
        };
        return _jsx(FormTheme.Label, { ...props }, props.id);
    }
    _renderWidget(props) {
        return (_jsx(_Fragment, { children: _jsxs(FlexRow, { gap: 15, alignFlexEnd: true, children: [_jsx(FlexCol, { children: this.renderChild('width') }), _jsx(FlexCol, { style: { marginBottom: 30 }, children: _jsx(Icon, { icon: 'link-3' }) }), _jsx(FlexCol, { children: this.renderChild('height') })] }) }));
    }
}
