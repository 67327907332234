import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { AbstractChoiceType, SimpleType } from '../core';
import { Select } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { isString, orFunction } from '@f2w/utils';
export var CountryTypeEnum;
(function (CountryTypeEnum) {
    CountryTypeEnum["AD"] = "AD";
    CountryTypeEnum["AE"] = "AE";
    CountryTypeEnum["AF"] = "AF";
    CountryTypeEnum["AG"] = "AG";
    CountryTypeEnum["AI"] = "AI";
    CountryTypeEnum["AL"] = "AL";
    CountryTypeEnum["AM"] = "AM";
    CountryTypeEnum["AO"] = "AO";
    CountryTypeEnum["AQ"] = "AQ";
    CountryTypeEnum["AR"] = "AR";
    CountryTypeEnum["AS"] = "AS";
    CountryTypeEnum["AT"] = "AT";
    CountryTypeEnum["AU"] = "AU";
    CountryTypeEnum["AW"] = "AW";
    CountryTypeEnum["AX"] = "AX";
    CountryTypeEnum["AZ"] = "AZ";
    CountryTypeEnum["BA"] = "BA";
    CountryTypeEnum["BB"] = "BB";
    CountryTypeEnum["BD"] = "BD";
    CountryTypeEnum["BE"] = "BE";
    CountryTypeEnum["BF"] = "BF";
    CountryTypeEnum["BG"] = "BG";
    CountryTypeEnum["BH"] = "BH";
    CountryTypeEnum["BI"] = "BI";
    CountryTypeEnum["BJ"] = "BJ";
    CountryTypeEnum["BL"] = "BL";
    CountryTypeEnum["BM"] = "BM";
    CountryTypeEnum["BN"] = "BN";
    CountryTypeEnum["BO"] = "BO";
    CountryTypeEnum["BQ"] = "BQ";
    CountryTypeEnum["BR"] = "BR";
    CountryTypeEnum["BS"] = "BS";
    CountryTypeEnum["BT"] = "BT";
    CountryTypeEnum["BV"] = "BV";
    CountryTypeEnum["BW"] = "BW";
    CountryTypeEnum["BY"] = "BY";
    CountryTypeEnum["BZ"] = "BZ";
    CountryTypeEnum["CA"] = "CA";
    CountryTypeEnum["CC"] = "CC";
    CountryTypeEnum["CD"] = "CD";
    CountryTypeEnum["CF"] = "CF";
    CountryTypeEnum["CG"] = "CG";
    CountryTypeEnum["CH"] = "CH";
    CountryTypeEnum["CI"] = "CI";
    CountryTypeEnum["CK"] = "CK";
    CountryTypeEnum["CL"] = "CL";
    CountryTypeEnum["CM"] = "CM";
    CountryTypeEnum["CN"] = "CN";
    CountryTypeEnum["CO"] = "CO";
    CountryTypeEnum["CR"] = "CR";
    CountryTypeEnum["CU"] = "CU";
    CountryTypeEnum["CV"] = "CV";
    CountryTypeEnum["CW"] = "CW";
    CountryTypeEnum["CX"] = "CX";
    CountryTypeEnum["CY"] = "CY";
    CountryTypeEnum["CZ"] = "CZ";
    CountryTypeEnum["DE"] = "DE";
    CountryTypeEnum["DJ"] = "DJ";
    CountryTypeEnum["DK"] = "DK";
    CountryTypeEnum["DM"] = "DM";
    CountryTypeEnum["DO"] = "DO";
    CountryTypeEnum["DZ"] = "DZ";
    CountryTypeEnum["EC"] = "EC";
    CountryTypeEnum["EE"] = "EE";
    CountryTypeEnum["EG"] = "EG";
    CountryTypeEnum["EH"] = "EH";
    CountryTypeEnum["ER"] = "ER";
    CountryTypeEnum["ES"] = "ES";
    CountryTypeEnum["ET"] = "ET";
    CountryTypeEnum["FI"] = "FI";
    CountryTypeEnum["FJ"] = "FJ";
    CountryTypeEnum["FK"] = "FK";
    CountryTypeEnum["FM"] = "FM";
    CountryTypeEnum["FO"] = "FO";
    CountryTypeEnum["FR"] = "FR";
    CountryTypeEnum["GA"] = "GA";
    CountryTypeEnum["GB"] = "GB";
    CountryTypeEnum["GD"] = "GD";
    CountryTypeEnum["GE"] = "GE";
    CountryTypeEnum["GF"] = "GF";
    CountryTypeEnum["GG"] = "GG";
    CountryTypeEnum["GH"] = "GH";
    CountryTypeEnum["GI"] = "GI";
    CountryTypeEnum["GL"] = "GL";
    CountryTypeEnum["GM"] = "GM";
    CountryTypeEnum["GN"] = "GN";
    CountryTypeEnum["GP"] = "GP";
    CountryTypeEnum["GQ"] = "GQ";
    CountryTypeEnum["GR"] = "GR";
    CountryTypeEnum["GS"] = "GS";
    CountryTypeEnum["GT"] = "GT";
    CountryTypeEnum["GU"] = "GU";
    CountryTypeEnum["GW"] = "GW";
    CountryTypeEnum["GY"] = "GY";
    CountryTypeEnum["HK"] = "HK";
    CountryTypeEnum["HM"] = "HM";
    CountryTypeEnum["HN"] = "HN";
    CountryTypeEnum["HR"] = "HR";
    CountryTypeEnum["HT"] = "HT";
    CountryTypeEnum["HU"] = "HU";
    CountryTypeEnum["ID"] = "ID";
    CountryTypeEnum["IE"] = "IE";
    CountryTypeEnum["IL"] = "IL";
    CountryTypeEnum["IM"] = "IM";
    CountryTypeEnum["IN"] = "IN";
    CountryTypeEnum["IO"] = "IO";
    CountryTypeEnum["IQ"] = "IQ";
    CountryTypeEnum["IR"] = "IR";
    CountryTypeEnum["IS"] = "IS";
    CountryTypeEnum["IT"] = "IT";
    CountryTypeEnum["JE"] = "JE";
    CountryTypeEnum["JM"] = "JM";
    CountryTypeEnum["JO"] = "JO";
    CountryTypeEnum["JP"] = "JP";
    CountryTypeEnum["KE"] = "KE";
    CountryTypeEnum["KG"] = "KG";
    CountryTypeEnum["KH"] = "KH";
    CountryTypeEnum["KI"] = "KI";
    CountryTypeEnum["KM"] = "KM";
    CountryTypeEnum["KN"] = "KN";
    CountryTypeEnum["KP"] = "KP";
    CountryTypeEnum["KR"] = "KR";
    CountryTypeEnum["KW"] = "KW";
    CountryTypeEnum["KY"] = "KY";
    CountryTypeEnum["KZ"] = "KZ";
    CountryTypeEnum["LA"] = "LA";
    CountryTypeEnum["LB"] = "LB";
    CountryTypeEnum["LC"] = "LC";
    CountryTypeEnum["LI"] = "LI";
    CountryTypeEnum["LK"] = "LK";
    CountryTypeEnum["LR"] = "LR";
    CountryTypeEnum["LS"] = "LS";
    CountryTypeEnum["LT"] = "LT";
    CountryTypeEnum["LU"] = "LU";
    CountryTypeEnum["LV"] = "LV";
    CountryTypeEnum["LY"] = "LY";
    CountryTypeEnum["MA"] = "MA";
    CountryTypeEnum["MC"] = "MC";
    CountryTypeEnum["MD"] = "MD";
    CountryTypeEnum["ME"] = "ME";
    CountryTypeEnum["MF"] = "MF";
    CountryTypeEnum["MG"] = "MG";
    CountryTypeEnum["MH"] = "MH";
    CountryTypeEnum["MK"] = "MK";
    CountryTypeEnum["ML"] = "ML";
    CountryTypeEnum["MM"] = "MM";
    CountryTypeEnum["MN"] = "MN";
    CountryTypeEnum["MO"] = "MO";
    CountryTypeEnum["MP"] = "MP";
    CountryTypeEnum["MQ"] = "MQ";
    CountryTypeEnum["MR"] = "MR";
    CountryTypeEnum["MS"] = "MS";
    CountryTypeEnum["MT"] = "MT";
    CountryTypeEnum["MU"] = "MU";
    CountryTypeEnum["MV"] = "MV";
    CountryTypeEnum["MW"] = "MW";
    CountryTypeEnum["MX"] = "MX";
    CountryTypeEnum["MY"] = "MY";
    CountryTypeEnum["MZ"] = "MZ";
    CountryTypeEnum["NA"] = "NA";
    CountryTypeEnum["NC"] = "NC";
    CountryTypeEnum["NE"] = "NE";
    CountryTypeEnum["NF"] = "NF";
    CountryTypeEnum["NG"] = "NG";
    CountryTypeEnum["NI"] = "NI";
    CountryTypeEnum["NL"] = "NL";
    CountryTypeEnum["NO"] = "NO";
    CountryTypeEnum["NP"] = "NP";
    CountryTypeEnum["NR"] = "NR";
    CountryTypeEnum["NU"] = "NU";
    CountryTypeEnum["NZ"] = "NZ";
    CountryTypeEnum["OM"] = "OM";
    CountryTypeEnum["PA"] = "PA";
    CountryTypeEnum["PE"] = "PE";
    CountryTypeEnum["PF"] = "PF";
    CountryTypeEnum["PG"] = "PG";
    CountryTypeEnum["PH"] = "PH";
    CountryTypeEnum["PK"] = "PK";
    CountryTypeEnum["PL"] = "PL";
    CountryTypeEnum["PM"] = "PM";
    CountryTypeEnum["PN"] = "PN";
    CountryTypeEnum["PR"] = "PR";
    CountryTypeEnum["PS"] = "PS";
    CountryTypeEnum["PT"] = "PT";
    CountryTypeEnum["PW"] = "PW";
    CountryTypeEnum["PY"] = "PY";
    CountryTypeEnum["QA"] = "QA";
    CountryTypeEnum["RE"] = "RE";
    CountryTypeEnum["RO"] = "RO";
    CountryTypeEnum["RS"] = "RS";
    CountryTypeEnum["RU"] = "RU";
    CountryTypeEnum["RW"] = "RW";
    CountryTypeEnum["SA"] = "SA";
    CountryTypeEnum["SB"] = "SB";
    CountryTypeEnum["SC"] = "SC";
    CountryTypeEnum["SD"] = "SD";
    CountryTypeEnum["SE"] = "SE";
    CountryTypeEnum["SG"] = "SG";
    CountryTypeEnum["SH"] = "SH";
    CountryTypeEnum["SI"] = "SI";
    CountryTypeEnum["SJ"] = "SJ";
    CountryTypeEnum["SK"] = "SK";
    CountryTypeEnum["SL"] = "SL";
    CountryTypeEnum["SM"] = "SM";
    CountryTypeEnum["SN"] = "SN";
    CountryTypeEnum["SO"] = "SO";
    CountryTypeEnum["SR"] = "SR";
    CountryTypeEnum["SS"] = "SS";
    CountryTypeEnum["ST"] = "ST";
    CountryTypeEnum["SV"] = "SV";
    CountryTypeEnum["SX"] = "SX";
    CountryTypeEnum["SY"] = "SY";
    CountryTypeEnum["SZ"] = "SZ";
    CountryTypeEnum["TC"] = "TC";
    CountryTypeEnum["TD"] = "TD";
    CountryTypeEnum["TF"] = "TF";
    CountryTypeEnum["TG"] = "TG";
    CountryTypeEnum["TH"] = "TH";
    CountryTypeEnum["TJ"] = "TJ";
    CountryTypeEnum["TK"] = "TK";
    CountryTypeEnum["TL"] = "TL";
    CountryTypeEnum["TM"] = "TM";
    CountryTypeEnum["TN"] = "TN";
    CountryTypeEnum["TO"] = "TO";
    CountryTypeEnum["TR"] = "TR";
    CountryTypeEnum["TT"] = "TT";
    CountryTypeEnum["TV"] = "TV";
    CountryTypeEnum["TW"] = "TW";
    CountryTypeEnum["TZ"] = "TZ";
    CountryTypeEnum["UA"] = "UA";
    CountryTypeEnum["UG"] = "UG";
    CountryTypeEnum["UM"] = "UM";
    CountryTypeEnum["US"] = "US";
    CountryTypeEnum["UY"] = "UY";
    CountryTypeEnum["UZ"] = "UZ";
    CountryTypeEnum["VA"] = "VA";
    CountryTypeEnum["VC"] = "VC";
    CountryTypeEnum["VE"] = "VE";
    CountryTypeEnum["VG"] = "VG";
    CountryTypeEnum["VI"] = "VI";
    CountryTypeEnum["VN"] = "VN";
    CountryTypeEnum["VU"] = "VU";
    CountryTypeEnum["WF"] = "WF";
    CountryTypeEnum["WS"] = "WS";
    CountryTypeEnum["YE"] = "YE";
    CountryTypeEnum["YT"] = "YT";
    CountryTypeEnum["ZA"] = "ZA";
    CountryTypeEnum["ZM"] = "ZM";
    CountryTypeEnum["ZW"] = "ZW";
})(CountryTypeEnum || (CountryTypeEnum = {}));
export class CountryType extends SimpleType.ExtendString {
    choices = Object
        .keys(CountryTypeEnum)
        .map(key => ({
        value: key,
        label: CountryType.trans(key),
    }))
        .sort((country1, country2) => {
        return country1.label.localeCompare(country2.label.toUpperCase());
    });
    static trans(countryCode) {
        return isString(countryCode) ? translate(`country.${countryCode}`) : '';
    }
    constructor(options, parent) {
        super({
            label: translate('address.country'),
            required: true,
            default: CountryTypeEnum.CH,
            nullable: false,
            ...options,
            constraints: {
                ...options?.constraints,
                oneOf: [
                    Object.values(CountryTypeEnum),
                    null,
                ],
            }
        }, parent);
    }
    _renderWidget(props) {
        const _field = this.field;
        const choices = useMemo(() => {
            if (this.options.allowed) {
                return this.choices.filter(c => this.options.allowed.includes(c.value));
            }
            return this.choices;
        }, []);
        return _jsx(Select, { isSearchable: true, "$variant": _field.showError ? 'error' : 'primary', menuShouldBlockScroll: true, menuPortalTarget: document.body, ...this.options?.getProps?.(), ...AbstractChoiceType.getEventProps(_field), options: choices, placeholder: this.options.placeholder, isDisabled: orFunction(this.options.disabled), isClearable: _field.isNullable, defaultValue: _field.initialValue });
    }
}
