import { jsx as _jsx } from "react/jsx-runtime";
import { useViewType } from "@f2w/view";
import { RoomType } from "Components/ObjectViewModal/forms/RoomType";
import { RoomTemplate } from "Components/ObjectViewModal/components/RoomView/RoomTemplate";
import { translate } from "Services/Translator";
import { GeneralTab } from "Components/ObjectViewModal/components/RoomView/GeneralTab";
import { AssetsTab } from "Components/ObjectViewModal/components/RoomView/AssetsTab";
import { Button } from "Atoms/Button";
export const useRoomView = ({ getRentalObjectRoomDetails, updateRentalObjectRoom, createAssetFromRoomForm }) => useViewType(() => new RoomType(), {
    id: 'room-view',
    defaultState: {
        leftActiveTab: 'general',
    },
    DefaultTemplate: props => _jsx(RoomTemplate, { ...props }),
    load: async (id) => {
        const data = await getRentalObjectRoomDetails(id);
        return {
            ...data,
            general: {
                propertyId: data.propertyId,
                buildingId: data.buildingId,
                rentalObjectId: data.rentalObjectId,
                area: data.area,
                notes: data.notes,
            }
        };
    }
}, () => ({
    general: {
        showHeader: false,
        name: translate('rentalObjectRoom.detailView.generalTab'),
        Content: (props) => _jsx(GeneralTab, { ...props }),
        Form: ({ type }) => type.renderChild('general'),
        save: async (values, view) => {
            return updateRentalObjectRoom(view.data.id, {
                rentalObjectId: values.rentalObjectId,
                area: values.area,
                notes: values.notes
            }).then(response => {
                view.updateData({
                    ...response,
                    area: values.area,
                    notes: values.notes
                });
            });
        }
    },
    assets: {
        name: translate('assets.title'),
        Content: (props) => _jsx(AssetsTab, { ...props }),
        ContentHeader: props => (_jsx(Button.New, { variant: 'outline', onClick: () => createAssetFromRoomForm.openForm() }))
    }
}));
