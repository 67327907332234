import { jsx as _jsx } from "react/jsx-runtime";
import { Select as DropdownSelect } from 'Atoms/Select';
import { translate } from 'Services/Translator';
import { ContractRentModificationFilter } from 'Pages/Contract/enums/ContractRentModificationFilter';
const options = Object.values(ContractRentModificationFilter).map(filter => ({
    value: filter,
    label: translate(`contract.upcomingRentModificationFilter.${filter.toLowerCase()}`)
}));
export function UpcomingRentModificationFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, alignMenu: 'right', options: options, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
}
