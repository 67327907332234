import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { DataTable } from '@f2w/data-table';
import { translate } from 'Services/Translator';
import moment from 'moment';
import { formatMoney } from 'Services/NumberHelper';
import { Div } from 'Atoms/Layout';
export const CreditorAccountingRecordTableModel = DataTable.factory()(() => ({
    tableId: 'CreditorAccountingRecord',
    manualControl: false,
    useSortBy: false,
    usePagination: {
        hideInfo: true
    },
    initialState: {
        pageSize: 5
    },
    columns: {
        bookingDate: {
            Header: translate('creditor.bookingDate'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ? moment(value).format('DD.MM.YYYY') : translate('notAvailable') }))
        },
        label: {
            Header: translate('creditor.label'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ?? translate('notAvailable') }))
        },
        amount: {
            Header: translate('creditor.amount'),
            Cell: ({ value }) => (_jsx(Div, { textNoWrap: true, children: formatMoney(value) }))
        },
        debitAccountLabel: {
            Header: translate('finances.debitAccount'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ?? translate('notAvailable') }))
        }
    }
}));
