import generateRoute from "Services/Router";
import client from "Utils/client";
import { getGlobalContext } from "Services/GlobalContext";
export const getCurrentCustomer = () => {
    const { customer } = getGlobalContext();
    return customer;
};
export const getCurrentUser = () => {
    const { user } = getGlobalContext();
    return user;
};
export const getAccountUsers = () => {
    const route = generateRoute('api.account.users');
    return client
        .get(route)
        .then(response => response.data);
};
export const getUsers = (params) => {
    const route = generateRoute('api.users.list');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export const blockUser = async (id) => {
    return await client.post(generateRoute('api.user.block', { user: id }));
};
export const unBlockUser = async (id) => {
    return await client.post(generateRoute('api.user.unblock', { user: id }));
};
export const getUsersInAllMandates = () => {
    const route = generateRoute('api.users.inAllMandates');
    return client
        .get(route)
        .then(response => response.data);
};
export const addUserToAllMandates = (userIds) => {
    if (Array.isArray(userIds)) {
        userIds = userIds.join(',');
    }
    const route = generateRoute('api.users.addToAllMandates', { users: userIds });
    return client
        .post(route);
};
export const completeUserOnboarding = () => {
    const route = generateRoute('api.userOnboarding.complete');
    return client.post(route);
};
export default {
    getUsersInAllMandates,
    addUserToAllMandates,
    getAccountUsers,
    completeUserOnboarding,
};
