import { useTheme } from "@f2w/theme";
import styled, { css } from "styled-components";
export const Section = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        gap: 24px;
        margin-bottom: 56px;
        overflow-x: auto;
        max-width: 100%;
    `;
});
export const SectionTitle = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
        color: #434954;
        align-items: center;
    `;
});
export const SectionBar = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        justify-content: space-between;
        margin-bottom: ${rem(40)};
    `;
});
