import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useDebitorView } from 'Pages/Debitor/components/DebitorView/hooks/useDebitorView';
import { DetailViewModal } from '@f2w/view';
export const DebitorView = ({ debitorInvoiceId, onClose, ...props }) => {
    const debitorView = useDebitorView(props);
    React.useEffect(() => {
        void debitorView.load(debitorInvoiceId);
    }, [debitorInvoiceId]);
    return _jsx(DetailViewModal, { view: debitorView, title: debitorView.data.label, onClose: onClose });
};
