import { useFormModal } from '@f2w/form';
import { translate } from "Services/Translator";
import { DistributionKeyType } from "../types";
import { DistributionKeyServices } from "Pages/AncillaryExpense";
export const useAncillaryDistributionKeyForm = ({ onSaved, distributionKeyServices = DistributionKeyServices }) => {
    return useFormModal({
        name: 'ancillary-distribution-key-form',
        type: () => new DistributionKeyType({
            distributionKeyServices
        }),
        title: ({ values }) => values.id
            ? translate('ancillaryExpense.distributionKeys.edit')
            : translate('ancillaryExpense.distributionKeys.create'),
        onLoad: async (id) => {
            if (!id) {
                return undefined;
            }
            const data = await distributionKeyServices.getDistributionKey(id);
            const valueIds = {};
            data.values?.forEach(value => {
                valueIds[value.rentalObject.id] = {
                    value: value.value
                };
            });
            return {
                id: data.id,
                propertyId: data.property.id,
                title: data.title,
                keyType: data.keyType,
                valueIds,
                values: data.values || [],
            };
        },
        onSave: (values, a, type) => {
            const upsert = type.getSubmitData();
            if (values.id) {
                return distributionKeyServices.updateDistributionKey(values.id, upsert)
                    .then(() => {
                    onSaved();
                });
            }
            else {
                return distributionKeyServices.createDistributionKey(upsert)
                    .then(onSaved);
            }
        }
    });
};
