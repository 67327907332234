import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tab, Tabs } from 'Atoms/Tabs';
import { Title } from '@f2w/data-table/layout/PageOverviewStyles';
import { EditProfileForm } from 'Pages/Profile/Profile/EditProfileForm';
import { ChangePasswordForm } from 'Pages/Profile/ChangePassword/ChangePasswordForm';
import { TwoFactorAuthSettingsPage } from 'Pages/TwoFactorAuth/TwoFactorAuthManagement/TwoFactorAuthSettingsPage';
const ProfilePage = (props) => {
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: "Profil" }), _jsxs(Tabs, { id: "accounting-tabs", defaultActiveKey: "profile", children: [_jsx(Tab, { className: "mt-4", eventKey: "profile", title: "Profil", children: _jsx(EditProfileForm, {}) }), _jsx(Tab, { className: "mt-4", eventKey: "password", title: "Passwort ändern", children: _jsx(ChangePasswordForm, {}) }), _jsx(Tab, { className: "mt-4", eventKey: "2fa", title: "Zwei-Faktor-Authentifizierung", children: _jsx(TwoFactorAuthSettingsPage, {}) })] })] }));
};
export default ProfilePage;
