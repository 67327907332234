import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTable } from '@f2w/data-table';
import { translate } from 'Services/Translator';
import moment from 'moment';
import { formatMoney, formatPercentage } from 'Services/NumberHelper';
import { Div } from 'Components/Atoms';
import { MortgageType } from 'Components/ObjectViewModal/types';
export const MortgageTableModel = DataTable.factory()(() => ({
    tableId: 'MortgageTable',
    manualControl: false,
    useSortBy: false,
    usePagination: {
        hideInfo: true,
    },
    columns: {
        $actions: { sticky: "right", width: 0 },
        value: {
            Header: translate('mortgage.value'),
            Cell: ({ value }) => (_jsx(Div, { textNoWrap: true, children: value !== undefined ? formatMoney(value) : translate('notAvailable') }))
        },
        pediod: {
            Header: translate('mortgage.startEnd'),
            accessor: 'start',
            Cell: ({ row: { original: { start, end } } }) => (_jsxs(_Fragment, { children: [start ? moment(start).format('DD.MM.YYYY') : '—', " - ", end ? moment(end).format('DD.MM.YYYY') : '—'] }))
        },
        interest: {
            Header: translate('mortgage.interest'),
            accessor: 'start',
            Cell: ({ row: { original: mortgage }, saronInterest }) => (_jsxs(_Fragment, { children: [translate(`mortgage.type.${mortgage.type}`), mortgage.type !== MortgageType.FLOATING && _jsxs(_Fragment, { children: [mortgage.type === MortgageType.FIXED && (mortgage?.interest ? ' (' + mortgage.interest + '%)' : '—'), mortgage.type === MortgageType.SARON && ' (' + formatPercentage((saronInterest + mortgage.extraInterest) / 100, '0.000%') + ')'] })] }))
        },
    }
}));
