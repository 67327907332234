import { translate } from 'Services/Translator';
import { toString } from 'lodash';
import { SimpleValue, SimpleValueType, } from './base';
import { Yup } from '../base';
export class StringValueType extends SimpleValueType {
    __fw_type_name = 'string';
    constructor(props) {
        super(props);
    }
    static define(props) {
        return new StringValueType(props);
    }
    createValue(props) {
        return new SimpleValue(this._type, props);
    }
    get schema() {
        return super.schema;
    }
    _createSchema() {
        return new Yup.StringSchema();
    }
    _parse(value) {
        if (value != null && (value = toString(value)))
            return value;
        if (this.isNullable)
            return null;
        return '';
    }
    _typeCheck(value) {
        if (value instanceof String)
            value = value.valueOf();
        return typeof value === 'string';
    }
    getConstraints(props) {
        const { mutate } = props;
        return {
            ...super.getConstraints(props),
            min(value, message) {
                mutate(s => s.min(value, message ?? translate('form.validation.noLessThan', { length: value })));
                return value;
            },
            max(value, message) {
                mutate(s => s.max(value, message ?? translate('form.validation.noLongerThan', { length: value })));
                return value;
            },
            length(value, message) {
                mutate((s) => s.length(value, message));
                return value;
            },
            matches(value, message) {
                mutate(s => s.matches(value, message));
                return value;
            },
            trim(value, message) {
                mutate(s => s.trim(message));
                return value;
            },
            email(value, message) {
                mutate(s => s.email(message ?? translate('account.invalidEmail')));
                return value;
            },
            url(value, message) {
                mutate(s => s.url(message ?? translate('form.type.url.error')));
                return value;
            },
            uuid(value, message) {
                mutate(s => s.uuid(message));
                return value;
            },
            lowercase(value, message) {
                mutate(s => s.lowercase(message));
                return value;
            },
            uppercase(value, message) {
                mutate(s => s.uppercase(message));
                return value;
            },
        };
    }
}
