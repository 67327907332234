import { Sort } from 'Types/DataTable/Sort';
export const resolveFilters = (_filters) => {
    const filters = {};
    _filters?.forEach(filter => {
        if (Array.isArray(filters?.[filter.id])) {
            filters[filter.id] = [...filters[filter.id], ...[filter.value].flat(2)];
        }
        else {
            filters[filter.id] = filter.value;
        }
    });
    return filters;
};
export const resolveSort = (_sortBy) => {
    const sorts = {};
    _sortBy && _sortBy.forEach(s => {
        sorts[s.id] = Sort.fromBool(s.desc);
    });
    return sorts;
};
