import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { Navigation } from "Pages/Administration/components/Navigation/Navigation";
import UserBarPage from "Pages/User/UserBar/UserBarPage";
import { ActiveView } from "Pages/Administration/enums";
import { CustomersPage } from "Pages/Administration/Customers/CustomersPage";
import { CustomerServices, CustomerSubscriptionServices, HandoverServices } from "./Customers/services";
import { useActiveViewContext } from "Pages/Administration/context/ActiveViewContext";
import { CustomerView } from "Pages/Administration/Customers/CustomerView";
import { CustomerSubscriptionView } from "Pages/Administration/Customers/CustomerSubscriptionView";
import { AuditLogPage } from "Pages/Administration/AuditLog/AuditLogPage";
import { AuditLogServices } from "Pages/Administration/AuditLog/services";
import { FeatureFlagPage } from "Pages/Administration/FeatureFlag/FeatureFlagPage";
import { FeatureFlagServices } from "Pages/Administration/FeatureFlag/services";
import { PatchNotesPage } from "Pages/Administration/PatchNotes/PatchNotesPage";
import { PatchNoteServices } from "Pages/Administration/PatchNotes/services";
import { HandoverCostOverviewPage } from "Pages/Administration/Customers/HandoverCostOverviewPage";
import { MaintenanceServices } from "Pages/Administration/Maintenance/services";
import { MaintenancePage } from "Pages/Administration/Maintenance/MaintenancePage";
export const AdministrationPage = ({ customerServices = CustomerServices, customerSubscriptionServices = CustomerSubscriptionServices, auditLogServices = AuditLogServices, featureFlagServices = FeatureFlagServices, patchNoteServices = PatchNoteServices, handoverServices = HandoverServices, maintenanceServices = MaintenanceServices, }) => {
    const { activeView } = useActiveViewContext();
    const view = React.useMemo(() => {
        switch (activeView) {
            case ActiveView.CUSTOMER_DETAILS: return (_jsx(CustomerView, { getCustomerDetails: customerServices.getCustomerDetails, editCustomer: customerServices.editCustomer, createSubscription: customerSubscriptionServices.createSubscription, getCreateSubscriptionSuggestion: customerSubscriptionServices.getCreateSubscriptionSuggestion, getUpgradeSubscriptionSuggestion: customerSubscriptionServices.getUpgradeSubscriptionSuggestion, getDowngradeSubscriptionSuggestion: customerSubscriptionServices.getDowngradeSubscriptionSuggestion }));
            case ActiveView.CUSTOMER_SUBSCRIPTION_DETAILS: return (_jsx(CustomerSubscriptionView, { getSubscriptionDetails: customerSubscriptionServices.getSubscriptionDetails, updateSubscription: customerSubscriptionServices.updateSubscription, loadSubscription: customerSubscriptionServices.loadSubscription, deleteSubscription: customerSubscriptionServices.deleteSubscription }));
            case ActiveView.CUSTOMERS: return _jsx(CustomersPage, { customerServices: customerServices });
            case ActiveView.AUDIT_LOG: return (_jsx(AuditLogPage, { auditLogServices: auditLogServices, getActiveCustomers: customerServices.getActiveCustomers }));
            case ActiveView.FEATURE_FLAGS: return _jsx(FeatureFlagPage, { featureFlagServices: featureFlagServices });
            case ActiveView.PATCH_NOTES: return _jsx(PatchNotesPage, { patchNoteServices: patchNoteServices });
            case ActiveView.HANDOVER_COST_OVERVIEW: return _jsx(HandoverCostOverviewPage, { handoverServices: handoverServices });
            case ActiveView.MAINTENANCE: return _jsx(MaintenancePage, { maintenanceServices: maintenanceServices });
        }
    }, [activeView]);
    return (_jsxs(FlexRow, { h: '100vh', children: [_jsx(Div, { h: '100vh', children: _jsx(Navigation, {}) }), _jsxs(FlexCol, { w: '100%', className: 'fw-main kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper', children: [_jsx(FlexRow, { justifyEnd: true, alignCenter: true, className: 'kt-header kt-grid__item  kt-header--fixed', children: _jsx(Div, { className: 'kt-header__topbar kt-header__topbar--fixed kt-hidden-tablet-and-mobile', children: _jsx(Div, { className: 'kt-header__topbar-item kt-header__topbar-item--user', children: _jsx(UserBarPage, {}) }) }) }), _jsx(Div, { h: '100%', style: { overflowY: 'auto' }, children: view })] })] }));
};
