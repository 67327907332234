import { AbstractFormType, NumberType } from '@f2w/form';
import { translate } from 'Services/Translator';
export class CalculationWizardEditBasicsType extends AbstractFormType {
    buildFields() {
        return {
            administrativeCost: new NumberType({
                label: translate('ancillaryExpenseCalculation.administrativeCost'),
                required: true,
                constraints: {
                    min: [0, translate('form.validation.positiveValue')]
                },
                getProps: () => ({
                    prefix: '%'
                })
            }),
        };
    }
}
