import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ModalNew } from "Components/Modal";
import { translate } from "Services/Translator";
import TaskForm from "./../TaskForm/TaskForm";
import { createTask, updateTask } from "Services/TaskManager";
import { Button } from "Components/Atoms/Button";
import TaskModalContext from "./../TaskModalContext";
import { getAccountUsers } from "Services/UserServices";
const TaskEditModal = ({ task, onHide }) => {
    const [show, setShow] = useState(true);
    const [users, setUsers] = useState(undefined);
    useEffect(() => {
        getAccountUsers()
            .then((users) => setUsers(users));
    }, []);
    let activeForm = null;
    const setActiveForm = (form) => activeForm = form;
    const onSaveChanges = async () => {
        await activeForm.submitForm();
    };
    const closeModalHandler = () => {
        setShow(false);
        onHide();
    };
    return (_jsx(TaskModalContext.Provider, { value: { setActiveForm }, children: _jsxs(ModalNew, { show: show, onHide: closeModalHandler, size: {
                maxWidth: 'clamp(200px, 80vw, 1000px)',
                maxHeight: 'clamp(400px, 90vh, 800px)',
                height: "96vh"
            }, children: [_jsx(ModalNew.Header, { closeButton: true, children: _jsx(ModalNew.Title, { children: task ? translate('task.action.edit') : translate('task.action.add') }) }), _jsx(ModalNew.Body, { children: users && _jsx(TaskForm, { task: task, users: users, onSubmit: async (task) => {
                            try {
                                const result = task.id ? await updateTask(task) : await createTask(task);
                                closeModalHandler();
                                return result;
                            }
                            catch ({ message }) {
                                alert(message);
                            }
                        } }) }), _jsxs(ModalNew.Footer, { children: [_jsx(Button, { "$color": 'link', onClick: () => closeModalHandler(), children: translate('btn.close') }), _jsx(Button, { "$color": 'primary', type: "submit", onClick: onSaveChanges, children: translate('btn.save') })] })] }) }));
};
export default TaskEditModal;
