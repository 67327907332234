import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { Box, FlexRow } from 'Atoms/Layout';
import { Button, ButtonGroup } from 'Atoms/Button';
import { useDispatcherApi } from 'Components/Dispatcher';
import { TransactionStatusType } from '../../types';
export const BulkActions = ({ updateApi }) => {
    const { modal } = useDispatcherApi();
    const approvePreAssigned = (ids = undefined) => modal.show({
        asyncLoad: async (template) => {
            const transactions = await updateApi.loadInfo({
                status: TransactionStatusType.PRE_ASSIGNED,
                ids,
            });
            return template.confirm({
                message: _jsx(Box, { children: _jsx(FlexRow, { gap: 10, justifyContent: 'center', children: _jsx("span", { children: translate('bankingReconciliationTransactionsTable.confirm.approve', { nb: transactions.length }) }) }) }),
                onConfirm: () => {
                    if (ids) {
                        updateApi.assign(transactions.map(t => t.id));
                    }
                    else {
                        updateApi.approveAllPreAssigned();
                    }
                },
            });
        },
    });
    const ignoreSelected = (ids) => modal.show({
        asyncLoad: async (template) => {
            const transactions = await updateApi.loadInfo({
                status: [TransactionStatusType.PRE_ASSIGNED, TransactionStatusType.PENDING],
                ids: ids,
            });
            return template.confirm({
                message: translate('bankingReconciliationTransactionsTable.confirm.ignore', { nb: transactions.length }),
                onConfirm: () => {
                    updateApi.ignore(transactions.map(t => t.id));
                },
            });
        },
    });
    const unignoreSelected = (ids) => modal.show({
        asyncLoad: async (template) => {
            const transactions = await updateApi.loadInfo({
                status: TransactionStatusType.IGNORED,
                ids: ids,
            });
            return template.confirm({
                message: translate('bankingReconciliationTransactionsTable.confirm.unignore', { nb: transactions.length }),
                onConfirm: () => {
                    updateApi.unignore(transactions.map(t => t.id));
                },
            });
        },
    });
    return (_jsx(FlexRow, { alignItems: "center", gap: 15, children: updateApi.dirtyIds.length
            ? _jsxs(_Fragment, { children: [_jsx("span", { children: translate('bankingReconciliationTransactionsTable.pendingUpdates', { nb: updateApi.dirtyIds.length }) }), _jsxs(ButtonGroup, { children: [_jsx(Button, { color: "success", label: translate('bankingReconciliationTransactionsTable.actions.save'), icon: 'save', onClick: () => updateApi.assign(updateApi.dirtyIds), confirm: {
                                    message: translate('bankingReconciliationTransactionsTable.confirm.saveAllUpdate'),
                                    btnConfirm: translate('Confirm'),
                                } }), _jsx(Button, { outline: true, color: "error", label: translate('bankingReconciliationTransactionsTable.actions.discard'), icon: 'refresh-cw', onClick: () => updateApi.reset() })] })] })
            : updateApi.selectedIds.length
                ? _jsxs(_Fragment, { children: [_jsx("span", { children: translate('bankingReconciliationTransactionsTable.selectedData', { nb: updateApi.selectedIds.length }) }), _jsxs(ButtonGroup, { children: [_jsx(Button, { outline: true, color: 'success', label: translate('bankingReconciliationTransactionsTable.actions.assign'), icon: 'save', onClick: () => approvePreAssigned(updateApi.selectedIds) }), _jsx(Button, { outline: true, color: 'success', label: translate('bankingReconciliationTransactionsTable.actions.ignore'), onClick: () => ignoreSelected(updateApi.selectedIds) }), _jsx(Button, { outline: true, color: 'success', label: translate('bankingReconciliationTransactionsTable.actions.unignore'), onClick: () => unignoreSelected(updateApi.selectedIds) })] })] })
                : _jsx(_Fragment, { children: _jsx(Button, { icon: "save", label: translate('bankingReconciliationTransactionsTable.actions.approveAllPreAssigned'), onClick: () => approvePreAssigned() }) }) }));
};
