import { jsx as _jsx } from "react/jsx-runtime";
import BaseQRCode from "react-qr-code";
import styled, { css } from "styled-components";
import { useTheme } from "@f2w/theme";
const QRCodeWrapper = styled.div(({ size, bgColor, fgColor }) => {
    const { rem } = useTheme();
    const padding = Math.min(50, Math.round(size * 0.05));
    return css `
        width: ${size + padding}px;
        padding: ${padding}px;
        border-radius: ${rem(16)};
        border: 5px solid ${fgColor};
        background: ${bgColor};
    `;
});
const QRCode = styled(BaseQRCode).attrs(({ size, level, fgColor }) => {
    return {
        viewBox: `0 0 ${size} ${size}`,
        size: size,
        level: level || 'H',
        fgColor: fgColor
    };
})(() => {
    return css `
        height: auto;
        max-width: 100%;
        width: 100%;
    `;
});
const WrappedQRCode = ({ value, size = 384, bgColor = "#fff", fgColor: baseFgColor, level }) => {
    const { palette } = useTheme();
    const fgColor = baseFgColor || palette.dark.$900;
    return (_jsx(QRCodeWrapper, { size: size, fgColor: fgColor, bgColor: bgColor, children: _jsx(QRCode, { value: value, size: size, bgColor: bgColor, fgColor: fgColor, level: level }) }));
};
export default WrappedQRCode;
