import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { useUncontrolled } from 'uncontrollable';
import AbstractNav from 'Components/Base/AbstractNav';
import { NavItem, NavItemRoot } from './NavItem';
import { NavLink, NavLinkRoot } from './NavLink';
import styled, { css } from 'styled-components';
import useMergedRefs from '@restart/hooks/useMergedRefs';
const UnstyledNav = forwardRef(({ variant, position, scrollable, fill, style: _style, sticky, justify, as = 'nav', ...uncontrolledProps }, ref) => {
    const navRef = React.useRef();
    const { className, children, activeKey, ...props } = useUncontrolled(uncontrolledProps, { activeKey: 'onSelect' });
    React.useEffect(() => {
        const el = navRef.current;
        setActiveItem(el);
    }, [activeKey]);
    const mergedRef = useMergedRefs(ref, navRef);
    const vertical = ['left', 'right'].includes(position);
    const nowrap = scrollable ?? !vertical;
    const styleProps = {
        variant,
        position,
        vertical,
        scrollable,
    };
    if (sticky) {
        _style = { position: 'sticky', zIndex: 1, [sticky]: 0, ..._style };
    }
    return (_jsx(NavRoot, { className: classNames(className, nowrap && 'fw-scrollable', fill && 'fw-nav--fill', justify && 'fw-nav--justify'), ...styleProps, as: as, style: _style, ref: mergedRef, children: _jsx(AbstractNav, { ...props, activeKey: activeKey, className: classNames('fw-nav', fill && 'fw-nav--fill', justify && 'fw-nav--justify'), children: children }) }));
});
export const NavRoot = styled.div(({ theme: { palette }, theme, position, vertical, variant, }) => {
    return css `
      display: flex;
      ${vertical ? css `
        flex-direction: column;
      ` : css `
      `};

      ${positionStyles2(position, {}, {}, {}, {})};

      .fw-nav {
        flex-grow: 1;
        flex-wrap: nowrap;
        padding: 0;
        margin: 0;
        gap: 4px;
        ${vertical ? css `
          display: flex;
          flex-direction: column;
        ` : css `
          display: inline-flex;
          white-space: nowrap;
          flex-direction: row;
          align-items: stretch;
        `};

        &.fw-nav--fill > :is(${NavLinkRoot}, ${NavItemRoot}) {
          flex: 1 1 auto;
          text-align: center;
        }

        &.fw-nav--justify > :is(${NavLinkRoot}, ${NavItemRoot}) {
          flex-basis: 0;
          flex-grow: 1;
          text-align: center;
        }
      }
    }

    &.fw-scrollable {
      ::-webkit-scrollbar {
        display: none;
      }

      ::-webkit-scrollbar-track {
        display: none;
      }

      ::-webkit-scrollbar-thumb {
        display: none;
      }

      overflow: auto;
      scroll-padding: 0 0 0 0;
      scroll-snap-type: both mandatory;
      position: relative;
      max-height: 100%;
      max-width: 100%;

      .fw-nav {
        position: relative;
        ${vertical ? css `` : css `
          padding-left: var(--fw-nav-offset, 10px);
        `};
      }

      .fw-nav > :is(${NavLinkRoot}, ${NavItemRoot}) {
        scroll-snap-align: start;
        scroll-snap-stop: normal;
      }
    }

    ${createNavVariantCss({ theme, position, variant })}
    `;
});
export const Nav = Object.assign(UnstyledNav, {
    Item: NavItem,
    Link: NavLink,
    Root: NavRoot
});
function setActiveItem(el) {
    const activeChild = el.querySelector('[data-rb-event-key].active');
    if (activeChild) {
        el.scrollLeft = activeChild.offsetLeft;
    }
}
function positionStyles(position, style) {
    return style[position] ?? style.top;
}
function positionStyles2(position, top, right, bottom, left) {
    return { top, right, bottom, left }[position];
}
const createNavVariantCss = ({ theme: { palette }, position, variant, }) => {
    switch (variant) {
        case 'tabs':
            const borderWidth = 2;
            const borderColor = palette.dark.$200;
            return {
                '.fw-nav': {
                    border: `0 solid ${borderColor}`,
                    ...positionStyles(position, {
                        bottom: {
                            borderTopWidth: borderWidth,
                        },
                        left: {
                            borderRightWidth: borderWidth,
                        },
                        top: {
                            borderBottomWidth: borderWidth,
                        },
                        right: {
                            borderLeftWidth: borderWidth,
                        },
                    }),
                },
                [`${NavLinkRoot}`]: {
                    '--border-color': 'transparent',
                    border: `0 solid transparent`,
                    margin: 0,
                    ...positionStyles(position, {
                        bottom: {
                            marginTop: -borderWidth,
                            borderTopWidth: borderWidth,
                        },
                        left: {
                            marginRight: -borderWidth,
                            borderRightWidth: borderWidth,
                        },
                        top: {
                            marginBottom: -borderWidth,
                            borderBottomWidth: borderWidth,
                        },
                        right: {
                            marginLeft: -borderWidth,
                            borderLeftWidth: borderWidth,
                        },
                    }),
                    ...NavCss.hoverFocus({
                        color: palette.primary.$300,
                    }),
                },
                ...NavCss.activeLink({
                    color: palette.primary.$500,
                    borderColor: palette.primary.$500,
                }),
            };
        case 'pills':
            return {
                [`${NavLinkRoot}`]: {
                    borderRadius: 8,
                    ...NavCss.hoverFocus({
                        color: palette.primary.$600,
                        backgroundColor: palette.primary.$50,
                    }),
                    ...NavCss.disabled({
                        opacity: .6,
                    }),
                },
                ...NavCss.activeLink({
                    color: palette.white,
                    backgroundColor: palette.primary.$500,
                }),
            };
    }
    return null;
};
export const isVertical = (position) => ['left', 'right'].includes(position);
const NavCss = {
    item: (styles) => ({
        [`${NavItemRoot}`]: styles,
    }),
    link: (styles) => ({
        [`${NavLinkRoot}`]: styles,
    }),
    hoverFocus: (styles) => ({
        '&:hover, &:focus': styles,
    }),
    disabled: (styles) => ({
        '&.disabled': styles,
    }),
    activeLink: (styles) => ({
        [`${NavLinkRoot}.active, ${NavItemRoot}.show ${NavLinkRoot}`]: styles,
    }),
};
