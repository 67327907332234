import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, Tbody, Td, Th, Thead, Tr } from "Components/Atoms";
import HandoverPersonRoleBadge from "Pages/Handover/components/HandoverView/component/HandoverPersonRoleBadge";
import { formatPersonName } from "Pages/Handover/services/HandoverFormatter";
const PersonsTable = ({ persons }) => {
    return (_jsxs(Table, { colSpan: 3, size: 'sm', hover: false, variant: 'default', style: { width: '100%' }, children: [_jsxs(Thead, { children: [_jsx(Th, { noWrap: true, children: "Name" }), _jsx(Th, { noWrap: true, children: "Rolle" }), _jsx(Th, { noWrap: true, children: "E-Mail-Adresse" })] }), _jsx(Tbody, { children: persons.map(person => {
                    const expandableContent = person.newAddress !== null
                        ? (_jsxs(Tr, { children: [_jsx("b", { children: "New address:" }), " ", person.newAddress.street, ", ", person.newAddress.zip, " ", person.newAddress.city] }))
                        : null;
                    return (_jsxs(Tr.Expandable, { expandableRows: expandableContent, expanded: true, children: [_jsx(Td, { children: _jsx("b", { children: formatPersonName(person) }) }), _jsx(Td, { children: _jsx(HandoverPersonRoleBadge, { role: person.role }) }), _jsx(Td, { children: person.email !== '' && _jsx("a", { href: 'mailto:' + person.email, children: person.email }) })] }, 'persons-row-' + person.id));
                }) })] }));
};
export default PersonsTable;
