import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import { ariaAttr } from '@f2w/theme';
import { CssSelectors, TableTheme } from '../theme';
import classnames from 'classnames';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import { useTargetContainer } from 'Hooks/useTargetContainer';
import { Loader } from 'Atoms/Spinner';
import context from '../theme/TableContext';
import { isObject } from '@f2w/utils';
import { CssClasses } from '../theme/TableTheme';
const TableRoot = forwardRef(({ as: Component = TableTheme.RootSlot, className, isLoading, id, title, layout, dropzone, children, ...iProps }, ref) => {
    const [_themeProps, props] = TableTheme.splitProps(iProps);
    const { Css: _1, ...deps } = _themeProps;
    const theme = useMemo(() => {
        return {
            ..._themeProps,
            ...(isObject(scroll) ? scroll : {}),
        };
    }, Object.values(deps));
    const Css = CssClasses;
    const { height, minHeight, maxHeight } = theme;
    return (_jsx(context.Provider, { value: theme, children: _jsxs(Component, { ...props, id: id && `${id}-wrapper`, ref: ref, table: { ...theme, Css: CssSelectors }, style: { ...props.style }, "aria-busy": ariaAttr(isLoading), className: classnames(Css.table, className, useTargetContainer.resetClassname, {
                [Css.tableScroll]: theme.scroll,
                [Css.fit]: theme.scroll === 'fit',
                [Css.tableHover]: theme.hover,
                [Css.tableBorder]: theme.border,
                [Css.tableRadius]: theme.borderRadius,
                [Css.tableRowRadius]: theme.rowRadius,
                [Css.tableDivider]: theme.rowDivider,
                [Css.tableSpacing]: theme.rowSpacing,
                [Css[`size__${theme.size}`]]: theme.size,
                [Css[`variant--${theme.variant}`]]: theme.variant,
                [Css.tableFixed]: theme.fixed,
            }), children: [isLoading && _jsx(Loader, { floating: true }), dropzone && _jsxs(_Fragment, { children: [dropzone.getInputProps && _jsx("input", { ...dropzone.getInputProps() }), _jsx("div", { className: Css.tableDropzoneContent, children: _jsx("div", { className: Css.tableDropzonePlaceholder }) })] }), _jsx(TableTheme.MainSlot, { className: Css.main, style: { height, minHeight, maxHeight }, children: _jsx(TableTheme.TableSlot, { id: id, title: title, "aria-busy": isLoading, style: { tableLayout: layout, height, minHeight }, children: children }) })] }) }));
});
TableRoot.displayName = "Table";
export const Table = forwardRef(({ dropzone, ...props }, ref) => {
    if (dropzone?.getRootProps) {
        const Css = CssClasses;
        const { ref: newRef, onClick: _1, ...dropzoneProps } = dropzone.getRootProps();
        ref = useMergedRefs(ref, newRef);
        return _jsx(TableRoot, { ref: ref, ...dropzoneProps, ...props, dropzone: dropzone, className: classnames(props.className, Css.tableDropzone, {
                [Css.tableDropzoneActive]: dropzone.isDragActive,
                [Css.tableDropzoneReject]: dropzone.isDragReject,
                [Css.tableDropzoneAccept]: dropzone.isDragAccept,
            }) });
    }
    return _jsx(TableRoot, { ref: ref, ...props });
});
Table.displayName = "Table";
