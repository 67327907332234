import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal } from 'Components/Modal';
import { translate } from 'Services/Translator';
import { ImageCarousel } from 'Components/ImageCarousel';
import { Body, Container, Header, Section } from 'Atoms/Layout/components';
import { Title } from 'Atoms/Text';
const PatchNotesModal = ({ patchNote, onHide }) => {
    const [show, setShow] = useState(true);
    if (!show) {
        return null;
    }
    const onDialogHide = () => {
        if (onHide) {
            onHide();
        }
        setShow(false);
    };
    return (_jsxs(Modal, { show: show, onHide: () => { onDialogHide(); }, size: 'content', children: [_jsx(Modal.Header, { closeButton: true, children: _jsx(Modal.Title, { children: patchNote.title }) }), _jsx(Body, { children: _jsxs(Container, { children: [patchNote.images && patchNote.images.length > 0 && (_jsx(Header, { sticky: true, children: _jsx(ImageCarousel, { position: 'top', images: patchNote.images }) })), _jsx(Header.Sticky, { children: _jsx(Title, { h6: true, as: 'h3', children: patchNote.leadText }) }), patchNote.features && _jsxs(Section, { children: [_jsxs(Title, { body: 600, as: 'h4', children: [translate('patchNotes.newFeatures'), ":"] }), _jsx("div", { dangerouslySetInnerHTML: { __html: patchNote.features } })] }), patchNote.bugFixes && _jsxs(Section, { children: [_jsxs(Title, { body: 600, as: 'h4', children: [translate('patchNotes.fixesAndImprovements'), ":"] }), _jsx("div", { dangerouslySetInnerHTML: { __html: patchNote.bugFixes } })] })] }) }), _jsx(Modal.Footer, { children: _jsx("a", { href: "https://www.fairwalter.com/ressourcen/updates", target: "_blank", children: translate('patchNotes.newsAndUpdates') }) })] }));
};
export default PatchNotesModal;
