import { AbstractFormType, SelectType } from "@f2w/form";
import React from "react";
import { translate } from "Services/Translator";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { getRentalObjectAbstractRooms } from "Components/ObjectViewModal/services/objectViewModalServices";
export class CreateRoomType extends AbstractFormType {
    properties = [];
    buildings = [];
    rentalObjects = [];
    abstractRooms = [];
    _useField(props) {
        super._useField(props);
        const initialLoad = React.useRef(true);
        const [properties, setProperties] = React.useState([]);
        const [buildingMap, setBuildingMap] = React.useState(new Map());
        const [rentalObjectMap, setRentalObjectMap] = React.useState(new Map());
        const [abstractRooms, setAbstractRooms] = React.useState([]);
        this.properties = properties;
        this.buildings = buildingMap.get(this.field.value.propertyId) ?? [];
        this.rentalObjects = rentalObjectMap.get(this.field.value.buildingId) ?? [];
        this.abstractRooms = abstractRooms;
        const portfolio = usePortfolio();
        React.useEffect(() => {
            getRentalObjectAbstractRooms()
                .then(abstractRooms => setAbstractRooms(abstractRooms.map(abstractRoom => ({
                label: abstractRoom.name,
                value: abstractRoom.id
            }))));
            const buildingMap = new Map();
            portfolio.getBuildings().forEach(building => {
                buildingMap.set(building.propertyId, [
                    ...(buildingMap.get(building.propertyId) ?? []),
                    {
                        label: building.name,
                        value: building.id
                    }
                ]);
            });
            const rentalObjectMap = new Map();
            portfolio.getRentalObjects().forEach(rentalObject => {
                rentalObjectMap.set(rentalObject.buildingId, [
                    ...(rentalObjectMap.get(rentalObject.buildingId) ?? []),
                    {
                        label: rentalObject.name,
                        value: rentalObject.id
                    }
                ]);
            });
            setProperties(portfolio.getProperties().map(property => ({
                label: property.name,
                value: property.id
            })));
            setBuildingMap(buildingMap);
            setRentalObjectMap(rentalObjectMap);
        }, []);
        React.useEffect(() => {
            if (!initialLoad.current) {
                this.children.buildingId.field.setValue(undefined, false);
            }
            return () => {
                initialLoad.current = false;
            };
        }, [this.field.value.propertyId]);
        React.useEffect(() => {
            if (!initialLoad.current) {
                this.children.rentalObjectId.field.setValue(undefined, false);
            }
            return () => {
                initialLoad.current = false;
            };
        }, [this.field.value.buildingId]);
    }
    buildFields() {
        return {
            propertyId: new SelectType({
                label: translate('property'),
                searchable: true,
                choiceOptions: () => this.properties
            }),
            buildingId: new SelectType({
                label: translate('building.title'),
                searchable: true,
                choiceOptions: () => this.buildings
            }),
            rentalObjectId: new SelectType({
                label: translate('rentalObject'),
                searchable: true,
                choiceOptions: () => this.rentalObjects
            }),
            abstractRoomId: new SelectType({
                label: translate('rentalObject.abstractRoom'),
                searchable: true,
                choiceOptions: () => this.abstractRooms
            })
        };
    }
}
