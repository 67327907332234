import { generate } from "Services/Router";
import client from "Utils/client";
import { uploadFile, uploadFiles } from "Services/UploadManager";
export var CustomerDocumentServices;
(function (CustomerDocumentServices) {
    CustomerDocumentServices.listCustomerDocuments = async (params) => {
        const route = generate('api.customerDocuments.list');
        return client
            .get(route, { params })
            .then(response => response.data);
    };
    CustomerDocumentServices.updateCustomerDocumentCategory = (customerDocumentId, category) => {
        const route = generate('api.customerDocuments.updateCategory', {
            customerDocument: customerDocumentId
        });
        return client.patch(route, { category });
    };
    CustomerDocumentServices.bulkUpdateCustomerDocumentCategory = (customerDocumentIds, category) => {
        const route = generate('api.customerDocuments.bulkUpdateCategory');
        return client.patch(route, {
            customerDocumentIds,
            category
        });
    };
    CustomerDocumentServices.updateCustomerDocumentProperty = (customerDocumentId, propertyId) => {
        const route = generate('api.customerDocuments.updateProperty', {
            customerDocument: customerDocumentId,
            property: propertyId,
        });
        return client.patch(route);
    };
    CustomerDocumentServices.clearCustomerDocumentProperty = (customerDocumentId) => {
        const route = generate('api.customerDocuments.clearProperty', {
            customerDocument: customerDocumentId
        });
        return client.patch(route);
    };
    CustomerDocumentServices.updateCustomerDocumentBuilding = (customerDocumentId, buildingId) => {
        const route = generate('api.customerDocuments.updateBuilding', {
            customerDocument: customerDocumentId,
            building: buildingId,
        });
        return client.patch(route);
    };
    CustomerDocumentServices.clearCustomerDocumentBuilding = (customerDocumentId) => {
        const route = generate('api.customerDocuments.clearBuilding', {
            customerDocument: customerDocumentId
        });
        return client.patch(route);
    };
    CustomerDocumentServices.updateCustomerDocumentRentalObject = (customerDocumentId, rentalObjectId) => {
        const route = generate('api.customerDocuments.updateRentalObject', {
            customerDocument: customerDocumentId,
            rentalObject: rentalObjectId,
        });
        return client.patch(route);
    };
    CustomerDocumentServices.clearCustomerDocumentRentalObject = (customerDocumentId) => {
        const route = generate('api.customerDocuments.clearRentalObject', {
            customerDocument: customerDocumentId
        });
        return client.patch(route);
    };
    CustomerDocumentServices.updateCustomerDocumentContract = (customerDocumentId, contractId) => {
        const route = generate('api.customerDocuments.updateContract', {
            customerDocument: customerDocumentId,
            contract: contractId,
        });
        return client.patch(route);
    };
    CustomerDocumentServices.clearCustomerDocumentContract = (customerDocumentId) => {
        const route = generate('api.customerDocuments.clearContract', {
            customerDocument: customerDocumentId
        });
        return client.patch(route);
    };
    CustomerDocumentServices.deleteCustomerDocument = (id) => {
        const route = generate('api.customerDocuments.delete', {
            customerDocument: id
        });
        return client.delete(route);
    };
    CustomerDocumentServices.deleteMultipleCustomerDocuments = (ids) => {
        const route = generate('api.customerDocuments.deleteMultiple');
        return client.delete(route, { data: { ids } });
    };
    CustomerDocumentServices.exportCustomerDocuments = (data) => {
        const route = generate('api.customerDocuments.export');
        return client.post(route, data);
    };
    CustomerDocumentServices.exportSelectedCustomerDocuments = (documentIds) => {
        const route = generate('api.customerDocuments.exportSelected');
        return client.post(route, { ids: documentIds });
    };
    CustomerDocumentServices.reAssignMultipleCustomerDocuments = (ids, data) => {
        const route = generate('api.customerDocuments.reAssignMultiple');
        return client.patch(route, {
            customerDocumentIds: ids,
            ...data
        });
    };
    CustomerDocumentServices.updateCustomerDocument = (id, data) => {
        const route = generate('api.customerDocuments.update', {
            customerDocument: id
        });
        return client.put(route, data);
    };
    const UPLOAD_URL = '/_uploader/customer_documents/upload';
    CustomerDocumentServices.uploadCustomerDocument = (file, params) => {
        return uploadFile(UPLOAD_URL, file, params);
    };
    CustomerDocumentServices.uploadCustomerDocuments = (files, params, onFileUploaded = null) => {
        return uploadFiles(UPLOAD_URL, files, params, onFileUploaded);
    };
})(CustomerDocumentServices || (CustomerDocumentServices = {}));
