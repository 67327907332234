import { cleanObj } from '@f2w/utils';
export function useFlexProps(dir, { reverse, inline, ...props }) {
    const style = cleanObj({
        display: inline ? 'inline-flex' : 'flex',
        flexDirection: [dir, reverse ? '-reverse' : ''].join(''),
    });
    return {
        ...props,
        style: {
            ...props.style,
            ...style,
        },
    };
}
