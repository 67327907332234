import React from 'react';
import * as rt from 'react-table';
import { useMountedLayoutEffect } from 'react-table';
import { resolveFilters } from '@f2w/data-table/table-types';
import { useRowSelectHook } from './useRowSelectHook';
const actions = {
    selectAllRows: 'selectAllRows',
    deselectAllRows: 'deselectAllRows',
};
export const useRowSelect = {
    initOptions: (options, table) => ({
        autoResetSelectedRows: false,
    }),
    createOptions: ({ getAllIds, autoResetSelectedRows }) => ({
        autoResetSelectedRows: getAllIds ? false : autoResetSelectedRows,
    }),
    hook: hooks => {
        useRowSelectHook(hooks);
        hooks.stateReducers.push((state, action, previousState, instance) => {
            if (action.type === actions.selectAllRows) {
                const selectedRowIds = Object.create(null);
                for (const rowId of action.rowIds) {
                    selectedRowIds[rowId] = true;
                }
                return {
                    ...state,
                    selectedRowIds,
                };
            }
            if (action.type === actions.deselectAllRows) {
                const selectedRowIds = Object.create(null);
                return {
                    ...state,
                    selectedRowIds,
                };
            }
        });
        hooks.useInstance.push((instance) => {
            const { dispatch, getAllIds, manualControl, dataResponse, toggleAllRowsSelected, isAllRowsSelected: _isAllRowsSelected, state: { selectedRowIds, filters, globalFilter, }, } = instance;
            const selectAllRows = React.useCallback(() => {
                if (manualControl && getAllIds) {
                    instance.setLoading(true);
                    getAllIds({
                        query: globalFilter,
                        filters: resolveFilters(filters)
                    }, instance)
                        .then(rowIds => dispatch({ type: actions.selectAllRows, rowIds }))
                        .finally(() => {
                        instance.setLoading(false);
                    });
                }
                else {
                    toggleAllRowsSelected(true);
                }
            }, [dispatch, getAllIds, manualControl, globalFilter, filters]);
            const deselectAllRows = React.useCallback(() => dispatch({ type: actions.deselectAllRows }), [dispatch]);
            const isAllRowsSelected = manualControl
                ? dataResponse.totalElements === Object.keys(selectedRowIds).length
                : _isAllRowsSelected;
            useMountedLayoutEffect(() => {
                dispatch({ type: rt.actions.resetSelectedRows });
            }, [dispatch, filters, globalFilter]);
            Object.assign(instance, {
                selectAllRows,
                deselectAllRows,
                isAllRowsSelected,
            });
        });
    },
};
