import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled, { css } from "styled-components";
import { useTheme } from "@f2w/theme";
import { Button } from "Components/Atoms/Button";
import { ActionStep, ActionStepList } from "Pages/GettingStartedPage/components/ActionStep";
import { translate } from "Services/Translator";
import axios from "axios";
import generateRoute from "Services/Router";
const SectionWrapper = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        margin-top: ${rem(40)};
        border: 2px solid ${palette.dark.$50};
        border-radius: ${rem(16)};
        overflow: hidden;
        @media (min-width: 1280px) {
          display: flex;
        }
    `;
});
export const SectionPrimary = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        flex: 1.5;
        gap: ${rem(24)};
        padding:${rem(20)};
        gap: ${rem(19)};
        @media (min-width: 1280px) {
          display: flex;
          padding:${rem(40)};
        }
    `;
});
export const SectionPrimaryInfo = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        flex: 1;
        @media (max-width: 1280px) {
          margin-bottom: ${rem(30)};
        }
    `;
});
export const SectionSecondary = styled.div(({ isEmpty }) => {
    const { rem, palette } = useTheme();
    return css `
        flex: 1;
        padding: ${rem(20)};
        gap: ${rem(40)};
        background-color: ${isEmpty ? 'transparent' : palette.dark.$50};
        @media (min-width: 1280px) {
          display: flex;
          padding: ${rem(40)};
        }

    `;
});
export const SectionVideo = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        flex: 1;
        max-width: 340px;
        @media (max-width: 1280px) {
          margin-bottom: ${rem(30)};
        }
    `;
});
export const SectionDocs = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
      flex: 1
    `;
});
export const SectionPrimaryTitle = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        color: ${palette.dark.$500};
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.02em;
        margin-bottom: ${rem(16)};
    `;
});
export const SectionText = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        color: ${palette.dark.$900};
        font-size: 14px;
        line-height: 150%;
        margin-bottom: ${rem(24)};
        padding-right: ${rem(5)};
    `;
});
export const SectionSecondaryTitle = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        font-weight: 600;
        color: ${palette.dark.$900};
        margin-bottom: ${rem(12)};
    `;
});
export const YouTubePlayer = styled(({ id, playlistId, className }) => {
    const url = id ? `//www.youtube.com/embed/${id}` : `//www.youtube.com/embed/videoseries?list=${playlistId}`;
    return (_jsx("div", { className: className, children: _jsx("iframe", { width: "560", height: "315", src: url, title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }) }));
})(() => {
    const { rem, palette } = useTheme();
    return css `
        position: relative;
        overflow: hidden;
          border-radius: ${rem(16)};
          aspect-ratio: 3 / 2;

        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
    `;
});
export const LinkList = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `

    `;
});
export const Link = styled.a(() => {
    const { rem, palette } = useTheme();
    return css `
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        margin-bottom: ${rem(8)};
        ${palette.primary.$500};
    `;
});
const calcStepsStates = (steps, tasksCompletion) => {
    let hasActiveStep = false;
    return steps.map((step) => {
        const task = tasksCompletion.filter((task) => task.task === step.property).pop() ?? null;
        const completed = task?.completed ?? false;
        let state;
        if (completed) {
            state = "completed";
        }
        else {
            state = hasActiveStep ? "pending" : "active";
            hasActiveStep = true;
        }
        return state;
    });
};
export const GettingStartedSection = ({ section, tasksCompletion }) => {
    const stepsStates = calcStepsStates(section.steps, tasksCompletion);
    const activeStepIndex = stepsStates.findIndex(state => state === "active");
    const activeStep = activeStepIndex !== null ? section.steps[activeStepIndex] : null;
    const hasSecondarySection = section.video || section.docs;
    const activeStepTaskCompletion = activeStep
        ? tasksCompletion.filter(v => v.task === activeStep.property).pop()
        : null;
    return (_jsxs(SectionWrapper, { children: [_jsxs(SectionPrimary, { children: [_jsxs(SectionPrimaryInfo, { children: [_jsx(SectionPrimaryTitle, { children: section.title }), _jsx(SectionText, { children: section.description }), activeStep &&
                                _jsx(Button, { href: activeStep.url, target: '_blank', onClick: () => {
                                        axios.post(generateRoute('api.userOnboarding.startTask', { task: activeStepTaskCompletion.task }));
                                    }, children: activeStep['buttonTitle'] || activeStep.title })] }), _jsx(ActionStepList, { children: section.steps.map((step, index) => {
                            const taskCompletion = tasksCompletion.filter(v => v.task === step.property).pop();
                            return (_jsx(ActionStep, { stepNumber: index + 1, state: stepsStates[index], url: step.url, taskCompletion: taskCompletion, children: step.title }, index));
                        }) })] }), _jsx(SectionSecondary, { isEmpty: !hasSecondarySection, children: hasSecondarySection && _jsxs(_Fragment, { children: [section.video && _jsxs(SectionVideo, { children: [_jsx(SectionSecondaryTitle, { children: section.video.title }), _jsx(YouTubePlayer, { ...section.video })] }), _jsxs(SectionDocs, { children: [_jsx(SectionSecondaryTitle, { children: translate("start.documentation") }), _jsx(LinkList, { children: section.docs.map((doc, index) => (_jsx(Link, { href: doc.url, target: '_blank', children: doc.title }, index))) })] })] }) })] }));
};
