import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { toPx, UseStyled } from '@f2w/theme';
import { ButtonGroup } from 'Atoms/ButtonGroup';
import { Carousel, FullscreenTrigger } from 'Components/Carousel';
import { Modal as ModalTmp, useModalCloseHandler } from 'Components/Modal';
import { tryFunction, useAutoFocus } from '@f2w/utils';
import { Button } from 'Atoms/Button';
import { trans } from 'Services/App';
import { CrossIcon } from 'Components/App/Icons';
import classnames from 'classnames';
export const ImageCarousel = (iProps) => {
    const { images, activeIndex, onSelect, renderActions: __renderActions, fit, position, height = 267, noPreview, ...rest } = iProps;
    const [fullScreen, setFullScreen] = useState(false);
    const renderActions = (index, props) => {
        if (!images[index]) {
            return _jsx(_Fragment, {});
        }
        return (_jsxs(ButtonGroup, { size: 'sm', variant: 'outline', children: [_jsx(Button, { href: images[index].path, title: trans('btn.download'), download: images[index].name ?? '', icon: 'download' }), tryFunction(__renderActions, [images[index], props])] }));
    };
    const itemProps = useMemo(() => ({
        fit,
        position,
        height,
    }), [fit, position, height]);
    const children = useMemo(() => images.map((image, key) => (_jsxs(Carousel.Item, { children: [!noPreview && _jsx(FullscreenTrigger, { onClick: () => setFullScreen(p => !p) }), _jsx(Media, { ...itemProps, children: _jsx("img", { src: image.path, alt: image.name ?? '', height: height }) })] }, key))), [height, itemProps, images?.length, images]);
    const rootProps = {
        ...rest,
        onSelect,
        activeIndex,
        renderActions,
    };
    const autoFocusRef = useAutoFocus();
    return (_jsxs(_Fragment, { children: [_jsx(Carousel, { as: Root, ...rootProps, children: children }), _jsxs(ModalTmp, { fullScreen: true, dialogAs: FullscreenTheme, show: fullScreen, onHide: () => setFullScreen(false), autoFocus: false, children: [_jsx(CloseModal, {}), _jsx(Carousel, { as: Root, ...rootProps, defaultActiveIndex: activeIndex, ref: autoFocusRef, tabIndex: -1, autoFocus: true, children: images.map((image, key) => (_jsx(Carousel.Item, { children: _jsx(Media2, { ...itemProps, children: _jsx("img", { src: image.path, alt: image.name ?? '' }) }) }, key))) })] })] }));
};
const FullscreenTheme = styled.div ``;
const CloseModal = UseStyled.Fn((props) => {
    const handleClick = useModalCloseHandler();
    return _jsx("button", { type: "button", onClick: handleClick, className: classnames('close', props.className), "data-dismiss": "modal", "aria-label": 'Close', children: _jsx(CrossIcon, { size: 40 }) });
}, css `
        &&& {
          color: white;
          position: absolute;
          display: block;
          right: 20px;
          top: 20px;
          z-index: 200;
          pointer-events: auto;
        }
    `);
export const Media2 = styled.div `
  overflow: hidden;
  padding: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`;
export const Media = styled.div `
  overflow: hidden;
  padding: ${p => (p.fit === 'contain' ? '1px' : 0)};
  pointer-events: none;
  border-radius: var(--fw-dropzone-area-border-radius);

  img {
    width: 100%;
    height: ${p => toPx(p.height) || 'auto'};
    object-fit: ${p => p.fit || 'cover'};
    object-position: ${p => p.position || undefined};
  }
`;
const Root = styled.div `
  && {
    --fw-carousel-ico--opacity: 0;
    &:is(:focus, :focus-within) {
      outline: none;
      border: none;
    }

    &:is(:hover, :focus, :focus-within) {
      --fw-carousel-ico--opacity: .7;
    }
  }
`;
