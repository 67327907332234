import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import { FlexRow, Text } from 'Components/Atoms';
import { DataTable } from '@f2w/data-table';
import moment from 'moment';
import { formatAmount } from 'Services/NumberHelper';
import { ContractProgress } from 'Pages/AncillaryExpense/ContractProgress';
const BaseTable = DataTable.factory()(() => ({
    tableId: 'CalculationDistributionsFixed',
    manualControl: false,
    showEmpty: true,
    usePagination: {},
    initialState: {
        pageSize: 50
    },
    useGlobalFilter: {},
    columns: {
        grp: {
            Header: '',
            minWidth: 80,
            maxWidth: 100,
            accessor: a => a.activeContractStartDate,
            Cell: ({ row: { original: { activeContractStartDate, activeContractEndDate, contract } }, overviewData }) => {
                if (!activeContractStartDate) {
                    return translate('notAvailable');
                }
                return _jsx(ContractProgress, { calculationFrom: overviewData.periodFrom, calculationTo: overviewData.periodTo, isVacancy: contract === null, contractFrom: activeContractStartDate, contractTo: activeContractEndDate });
            }
        },
        rentalObject: {
            Header: translate('rentalObject'),
            useSortBy: {},
            accessor: d => d.rentalObject.name,
            Cell: ({ value }) => _jsx(Text, { nowrap: true, value: value }),
        },
        rentalObjectType: {
            Header: translate('baseData.rentalObject.type'),
            accessor: v => v.rentalObjectType
                ? translate(`rentalObjectType.${v.rentalObjectType}`)
                : undefined,
            Cell: ({ value }) => _jsx(_Fragment, { children: value }),
            useSortBy: {}
        },
        tenants: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.tenants'),
            useSortBy: {},
            accessor: d => {
                if (d.tenants.length === 0) {
                    return translate('rentalObject.state.vacancy');
                }
                return d.tenants?.map(v => v.name).join(', ');
            },
        },
        activeContractStartDate: {
            Header: translate('contract.details.period'),
            useSortBy: {},
            Cell: ({ row: { original: { activeContractStartDate, activeContractEndDate } } }) => {
                if (!activeContractStartDate) {
                    return translate('notAvailable');
                }
                return moment(activeContractStartDate).format('DD.MM.YYYY') + ' – '
                    + (activeContractEndDate ? moment(activeContractEndDate).format('DD.MM.YYYY') : '∞');
            }
        },
        distributionKeyValue: {
            Header: translate('ancillaryExpenseDistributionKey'),
            Cell: ({ value }) => value !== undefined && value !== null ? formatAmount(value) : translate('notAvailable'),
        },
        duration: {
            Header: translate('ancillaryExpenseCalculation.duration'),
            Cell: ({ row: { original: { coveredDays, totalDays } } }) => coveredDays && totalDays
                ? _jsx(Text, { nowrap: true, value: `${coveredDays} / ${totalDays}` })
                : translate('notAvailable')
        },
        heatingShare: {
            Header: translate('ancillaryExpenseCalculation.heatingShare'),
            Cell: ({ row: { original: { heatingShare, coveredDays, totalDays } }, overviewData: { nbPeriodDays, nbNominalDays } }) => coveredDays && totalDays
                ? _jsx(Text, { nowrap: true, value: `${formatAmount(heatingShare / 100, '0.00[00]')} / ${formatAmount(nbPeriodDays / nbNominalDays, '0.00[00]')}` })
                : translate('notAvailable')
        },
        totalSharePercent: {
            Header: translate('ancillaryExpenseCalculation.totalSharePercent'),
            useSortBy: {},
            Cell: ({ value }) => value !== undefined && value !== null ? `${formatAmount(value)}%` : translate('notAvailable'),
        },
        amount: {
            Header: translate('amount'),
            useSortBy: {},
            Cell: ({ value }) => value !== undefined && value !== null
                ? _jsx(FlexRow, { justifyFlexEnd: true, children: formatAmount(value) })
                : translate('notAvailable'),
        },
    },
}));
export const CalculationDistributionFixedTable = Object.assign(BaseTable, {
    use: ({ data, distributionAccount, overviewData, loadData, updateDistributionKey, updateHeatingDaysShare, type }) => {
        const _updateDistributionKey = React.useCallback((selectedDistributionId) => {
            table.setLoading(true);
            return updateDistributionKey(selectedDistributionId, type)
                .catch(() => table.dispatcher.toast.error({
                message: translate('table.errorUpdatingDistributionKey'),
            }))
                .then(() => _loadData());
        }, [updateDistributionKey, type]);
        const _updateHeatingDaysShare = React.useCallback((heatingDaysShare) => {
            table.setLoading(true);
            return updateHeatingDaysShare(heatingDaysShare, type)
                .catch(() => table.dispatcher.toast.error({
                message: translate('table.errorUpdatingDistributionKey'),
            }))
                .then(() => _loadData());
        }, [updateHeatingDaysShare, type]);
        const _loadData = React.useCallback(() => {
            table.setLoading(true);
            return loadData()
                .catch(() => table.dispatcher.toast.error({
                message: translate('table.errorLoadingData'),
            }))
                .finally(() => table.setLoading(false));
        }, [loadData]);
        const table = BaseTable.useCreateTable({
            data: data,
            distributionAccount,
            overviewData,
            refresh: () => _loadData(),
            updateDistributionKey: _updateDistributionKey,
            updateHeatingDaysShare: _updateHeatingDaysShare,
        });
        React.useEffect(() => {
            table.setHiddenColumns(distributionAccount?.isHeatingDaysShare ? ['duration'] : ['heatingShare']);
        }, [distributionAccount?.isHeatingDaysShare]);
        return table;
    },
});
