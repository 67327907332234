import { jsx as _jsx } from "react/jsx-runtime";
import { Select as DropdownSelect } from 'Atoms/Select';
import { translate } from "Services/Translator";
export function DebitorInvoiceOverdueFilter({ column: { filterValue, setFilter }, }) {
    const options = [
        { value: '0', label: translate('invoice.state.all') },
        { value: '1', label: translate('debitor.overdue') },
    ];
    return (_jsx(DropdownSelect, { value: filterValue || '0', isSearchable: true, useMaxContentWidth: true, options: options, onChange: (option) => {
            setFilter(option ? option.value : undefined);
        } }));
}
