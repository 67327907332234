import { KeyOrChild } from './BaseState';
import { forEachObject, inObject, isFunction, mapObject, objectKeys, } from '@f2w/utils';
export class StateDescriptors {
    meta = Object.create(null);
    data = Object.create(null);
    constructor() {
    }
    static create() {
        return new StateDescriptors();
    }
    _assignDescriptor(target, key, descriptor) {
        const prev = {
            configurable: false,
            enumerable: true,
            ...target[key],
        };
        const desc = isFunction(descriptor) ? descriptor(prev) : { ...prev, ...descriptor };
        Object.assign(target, { [key]: desc });
        return this;
    }
    addMeta(name, descriptor) {
        return this._assignDescriptor(this.meta, name, descriptor);
    }
    addData(name, descriptor) {
        return this._assignDescriptor(this.data, name, descriptor);
    }
    defineMeta(target = Object.create(null)) {
        return Object.defineProperties(target, this.meta);
    }
    defineData(target = Object.create(null)) {
        return Object.defineProperties(target, { ...this.meta, ...this.data });
    }
}
export class StateBuilder {
    config = Object.create(null);
    constructor() {
    }
    static create() {
        return new StateBuilder();
    }
    add(name, descriptor) {
        return this;
    }
}
export class IndexManager {
    options;
    data = Object.create(null);
    constructor(options, config) {
        this.options = options;
        this.extend(config);
    }
    static create(config, options) {
        return new IndexManager(options ?? {}, config);
    }
    updateOptions(options) {
        Object.assign(this.options, options);
        return this;
    }
    add(name, config) {
        const map = this.data;
        if (!map[name])
            map[name] = new IndexManager.Index(name, config, this.options);
        else {
            map[name].extend((prev) => ({
                ...prev,
                ...config,
            }));
        }
        return this;
    }
    remove(name) {
        delete this.data[name];
        return this;
    }
    extend(configMap) {
        forEachObject(configMap, (k, config) => {
            this.add(k, config);
        });
        return this;
    }
    forEach(cb) {
        Object.keys(this.data).forEach(key => {
            cb(this.data[key], key);
        });
    }
    map(cb) {
        return mapObject(this.data, (key, index) => {
            return cb(index, key);
        });
    }
    update(cb, keys) {
        const updatedKeys = (keys ?? objectKeys(this.data))
            .filter((k) => (this.data[k] && cb(this.data[k], k)));
        return updatedKeys.length > 0 ? updatedKeys : null;
    }
}
(function (IndexManager) {
    class Index {
        name;
        globalConfig;
        config;
        _set = new Set();
        constructor(name, config, globalConfig) {
            this.name = name;
            this.globalConfig = globalConfig;
            this.config = {
                name,
                ...config,
            };
        }
        extend(config) {
            Object.assign(this.config, config(this.config));
            return this;
        }
        get size() {
            return this._set.size;
        }
        has(key) {
            return this._set.has(KeyOrChild.key(key));
        }
        values() {
            return [...this._set.values()];
        }
        get() {
            return this.config.getValue(this._set);
        }
        add(key, ignoreUpdate) {
            key = KeyOrChild.key(key);
            if (this._set.has(key))
                return false;
            this._set.add(key);
            ignoreUpdate || this._handleUpdate([key], true);
            return true;
        }
        remove(key, ignoreUpdate) {
            key = KeyOrChild.key(key);
            if (!this._set.delete(key))
                return false;
            ignoreUpdate || this._handleUpdate([key], false);
            return true;
        }
        _update(child) {
            let add;
            if (this.config.getStatus) {
                add = !!this.config.getStatus(child);
            }
            else if (inObject(child['_state'].meta, this.name)) {
                add = !!child['_state'].meta[this.name];
            }
            return add == null ? false : this.update(child.key, add);
        }
        update(key, add, ignoreUpdate) {
            return (add ? this.add(key, ignoreUpdate) : this.remove(key, ignoreUpdate));
        }
        updateAll(keys, add, ignoreUpdate) {
            const updatedKeys = keys
                .map(k => KeyOrChild.key(k))
                .filter(k => this.update(k, add, true));
            if (updatedKeys.length && !ignoreUpdate)
                this._handleUpdate(updatedKeys, add);
            return updatedKeys.length > 0;
        }
        removeAll(keys, ignoreUpdate) {
            return this.updateAll(keys, false, ignoreUpdate);
        }
        toggle(key) {
            return this.update(key, !this.has(key));
        }
        clear(ignoreUpdate) {
            const updatedKeys = this.values();
            this._set.clear();
            if (!ignoreUpdate && updatedKeys.length)
                this._handleUpdate(updatedKeys, false);
            return updatedKeys.length > 0;
        }
        forEach(cb) {
            this._set.forEach(cb);
        }
        map(cb) {
            const items = [];
            this._set.forEach((key, index) => {
                const resolvedChild = cb(key, index);
                resolvedChild && items.push(resolvedChild);
            });
            return items;
        }
        _handleUpdate(keys, add) {
            const event = {
                propName: this.name,
                keys,
                add,
                index: this
            };
            if (add) {
                this.config.onAdd?.(keys, this);
                this.globalConfig.onAdd?.(event);
            }
            else {
                this.config.onRemove?.(keys, this);
                this.globalConfig.onRemove?.(event);
            }
            this.config.onChange?.(keys, add, this);
            this.globalConfig.onChange?.(event);
        }
    }
    IndexManager.Index = Index;
})(IndexManager || (IndexManager = {}));
export class GetterDescriptors {
    configMap;
    constructor(configMap) {
        this.configMap = { ...configMap };
    }
    define(configMap) {
        Object.assign(this.configMap, configMap);
        return this;
    }
    add(name, config) {
        this.configMap[name] = config;
        return this;
    }
    defineGetters(child) {
        forEachObject(this.configMap, (key, config) => {
            const props = {
                configurable: true,
                enumerable: true,
                get: () => config.getValue(child),
            };
            if (config.set) {
                props.set = (value) => {
                    config.set(child, value);
                };
            }
            Object.defineProperty(config['data'][key], child.key, props);
        });
    }
    removeGetters(id) {
        forEachObject(this.configMap, (key, config) => {
            delete config['data'][id];
        });
    }
}
export class GetterDescriptor {
    name;
    globalConfig;
    data = Object.create(null);
    config;
    constructor(name, config, globalConfig) {
        this.name = name;
        this.globalConfig = globalConfig;
        this.config = {
            name,
            ...config,
        };
    }
    static fromConfig(config) {
        const map = Object.create(null);
        forEachObject(config, (key, config) => {
            map[key] = new GetterDescriptor(key, {
                ...config,
            });
        });
        return map;
    }
    add(child) {
        const config = this.config;
        let set;
        if (config.set)
            set = (value) => {
                config.set(child, value);
            };
        Object.defineProperty(this.data, child.key, {
            configurable: true,
            enumerable: true,
            get: () => config.getValue(child),
            set,
        });
    }
    remove(keyOrChild) {
        const key = KeyOrChild.key(keyOrChild);
        delete this.data[key];
    }
}
