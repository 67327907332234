import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Dropdown, DropdownDivider, DropdownHeader, DropdownItem, DropdownItemText, DropdownMenu, DropdownToggle, } from 'Atoms/Dropdown';
import { useEventCallback } from '@restart/hooks';
import { addClass, closest, removeClass } from 'dom-helpers';
import { renderChildren, isFunction, isObject } from '@f2w/utils';
import { useTableTheme } from 'Atoms/Table';
import { Button } from 'Atoms/Button';
import { useId } from 'Hooks/useId';
export const ActionDropdownComponents = {
    Link: DropdownItem,
    Divider: DropdownDivider,
    Header: DropdownHeader,
    Text: DropdownItemText,
    Menu: DropdownMenu,
    Toggle: DropdownToggle,
};
function renderAction(actionProps, props) {
    if (React.isValidElement(actionProps)) {
        return React.cloneElement(actionProps, props);
    }
    if (isFunction(actionProps)) {
        return React.createElement(actionProps, props);
    }
    if (isObject(actionProps)) {
        return _jsx(Button, { variant: 'linkFill', ...props, ...actionProps });
    }
}
export const ActionsDropdown = Object.assign(function ActionsDropdown({ label, dropdownActions, children = dropdownActions, id, buttonProps, quickActions, ...props }) {
    const ref = React.useRef();
    const tableTheme = useTableTheme();
    id = id || useId();
    id = `fw-actions-dropdown-${id}`;
    const onToggle = tableTheme?.Css && useEventCallback((visible) => {
        const cellSelector = ':is(td, th)';
        const scrollSelector = `.${tableTheme.Css['table--scroll']}`;
        const activeDropdownClass = tableTheme.Css.activeDropdown;
        const cellEl = closest(ref.current, cellSelector);
        if (cellEl) {
            visible ? addClass(cellEl, activeDropdownClass) : removeClass(cellEl, activeDropdownClass);
            const scrollEl = closest(ref.current, scrollSelector);
            if (scrollEl) {
                visible ? addClass(scrollEl, activeDropdownClass) : removeClass(scrollEl, activeDropdownClass);
            }
        }
    });
    const menuItems = filterChildren(children);
    const menuButtons = useMemo(() => {
        const actions = (quickActions ?? [])
            .map((actionProps, i) => renderAction(actionProps, { key: `quick-action-${i}` }))
            .filter(p => p);
        return actions.length ? actions : null;
    }, [quickActions]);
    return _jsxs(_Fragment, { children: [menuItems && _jsxs(Dropdown, { variant: 'dark', drop: 'right', ...props, className: 'fw-actions-dropdown', "data-actions": "", ref: ref, container: true, onToggle: onToggle, children: [menuItems && _jsx(Dropdown.Toggle, { nocaret: true, variant: 'linkFill', color: 'neutral', rounded: !label, label: label, dark: true, split: true, id: `${id}__toggle`, role: "combobox", "aria-controls": id, "$size": 'md', icon: 'more-vertical', ...buttonProps }), menuItems && _jsx(Dropdown.Menu, { id: id, role: "listbox", children: menuItems })] }), menuButtons] });
}, ActionDropdownComponents);
function filterChildren(children) {
    let result;
    if (isFunction(children)) {
        children = children(ActionDropdownComponents);
    }
    if (children) {
        result = renderChildren(children, (child, { first, last, displayName }) => {
            if ((first || last) && ActionDropdownComponents.Divider.displayName === displayName) {
                return null;
            }
            return child;
        })?.filter?.(v => !!v);
    }
    return result?.length ? result : undefined;
}
