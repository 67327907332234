import { getGlobalContext } from 'Services/GlobalContext';
import { isStorybookEnv } from '@f2w/utils';
const { roles = [], user } = getGlobalContext();
export function hasRole(role) {
    return roles.includes(role) || isStorybookEnv();
}
export function isAccountOwner() {
    return roles.includes('ROLE_OWNER');
}
export function onboardingCompleted() {
    return user?.onboardingCompleted;
}
