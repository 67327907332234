import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import React from 'react';
import { translate } from 'Services/Translator';
import { formatMoney } from 'Services/NumberHelper';
import { Button, ButtonToolbar } from 'Atoms/index';
import { useModalDispatcherConfirm, useToastDispatcherApi } from 'Components/Dispatcher';
import moment from 'moment';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { CreditorInvoiceState } from 'Pages/Creditor/components/CreditorInvoiceState';
const BaseTable = Table.factory()(() => ({
    tableId: 'BankFiles',
    manualControl: true,
    BulkActions: ({ selectedFlatRows, refresh, downloadSelectedBankFiles, downloadNewBankFiles, markBankFilesAsPaid }) => {
        const ids = selectedFlatRows.map(row => Number(row.id));
        return (_jsxs(ButtonToolbar, { size: 'md', color: 'dark', variant: 'outlineFill', children: [_jsx(Button, { onClick: () => downloadSelectedBankFiles(ids, refresh), disabled: ids.length === 0, title: translate('creditor.bankFiles.action.downloadSelected'), children: translate('creditor.bankFiles.action.downloadSelected') }), _jsx(ActionsDropdown, { id: 'bank-files-actions-dropdown', drop: 'down', style: { marginLeft: 10 }, buttonProps: {
                        variant: 'outline',
                        color: 'dark',
                        rounded: false,
                        label: translate('table.actions.title')
                    }, dropdownActions: ({ Link: Button }) => {
                        return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => downloadNewBankFiles(refresh), title: translate('creditor.bankFiles.action.downloadNew'), children: translate('creditor.bankFiles.action.downloadNew') }), _jsx(Button, { onClick: () => markBankFilesAsPaid(ids, refresh), disabled: ids.length === 0, title: translate('creditor.bankFiles.action.markAsPaid'), children: translate('creditor.bankFiles.action.markAsPaid') })] }));
                    } })] }));
    },
    RowActions: ({ row: { original: { id, creditorInvoices, lastDownloadAt } }, openCreditorInfoModal, onAlreadyDownloadedBankFile, downloadBankFile, refresh }) => (_jsx(ActionsDropdown, { id: id, scrollable: true, quickActions: [
            {
                icon: 'eye',
                onClick: () => openCreditorInfoModal(id, creditorInvoices)
            },
            {
                icon: 'download',
                title: translate('creditor.bankFile.download'),
                onClick: () => lastDownloadAt
                    ? onAlreadyDownloadedBankFile(id, refresh)
                    : downloadBankFile(id, refresh)
            },
        ] })),
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    columns: {
        $rowSelect: {
            sticky: 'left',
        },
        $actions: {
            sticky: 'left'
        },
        totalAmount: {
            Header: translate('creditor.totalAmount'),
            useSortBy: {},
            Cell: ({ value }) => formatMoney(value)
        },
        state: {
            Header: translate('creditor.state'),
            Cell: ({ value }) => _jsx(CreditorInvoiceState, { state: value })
        },
        lastDownloadAt: {
            Header: translate('creditor.bankFile.lastDownloadedAt'),
            Cell: ({ value }) => value
                ? moment(value).format('DD.MM.YYYY HH:mm:ss')
                : translate('notAvailable'),
            useSortBy: {},
        },
        bankAccount: {
            Header: translate('creditor.bankAccount'),
            useSortBy: {},
            Cell: ({ value }) => value && value.name ? value.name : translate('notAvailable')
        }
    }
}));
const prepAndDownloadFile = (response) => {
    const contentDispositionHeader = response.headers['content-disposition'];
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = fileNameRegex.exec(contentDispositionHeader);
    let fileName = "";
    if (matches !== null && matches[1]) {
        fileName = matches[1]
            .replace(/['"]/g, '')
            .replace('utf-8', '');
    }
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURI(fileName));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};
export const BankFilesDataTable = Object.assign(BaseTable, {
    use: ({ creditorServices, openCreditorInfoModal }) => {
        const { success, error } = useToastDispatcherApi();
        const markBankFilesAsPaid = React.useCallback((bankFileIds, refresh) => {
            creditorServices.markBankFilesAsPaid(bankFileIds)
                .then(() => {
                success({
                    timeout: 2,
                    message: translate('creditor.bankFile.markAsPaid.success')
                });
                refresh();
            })
                .catch((errorRq) => {
                if (errorRq.isAxiosError && errorRq.response && errorRq.response.status === 400) {
                    error({
                        timeout: 5,
                        message: errorRq.response.data.message
                    });
                }
                else {
                    error({
                        timeout: 5,
                        message: translate('creditor.bankFile.markAllAsPaid.error')
                    });
                }
            });
        }, []);
        const downloadNewBankFiles = React.useCallback((refresh) => {
            creditorServices
                .downloadNewBankFiles()
                .then(response => {
                prepAndDownloadFile(response);
                refresh();
            })
                .catch(async (errorRq) => {
                if (errorRq.isAxiosError && errorRq.response && errorRq.response.status === 400) {
                    error({
                        timeout: 5,
                        message: JSON.parse(await errorRq.response.data.text()).message
                    });
                }
                else {
                    error({
                        timeout: 5,
                        message: translate('creditor.popup.downloadNewlyCreated.error.text')
                    });
                }
            });
        }, []);
        const downloadSelectedBankFiles = React.useCallback((bankFileIds, refresh) => {
            creditorServices
                .downloadSelectedBankFiles({ bankFileIds })
                .then(response => {
                prepAndDownloadFile(response);
                refresh();
            })
                .catch(async (e) => {
                if (e.isAxiosError && e.response && e.response.status === 400) {
                    error({
                        timeout: 5,
                        message: JSON.parse(await e.response.data.text()).message
                    });
                }
                else {
                    throw e;
                }
            });
        }, []);
        const downloadBankFile = React.useCallback((bankFileId, refresh) => {
            creditorServices
                .downloadBankFile(bankFileId)
                .then(response => {
                prepAndDownloadFile(response);
                refresh();
            })
                .catch(async (e) => {
                if (e.isAxiosError && e.response && e.response.status === 400) {
                    error({
                        timeout: 5,
                        message: JSON.parse(await e.response.data.text()).message
                    });
                }
                else {
                    throw e;
                }
            });
        }, []);
        const onAlreadyDownloadedBankFile = useModalDispatcherConfirm({
            onConfirm: downloadBankFile,
            message: translate('creditor.popup.downloadBankFile.areYouSure.title'),
            help: translate('creditor.popup.downloadBankFile.areYouSure.text'),
            btnConfirm: translate('creditor.popup.downloadBankFile.areYouSure.confirm'),
            severity: 1
        });
        return BankFilesDataTable.useTable({
            downloadNewBankFiles,
            markBankFilesAsPaid,
            openCreditorInfoModal,
            downloadSelectedBankFiles,
            downloadBankFile,
            onAlreadyDownloadedBankFile,
        }, () => ({
            getData: ({ request }) => creditorServices.listBankFiles({ ...request }),
        }));
    }
});
