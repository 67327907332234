import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { useDebitorInvoiceActions } from './hooks/useDebitorInvoiceActions';
export const DownloadActions = ({ invoice, openLink, }) => {
    if (!invoice.isPayable) {
        return null;
    }
    const menu = useDebitorInvoiceActions({
        invoiceId: invoice.id,
        mandateName: invoice['mandateName'] ?? invoice.mandate?.name,
        multipleParties: invoice.hasMultipleParties,
        multiplePersons: invoice.hasMultiplePersons,
        withQr: invoice.supportsQr,
        reminder: invoice.reminderState,
        download: openLink
    });
    return menu.render();
};
const Actions = ({ cell: { value: invoice }, row, refresh, onEditDebitorClicked, onSendDebitorClicked, onDeleteDebitorClicked, onRemindDebitorClicked, onViewDebitorClicked, openManualPaymentForm, openSplitPaymentForm }) => {
    const isDraft = invoice.currentPlace === 'draft';
    return (_jsx(_Fragment, { children: _jsx(ActionsDropdown, { id: row.id, scrollable: true, quickActions: [
                {
                    icon: "eye",
                    title: translate('debitor.actions.view'),
                    onClick: () => {
                        onViewDebitorClicked(row.id);
                    }
                },
            ], children: ({ Link }) => (_jsxs(_Fragment, { children: [isDraft && _jsxs(_Fragment, { children: [_jsx(Link, { title: translate('debitorInvoice.edit'), label: translate('debitor.actions.edit'), icon: 'edit-3', onClick: () => onEditDebitorClicked(invoice.id) }), _jsx(Link, { icon: 'check', label: translate('debitor.actions.send'), title: translate('debitorInvoice.send'), onClick: () => onSendDebitorClicked(row.id) })] }), invoice.isPayable && _jsx(_Fragment, { children: _jsx(Link, { icon: 'credit-card', label: translate('debitor.actions.pay'), title: translate('debitorInvoice.pay'), onClick: () => openManualPaymentForm(invoice.id) }) }), invoice.hasMainPayments && invoice.state.key.toUpperCase() === "OVERPAID" && _jsx(_Fragment, { children: _jsx(Link, { icon: 'divide-circle', label: translate('debitor.actions.split'), title: translate('debitorInvoice.split'), onClick: () => openSplitPaymentForm(invoice.id) }) }), _jsx(Link, { onClick: () => onDeleteDebitorClicked(invoice.id, refresh), icon: 'trash-2', label: translate('debitor.actions.delete') }), _jsx(Link, { onClick: () => onRemindDebitorClicked(invoice.id, refresh), icon: 'alert-circle', label: translate('debitor.actions.remind') }), _jsx(DownloadActions, { invoice: invoice })] })) }) }));
};
export default Actions;
