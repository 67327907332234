import styled, { css } from "styled-components";
import { UseStyled, useTheme } from "@f2w/theme";
const TwoFactorAuthManagementStyledBase = UseStyled.styled('div', () => ({}));
const Wrapper = styled.div(() => {
    const { typography } = useTheme();
    return css `
       max-width: 670px;
       display: flex;
       flex-direction: column;
       gap: 24px;
       ${typography.body.fontStyles};
       align-items: center;
       text-align: center;
    `;
});
const Heading = styled.h1(() => {
    const { typography } = useTheme();
    return css `
        ${typography.h6.fontStyles};
    `;
});
const Actions = styled.div(() => {
    return css `
      display: flex;
      flex-direction: row;
      gap: 24px;
    `;
});
const TwoFactorAuthManagementStyled = Object.assign(TwoFactorAuthManagementStyledBase, {
    Wrapper,
    Heading,
    Actions,
});
export default TwoFactorAuthManagementStyled;
