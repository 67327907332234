import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { HeaderBase } from './base';
import { FlexCol, FlexRow } from '../Flex';
import { Icon } from 'Atoms/Icon';
const AvatarHeader = {
    Root: (props) => {
        const { avatarProps, avatarText, avatarIcon, pretitle, title, subtitle, actions, children, ...rest } = props;
        return (_jsxs(FlexRow, { wrap: 'wrap', justifySpaceBetween: true, alignCenter: true, ...rest, children: [_jsxs(FlexRow, { wrap: 'wrap', inline: true, alignCenter: true, children: [_jsx(Header.AvatarRoot, { ...avatarProps, children: avatarText ?? _jsx(Icon, { icon: avatarIcon ?? 'file-text' }) }), _jsxs(FlexCol, { gap: 2, children: [pretitle && (_jsx(AvatarHeader.Small, { children: pretitle })), title && (_jsx(AvatarHeader.Title, { children: title })), subtitle && (_jsxs(AvatarHeader.Text, { children: [_jsx(Icon, { icon: "corner-down-right", size: 14 }), " ", subtitle] }))] })] }), !!actions && _jsx(FlexRow, { gap: 10, alignCenter: true, justifyFlexEnd: true, children: actions }), children] }));
    },
    Small: styled('span') `
        display: block;
        color: ${p => p.theme.palette.neutral.$300};
        ${p => p.theme.typography.pretitle.css()};
    `,
    Text: styled('span') `
        display: block;
        margin: 4px 0;
        color: ${p => p.theme.palette.neutral.$900};
        ${p => p.theme.typography.small.css()};

        ${Icon} {
            position: relative;
            top: -3px;
            margin-right: 5px;
        }
    `,
    Title: styled('h2') `
        ${p => p.theme.typography.large.css({ fontWeight: 500, fontFamily: undefined })};
        color: ${p => p.theme.palette.neutral.$800};
        margin: 0;

        small {
            display: block;
            color: ${p => p.theme.palette.neutral.$300};
            ${p => p.theme.typography.pretitle.css()};
        }
    `,
};
export const ContentHeader = styled(HeaderBase) `
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
`;
function Header(props) {
    return _jsx(HeaderBase, { ...props });
}
(function (Header) {
    Header.displayName = 'Header';
    Header.Base = HeaderBase;
    Header.Split = ContentHeader;
    Header.Avatar = AvatarHeader.Root;
    Header.AvatarRoot = styled.div `
        border-radius: 50%;
        background: ${p => p.theme.palette.primary.$50};
        color: ${p => p.theme.palette.primary.$500};
        width: 48px;
        height: 48px;
        font-size: 22px;
        line-height: 23px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
    `;
    Header.Sticky = ({ sticky = 0, ...props }) => (_jsx(ContentHeader, { sticky: sticky, ...props }));
})(Header || (Header = {}));
export { Header };
