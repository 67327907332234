import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ThemeProvider } from '@f2w/theme';
import { translate } from 'Services/Translator';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';
import { ButtonToolbar } from '../ButtonToolbar/ButtonToolbar';
import { StyledButton } from './StyledButton';
export const ButtonTheme = ({ children, size, color, variant, }) => {
    const tokens = useMemo(() => ({ size, color, variant }), [size, color, variant]);
    return (_jsx(ThemeProvider, { tokens: tokens, children: children }));
};
export const Button = Object.assign(Object.assign(StyledButton, {
    ButtonTheme,
    Base: StyledButton,
    Group: ButtonGroup,
    Toolbar: ButtonToolbar,
}), btnTypes({
    Edit: (props) => _jsx(StyledButton, { icon: 'edit', variant: 'outline', label: translate('btn.edit'), ...props }),
    Action: (props) => _jsx(StyledButton, { variant: 'outline', ...props }),
    New: (props) => _jsx(StyledButton, { icon: 'plus', variant: 'outline', label: translate('btn.new'), ...props }),
    Cancel: (props) => _jsx(StyledButton, { variant: 'linkFill', color: 'error', label: translate('btn.cancel'), ...props }),
    Save: (props) => _jsx(StyledButton, { label: translate('btn.save'), ...props }),
    Delete: (props) => _jsx(StyledButton, { variant: 'link', icon: 'trash-2', color: 'error', label: translate('btn.delete'), confirm: translate('confirm.message.delete'), ...props }),
}));
function btnTypes(components) {
    Object.values(components).forEach((c) => {
        c.displayName = 'Button';
    });
    return components;
}
