import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Button } from 'Atoms/Button';
import { getImageSrc } from './helpers';
import styled from 'styled-components';
import { translate } from 'Services/Translator';
import { useDropzone } from 'react-dropzone';
import { ImageCropper } from './ImageCropper';
export const Image = (props) => {
    const { value, onChange, onDelete, editable, round, children, onLoad, uploadLabel = translate('form.control.image.action.upload'), deleteLabel = translate('form.control.image.action.delete'), deleteConfirmLabel = translate('form.control.image.action.delete.confirm'), } = props;
    const [newImageSrc, setNewImageSrc] = React.useState(null);
    const src = newImageSrc ?? value;
    const handleSave = (image) => {
        onChange?.(image);
        setNewImageSrc(null);
    };
    const handleDelete = (image) => {
        onDelete?.(image);
        setNewImageSrc(null);
    };
    const dropzone = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        maxSize: (1024 * 1024) * 2,
        multiple: false,
        onDrop: (files) => {
            const file = files[0];
            if (file) {
                if (editable)
                    getImageSrc(file)
                        .then(src => setNewImageSrc(src))
                        .catch(e => {
                        console.log('Error creating image');
                    });
                else
                    handleSave(file);
            }
        },
    });
    useEffect(() => {
        if (src) {
            onLoad?.(src);
        }
    }, []);
    if (editable && newImageSrc) {
        return (_jsx("div", { children: _jsx(ImageCropper, { cropShape: round ? 'round' : 'rect', ...(typeof editable === 'boolean' ? {} : editable), imageSrc: newImageSrc, onSave: handleSave, onCancel: () => setNewImageSrc(null) }) }));
    }
    return (_jsx("div", { children: _jsxs(DropzoneRoot, { ...dropzone.getRootProps(), "data-state": getDropzoneState(dropzone), children: [_jsx("input", { ...dropzone.getInputProps() }), children ? children({
                    ...props,
                    src
                }) : (src && _jsx(_Fragment, { children: _jsx(Img, { src: src, contain: true }) })), _jsxs(Button.ButtonTheme, { size: 'md', variant: 'outline', children: [_jsx(Button, { label: uploadLabel, icon: 'upload-cloud' }), !value ? null : _jsx(Button, { color: 'error', label: deleteLabel, onClick: (e) => {
                                e.stopPropagation();
                                handleDelete(value);
                            }, confirm: deleteConfirmLabel })] })] }) }));
};
export const Img = (props) => {
    return (_jsx(ImageRoot, { ...props, children: _jsx("img", { style: { width: "100%" }, src: props.src, alt: "Image" }) }));
};
const ImageRoot = styled.div `
  overflow: hidden;
  width: 100%;
  max-width: 200px;

  border: 1px solid ${p => p.theme.palette.neutral.$100};

  > img {
    display: inline-block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  ${p => {
    return {};
}};
`;
const DropzoneRoot = styled.div `
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  border: var(--dropzone-active-color, transparent) 1px dashed;

  &[data-state=active] {
    border-color: var(--fw-color-primary-500);
  }
  &[data-state=accept] {
    border-color: var(--fw-color-success-500);
    > * {
      opacity: .5;
    }
  }
  &[data-state=reject] {
    border-color: var(--fw-color-error-500);
    > * {
      opacity: .5;
    }
  }
`;
function getDropzoneState({ isDragActive, isDragAccept, isDragReject }) {
    if (isDragReject)
        return 'reject';
    if (isDragAccept)
        return 'accept';
    if (isDragActive)
        return 'active';
    return undefined;
}
