import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDom from 'react-dom';
import { OldAppLayout } from '@app/layout';
import { useCreateBankAccountForm } from "Pages/BankAccount/hooks/useCreateBankAccountForm";
import BankAccountServices from "Pages/BankAccount/services/bankAccountServices";
import { FormModal } from "@f2w/form";
const entryElement = document.getElementById('UpsertBankAccountSegmentEntry');
if (entryElement) {
    const onSaveElement = document.getElementById('new-bank-account-added');
    const triggerElement = document.getElementsByClassName('new-bank-account');
    const sideBarTriggerElement = document.getElementById('new-bank-account-sidebar');
    const SubHelperObj = {
        subscriber: null,
        subscribe: function (callbackFn) {
            this.subscriber = { parent: parent, callbackFn: callbackFn };
        },
        openModal: function (bankType) {
            this.subscriber.callbackFn(undefined, bankType);
        },
        addToDropdown: function (newObject) {
            const event = new CustomEvent("click", {
                detail: newObject
            });
            onSaveElement.dispatchEvent(event);
        }
    };
    const UpsertBankAccountSegment = ({ fixedBankType }) => {
        const form = useCreateBankAccountForm({
            createBankAccount: BankAccountServices.createBankAccount,
            onSubmit: (savedBank) => {
                SubHelperObj.addToDropdown({
                    id: savedBank.id,
                    label: savedBank.iban
                        ? savedBank.iban
                        : savedBank.qrIban ?? savedBank.esr
                });
            },
            fixedBankType: fixedBankType
        });
        React.useEffect(() => {
            SubHelperObj.subscribe(form.openForm);
        }, []);
        return _jsx(FormModal, { modal: form });
    };
    let fixedType = undefined;
    const elementToUse = triggerElement.length > 0 ? triggerElement.item(0) : sideBarTriggerElement;
    if (elementToUse) {
        const element = elementToUse;
        for (const className of element.classList) {
            if (className.includes('bank-type-')) {
                fixedType = className.replace('bank-type-', '');
                break;
            }
        }
        $(element).on('click', function (e, data) {
            SubHelperObj.openModal(data ? data.bankType : undefined);
        });
    }
    (async () => {
        ReactDom.render(_jsx(OldAppLayout, { children: _jsx(UpsertBankAccountSegment, { fixedBankType: fixedType }) }), entryElement);
    })();
}
