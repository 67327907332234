import React from "react";
import { PaymentTableModel } from "Pages/Creditor/components/CreditorView/models/PaymentTableModel";
import { useModalDispatcherConfirm, useToastDispatcherApi } from "Components/Dispatcher";
import { translate } from "Services/Translator";
export const usePaymentsTable = ({ creditorInvoiceId, payments, deleteCreditorPayment, refreshDetails }) => {
    const { success, error } = useToastDispatcherApi();
    const onDeletePayment = React.useCallback((id) => {
        deleteCreditorPayment(creditorInvoiceId, id)
            .then(() => {
            success({
                timeout: 2,
                message: translate('payment.delete.successFlash')
            });
            refreshDetails();
        })
            .catch(({ response }) => {
            const errorMessage = (response.status === 400)
                ? response.data.message
                : translate('payment.delete.errorFlash');
            error({
                timeout: 5,
                message: errorMessage
            });
        });
    }, [payments]);
    const onDeleteConfirmation = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: onDeletePayment,
        message: translate('payment.delete.title'),
        help: translate('payment.delete.text'),
        btnConfirm: translate('btn.confirm')
    });
    const table = PaymentTableModel.useCreateTable({
        data: payments,
        onPaymentDelete: onDeleteConfirmation
    });
    return {
        table
    };
};
