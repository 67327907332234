import { AbstractFormType, SelectType, StringType } from "@f2w/form";
import { useEffect, useState } from "react";
import { FeatureFlagServices } from "Pages/Administration/FeatureFlag/services";
import { translateAdmin } from "Services/App";
export class UpsertFeatureFlagType extends AbstractFormType {
    features = [];
    _useField(props) {
        super._useField(props);
        const [features, setFeatures] = useState([]);
        this.features = features;
        useEffect(() => {
            FeatureFlagServices.getAvailableFeatures()
                .then(data => setFeatures(data.map(feature => ({
                value: feature,
                label: feature
            }))));
        }, []);
    }
    buildFields() {
        return {
            email: new StringType({
                label: translateAdmin('featureFlag.email')
            }),
            feature: new SelectType({
                label: translateAdmin('featureFlag.feature'),
                choiceOptions: () => this.features
            }),
        };
    }
}
