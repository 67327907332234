import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FlexCol } from "Atoms/Layout";
import { Text } from "Atoms/Text";
import { translate } from "Services/App";
import { Button } from "Atoms/Button";
import { useResetPasswordForm } from "Pages/Auth/hooks/useResetPasswordForm";
import { Form, FormProvider } from "@f2w/form";
export const ResetPasswordSection = ({ email, verificationToken, resetPassword, toLogin }) => {
    const form = useResetPasswordForm({
        verificationToken,
        resetPassword,
        onSave: toLogin
    });
    return (_jsxs(FlexCol, { gap: 40, children: [_jsxs(FlexCol, { gap: 16, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('account.password.reset.new') }), _jsxs(Text, { medium: 400, neutral: 500, children: [translate('account.password.reset.message'), ": ", _jsx("strong", { children: email })] }), _jsx(Text, { medium: 400, neutral: 500, children: translate('account.reset.password.rules') })] }), _jsxs(FlexCol, { gap: 24, children: [_jsx(FormProvider, { form: form, children: _jsx(Form, { noValidate: true, children: form.render() }) }), _jsx(Button, { onClick: () => {
                            void form.submitForm();
                        }, children: translate('btn.next') }), _jsx(Button, { variant: 'link', onClick: toLogin, children: translate('btn.backToLogin') })] })] }));
};
