import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import useDebouncedCallback from '@restart/hooks/useDebouncedCallback';
const StrengthBar = styled.div `
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: stretch;
  gap: 2px;
  margin: 12px 0;
  height: 8px;
`;
const StrengthBarItem = styled.div `
  flex: 1 1 100%;
  background: #ddd;
  transition: background-color 300ms ease-in-out;
`;
const PasswordStrengthIndicator = ({ password }) => {
    const barColors = ['#ef4836', '#f6b44d', '#2b90ef', '#25c281'];
    const [score, setScore] = useState(0);
    const updateScore = useDebouncedCallback((password) => {
        const options = {
            translations: zxcvbnEnPackage.translations,
            graphs: zxcvbnCommonPackage.adjacencyGraphs,
            dictionary: {
                ...zxcvbnCommonPackage.dictionary,
                ...zxcvbnEnPackage.dictionary,
            },
        };
        zxcvbnOptions.setOptions(options);
        const scoringResult = zxcvbn(password);
        setScore(scoringResult.score || (password ? 1 : 0));
    }, 150);
    useEffect(() => {
        updateScore(password);
    }, [password]);
    return (_jsx(StrengthBar, { children: barColors.map((color, index) => (_jsx(StrengthBarItem, { style: { backgroundColor: score > index ? barColors[score - 1] : undefined } }, index))) }));
};
export default PasswordStrengthIndicator;
