import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { toPx, UseStyled } from '@f2w/theme';
import styled from 'styled-components';
import { SafeAnchor } from 'Atoms/SafeAnchor';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { Text as BaseText } from 'Atoms/Text';
export var Box;
(function (Box) {
    Box.Header = UseStyled.Fn((props) => {
        return _jsx(BaseText, { ...props, as: SafeAnchor, ellipsis: true });
    });
    Box.Text = UseStyled.Fn((props) => {
        return _jsx(BaseText, { neutral: 500, ...props, ellipsis: true });
    });
    Box.Content = UseStyled.styled('div');
    Box.Root = styled.div `
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
      ${p => p.theme.typography.medium.fontStyles};
      max-width: 100%;

      ${Box.Content} {
        color: var(--fw-color-neutral-500);
        text-wrap: none;

        em {
          font-style: inherit;
          color: initial;
        }
      }
    `;
})(Box || (Box = {}));
export const RentalObjectCard = ({ rentalObjectName: title, rentalObjectId: id, buildingName: buildingName, onClick: onStreetClick, maxWidth, }) => {
    const { openViewModalObj } = useObjectViewModalContext();
    const onClick = React.useCallback((e) => {
        e.preventDefault();
        id && openViewModalObj({ id, view: ActiveView.RENTAL_OBJECT });
        onStreetClick && onStreetClick();
    }, [id]);
    return (_jsxs(Box.Root, { style: { maxWidth: toPx(maxWidth) }, children: [_jsx(Box.Header, { onClick: onClick, children: title }), buildingName && (_jsx(Box.Text, { children: buildingName }))] }));
};
