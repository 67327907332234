import { forwardRef } from 'react';
import styled from 'styled-components';
import { isObject } from '@f2w/utils';
import { renderIcon } from './BaseIcon';
const IconBase = forwardRef(({ icon: iconName, size, ...props }, ref) => {
    if (!iconName)
        return null;
    props.ref = ref;
    if (size) {
        Object.assign(props, isObject(size) ? size : { width: size, height: size });
    }
    return renderIcon(iconName, props);
});
export const Icon = styled(IconBase) `
    display: ${p => p.block ? 'block' : 'inline'};
    flex-shrink: 0 !important;
    stroke-linecap: round;
    stroke-linejoin: round;
`;
