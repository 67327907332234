var UserOnboardingTask;
(function (UserOnboardingTask) {
    UserOnboardingTask[UserOnboardingTask["ACCOUNT_ADDRESS"] = 101] = "ACCOUNT_ADDRESS";
    UserOnboardingTask[UserOnboardingTask["PROFILE_JOB_TITLE"] = 102] = "PROFILE_JOB_TITLE";
    UserOnboardingTask[UserOnboardingTask["PROFILE_BIRTHDATE"] = 103] = "PROFILE_BIRTHDATE";
    UserOnboardingTask[UserOnboardingTask["PROFILE_PHONE_NUMBER"] = 104] = "PROFILE_PHONE_NUMBER";
    UserOnboardingTask[UserOnboardingTask["PROFILE_IMAGE"] = 105] = "PROFILE_IMAGE";
    UserOnboardingTask[UserOnboardingTask["MANDATE"] = 201] = "MANDATE";
    UserOnboardingTask[UserOnboardingTask["RENTAL_OBJECT"] = 202] = "RENTAL_OBJECT";
    UserOnboardingTask[UserOnboardingTask["BANK_ACCOUNT_FOR_PROPERTY"] = 203] = "BANK_ACCOUNT_FOR_PROPERTY";
    UserOnboardingTask[UserOnboardingTask["ANCILLARY_EXPENSE_BASE_DATA"] = 205] = "ANCILLARY_EXPENSE_BASE_DATA";
    UserOnboardingTask[UserOnboardingTask["CONTRACT"] = 301] = "CONTRACT";
    UserOnboardingTask[UserOnboardingTask["CONTRACT_MODIFICATION"] = 302] = "CONTRACT_MODIFICATION";
    UserOnboardingTask[UserOnboardingTask["MOVE_IN_PROCESS"] = 303] = "MOVE_IN_PROCESS";
    UserOnboardingTask[UserOnboardingTask["MOVE_OUT_PROCESS"] = 304] = "MOVE_OUT_PROCESS";
    UserOnboardingTask[UserOnboardingTask["AUTOMATIC_INVOICING"] = 401] = "AUTOMATIC_INVOICING";
    UserOnboardingTask[UserOnboardingTask["MANUAL_DEBITOR_INVOICE"] = 402] = "MANUAL_DEBITOR_INVOICE";
    UserOnboardingTask[UserOnboardingTask["CREDITOR_INVOICE"] = 403] = "CREDITOR_INVOICE";
    UserOnboardingTask[UserOnboardingTask["ACCOUNTING_RECORD"] = 404] = "ACCOUNTING_RECORD";
    UserOnboardingTask[UserOnboardingTask["SERVICE_PROVIDER"] = 501] = "SERVICE_PROVIDER";
    UserOnboardingTask[UserOnboardingTask["ASSET"] = 502] = "ASSET";
    UserOnboardingTask[UserOnboardingTask["DAMAGE"] = 503] = "DAMAGE";
})(UserOnboardingTask || (UserOnboardingTask = {}));
export default UserOnboardingTask;
