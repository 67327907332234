import { useMemo } from 'react';
import { ButtonColorDeprecated, buttonSizeToken, ButtonVariantTokens, } from './Button.tokens';
import { Icon } from 'Atoms/Icon';
import styled from 'styled-components';
import { BaseButton } from './BaseButton';
import { isString } from '@f2w/utils';
import { cssVars, tokenGetStyles } from '@f2w/theme';
const buttonStyles = ({ $color, theme: { palette, tokens }, $size = tokens?.size, color: _colorName = tokens?.color, rounded, reverse, theme, block, dark, ...props }) => {
    const styleDeps = Object.keys(ButtonVariantTokens).map(key => props[key]);
    const [_cssVars, _css] = useMemo(() => {
        const colorName = palette?.hasOwnProperty(_colorName) ? _colorName : 'primary';
        const color = palette[colorName];
        const variantCss = (isString($color)
            ? tokenGetStyles(ButtonColorDeprecated, $color, 'primary')?.(theme)
            : tokenGetStyles(ButtonVariantTokens, props, tokens?.variant ?? 'fill')?.(theme, {
                color,
                colorName,
                dark
            }));
        const { lineHeight, borderRadius, paddingX, paddingY, height, ...styles } = buttonSizeToken.getStyles($size, { theme });
        if (block) {
            styles.width = '100%';
            styles.justifyContent = 'center';
            styles.flex = 1;
        }
        return [
            cssVars({
                lineHeight,
                borderRadius: rounded ? '50%' : borderRadius,
                paddingX,
                paddingY,
                borderSize: 1,
                minHeight: height,
            }, 'btn-'),
            {
                ...styles,
                ...variantCss,
            }
        ];
    }, [_colorName, props.variant, $size, block, $color, ...styleDeps]);
    return {
        ..._cssVars,
        flexDirection: reverse ? 'row-reverse' : 'row',
        transitionProperty: 'color, background-color, border-color, box-shadow',
        transitionDuration: '250ms',
        transitionTimingFunction: 'ease-in',
        gap: '.65em',
        ..._css,
        [`${Icon}, .fw-icon`]: {
            fontSize: 'calc(1em + 2px)',
        },
    };
};
export const StyledButton = styled(BaseButton)
    .attrs((props) => {
    return {
        className: props.link || props.variant === 'link' ? 'fw-btn-nogap' : '',
    };
})
    .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['color', ...Object.keys(ButtonVariantTokens)].includes(prop),
})(buttonStyles);
