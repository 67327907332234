import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import personServices from 'Pages/Person/Details/services/personServices';
import { usePersonForm } from '../Details/forms/usePersonForm';
import { PersonView } from 'Pages/Person/Details/components/PersonView';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { FormModal } from '@f2w/form';
import { useModalDispatcherConfirm, useToastDispatcherApi } from "Components/Dispatcher";
import { getScopeFilters } from "Services/ScopeManager";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { useRenterMirrorTenantListExportForm } from "Pages/Rent/forms/RenterMirror/RenterMirrorDateExportForm/useRenterMirrorTenantListExportForm";
import { PersonDataTable } from "Pages/Person/Overview/PersonDataTable";
const PersonPage = ({ getPersonById, updatePerson, personData, createPersonAddress, updatePersonAddress, getPersonAccountStatementReport, listPersons }) => {
    const { openViewModalObj } = useObjectViewModalContext();
    const [personId, setPersonId] = React.useState(personData);
    const onStreetClick = React.useCallback((rentalObjectId) => () => {
        setPersonId(undefined);
        openViewModalObj({
            id: rentalObjectId,
            view: ActiveView.RENTAL_OBJECT,
        });
    }, [openViewModalObj]);
    const { error } = useToastDispatcherApi();
    const onDeleteConfirm = useModalDispatcherConfirm({
        onConfirm: async (id, refresh) => {
            try {
                await personServices.deletePerson(id);
            }
            catch (e) {
                const message = e.response.status === 422 ? e.response.data.message : 'form.response.error';
                error({
                    message: translate(message),
                    timeout: 5,
                    showRemove: true
                });
            }
            finally {
                refresh();
            }
        },
        message: translate('assets.delete.message'),
        help: translate('assets.delete.help'),
    });
    const portfolio = usePortfolio();
    const renterMirrorTenantListExportForm = useRenterMirrorTenantListExportForm();
    const instance = PersonDataTable.use({
        listPersons,
        onCreatePerson: () => personForm.openForm(),
        onEditPerson: (id) => personForm.openForm(id),
        onDeletePerson: onDeleteConfirm,
        onViewPerson: setPersonId,
        openRenterMirrorTenantListExportForm: (type) => {
            const scopeFilters = getScopeFilters(portfolio);
            renterMirrorTenantListExportForm.openForm({
                type,
                ...scopeFilters
            });
        },
        getPersonAccountStatementReport,
    });
    const personForm = usePersonForm({
        onSaved: () => instance.refresh(),
        createPerson: personServices.createPerson,
        updatePerson: personServices.updatePerson,
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, title: translate('persons.overview.title'), content: translate('persons.overview.description') }), _jsx(FormModal, { modal: personForm }), _jsx(FormModal, { modal: renterMirrorTenantListExportForm }), personId && (_jsx(PersonView, { updatePerson: updatePerson, getPersonById: getPersonById, onStreetClick: onStreetClick, createPersonAddress: createPersonAddress, updatePersonAddress: updatePersonAddress, onClose: () => setPersonId(undefined), personId: personId }))] }));
};
export default PersonPage;
