import { Dunning } from "../types";
import generateRoute from "Services/Router";
import client from "Utils/client";
export var DunningService;
(function (DunningService) {
    const downloadFile = (response) => {
        const fileName = response.headers['X-Download-File-Name'.toLowerCase()];
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', decodeURI(fileName));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
    DunningService.listDunning = async (params) => {
        const route = generateRoute('api.dunning.list');
        const { data } = await client.get(route, { params });
        return data;
    };
    DunningService.listDebitor = async (params) => {
        const response = await DunningService.listDunning(params);
        return {
            ...response,
            results: Dunning.transformPersonToDebitor(response.results)
        };
    };
    DunningService.getPersonAccountStatement = async (personId, fromDate, toDate) => {
        const params = {
            person: personId
        };
        if (fromDate) {
            params['fromDate'] = fromDate;
        }
        if (toDate) {
            params['toDate'] = toDate;
        }
        const route = generateRoute('api.dunning.personAccountStatement', params);
        const { data } = await client.get(route);
        return data;
    };
    DunningService.getPersonAccountStatementReport = async (personId, fromDate, toDate) => {
        const params = {
            person: personId
        };
        if (fromDate) {
            params['fromDate'] = fromDate;
        }
        if (toDate) {
            params['toDate'] = toDate;
        }
        const route = generateRoute('api.dunning.personAccountStatementReport', params);
        downloadFile(await client.get(route, {
            responseType: 'blob'
        }));
    };
    DunningService.getPersonAccountStatementReports = async (personIds, fromDate, toDate) => {
        const route = generateRoute('api.dunning.personAccountStatementReports');
        downloadFile(await client.post(route, {
            personIds,
            fromDate,
            toDate
        }, {
            responseType: 'blob'
        }));
    };
    DunningService.createReminders = (reminders, contactPerson) => {
        return client.post(generateRoute('reminder.createBulk'), {
            reminders,
            contactPerson
        });
    };
    DunningService.getContactPersons = () => {
        return client
            .get(generateRoute('reminder.contactPersons'))
            .then(r => r.data);
    };
})(DunningService || (DunningService = {}));
export default DunningService;
