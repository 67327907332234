import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import { components } from 'react-select';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { SearchIcon } from "Components/App/Icons";
const labelSorter = (a, b) => a.label.localeCompare(b.label, 'de', { numeric: true });
const createRentalObjectOption = (rentalObject, portfolio) => {
    return {
        value: rentalObject.id,
        label: rentalObject.name
    };
};
const PortfolioRentalObjectSelect = ({ value = undefined, property: selectedPropertyId = undefined, $variant = "default", ...props }) => {
    const portfolio = usePortfolio();
    const rentalObjects = selectedPropertyId
        ? portfolio.getRentalObjectsByPropertyId(selectedPropertyId)
        : [];
    const options = rentalObjects.map(rentalObject => createRentalObjectOption(rentalObject, portfolio));
    const selectedRentalObject = value ? portfolio.getRentalObjectById(value) : null;
    const selectedOption = selectedRentalObject
        ? createRentalObjectOption(selectedRentalObject, portfolio)
        : null;
    return (_jsx(Select, { value: selectedOption, options: options, components: $variant === 'search' ? {
            ValueContainer: ({ children, ...props }) => {
                return _jsxs(components.ValueContainer, { ...props, children: [_jsx("div", { style: { marginRight: 20, color: "#6C7487" }, children: _jsx(SearchIcon, {}) }), children] });
            },
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
        } : {}, isSearchable: true, menuPortalTarget: document.body, ...props }));
};
export default PortfolioRentalObjectSelect;
