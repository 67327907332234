import { jsx as _jsx } from "react/jsx-runtime";
import { useViewType } from "@f2w/view";
import { FormError } from "@f2w/form";
import { BuildingViewFormType } from "Components/ObjectViewModal/forms/BuildingViewFormType";
import { translate } from "Services/Translator";
import { BuildingTemplate } from '../components/BuildingView/BuildingTemplate';
import moment from "moment";
export const useBuildingView = (loadData, updateBuilding, updateBuildingNameForBreadcrumb, toastApi) => useViewType(() => new BuildingViewFormType(), {
    id: 'building-view',
    DefaultTemplate: props => _jsx(BuildingTemplate, { ...props }),
    load: async ({ id }) => {
        const data = await loadData(id);
        return {
            id,
            ...data,
            generalLeft: {
                name: data.name,
                egid: data.egid,
                street: data.address,
                houseNumber: data.houseNumber,
                buildYear: data.buildYear !== 0
                    ? moment(data.buildYear, 'YYYY').format('YYYY-01-01')
                    : undefined,
                lastRenovation: data.lastRenovation !== 0
                    ? moment(data.lastRenovation, 'YYYY').format('YYYY-01-01')
                    : undefined,
                startOfRenting: data.startOfRenting,
                numberOfFloors: data.numberOfFloors,
                area: data.area,
                taxValue: data.taxValue,
                insuranceValue: data.insuranceValue ?? 0,
                totalInvestment: data.totalInvestment,
                notes: data.notes,
                bunker: data.bunker,
            }
        };
    },
    saveError: (error) => {
        if (!('response' in error)) {
            return new Error(error);
        }
        const { response: { data, status } } = error;
        if ([422, 400].includes(status) || data?.message) {
            return new FormError(data.message);
        }
        return new FormError(data);
    },
    save: async () => { },
}, () => ({
    generalLeft: {
        name: translate('baseData.building.edit'),
        showHeader: false,
        Form: ({ type }) => type.renderChild('generalLeft'),
        save: async (values, view) => {
            const data = view.type.getChild('generalLeft').getResolvedValue();
            return updateBuilding(values.id, data)
                .then(() => {
                updateBuildingNameForBreadcrumb(values.id, data.name);
                view.updateData({
                    name: data.name,
                    egid: data.egid,
                    address: data.street,
                    bunker: data.bunker,
                    houseNumber: data.houseNumber,
                    buildYear: data.buildYear
                        ? Number(moment(data.buildYear).format('YYYY'))
                        : undefined,
                    lastRenovation: data.lastRenovation
                        ? Number(moment(data.lastRenovation).format('YYYY'))
                        : undefined,
                    startOfRenting: data.startOfRenting,
                    numberOfFloors: data.numberOfFloors,
                    area: data.area,
                    taxValue: data.taxValue,
                    insuranceValue: data.insuranceValue,
                    totalInvestment: data.totalInvestment,
                    notes: data.notes,
                    generalLeft: {
                        ...data,
                        buildYear: data.buildYear,
                        lastRenovation: data.lastRenovation,
                        street: data.street,
                        houseNumber: data.houseNumber
                    }
                });
            })
                .catch(({ response }) => {
                const errorMessage = (response.status === 400)
                    ? response.data.message
                    : translate('form.response.error');
                toastApi.error({
                    message: translate(errorMessage),
                    timeout: 5,
                    showRemove: true
                });
            });
        }
    },
    generalRight: {
        showHeader: false
    },
}));
