import { AbstractFormType } from '@f2w/form';
import { AlignmentType, AlignmentTypeEnum } from 'Pages/DocumentTemplates/GlobalSettings/types/AlignmentType';
import { LogoOffsetType } from 'Pages/DocumentTemplates/GlobalSettings/types/LogoOffsetType';
import { translate } from 'Services/Translator';
export class LogoPositionType extends AbstractFormType {
    constructor() {
        super({ label: false });
    }
    buildFields() {
        return {
            alignment: new AlignmentType({
                label: translate('globalTemplateSettings.logoAlignment'),
                includeCustom: true,
                defaultValue: AlignmentTypeEnum.LEFT,
                required: false,
            }),
            offset: new LogoOffsetType({
                inheritView: true,
                isVisible: () => this.field.value.alignment === AlignmentTypeEnum.CUSTOM
            }),
        };
    }
}
