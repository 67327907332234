import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import { translate } from 'Services/Translator';
import { TransactionAssignmentType } from '../../types';
export const assignmentTypeOptions = [
    { value: TransactionAssignmentType.DEBITOR_INVOICE, label: translate('bankingReconciliation.assignTransactions.type.debitorInvoice') },
    { value: TransactionAssignmentType.CREDITOR_INVOICE, label: translate('bankingReconciliation.assignTransactions.type.creditorInvoice') },
    { value: TransactionAssignmentType.MANUAL, label: translate('bankingReconciliation.assignTransactions.type.manual') },
];
export const AssignmentTypeSelect = ({ ...props }) => {
    return (_jsx(Select, { options: assignmentTypeOptions, ...props }));
};
