import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalNew as Modal } from 'Components/Modal';
import { useObjectViewModal } from '../ObjectViewModalContext';
import { ActiveView } from 'Components/ObjectViewModal/enums';
import { translate } from 'Services/Translator';
import ModalHeaderStyled from './ModalHeaderStyled';
import { Badge, Button, Icon } from 'Components/Atoms';
export const ModalHeader = ({ objectViewModalServices }) => {
    const { activeView, setCurrentActiveView, closeModal, onDelete, editData, breadcrumbs } = useObjectViewModal();
    return (_jsx(Modal.Header, { closeButton: true, onHide: closeModal, children: _jsxs(ModalHeaderStyled.Title, { children: [_jsx(ModalHeaderStyled.BreadcrumbWrapper, { children: breadcrumbs.map((breadcrumb, index) => (_jsx(ModalHeaderStyled.Breadcrumb, { children: index + 1 === breadcrumbs.length
                            ? breadcrumb.name
                            : (_jsxs(_Fragment, { children: [_jsx(ModalHeaderStyled.BreadcrumbTitle, { onClick: () => setCurrentActiveView(breadcrumb.view, breadcrumb.id), children: breadcrumb.name }), _jsx(ModalHeaderStyled.BreadcrumbDivider, { children: "/" })] })) }, index))) }), _jsx(ModalHeaderStyled.RightHeaderBlock, { children: ![ActiveView.CONTRACT, ActiveView.RENT, ActiveView.ASSET].includes(activeView.view) && (_jsxs(_Fragment, { children: [_jsxs(Button, { onClick: onDelete, color: 'error', variant: 'outline', style: { border: 'none' }, children: [_jsx(Icon, { icon: 'trash' }), " ", translate('btn.delete')] }), activeView && activeView.view === ActiveView.PROPERTY && editData && (editData.active
                                ? _jsx(Badge, { "$size": 'sm', "$color": 'successOutline', children: translate('active') })
                                : _jsx(Badge, { "$size": 'sm', "$color": 'secondary', children: translate('inactive') }))] })) })] }) }));
};
