import { jsx as _jsx } from "react/jsx-runtime";
import { translateAdmin } from "Services/Translator";
import { CustomerStatus } from "Pages/Administration/Customers/enums";
import { Select as DropdownSelect } from "Atoms/Select";
const options = [
    { value: CustomerStatus.ACTIVE, label: translateAdmin('customer.status.active') },
    { value: CustomerStatus.TERMINATED, label: translateAdmin('customer.status.terminated') },
    { value: CustomerStatus.DISABLED, label: translateAdmin('customer.status.disabled') },
    { value: CustomerStatus.TO_DELETE, label: translateAdmin('customer.status.toDelete') },
    { value: CustomerStatus.DELETED, label: translateAdmin('customer.status.deleted') },
    { value: CustomerStatus.CHURNED, label: translateAdmin('customer.status.churned') },
];
export function StatusFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: options, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
}
