import React from 'react';
import { useModalDispatcherConfirm, useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { FiscalYearTableModel } from 'Pages/Accounting/FiscalYear/models/FiscalYearDataModel';
import { dispatchCustomEvent } from "Services/EventManager";
export const useFiscalYearTable = (fiscalYearServices, onCreateClicked, onEditClicked, onDeleted, accountingSelectedFiscalYearId) => {
    const tableModel = React.useMemo(() => FiscalYearTableModel.create({
        manualControl: true
    }), []);
    const getData = React.useCallback(({ request }) => {
        return fiscalYearServices.listFiscalYears(request);
    }, []);
    const { success, error, warning } = useToastDispatcherApi();
    const onDeleteFiscalYear = React.useCallback((fiscalYearId, refresh) => {
        fiscalYearServices.deleteFiscalYear(fiscalYearId)
            .then(() => {
            success({
                timeout: 2,
                message: translate('accounting.fiscalYear.actions.delete.success')
            });
            onDeleted(fiscalYearId);
            refresh();
        })
            .catch(() => {
            error({
                timeout: 5,
                message: translate('accounting.fiscalYear.actions.delete.error')
            });
        });
    }, []);
    const onDeleteConfirmation = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: onDeleteFiscalYear,
        message: translate('accounting.fiscalYear.actions.delete.areYouSure'),
        help: translate('accounting.fiscalYear.actions.delete.confirmation'),
        btnConfirm: translate('btn.confirm')
    });
    const onToggleLockFiscalYear = React.useCallback((fiscalYearId, refresh) => {
        fiscalYearServices.toggleLockFiscalYear(fiscalYearId)
            .then(() => {
            success({
                timeout: 2,
                message: translate('accounting.fiscalYear.actions.toggleLock.success')
            });
            refresh();
        })
            .catch(() => {
            error({
                timeout: 5,
                message: translate('accounting.fiscalYear.actions.toggleLock.error')
            });
        });
    }, []);
    const onRefreshConfirm = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: (fiscalYearId) => {
            fiscalYearServices.refreshOpeningBalances(fiscalYearId)
                .then(() => {
                success({
                    message: translate('fiscalYear.refreshOpeningBalances.success'),
                    timeout: 5
                });
                if (accountingSelectedFiscalYearId === fiscalYearId) {
                    dispatchCustomEvent('fw.openingBalanceUpdated', {});
                }
            })
                .catch((e) => {
                if (e.isAxiosError && e.response.status === 400) {
                    warning({
                        message: e.response.data.message
                    });
                }
                else {
                    throw e;
                }
            });
        },
        message: translate('fiscalYear.refreshOpeningBalances'),
        help: translate('fiscalYear.refreshOpeningBalances.help'),
        btnConfirm: translate('fiscalYear.refreshOpeningBalances.confirm'),
        severity: 2
    });
    const instance = tableModel.createRemote({
        getData,
        onDeleteClicked: onDeleteConfirmation,
        onToggleLockClicked: onToggleLockFiscalYear,
        onCreateClicked: onCreateClicked,
        onEditClicked: onEditClicked,
        onRefreshBalancesClicked: onRefreshConfirm
    });
    return {
        instance
    };
};
