import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { useObjectViewModalContext, ActiveView } from 'Components/ObjectViewModal';
import { useAncillaryDistributionKeyForm } from '../../hooks';
import { DistributionKeysTable } from '../../table';
import { AccountServices, DistributionKeyServices } from '../../index';
import { FormModal } from '@f2w/form';
import { getScopePropertyId } from "Services/ScopeManager";
import { usePortfolio } from "Components/Base/PortfolioContext";
export const DistributionKeysTab = ({ accountServices = AccountServices, distributionKeyServices = DistributionKeyServices }) => {
    const { openViewModal } = useObjectViewModalContext();
    const portfolio = usePortfolio();
    const distributionKeyForm = useAncillaryDistributionKeyForm({
        onSaved: () => instance.refresh(),
        distributionKeyServices,
    });
    const instance = DistributionKeysTable.use({
        onPropertyClick: openViewModal(ActiveView.PROPERTY),
        distributionKeyServices,
        onCreateDistributionKey: () => distributionKeyForm.openNewForm({ propertyId: getScopePropertyId(portfolio) }),
        onEditDistributionKey: (id) => distributionKeyForm.openForm(id),
        onDuplicateDistributionKey: (propertyId) => undefined,
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { variant: 'content', instance: instance }), _jsx(FormModal, { size: 'content', modal: distributionKeyForm })] }));
};
