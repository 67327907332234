import styled, { css } from "styled-components";
import { UseStyled } from "@f2w/theme";
const RentalObjectStatusWrapper = UseStyled.styled('div', () => ({
    display: 'inline-flex',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    alignItems: 'center',
    gap: '8px'
}));
const BaseBadge = UseStyled.styled('div', ({ theme: { palette } }) => (css `
        color: var(--fw-badge-color, inherit);
        padding: 0.5rem;
        border-radius: 50px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
    `));
const VacantBadge = styled(BaseBadge) `--fw-badge-color: ${p => p.theme.palette.error.$500}`;
const UpcomingBadge = styled(BaseBadge) `--fw-badge-color: ${p => p.theme.palette.warning.$600}`;
const RentedOutBadge = styled(BaseBadge) `--fw-badge-color: ${p => p.theme.palette.success.$600}`;
const NoticedBadge = styled(BaseBadge) `--fw-badge-color: ${p => p.theme.palette.success.$600}`;
export const RentalObjectStatus = Object.assign(RentalObjectStatusWrapper, {
    VacantBadge,
    UpcomingBadge,
    NoticedBadge,
    RentedOutBadge
});
