import { jsx as _jsx } from "react/jsx-runtime";
import { renderContent } from 'Components/Base';
import { translate } from 'Services/Translator';
import { Button } from 'Components/Atoms/Button';
import { isFunction, OrFunction } from 'Utils/types';
export class ViewGroup {
    parent;
    props;
    constructor({ Template, Content, ContentHeader, ContentFooter, Form, Actions, ...props }, parent) {
        this.parent = parent;
        this._templates = {
            Template,
            Content,
            ContentHeader,
            ContentFooter,
            Form,
            Actions
        };
        this.props = {
            showHeader: true,
            ...props,
        };
    }
    get id() {
        return `${this.parent.key}-${this.key}`;
    }
    get view() {
        return this.parent;
    }
    get type() {
        return this.parent.type;
    }
    get key() {
        return this.props.id;
    }
    get data() {
        return this.parent.data;
    }
    get name() {
        return this.props.name ?? this.props.id;
    }
    get title() {
        return OrFunction(this.props.title, this) ?? translate(this.name);
    }
    get locked() {
        return OrFunction(this.props.locked, this);
    }
    get lockedMessage() {
        return OrFunction(this.props.lockedMessage, this) ?? translate('featureLocked.text');
    }
    hasForm() {
        return !!this._templates.Form && (!isFunction(this.props.hasForm) || this.props.hasForm(this));
    }
    render(name = 'Content', props) {
        const data = this.data;
        const template = name && this._templates[name];
        return (data && template && renderContent(template, {
            key: `${this.id}-${name}-content-view`,
            view: this.parent,
            group: this,
            ...props
        })) || null;
    }
    renderActions() {
        const hasForm = this.hasForm();
        if (hasForm) {
            if (this._templates['Actions']) {
                return renderContent(this._templates['Actions'], {
                    key: `${this.id}-Actions-content-view`,
                    view: this.parent,
                    group: this,
                });
            }
            return _jsx(Button.Edit, { style: { float: 'right' }, onClick: () => this.parent.openForm(this.key) });
        }
    }
}
