import { UseStyled } from '@f2w/theme';
import { css } from 'styled-components';
export const Balance = UseStyled.styled('div', ({ theme: { palette, typography } }) => (css `
      border-bottom: 1px solid ${palette.neutral.$100};
      margin-bottom: 56px;
    `));
export const BalanceHeader = UseStyled.styled('div', ({ theme: { palette, typography } }) => (css `
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        ${typography.body.fontStyles};
        font-weight: 600;
        color: ${palette.colors.neutral.$500};
      }
    `));
export const IncomeExpenditureHeader = UseStyled.styled('div', ({ theme: { palette, typography } }) => (css `
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        ${typography.body.fontStyles};
        font-weight: 600;
        color: ${palette.colors.neutral.$500};
      }
    `));
export const IncomeExpenditureDateRange = UseStyled.styled('div', ({ theme: { palette, typography } }) => (css `
      display: flex;
      gap: 16px;

      label {
        margin-right: 10px;
        color: ${palette.colors.neutral.$200};
      }
    `));
export const IncomeExpenditureCharts = UseStyled.styled('div', ({ theme: { palette } }) => (css `
      display: flex;
      justify-content: space-between;
      align-items: center;

      ${IncomeExpenditureChart} {
        width: 200px;
      }
    `));
export const IncomeExpenditureChart = UseStyled.styled('div', {});
export const IncomeExpenditureChartsSeparator = UseStyled.styled('div', ({ theme: { palette } }) => (css `
        width: 1px;
        background: ${palette.neutral.$100};
        height: 90px;
    `));
