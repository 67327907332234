import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { PieChart } from 'Components/Chart';
import { formatMoney } from 'Services/NumberHelper';
import { BalancePieChartLegend } from 'Pages/BankAccount/components/DetailView/BalancePieChartLegend';
export const BalancePieChart = ({ data, totalValue }) => {
    const config = useMemo(() => {
        return {
            dataKey: 'balance'
        };
    }, []);
    const totalInfo = React.useMemo(() => {
        return {
            title: "Total",
            value: formatMoney(totalValue),
            percent: 1,
        };
    }, [totalValue]);
    return (_jsx(PieChart, { colors: ["#9F78ED", "#16AC88", "#FCA524", "#4766FF", "#FA3A3A", "#D7D8DB"], data: data, config: config, totalInfo: totalInfo, Legend: (props) => _jsx(BalancePieChartLegend, { ...props, totalValue: totalValue }) }));
};
