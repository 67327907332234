import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteIcon, DownloadIcon, EditIcon } from "Components/App/Icons";
import { translate } from "Services/Translator";
import generateRoute from "Services/Router";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
const Actions = ({ getPersonAccountStatementReport, row, cell: { value: person }, refresh, onViewPerson, onEditPerson, onDeletePerson }) => {
    const urlParams = { person: person.id };
    const dsgExportUrl = generateRoute('person.dsgexport', urlParams);
    return (_jsxs(ActionsDropdown, { id: row.id, quickActions: [
            {
                icon: "eye",
                title: translate('person.view'),
                onClick: () => onViewPerson(row.id)
            },
        ], children: [_jsxs(ActionsDropdown.Link, { title: translate('person.edit'), onClick: () => onEditPerson(row.id), children: [_jsx(EditIcon, {}), " ", _jsx("span", { children: translate('person.edit') })] }), _jsxs(ActionsDropdown.Link, { title: translate('person.delete'), onClick: () => onDeletePerson(row.id, refresh), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('person.delete') })] }), _jsxs(ActionsDropdown.Link, { href: dsgExportUrl, children: [_jsx(DownloadIcon, {}), " ", _jsx("span", { children: translate('person.export') })] }), row.original.hasContracts && (_jsxs(ActionsDropdown.Link, { onClick: () => getPersonAccountStatementReport(row.original.id), children: [_jsx(DownloadIcon, {}), " ", _jsx("span", { children: translate('dunning.accountStatement') })] }))] }));
};
export default Actions;
