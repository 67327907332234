import { AbstractFormType, SelectType } from "@f2w/form";
import { translate } from "Services/Translator";
import React from "react";
import { getGlobalContext } from "Services/GlobalContext";
import DunningServices from "Pages/Dunning/services/dunningServices";
export class CreateRemindersType extends AbstractFormType {
    contactPersons = [];
    _useField(props) {
        super._useField(props);
        const [contactPersons, setContactPersons] = React.useState([]);
        this.contactPersons = contactPersons;
        const { user } = getGlobalContext();
        React.useEffect(() => {
            DunningServices.getContactPersons()
                .then(persons => setContactPersons(persons.map(person => ({
                label: person.name,
                value: person.id
            }))));
            this.children.contactPersonId.field.setValue(user.id);
        }, []);
    }
    buildFields() {
        return {
            contactPersonId: new SelectType({
                label: translate('reminderProcess.contactPersons.label'),
                choiceOptions: () => this.contactPersons,
                clearable: true,
                required: false,
                searchable: true
            })
        };
    }
}
