import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ContentHeader, Header, Section } from 'Atoms/Layout/components';
import { Div, FlexCol, FlexRow } from 'Atoms/Layout/Flex';
import { Text, Title } from 'Atoms/Text';
import { boolToObject } from '@f2w/utils';
import styled from "styled-components";
import { Icon } from "Atoms/Icon";
import { translate } from "Services/App";
import { Button } from "Atoms/Button";
import generateRoute from "Services/Router";
export const LockedOverlay = styled.div `
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    gap: 20px;
    backdrop-filter: blur(3px);
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    z-index: 100;
    padding-left: 40px;
    padding-right: 40px;
`;
export function ContentTemplateNew({ view, group, groups, renderNav, Header: HeaderComponent, }) {
    const headerProps = boolToObject(group.props.showHeader);
    const content = (_jsxs(_Fragment, { children: [HeaderComponent && _jsx(Header, { sticky: true, children: _jsx(HeaderComponent, { view: view }) }), groups.length > 1 && _jsx(Section.Nav, { sticky: 0, children: renderNav() }), group.locked ? _jsx(_Fragment, { children: _jsxs(FlexCol, { flexGrow: 1, children: [_jsxs(Div, { flexGrow: 1, style: { overflowY: 'hidden', position: 'relative' }, children: [_jsxs(LockedOverlay, { children: [_jsx(Icon, { icon: 'lock', size: 100 }), _jsx(Text, { as: 'h2', h2: true, children: translate('featureLocked.title') }), _jsx(Text, { as: 'p', body: true, style: { opacity: .5 }, children: group.lockedMessage })] }), headerProps && (_jsxs(ContentHeader, { sticky: headerProps?.sticky ?? 0, children: [_jsx(Title, { h6: true, as: 'h3', children: group.title }), _jsxs(Div, { children: [group.render('ContentHeader'), group.renderActions()] })] })), group.render('Content')] }), _jsx(FlexRow, { p: 15, justifyEnd: true, children: _jsx(Button, { label: translate('subscriptions.upgradeButton'), href: generateRoute('subscription.subscribe') }) })] }) }) : _jsxs(_Fragment, { children: [headerProps && (_jsxs(ContentHeader, { sticky: headerProps?.sticky ?? 0, children: [_jsx(Title, { h6: true, as: 'h3', children: group.title }), _jsxs(Div, { children: [group.render('ContentHeader'), group.renderActions()] })] })), group.render('Content')] })] }));
    return group.locked ? _jsx(FlexCol, { gap: 0, h: '100%', children: content }) : content;
}
export { ContentTemplateNew as DefaultContentTemplate };
