import { jsx as _jsx } from "react/jsx-runtime";
import { EmailType } from "@f2w/form";
import { Div } from "Atoms/Layout";
import { Text } from "Atoms/Text";
export class CustomEmailType extends EmailType {
    __getDefaultOptions() {
        return {
            ...(super.__getDefaultOptions()),
            getProps: () => ({
                prefix: undefined
            })
        };
    }
    _renderLabel(_iProps) {
        return (_jsx(Div, { mb: 12, children: _jsx(Text, { neutral: 500, body: 400, children: this.options.label }) }));
    }
}
