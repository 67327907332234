import * as rt from 'react-table';
export const useExpanded = {
    pluginName: 'useExpanded',
    initOptions: (options, table) => ({
        autoResetExpanded: false,
        expandSubRows: !table.useGroupBy,
    }),
    hook: hooks => {
        rt.useExpanded(hooks);
        hooks.getRowProps.push((props, meta) => {
            if (meta.row.canExpand || meta.instance.renderSubRow) {
                const onClick = props.onClick;
                props.onClick = (e) => {
                    if (typeof onClick === 'function') {
                        onClick(e);
                    }
                    if (e.target.tagName === 'TD') {
                        meta.row.toggleRowExpanded();
                    }
                };
            }
            return props;
        });
    },
};
