import { jsx as _jsx } from "react/jsx-runtime";
import Select from 'Components/Select';
import { SelectToggle } from 'Atoms/Select';
export const CategorySelect = (props) => {
    const { selectedCategory, categories, onChange } = props;
    const options = categories.map((category) => ({
        value: category.value ?? category.id,
        label: category.label ?? category.name,
        category,
    }));
    const selectedOption = selectedCategory
        ? options.find(({ value: optionValue }) => optionValue === selectedCategory.id)
        : null;
    const selectedValues = [selectedOption];
    return (_jsx(Select, { value: selectedValues, options: options, onChange: onChange, placeholder: "Search", controlShouldRenderValue: false, isClearable: false, autoFocus: true, menuIsOpen: true, closeMenuOnSelect: true }));
};
export const TagPicker = ({ value, ...props }) => {
    return _jsx(SelectToggle, { maxValueWidth: 250, isClearable: true, ...props, value: value ?? '', badgeProps: {
            $size: 'xs',
            $color: 'primaryOutline',
            ...props.badgeProps
        } });
};
