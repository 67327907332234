import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Checkbox } from '../Checkbox';
import styled from 'styled-components';
import { toNum } from '@f2w/utils';
export const MonthSelect = ({ value, name, disabledMonths, onChange: _onChange, readOnly, disabled, $size, shortNames, }) => {
    const months = useMemo(() => {
        return moment.months().map((label, index) => ({
            value: index + 1,
            label: shortNames ? label.substring(0, 3) : label,
            isDisabled: disabledMonths?.includes(index + 1),
        }));
    }, [disabledMonths]);
    const onChange = useCallback((event) => {
        const month = toNum(event.target.value);
        let values = [...(value || [])];
        if (!event.target.checked)
            values = values.filter(v => (v !== month));
        else if (!values.includes(month))
            values.push(month);
        values = values.sort((a, b) => a < b ? -1 : 1);
        _onChange?.(values, event);
    }, [value, _onChange]);
    return _jsx(MonthSelectRoot, { children: months.map((month) => (_jsx(Checkbox, { name: `${name}[${month.value}]`, "$size": $size, label: month.label, readOnly: readOnly, checked: !!value?.includes(month.value), value: month.value, onChangeNative: onChange, disabled: month.isDisabled || disabled }, `month-${month.value}`))) });
};
const MonthSelectRoot = styled.div `
  --col-size: auto;
  --row-size: auto;
  flex-grow: 1;
  width: fit-content;
  max-width: fit-content;
  padding: 4px;
  column-gap: 30px;
  row-gap: 15px;
  display: grid;
  align-items: stretch;
  justify-content: stretch;
  grid-template-columns: var(--col-size) var(--col-size) var(--col-size);
  grid-template-rows: var(--row-size) var(--row-size) var(--row-size) var(--row-size);
`;
