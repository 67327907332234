import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { Section } from 'Atoms/Layout/components';
import { Div, FlexCol } from "Atoms/Layout";
import { DataTable } from "@f2w/data-table";
import { PropertyRefsDataTable } from "Pages/BankAccount/components/DetailView/PropertyRefsDataTable";
export const PropertiesTab = ({ group, view, updateBankAccountProperties }) => {
    const initialPropertiesRefs = group.data.propertiesRefs;
    const [error, setError] = useState();
    const [propertiesRefs, setPropertiesRefs] = useState(initialPropertiesRefs);
    const instance = PropertyRefsDataTable.use({
        propertiesRefs,
        setPropertiesRefs,
        isEdit: view.state.editPropertiesOpen
    });
    const onClose = useCallback((view) => {
        setPropertiesRefs(initialPropertiesRefs);
        view.setState({
            ...view.state,
            editPropertiesOpen: false
        });
    }, [initialPropertiesRefs]);
    const onSave = useCallback(async (view) => {
        setError(undefined);
        await updateBankAccountProperties(view.data.id, propertiesRefs.map(ref => ({
            propertyId: ref.property.id,
            financialAccountId: ref.financialAccount.id,
            isPrimary: ref.isPrimary
        }))).then((updatedData) => {
            view.setState({
                ...view.state,
                editPropertiesOpen: false
            });
            view.updateData({
                ...view.data,
                ...updatedData,
            });
        }).catch((e) => {
            if (e.isAxiosError && e.response.status === 422) {
                setError(e.response.data.message);
                return;
            }
            throw e;
        });
    }, [propertiesRefs]);
    useEffect(() => {
        view.setState({
            ...view.state,
            onSave,
            onClose
        });
    }, [onClose, onSave]);
    return (_jsx(Section, { children: _jsxs(FlexCol, { gap: 24, children: [error && _jsx(Div, { color: 'error.$500', children: error }), _jsx(DataTable.SimpleTable, { instance: instance })] }) }));
};
