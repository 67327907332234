import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import classes from '../styles';
import classNames from 'classnames';
import Portfolio from 'Models/Portfolio';
import { FavouriteToggle, ListGroup } from './ListGroup';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import useDropdown from 'Hooks/useDropdown';
import { Icon } from 'Atoms/Icon';
import DropdownModal from '../../DropdownModal/DropdownModal';
import { RentalObjectItem } from './RentalObjectItem';
import { Div, FlexRow } from 'Atoms/Layout';
import { Text } from 'Atoms/Text';
import { translate } from 'Services/Translator';
export const PropertyItem = ({ property, onSelected: _onSelected, isSelectedItem = Portfolio.hasPropertyScope(property.id), onToggleFavourite, }) => {
    const portfolio = usePortfolio();
    const rentalObjects = portfolio.getRentalObjectsByPropertyId(property.id);
    const dropdown = useDropdown("middleRight");
    const ref = dropdown.triggerRef;
    const onSelected = useCallback(() => {
        _onSelected({ level: "property", id: property.id });
    }, [_onSelected, property.id]);
    const onKeyDown = (e) => {
        if (e.key === "ArrowRight" || e.key === " ") {
            dropdown.onTrigger();
        }
        else if (dropdown.dropdownPosition && (e.key === "ArrowLeft" || e.key === "Escape" || e.key === "Backspace")) {
            dropdown.hideDropdown();
            ref.current.focus();
        }
        else if (e.key === "Enter") {
            onSelected();
        }
        else {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
    };
    return (_jsx("li", { tabIndex: 0, ref: dropdown.triggerRef, onKeyDown: onKeyDown, onMouseLeave: () => dropdown.hideDropdown(), className: classNames({ [`${classes.selected}`]: isSelectedItem }), onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelected();
        }, children: _jsxs(FlexRow, { alignCenter: true, children: [_jsx(FavouriteToggle, { active: portfolio.isFavouriteProperty(property.id), onToggle: () => {
                        portfolio.toggleFavouriteProperty(property.id);
                        onToggleFavourite();
                    } }), _jsx(Text, { as: Div, flexGrow: 1, medium: 400, children: property.name }), rentalObjects.length > 0 && _jsxs(_Fragment, { children: [_jsxs(FlexRow, { className: classes.rightEnd, onMouseEnter: () => dropdown.onTrigger(), alignCenter: true, gap: 10, children: [_jsx(Text, { small: true, neutral: 300, children: translate('focusSelector.rentalObjectsNumber', { count: rentalObjects.length }) }), _jsx(Icon, { size: 24, color: 'white', icon: 'chevron-right' })] }), _jsx(RentalObjectsDropdown, { dropdown: dropdown, rentalObjects: rentalObjects, onToggleFavourite: onToggleFavourite, onSelected: _onSelected })] })] }) }, property.id));
};
const RentalObjectsDropdown = ({ dropdown, rentalObjects, onToggleFavourite, onSelected }) => {
    const firstItemRef = useRef(null);
    let className = classNames({
        [`${classes.dropDown}`]: true,
        [`${classes["animation_" + dropdown.animation]}`]: dropdown.animation,
    });
    useEffect(() => {
        if (dropdown.dropdownPosition && firstItemRef && firstItemRef.current) {
            firstItemRef.current.focus();
        }
    }, [dropdown.dropdownPosition !== null]);
    return (_jsx(DropdownModal, { dropdown: dropdown, modalTheme: "none", onClickOutside: null, children: _jsx("div", { className: className, onClick: ((e) => e.stopPropagation()), children: _jsx(ListGroup, { style: { overflowY: 'auto' }, children: rentalObjects?.map?.((item, i) => (_jsx(RentalObjectItem, { rentalObject: item, onSelected: onSelected, onToggleFavourite: onToggleFavourite }, `rentalObject-${item.id}`))) }) }) }));
};
