import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import { translate } from "Services/Translator";
const periods = [
    "2year",
    "year",
    "quarter1",
    "quarter2",
    "quarter3",
    "quarter4",
];
const DatePeriodSelect = ({ value, onChange }) => {
    return (_jsx(Select, { value: value, onChange: onChange, options: periods.map((period) => ({
            value: period,
            label: translate('datePeriod.' + period)
        })) }));
};
export default DatePeriodSelect;
