import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button } from "Components/Atoms/Button";
import AuthCodeInput from "Components/Atoms/AuthCodeInput/AuthCodeInput";
import { deactivate2FA } from "Services/TwoFactorAuthManager";
import FormErrorMessage from "Components/Atoms/Form/FormErrorMessage";
import { SuccessPageIcon } from "Components/App/Icons";
import { translate } from "Services/Translator";
import TwoFactorAuthManagementStyled from "Pages/TwoFactorAuth/TwoFactorAuthManagement/TwoFactorAuthManagementStyled";
const { Wrapper, Heading, Actions } = TwoFactorAuthManagementStyled;
const VerifyStep = ({ onSubmit, onCancel }) => {
    const [authCode, setAuthCode] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const onDeactivate = async (authCode) => {
        setErrorMessage(null);
        setLoading(true);
        try {
            await deactivate2FA(authCode);
            onSubmit();
        }
        catch (error) {
            let errorMessage = error.message;
            if (error.response && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            setErrorMessage(errorMessage);
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (authCode.replace(/\s/g, '').length !== 6) {
            return;
        }
        onDeactivate(authCode);
    }, [authCode]);
    return (_jsxs(_Fragment, { children: [_jsx(Heading, { children: translate('2fa.deactivation.verify.title') }), _jsx("div", { children: translate('2fa.deactivation.verify.subtitle') }), _jsx("h3", { children: translate('2fa.deactivation.verify.codeLabel') }), _jsx(AuthCodeInput, { autoFocus: true, defaultValue: authCode, onChange: (value) => {
                    setAuthCode(value);
                    setErrorMessage(null);
                }, length: 6 }), !!errorMessage && _jsx(FormErrorMessage, { children: errorMessage }), _jsxs(Actions, { children: [_jsx(Button, { variant: "neutral", onClick: onCancel, children: translate('2fa.activation.verify.back') }), _jsx(Button, { color: "error", "$size": "lg", onClick: () => onDeactivate(authCode), loading: loading, children: translate('2fa.deactivation.verify.submit') })] })] }));
};
const SuccessStep = ({ onComplete }) => (_jsxs(_Fragment, { children: [_jsx(Heading, { children: translate('2fa.deactivation.success.title') }), _jsx(SuccessPageIcon, {}), _jsx("h3", { children: translate('2fa.deactivation.success.subtitle') }), _jsx("div", { children: translate('2fa.deactivation.success.description') }), _jsx("div", { children: _jsx(Button, { "$size": "lg", onClick: onComplete, children: translate('2fa.deactivation.success.action') }) })] }));
const TwoFactorAuthDeactivationView = ({ onComplete, onCancel }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const promoteStep = () => setCurrentStep((step) => step + 1);
    return (_jsxs(Wrapper, { children: [currentStep === 1 && _jsx(VerifyStep, { onSubmit: promoteStep, onCancel: onCancel }), currentStep === 2 && _jsx(SuccessStep, { onComplete: onComplete })] }));
};
export default TwoFactorAuthDeactivationView;
