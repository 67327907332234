import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { useMandateForm } from 'Pages/Mandate/useMandateForm';
import { FormModal } from '@f2w/form';
import { useMandateTable } from 'Pages/Mandate/useMandateTable';
import { useUserForm } from '../User/Overview/hooks/useUserForm';
import { useEventCallback } from '@restart/hooks';
const MandatePage = ({ openUpsertModal }) => {
    const onSave = useEventCallback(() => {
        table?.refresh?.();
    });
    const mandateForm = useMandateForm({ onSave });
    const userForm = useUserForm({ onSave });
    const table = useMandateTable({
        openMandateForm: mandateForm.openForm,
        openUserForm: userForm.openForm,
    });
    React.useEffect(() => {
        if (openUpsertModal) {
            mandateForm.openForm(null);
        }
    }, [openUpsertModal]);
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: table, title: translate('mandates.title') }), _jsx(FormModal, { modal: mandateForm }), _jsx(FormModal, { modal: userForm })] }));
};
export default MandatePage;
