import { AbstractFormType, SelectType } from '@f2w/form';
import { translate } from 'Services/Translator';
export class FinancialAccountType extends AbstractFormType {
    buildFields() {
        return {
            financialAccountId: new SelectType({
                label: translate('ancillaryExpenseAccount.accountingAccount'),
                searchable: true,
                choiceOptions: () => this.options.financialAccounts?.(),
                required: true,
            }),
        };
    }
}
