import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { UseStyled } from '@f2w/theme';
import styled, { css } from 'styled-components';
import { Icon } from 'Atoms/Icon';
import { EmailType, PhoneType } from '@f2w/form';
import { translate } from 'Services/Translator';
import { FlexRow } from 'Atoms/Layout';
export var PropertyStyled;
(function (PropertyStyled) {
    function ContactCard({ isClickable, name, image, onClick, email, phone }) {
        const info = ([
            email && {
                value: EmailType.format(email),
                href: `mailto:${email}`,
                icon: 'mail'
            },
            phone && {
                value: PhoneType.format(phone),
                href: `tel:${phone}`,
                icon: 'phone'
            },
        ]).filter(p => p?.value);
        return _jsxs(_Fragment, { children: [_jsxs(ContactCard.Root, { isClickable: isClickable, children: [_jsx(ContactCard.ImageRoot, { children: image ? _jsx("img", { src: image, alt: name }) : _jsx("div", { children: name.charAt(0) }) }), _jsx(ContactCard.NameRoot, { isClickable: isClickable, onClick: onClick, children: name })] }), !info?.length ? null : (_jsx(FlexRow, { columnGap: 20, rowGap: 10, justifyEnd: true, flexWrap: true, flexShrink: 1, children: info.map((p, i) => p.value ? (_jsxs(ContactCard.InfoRoot, { as: 'a', href: p.href, children: [_jsx(Icon, { icon: p.icon }), " ", _jsx("span", { children: p.value })] }, `contact-info-${i}`)) : (_jsxs(ContactCard.InfoRoot, { children: [_jsx(Icon, { icon: p.icon }), " ", _jsx("span", { children: translate('notAvailable') })] }, `contact-info-${i}`))) }))] });
    }
    PropertyStyled.ContactCard = ContactCard;
    (function (ContactCard) {
        ContactCard.Root = styled.div `
            display: flex;
            align-items: center;
            gap: 16px;
            cursor: ${p => p.isClickable ? 'pointer' : 'auto'};
            flex: 1 0 auto;
        `;
        ContactCard.InfoRoot = UseStyled.styled('div', () => (css `
            display: inline-flex;
            justify-content: space-between;
            gap: 12px;
            align-items: center;
            flex-wrap: nowrap;
        `));
        ContactCard.NameRoot = styled.div `
          ${p => p.isClickable ? `color: ${p.theme.palette.primary.$500};` : ""}
        `;
        ContactCard.ImageRoot = styled.div `
            img {
                border-radius: 50%;
                height: 48px;
                width: 48px;
            }

            div {
                border-radius: 50%;
                height: 48px;
                width: 48px;
                background-color: #D7D8DB;
                display: flex;
                align-items: center;
                justify-content:center;
                color: black;
            }
        `;
    })(ContactCard = PropertyStyled.ContactCard || (PropertyStyled.ContactCard = {}));
})(PropertyStyled || (PropertyStyled = {}));
export default PropertyStyled;
