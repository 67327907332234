import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { SearchIcon } from 'Components/App/Icons';
import { useTheme } from '@f2w/theme';
import styled, { css } from 'styled-components';
import { Icon } from 'Atoms/Icon';
import useRefWithInitialValueFactory from '@restart/hooks/useRefWithInitialValueFactory';
const LaunchpadSearchBarBase = styled.div(({ isExpanded }) => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            display: block;
            width: 100%;
            background-color: inherit;
            color: ${isExpanded ? palette.neutral.$50 : palette.neutral.$300};
            font-size: ${rem(16)};
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.02em;
            border: none;
            outline: none;
            &::placeholder {
                color: ${isExpanded ? '#50596B' : palette.neutral.$300};
            }
       }
    `;
});
const LaunchpadSearchBarSection = styled.div(({ isExpanded }) => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        flex: 1;
        align-items: center;
        color: ${isExpanded ? palette.neutral.$50 : palette.neutral.$300};
    `;
});
const LaunchpadSearchBarIcon = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        margin-right: ${rem(18)};
    `;
});
const LaunchpadSearchBarCloseIcon = styled.span(() => {
    const { rem, palette } = useTheme();
    return css `
        cursor: pointer;
        color: ${palette.neutral.$50};
    `;
});
const LaunchpadSearchBar = ({ inputRef, initialSearchQuery, onSearchQueryChange, isExpanded, onDone, onReset, onCancel, onFocus, placeholder }) => {
    const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
    const isLoadedRef = useRefWithInitialValueFactory(() => false);
    const onChange = (query) => {
        setSearchQuery(query);
        onSearchQueryChange(query);
    };
    useEffect(() => setSearchQuery(initialSearchQuery), [initialSearchQuery]);
    useEffect(() => {
        if (isExpanded && !isLoadedRef.current) {
            isLoadedRef.current = true;
            onSearchQueryChange('');
        }
    }, [isExpanded]);
    return (_jsxs(LaunchpadSearchBarBase, { isExpanded: isExpanded, children: [_jsxs(LaunchpadSearchBarSection, { isExpanded: isExpanded, children: [_jsx(LaunchpadSearchBarIcon, { children: _jsx(SearchIcon, { size: 22 }) }), _jsx("input", { ref: inputRef, placeholder: placeholder, value: searchQuery || '', onFocus: onFocus, onChange: (e) => onChange(e.target.value), onKeyDown: (e) => {
                            if (e.key === "Enter" || e.key === 'ArrowDown') {
                                e.preventDefault();
                                onDone();
                            }
                            if (e.key === "Escape") {
                                e.preventDefault();
                                onCancel();
                            }
                            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                                e.stopPropagation();
                            }
                        } })] }), searchQuery && _jsx(LaunchpadSearchBarCloseIcon, { onClick: () => {
                    onReset();
                    setSearchQuery("");
                    inputRef.current.focus();
                }, children: _jsx(Icon, { size: 22, icon: "x" }) })] }));
};
export default LaunchpadSearchBar;
