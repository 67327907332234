import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FlexRow } from "Atoms/Layout";
import { Text } from 'Components/Atoms';
import { Box as Box } from "Components/App/card";
import { translate } from "Services/Translator";
export const PositionsObjectDataField = ({ positions, onBuildingClick, onPropertyClick, openPositionsModal }) => {
    const firstRentalObjectPosition = positions.find(position => position.rentalObject);
    const firstPropertyPosition = positions.find(position => position.property);
    const onClick = React.useCallback((e, id) => {
        e.preventDefault();
        onBuildingClick(id);
    }, [onBuildingClick]);
    if (!firstPropertyPosition && !firstRentalObjectPosition) {
        return _jsx(_Fragment, { children: translate('notAvailable') });
    }
    if (firstRentalObjectPosition) {
        const { rentalObject: { id, buildingName, ewid, name, buildingEgid: egid } } = firstRentalObjectPosition;
        const code = (egid || ewid)
            ? (egid ?? '') + (egid && ewid ? '-' : '') + (ewid ?? '')
            : '';
        return (_jsxs(FlexRow, { gap: 15, alignCenter: true, textNoWrap: true, children: [_jsxs(Box.Root, { style: { flex: "none" }, children: [_jsx(Box.Header, { onClick: e => onClick(e, id), children: buildingName }), _jsxs(Box.Content, { children: [!!code && code, !!code && !!name && _jsx(_Fragment, { children: "\u00A0\u00B7\u00A0" }), !!name && _jsx("em", { children: name })] })] }), positions.length > 1 &&
                    _jsxs(Text, { as: 'a', href: '#', primary: 500, onClick: e => {
                            e.preventDefault();
                            openPositionsModal(positions);
                        }, children: ["+", positions.length - 1] })] }));
    }
    const { property: { id, name } } = firstPropertyPosition;
    return (_jsxs(FlexRow, { gap: 15, alignCenter: true, children: [_jsx("a", { href: "#", onClick: (event => {
                    event.preventDefault();
                    onPropertyClick(id);
                }), children: name }), positions.length > 1 &&
                _jsxs(Text, { as: 'a', href: '#', primary: 500, onClick: e => {
                        e.preventDefault();
                        openPositionsModal(positions);
                    }, children: ["+", positions.length - 1] })] }));
};
