import { setIn } from './propertyPath';
export function getUrlParams(url) {
    return parseUrlParams(url);
}
export function parseUrlParams(url, data = {}) {
    const _location = window?.top?.location ?? window?.location;
    const u = url ?? _location.search;
    const params = new URLSearchParams(u);
    params.forEach((value, key) => {
        data = setIn(data, key, value);
    });
    return data;
}
