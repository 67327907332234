import { ScalarField } from './ScalarField';
import { useIMask } from 'react-imask';
export class MaskedField extends ScalarField {
    _skipUpdate = false;
    _maskInput;
    constructor(type, props) {
        super(type, props);
    }
    get mask() {
        return this._maskInput?.maskRef?.current ?? this._type.getMask();
    }
    get maskRef() {
        return this._maskInput?.ref;
    }
    use(props) {
        super.use(props);
        this._maskInput = useIMask(this._type.getMask(), {
            onAccept: (value, mask, e) => {
                if (this._skipUpdate) {
                    this._skipUpdate = false;
                }
                else if (this.state.value !== mask.unmaskedValue) {
                    this._updateValue({
                        value: mask.unmaskedValue,
                        shouldValidate: true,
                    });
                }
            },
            onComplete: (unmaskedValue, mask) => {
            },
        });
        return this;
    }
    reset() {
        super.reset();
        this._skipUpdate = true;
        this.mask.value = (this.initialValue ?? "").toString();
    }
    setValue(value, shouldValidate = true, skipTouched = !shouldValidate) {
        this._skipUpdate = true;
        this.mask.value = (value ?? "").toString();
        super.setValue(this.mask.unmaskedValue, shouldValidate, skipTouched);
    }
    setInternalValue(value) {
        this._skipUpdate = true;
        this.mask.value = (value ?? "").toString();
        super.setInternalValue(this.mask.unmaskedValue);
    }
    __dump() {
        return {
            ...super.__dump(),
            mask: {
                value: this.mask.value,
                typedValue: this.mask.typedValue,
                unmaskedValue: this.mask.unmaskedValue,
            },
        };
    }
}
