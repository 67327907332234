import { useMemo } from 'react';
import { isString, toObject } from '@f2w/utils';
import './Modal.styles.scss';
import { SlotFactory } from '@fw/theme';
export const modalSlot = new SlotFactory('modal');
export const ClassName = {
    open: 'fw-modal-open',
};
export const modalSize = {
    'fullscreen': {
        width: '100vw',
        height: '100vh',
        maxHeight: '100vh',
        minHeight: '100vh'
    },
    'test': {
        width: '90vw',
        maxWidth: '1200px',
        minWidth: '400px',
        height: '60vh',
        minHeight: '400px',
        maxHeight: '800px',
    },
    'detailView': {
        width: '95vw',
        height: '85vh',
    },
    'content': {
        width: '80vw',
        maxWidth: '1000px',
        maxHeight: '90vh',
        minHeight: '50vh'
    },
    'form-preview': {
        width: '60vw',
        height: '90vh'
    },
    'form': {
        maxWidth: '700px',
        maxHeight: '90vh'
    },
    'small': {
        maxWidth: '600px',
        maxHeight: '60vh'
    },
};
export function getModalSizeProps(size) {
    if (isString(size)) {
        return { ...modalSize[size] };
    }
    return toObject(size);
}
const modalPositionMap = {
    CENTER: 0,
    TOP: 1,
    RIGHT: 2,
    BOTTOM: 3,
    LEFT: 4,
    TOP_LEFT: 14,
    TOP_RIGHT: 12,
    BOTTOM_LEFT: 34,
    BOTTOM_RIGHT: 32,
};
export function createPositionMargin(offset = 0, position = 'CENTER') {
    return useMemo(() => {
        const margin = Array(4).fill('auto');
        const pos = modalPositionMap[position]?.toString();
        if (pos) {
            const [pos1, pos2] = pos.split('');
            if (pos1)
                margin[pos1 - 1] = offset || 0;
            if (pos2)
                margin[pos2 - 1] = offset || 0;
        }
        return margin.join(' ');
    }, [position]);
}
