import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalNew } from "Components/Modal";
import { UseStyled } from "@fw/theme";
import { FlexCol, FlexRow } from "Atoms/Layout";
import { Text } from "Atoms/Text";
import { Icon } from "Atoms/Icon";
import { DateType, EmailType, PhoneType } from "@f2w/form";
import { translate } from "Services/Translator";
import { Button, ButtonToolbar } from "Components/Atoms";
const Avatar = UseStyled.styled('div', ({ theme: { palette } }) => ({
    borderRadius: '50%',
    height: '48px',
    width: '48px',
    backgroundColor: palette.neutral.$100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.neutral.$500,
    fontWeight: 'normal'
}));
const InfoIcon = UseStyled.styled('div', ({ theme: { palette } }) => ({
    borderRadius: '50%',
    height: '48px',
    width: '48px',
    backgroundColor: palette.primary.$50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.primary.$500,
    fontWeight: 'normal'
}));
export const PersonShortInfoModal = ({ row, onClose, openPersonDetails }) => {
    if (!row) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(ModalNew, { show: true, onHide: onClose, size: { maxWidth: '600px' }, children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onClose, children: _jsx(ModalNew.Title, { children: _jsxs(FlexRow, { gap: 15, alignItems: 'center', children: [_jsx(Avatar, { children: _jsx(Text, { body: 400, children: row.initials }) }), _jsx("div", { children: row.name })] }) }) }), _jsx(ModalNew.Body, { children: _jsxs(FlexRow, { gap: 15, children: [_jsxs(FlexCol, { gap: 40, size: "50%", children: [_jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'map-pin' }) }), _jsx(FlexCol, { children: row.street
                                                ? (_jsxs(_Fragment, { children: [_jsxs(Text, { body: 400, children: [row.street, " ", row.houseNumber] }), _jsxs(Text, { body: 400, children: [row.zip, " ", row.city] })] }))
                                                : _jsx(_Fragment, { children: translate('notAvailable') }) })] }), _jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'phone' }) }), _jsx(Text, { body: 400, children: row.phoneNumber ? PhoneType.format(row.phoneNumber) : translate('notAvailable') })] }), _jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'mail' }) }), _jsx(Text, { body: 400, children: row.emailAddress ? EmailType.format(row.emailAddress) : translate('notAvailable') })] })] }), _jsxs(FlexCol, { gap: 40, size: "50%", children: [_jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'gift' }) }), _jsx(Text, { body: 400, children: row.dateOfBirth ? DateType.format(row.dateOfBirth) : translate('notAvailable') })] }), row.secondPhoneNumber && (_jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'phone' }) }), _jsx(Text, { body: 400, children: PhoneType.format(row.secondPhoneNumber) })] })), row.secondEmailAddress && (_jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'mail' }) }), _jsx(Text, { body: 400, children: EmailType.format(row.secondEmailAddress) })] }))] })] }) }), _jsx(ModalNew.Footer, { children: _jsxs(ButtonToolbar, { children: [_jsx(Button, { variant: 'outline', color: 'neutral', onClick: onClose, children: translate('btn.close') }), _jsx(Button, { onClick: () => {
                                onClose();
                                openPersonDetails(row.personId);
                            }, children: translate('dunning.btn.openPersonDetails') })] }) })] }));
};
