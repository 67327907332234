import { jsx as _jsx } from "react/jsx-runtime";
import { createWithScope, dataAttr, slotAttr, slotCss, } from './utils';
export class SlotFactory {
    scope;
    constructor(scope) {
        this.scope = scope;
        this.create = this.create.bind(this);
        this.props = this.props.bind(this);
        this.attr = this.attr.bind(this);
        this.css = this.css.bind(this);
        this.partAttr = this.partAttr.bind(this);
        this.partCss = this.partCss.bind(this);
    }
    create(slot, options = Object.create(null)) {
        return createWithScope(this.scope, slot, options);
    }
    props(slot, props) {
        const attrs = this.attr(slot);
        return {
            ...attrs,
            ...props,
            ...attrs
        };
    }
    attr(slot) {
        return slotAttr(this.scope, slot);
    }
    css(...slots) {
        return slotCss(this.scope, ...slots);
    }
    partAttr(part) {
        return slotAttr(null, part);
    }
    partCss(...slots) {
        return slotCss(null, ...slots);
    }
}
export class SlotFactory2 extends SlotFactory {
    parts;
    constructor(scope, parts) {
        super(scope);
        this.parts = parts;
    }
    slot(slot, props) {
        return _jsx(Slot, { scope: this.scope, part: slot });
    }
}
export const Slot = ({ scope, part, slot, as: Tag = 'div', ...props }) => {
    return _jsx(Tag, { ...(slot ? { 'data-slot': dataAttr(slot) } : slotAttr(scope, part)), ...props });
};
