import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import { ActiveView } from "Pages/Administration/enums";
const ActiveViewContext = React.createContext({
    activeView: ActiveView.CUSTOMERS,
    setActiveView: () => { },
    setDetailsActiveView: () => { },
    setAdditionalData: () => { },
});
export const ActiveViewProvider = ({ children }) => {
    const [id, setId] = useState();
    const [additionalData, setAdditionalData] = useState();
    const [activeView, setActiveView] = useState(ActiveView.CUSTOMERS);
    return (_jsx(ActiveViewContext.Provider, { value: {
            id,
            activeView,
            setActiveView: (activeView, additionalData) => {
                setId(undefined);
                setActiveView(activeView);
                setAdditionalData(additionalData);
            },
            setDetailsActiveView: (id, activeView, additionalData) => {
                setId(id);
                setActiveView(activeView);
                setAdditionalData(additionalData);
            },
            additionalData,
            setAdditionalData
        }, children: children }));
};
export const useActiveViewContext = () => useContext(ActiveViewContext);
