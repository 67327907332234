import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from "Atoms/Layout/components";
import { Tbody, Td, Th, Thead, Tr } from "Atoms/Table";
import { translateAdmin } from "Services/App";
import { Icon } from "Atoms/Icon";
import { DateType } from "@f2w/form";
import { getSubscriptionStatusBadge } from "Pages/Administration/Customers/helpers/badges";
export const SubscriptionsTab = ({ view }) => {
    const { subscriptions } = view.data;
    const { openSubscriptionDetails } = view.options.actions;
    return (_jsxs(Section.Table, { stickyHead: 0, colSpan: 5, children: [_jsxs(Thead, { children: [_jsx(Th, { children: translateAdmin('customer.subscription') }), _jsx(Th, { children: translateAdmin('customer.subscription.start') }), _jsx(Th, { children: translateAdmin('customer.subscription.end') }), _jsx(Th, { children: translateAdmin('customer.subscription.status') }), _jsx(Th, { children: " " })] }), _jsx(Tbody, { children: subscriptions.map((subscription) => (_jsxs(Tr, { style: { cursor: "pointer" }, onClick: () => openSubscriptionDetails(subscription.id), children: [_jsx(Td, { children: subscription.name }), _jsx(Td, { children: DateType.useFormat(subscription.start) }), _jsx(Td, { children: DateType.useFormat(subscription.end) }), _jsx(Td, { children: getSubscriptionStatusBadge(subscription.status) }), _jsx(Td, { children: _jsx(Icon, { icon: 'chevron-right', size: 20 }) })] }, subscription.id))) })] }));
};
