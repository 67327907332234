import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useDispatcherApi } from "Components/Dispatcher";
import { translate } from "Services/Translator";
import { useEffect, useRef, useState } from "react";
import { Select } from "Atoms/Select";
import { getAccountUsers } from "Services/UserServices";
export const useUserDeleteApi = ({ onSuccess, deleteUser, getUserDeletionSummary }) => {
    const { modal: modalApi } = useDispatcherApi();
    const [users, setUsers] = useState([]);
    const ref = useRef();
    useEffect(() => {
        getAccountUsers()
            .then(response => setUsers(response.map(user => ({
            value: user.id,
            label: user.fullName
        }))));
    }, []);
    return async (userId) => {
        const summary = await getUserDeletionSummary(userId);
        const isSummaryEmpty = summary.taskCount === 0
            && summary.propertyCount === 0
            && summary.handoverCount === 0;
        if (isSummaryEmpty) {
            modalApi.show({
                onConfirm: async () => {
                    return deleteUser(userId)
                        .then(onSuccess);
                },
                message: translate('user.delete.help'),
                btnConfirm: {
                    label: translate('user.delete')
                },
            });
            return;
        }
        modalApi.show({
            asyncLoad: async () => {
                let transferToUserId = undefined;
                return {
                    severity: 4,
                    onConfirm: async () => {
                        return deleteUser(userId, transferToUserId)
                            .then(onSuccess);
                    },
                    message: translate('modalDispatcher.areYouSure'),
                    content: _jsxs(_Fragment, { children: [_jsx("p", { children: _jsxs("b", { children: [translate('user.delete.confirmation'), ":"] }) }), _jsxs("ul", { children: [summary.handoverCount > 0 ? _jsxs("li", { children: [summary.handoverCount, " ", translate('handover.title')] }) : undefined, summary.propertyCount > 0 ? _jsxs("li", { children: [summary.propertyCount, " ", translate('properties')] }) : undefined, summary.taskCount > 0 ? _jsxs("li", { children: [summary.taskCount, " ", translate('dashboard.sections.tasks.title')] }) : undefined] })] }),
                    btnConfirm: {
                        label: translate('user.delete')
                    },
                    customSection: (summary.propertyCount > 0 || summary.taskCount > 0) ? (_jsxs("section", { children: [_jsxs("p", { children: [translate('user.transferToUser'), ":"] }), _jsx(Select, { options: users, isClearable: true, container: true, onChange: option => transferToUserId = option?.value })] })) : undefined
                };
            }
        });
    };
};
