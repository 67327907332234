import { inObject, isObject } from 'Utils/types';
export function forColumns(columns, cb) {
    Object.keys(columns).map((id) => {
        const column = columns[id] = cb(id, columns[id], columns);
        if (!isObject(column)) {
            columns[id] = null;
            delete columns[id];
        }
        if (inObject(column, 'columns')) {
            column.columns = forColumns(column.columns, cb);
        }
        columns[id] = column;
    });
    return columns;
}
export function columnsToArray(columns) {
    return Object.keys(columns).map((id) => {
        const column = columns[id];
        if (column.columns) {
            column.columns = columnsToArray(column.columns);
        }
        return column;
    }).filter(c => !!c);
}
export function extractOptions(props, defaultOptions) {
    const newProps = {};
    Object.keys(props).map(key => {
        if (defaultOptions[key]) {
            newProps[key] = key === 'columns'
                ? extractOptions(props[key], defaultOptions[key])
                : defaultOptions[key];
        }
    });
    return newProps;
}
