import { jsx as _jsx } from "react/jsx-runtime";
import { Select as DropdownSelect } from "Atoms/Select";
import { usePortfolio } from "Components/Base/PortfolioContext";
export function PortfolioPropertyFilter({ column: { filterValue, setFilter }, }) {
    const portfolio = usePortfolio();
    const options = portfolio.getProperties().map(property => ({
        label: property.name,
        value: property.id
    }));
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: options, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
}
