import { BaseType, BaseValueType, createSpecsApi, } from '../../base';
import { SimpleValue } from './SimpleValue';
export class SimpleValueType extends BaseValueType {
    _specs;
    constructor(props) {
        super(props);
        this._specs = createSpecsApi({
            defaultOptions: props?.defaultOptions,
            getSchema: props?.getSchema,
            schemaType: () => this._createSchema(),
            getOptionsModifiers: p => this.getOptionsModifiers(p),
            getConstraints: p => this.getConstraints(p),
        });
    }
    static specs() {
    }
    static define(type) {
    }
    createValueType(type, props) {
        return new SimpleValue(type, props);
    }
    format(value) {
        value = this.cast(value, { ignoreDefaults: true }) ?? '';
        return this._props.format ? this._props.format(value) : value.toString();
    }
    getConstraints(props) {
        return {};
    }
}
export class BaseSimpleType extends BaseType {
    static format(value) {
        return (new this({})).specs.format(value);
    }
    constructor(options) {
        super(options);
    }
}
