import { jsx as _jsx } from "react/jsx-runtime";
import { DefaultContentTemplate } from '../components/templates';
import { Nav } from 'Components/Atoms/Nav';
import { renderContent } from 'Components/Base';
export function useTabView({ view, groups: groupNames, stateKey, templates, Header, Template: InitialTemplate = DefaultContentTemplate, }) {
    const groups = view.pick(groupNames);
    const activeKey = (view.state[stateKey] || groups[0]?.key);
    const content = view.get(activeKey);
    const id = `${view.key}-group-template--${stateKey}`;
    const show = (name) => {
        view.updateState({ [stateKey]: name });
    };
    const renderNav = (props) => {
        return content && _jsx(Nav, { variant: 'tabs', ...props, onSelect: show, activeKey: activeKey, className: ['fw-view-tabs--nav', props?.className], children: groups.map(group => (_jsx(Nav.Link, { eventKey: group.key, children: group.title }, `${id}-${group.id}`))) });
    };
    const render = (props) => {
        return content && renderContent(templates?.[content?.key] ?? InitialTemplate, {
            key: content?.key ?? 'default',
            ...api,
            ...props
        });
    };
    const api = {
        view,
        group: content,
        groups: groups,
        Header,
        show,
        renderNav,
        id
    };
    return [render, api];
}
