import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { BlockBase, DividerBase, SectionBase } from './base';
import { Nav, NavLinkRoot } from 'Atoms/Nav';
import { Table } from 'Atoms/Table';
import { Div } from '../Flex';
import { SimpleDataTable } from '@f2w/data-table/layout/SimpleTable';
const SectionWrap = styled(BlockBase) ``;
const SectionRoot = styled(SectionBase).attrs((props) => ({ as: 'section', ...props })) ``;
const SectionSide = styled(Div)(({ right, spacing }) => {
    const condiCss = right ? {
        paddingLeft: spacing ?? 'var(--fw-padding-x, 24px)',
        marginLeft: spacing ?? 'var(--fw-padding-x, 24px)',
        borderLeft: '1px solid var(--border-color, transparent)',
    } : {
        paddingRight: spacing ?? 'var(--fw-padding-x, 24px)',
        marginRight: spacing ?? 'var(--fw-padding-x, 24px)',
        borderRight: '1px solid var(--border-color, transparent)',
    };
    return css `
      && {
        flex-grow: 0;
        ${condiCss}
        ${NavLinkRoot} {
          padding: 6px 8px;
          ${p => p.theme.typography.medium.fontSizeStyles};
        }
      }
    `;
});
export const Section = Object.assign(SectionRoot, {
    Base: SectionBase,
    Side: SectionSide,
    Row: styled(SectionBase) `
      display: flex;

      & > :not(${SectionSide}) {
        flex-grow: 1;
        min-width: 0;
      }
    `,
    Col: styled(SectionBase) `
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: ${p => p.gap};
      & > :not(${SectionSide}) {
        flex-grow: 1;
        min-width: 0;
      }
    `,
    Nav: styled(SectionBase) `
      padding-bottom: 0;
      margin-bottom: var(--fw-padding-y);
      width: 100%;
      min-width: 100%;
      z-index: 31;
    `,
    SideNav: styled(SectionSide).attrs({ as: Nav }) ``,
    Divider: styled(DividerBase) ``,
    Table: (props) => (_jsx(SectionRoot, { fitContent: true, children: _jsx(Table, { variant: 'section', ...props }) })),
    DataTable: (props) => (_jsx(SectionRoot, { fitContent: true, children: _jsx(SimpleDataTable, { variant: 'section', ...props }) })),
});
