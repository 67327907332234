import { translate } from 'Services/Translator';
import { DataTable } from '@f2w/data-table';
import { QuickschildIntegrationApi } from 'Pages/Quickschild/services/QuickschildIntegrationService';
const BaseTable = DataTable.factory()(() => ({
    tableId: 'Quickschild',
    manualControl: true,
    usePagination: {},
    initialState: {
        sortBy: [{ id: 'name', desc: false }],
    },
    useSortBy: {},
    columns: {
        name: {
            Header: translate('quickschild.integrationView.properties.property'),
            useSortBy: {},
        },
        address: {
            Header: translate('quickschild.integrationView.properties.address'),
            accessor: u => u.zip + ' ' + u.city,
        },
    }
}));
export const QuickschildPropertiesTable = Object.assign(BaseTable, {
    use: () => {
        return QuickschildPropertiesTable.useTable({}, () => ({
            getData: async ({ request }) => QuickschildIntegrationApi.getProperties(request),
            useRowSelect: {
                showSelectAll: true,
                getAllIds: async (request) => QuickschildIntegrationApi.getPropertiesIds(request),
            },
        }));
    },
});
