import { UseStyled, useTheme } from "@f2w/theme";
import { css } from "styled-components";
var AccountingNoPropertyPageStyled;
(function (AccountingNoPropertyPageStyled) {
    AccountingNoPropertyPageStyled.Body = UseStyled.styled('div', () => {
        const { rem, palette, typography } = useTheme();
        return css `
        `;
    });
    AccountingNoPropertyPageStyled.Content = UseStyled.styled('div', () => {
        const { rem, palette, typography } = useTheme();
        return css `
            max-width: ${rem(700)};
        `;
    });
})(AccountingNoPropertyPageStyled || (AccountingNoPropertyPageStyled = {}));
export default AccountingNoPropertyPageStyled;
