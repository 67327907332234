import { inObject, isNotEmptyObject } from 'Utils/types';
import { FormField } from './FormField';
import dom from 'dom-helpers';
export class ObjectField extends FormField {
    isCompound = true;
    constructor(type, props) {
        super(type, props);
    }
    _updatePath() {
        super._updatePath();
        if (this.isRoot || this._type.options.inheritData) {
            this._props.path = this.parent?.path || null;
        }
    }
    setValue(newValue, shouldValidate) {
        super.setValue(newValue, shouldValidate);
    }
    setError(errors, focusFirst) {
        if (isNotEmptyObject(errors)) {
            this.forChildren(async (child) => {
                if (child._type.isInherited()) {
                    child.setError(errors);
                }
                else if (inObject(errors, child.key)) {
                    child.setError(errors[child.key]);
                }
            });
            if (focusFirst) {
                const firstError = dom.querySelectorAll(document.body, '[aria-invalid=true]').shift();
                firstError?.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }
    async _validate(value) {
        const errors = {};
        this.forChildren(async (child) => {
            if (inObject(value, child.key)) {
                errors[child.key] = await child._validate(value[child.key]);
            }
        });
        return errors;
    }
    __dump() {
        return this.forChildren(child => child.__dump());
    }
    _getChild(key) {
        return this._type.children?.[key]?.field;
    }
    forChildren(cb) {
        const value = {};
        const children = this._type.children;
        for (const key in children) {
            value[key] = cb(children[key].field, key);
        }
        return value;
    }
}
