import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import TaskEditModal from "Pages/Task/TaskEdit/TaskEditModal";
import { Button } from "Components/Atoms/Button";
import moment from "moment";
import useConfirmHandler from "Hooks/useConfirmHandler";
import { translate } from "Services/Translator";
import { DeleteIcon, EditNoteIcon, IconPlus } from "Components/App/Icons";
import { useTheme } from "@f2w/theme";
import styled, { css } from "styled-components";
import { SectionBar, SectionTitle } from "Pages/Dashboard/components/DashboardSection";
import { getTasks as getTasksApi, getTask as getTaskApi, removeTask as removeTaskApi, toggleTaskComplete as toggleTaskCompleteApi } from "Services/TaskManager";
import Checkbox from "Atoms/Checkbox";
import { Tabs, Tab } from "Atoms/Tabs";
const TaskList = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        margin-top: 16px;


    `;
});
const TaskListItemStyled = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        align-items: center;
        border-top: 1px solid #E1E2E6;
        padding: 24px 0;
        &:first-child {
            border-top: none;
        }
    `;
});
const TaskItem = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color:  ${palette.primary.$500};
    `;
});
const TaskCreatedBy = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        color:  ${palette.primary.$500};
    `;
});
const TaskNameDate = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    `;
});
const TaskDot = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        color:  ${palette.black};
        font-weight: 600;
    `;
});
const TaskActions = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        flex-direction: row;
        gap: 24px;
        justify-content: right;
    `;
});
const TaskAction = styled.button(() => {
    return css `
        border: none;
        background: none;
        color: #5B606B;
        padding: 0;
    `;
});
const TaskInfo = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        margin-left: 16px;
        flex: 1;
    `;
});
const TaskPriorityGroup = styled.span(() => {
    return css `
        display: flex;
        gap: 2px;
    `;
});
function getTaskPriorityColor($color) {
    if ($color === 'low') {
        return '#16AC88';
    }
    if ($color === 'normal') {
        return '#FCA524';
    }
    if ($color === 'high') {
        return '#FA3A3A';
    }
    return '#D7D8DB';
}
const TaskPriorityLine = styled.span(({ $color }) => {
    const { rem, palette } = useTheme();
    const color = getTaskPriorityColor($color);
    return css `
        width: 16px;
        height: 3px;
        border-radius: 64px;
        background: ${color};
    `;
});
const TaskPriority = ({ priority }) => {
    const $color = priority === 2 ? 'high' : priority === 1 ? 'normal' : 'low';
    return (_jsxs(TaskPriorityGroup, { children: [_jsx(TaskPriorityLine, { "$color": priority >= 0 ? $color : 'neutral' }), _jsx(TaskPriorityLine, { "$color": priority >= 1 ? $color : 'neutral' }), _jsx(TaskPriorityLine, { "$color": priority >= 2 ? $color : 'neutral' })] }));
};
const TaskListItem = ({ task, handleToggleTaskComplete, editTask, handleDeleteTask }) => {
    const color = getTaskPriorityColor(task.priority === 2 ? 'high' : task.priority === 1 ? 'normal' : 'low');
    return _jsxs(TaskListItemStyled, { children: [_jsx(Checkbox, { checked: task.achievedAt !== null, onChange: (e) => handleToggleTaskComplete(task, e.currentTarget.checked) }), _jsxs(TaskInfo, { children: [_jsx(TaskItem, { children: task.title }), _jsxs(TaskNameDate, { children: [_jsx(TaskCreatedBy, { children: task.assignee.name }), _jsx(TaskDot, { children: "\u2219" }), _jsx(TaskPriority, { priority: task.priority }), task.dueTo && _jsxs(_Fragment, { children: [_jsx(TaskDot, { children: "\u2219" }), _jsx("div", { style: { color }, children: moment(task.dueTo).format('DD.MM.YYYY') })] })] })] }), _jsxs(TaskActions, { children: [_jsx(TaskAction, { onClick: () => editTask(task.id), children: _jsx(EditNoteIcon, { size: 22 }) }), _jsx(TaskAction, { onClick: () => handleDeleteTask(task), children: _jsx(DeleteIcon, { size: 22 }) })] })] }, task.id);
};
const Tasks = (props) => {
    const getTasks = props.getTasks || getTasksApi;
    const getTask = props.getTask || getTaskApi;
    const removeTask = props.removeTask || removeTaskApi;
    const toggleTaskComplete = props.toggleTaskComplete || toggleTaskCompleteApi;
    const [selectedTask, setSelectedTask] = useState(null);
    const [taskModalShown, showTaskModal] = useState(false);
    const [assignedTasks, setAssignedAssignedTasks] = useState(undefined);
    const [createdTasks, setCreatedTasks] = useState(undefined);
    const refreshData = async () => {
        const tasksGroup = await getTasks();
        setAssignedAssignedTasks(tasksGroup.assigned);
        setCreatedTasks(tasksGroup.created);
    };
    const createTask = () => {
        setSelectedTask(null);
        showTaskModal(true);
    };
    const editTask = async (taskId) => {
        const task = await getTask(taskId);
        setSelectedTask(task);
        showTaskModal(true);
    };
    const handleToggleTaskComplete = async (task, complete) => {
        task.achievedAt = complete ? moment().toDate() : null;
        setAssignedAssignedTasks([...assignedTasks]);
        setCreatedTasks([...createdTasks]);
        return toggleTaskComplete(task.id, complete);
    };
    const handleDeleteTask = useConfirmHandler(async (task) => {
        await removeTask(task.id);
        refreshData();
    }, translate('task.message.deleteConfirmation'));
    const hideTaskModal = () => {
        setSelectedTask(null);
        showTaskModal(false);
        refreshData();
    };
    useEffect(() => {
        refreshData();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(SectionBar, { children: [_jsxs(SectionTitle, { children: [translate('dashboard.sections.tasks.title'), " ", props.helpText] }), _jsxs(Button, { "$size": "lg", onClick: () => createTask(), children: [_jsx(IconPlus, {}), translate('dashboard.sections.tasks.action.create')] })] }), _jsxs(Tabs, { id: "tasks-tabs", defaultActiveKey: "assigned", children: [_jsx(Tab, { eventKey: "assigned", title: translate('task.section.assigned'), children: _jsxs(TaskList, { style: { maxHeight: 480, overflowY: 'auto', paddingRight: 30 }, children: [assignedTasks && assignedTasks.map((task) => _jsx(TaskListItem, { task: task, handleToggleTaskComplete: handleToggleTaskComplete, editTask: editTask, handleDeleteTask: handleDeleteTask }, task.id)), assignedTasks && assignedTasks.length === 0 && _jsx("div", { children: translate('dashboard.sections.tasks.message.noTasks') })] }) }), _jsx(Tab, { eventKey: "created", title: translate('task.section.created'), children: _jsxs(TaskList, { style: { maxHeight: 480, overflowY: 'auto', paddingRight: 30 }, children: [createdTasks && createdTasks.map((task) => _jsx(TaskListItem, { task: task, handleToggleTaskComplete: handleToggleTaskComplete, editTask: editTask, handleDeleteTask: handleDeleteTask }, task.id)), createdTasks && createdTasks.length === 0 && _jsx("div", { children: translate('dashboard.sections.tasks.message.noTasks') })] }) })] }), taskModalShown && _jsx(TaskEditModal, { task: selectedTask, onHide: hideTaskModal })] }));
};
export default Tasks;
