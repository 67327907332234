import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ModalNew } from "Components/Modal";
import { Button, ButtonToolbar, FlexCol, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "Components/Atoms";
import { translate } from "Services/Translator";
import { DateType, MoneyType } from "@f2w/form";
import { Section } from "Atoms/Layout/components";
export const FinancialAccountStatementModal = ({ financialAccountId, financialAccountName, calculationId, propertyName, periodFrom, periodTo, onClose, getFinancialAccountStatement }) => {
    const [financialAccountStatement, setFinancialAccountStatement] = React.useState();
    React.useEffect(() => {
        if (financialAccountId) {
            getFinancialAccountStatement(financialAccountId, calculationId, periodFrom, periodTo)
                .then(res => setFinancialAccountStatement(res));
        }
        else {
            setFinancialAccountStatement(undefined);
        }
    }, [financialAccountId]);
    if (!financialAccountId || financialAccountStatement === undefined) {
        return _jsx(_Fragment, {});
    }
    const { accountingRecords, debitTotal, creditTotal, balanceTotal } = financialAccountStatement;
    const accountingRecordTable = (_jsxs(Section.Table, { stickyHead: 0, colSpan: 6, variant: 'content', size: 'sm', children: [_jsxs(Thead, { children: [_jsx(Th, { style: { fontWeight: 'bold' }, children: translate('date') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('accountingRecord.counterAccountPosition') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('accountingRecord.label') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('accountingRecord.debit') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('accountingRecord.credit') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('accountingRecord.balance') })] }), _jsx(Tbody, { children: accountingRecords.map(accountingRecord => (_jsxs(Tr, { children: [_jsx(Td, { children: DateType.format(accountingRecord.date) }), _jsx(Td, { children: accountingRecord.counterAccountPosition }), _jsx(Td, { children: accountingRecord.label }), _jsx(Td, { children: accountingRecord.debitAmount !== null ? MoneyType.format(accountingRecord.debitAmount) : '' }), _jsx(Td, { children: accountingRecord.creditAmount !== null ? MoneyType.format(accountingRecord.creditAmount) : '' }), _jsx(Td, { children: MoneyType.format(accountingRecord.balance) })] }, accountingRecord.id))) }), _jsx(Tfoot, { children: _jsxs(Tr, { children: [_jsx(Td, { style: { backgroundColor: '#F4F4F4', fontWeight: 'bold' }, colSpan: 2 }), _jsxs(Td, { style: { backgroundColor: '#F4F4F4', fontWeight: 'bold', textAlign: 'end' }, children: [translate('total'), ":"] }), _jsx(Td, { style: { backgroundColor: '#F4F4F4', fontWeight: 'bold' }, children: MoneyType.format(debitTotal) }), _jsx(Td, { style: { backgroundColor: '#F4F4F4', fontWeight: 'bold' }, children: MoneyType.format(creditTotal) }), _jsx(Td, { style: { backgroundColor: '#F4F4F4', fontWeight: 'bold' }, children: MoneyType.format(balanceTotal) })] }) })] }));
    return (_jsxs(ModalNew, { show: true, onHide: onClose, size: { maxWidth: '80vw', maxHeight: '80vh' }, children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onClose, children: _jsx(ModalNew.Title, { children: translate('financialAccountStatement.title', { financialAccountName }) }) }), _jsxs(ModalNew.Body, { children: [_jsx(FlexCol, { gap: 24, children: _jsx(FlexCol, { children: _jsxs(Text, { body: 500, children: [translate('accountingRecord.financialAccount'), ": ", financialAccountName] }) }) }), _jsx(FlexCol, { gap: 24, children: _jsx(FlexCol, { children: _jsxs(Text, { body: 500, children: [translate('baseData.property'), ": ", propertyName] }) }) }), _jsx(FlexCol, { gap: 24, style: { marginTop: 15 }, children: _jsx(FlexCol, { children: accountingRecordTable }) })] }), _jsx(ModalNew.Footer, { children: _jsx(ButtonToolbar, { children: _jsx(Button, { variant: 'outline', color: 'neutral', onClick: onClose, children: translate('btn.close') }) }) })] }));
};
