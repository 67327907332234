import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
const AssetContext = React.createContext({
    rentalObjects: [],
    rooms: new Map(),
    modalOpen: false,
    closeModal: () => { },
    openForm: () => { }
});
const AssetProvider = ({ children, assetServices: { getAssetById, getAssetsDropdownData } }) => {
    const [assetId, setAssetId] = React.useState();
    const [initialFormData, setInitialFormData] = React.useState();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [rentalObjects, setRentalObjects] = React.useState([]);
    const [rooms, setRooms] = React.useState(new Map());
    React.useEffect(() => {
        getAssetsDropdownData().then(response => {
            setRentalObjects(response.rentalObjects.map(rentalObject => ({
                value: rentalObject.id,
                label: rentalObject.name
            })));
            const roomMap = new Map();
            response.rooms.forEach(room => {
                if (roomMap.has(room.rentalObjectId)) {
                    const roomsInMap = roomMap.get(room.rentalObjectId);
                    roomsInMap.push({
                        value: room.id,
                        label: room.name
                    });
                    roomMap.set(room.rentalObjectId, roomsInMap);
                }
                else {
                    roomMap.set(room.rentalObjectId, [{
                            value: room.id,
                            label: room.name
                        }]);
                }
            });
            setRooms(roomMap);
        });
    }, []);
    const openForm = React.useCallback((assetId) => {
        if (assetId) {
            getAssetById(assetId)
                .then(async (data) => {
                await setAssetId(assetId);
                await setInitialFormData(data);
                await setModalOpen(true);
            });
        }
        else {
            setAssetId(null);
            setInitialFormData(undefined);
            setModalOpen(true);
        }
    }, []);
    const onClose = React.useCallback(() => setModalOpen(false), []);
    return (_jsx(AssetContext.Provider, { value: { initialFormData, rentalObjects, rooms, modalOpen, closeModal: onClose, assetId, openForm }, children: children }));
};
export const useAssetContext = () => React.useContext(AssetContext);
export default AssetProvider;
