import { getGlobalContext } from 'Services/GlobalContext';
import { isStorybookEnv } from '@f2w/utils';
export var ProductFeatureType;
(function (ProductFeatureType) {
    ProductFeatureType["TEMPLATES"] = "TEMPLATES";
    ProductFeatureType["RENT_OVERVIEW"] = "RENT_OVERVIEW";
    ProductFeatureType["USERS"] = "USERS";
    ProductFeatureType["ASSETS"] = "ASSETS";
    ProductFeatureType["SERVICE_PROVIDERS"] = "SERVICE_PROVIDERS";
    ProductFeatureType["DAMAGES_AND_REPAIRS"] = "DAMAGES_AND_REPAIRS";
    ProductFeatureType["TASKS_IN_DASHBOARD"] = "TASKS_IN_DASHBOARD";
    ProductFeatureType["REMINDERS"] = "REMINDERS";
    ProductFeatureType["RENT_POTENTIAL_CALCULATOR"] = "RENT_POTENTIAL_CALCULATOR";
})(ProductFeatureType || (ProductFeatureType = {}));
export function hasProductFeature(productFeature) {
    const { productFeatures = [] } = getGlobalContext();
    return productFeatures?.includes(productFeature) || isStorybookEnv();
}
