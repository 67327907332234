import { jsx as _jsx } from "react/jsx-runtime";
import { ContentTemplateNew, SplitViewTemplate } from "@f2w/view";
import { GeneralTab } from "Components/ObjectViewModal/components/RoomView/GeneralTab";
export const RoomTemplate = (props) => {
    return _jsx(SplitViewTemplate, { ...props, Template: ContentTemplateNew, leftSide: {
            groups: ['general'],
            Template: props => _jsx(GeneralTab, { ...props })
        }, rightSide: {
            groups: ['assets'],
        } });
};
