import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import { translate } from 'Services/Translator';
import { useMemo } from 'react';
const YearSelect = ({ min, max, ...props }) => {
    const options = useMemo(() => {
        const now = new Date();
        const maxYear = max || now.getFullYear();
        const minYear = min || maxYear - 10;
        if (minYear > maxYear)
            return [];
        return Array.from({ length: maxYear - minYear + 1 }, (_, i) => {
            const year = maxYear - i;
            return { value: year, label: year.toString() };
        });
    }, [min, max]);
    return (_jsx(Select, { placeholder: translate('Year'), options: options, ...props }));
};
export default YearSelect;
