import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import { translate } from "Services/Translator";
const options = [
    { value: "_01", label: translate('ancillaryExpenseAccount.costType.heating') },
    { value: "_02", label: translate('ancillaryExpenseAccount.costType.hot_water') },
    { value: "_03", label: translate('ancillaryExpenseAccount.costType.ventilation') },
    { value: "_04", label: translate('ancillaryExpenseAccount.costType.operating_costs') },
    { value: "_05", label: translate('ancillaryExpenseAccount.costType.cold_water') },
    { value: "_06", label: translate('ancillaryExpenseAccount.costType.electricity') },
    { value: "_07", label: translate('ancillaryExpenseAccount.costType.cold') },
    { value: "_08", label: translate('ancillaryExpenseAccount.costType.user_change') },
    { value: "_09", label: translate('ancillaryExpenseAccount.costType.heating_hot_water') },
    { value: "_10", label: translate('ancillaryExpenseAccount.costType.sewage') },
];
const CostTypeSelect = ({ ...props }) => {
    return (_jsx(Select, { options: options, menuPortalTarget: document.body, ...props }));
};
export default CostTypeSelect;
