import { jsx as _jsx } from "react/jsx-runtime";
import { Schema, SimpleType } from '../core';
import { FormControl } from 'Atoms/Input';
export class BooleanType extends SimpleType {
    constructor(options, parent) {
        super(options, parent, Schema.boolean());
    }
    _renderLabel(props = {}) {
        return null;
    }
    WidgetComponent({ value, ...props }) {
        return _jsx(FormControl.Checkbox, { ...{
                checked: !!this.field.value,
                ...props,
                help: this.options.help,
                children: this.options.content ?? this.field.label,
            } });
    }
}
