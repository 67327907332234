import { useFormModal } from "@f2w/form";
import { MortgageType } from "../types/MortgageType";
import { translate } from "Services/Translator";
import * as MortgageServices from "../services/mortgageServices";
export const useMortgageForm = ({ onSaved, mortgageServices = MortgageServices }) => {
    return useFormModal({
        name: 'mortgage-form',
        type: () => new MortgageType(),
        title: ({ values }) => values.id
            ? translate('mortgage.form.title.edit')
            : translate('mortgage.form.title.create'),
        onLoad: async (id) => {
            if (!id) {
                return undefined;
            }
            return await mortgageServices.getMortgageById(id);
        },
        onSave: (values) => {
            const mortgageId = values.id;
            if (mortgageId) {
                return mortgageServices.updateMortgage(mortgageId, values)
                    .then(() => onSaved());
            }
            else {
                return mortgageServices.createMortgage(values)
                    .then(() => onSaved());
            }
        }
    });
};
