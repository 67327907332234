import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import { translateAdmin } from "Services/App";
import { Div } from "Atoms/Layout";
import React from "react";
import { PatchNoteDataTable } from "Pages/Administration/PatchNotes/table/PatchNoteDataTable";
import { useUpsertPatchNotesForm } from "Pages/Administration/PatchNotes/hooks/useUpsertPatchNotesForm";
import { FormModal } from "@f2w/form";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { ViewPatchNote } from "Pages/Administration/PatchNotes/components/ViewPatchNote";
export const PatchNotesPage = ({ patchNoteServices }) => {
    const ref = React.useRef(undefined);
    const { success } = useToastDispatcherApi();
    const instance = PatchNoteDataTable.use({
        getPatchNotes: patchNoteServices.getPatchNotes,
        createPatchNote: () => form.openForm(),
        editPatchNote: (id) => form.openForm(id),
        viewPatchNote: (id) => ref.current?.(id),
        deletePatchNote: (id) => {
            patchNoteServices.deletePatchNote(id)
                .then(() => {
                instance.refresh();
                success({
                    message: translateAdmin('patchNotes.delete.success')
                });
            });
        },
        publishPatchNote: (id) => {
            patchNoteServices.publishPatchNote(id)
                .then(() => {
                instance.refresh();
                success({
                    message: translateAdmin('patchNotes.publish.success')
                });
            });
        },
        unpublishPatchNote: (id) => {
            patchNoteServices.unpublishPatchNote(id)
                .then(() => {
                instance.refresh();
                success({
                    message: translateAdmin('patchNotes.unpublish.success')
                });
            });
        }
    });
    const form = useUpsertPatchNotesForm({
        getPatchNote: patchNoteServices.getPatchNote,
        createPatchNote: patchNoteServices.createPatchNote,
        updatePatchNote: patchNoteServices.updatePatchNote,
        getFile: patchNoteServices.getFile,
        onSave: () => instance.refresh()
    });
    return (_jsxs(Div, { p: 50, h: '100%', children: [_jsx(PageOverview, { instance: instance, inlineFilters: true, title: translateAdmin('patchNotes.title') }), _jsx(FormModal, { modal: form }), _jsx(ViewPatchNote, { loadDataReference: ref, getPatchNote: patchNoteServices.getPatchNote })] }));
};
