import styled from 'styled-components';
import { FlexRow } from 'Components/Atoms';
import { Header, Section } from 'Atoms/Layout/components';
const CalculationWizardRoot = styled.div `
  --fw-padding-y: 10px;

  ${Section.Base} {
    &:first-child {
      padding-top: 0;
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
    & + ${Header.Base} {
      margin-top: calc(2 * var(--fw-padding-y));
    }
  }

  ${Header.Base} {

  }
`;
export const CalculationWizardStyled = Object.assign(CalculationWizardRoot, {
    PropertyCard: styled(FlexRow) `
          background: ${p => p.theme.palette.neutral.$50};
          border-radius: 20px;
        `,
});
