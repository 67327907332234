import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import moment from 'moment';
import { formatAmount } from 'Services/NumberHelper';
import { CalculationServices } from '../services';
import { DataTable } from '@f2w/data-table';
import { FlexRow, Input } from 'Components/Atoms';
const BaseTable = DataTable.factory()(() => ({
    tableId: 'CalculationFees',
    manualControl: false,
    showEmpty: true,
    useGlobalFilter: {},
    useRowState: {
        initialRowStateAccessor: (...args) => {
            return {};
        },
    },
    usePagination: {},
    initialState: {
        pageSize: 50
    },
    columns: {
        rentalObject: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.rentalObject'),
            accessor: v => v.rentalObject.name,
            Cell: ({ value }) => value,
            useSortBy: {}
        },
        rentalObjectType: {
            Header: translate('baseData.rentalObject.type'),
            accessor: v => translate(`rentalObjectType.${v.rentalObjectType}`),
            Cell: ({ value }) => _jsx(_Fragment, { children: value }),
            useSortBy: {},
            useFilters: {
                selectFilter: { isMulti: true }
            }
        },
        tenants: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.tenants'),
            accessor: v => v.tenants.map(tenant => tenant.name).join(' & '),
            Cell: ({ value }) => _jsx("div", { children: value }),
            useSortBy: {},
        },
        activeContractStartDate: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.startDate'),
            Cell: ({ value }) => value
                ? moment(value).format('DD.MM.YYYY')
                : translate('notAvailable'),
            useSortBy: {},
        },
        activeContractEndDate: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.endDate'),
            Cell: ({ value }) => value
                ? moment(value).format('DD.MM.YYYY')
                : translate('notAvailable'),
            useSortBy: {},
        },
        ancillaryExpenseFloatingAmount: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.floatingAmount'),
            Cell: ({ value, editMode, updateApi, row: { id } }) => {
                const { ancillaryExpenseFloatingAmount = value, } = updateApi.getData(id);
                if (!editMode) {
                    return _jsx(FlexRow, { justifyFlexEnd: true, children: ancillaryExpenseFloatingAmount !== undefined && ancillaryExpenseFloatingAmount !== null ? formatAmount(ancillaryExpenseFloatingAmount) : translate('notAvailable') });
                }
                return (_jsx(Input, { type: 'number', step: 0.01, "$size": 'sm', prefix: 'CHF', value: ancillaryExpenseFloatingAmount ?? 0, onChange: (value) => {
                        updateApi.update(id, {
                            ancillaryExpenseFloatingAmount: value
                        });
                    } }));
            }
        },
        ancillaryExpenseFlatAmount: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.flatAmount'),
            Cell: ({ value }) => _jsx(FlexRow, { justifyFlexEnd: true, children: formatAmount(value) })
        }
    },
}));
export const CalculationFeesTable = Object.assign(BaseTable, {
    use: ({ calculationId, calculationServices = CalculationServices, reloadDistributions, setEditMode, editMode }) => {
        const [data, setData] = React.useState([]);
        const loadData = React.useCallback(() => {
            table.setLoading(true);
            calculationServices.listCalculationFees(calculationId)
                .then(results => {
                setData(results);
            })
                .finally(() => {
                table.setLoading(false);
            });
        }, []);
        React.useEffect(() => {
            loadData();
        }, []);
        const table = BaseTable.useCreateTable({
            data: data,
            refresh: () => loadData(),
            calculationServices,
            calculationId,
            reloadDistributions,
            setEditMode,
            editMode
        });
        return table;
    },
});
