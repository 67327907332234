import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import FiscalYearServices from "./services/fiscalYearServices";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import { useFiscalYearTable } from "Pages/Accounting/FiscalYear/hooks/useFiscalYearTable";
import { FormModal, useFormModal } from "@f2w/form";
import FiscalYearType from "Pages/Accounting/FiscalYear/forms/FiscalYearType";
import { translate } from "Services/Translator";
import { useToastDispatcherApi } from "Components/Dispatcher";
export const FiscalYearPage = ({ fiscalYearServices = FiscalYearServices, onChange, accountingSelectedFiscalYearId, selectedFiscalYearId }) => {
    const { warning } = useToastDispatcherApi();
    const modalForm = useFormModal({
        name: 'fiscal-year-form',
        title: ({ values }) => {
            return values?.id
                ? translate('accounting.fiscalYear.form.title.edit')
                : translate('accounting.fiscalYear.form.title.create');
        },
        type: () => new FiscalYearType(),
        onLoad: async (id) => {
            if (id) {
                const data = await fiscalYearServices.getFiscalYearById(id);
                return {
                    id,
                    label: data.label,
                    starts: data.starts,
                    ends: data.ends,
                    transferBalances: false
                };
            }
            return null;
        },
        onSave: async (data) => {
            const promise = data.id
                ? fiscalYearServices.updateFiscalYear(data.id, data)
                : fiscalYearServices.createFiscalYear(data);
            return promise
                .then(data => {
                onSave();
                if (data.showOpeningBalanceWarning) {
                    warning({
                        message: translate('fiscalYear.refreshOpeningBalances.noPreviousYear.warning')
                    });
                }
            });
        }
    });
    const { instance } = useFiscalYearTable(fiscalYearServices, () => { modalForm.openNewForm(); }, (id) => { modalForm.openForm(id); }, (deletedId) => {
        if (deletedId === selectedFiscalYearId) {
            window.location.reload();
        }
        else {
            onChange?.();
        }
    }, accountingSelectedFiscalYearId);
    const onSave = React.useCallback(() => {
        onChange?.();
        instance.refresh();
    }, [instance]);
    return (_jsxs("div", { children: [_jsx(PageOverview, { title: null, instance: instance, inlineFilters: true, rootStyles: { padding: 0 } }), _jsx(FormModal, { modal: modalForm })] }));
};
