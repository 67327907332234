import { useFormModal } from "@f2w/form";
import { CreateAssetFromRoomType } from "Pages/Asset/forms/AssetType";
import { translate } from "Services/Translator";
export const useCreateAssetFromRoomForm = ({ roomId, rentalObjectId, createAsset, onSave }) => useFormModal({
    name: 'create-asset-in-room',
    title: translate('asset.new'),
    type: () => new CreateAssetFromRoomType(),
    onSave: async (values) => {
        return createAsset({
            roomId,
            rentalObjectId,
            ...values
        })
            .then(id => onSave({
            id,
            name: values.name
        }));
    }
});
