import React from "react";
import { useObjectViewModal } from "Components/ObjectViewModal";
import { useRentalObjectView } from "Components/ObjectViewModal/hooks/useRentalObjectView";
export const RentalObjectView = ({ rentalObjectId, getRentalObjectDetails, updateRentalObject, waitingListServices, handoverId, }) => {
    const { updateRentalObjectNameForBreadcrumb } = useObjectViewModal();
    const view = useRentalObjectView(getRentalObjectDetails, updateRentalObject, waitingListServices, updateRentalObjectNameForBreadcrumb, handoverId);
    React.useEffect(() => {
        view.load({ id: rentalObjectId });
    }, [rentalObjectId]);
    return view.render();
};
