import { UseStyled } from "@f2w/theme";
import { ModalNew as Modal } from "Components/Modal";
const ModalHeaderBase = UseStyled.styled('div', ({}));
const BreadcrumbWrapper = UseStyled.styled('div', ({ theme: { palette } }) => ({
    display: 'flex',
    color: palette.primary.$500,
    fontSize: '1.5rem'
}));
const Breadcrumb = UseStyled.styled('div', () => ({
    display: 'flex',
}));
const ContractBreadcrumb = UseStyled.styled('div', ({ theme: { palette } }) => ({
    color: palette.neutral.$900
}));
const BreadcrumbTitle = UseStyled.styled('a', () => ({
    cursor: 'pointer'
}));
const BreadcrumbDivider = UseStyled.styled('div', ({ theme: { palette } }) => ({
    marginLeft: '1rem',
    marginRight: '1rem',
    color: palette.dark.$900
}));
const Title = UseStyled.styled(Modal.Title, () => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
}));
const RightHeaderBlock = UseStyled.styled('div', () => ({
    display: 'flex',
    gap: '8px'
}));
const ModalHeader = Object.assign(ModalHeaderBase, {
    BreadcrumbWrapper,
    Breadcrumb,
    BreadcrumbTitle,
    BreadcrumbDivider,
    Title,
    RightHeaderBlock,
    ContractBreadcrumb
});
export default ModalHeader;
