import { isFunction } from '@f2w/utils';
export function functionalUpdateVoid(prev, updater) {
    isFunction(updater) && updater(prev);
    return {
        ...prev,
    };
}
export function functionalUpdate(updater, old) {
    return isFunction(updater) ? updater(old) : {
        ...old,
        ...updater,
    };
}
export function isSameObject(obj1, obj2) {
    try {
        const json1 = JSON.stringify(obj1);
        const json2 = JSON.stringify(obj2);
        return json1 === json2;
    }
    catch (e) { }
    return false;
}
