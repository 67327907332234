import generateRoute from "Services/Router";
import client from "Utils/client";
const getAccountWideAvailableRoles = () => {
    const route = generateRoute('api.users.accountAvailableRoles');
    return client
        .get(route)
        .then(response => response.data);
};
const getUserById = (id) => {
    const route = generateRoute('api.users.byId', {
        user: id
    });
    return client
        .get(route)
        .then(response => response.data);
};
const createUser = (upsertUserDto) => {
    const route = generateRoute('api.users.create');
    return client
        .post(route, upsertUserDto);
};
const updateUser = (id, upsertUserDto) => {
    const route = generateRoute('api.users.update', {
        user: id
    });
    if (upsertUserDto.mandates === null) {
        upsertUserDto.mandates = [];
    }
    return client
        .put(route, upsertUserDto);
};
const getUserDeletionSummary = (userId) => {
    const route = generateRoute('api.users.deletionSummary', {
        user: userId
    });
    return client
        .get(route)
        .then(response => response.data);
};
const deleteUser = (userId, transferToUserId) => {
    const route = generateRoute('api.users.delete', {
        user: userId,
        transferToUserId: transferToUserId ?? null
    });
    return client.delete(route);
};
export default {
    getAccountWideAvailableRoles,
    getUserById,
    createUser,
    updateUser,
    getUserDeletionSummary,
    deleteUser
};
