import client from "Utils/client";
import generateRoute from "Services/Router";
export var PersonServices;
(function (PersonServices) {
    PersonServices.listPersonsForDropdown = async (withContractedRentalObjects = false) => {
        const listPersonsForDropdownRoute = generateRoute("api.people.dropdown", {
            withContractedRentalObjects: withContractedRentalObjects ? 1 : 0
        });
        return client
            .get(listPersonsForDropdownRoute)
            .then(response => response.data);
    };
})(PersonServices || (PersonServices = {}));
export default PersonServices;
