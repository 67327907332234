import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { translate } from 'Services/Translator';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { useMortgageTable } from './hooks/useMortgageTable';
import * as MortgageServices from './services/mortgageServices';
import { PaymentVsRentChart, RefinancingChart } from './components/MortgageChart';
import styled from 'styled-components';
import { Button, ButtonGroup, Title } from 'Components/Atoms';
import { useModalDispatcherConfirm, useToastDispatcherApi } from "Components/Dispatcher";
import { MortgageStatsGroup } from './types';
import { Header } from 'Atoms/Layout/components';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { useMortgageForm } from 'Pages/Mortgage/hooks/useMortgageForm';
import { FormModal } from '@f2w/form';
const MortgagePage = ({ services = MortgageServices, }) => {
    const { openViewModal } = useObjectViewModalContext();
    const toastApi = useToastDispatcherApi();
    const onDeleteConfirm = useModalDispatcherConfirm({
        onConfirm: async (id) => {
            try {
                await services.deleteMortgage(id);
            }
            catch (e) {
                toastApi.error({
                    message: translate('mortgage.delete.error'),
                    timeout: 5,
                    showRemove: true
                });
                console.error(e);
            }
            finally {
                table.refresh();
            }
        },
        message: translate('mortgage.delete.message'),
        help: translate('mortgage.delete.help'),
    });
    const mortgageForm = useMortgageForm({
        onSaved: () => table.refresh(),
        mortgageServices: services,
    });
    const [group, setGroup] = useState(MortgageStatsGroup.SemiAnnually);
    const table = useMortgageTable({
        services,
        onDeleteConfirm,
        onPropertyClick: openViewModal(ActiveView.PROPERTY),
        onCreateMortgage: mortgageForm.openForm,
        onEditMortgage: (id) => mortgageForm.openForm(id),
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: table, title: translate('mortgage.overview.title'), children: table.pageRows.length === 0 ? null : _jsxs(ChartRoot, { children: [_jsxs(ChartContent, { children: [_jsxs(Header.Split, { children: [_jsx(Title, { body: 500, children: translate('mortgage.stats.refinancing.title') }), _jsx(ButtonGroup, { size: 'sm', children: Object.values(MortgageStatsGroup).map((value, index) => (_jsx(Button, { outlineFill: true, active: group === value, onClick: () => setGroup(value), label: translate(`mortgage.stats.group.${value}`) }, `action-${index}`))) })] }), _jsx("div", { className: 'chart-offset', children: _jsx(RefinancingChart, { loadData: services.getMortgagesStats, offset: ChartOffset, group: group }) })] }), _jsxs(ChartContent, { children: [_jsx(Header.Split, { children: _jsx(Title, { body: 500, children: translate('mortgage.stats.paymentVsRent.title') }) }), _jsx("div", { className: 'chart-offset', children: _jsx(PaymentVsRentChart, { loadData: services.getMortgagesRentStats, offset: ChartOffset }) })] })] }) }), _jsx(FormModal, { modal: mortgageForm })] }));
};
const ChartOffset = {
    padding: { left: 20, right: 10 },
    margin: { top: 0, right: 0, left: 40, bottom: 100 },
};
const ChartContent = styled.div `
    display: flex;
    flex-direction: column;
`;
const ChartRoot = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  //align-items: stretch;
  //justify-content: stretch;
  gap: 40px;
  --fw-padding-y: 0px;

  ${ChartContent} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoint.up('md')} {
    flex-direction: row;
    ${ChartContent} {
        flex: 1 1 50%;
        max-width: 50%;
    }
  }

  .chart-offset {
    margin-left: -40px;
    width: 100%;
    //width: calc(100% + 40px);
  }

  ${Header.Base} {
    min-height: 40px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;
export default MortgagePage;
