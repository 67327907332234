import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Div, FlexCol, FlexRow, Text } from "Components/Atoms";
import { translate } from "Services/Translator";
import { DataTable } from "@f2w/data-table";
import { useCreditorAccountingRecordsTable } from "Pages/Creditor/components/CreditorView/hooks/useCreditorAccountingRecordsTable";
export const CreditorAccountingRecords = ({ accountingRecords }) => {
    const { table } = useCreditorAccountingRecordsTable({
        accountingRecords: accountingRecords,
    });
    return (_jsxs(FlexCol, { children: [_jsx(Text, { large: 500, neutral: 500, children: _jsx(FlexRow, { alignCenter: true, children: translate('creditor.accounting') }) }), _jsx(Div, { pt: 16, pb: 16, children: _jsx(DataTable.SimpleTable, { filter: 'inline', variant: 'default', instance: table }) })] }));
};
