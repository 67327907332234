import { jsx as _jsx } from "react/jsx-runtime";
import { useEventCallback } from '@restart/hooks';
import { SimpleType } from '../core';
import { TextEditor } from 'Atoms/Input/controls/TextEditor';
import { TextArea } from 'Atoms/Input/controls/TextArea';
export class TextType extends SimpleType.ExtendString {
    constructor(options, parent) {
        super(options, parent);
    }
    WidgetComponent(props) {
        return _jsx(TextArea, { ...{
                ...props,
            } });
    }
}
export class EditorType extends SimpleType.ExtendString {
    constructor(options, parent) {
        super(options, parent);
    }
    _renderWidget(props) {
        const onChange = useEventCallback((value) => {
            this.field.setValue(value);
        });
        const onBlur = useEventCallback((value) => {
            this.field.setTouched(true, true);
        });
        return _jsx(TextEditor, { onChange: onChange, onBlur: onBlur, editorType: this.options.type || 'block', initialValue: this.field.initialValue });
    }
}
