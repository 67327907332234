import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { HandoverCostOverviewDataTable } from "Pages/Administration/Customers/table/HandoverCostOverviewDataTable";
import { translateAdmin } from "Services/App";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import { SafeAnchor } from "Atoms/SafeAnchor";
import { ActiveView } from "Pages/Administration/enums";
import { useActiveViewContext } from "Pages/Administration/context/ActiveViewContext";
import { Icon } from "Atoms/Icon";
import { UseStyled } from "@fw/theme";
import { css } from "styled-components";
import { Badge } from "Atoms/Badge";
const IconWrapper = UseStyled.styled(Div, ({ theme: { palette } }) => css `
    display: flex;
    width: 48px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background-color: ${palette.primary.$50};
    color: ${palette.primary.$500};
`);
export const HandoverCostOverviewPage = ({ handoverServices: { listHandoverCostOverview, dropInvoiceHandoverProtocols, invoiceHandoverProtocols, } }) => {
    const { setActiveView, setDetailsActiveView, additionalData } = useActiveViewContext();
    const instance = HandoverCostOverviewDataTable.use({
        listHandoverCostOverview,
        dropInvoiceHandoverProtocols,
        invoiceHandoverProtocols,
        customerId: additionalData.customerId
    });
    return (_jsxs(FlexCol, { gap: 40, p: 50, h: '100%', children: [_jsxs(FlexRow, { gap: 5, children: [_jsx(SafeAnchor, { onClick: () => {
                            setActiveView(ActiveView.CUSTOMERS);
                        }, children: translateAdmin('customers') }), _jsx("span", { children: "/" }), _jsx(SafeAnchor, { onClick: () => {
                            setDetailsActiveView(additionalData.customerId, ActiveView.CUSTOMER_DETAILS);
                        }, children: additionalData.customerName }), _jsx("span", { children: "/" }), translateAdmin('handoverApp')] }), _jsxs(FlexRow, { gap: 16, alignCenter: true, children: [_jsx(IconWrapper, { children: _jsx(Icon, { icon: 'link', size: 24 }) }), _jsx(Div, { style: { fontWeight: 'bold' }, children: translateAdmin('handoverApp.list') }), _jsx(Badge, { "$color": additionalData.integrationActive ? 'success' : 'danger', children: translateAdmin(`customer.integration.${additionalData.integrationActive ? 'active' : 'notActive'}`) })] }), _jsx(PageOverview, { inlineFilters: true, title: translateAdmin('handoverApp.list'), instance: instance })] }));
};
