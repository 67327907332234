import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useMemo } from 'react';
import styled from 'styled-components';
import { frc } from '@f2w/theme';
import classnames from 'classnames';
import { useTableTheme } from '../theme';
import { TableRow, TableRowSpacer, Tr } from './Row';
import { isFirstChildTypeOf, renderChildren, isNumLike, toNum } from '@f2w/utils';
import { Td } from './Cell';
const TheadRoot = styled.thead ``;
const TbodyRoot = styled.tbody ``;
const TfootRoot = styled.tfoot ``;
TheadRoot.displayName = "TableGroupHeadRoot";
TbodyRoot.displayName = "TableGroupBodyRoot";
TfootRoot.displayName = "TableGroupFootRoot";
export var TableGroupType;
(function (TableGroupType) {
    TableGroupType["HEAD"] = "head";
    TableGroupType["BODY"] = "body";
    TableGroupType["FOOT"] = "foot";
})(TableGroupType || (TableGroupType = {}));
const typeMap = {
    [TableGroupType.HEAD]: ({ Css, stickyHead }) => ({
        className: Css.groupHead,
        Component: TheadRoot,
        sticky: stickyHead,
        stickyProp: 'top',
    }),
    [TableGroupType.BODY]: ({ Css }) => ({
        className: Css.groupBody,
        Component: TbodyRoot,
        isBody: true,
    }),
    [TableGroupType.FOOT]: ({ Css, stickyFoot }) => ({
        className: Css.groupFoot,
        Component: TfootRoot,
        sticky: stickyFoot,
        stickyProp: 'bottom',
    }),
};
export const TableGroup = forwardRef(({ as: As, type = TableGroupType.BODY, expanded, expandable, sticky: initialSticky, ...props }, ref) => {
    if (isFirstChildTypeOf(props.children, TableGroup.displayName)) {
        return _jsx(React.Fragment, { children: props.children });
    }
    const theme = useTableTheme();
    const { Css, scroll, rowSpacing, rowDivider, EmptyRow, colSpan, HeaderRow } = theme;
    let { Component, className, isBody, stickyProp, sticky: _sticky } = typeMap[type]?.(theme);
    const { isSticky, stickyStyles } = useMemo(() => {
        let isSticky, stickyValue;
        const sticky = initialSticky ?? _sticky;
        if (stickyProp) {
            if (sticky != null) {
                if (isNumLike(sticky) || sticky) {
                    isSticky = true;
                    stickyValue = toNum(sticky);
                }
            }
            else if (scroll) {
                isSticky = true;
            }
        }
        return {
            isSticky,
            stickyStyles: stickyValue > 0 ? {
                [stickyProp]: stickyValue
            } : undefined
        };
    }, [initialSticky, scroll, _sticky]);
    props.className = classnames(props.className, Css.group, isSticky && Css.sticky, className, expandable ? Css.groupExpandable : Css.group2);
    let fillRow;
    if (React.Children.count(props.children) > 0) {
        if (!isFirstChildTypeOf(props.children, TableRow.displayName)) {
            props.children = _jsx(Tr, { bodyRow: isBody, children: props.children });
        }
        if (isBody) {
            fillRow = true;
            props.children = _jsxs(_Fragment, { children: [props.children, rowSpacing || rowDivider ? _jsx(TableRowSpacer, {}) : null] });
        }
        else {
            props.children = renderChildren(props.children, (row, { displayName, first, last }) => {
                if (displayName?.startsWith(TableRow.displayName)) {
                    return React.cloneElement(row, {
                        bodyRow: false,
                    });
                }
                return row;
            });
        }
    }
    else {
        props.children = _jsx(Tr, { bodyRow: false, children: _jsx(Td, { type: 'content', style: { width: 0 }, colSpan: true, align: 'center', valign: 'middle', children: EmptyRow?.(theme) }) });
    }
    if (stickyStyles) {
        props.style = {
            ...props.style,
            ...stickyStyles,
        };
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Component, { "data-group": type.toLowerCase() || undefined, "data-sticky": isSticky ? stickyProp : undefined, ...props, ref: ref, children: [props.children, HeaderRow && type === TableGroupType.HEAD && HeaderRow?.(theme)] }), fillRow && _jsx(TbodyRoot, { className: Css.groupFill, children: _jsx(Tr.Root, { children: _jsx(Td.Root, { colSpan: colSpan, children: '' }) }) })] }));
});
TableGroup.displayName = "TableGroup";
export const Thead = frc(`${TableGroup.displayName}Head`, (props, ref) => (_jsx(TableGroup, { type: TableGroupType.HEAD, ...props, ref: ref })));
export const Tfoot = frc(`${TableGroup.displayName}Foot`, (props, ref) => (_jsx(TableGroup, { type: TableGroupType.FOOT, ...props, ref: ref })));
export const Tbody = frc(`${TableGroup.displayName}Body`, (props, ref) => (_jsx(TableGroup, { type: TableGroupType.BODY, ...props, ref: ref })));
