import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { UpsertPatchNotesType } from "Pages/Administration/PatchNotes/types/UpsertPatchNotesType";
import { translate, translateAdmin } from "Services/App";
import { Button } from "Atoms/Button";
export const useUpsertPatchNotesForm = ({ createPatchNote, updatePatchNote, getPatchNote, getFile, onSave }) => useFormModal({
    name: 'upsert-patch-notes-form',
    type: () => new UpsertPatchNotesType(),
    title: ({ values }) => values.id
        ? translateAdmin('patchNotes.edit.title')
        : translateAdmin('patchNotes.create.title'),
    onLoad: async (id) => {
        const data = await getPatchNote(id);
        const preparedImages = [];
        for (const image of data.images) {
            const file = await getFile(image.path, image.name, image.mimeType);
            preparedImages.push({
                id: image.id,
                file: file,
                path: image.path,
                publicName: image.name
            });
        }
        return {
            id,
            ...data,
            preparedImages
        };
    },
    onSave: async (values, _helper, type) => {
        const newImages = type.images
            .filter(image => typeof image.id === 'string')
            .map(image => image.file);
        const existingImageIds = type.images
            .filter(image => typeof image.id === 'number')
            .map(image => Number(image.id));
        if (values.id) {
            return updatePatchNote(values.id, {
                title: values.title,
                leadText: values.leadText,
                features: values.features,
                bugFixes: values.bugFixes,
                existingImageIds: existingImageIds
            }, newImages).then(onSave);
        }
        return createPatchNote({
            title: values.title,
            leadText: values.leadText,
            features: values.features,
            bugFixes: values.bugFixes,
            existingImageIds: []
        }, newImages).then(onSave);
    },
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('btn.save') })] });
    },
});
