import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { renderContent } from 'Components/Base';
import { isFunction, isNotEmptyObject, isString, tryFunction } from 'Utils/types';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { FormError, FormProvider, } from '@f2w/form';
import { isDevEnv, setDebugVariable } from '@f2w/utils';
import { ViewGroup } from './ViewGroup';
import { SpinnerNew } from 'Components/Atoms/Spinner';
import { DefaultContentTemplate, DefaultFormTemplate } from '../components/templates';
import * as f from 'formik';
import { handleResponseError } from 'Utils/client';
;
export class AbstractViewType {
    type;
    _options;
    _dispatcher;
    groups = Object.create(null);
    constructor(type, _options, groups, _dispatchUpdate, _dispatcher) {
        this.type = type;
        this._options = _options;
        this._dispatcher = _dispatcher;
        const state = this._state = {
            debug: isDevEnv(),
            data: {},
            enableForm: _options.enableForms ?? false,
            activeGroupName: _options.initialView,
            state: {
                ..._options.defaultState,
            },
        };
        this._dispatchUpdate = (update) => {
            update && Object.assign(state, isFunction(update) ? update(state) : update);
            _dispatchUpdate();
        };
        this.type.createOld({
            name: _options.id,
            context: {
                form: null,
                get data() {
                    return state.data || {};
                },
            },
        });
        for (const id of Object.keys(groups)) {
            this.groups[id] = new ViewGroup({ id, ...groups[id] }, this);
        }
        if (_options.enableForms) {
            this.load();
        }
        this.Form = this.Form.bind(this);
        this.setState = this.setState.bind(this);
        this.updateState = this.updateState.bind(this);
        this.setData = this.setData.bind(this);
        this.updateData = this.updateData.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.openForm = this.openForm.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.get = this.get.bind(this);
        this.has = this.has.bind(this);
        this.pick = this.pick.bind(this);
        this.omit = this.omit.bind(this);
    }
    get options() {
        return this._options;
    }
    get dispatcher() {
        return this._dispatcher;
    }
    get key() {
        return this._options.id ?? this.type.constructor.name;
    }
    get id() {
        return this.activeGroup?.id || this.key;
    }
    get state() {
        return this._state.state;
    }
    setState(state) {
        this._dispatchUpdate({ state });
    }
    updateState(state) {
        isFunction(state) ? state(this.state) : Object.assign(this.state, state);
        this._dispatchUpdate();
    }
    get data() {
        return this._state.data;
    }
    setData(data) {
        this._dispatchUpdate({ data });
    }
    get isDebugging() {
        return localStorage.getItem('__fw:view-debug') === "1";
    }
    debug(debug = true) {
        localStorage.setItem('__fw:view-debug', debug ? '1' : undefined);
        this._dispatchUpdate({ debug });
    }
    updateData(data) {
        let updateData = isFunction(data) ? data(this.data) : data;
        if (updateData)
            Object.assign(this.data, updateData);
        this._dispatchUpdate();
    }
    get isLoading() {
        return !!this._state.loading;
    }
    get isLoaded() {
        return !this.isLoading && this._state.loaded;
    }
    setLoading(loading = true) {
        this._dispatchUpdate({ loading });
    }
    async load(params) {
        if (!this.isLoading) {
            if (this._options.load) {
                this.setLoading(true);
                return this._options.load(params)
                    .then((data) => {
                    this._dispatchUpdate({
                        loaded: true,
                        data: this.type.cast(data),
                    });
                    return this.data;
                })
                    .catch((e) => handleResponseError(e, {
                    dispatcher: this.dispatcher?.toast,
                    defaultMessage: translate('client.response.error.loading'),
                    onError: e => {
                        this._options?.loadError?.(e, this);
                    }
                }))
                    .finally(() => {
                    this.setLoading(false);
                });
            }
            this._dispatchUpdate({
                loaded: true,
                data: this.type.cast(),
            });
            return this.data;
        }
    }
    get activeGroup() {
        return this.get(this._state.activeGroupName);
    }
    setActiveGroup(groupName) {
        if (groupName !== this._state.activeGroupName) {
            this._dispatchUpdate({
                activeGroupName: groupName ?? null,
            });
        }
    }
    get isEditingEnabled() {
        const { enableForm } = this._state;
        return !!enableForm;
    }
    get form() {
        return this.type.getFormik();
    }
    hasForm() {
        return !this.activeGroup || this.activeGroup.hasForm();
    }
    getFormTemplate() {
        return this._options.DefaultFormTemplate ?? DefaultFormTemplate;
    }
    getContentTemplate() {
        return this.activeGroup?._templates?.Template ?? this._options.DefaultTemplate ?? DefaultContentTemplate;
    }
    render(Template = this.getContentTemplate(), props) {
        if (!this.isLoaded) {
            return _jsx(SpinnerNew, { alignItems: "center", justifyContent: "center" });
        }
        if ((this.isDebugging || props?.withForm) && this.isEditingEnabled) {
            return this.renderForm(Template, props);
        }
        return this.renderContent(Template, props);
    }
    renderContent(Template = this.getContentTemplate(), props) {
        return Template ? renderContent(Template, {
            key: `${this.id}-view`,
            group: this.activeGroup,
            view: this,
            ...props,
        }) : null;
    }
    renderForm(Template = this.getFormTemplate(), props) {
        if (this.isEditingEnabled && this.hasForm()) {
            return renderContent(this.Form, {
                key: `${this.id}-form-view`,
                group: this.activeGroup,
                view: this,
                Template,
                ...props,
            });
        }
        return null;
    }
    Form({ group, Template, ...props }) {
        let type = this.type;
        const data = useMemo(() => {
            return this.isLoaded ? this.data : type.getDefaultValue();
        }, [this.isLoaded]);
        const formApi = useViewForm({
            view: this,
            data,
            onSaveError: (error) => {
                return tryFunction(this._options?.saveError, [
                    error,
                    this,
                    group
                ]) ?? error;
            },
            onSave: group?.props?.save ?? this._options?.save,
        });
        let children;
        if (group) {
            if (group.hasForm()) {
                children = group._templates.Form(this);
            }
        }
        else {
            children = type.render();
        }
        return _jsx(FormProvider, { form: formApi, children: Template ? renderContent(Template, {
                formChildren: children,
                view: this,
                group,
                ...props
            }) : children });
    }
    async saveForm() {
        if (!this.form || this.form.loading) {
            return;
        }
        const activeGroup = this.activeGroup;
        return new Promise(async (resolve) => {
            let closeForm = activeGroup?.props?.closeOnSave ?? this._options.closeFormOnSave ?? true;
            const forceValidation = activeGroup?.props?.forceValidation ?? this._options.forceValidation ?? false;
            if (this.form.dirty || forceValidation) {
                const response = await this.form.submitForm();
                if (!response || !this.form.isValid) {
                    resolve(false);
                    return;
                }
                closeForm = response.closeForm ?? closeForm;
            }
            closeForm
                ? this._closeForm(() => resolve(true), 1)
                : resolve(true);
        });
    }
    openForm(group) {
        this.form?.dirty ? this._showConfirmation(() => this._openForm(group)) : this._openForm(group);
    }
    open(groupName) {
        if (groupName !== this._state.activeGroupName) {
            this._dispatchUpdate({
                activeGroupName: groupName ?? null,
            });
        }
    }
    _openForm(groupName) {
        if (groupName !== this._state.activeGroupName || true !== this._state.enableForm) {
            this._dispatchUpdate({
                enableForm: true,
                activeGroupName: groupName ?? null,
            });
        }
    }
    closeForm(onClose, force) {
        this.form?.dirty && !force ? this._showConfirmation(() => this._closeForm(onClose)) : this._closeForm(onClose);
    }
    _closeForm(onClose, delay) {
        this.type.closeForm();
        if (this._state.enableForm) {
            this._dispatchUpdate({
                enableForm: false,
                activeGroupName: null,
            });
        }
        if (onClose) {
            delay ? setTimeout(onClose, 1) : onClose();
        }
    }
    _showConfirmation(onConfirm) {
        this._dispatcher.modal.show({
            onConfirm: () => onConfirm(),
            message: translate('formModal.confirm.message'),
            help: translate('formModal.confirm.help'),
            btnConfirm: translate('formModal.confirm.btn.confirm'),
            btnCancel: translate('formModal.confirm.btn.cancel'),
        });
    }
    get(key) {
        return this.groups?.[key];
    }
    has(key) {
        return !!this.groups[key];
    }
    pick(keys) {
        const groups = Object.values(this.groups);
        if (keys) {
            return groups.filter(v => keys.includes(v.key));
        }
        return groups;
    }
    omit(keys) {
        return Object.values(this.groups).filter(v => !keys.includes(v.key));
    }
}
export class ViewType extends AbstractViewType {
}
var FormResponse;
(function (FormResponse) {
    function create(props) {
        const updateData = isNotEmptyObject(props?.updateValues) ? props.updateValues : undefined;
        const shouldUpdate = (props?.updateValues === true || updateData);
        return {
            closeForm: props?.closeForm,
            updateData,
            shouldUpdate,
            data: { ...props?.data },
            error: !!(props?.error || props?.errorMessage),
            errorMessage: props?.errorMessage ?? (isString(props?.error) ? props?.error : undefined),
            successMessage: props?.successMessage,
        };
    }
    FormResponse.create = create;
})(FormResponse || (FormResponse = {}));
function useViewForm({ view, data, onSave: _onSave, onReset: _onReset, onSaveError: _onSaveError, ...config }) {
    const [loading, setLoading] = useState(false);
    const toastApi = useToastDispatcherApi();
    const type = view.type;
    const onSubmit = useCallback(async (values, util) => {
        if (!loading) {
            setLoading(true);
            const { activeGroup: currentGroup } = view;
            return _onSave?.(values, view, type)
                .then(v => FormResponse.create(v))
                .then(async (response) => {
                if (response.error) {
                    response.errorMessage && toastApi?.error?.(response.errorMessage);
                }
                else {
                    let updateValues = values;
                    response.successMessage && toastApi?.success?.(response.successMessage);
                    if (currentGroup?.key === view.activeGroup?.key) {
                        util.setStatus({});
                        util.resetForm({
                            values: updateValues
                        });
                    }
                    if (response.shouldUpdate) {
                        updateValues = type.cast({
                            ...updateValues,
                            ...response.updateData,
                        });
                        view.updateData(updateValues);
                    }
                }
                return response;
            })
                .catch(error => FormError.handleError({
                error,
                type,
                setStatus: util.setStatus,
                dispatcher: toastApi,
            }))
                .finally(() => {
                setLoading(false);
            });
        }
    }, [_onSave, loading, setLoading]);
    const onReset = useCallback((values, util) => {
        type.field.reset();
    }, []);
    const form = f.useFormik({
        ...config,
        validateOnChange: true,
        validateOnMount: false,
        validateOnBlur: true,
        onSubmit,
        onReset,
        initialStatus: {},
        initialValues: data,
    });
    const getType = useCallback(() => {
        return type;
    }, [type]);
    Object.assign(form, {
        getType,
        loading,
        setLoading,
    });
    type.setFormik(form);
    if (isDevEnv()) {
        setDebugVariable('fw_form', form);
        setDebugVariable('fw_type', type);
    }
    return form;
}
