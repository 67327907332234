import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Badge } from 'Atoms/Badge';
import { Icon } from 'Atoms/Icon';
import { useDispatcherApi } from 'Components/Dispatcher/useDispatcher';
import { translate } from 'Services/App';
import { isString, OrFunction, toObject } from '@f2w/utils';
import { FlexRow } from 'Atoms/Layout';
import { SearchSelect } from './SelectSearch';
export const SelectBadgeInline = ({ select, label, hideBadges, hideSelected, showRemoveAll, closeOnSelect, removeAllPromptMessage, removeAllLabel = translate('mandates.removeAll'), fixedDropdown, ...props }) => {
    const { removeValue, selectedOptions, clearValue } = OrFunction(select);
    const { modal } = useDispatcherApi();
    const onRemoveAllHandler = useCallback(async () => {
        if (await modal.confirmAsync({
            btnConfirm: removeAllLabel,
            message: removeAllPromptMessage,
            ...(isString(confirm) ? { message: confirm } : toObject(confirm)),
        })) {
            clearValue();
        }
    }, [clearValue]);
    return (_jsxs(FlexRow, { alignCenter: true, gap: 8, ...props, children: [_jsx(SearchSelect, { select: select, label: label, closeMenuOnSelect: closeOnSelect, hideSelectedOptions: hideSelected, closeMenuOnScroll: true, fixed: fixedDropdown }), !hideBadges && (_jsxs(FlexRow, { alignCenter: true, gap: 8, flexWrap: true, flexGrow: 1, children: [selectedOptions?.map(opt => {
                        return (_jsxs(Badge, { "$size": 'sm', onClick: () => {
                                removeValue(opt.value);
                            }, children: [opt.label, " ", _jsx(Icon, { icon: 'x' })] }, opt.value));
                    }), showRemoveAll && selectedOptions?.length > 2 && (_jsx(Badge, { "$size": 'sm', "$color": 'danger', onClick: onRemoveAllHandler, children: removeAllLabel || 'Remove all' }))] }))] }));
};
