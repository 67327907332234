import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, EditorType, StringType } from "@f2w/form";
import { translateAdmin } from "Services/App";
import { useEffect, useState } from "react";
import { Div, FlexCol } from "Atoms/Layout";
import { UploadImages } from "Components/ImageCarousel";
import { v4 as uuidv4 } from "uuid";
import { readFile } from "@f2w/utils";
export class UpsertPatchNotesType extends AbstractFormType {
    images = [];
    _useField(props) {
        super._useField(props);
        const [images, setImages] = useState([]);
        useEffect(() => {
            const initialData = this.getInitialData();
            if (initialData.preparedImages) {
                setImages(initialData.preparedImages);
            }
        }, []);
        this.images = images;
        this.setImages = setImages;
    }
    buildFields() {
        return {
            title: new StringType({
                label: translateAdmin('patchNotes.fields.title')
            }),
            leadText: new StringType({
                label: translateAdmin('patchNotes.fields.leadText')
            }),
            features: new EditorType({
                type: 'FULL',
                label: translateAdmin('patchNotes.fields.features')
            }),
            bugFixes: new EditorType({
                type: 'FULL',
                label: translateAdmin('patchNotes.fields.bugFixes')
            }),
        };
    }
    _renderWidget() {
        return _jsxs(FlexCol, { children: [this.renderChild('title'), this.renderChild('leadText'), _jsx(Div, { mb: 21, children: _jsx(UploadImages, { data: this.images, onUpload: async (files) => {
                            const mappedFiles = await Promise.all(files
                                .map(uploadedFile => uploadedFile.file)
                                .map(async (file) => ({
                                id: uuidv4(),
                                file: file,
                                path: await readFile(file),
                                publicName: file.name,
                            })));
                            this.setImages((old) => ([
                                ...old,
                                ...mappedFiles
                            ]));
                        }, onDelete: async (file) => {
                            this.setImages(prev => prev.filter(f => f.id !== file.id));
                        } }) }), this.renderChild('features'), this.renderChild('bugFixes')] });
    }
}
