import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { TextRoot, useTextProps } from './Text';
const TitleBase = styled(TextRoot).attrs(props => {
    return {
        as: 'h3',
        size: 'h6',
        role: 'heading',
        ...useTextProps(props),
    };
}) `
  margin: 0;
`;
export const Title = Object.assign(TitleBase, {
    H1: (props) => _jsx(TitleBase, { as: 'h1', ...props }),
    H2: (props) => _jsx(TitleBase, { as: 'h2', ...props }),
    H3: (props) => _jsx(TitleBase, { as: 'h3', ...props }),
    H4: (props) => _jsx(TitleBase, { as: 'h4', ...props }),
    H5: (props) => _jsx(TitleBase, { as: 'h5', ...props }),
    H6: (props) => _jsx(TitleBase, { as: 'h6', ...props }),
});
