import axios from 'axios';
import client from 'Utils/client';
import generateRoute from 'Services/Router';
export const updateMandateUserAccess = (mandateId, updateMandateUsersDto) => {
    const route = generateRoute('api.mandates.updateUserAccess', {
        'mandate': mandateId
    });
    return axios.put(route, updateMandateUsersDto);
};
export const getMandateById = async (mandateId) => {
    const route = generateRoute('api.mandates.view', {
        'mandate': mandateId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const getPaginatedMandates = (dataTableParams) => {
    const route = generateRoute('api.mandates.list');
    return client
        .get(route, { params: dataTableParams })
        .then(response => response.data);
};
export const getMandateIds = (dataTableParams) => {
    const route = generateRoute('mandates.ids');
    return axios
        .get(route, { params: dataTableParams })
        .then(response => response.data);
};
export const createMandate = (upsertMandateDto) => {
    const route = generateRoute('api.mandates.create');
    let request = upsertMandateDto;
    request.isCorporate = upsertMandateDto.isCorporate === true || upsertMandateDto.isCorporate === "true";
    return axios.post(route, request);
};
export const updateMandate = (mandateId, upsertMandateDto) => {
    let request = upsertMandateDto;
    request.isCorporate = upsertMandateDto.isCorporate === true || upsertMandateDto.isCorporate === "true";
    const route = generateRoute('api.mandates.update', {
        'mandate': mandateId
    });
    return axios.put(route, request);
};
export const deleteMandate = (mandateId) => {
    const route = generateRoute('api.mandates.delete', {
        'mandate': mandateId
    });
    return axios.delete(route);
};
export default {
    updateMandateUserAccess,
    getMandateById,
    getPaginatedMandates,
    createMandate,
    updateMandate,
    deleteMandate,
};
