import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Div, FlexCol, FlexRow, Icon, Text } from "Components/Atoms";
import { translate } from "Services/Translator";
import { DataTable } from "@f2w/data-table";
import { usePaymentsTable } from "Pages/Creditor/components/CreditorView/hooks/usePaymentsTable";
import { CreditorState } from "Pages/Creditor/enums";
export const CreditorPayments = ({ creditorInvoiceId, payments, deleteCreditorPayment, refreshDetails, onOpenNewPaymentForm, creditorState, hasBankFile }) => {
    const { table } = usePaymentsTable({
        creditorInvoiceId,
        payments,
        deleteCreditorPayment,
        refreshDetails
    });
    return (_jsxs(FlexCol, { children: [_jsx(Text, { large: 500, neutral: 500, children: _jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [translate('creditor.payments'), _jsxs(Button, { onClick: onOpenNewPaymentForm, disabled: ![
                                CreditorState.READY_FOR_PAYMENT,
                                CreditorState.PARTIALLY_PAID,
                                CreditorState.OVERPAID,
                                CreditorState.PAID
                            ].includes(creditorState) || hasBankFile, children: [_jsx(Icon, { icon: 'plus' }), translate('payment.add')] })] }) }), _jsx(Div, { pt: 16, pb: 16, children: _jsx(DataTable.SimpleTable, { filter: 'inline', variant: 'default', instance: table }) })] }));
};
