import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Div, FlexCol, FlexRow, Nav, Select } from "Components/Atoms";
import React from "react";
import { CalculationServices } from "Pages/AncillaryExpense";
import { FixedDistributionTable } from "Pages/AncillaryExpense/components/CalculationDistributionSection/components/FixedDistributionTable";
import { ConsumptionDistributionTable } from "Pages/AncillaryExpense/components/CalculationDistributionSection/components/ConsumptionDistributionTable";
import { translate } from "Services/Translator";
import Spinner from "./Spinner";
export const DistributionsSection = ({ distributionSection, propertyId, overviewData, calculationId, isLoading: baseIsLoading, getDistributionKeysForProperty, calculationServices = CalculationServices, setRefreshData, selectedRentalObjectType, setSelectedRentalObjectType }) => {
    const [editMode, setEditMode] = React.useState(false);
    const { accountDistributions, updateDistributionKey, updateHeatingDaysShare, refreshData, activeAccountId, setAccount, distributionMap, isLoading, setIsLoading, rentalObjectTypeOptions } = distributionSection;
    React.useEffect(() => {
        setRefreshData(() => refreshData);
    }, [refreshData]);
    React.useEffect(() => {
        if (!isLoading && baseIsLoading) {
            setIsLoading(true);
        }
    }, [baseIsLoading]);
    return (_jsxs(Div, { style: { position: "relative" }, children: [_jsx(Spinner, { isVisible: isLoading }), _jsxs(FlexRow, { inline: true, px: 10, py: 10, alignItems: 'baseline', gap: 5, children: [_jsx("label", { htmlFor: "rentalObjectTypeFilter", style: { fontWeight: 600 }, children: translate('baseData.rentalObject.type') }), _jsx(Select, { ...{
                            id: "rentalObjectTypeFilter",
                            options: rentalObjectTypeOptions,
                            value: selectedRentalObjectType,
                            isClearable: true,
                            onChange: (opt) => {
                                setSelectedRentalObjectType(opt?.value);
                            },
                        } })] }), _jsx(Nav, { id: "distributions-nav", variant: 'tabs', position: 'top', onSelect: (accountId) => setAccount(Number(accountId)), activeKey: activeAccountId, children: Array.from(distributionMap.values()).map(({ accountId, accountName }) => (_jsx(Nav.Link, { eventKey: accountId, label: accountName, disabled: editMode && accountDistributions.accountId !== accountId }, `distributions-nav-${accountId}`))) }), _jsxs(FlexCol, { gap: 20, children: [accountDistributions?.allocation_formula && (_jsx(FixedDistributionTable, { distributionAccount: accountDistributions.allocation_formula, overviewData: overviewData, getDistributionKeysForProperty: () => getDistributionKeysForProperty(propertyId), updateDistributionKey: updateDistributionKey, updateHeatingDaysShare: updateHeatingDaysShare, loadData: () => refreshData() })), accountDistributions?.consumption && (_jsx(ConsumptionDistributionTable, { accountDistributions: accountDistributions, overviewData: overviewData, getDistributionKeysForProperty: () => getDistributionKeysForProperty(propertyId), updateDistributionKey: updateDistributionKey, loadData: () => refreshData(), setEditMode: setEditMode, calculationId: calculationId, createFixCostDistribution: calculationServices.createFixCostDistribution, onDeleteFixCost: async (calculationId, accountId) => {
                            await calculationServices.deleteFixCostDistribution(calculationId, accountId);
                            await refreshData();
                        }, onDeleteCommonArea: async (calculationId, accountId) => {
                            await calculationServices.deleteCommonAreaDistribution(calculationId, accountId);
                            await refreshData();
                        }, createCommonAreaDistribution: calculationServices.createCommonAreaDistribution, saveMeters: calculationServices.saveMeters }))] })] }));
};
