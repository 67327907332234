import axios from 'axios';
import moment from 'moment';
import { setDebugVariable } from '@f2w/utils';
setDebugVariable({ axios });
export function paramsSerializer(o) {
    function iter(o, path) {
        if (Array.isArray(o)) {
            o.forEach(function (a, i) {
                iter(a, path + '[' + i + ']');
            });
            return;
        }
        if (o !== null && typeof o === 'object') {
            Object.keys(o).forEach(function (k) {
                iter(o[k], path + '[' + k + ']');
            });
            return;
        }
        if (o !== undefined) {
            data.push(path + '=' + encodeURIComponent(o));
        }
    }
    var data = [];
    Object.keys(o).forEach(function (k) {
        iter(o[k], k);
    });
    return data.join('&');
}
const dateTransformer = data => {
    if (data instanceof Date) {
        return moment(data).format('YYYY-MM-DD HH:mm:ss');
    }
    if (Array.isArray(data)) {
        return data.map(val => dateTransformer(val));
    }
    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]));
    }
    return data;
};
const client = axios.create({
    paramsSerializer,
});
client.interceptors.request.use((config) => {
    config.transformRequest = [dateTransformer].concat(config.transformRequest);
    return config;
});
export default client;
