import { jsx as _jsx } from "react/jsx-runtime";
import { DatePicker } from "Atoms/DatePicker";
import moment from "moment/moment";
export function DateFilter({ column: { filterValue, setFilter } }) {
    return (_jsx(DatePicker, { value: filterValue || '', onChange: (value) => {
            if (value === null) {
                setFilter(undefined);
            }
            else {
                setFilter(moment(value).format('YYYY-MM-DD'));
            }
        } }));
}
