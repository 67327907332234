import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Icon } from 'Atoms/Icon';
import { Text } from 'Atoms/Text';
import { translate } from 'Services/App';
import styled from 'styled-components';
import { isBool, isObject } from '@f2w/utils';
import { Button } from 'Atoms/Button';
import generateRoute from 'Services/Router';
const lockScreen = {
    Page: ({ children, ...props }) => {
        useEffect(() => {
            document.body.classList.add('fw-content-locked');
            return () => {
                document.body.classList.remove('fw-content-locked');
            };
        }, []);
        return (_jsx(LockScreen, { ...props, children: _jsx(Button, { color: 'secondary', label: translate('subscriptions.upgradeButton'), href: generateRoute('subscription.subscribe') }) }));
    },
    useProps(message) {
        if (isObject(message) && !React.isValidElement(message))
            return { ...message };
        return { message };
    },
};
export const LockScreen = Object.assign(function LockScreen(props) {
    if (props.message === false)
        return null;
    const message = isBool(props.message) ? null : props.message;
    const { title, children, } = props;
    return _jsxs(ModalDisableBackdrop, { children: [_jsx(Icon, { icon: 'lock', size: 100 }), _jsx(Text, { as: 'h2', h2: true, children: title || translate('featureLocked.title') }), _jsxs(Text, { as: 'p', body: true, style: { opacity: .6 }, children: [translate('featureLocked.text'), " ", message] }), children && _jsx(_Fragment, { children: children })] });
}, lockScreen);
const ModalDisableBackdrop = styled.div `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10200;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    padding: 20px 40px;
    gap: 20px;
    backdrop-filter: blur(3px);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
