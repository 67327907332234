import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ModalNew } from "Components/Modal";
import { translate } from "Services/Translator";
import { Button, Div, FlexCol, FlexRow, Text } from "Components/Atoms";
import { useToastDispatcherApi } from "Components/Dispatcher";
export const BulkInvoicesErrorModal = ({ bulkBankFiles, invoiceIds, numberOfInvoicesInPast, numberOfCorrectInvoices, bulkInvoicesErrorModalOpen, invalidBankLinks, onClose, onRefresh }) => {
    const { success, error } = useToastDispatcherApi();
    const onConfirm = React.useCallback(() => {
        bulkBankFiles(invoiceIds, true)
            .then(() => {
            success({
                timeout: 2,
                message: translate('creditor.bankFiles.created')
            });
            onRefresh();
        })
            .catch((errorRq) => {
            if (errorRq.isAxiosError && errorRq.response) {
                const { response: { data, status } } = errorRq;
                if (status === 400) {
                    error({
                        timeout: 5,
                        message: data.message
                    });
                }
            }
            else {
                error({
                    timeout: 5,
                    message: translate('creditor.bankFiles.unableToCreate')
                });
            }
        });
    }, [bulkBankFiles, invoiceIds]);
    return (_jsxs(ModalNew, { show: bulkInvoicesErrorModalOpen, size: { maxWidth: '700px' }, children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onClose, children: _jsx(ModalNew.Title, { children: translate('creditor.invalidForBankFile') }) }), _jsx(ModalNew.Body, { children: _jsxs(FlexCol, { gap: 5, children: [numberOfInvoicesInPast > 0 && _jsx(Div, { children: translate('creditor.paymentInThePast.text') }), invalidBankLinks.length > 0 && (_jsxs(FlexCol, { gap: 5, children: [_jsxs(Text, { children: [translate('creditor.invalidBankAddresses'), ":"] }), _jsx("ol", { children: invalidBankLinks.map((link, index) => _jsx("li", { dangerouslySetInnerHTML: { __html: link } }, index)) }), _jsx(Text, { children: translate('creditor.bankAccounts.bankFileCannotBeCreated') })] }))] }) }), _jsx(ModalNew.Footer, { children: _jsxs(FlexRow, { gap: 15, flexWrap: true, children: [_jsx(Button, { onClick: onConfirm, disabled: numberOfCorrectInvoices === 0, children: translate('creditor.bankFiles.action.createForCorrectInvoices', {
                                numberOfCorrectInvoices: numberOfCorrectInvoices
                            }) }), _jsx(Button, { onClick: onClose, children: translate('creditor.bankFiles.action.cancelAndKeepEditing') })] }) })] }));
};
