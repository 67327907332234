import { AbstractFormType, DateType, DecimalType, fieldsFactory, MoneyType, NumberType, SelectType, StringType } from '@f2w/form';
import { translate } from 'Services/Translator';
import { MortgageType as MortgageInterestType } from 'Components/ObjectViewModal/types';
import moment from 'moment';
import { usePortfolio } from "Components/Base/PortfolioContext";
export class MortgageType extends AbstractFormType {
    propertyOptions;
    _useField(props) {
        super._useField(props);
        const portfolio = usePortfolio();
        this.propertyOptions = portfolio.getProperties().map(property => ({
            label: property.name,
            value: property.id
        }));
    }
    buildFields() {
        return fieldsFactory()({
            propertyId: new SelectType({
                label: translate('property'),
                required: true,
                searchable: true,
                placeholder: translate('baseData.property.select') + '...',
                choiceOptions: () => this.propertyOptions,
                isVisible: () => this.field.value.id === undefined,
            }),
            label: new StringType({
                label: translate('mortgage.label'),
                required: false
            }),
            value: new MoneyType({
                label: translate('mortgage.value'),
                required: true
            }),
            start: new DateType({
                label: translate('mortgage.start'),
                required: true,
            }),
            end: new DateType({
                label: translate('mortgage.end'),
                required: true,
                constraints: {
                    test: ['is-after-start', translate('mortgage.startDateMustBeBeforeEndDate'), (currentValue) => {
                            return this.field.value.start
                                ? moment(currentValue).isAfter(moment(this.field.value.start))
                                : true;
                        }]
                }
            }),
            type: new SelectType({
                label: translate('mortgage.type'),
                required: true,
                choiceOptions: Object.values(MortgageInterestType).map((value) => ({
                    label: translate('mortgage.type.' + value),
                    value: value,
                })),
                defaultValue: MortgageInterestType.FIXED,
            }),
            interest: new DecimalType({
                label: translate('mortgage.interest'),
                scale: 3,
                prefix: '%',
                isVisible: () => this.children?.type?.field?.value === MortgageInterestType.FIXED,
                required: true,
                constraints: {
                    moreThan: [0, translate('form.validation.notValidNumber')],
                }
            }),
            extraInterest: new NumberType({
                label: translate('mortgage.extraInterest'),
                isVisible: () => this.children?.type?.field?.value === MortgageInterestType.SARON,
                defaultValue: 0,
                required: true
            }),
            amortisationAmount: new MoneyType({
                label: translate('mortgage.amortisationAmount'),
                required: false
            }),
            amortisationDeadline: new DateType({
                label: translate('mortgage.amortisationDeadline'),
                required: false,
                constraints: {
                    test: ['is-after-start', translate('mortgage.startDateMustBeBeforeEndDate'), (currentValue) => {
                            if (!currentValue) {
                                return true;
                            }
                            return this.field.value.start
                                ? moment(currentValue).isAfter(moment(this.field.value.start))
                                : true;
                        }]
                }
            }),
        });
    }
}
