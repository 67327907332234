import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import AccordionContext from './AccordionContext';
import { useAccordionToggle } from './AccordionToggle';
import { AccordionCollapse } from './AccordionCollapse';
import { Icon } from 'Atoms/Icon';
import styled from 'styled-components';
import classnames from 'classnames';
import { EllipsisText } from 'Atoms/Text';
import { isFunction } from 'Utils';
export const AccordionItem = ({ title, Actions: HeaderComponent, children, onClick, eventKey, }) => {
    const { contextEventKey, ...props } = {
        onClick: eventKey && useAccordionToggle(eventKey, onClick),
        contextEventKey: eventKey && useContext(AccordionContext),
    };
    const isSelected = eventKey && useMemo(() => eventKey === contextEventKey, [eventKey, contextEventKey]);
    const header = (_jsxs(Header, { ...props, className: classnames(eventKey && ''), children: [_jsx(HeaderTitle, { children: _jsx(EllipsisText, { children: title }) }), HeaderComponent && _jsx(HeaderActions, { children: isFunction(HeaderComponent) ? HeaderComponent({ title, isSelected }) : HeaderComponent }), eventKey && _jsx(Icon, { icon: isSelected ? 'minus' : 'plus' })] }));
    if (eventKey) {
        return (_jsxs(Root, { className: classnames(isSelected && 'fw-active', 'fw-accordion-item--link'), children: [header, children && _jsx(AccordionCollapse, { eventKey: eventKey, children: _jsx(Content, { children: children }) })] }));
    }
    return (_jsxs(Root, { className: 'fw-accordion-item--text', children: [header, children && _jsx(Content, { children: children })] }));
};
AccordionItem.displayName = 'AccordionItem';
const HeaderTitle = styled.div ``;
const HeaderActions = styled.div ``;
const Content = styled.div `
  display: block;
  //padding: var(--padding-y) var(--padding-x);
`;
const Header = styled.div `
  --fw-header-height: 56px;
  position: sticky;
  top: var(--fw-header-sticky-top);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-height: var(--fw-header-height);
  background-color: white;
  z-index: 3;

  ${p => p.theme.typography.large.css({ fontWeight: 600 })};

  ${HeaderTitle} {
    flex: 1;
  }

  ${HeaderActions} {
    display: flex;
  }

  ${Icon} {
    flex: 0;
    line-height: 1em;
    font-size: ${p => p.theme.typography.h6.fontSize.pxValue};
    min-width: 1em;
  }
`;
const Root = styled.div `
  --padding-x: 0px;
  --padding-y: 20px;
  --fw-header-sticky-top: 75px;

  display: flex;
  flex-direction: column;

  & & ${Header} {
    ${p => p.theme.typography.body.css({ fontFamily: undefined, fontWeight: 500 })};
    top: calc(var(--fw-header-height) + var(--fw-header-sticky-top));
    z-index: 2;
  }

  &.fw-accordion-item--link {
    & + & {
      border-top: 1px solid #ccc;
    }

    ${Header} {
        padding: 4px;
        cursor: pointer;
        &:hover {
          color: ${p => p.theme.palette.primary.$700};
        }
    }
    &.fw-active {
      ${Header} {
        color: ${p => p.theme.palette.primary.$500};
      }
    }
  }

  &.fw-accordion-item--text {

    ${Header} {
      padding: 4px 0 10px 0;
    }
  }

`;
