import { UseStyled, useTheme } from "@f2w/theme";
import { css } from "styled-components";
import { Button } from "Components/Atoms";
const SelectSubscriptionPlanBase = UseStyled.styled('div', () => ({}));
const Body = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        margin: auto;
        max-width: ${rem(1500)};
        padding: ${rem(48)};
    `;
});
const Heading = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        justify-content: space-between;
        margin: 0 0 ${rem(40)} 0;
        gap: ${rem(20)};
    `;
});
const HeadingTitle = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        flex:1;
        justify-content: center;
        color:  ${palette.neutral.$500};
        ${typography.h6.fontStyles};
    `;
});
const PlanDetails = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `;
});
const ButtonSection = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
    `;
});
const ButtonGap = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        margin-right: ${rem(8)};
    `;
});
const PlanTitle = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        color:  ${palette.black};
        ${typography.body.css({ fontWeight: 600 })};
        margin-bottom: ${rem(8)};
        display: flex;
        align-items: center;
    `;
});
const VariablePlanTitle = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        ${typography.h6.fontStyles};
        color:  ${palette.neutral.$900};
        margin-bottom: ${rem(8)};
        display: flex;
        align-items: center;
    `;
});
const PlanPrice = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        ${typography.body.css({ fontWeight: 600 })};
        color:  ${palette.primary.$500};
        margin-bottom: ${rem(8)};
        display: flex;
        align-items: baseline;
    `;
});
const VariablePlanPrice = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        ${typography.large.css({ fontWeight: 500 })};
        color:  ${palette.primary.$500};
        margin-bottom: ${rem(8)};
        display: flex;
        align-items: baseline;
    `;
});
const PlanInfo = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        ${typography.small.fontStyles};
        color:  ${palette.neutral.$300};
        white-space: initial;
        display: flex;
        align-items: center;
    `;
});
const PlanButton = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
    margin-top: ${rem(26)};
    display: flex;
    align-items: center;
    text-align: center;

     ${Button} {
         flex: 1;
     }
    `;
});
const PlanData = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        flex:1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: ${rem(32)};
    `;
});
const ButtonSeeMore = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        flex: 1;
        padding: ${rem(16)} 0;
        align-items: center;
        border-bottom: ${rem(1)} solid ${palette.neutral.$100};
        margin-bottom: ${rem(40)};
        justify-content: center;
    `;
});
const Plans = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: grid;
        grid-auto-flow: column;
        border-left: ${rem(1)} solid ${palette.neutral.$100};
        border-bottom: ${rem(1)} solid ${palette.neutral.$100};
    `;
});
const Plan = UseStyled.styled('div', ({ isCurrent }) => {
    const { rem, palette, typography } = useTheme();
    return css `
            display: contents;
            ${PlanCell} {
            background: ${isCurrent ? palette.primary.$50 : 'transparent'};
            }
    `;
});
const PlanCell = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        ${typography.medium.fontStyles};
        color:  ${palette.neutral.$500};
        /* neutral/neutral-100 */
        border-top: 1px solid ${palette.neutral.$100};
        border-right: 1px solid ${palette.neutral.$100};
        padding: ${rem(16)};
        display: flex;
        align-items: center;
        &:first-child {
            grid-row: 1;
        }
        &:last-child {
            grid-row: 100;
        }
    `;
});
const VariablePlanSection = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        flex:1;
        align-items:center;
        justify-content: space-between;
        gap: ${rem(45)};
    `;
});
const VariablePlanForm = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        flex-direction: column;
        gap: ${rem(16)};
        flex:1;
        padding: ${rem(32)};
        border: 1px solid ${palette.neutral.$100};
        border-radius: ${rem(8)};
        ${typography.body.fontStyles};
        color:  ${palette.neutral.$900};
    `;
});
const PerMonth = UseStyled.styled('span', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        ${typography.small.fontStyles};
        color:  ${palette.primary.$150};
        // color: #AAB9FF;

    `;
});
const ButtonUpgrade = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        margin-top: ${rem(40)};
        display: flex;
        flex:1;
        justify-content: right;
    `;
});
const TitlePriceSection = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        flex-direction: column;
        gap: ${rem(8)};
    `;
});
const LimitButton = UseStyled.styled('button', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        width: 32px;
        height: 32px;
        border: 1px solid ${palette.primary.$500};
        border-radius: ${rem(48)};
        color: ${palette.primary.$500};
        background:  ${palette.white};
        padding: ${rem(8)};
        justify-content: center;
        align-items:  center;
        &:disabled {
            border: 1px solid ${palette.neutral.$100};
            color: ${palette.neutral.$100};
        }
    `;
});
const SelectSubscriptionPlanStyled = Object.assign(SelectSubscriptionPlanBase, {
    Body,
    Heading,
    HeadingTitle,
    ButtonSection,
    ButtonGap,
    PlanDetails,
    PlanTitle,
    PlanPrice,
    PlanInfo,
    PlanButton,
    PlanData,
    ButtonSeeMore,
    Plans,
    Plan,
    PlanCell,
    VariablePlanSection,
    VariablePlanForm,
    PerMonth,
    ButtonUpgrade,
    VariablePlanTitle,
    VariablePlanPrice,
    TitlePriceSection,
    LimitButton
});
export default SelectSubscriptionPlanStyled;
