import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, BooleanType, EmailType, GenderType, NumberType, PasswordRepeatType, PhoneType, SelectType, StringType, UrlType, } from '@f2w/form';
import { translate } from 'Services/Translator';
import { ContactType } from 'Pages/Administration/Customers/types/EditCustomerType';
export class AccountType extends AbstractFormType {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            title: translate('account.create'),
        };
    }
    buildFields() {
        return {
            gender: new GenderType({
                label: translate('person.salutation'),
                required: true,
            }),
            firstName: new StringType({
                label: translate('person.firstName'),
                required: true,
                constraints: { max: [16, translate('form.validation.noLongerThan', { length: 16 })] },
            }),
            lastName: new StringType({
                label: translate('person.lastName'),
                required: true,
                constraints: { max: [16, translate('form.validation.noLongerThan', { length: 16 })] },
            }),
            email: new EmailType({
                label: translate('person.email'),
                required: true,
            }),
            terms: new BooleanType({
                defaultValue: false,
                content: (_jsxs(_Fragment, { children: [translate('register.agree'), "\u00A0", _jsx("a", { href: "https://fairwalter.com/rechtliches#agb", target: "_blank", children: translate('termsAndServices.long') }), "\u00A0", translate('register.ofFairwalter'), "."] })),
                constraints: { isTrue: translate('account.invalidTerms') },
            }),
            password: new PasswordRepeatType({
                required: true,
                min: 6,
            }),
            cellPhoneNumber: new PhoneType({
                label: translate('phoneNumber'),
            }),
            accountType: new SelectType({
                label: translate('account.contactType'),
                defaultValue: 'corporate',
                choiceOptions: () => [
                    { value: 'corporate', label: translate('person.type.corporate') },
                    { value: 'personal', label: translate('person.type.natural') },
                ]
            }),
            accountName: new StringType({
                label: translate('account.name'),
                required: true,
            }),
            website: new UrlType({
                label: translate('website'),
                nullable: true,
            }),
            nbObjectsPlanned: new NumberType({
                label: translate('account.nbObjectsPlanned'),
                nullable: true,
                constraints: { max: 1000000 },
                required: false,
            }),
            nbMandatesPlanned: new NumberType({
                label: translate('account.nbMandatesPlanned'),
                nullable: true,
                constraints: { max: 1000000 },
                required: false,
            }),
            nbUsersPlanned: new NumberType({
                label: translate('account.nbUsersPlanned'),
                nullable: true,
                constraints: { max: 1000000 },
                required: false,
            }),
            contactAddress: new ContactType({
                label: translate('account.mailingAddress'),
                title: null,
                wrapWithBorder: false,
                required: true,
                isBillingContact: false,
            }),
            contact: new ContactType({
                label: false,
                title: translate('account.mailingAddress'),
                wrapWithBorder: true,
                required: true,
                isBillingContact: false,
            }),
            billingContact: new ContactType({
                label: false,
                title: translate('account.mailingAddress'),
                wrapWithBorder: true,
                required: true,
                isBillingContact: true,
            }),
        };
    }
    _renderWidget() {
        return _jsx(_Fragment, { children: this.renderChildren() });
    }
}
