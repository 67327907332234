import axios from "axios";
export function uploadFiles(uploadUrl, files, params, onFileUploaded = null) {
    let promises = [];
    for (const file of files) {
        const promise = uploadFile(uploadUrl, file, params)
            .then(result => {
            if (onFileUploaded)
                onFileUploaded(file, result);
            return result;
        });
        promises.push(promise);
    }
    return Promise.all(promises);
}
export function uploadFile(uploadUrl, file, params = {}) {
    const formData = new FormData();
    formData.append(file.name, file);
    formData.append('params', JSON.stringify(params));
    return axios.post(uploadUrl, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}
