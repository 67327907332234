import { jsx as _jsx } from "react/jsx-runtime";
import { AuditLogDataTable } from "Pages/Administration/AuditLog/table/AuditLogTableDataTable";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import { Div } from "Atoms/Layout";
export const AuditLogPage = ({ auditLogServices, getActiveCustomers }) => {
    const instance = AuditLogDataTable.use({
        listAuditLog: auditLogServices.listAuditLog,
        getActiveCustomers
    });
    return (_jsx(Div, { p: 50, h: '100%', children: _jsx(PageOverview, { instance: instance, title: "Audit Log", showFilters: true }) }));
};
