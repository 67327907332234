import { jsx as _jsx } from "react/jsx-runtime";
import { useViewType } from "@f2w/view";
import { CustomerSubscriptionView } from "Pages/Administration/Customers/types/CustomerSubscriptionView";
import { translateAdmin } from "Services/App";
import { SubscriptionInfoTab } from "Pages/Administration/Customers/components/SubscriptionInfoTab";
import { CustomerSubscriptionTemplate } from "Pages/Administration/Customers/components/CustomerSubscriptionTemplate";
export const useCustomerSubscriptionView = ({ getSubscriptionDetails, onEdit, deleteSubscription }) => useViewType(() => new CustomerSubscriptionView(), {
    id: 'customer-view',
    defaultState: {
        leftActiveTab: 'info',
    },
    DefaultTemplate: (props) => _jsx(CustomerSubscriptionTemplate, { ...props }),
    load: ({ id }) => getSubscriptionDetails(id),
    actions: {
        onEdit,
        deleteSubscription
    }
}, () => ({
    info: {
        title: translateAdmin('customer.subscription'),
        Content: (props) => _jsx(SubscriptionInfoTab, { ...props })
    }
}));
