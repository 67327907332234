import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import contains from 'dom-helpers/contains';
import React, { cloneElement, useCallback, useRef } from 'react';
import useTimeout from '@restart/hooks/useTimeout';
import safeFindDOMNode from 'react-overlays/safeFindDOMNode';
import warning from 'warning';
import { useUncontrolledProp } from 'uncontrollable';
import { Overlay } from './Overlay';
class RefHolder extends React.Component {
    render() {
        return this.props.children;
    }
}
function normalizeDelay(delay) {
    return delay && typeof delay === 'object'
        ? delay
        : {
            show: delay,
            hide: delay,
        };
}
function handleMouseOverOut(handler, args, relatedNative) {
    const [e] = args;
    const target = e.currentTarget;
    const related = e.relatedTarget || e.nativeEvent[relatedNative];
    if ((!related || related !== target) && !contains(target, related)) {
        handler(...args);
    }
}
const defaultProps = {
    defaultShow: false,
    trigger: ['hover', 'focus'],
};
export function OverlayTrigger({ trigger, overlay, children, popperConfig = {}, show: propsShow, defaultShow = false, onToggle, delay: propsDelay, placement, flip = placement && placement.indexOf('auto') !== -1, ...props }) {
    const triggerNodeRef = useRef(null);
    const timeout = useTimeout();
    const hoverStateRef = useRef('');
    const [show, setShow] = useUncontrolledProp(propsShow, defaultShow, onToggle);
    const delay = normalizeDelay(propsDelay);
    const { onFocus, onBlur, onClick } = typeof children !== 'function'
        ? React.Children.only(children).props
        : {};
    const getTarget = useCallback(() => safeFindDOMNode(triggerNodeRef.current), []);
    const handleShow = useCallback(() => {
        timeout.clear();
        hoverStateRef.current = 'show';
        if (!delay.show) {
            setShow(true);
            return;
        }
        timeout.set(() => {
            if (hoverStateRef.current === 'show')
                setShow(true);
        }, delay.show);
    }, [delay.show, setShow, timeout]);
    const handleHide = useCallback(() => {
        timeout.clear();
        hoverStateRef.current = 'hide';
        if (!delay.hide) {
            setShow(false);
            return;
        }
        timeout.set(() => {
            if (hoverStateRef.current === 'hide')
                setShow(false);
        }, delay.hide);
    }, [delay.hide, setShow, timeout]);
    const handleFocus = useCallback((...args) => {
        handleShow();
        onFocus?.(...args);
    }, [handleShow, onFocus]);
    const handleBlur = useCallback((...args) => {
        handleHide();
        onBlur?.(...args);
    }, [handleHide, onBlur]);
    const handleClick = useCallback((...args) => {
        setShow(!show);
        if (onClick)
            onClick(...args);
    }, [onClick, setShow, show]);
    const handleMouseOver = useCallback((...args) => {
        handleMouseOverOut(handleShow, args, 'fromElement');
    }, [handleShow]);
    const handleMouseOut = useCallback((...args) => {
        handleMouseOverOut(handleHide, args, 'toElement');
    }, [handleHide]);
    const triggers = trigger == null ? ['hover', 'click'] : [].concat(trigger);
    const triggerProps = {};
    if (triggers.indexOf('click') !== -1) {
        triggerProps.onClick = handleClick;
    }
    if (triggers.indexOf('focus') !== -1) {
        triggerProps.onFocus = handleFocus;
        triggerProps.onBlur = handleBlur;
    }
    if (triggers.indexOf('hover') !== -1) {
        warning(triggers.length > 1, '[react-bootstrap] Specifying only the `"hover"` trigger limits the visibility of the overlay to just mouse users. Consider also including the `"focus"` trigger so that touch and keyboard only users can see the overlay as well.');
        triggerProps.onMouseOver = handleMouseOver;
        triggerProps.onMouseOut = handleMouseOut;
    }
    return (_jsxs(_Fragment, { children: [typeof children === 'function' ? (children({ ...triggerProps, ref: triggerNodeRef })) : (_jsx(RefHolder, { ref: triggerNodeRef, children: cloneElement(children, triggerProps) })), _jsx(Overlay, { ...props, show: show, onHide: handleHide, flip: flip, placement: placement, popperConfig: popperConfig, target: getTarget, children: overlay })] }));
}
OverlayTrigger.defaultProps = defaultProps;
OverlayTrigger.displayName = 'OverlayTrigger';
