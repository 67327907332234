import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { Select as DropdownSelect } from "Atoms/Select";
const options = [
    {
        label: translate('yes'),
        value: "1"
    },
    {
        label: translate('no'),
        value: "0"
    },
];
export function HasChangesFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: options, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
}
