import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Text } from 'Atoms/Text';
import { toPx, toUnit, UseStyled } from '@fw/theme';
import { CrossIcon } from 'Components/App/Icons';
import { useModalCloseHandler } from '../hooks';
import { translate } from 'Services/App';
import { useModalContext } from '../ModalContext';
import { LockScreen } from 'Atoms/LockScreen';
import { modalSlot } from '../Modal.theme';
import { Button } from 'Atoms/Button';
export const ModalDivider = UseStyled.Fn(({ className }) => _jsx("div", { className: className }), ({ theme: { palette }, border = true, space = '1.5em', fit }) => ({
    minWidth: '100%',
    borderBottom: `1px solid ${border ? palette.dark.$50 : 'transparent'}`,
    boxSizing: 'content-box',
    margin: useMemo(() => {
        return [toUnit(space), fit ? 'calc(-1 * var(--modal__padding-x, 0))' : '0'].join(' ');
    }, [space, fit]),
}));
export const ModalClose = modalSlot.create('closeTrigger', {
    tag: (p) => {
        return _jsx(Button, { variant: 'link', children: _jsx(CrossIcon, {}), "aria-label": translate('btn.close'), "data-dismiss": 'modal', ...p });
    },
    props: ({ label, onHide, ...p }) => {
        const handleClick = useModalCloseHandler(onHide);
        return {
            ...p,
            onClick: handleClick,
        };
    },
});
export const ModalTitle = modalSlot.create('title', {
    tag: Text,
    defaultProps: {
        role: 'heading',
        as: 'h5',
        size: 'h6',
        colorName: 'dark.$600',
        p: 0,
        m: 0,
    },
});
export const ModalHeader = modalSlot.create('header', {
    tag: 'div',
    props: ({ closeLabel = translate('btn.close'), closeButton, onHide, children, ...props }) => ({
        ...props,
        children: _jsxs(_Fragment, { children: [children, closeButton && (_jsx(ModalClose, { onHide: onHide }))] }),
    }),
});
export const ModalFooter = modalSlot.create('footer', {
    tag: 'div',
    defaultProps: {},
});
export const ModalBody = modalSlot.create('body', {
    defaultProps: {},
    props: ({ asChild, padding, paddingX = padding, paddingY = padding, children, ...props }) => {
        const { locked } = useModalContext();
        if (!asChild) {
            props.style = {
                '--modal__padding-x': toPx(paddingX),
                '--modal__padding-y': toPx(paddingY),
                ...props.style,
                overflow: locked ? 'hidden' : undefined,
            };
        }
        return {
            ...props,
            children: _jsxs(_Fragment, { children: [locked && _jsx(LockScreen, { message: locked }), children] })
        };
    },
});
