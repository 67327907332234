import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { formatMoney } from 'Services/NumberHelper';
import { adaptEventsOfChild } from 'recharts/lib/util/types';
import classnames from 'classnames';
import { isNum } from 'Utils/types';
import { SvgSymbol } from 'Components/Chart/components/SvgSymbol';
import { translate } from 'Services/Translator';
const TableLegendRoot = styled.table `
  --cell-padding-x: 8px;
  --cell-padding-y: 8px;
  --row-height: 39px;
  width: 100%;
  border-collapse: collapse;

  td {
    vertical-align: middle;
    height: var(--row-height, auto);
    padding: var(--cell-padding-y) var(--cell-padding-x);
    white-space: nowrap;
    //&:not(:first-child):not(:last-child-child)

    span {
      display: inline-block;
      white-space: nowrap;
      flex-wrap: nowrap;
      align-items: center;
    }

    .c-primary {
      color: ${p => p.theme.palette.primary.$500};
    }

    &.cell-stack {
      text-align: right;
      padding-right: 8px;

      & + td {
        padding-left: 8px;
        text-align: left;

        span:first-child {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            display: block;
            height: 100%;
            width: 1px;
            top: 0;
            left: -9px;
            border-left: 1px solid ${p => p.theme.palette.dark.$200};
          }
        }
      }
    }
  }

  thead {
    td {
      text-transform: uppercase;
      color: ${p => p.theme.palette.neutral.$200};
      ${p => p.theme.typography.pretitle.css()};
      border: none !important;

      > span {
      }

      &.cell-money {
        ${p => p.theme.typography.medium.css()};

        > span {
        }

        > small {
        }
      }
    }
  }

  tbody {
    tr {
      transition: opacity ease-in-out 300ms;
    }

    td {
      ${p => p.theme.typography.medium.css()};
    }
  }

  tr > td {
    border-style: solid;
    border-color: ${p => p.theme.palette.neutral.$100};
    border-width: 1px 0 0 0;
  }

  tfoot {
    color: ${p => p.theme.palette.neutral.$200};
    .cell-money {
      color: ${p => p.theme.palette.neutral.$900};
      font-weight: 600;
    }
  }

  &.is-focused {
    tbody {
      tr:not(.is-active) {
        opacity: .5;
      }
    }
  }
`;
export const BalancePieChartLegend = ({ ...props }) => {
    const { payload, activeIndex, totalValue } = props;
    const totalData = React.useMemo(() => {
        return {
            name: translate('dashboard.sections.financials.data.total'),
            balance: totalValue
        };
    }, [totalValue]);
    return (_jsx("div", { style: { overflowX: 'auto' }, children: _jsxs(TableLegendRoot, { className: classnames(isNum(activeIndex) && 'is-focused'), children: [_jsx("thead", { style: { display: 'block' }, children: _jsxs("tr", { children: [_jsx("td", { style: { width: '100%' }, children: _jsx("span", { children: translate('bank.detailView.property') }) }), _jsx("td", { align: "right", style: { paddingRight: payload.length > 5 ? '25px' : 0 }, children: _jsx("span", { children: translate('bank.detailView.balance') }) })] }) }), _jsx("tbody", { style: { height: '234px', overflowY: 'auto', display: 'block' }, children: payload.map((entry, i) => {
                        const { payload: data, color, } = entry;
                        const balance = data.balance;
                        return (_jsxs("tr", { ...adaptEventsOfChild(props, entry, i), className: classnames(activeIndex === i && 'is-active'), children: [_jsxs("td", { className: "cell-name", style: { width: '100%' }, children: [_jsx(SvgSymbol, { color: color }), _jsx("span", { children: entry.value })] }), _jsx("td", { className: "cell-stack", children: _jsx("span", { children: formatMoney(balance) }) })] }, `item-${i}`));
                    }) }), _jsx("tfoot", { style: { display: 'block' }, children: _jsxs("tr", { children: [_jsx("td", { style: { width: '100%' }, children: "Total" }), _jsx("td", { align: "right", className: "cell-money", children: _jsx("span", { children: formatMoney(totalData.balance) }) })] }) })] }) }));
};
