import { jsx as _jsx } from "react/jsx-runtime";
import useStableMemo from '@restart/hooks/useStableMemo';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import { IdProvider } from 'Hooks/useId';
import { ThemeProvider } from '@f2w/theme';
import Portfolio from 'Models/Portfolio';
import PortfolioContext from 'Components/Base/PortfolioContext';
import { DispatcherProvider } from 'Components/Dispatcher/DispatcherProvider';
export const AppProvider = ({ children }) => {
    const dispatch = useForceUpdate();
    const portfolio = useStableMemo(() => Portfolio.instance({ dispatch }), []);
    return (_jsx(IdProvider, { children: _jsx(PortfolioContext.Provider, { value: portfolio, children: _jsx(ThemeProvider, { children: _jsx(DispatcherProvider, { children: children }) }) }) }));
};
