import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Body, Container, Content } from 'Atoms/Layout/components';
import { translate } from 'Services/Translator';
import { ModalNew } from 'Components/Modal';
import { CreditorServices } from 'Pages/Creditor/services';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import AssetServices from 'Pages/Asset/services/assetServices';
import { CreditorPaymentContent } from 'Pages/Creditor/components/CreditorView/components/CreditorPaymentContent';
import { CreditorManualPaymentForm } from 'Pages/Creditor/components/CreditorView/components/CreditorManualPaymentForm';
import { CreditorProcessStep, CreditorState } from 'Pages/Creditor/enums';
import { handleResponseError } from 'Utils/client';
import { MediaPreview } from 'Atoms/Media';
const stateToForm = (state) => {
    switch (state) {
        case CreditorState.INFO_PENDING:
        case CreditorState.QR_COMPLETED: {
            return CreditorProcessStep.PROCESSING;
        }
        case CreditorState.INFO_CONFIRMED: {
            return CreditorProcessStep.INVOICE_POSITIONS;
        }
        case CreditorState.READY_FOR_PAYMENT:
        case CreditorState.INVOICE_ASSIGNED: {
            return CreditorProcessStep.APPROVAL;
        }
    }
};
export const CreditorView = ({ creditorInvoiceId, onClose, getCreditorInvoiceDetails = CreditorServices.getCreditorInvoiceDetails, deleteCreditorPayment = CreditorServices.deleteCreditorPayment, updateCreditorObjectData = CreditorServices.updateCreditorObjectData, getAssetsByRentalObjectForDropdown = AssetServices.getAssetsByRentalObjectForDropdown, createManualPayment = CreditorServices.createManualPayment, refreshTable, openProcessForm }) => {
    const [formOpen, setFormOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [creditorInvoice, setCreditorInvoice] = React.useState();
    const toastApi = useToastDispatcherApi();
    const loadDetails = React.useCallback(async (openModal) => {
        return getCreditorInvoiceDetails(creditorInvoiceId)
            .then(async (data) => {
            setCreditorInvoice(data);
            openModal && setModalOpen(true);
        })
            .catch(e => handleResponseError(e, {
            dispatcher: toastApi,
            defaultMessage: translate('client.response.error.loading'),
        }));
    }, [creditorInvoiceId]);
    const refreshDetails = React.useCallback(async (openModal) => {
        await setFormOpen(false);
        await refreshTable?.();
        await loadDetails();
    }, [creditorInvoiceId, refreshTable]);
    const onModalClose = React.useCallback(async () => {
        await onClose?.();
        await setFormOpen(false);
        await setModalOpen(false);
        await setCreditorInvoice(undefined);
    }, [onClose]);
    const onOpenEditForm = React.useCallback(() => {
        onModalClose();
        openProcessForm?.({
            id: creditorInvoiceId,
            receipt: creditorInvoice.receipt,
            state: creditorInvoice.state,
            currentStep: stateToForm(creditorInvoice.state),
            initialStep: stateToForm(creditorInvoice.state)
        });
    }, [openProcessForm, creditorInvoice, creditorInvoiceId, onModalClose]);
    React.useEffect(() => {
        if (creditorInvoiceId) {
            loadDetails(true);
        }
    }, [creditorInvoiceId]);
    if (!creditorInvoice) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(ModalNew, { show: modalOpen, onHide: onModalClose, size: 'detailView', children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onModalClose, children: _jsxs(ModalNew.Title, { children: [translate('creditorInvoice'), " - ", creditorInvoice.label ?? translate('notAvailable')] }) }), _jsxs(Body, { split: true, children: [_jsx(Container, { children: formOpen ? (_jsx(CreditorManualPaymentForm, { createManualPayment: createManualPayment, creditorId: creditorInvoice.id, propertyId: creditorInvoice.property.id, payerBankAccountId: creditorInvoice.payerBankAccountId, creditorLabel: creditorInvoice.label, creditorAmountRemaining: creditorInvoice.amountRemaining, financialAccountId: creditorInvoice.payerFinancialAccountId, closeForm: refreshDetails })) : (_jsx(CreditorPaymentContent, { creditorInvoice: creditorInvoice, updateCreditorObjectData: updateCreditorObjectData, getAssetsByRentalObjectForDropdown: getAssetsByRentalObjectForDropdown, refreshDetails: refreshDetails, deleteCreditorPayment: deleteCreditorPayment, onOpenNewPaymentForm: () => setFormOpen(true), onOpenEditForm: onOpenEditForm })) }), _jsx(Container, { style: { borderLeft: '1px solid #d7d8db' }, children: _jsx(Content, { style: { justifyContent: 'center', alignItems: 'center' }, children: _jsx(MediaPreview, { ...creditorInvoice?.receipt }) }) })] })] }));
};
