import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import createChainedFunction from 'Components/Base/createChainedFunction';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import { translate } from 'Services/Translator';
import { isString, toObject, toStr } from 'Utils/types';
import { useDispatcherApi } from 'Components/Dispatcher/useDispatcher';
import { Icon } from 'Atoms/Icon';
import { downloadFileFromUrl, textContent } from '@f2w/utils';
import { useLink } from './useLink';
export const ButtonRoot = styled.button ``;
const useLinkProps = (to, props) => {
    if (to !== undefined) {
        const linkArgs = [to].flat(2);
        return useLink(linkArgs?.[0], { ...props, ...linkArgs?.[1] });
    }
};
function useButtonProps({ to: _to, ...props }) {
    let isTrivialHref;
    const linkProps = useLinkProps(_to, { target: props.target });
    if (linkProps) {
        Object.assign(props, linkProps);
    }
    else if (props.href !== undefined) {
        props.href = toStr(props.href).trim() || '#';
        isTrivialHref = props.href === '#';
    }
    return {
        to: linkProps?.onClick,
        isTrivialHref,
        ...props,
    };
}
export const UnstyledButton = forwardRef(({ icon, active, ...initialProps }, ref) => {
    const isMounted = useRef(false);
    const btnRef = useRef();
    const [actionLoading, setActionLoading] = useState();
    const dispatcher = useDispatcherApi();
    const props = useButtonProps({
        ...initialProps,
        loading: actionLoading || initialProps.loading,
    });
    const { isTrivialHref, to, href, label, loading, onClickAsync, disabled, download, type, nested, confirm, tooltip, truncate, ...buttonProps } = {
        ...props,
        ref: useMergedRefs(btnRef, ref),
    };
    buttonProps.onClick = (async (event) => {
        const { disabled, loading } = props;
        if (disabled || loading) {
            event.stopPropagation();
            event.preventDefault();
            return;
        }
        const { nested, onClickAsync, onClick = onClickAsync, download, target, confirm, } = props;
        const isAsyncAction = !!onClickAsync;
        const isAsync = confirm || download != null || isAsyncAction;
        if (nested || confirm)
            event.stopPropagation();
        if (!onClick && !isAsync) {
            if (isTrivialHref)
                event.preventDefault();
            return;
        }
        event.preventDefault();
        const innerClick = async () => {
            if (!confirm || await dispatcher.modal.confirmAsync({
                btnConfirm: translate('btn.confirm'),
                message: translate('Are you sure?'),
                ...(isString(confirm) ? { message: confirm } : toObject(confirm)),
            })) {
                const { href, label } = props;
                if (!to && !isTrivialHref && href != null) {
                    if (download != null) {
                        await downloadFileFromUrl({
                            url: href,
                            fileName: (isString(download) ? download : label),
                            onError: (e, p) => {
                                dispatcher?.toast?.error?.({
                                    message: `Error downloading file ${p.fileName}`
                                });
                            },
                            onStart: (p) => {
                                dispatcher?.modal?.show?.({
                                    message: translate('actions.downloading'),
                                    help: p.fileName,
                                    loading: true,
                                    onCancel: false,
                                });
                            },
                            onFinish: (p) => {
                                dispatcher?.modal?.close?.();
                            },
                        });
                    }
                    else {
                        window.open(href, target);
                    }
                }
                if (onClick) {
                    return onClick(event);
                }
            }
        };
        isMounted.current && setActionLoading(true);
        await innerClick()
            .catch(error => {
            console.error('Button onClick error', {
                target: btnRef.current,
                error,
            });
            throw error;
        })
            .finally(() => isMounted.current && setActionLoading(false));
    });
    if (active)
        buttonProps['aria-selected'] = true;
    if (loading)
        buttonProps['aria-busy'] = true;
    if (disabled)
        buttonProps['aria-disabled'] = true;
    if (loading)
        icon = 'spinner';
    buttonProps.className = classnames(props.className, {
        'is-selected active': active,
        'is-disabled': disabled,
        'is-loading': loading,
        'fw-btn-icon': icon && !(props.children || label),
    });
    buttonProps.children = _jsxs(_Fragment, { children: [icon && _jsx(Icon, { icon: icon }), label && _jsx("span", { children: label }), props.children] });
    if (label && truncate)
        buttonProps['title'] = textContent(label);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);
    if (href != null) {
        const handleKeyDown = (event) => {
            if (event.key === ' ') {
                event.preventDefault();
                buttonProps.onClick?.(event);
            }
        };
        if (isTrivialHref) {
            buttonProps['role'] = buttonProps['role'] || type || 'button';
        }
        if (disabled) {
            buttonProps.tabIndex = -1;
            buttonProps['aria-disabled'] = true;
        }
        return (_jsx(ButtonRoot, { ...buttonProps, as: 'a', href: href, onKeyDown: createChainedFunction(handleKeyDown, buttonProps.onKeyDown) }));
    }
    return _jsx(ButtonRoot, { ...buttonProps, disabled: disabled, type: type || 'button' });
});
UnstyledButton.displayName = 'Button';
