import generateRoute from "Services/Router";
import client from "Utils/client";
const NotificationManager = {
    list: (params) => {
        return client.get(generateRoute('api.notifications.list'), { params })
            .then(r => Array.isArray(r.data) ? r.data : []);
    },
    markAsRead: (id) => {
        const ids = Array.isArray(id) ? id.join(',') : id;
        return client.post(generateRoute('api.notifications.markRead'), {}, {
            params: { ids }
        }).then(r => r.data);
    },
};
export default NotificationManager;
