import generateRoute from "Services/Router";
import client from "Utils/client";
const listSubscriptions = () => {
    const route = generateRoute('api.subscription.list');
    return client
        .get(route)
        .then(response => response.data);
};
export default {
    listSubscriptions
};
