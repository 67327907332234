import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useDropzone as useReactDropzone } from 'react-dropzone';
import { formatBytes, objectOmit, orFunction, setDebugVariable, tryFunction } from '@f2w/utils';
import { Icon, Loader } from 'Atoms/index';
import { UploadManager } from 'Components/Dispatcher/UploadManager';
import { translate } from 'Services/Translator';
function useUploadArea({ preventDropOnDocument, ...iProps }) {
    const { options, upload } = UploadManager.use(iProps);
    const props = objectOmit(iProps, options);
    const onDrop = useCallback((f1, f2) => upload(f1, f2), [upload]);
    const dropzone = useReactDropzone({
        onDrop,
        preventDropOnDocument,
        multiple: options.multiple,
        accept: options.accept.types,
        noClick: !props.isEmpty,
        disabled: props.disabled,
    });
    const { isDragReject, isDragActive, isDragAccept, open: openDialog, } = dropzone;
    const status = (isDragActive ? (isDragAccept ? 'accept' : isDragReject ? 'reject' : 'active') : undefined);
    return {
        options,
        dropzone,
        openDialog,
        props: {
            ...props,
            status,
        },
    };
}
export function UploadArea({ children, minHeight, placeholderIcon, placeholderText = translate('uploadManager.placeholder'), placeholderInfo, ...initialProps }) {
    const [loading, setLoading] = useState();
    const api = useUploadArea(initialProps);
    const { props: { isEmpty, disabled, status, }, options, dropzone: { getInputProps, getRootProps, }, } = api;
    setDebugVariable({ uploadArea: api });
    return (_jsx(_Fragment, { children: _jsxs(UploadArea.Root, { style: { minHeight }, "aria-busy": loading, "data-dropzone-status": status, className: classnames({
                'fw-dropzone--empty': isEmpty,
                'fw-dropzone--active': status,
            }), ...getRootProps(), tabIndex: -1, children: [!disabled && _jsx("input", { ...getInputProps() }), loading && _jsx(Loader, { floating: true }), isEmpty ? (_jsxs(UploadArea.Placeholder, { children: [_jsx(UploadArea.PlaceholderIcon, { icon: placeholderIcon ?? 'upload-cloud' }), placeholderText && (_jsx(UploadArea.PlaceholderText, { dangerouslySetInnerHTML: { __html: placeholderText } })), _jsx(UploadArea.PlaceholderSmall, { children: placeholderInfo !== undefined ? orFunction(placeholderInfo, api) : (_jsxs(_Fragment, { children: [translate('uploadManager.placeholderInfo', {
                                        size: formatBytes(options.maxSize),
                                    }), _jsx("br", {}), options.accept.extensions.join(', ')] })) })] })) : (_jsx(UploadArea.Main, { children: tryFunction(children, [api]) ?? null }))] }) }));
}
(function (UploadArea) {
    UploadArea.PlaceholderIcon = styled(Icon) ``;
    UploadArea.PlaceholderText = styled.div ``;
    UploadArea.PlaceholderSmall = styled.small ``;
    UploadArea.Placeholder = styled.div `
      padding: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 10px;
      color: var(--fw-color-neutral-200);

      ${p => p.theme.typography.body.fontStyles};
      transition: inherit;
      transition-property: color;
      //transition: color 240ms ease-in-out;

      > * {
        transition: inherit;
      }

      ${UploadArea.PlaceholderIcon} {
        font-size: 40px;
      }

      ${UploadArea.PlaceholderSmall} {
        font-size: 80%;
      }

      ${UploadArea.PlaceholderText} {
        color: var(--fw-dropzone-placeholder-active-color);
      }
    `;
    UploadArea.Main = styled.div `
      position: relative;
      border-radius: inherit;
      transition: inherit;
      transition-property: opacity;
    `;
    UploadArea.Root = styled.div `
      --fw-dropzone-area-border-radius: var(--fw-border-radius-lg);
      --fw-dropzone-area-border-color: var(--fw-color-neutral-90);

      position: relative;
      border-radius: var(--fw-dropzone-area-border-radius);
      background-color: var(--fw-color-dark-10);

      transition-duration: 250ms;
      transition-timing-function: ease-in-out;
      transition-property: color, border, background-color, box-shadow;


      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        transition: inherit;
        pointer-events: none;
      }

      &:before {
        border: 2px dashed var(--fw-dropzone-area-border-color);
      }

      &[aria-busy=true] {
        pointer-events: none;
      }

      &.fw-dropzone--empty:hover {
        --fw-dropzone-area-border-color: var(--fw-color-neutral-200);
        cursor: pointer;

        ${UploadArea.Placeholder} > div {
          color: var(--fw-color-neutral-300);
        }
      }

      &.fw-dropzone--active {
        --fw-dropzone-area-border-color: var(--fw-color-primary-500);

          &[data-dropzone-status="reject"] {
            --fw-dropzone-area-border-color: var(--fw-color-error-500);
          }
        ${UploadArea.Main} {
          opacity: .5;
        }

        &:after {
          z-index: 110;
          box-shadow: 0 0 0 3px inset white;
        }

        &:before {
          z-index: 111;
          //box-shadow: 0 0 20px -3px inset var(--fw-dropzone-area-border-color),
          //            0 0 20px -3px inset white;

          box-shadow: 0 0 0 1px white,
          0 0 10px -1px var(--fw-dropzone-area-border-color),
          0 0 60px 35px inset white;
          //box-shadow: 0 0 0 1px white,
          //0 0 10px -1px var(--fw-dropzone-area-border-color),
          //0 0 60px 35px inset white,
          //0 0 40px 15px inset var(--fw-dropzone-area-border-color);
        }
      }
    `;
})(UploadArea || (UploadArea = {}));
