import * as React from 'react';
export function fc(displayName, c) {
    c.displayName = displayName;
    return c;
}
export function frc(displayName, render) {
    const ForwardedComponent = React.forwardRef(render);
    ForwardedComponent.displayName = displayName;
    return ForwardedComponent;
}
export function fixedForwardRef(render) {
    return React.forwardRef(render);
}
