import { translate } from 'Services/Translator';
import generateRoute from "Services/Router";
import UserOnboardingTask from "Pages/GettingStartedPage/types/UserOnboardingTask";
const data = [
    {
        title: translate("start.section.profile.title"),
        description: translate("start.section.profile.description"),
        steps: [
            {
                property: UserOnboardingTask.ACCOUNT_ADDRESS,
                title: translate("start.section.profile.step.addAddress"),
                buttonTitle: translate("start.section.profile.stepButton.addAddress"),
                url: generateRoute('dashboard', { openAccountEditForm: 1 }),
            },
            {
                property: UserOnboardingTask.PROFILE_PHONE_NUMBER,
                title: translate("start.section.profile.step.addPhoneNumber"),
                buttonTitle: translate("start.section.profile.stepButton.addPhoneNumber"),
                url: generateRoute("profile")
            },
            {
                property: UserOnboardingTask.PROFILE_JOB_TITLE,
                title: translate("start.section.profile.step.addJobTitle"),
                buttonTitle: translate("start.section.profile.stepButton.addJobTitle"),
                url: generateRoute("profile"),
            },
            {
                property: UserOnboardingTask.PROFILE_BIRTHDATE,
                title: translate("start.section.profile.step.addBirthdate"),
                buttonTitle: translate("start.section.profile.stepButton.addBirthdate"),
                url: generateRoute("profile"),
            },
            {
                property: UserOnboardingTask.PROFILE_IMAGE,
                title: translate("start.section.profile.step.addProfileImage"),
                buttonTitle: translate("start.section.profile.stepButton.addProfileImage"),
                url: generateRoute("profile")
            },
        ],
        docs: null,
        video: null
    },
    {
        title: translate("start.section.baseData.title"),
        description: translate("start.section.baseData.description"),
        steps: [
            {
                property: UserOnboardingTask.MANDATE,
                title: translate("start.section.baseData.step.createMandate"),
                buttonTitle: translate("start.section.baseData.stepButton.createMandate"),
                url: generateRoute("mandates", { openUpsertModal: 1 }),
            },
            {
                property: UserOnboardingTask.RENTAL_OBJECT,
                title: translate("start.section.baseData.step.createObject"),
                buttonTitle: translate("start.section.baseData.stepButton.createObject"),
                url: generateRoute("baseData.createProperty"),
            },
            {
                property: UserOnboardingTask.BANK_ACCOUNT_FOR_PROPERTY,
                title: translate("start.section.baseData.step.createBankAccountForProperty"),
                buttonTitle: translate("start.section.baseData.stepButton.createBankAccountForProperty"),
                url: generateRoute('bank_accounts_overview_page', {
                    openCreateModal: 1
                }),
            },
            {
                property: UserOnboardingTask.ANCILLARY_EXPENSE_BASE_DATA,
                title: translate("start.section.baseData.step.createAncillaryExpenseBaseData"),
                buttonTitle: translate("start.section.baseData.stepButton.createAncillaryExpenseBaseData"),
                url: generateRoute('ancillaryExpenses.overview'),
            },
        ],
        docs: [
            {
                title: translate("start.section.baseData.docs.createMandate"),
                url: "https://help.fairwalter.com/mandat-erstellen",
            },
            {
                title: translate("start.section.baseData.docs.manageMasterData"),
                url: "https://help.fairwalter.com/ansicht-der-stammdaten",
            },
            {
                title: translate("start.section.baseData.docs.createObject"),
                url: "https://help.fairwalter.com/neue-liegenschaft-erfassen",
            },
            {
                title: translate("start.section.accounting.docs.createBankAccount"),
                url: "https://help.fairwalter.com/bankkonten-mit-buchhaltung-verbinden",
            },
            {
                title: translate("start.section.baseData.docs.connectYourBankAccount"),
                url: "https://help.fairwalter.com/qr-rechnung-einrichten",
            },
            {
                title: translate("start.section.baseData.docs.createAncillaryExpenseBaseData"),
                url: "https://help.fairwalter.com/nebenkostenabrechnung-verteilung-zaehlerstand-erstellen",
            },
        ],
        video: {
            title: translate("start.section.baseData.docs.videoTitle"),
            id: "KcEpTLGaNCE"
        }
    },
    {
        title: translate("start.section.propertyManagement.title"),
        description: translate("start.section.propertyManagement.description"),
        steps: [
            {
                property: UserOnboardingTask.CONTRACT,
                title: translate("start.section.baseData.step.createYourContract"),
                buttonTitle: translate("start.section.baseData.stepButton.createYourContract"),
                url: generateRoute("create_contract")
            },
            {
                property: UserOnboardingTask.CONTRACT_MODIFICATION,
                title: translate("start.section.propertyManagement.step.startContractModification"),
                buttonTitle: translate("start.section.propertyManagement.stepButton.startContractModification"),
                url: generateRoute('modify_contract'),
            },
            {
                property: UserOnboardingTask.MOVE_IN_PROCESS,
                title: translate("start.section.propertyManagement.step.startTenantMoveInProcess"),
                buttonTitle: translate("start.section.propertyManagement.stepButton.startTenantMoveInProcess"),
                url: generateRoute('onboard'),
            },
            {
                property: UserOnboardingTask.MOVE_OUT_PROCESS,
                title: translate("start.section.propertyManagement.step.startTenantMoveOutProcess"),
                buttonTitle: translate("start.section.propertyManagement.stepButton.startTenantMoveOutProcess"),
                url: generateRoute('offboard'),
            },
        ],
        docs: [
            {
                title: translate("start.section.propertyManagement.docs.startTenantMoveInProcess"),
                url: "https://help.fairwalter.com/mieter-inneneinzug-erfassen",
            },
            {
                title: translate("start.section.propertyManagement.docs.startContractModification"),
                url: "https://help.fairwalter.com/vertragsanpassung-mietzinsanpassung-etc",
            },
            {
                title: translate("start.section.propertyManagement.docs.mieterspiegelDownload"),
                url: "https://help.fairwalter.com/mieterspiegel-downloaden",
            },
        ],
        video: {
            title: translate("start.section.propertyManagement.docs.videoTitle"),
            playlistId: 'PLYJMA0djWbolFsm2nc1j7qyLVmvYFnYko',
        }
    },
    {
        title: translate("start.section.accounting.title"),
        description: translate("start.section.accounting.description"),
        steps: [
            {
                property: UserOnboardingTask.AUTOMATIC_INVOICING,
                title: translate("start.section.accounting.step.createAutomaticInvoicing"),
                buttonTitle: translate("start.section.accounting.stepButton.createAutomaticInvoicing"),
                url: generateRoute('automatic_invoices_view'),
            },
            {
                property: UserOnboardingTask.MANUAL_DEBITOR_INVOICE,
                title: translate("start.section.accounting.step.createManualDebitorInvoice"),
                buttonTitle: translate("start.section.accounting.stepButton.createManualDebitorInvoice"),
                url: generateRoute('list_debitor_invoices'),
            },
            {
                property: UserOnboardingTask.CREDITOR_INVOICE,
                title: translate("start.section.accounting.step.createCreditorInvoice"),
                buttonTitle: translate("start.section.accounting.stepButton.createCreditorInvoice"),
                url: generateRoute('creditorInvoices.overview')
            },
            {
                property: UserOnboardingTask.ACCOUNTING_RECORD,
                title: translate("start.section.accounting.step.createManualAccountingRecord"),
                buttonTitle: translate("start.section.accounting.stepButton.createManualAccountingRecord"),
                url: generateRoute('view_accounting'),
            }
        ],
        docs: [
            {
                title: translate("start.section.accounting.docs.createAutomaticInvoicing"),
                url: "https://help.fairwalter.com/sollstellung-miete",
            },
            {
                title: translate("start.section.accounting.docs.createDebitorInvoice"),
                url: "https://help.fairwalter.com/debitorenrechnung-erstellen-bearbeiten",
            },
            {
                title: translate("start.section.accounting.docs.createCreditorInvoice"),
                url: "https://help.fairwalter.com/kreditorenrechnung-erfassen-loeschen",
            },
            {
                title: translate("start.section.accounting.docs.createManualAccountingRecord"),
                url: "https://help.fairwalter.com/korrektur-buchungen-vorjahre",
            }
        ],
        video: {
            title: translate("start.section.accounting.docs.videoTitle"),
            playlistId: 'PLYJMA0djWbolAJ2ABXJKG4n09vEeSt-hP'
        }
    },
    {
        title: translate("start.section.facilityManagement.title"),
        description: translate("start.section.facilityManagement.description"),
        steps: [
            {
                property: UserOnboardingTask.SERVICE_PROVIDER,
                title: translate("start.section.propertyManagement.step.addServiceProvider"),
                buttonTitle: translate("start.section.propertyManagement.stepButton.addServiceProvider"),
                url: generateRoute('serviceProvider.list', { openUpsertModal: 1 }),
            },
            {
                property: UserOnboardingTask.ASSET,
                title: translate("start.section.facilityManagement.step.editAssets"),
                buttonTitle: translate("start.section.facilityManagement.stepButton.editAssets"),
                url: generateRoute('list_assets', { openUpsertModal: 1 }),
            },
            {
                property: UserOnboardingTask.DAMAGE,
                title: translate("start.section.facilityManagement.step.reportDamage"),
                buttonTitle: translate("start.section.facilityManagement.stepButton.reportDamage"),
                url: generateRoute('list_damages', { openUpsertModal: 1 }),
            },
        ],
        docs: [
            {
                title: translate("start.section.facilityManagement.docs.addServiceProvider"),
                url: "https://help.fairwalter.com/dienstleister-innen-erfassen",
            },
            {
                title: translate("start.section.facilityManagement.docs.manageServiceProvider"),
                url: "https://help.fairwalter.com/dienstleister-innen-verwalten",
            },
            {
                title: translate("start.section.facilityManagement.docs.reportDamage"),
                url: "https://help.fairwalter.com/reparaturen-schaeden-dokumentieren",
            },
        ],
        video: {
            title: translate("start.section.facilityManagement.docs.videoTitle"),
            playlistId: 'PLYJMA0djWbokx2WbjxbCfMeR1p_b8amf3'
        }
    }
];
export default data;
