import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { isFunction, isObject, isProdEnv, isString } from '@f2w/utils';
import useTimeout from '@restart/hooks/useTimeout';
import classnames from 'classnames';
import * as Schema from '../schema';
import { FormTheme } from '../theme';
import { renderContent } from '../renderContent';
import { translate } from 'Services/Translator';
export * from './general';
export class AbstractType {
    options = Object.create(null);
    schema;
    _context = null;
    parent;
    field = null;
    constructor(_initialOptions, parent, schema) {
        this.parent = parent;
        this.field = null;
        const { default: defaultValueOld, constraints, ...options } = this.__configureOptions({
            ...this.__getDefaultOptions(),
            ..._initialOptions,
        });
        const { required, nullable, defaultValue = defaultValueOld, errorLabel, } = options;
        this.options = options;
        this.schema = schema.meta({ getType: () => this });
        this.updateSchema({
            nullable,
            required,
            defaultValue,
            constraints,
            errorLabel,
        });
        this.validate = this.validate.bind(this);
        this.render = this.render.bind(this);
        this._render = this._render.bind(this);
        this.__provideField = this.__provideField.bind(this);
        this.__renderField = this.__renderField.bind(this);
    }
    isCompound() {
        return this.isArray() || this.isObject();
    }
    isArray() {
        return false;
    }
    isObject() {
        return false;
    }
    isInherited() {
        return this.isObject() && this.options.inheritData;
    }
    __configureOptions(options) {
        return options;
    }
    log(message, ...args) {
        this.field.log(message, ...args);
    }
    updateSchema({ required, nullable, constraints, errorLabel, defaultValue, }) {
        required = required ?? (nullable != null ? !nullable : true);
        nullable = nullable ?? !required;
        this.mutate((schema) => {
            defaultValue === undefined || schema.default(defaultValue);
            (nullable || (nullable == null && defaultValue === undefined && !required)) && schema.nullable();
            const label = errorLabel || this.options?.label;
            schema.label(isString(label) ? label : 'This');
            if (required) {
                isString(required)
                    ? schema.required(required)
                    : schema.required(translate('form.validation.fieldIsRequired'));
            }
            else {
                schema.notRequired();
            }
            resolveSchemaConstraints(schema, constraints);
        });
    }
    getFormik() {
        return this._context?.form;
    }
    clone() {
        const clone = Object.create(Object.getPrototypeOf(this));
        return clone;
    }
    __getDefaultOptions() {
        return {};
    }
    get isRoot() {
        return !this.parent;
    }
    get isNullable() {
        return this.schema.spec.nullable;
    }
    get isRequired() {
        return this.schema.spec.presence === 'required';
    }
    getRoot() {
        return this.parent ? this.parent.getRoot() : this;
    }
    getDefaultValue() {
        return this.schema.getDefault();
    }
    get value() {
        return this.field?.value;
    }
    getResolvedValue() {
        return this.field.value;
    }
    getFormValue() {
        if (this.isCompound()) {
            let data = {};
            Object.values(this.children).forEach(child => {
                const value = child.getFormValue();
                if (child.isCompound()) {
                    Object.assign(data, value);
                }
                else if (value !== undefined) {
                    data[child.field.path] = value;
                }
            });
            return data;
        }
        return this.field.value;
    }
    getFormData() {
        const data = this.getFormValue();
        const formData = new FormData();
        for (const name of Object.keys(data)) {
            formData.set(name, data[name]);
        }
        return formData;
    }
    get context() {
        return this._context || {};
    }
    getContext() {
        if (this.isRoot) {
            return this._context ?? (this._context = {});
        }
        return this.getRoot().getContext();
    }
    _createInternal({ parent, context, ...props }) {
        this.parent = parent;
        this._context = context ?? parent?.context ?? {};
        this.field = this.field ?? this._createField(props);
    }
    render(props) {
        return this.field && renderContent(this.__provideField, {
            key: `${this.field.id}--provider`,
            ...props,
        });
    }
    isVisible() {
        const options = this.options;
        return isFunction(options.isVisible) ? options.isVisible(this) : options.isVisible ?? true;
    }
    __provideField(props) {
        this.field.isVisible = props?.isVisible ?? this.isVisible();
        if (false === this.field.isVisible) {
            return null;
        }
        return renderContent(this.__renderField, {
            key: `${this.field.id}--field`,
            ...props,
        });
    }
    __useField(iProps) {
        this.field.use(iProps);
        this.__registerField(this.field);
        this._useField(iProps);
    }
    __registerField(_field) {
        const [debouncedValidator, debounceDelay = 600] = this._validateDebounced();
        const { set, clear } = useTimeout();
        const validateFn = React.useCallback((value) => {
            return new Promise((resolve, reject) => {
                const shouldValidate = _field.shouldValidate(value);
                if (!shouldValidate) {
                    return resolve(_field.state.error);
                }
                _field.state.validatedValue = value;
                _field.state.validated = true;
                clear();
                const onSuccess = (error = '') => {
                    resolve(_field.state.error = error);
                };
                const onError = (err) => {
                    if (err.name === 'ValidationError') {
                        onSuccess(err.message);
                    }
                    else {
                        if (!isProdEnv()) {
                            console.warn(`Warning: An unhandled error was caught during validation in <Formik validationSchema />`, err);
                        }
                        reject(err);
                    }
                };
                this.validate(value).then((value) => (debouncedValidator
                    ? set(() => debouncedValidator(value).then(onSuccess, onError), debounceDelay)
                    : onSuccess()), onError);
            });
        }, [_field.path]);
        const { registerField, unregisterField } = this.getFormik();
        React.useEffect(() => {
            if (_field.path && !_field.isCompound) {
                if (_field.isRoot) {
                    _field.setTouched({}, false);
                    _field.reset();
                }
                registerField(_field.path, {
                    validate: validateFn,
                });
            }
            return () => {
                if (_field.path && !_field.isCompound) {
                    unregisterField(_field.path);
                }
            };
        }, [registerField, unregisterField, _field.path, validateFn]);
    }
    __renderField(iProps) {
        this.__useField(iProps);
        return this._render(iProps);
    }
    _useField(props) { }
    _render({ label, ...iProps } = {}) {
        const props = {
            ...iProps,
            field: this.field,
            id: `${this.field.id}--row`,
            'aria-invalid': this.field.showError,
            className: classnames(iProps?.className),
            children: iProps?.children || _jsxs(_Fragment, { children: [label !== false && this._renderLabel(), this._renderWidget(iProps), this._renderError()] }),
        };
        return _jsx(FormTheme.Row, { ...props }, props.id);
    }
    _renderLabel(iProps) {
        const props = {
            ...iProps,
            field: this.field,
            id: `${this.field.id}--label`,
            className: classnames('fw-form__label', iProps?.className),
        };
        return _jsx(FormTheme.Label, { ...props }, props.id);
    }
    _renderError(iProps) {
        const props = {
            ...iProps,
            field: this.field,
            id: `${this.field.id}--error`,
            className: classnames('fw-form__error', iProps?.className),
        };
        return _jsx(FormTheme.Error, { ...props }, props.id);
    }
    throwValidationError(error) {
        throw new Schema.ValidationError(error, this.field.value, this.field.path);
    }
    validate(value) {
        return this.schema.validate(value, { abortEarly: true, path: this.field?.path });
    }
    _validateDebounced() {
        return [];
    }
    mutate(fn) {
        this.schema.withMutation(fn);
        return this;
    }
}
function resolveSchemaConstraints(schema, constraints) {
    if (isObject(constraints)) {
        for (const key in constraints) {
            if (isFunction(schema[key])) {
                let args = constraints[key];
                if (!Array.isArray(args)) {
                    if (args == null)
                        continue;
                    args = [args];
                }
                schema = schema[key](...args);
            }
        }
    }
    return schema;
}
