import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalNew } from 'Components/Modal';
import React from 'react';
import { translate } from 'Services/Translator';
import { Button } from 'Components/Atoms/Button';
import DocumentTemplateServices from '../../services/documentTemplateServices';
import { UseStyled } from '@f2w/theme';
import Spinner from 'Components/Spinner';
import { ErrorMessage, Field, Formik } from "formik";
import FormSelect from "Components/Atoms/Form/FormSelect";
import FormErrorMessage from "Components/Atoms/Form/FormErrorMessage";
import * as yup from "yup";
const ModalBodyContainer = UseStyled.styled('div', () => ({}));
const FormValidation = yup.object().shape({
    mandateId: yup
        .number()
        .required(translate('form.validation.fieldIsRequired'))
});
export const LegacyCloneTemplateModal = ({ templateToClone, onClose, onCloneSuccess }) => {
    const [loading, setLoading] = React.useState(false);
    const [cloningTemplateLoading, setCloningTemplateLoading] = React.useState(false);
    const [availableMandates, setAvailableMandates] = React.useState([]);
    React.useEffect(() => {
        if (templateToClone) {
            setLoading(true);
            DocumentTemplateServices.getAvailableMandatesForTemplate(templateToClone.templateInstanceKey)
                .then(mandates => setAvailableMandates(mandates))
                .finally(() => setLoading(false));
        }
        else {
            setAvailableMandates([]);
            setLoading(false);
            setCloningTemplateLoading(false);
        }
    }, [templateToClone]);
    const formRef = React.useRef();
    const handleSubmit = React.useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [formRef]);
    const mandateOptions = React.useMemo(() => {
        return availableMandates.map(mandate => ({
            value: mandate.id,
            label: mandate.name
        }));
    }, [availableMandates]);
    const onCloneConfirmed = React.useCallback(({ mandateId }) => {
        setCloningTemplateLoading(true);
        DocumentTemplateServices
            .cloneTemplateForMandate(templateToClone.id, mandateId)
            .then(onCloneSuccess)
            .finally(() => setCloningTemplateLoading(false));
    }, [templateToClone, onCloneSuccess]);
    if (loading) {
        return (_jsx(_Fragment, {}));
    }
    return (_jsxs(ModalNew, { show: !!templateToClone, size: { maxWidth: '500px' }, children: [_jsxs(ModalNew.Header, { closeButton: !cloningTemplateLoading, onHide: onClose, children: [_jsx(ModalNew.Title, { children: translate('template.cloneForMandate') }), _jsx(Spinner, { isVisible: cloningTemplateLoading })] }), _jsx(ModalNew.Body, { children: _jsx(ModalBodyContainer, { children: _jsx(Formik, { innerRef: formRef, initialValues: {}, onSubmit: onCloneConfirmed, validationSchema: FormValidation, children: _jsxs(_Fragment, { children: [_jsx("label", { htmlFor: 'mandateId', children: translate('template.availableMandates') }), _jsx(Field, { component: FormSelect, maxMenuHeight: '200px', name: 'mandateId', options: mandateOptions }), _jsx(ErrorMessage, { component: FormErrorMessage, name: 'mandateId' })] }) }) }) }), _jsxs(ModalNew.Footer, { children: [_jsx(Button, { "$color": 'secondary-outline', "$size": 'md', onClick: onClose, style: { marginRight: '0.5rem' }, disabled: cloningTemplateLoading, children: translate('btn.cancel') }), _jsx(Button, { "$color": 'primary-outline', "$size": 'md', onClick: handleSubmit, disabled: cloningTemplateLoading, children: translate('btn.save') })] })] }));
};
