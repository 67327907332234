import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useEventCallback } from '@restart/hooks';
import { toPx } from '@f2w/theme';
import { getLocale } from "Services/Translator";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { InputTheme } from "Atoms/Input";
import { EditorVariants } from "Atoms/Input/controls/TextEditorConfig";
import './TextEditor.styles.scss';
export function TextEditor({ editorType = 'full', initialValue, placeholder, placeholderOptions, hasError, onChange: _onChange, onBlur: _onBlur, ...props }) {
    const locale = getLocale();
    const typeProps = React.useMemo(() => {
        const variantProps = (EditorVariants?.[editorType?.toLowerCase()] ?? EditorVariants.full);
        return {
            ...variantProps,
            config: {
                ...variantProps.config,
                placeholderConfig: {
                    types: placeholderOptions
                },
            }
        };
    }, [editorType, placeholderOptions, locale]);
    const onChange = useEventCallback((event, editor) => _onChange(editor.getData()));
    const onBlur = useEventCallback((event, editor) => _onBlur(editor.getData()));
    const onFocus = useEventCallback((event, editor) => {
        const element = document.querySelector(".ck-powered-by-balloon");
        if (element !== null)
            element.remove();
    });
    const vars = InputTheme.useVars({
        $variant: hasError ? 'error' : undefined
    });
    return (_jsx("div", { style: {
            '--fw-text-editor__border-size': toPx(1),
            '--fw-text-editor__border-radius': toPx(vars.borderRadius),
            '--fw-text-editor__border-color': vars.borderColor,
            '--fw-text-editor__active-color': vars.stateColor?.$500 ?? vars.activeColor.$500,
        }, className: `ck-editor-${editorType?.toLowerCase()}`, children: _jsx(CKEditor, { ...props, ...typeProps, data: initialValue, onChange: onChange, onBlur: onBlur, onFocus: onFocus }) }));
}
