import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { DataTable } from '@f2w/data-table';
import { translate } from 'Services/Translator';
import moment from 'moment/moment';
import { Div } from 'Atoms/Layout';
import { formatMoney } from 'Services/NumberHelper';
export const AccountingRecordTable = DataTable.factory()(() => ({
    tableId: 'AccountingRecord',
    manualControl: false,
    useSortBy: false,
    usePagination: {
        hideInfo: true
    },
    initialState: {
        pageSize: 5
    },
    columns: {
        bookingDate: {
            Header: translate('debitor.position.bookingDate'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ? moment(value).format('DD.MM.YYYY') : translate('notAvailable') }))
        },
        label: {
            Header: translate('debitor.label'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ?? translate('notAvailable') }))
        },
        amount: {
            Header: translate('debitor.amount'),
            Cell: ({ value }) => (_jsx(Div, { textNoWrap: true, children: formatMoney(value) }))
        },
        debitAccountLabel: {
            Header: translate('finances.debitAccount'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ?? translate('notAvailable') }))
        },
        creditAccountLabel: {
            Header: translate('finances.creditAccount'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ?? translate('notAvailable') }))
        }
    }
}));
