import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { Badge } from "Atoms/Badge";
const colors = {
    "draft": "darkOutline",
    "sent": "primary",
    "overpaid": "danger",
    "paid": "success",
    "partially_paid": "warning",
};
const DebitorInvoiceState = ({ state, size }) => {
    const color = state ? colors[state] : null;
    return (_jsx(Badge, { "$color": color, "$size": size, children: state ? translate(`invoice.state.${state}`) : '' }));
};
export default DebitorInvoiceState;
