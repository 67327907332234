import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from "@f2w/data-table/table-types";
import { translate } from "Services/Translator";
import { Text } from "Components/Atoms";
import { MoneyType } from "@f2w/form";
const BaseTable = Table.factory()(() => ({
    columns: {
        label: {
            Header: translate('creditor.label'),
            Cell: ({ value }) => value ?? translate('notAvailable')
        },
        amount: {
            Header: translate('creditor.amount'),
            Cell: ({ value }) => MoneyType.useFormat(value) ?? translate('notAvailable')
        },
        objectData: {
            Header: _jsx(Text, { style: { overflowWrap: 'break-word' }, children: translate('creditor.propertyOrRentalObject') }),
            Cell: ({ row: { original: { rentalObject, property } }, onRentalObjectCLick, onPropertyClick, onCloseModal }) => {
                if (rentalObject) {
                    return (_jsxs(Text, { children: [_jsx(Text, { as: 'a', href: "#", primary: 500, onClick: e => {
                                    e.preventDefault();
                                    onCloseModal();
                                    onPropertyClick(property.id);
                                }, children: property.name }), "\u00A0/\u00A0", _jsx(Text, { as: 'a', href: "#", primary: 500, onClick: e => {
                                    e.preventDefault();
                                    onCloseModal();
                                    onRentalObjectCLick(rentalObject.id);
                                }, children: rentalObject.name })] }));
                }
                if (property) {
                    return (_jsx(Text, { as: 'a', href: "#", primary: 500, onClick: e => {
                            e.preventDefault();
                            onCloseModal();
                            onPropertyClick(property.id);
                        }, children: property.name }));
                }
                return translate('notAvailable');
            }
        }
    }
}));
export const PositionsInfoDataTable = Object.assign(BaseTable, {
    use({ data, onRentalObjectCLick, onPropertyClick, onCloseModal, }) {
        return PositionsInfoDataTable.useCreateTable({
            data,
            onRentalObjectCLick,
            onPropertyClick,
            onCloseModal,
        });
    }
});
