export * from "./types";
export * from "./plugins.list";
import * as plugins from "./plugins.list";
import { useColumnOrder, useFilters, useGlobalFilter, useGroupBy, useSortBy, useExpanded, usePagination, useRowSelect, useResizeColumns, useRowState, useDropzone, } from "./plugins.list";
export { plugins };
const pluginMap = {
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useRowState,
    useDropzone,
};
const pluginOrder = Object.keys(pluginMap);
export const forPlugins = (cb) => {
    pluginOrder.forEach(name => {
        cb(name, pluginMap[name]);
    });
};
