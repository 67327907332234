import { useCallback, useMemo, useRef } from 'react';
import hasClass from 'dom-helpers/hasClass';
function getMargins(element) {
    const styles = window.getComputedStyle(element);
    const top = parseFloat(styles.marginTop) || 0;
    const right = parseFloat(styles.marginRight) || 0;
    const bottom = parseFloat(styles.marginBottom) || 0;
    const left = parseFloat(styles.marginLeft) || 0;
    return { top, right, bottom, left };
}
export default function usePopperMarginModifiers(props) {
    const overlayRef = useRef(null);
    const margins = useRef(null);
    const arrowMargins = useRef(null);
    const popoverClass = 'fw-popover';
    const dropdownMenuClass = 'fw-dropdown-menu';
    const callback = useCallback((overlay) => {
        if (!overlay ||
            !(hasClass(overlay, popoverClass) ||
                hasClass(overlay, dropdownMenuClass)))
            return;
        margins.current = getMargins(overlay);
        overlay.style.margin = '0';
        overlayRef.current = overlay;
    }, [popoverClass, dropdownMenuClass]);
    const offset = useMemo(() => {
        return {
            name: 'offset',
            options: {
                offset: ({ placement }) => {
                    if (!margins.current)
                        return [0, 0];
                    const { top, left, bottom, right } = margins.current;
                    switch (placement.split('-')[0]) {
                        case 'top':
                            return [0, bottom];
                        case 'left':
                            return [0, right];
                        case 'bottom':
                            return [0, top];
                        case 'right':
                            return [0, left];
                        default:
                            return props?.offset ?? [0, 0];
                    }
                },
            },
        };
    }, [margins]);
    const arrow = useMemo(() => {
        return {
            name: 'arrow',
            options: {
                padding: () => {
                    if (!arrowMargins.current) {
                        return 0;
                    }
                    const { top, right } = arrowMargins.current;
                    const padding = top || right;
                    return {
                        top: padding,
                        left: padding,
                        right: padding,
                        bottom: padding,
                    };
                },
            },
        };
    }, [arrowMargins]);
    const popoverArrowMargins = useMemo(() => {
        return {
            name: 'popoverArrowMargins',
            enabled: true,
            phase: 'main',
            fn: () => undefined,
            requiresIfExists: ['arrow'],
            effect({ state }) {
                if (!overlayRef.current ||
                    !state.elements.arrow ||
                    !hasClass(overlayRef.current, popoverClass)) {
                    return undefined;
                }
                if (state.modifiersData['arrow#persistent']) {
                    const { top, right } = getMargins(state.elements.arrow);
                    const padding = top || right;
                    state.modifiersData['arrow#persistent'].padding = {
                        top: padding,
                        left: padding,
                        right: padding,
                        bottom: padding,
                    };
                }
                else {
                    arrowMargins.current = getMargins(state.elements.arrow);
                }
                state.elements.arrow.style.margin = '0';
                return () => {
                    if (state.elements.arrow)
                        state.elements.arrow.style.margin = '';
                };
            },
        };
    }, [popoverClass]);
    return [callback, [offset, arrow, popoverArrowMargins]];
}
