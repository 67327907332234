import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { translate } from 'Services/Translator';
import { CustomerDocumentsDataTable } from "Pages/CustomerDocuments/table/CustomerDocumentsDataTable";
import { CustomerDocumentServices } from "Pages/CustomerDocuments/services";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import { useReAssignCustomerDocumentForm } from "Pages/CustomerDocuments/hooks/useReAssignCustomerDocumentForm";
import { FormModal } from "@f2w/form";
import { useEditCustomerDocumentForm } from "Pages/CustomerDocuments/hooks/useEditCustomerDocumentForm";
import { useExportCustomerDocuments } from "Pages/CustomerDocuments/hooks/useExportCustomerDocumentsForm";
import ContractServices from "Pages/Contract/services/contractServices";
export const CustomerDocumentsPage = ({ spaceUsed, spaceAllowed, customerDocumentServices = CustomerDocumentServices, contractServices = ContractServices, }) => {
    const [modalSize, setModalSize] = useState('form');
    const form = useReAssignCustomerDocumentForm({
        onSave: () => instance.refresh(),
        reAssignMultipleCustomerDocuments: customerDocumentServices.reAssignMultipleCustomerDocuments
    });
    const editForm = useEditCustomerDocumentForm({
        onSave: () => instance.refresh(),
        updateCustomerDocument: customerDocumentServices.updateCustomerDocument
    });
    const exportForm = useExportCustomerDocuments({
        exportCustomerDocuments: customerDocumentServices.exportCustomerDocuments
    });
    const instance = CustomerDocumentsDataTable.use({
        customerDocumentServices,
        getContractsForDropdown: contractServices.getContractsForDropdown,
        spaceUsed,
        spaceAllowed,
        openReAssignModal: (ids) => form.openForm(ids),
        openEditModal: (data) => {
            editForm.openForm(data);
            setModalSize(!data.fileType.includes('image/') && !data.fileType.includes('application/pdf')
                ? 'form'
                : 'form-preview');
        },
        openExportModal: (data) => exportForm.openForm(data),
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, showFilters: true, title: translate('documents') }), _jsx(FormModal, { modal: form }), _jsx(FormModal, { noPadding: modalSize === 'form-preview', size: modalSize, modal: editForm }), _jsx(FormModal, { modal: exportForm })] }));
};
