import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { fc, frc } from '@f2w/theme';
import { isFirstChildTypeOf, renderChildren } from '@f2w/utils';
import { useTableTheme } from '../theme';
import { TableCell, Td } from './Cell';
import { useEventCallback } from '@restart/hooks';
export const TrRoot = styled.tr ``;
TrRoot.displayName = 'TableRow';
export var TableRowStatus;
(function (TableRowStatus) {
    TableRowStatus["SUCCESS"] = "success";
    TableRowStatus["ERROR"] = "error";
    TableRowStatus["WARNING"] = "warning";
})(TableRowStatus || (TableRowStatus = {}));
export const TableRow = forwardRef(({ children, expandableRows: _expandableRows, expanded: _expanded, loading, disabled, bodyRow = true, selected, status, ...props }, ref) => {
    const { Css, rowSpacing, rowDivider } = useTableTheme();
    if (!isFirstChildTypeOf(children, TableCell.displayName)) {
        children = _jsx(Td, { colSpan: true, children: children });
    }
    let expandedRows = _expanded && _expandableRows && renderChildren(_expandableRows, (row, { displayName, last }) => {
        if (displayName?.startsWith(TableRow.displayName)) {
            return React.cloneElement(row, {
                bodyRow: false,
                'data-group': last ? 'last' : true,
                className: classnames(Css.row__expandable, Css.row__expanded, last && Css.row__last, row?.props?.className),
            });
        }
        return row;
    });
    children = renderChildren(children, (cell, { displayName, first, last }) => {
        return React.cloneElement(cell, {
            className: classnames(cell?.props?.className, first && Css.cell__first, last && Css.cell__last),
        });
    });
    if (React.Children.count(children) < 1) {
        return null;
    }
    let row = _jsx(TrRoot, { "data-group": !!_expandableRows ? 'first' : undefined, ...props, ref: ref, "aria-busy": loading, "aria-disabled": disabled, "data-status": status || undefined, "aria-selected": selected || undefined, "aria-expanded": _expanded || undefined, className: classnames(Css.row, _expandableRows && _expanded && [
            Css.row__first,
            Css.row__expanded,
        ], props.className), children: renderChildren(children, (cell, { displayName, first, last }) => {
            return React.cloneElement(cell, {
                className: classnames(cell?.props?.className, first && Css.cell__first, last && Css.cell__last),
            });
        }) });
    return (_jsxs(React.Fragment, { children: [bodyRow && (rowSpacing || rowDivider) && _jsx(TableRowSpacer, {}), row, _expandableRows && expandedRows] }));
});
TableRow.displayName = 'TableRow';
export const TableRowSpacer = frc(`${TableRow.displayName}Spacer`, (props, ref) => {
    const { Css } = useTableTheme();
    return (_jsx(TrRoot, { ...props, className: classnames(Css.rowSpacer, props?.className), ref: ref, children: _jsx(TableCell, { type: 'spacer', colSpan: true }) }));
});
const TableRowExpandable = fc(`${TableRow.displayName}Expandable`, ({ expanded: initialExpanded, ...props }) => {
    const [expanded, setExpanded] = useState(initialExpanded);
    const elRef = React.useRef();
    const onClick = useEventCallback((e) => {
        if (e.target?.tagName === 'TD') {
            setExpanded(!expanded);
            if (elRef.current) {
                if (expanded) {
                    elRef.current.classList.add('is-expanded');
                    elRef.current.classList.remove('is-collapsed');
                }
                else {
                    elRef.current.classList.remove('is-expanded');
                    elRef.current.classList.add('is-collapsed');
                }
            }
        }
    });
    return _jsx(TableRow, { ...props, expanded: expanded, ref: elRef, onClick: onClick });
});
TableRowExpandable.displayName = `${TableRow.displayName}Expandable`;
export const Tr = Object.assign(TableRow, {
    Root: TrRoot,
    Divider: TableRowSpacer,
    Expandable: TableRowExpandable,
});
