import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { useFlexProps, useLayoutProps } from './hooks';
import { Root } from './theme';
export { useFlexProps, useLayoutProps, useColProps } from './hooks';
export const Box = forwardRef((props, ref) => {
    return (_jsx(Root, { ...useLayoutProps(props), ref: ref }));
});
export const Div = forwardRef((props, ref) => {
    return (_jsx(Root, { ...useLayoutProps(props), ref: ref }));
});
export const FlexRow = forwardRef((props, ref) => (_jsx(Div, { ...useFlexProps('row', props), ref: ref })));
export const FlexCol = forwardRef((props, ref) => (_jsx(Div, { ...useFlexProps('column', props), ref: ref })));
export const FlexBox = forwardRef((props, ref) => (_jsx(Div, { ...useFlexProps(props.direction ?? 'row', props), ref: ref })));
