import { AbstractFormType, BooleanType, CollectionType, SelectType, StringType } from '@f2w/form';
import { translate } from 'Services/Translator';
import React from "react";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { FinancialAccountType } from './FinancialAccountType';
import { useFinancialAccountsHook } from "Hooks/useFinancialAccountsHook";
export class AncillaryExpenseAccountType extends AbstractFormType {
    bankPositions = [1020, 1021];
    properties = [];
    financialAccounts = [];
    _useField(props) {
        super._useField(props);
        const [properties, setProperties] = React.useState([]);
        const { groupedFinancialAccountOptions } = useFinancialAccountsHook({ propertyId: this.field.value.propertyId });
        this.properties = properties;
        this.financialAccounts = groupedFinancialAccountOptions;
        const portfolio = usePortfolio();
        React.useEffect(() => {
            const allProperties = portfolio.getProperties();
            const groupedProperties = new Map();
            allProperties.forEach(property => {
                const mandateId = property?.mandate?.id;
                if (!mandateId)
                    return;
                if (groupedProperties.has(mandateId)) {
                    groupedProperties.set(mandateId, {
                        ...groupedProperties.get(mandateId),
                        options: [
                            ...groupedProperties.get(mandateId).options,
                            {
                                label: property.name,
                                value: property.id
                            }
                        ]
                    });
                }
                else {
                    groupedProperties.set(mandateId, {
                        label: property.mandate.name,
                        options: [{
                                label: property.name,
                                value: property.id
                            }]
                    });
                }
            });
            setProperties(Array.from(groupedProperties.values()));
        }, []);
    }
    buildFields() {
        return {
            propertyId: new SelectType({
                label: translate('property'),
                required: true,
                searchable: true,
                isVisible: () => this.field.value.id === undefined,
                choiceOptions: () => this.properties
            }),
            name: new StringType({
                label: translate('ancillaryExpenseAccount.name'),
                required: true
            }),
            collectionType: new SelectType({
                label: translate('ancillaryExpenseAccount.collectionType'),
                required: true,
                choices: {
                    'fixed': translate('ancillaryExpenseAccount.collection.flat'),
                    'floating': translate('ancillaryExpenseAccount.collection.actual'),
                },
                defaultValue: 'floating'
            }),
            isShownInContract: new BooleanType({
                label: translate('ancillaryExpenseAccount.isShownInContract'),
                required: false,
                defaultValue: true
            }),
            isUsedInCalculation: new BooleanType({
                label: translate('ancillaryExpenseAccount.isUsedInCalculation'),
                required: false,
                defaultValue: true,
                isVisible: () => this.field.value.collectionType === 'floating'
            }),
            financialAccounts: new CollectionType({
                actions: { add: true, delete: true },
                widget: 'table',
                label: translate('ancillaryExpenseAccount.accountingAccounts'),
                prototype: () => new FinancialAccountType({
                    label: false,
                    financialAccounts: () => this.financialAccounts
                }),
                defaultValue: () => [{ financialAccountId: undefined }],
                isVisible: () => this.field.value.isUsedInCalculation && this.field.value.collectionType === 'floating'
            }),
            settlementType: new SelectType({
                label: translate('ancillaryExpenseAccount.settlementType'),
                required: false,
                choices: {
                    'allocation_formula': translate('ancillaryExpenseAccount.settlement.allocationFormula'),
                    'consumption': translate('ancillaryExpenseAccount.settlement.consumption'),
                },
                isVisible: () => this.field.value.collectionType === 'floating',
                defaultValue: 'allocation_formula',
                clearable: false
            }),
            unit: new SelectType({
                label: translate('ancillaryExpenseAccount.unit'),
                choices: {
                    "kWh": translate('unit.kWh'),
                    "l": translate('unit.l'),
                    "m2": translate('unit.m2'),
                    "m3": translate('unit.m3'),
                    "he": translate('unit.he'),
                    "CHF": translate('unit.CHF'),
                    "kg": translate('unit.kg'),
                    "o/oo": translate('unit.o/oo'),
                    "o/o": translate('unit.o/o'),
                },
                required: false,
                isVisible: () => this.field.value.settlementType === 'consumption' && this.field.value.collectionType === 'floating'
            }),
            costType: new SelectType({
                label: translate('ancillaryExpenseAccount.costType'),
                choices: {
                    "_01": translate('ancillaryExpenseAccount.costType.heating'),
                    "_02": translate('ancillaryExpenseAccount.costType.hot_water'),
                    "_03": translate('ancillaryExpenseAccount.costType.ventilation'),
                    "_04": translate('ancillaryExpenseAccount.costType.operating_costs'),
                    "_05": translate('ancillaryExpenseAccount.costType.cold_water'),
                    "_06": translate('ancillaryExpenseAccount.costType.electricity'),
                    "_07": translate('ancillaryExpenseAccount.costType.cold'),
                    "_08": translate('ancillaryExpenseAccount.costType.user_change'),
                    "_09": translate('ancillaryExpenseAccount.costType.heating_hot_water'),
                    "_10": translate('ancillaryExpenseAccount.costType.sewage'),
                },
                required: false,
                isVisible: () => this.field.value.settlementType === 'consumption' && this.field.value.collectionType === 'floating'
            })
        };
    }
}
