import { jsx as _jsx } from "react/jsx-runtime";
import { AbstractChoiceType, SimpleType } from '../core';
import { Select } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { orFunction } from "@f2w/utils";
var GenderTypeEnum;
(function (GenderTypeEnum) {
    GenderTypeEnum["MALE"] = "male";
    GenderTypeEnum["FEMALE"] = "female";
})(GenderTypeEnum || (GenderTypeEnum = {}));
export class GenderType extends SimpleType.ExtendString {
    static MALE = GenderTypeEnum.MALE;
    static FEMALE = GenderTypeEnum.FEMALE;
    choices = Object.values(GenderTypeEnum).map(value => ({
        value,
        label: translate(`form.type.gender.options.${value}`),
    }));
    constructor(options, parent) {
        super({
            label: translate('form.type.gender.label'),
            ...options,
            constraints: {
                ...options?.constraints,
                oneOf: [
                    Object.values(GenderTypeEnum),
                    null,
                ],
            }
        }, parent);
    }
    _renderWidget(props) {
        const _field = this.field;
        return _jsx(Select, { ...AbstractChoiceType.getEventProps(_field), ...{
                options: this.choices,
                placeholder: this.options.placeholder,
                isDisabled: orFunction(this.options.disabled),
                isClearable: _field.isNullable,
                defaultValue: _field.initialValue,
                $variant: _field.showError ? 'error' : 'primary',
                menuShouldBlockScroll: true,
                menuPortalTarget: document.body,
            } });
    }
}
