import { generate } from "Services/Router";
import client from "Utils/client";
import axios from "axios";
export var PatchNoteServices;
(function (PatchNoteServices) {
    PatchNoteServices.getPatchNotes = async (params) => {
        const route = generate('api.admin.patchNotes.list');
        return client
            .get(route, { params })
            .then(response => response.data);
    };
    PatchNoteServices.createPatchNote = async (data, images) => {
        const route = generate('api.admin.patchNotes.create');
        const formData = new FormData();
        images.forEach(image => formData.append('images[]', image));
        formData.append('model', new Blob([
            JSON.stringify(data)
        ], {
            type: 'application/json'
        }));
        return axios
            .post(route, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    };
    PatchNoteServices.updatePatchNote = async (id, data, images) => {
        const route = generate('api.admin.patchNotes.update', {
            patchNote: id
        });
        const formData = new FormData();
        images.forEach(image => formData.append('images[]', image));
        formData.append('model', new Blob([
            JSON.stringify(data)
        ], {
            type: 'application/json'
        }));
        return axios
            .post(route, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    };
    PatchNoteServices.deletePatchNote = (id) => {
        const route = generate('api.admin.patchNotes.delete', {
            patchNote: id
        });
        return client.delete(route);
    };
    PatchNoteServices.getPatchNote = async (id) => {
        const route = generate('api.admin.patchNotes.byId', {
            patchNote: id
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    PatchNoteServices.publishPatchNote = (id) => {
        const route = generate('api.admin.patchNotes.publish', {
            patchNote: id
        });
        return client
            .get(route);
    };
    PatchNoteServices.unpublishPatchNote = (id) => {
        const route = generate('api.admin.patchNotes.unpublish', {
            patchNote: id
        });
        return client
            .get(route);
    };
    PatchNoteServices.getFile = async (path, fileName, mimeType) => {
        const { data } = await client.get(path);
        return new File([data], fileName, { type: mimeType });
    };
})(PatchNoteServices || (PatchNoteServices = {}));
