import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AbstractType, } from './AbstractType';
import * as Schema from '../schema';
import { FieldArray } from 'formik';
import { Button, TableRow, Tfoot } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { useEventCallback } from '@restart/hooks';
import classnames from 'classnames';
import useStableMemo from '@restart/hooks/useStableMemo';
import { FormTheme } from '../theme';
import { renderContent } from '../renderContent';
import { isFunction, orFunction, toObject } from 'Utils/types';
import { ArrayField } from '../form';
import { Tbody, Td, Th, Thead } from 'Components/Atoms/Table';
import { AbstractObjectType } from './CompoundType';
export class AbstractArrayType extends AbstractType {
    children = Object.create(null);
    constructor({ ...options }, parent) {
        const proto = options.prototype();
        super(options, parent, Schema.array(proto.schema));
        this.innerType = proto;
        this.renderChild = this.renderChild.bind(this);
    }
    isArray() {
        return true;
    }
    _createField(props) {
        return new ArrayField(this, props);
    }
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            defaultValue: [],
        };
    }
    cast(value, ignoreDefaults) {
        if (!Array.isArray(value)) {
            if (this.isNullable)
                return null;
            if (!ignoreDefaults) {
                return this.cast(this.schema.getDefault(), true);
            }
            return [];
        }
        return value.map(childValue => this.innerType.cast(childValue, ignoreDefaults)).filter(v => v);
    }
    getResolvedValue() {
        return Object.values(this.children).map((child) => child.getResolvedValue());
    }
    getProtoDefaultValue() {
        return this.innerType.getDefaultValue();
    }
    _createChild(index) {
        const prototype = this.options.prototype();
        prototype._createInternal({
            name: index,
            parent: this,
        });
        return prototype;
    }
    renderChild({ index }) {
        const childType = this.children[index] = useStableMemo(() => this._createChild(index), [index]);
        const { touched, error } = childType.field;
        const status = touched ? (error ? 'error' : 'success') : undefined;
        if (childType instanceof AbstractObjectType) {
            switch (this.options.widget) {
                case 'table': {
                    childType.__useField();
                    return (_jsxs(FormTheme.Collection.TableRow, { children: [Object.values(childType.children).map?.((v, index) => {
                                return v.isVisible() ? _jsx(Td, { children: v.render({ label: false }) }, `${v.field.id}-table-col`) : undefined;
                            }), _jsx(Td, { actions: this._useTableActions(index), valign: 'top', style: { paddingTop: '7px' } })] }));
                }
            }
        }
        return _jsxs(FormTheme.Collection.Section, { status: status, "data-form-status": status, className: classnames('fw-collection--section', status && `fw-row-status--${status}`), children: [this._useActions(index, FormTheme.Collection.Actions), childType.render({ _label: false })] });
    }
    _render(props) {
        return _jsx(FieldArray, { name: this.field.path, children: manager => {
                this.field.manager = manager;
                return super._render({
                    ...props,
                    as: FormTheme.Collection.Row,
                });
            } });
    }
    _renderLabel(props) {
        const { actions } = this.options;
        const _field = this.field;
        const collectionProps = isFunction(this.options.getProps) ? this.options.getProps() : {};
        return super._renderLabel({
            ...props,
            as: FormTheme.Collection.Label,
            children: actions.add && _jsx(_Fragment, { children: _jsx(FormTheme.Collection.Actions, { children: _jsx(Button, { link: true, color: 'primary', "$size": 'md', onClick: () => _field.manager?.push(this.getProtoDefaultValue()), label: translate('form.collection.add'), disabled: orFunction(collectionProps?.disabled), icon: 'plus-circle' }) }) }),
        });
    }
    _renderError(props) {
        return super._renderError({ ...props, as: FormTheme.Collection.Error });
    }
    _renderWidget(props) {
        const _field = this.field;
        const innerType = this.innerType;
        if (innerType instanceof AbstractObjectType) {
            switch (this.options.widget) {
                case 'table': {
                    this.renderChild['displayName'] = TableRow.displayName;
                    const children = _field.value ?? [];
                    const columns = Object.keys(innerType.children).map((key) => {
                        const colTableLabel = this.options?.tableColumnOptions?.[key]?.label;
                        return innerType.children[key].isVisible() ? (_jsx(Th, { children: colTableLabel ?? innerType.children[key].options.label }, `${key}-table-head`)) : undefined;
                    });
                    return (_jsxs(FormTheme.Collection.Table, { borderRadius: false, variant: 'default', size: 'sm', className: "fw-collection--body", colSpan: columns.length, children: [_jsxs(Thead, { children: [columns, _jsx(Th, { sticky: "right" }, `${this.field.id}-table-actions`)] }), _jsx(Tbody, { children: children.map?.((v, index) => renderContent(this.renderChild, {
                                    key: `${_field.id}--${index}--collection`,
                                    index,
                                })) }), this.options.renderFooter ? this._renderFooter() : undefined] }));
                }
            }
        }
        return (_jsx(FormTheme.Collection.Body, { className: "fw-collection--body", children: _field.value?.map?.((v, index) => renderContent(this.renderChild, {
                key: `${_field.id}--${index}--collection`,
                index,
            })) }));
    }
    _renderFooter() {
        return (_jsx(Tfoot, {}));
    }
    _useTableActions(index) {
        const { actions } = this.options;
        const onRemove = useEventCallback((e) => {
            this.field.manager.remove(index);
        });
        const onMove = useEventCallback((position = -1) => {
            this.field.manager.move(index, index + position);
        });
        const act = [];
        actions?.sort && act.push(_jsxs(_Fragment, { children: [_jsx(Button, { outline: true, "$size": 'sm', icon: 'arrow-up', onClick: () => onMove(-1) }), _jsx(Button, { outline: true, "$size": 'sm', icon: 'arrow-down', onClick: () => onMove(1) })] }));
        const props = isFunction(this.options.getProps) ? this.options.getProps() : {};
        actions?.delete && act.push(_jsx(Button, { "$size": 'sm', outline: true, color: 'error', icon: 'trash-2', disabled: orFunction(props?.disabled), onClick: () => onRemove(index), confirm: translate('table.collection.deleteConfirm') }));
        return act;
    }
    _useActions(index, Component = 'div') {
        const { actions } = this.options;
        const onRemove = useEventCallback((e) => {
            this.field.manager.remove(index);
        });
        const onMove = useEventCallback((position = -1) => {
            this.field.manager.move(index, index + position);
        });
        return actions && _jsxs(Component, { children: [actions.sort && _jsxs(_Fragment, { children: [_jsx(Button, { outline: true, "$size": 'sm', icon: 'arrow-up', onClick: () => onMove(-1) }), _jsx(Button, { outline: true, "$size": 'sm', icon: 'arrow-down', onClick: () => onMove(1) })] }), actions.delete && _jsx(Button, { outline: true, color: 'error', "$size": 'sm', icon: 'trash-2', onClick: () => onRemove(index), confirm: translate('table.collection.deleteConfirm') })] });
    }
}
export class CollectionType extends AbstractArrayType {
    constructor({ actions, ...options }, parent) {
        super({
            ...options,
            actions: !actions
                ? {}
                : actions === true ? { add: true, delete: true, sort: true } : { ...toObject(actions) },
        }, parent);
    }
}
