export const isStorybookEnv = () => process.env.FW_STORYBOOK === 'true';
export const isAppEnv = () => process.env.FW_STORYBOOK !== 'true';
export const isDevEnv = () => process.env.NODE_ENV === 'development';
export const isProdEnv = () => process.env.NODE_ENV === 'production';
const isObject = (value) => (value != null && typeof value === 'object' && !Array.isArray(value));
const assign = (_w, name, value) => {
    if (isObject(name)) {
        Object.assign(_w, name);
    }
    else {
        _w[name] = value;
    }
};
export function setDebugVariable(name, value) {
    if (isDevEnv()) {
        assign(window, name, value);
        window.top && assign(window.top, name, value);
    }
}
export function dump(...args) {
    if (isDevEnv()) {
        console.log(...args);
    }
}
