import generateRoute from 'Services/Router';
import client from 'Utils/client';
export default {
    listAccountingRecords: (params) => {
        const route = generateRoute('api.accountingRecord.list');
        return client
            .get(route, { params })
            .then(response => response.data);
    },
    getAccountingRecordById: async (id) => {
        const response = await client.get(generateRoute('api.accountingRecord.get', { id: id }));
        return response.data;
    },
    createAccountingRecord: (upsertDto) => {
        const route = generateRoute('api.accountingRecord.create');
        return client.post(route, upsertDto);
    },
    updateAccountingRecord: (id, upsertDto) => {
        console.log(id);
        console.log(upsertDto);
        const route = generateRoute('api.accountingRecord.edit', { id: id });
        return client.put(route, upsertDto);
    },
    deleteAccountingRecord: (id) => {
        const route = generateRoute('api.accountingRecord.delete', {
            record: id
        });
        return client.delete(route);
    },
    getIncomeStatement: (propertyId, fiscalYearId, dateFilter) => {
        const from = dateFilter.from;
        const to = dateFilter.to;
        const route = generateRoute('api.accounting.incomeStatement', {
            propertyId,
            fiscalYearId,
            from,
            to
        });
        return client
            .get(route)
            .then(response => response.data);
    },
    getBalanceStatement: (propertyId, fiscalYearId, reportingDate) => {
        const at = reportingDate;
        const route = generateRoute('api.accounting.balanceStatement', {
            propertyId,
            fiscalYearId,
            at
        });
        return client
            .get(route)
            .then(response => response.data);
    },
    getOpeningBalance: (financialAccountId, fiscalYearId) => {
        const route = generateRoute('api.accounting.getOpeningBalance', {
            financialAccountId,
            fiscalYearId
        });
        return client
            .get(route)
            .then(response => response.data);
    },
    setOpeningBalance: (financialAccountId, fiscalYearId, value) => {
        const route = generateRoute('api.accounting.setOpeningBalance', {
            financialAccountId,
            fiscalYearId,
            value
        });
        return client
            .put(route)
            .then(response => response.data);
    },
    getAccountBalance: async (propertyId, financialAccountId, fiscalYearId) => {
        const route = generateRoute('api.accounting.balance', {
            propertyId: propertyId,
            financialAccountId: financialAccountId,
            fiscalYearId: fiscalYearId,
        });
        let response = await client.get(route);
        return await response.data;
    }
};
