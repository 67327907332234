import { AbstractFormType } from "@f2w/form";
import { translate } from "Services/App";
import { CustomEmailType } from "Pages/Auth/types/CustomEmailType";
export class PasswordForgetType extends AbstractFormType {
    buildFields() {
        return {
            email: new CustomEmailType({
                label: translate('emailAddress'),
                getProps: () => ({
                    autoFocus: true
                })
            })
        };
    }
}
