import { forEachObject } from 'Utils';
import { BaseState, KeyOrChild } from './BaseState';
import { GetterDescriptor, IndexManager, StateDescriptors } from './descriptors';
export class CompoundState extends BaseState {
    _indexManager;
    _config;
    constructor(config) {
        super(config);
        this._config = this.createConfig();
    }
    childrenMap = new Map();
    get indexes() {
        return this._indexManager.data;
    }
    get index() {
        return this._indexManager;
    }
    get data() {
        return {
            ...this._config.data
        };
    }
    get meta() {
        return {
            ...this._config.meta
        };
    }
    static createConfig(c) {
        return c;
    }
    static createConfig2(c) {
        return {
            config: c,
            extend(cb) {
                return CompoundState.createConfig2(cb(this.config));
            }
        };
    }
    static create(c) {
        return c;
    }
    getInitialConfig() {
        return CompoundState.getInitialConfig(this);
    }
    static getInitialConfig(_this) {
        const config = CompoundState.createConfig2({
            meta: {
                valid: {
                    getValue: () => (_this._config.meta.validated && !_this._config.meta.hasError),
                },
                disabled: {
                    getValue: () => false,
                },
            },
            index: {
                dirty: {
                    getValue: (set) => set.size > 0,
                },
                touched: {
                    getValue: (set) => set.size > 0,
                },
                hasError: {
                    getValue: (set) => set.size > 0,
                },
                validated: {
                    getValue: (set) => set.size === _this.getActiveChildrenSize(),
                },
                validating: {
                    getValue: (set) => set.size > 0,
                },
            },
            data: {
                initialValue: {
                    getValue: (child) => child.initialValue,
                    set: (child, value) => child.setValue(value),
                },
                value: {
                    getValue: (child) => child.value,
                    set: (child, value) => child.updateValue(value, true),
                },
                error: {
                    getValue: (child) => child.error || false,
                    set: (child, value) => child.setError(value),
                },
                touched: {
                    getValue: (child) => child.touched,
                    set: (child, value) => child.setTouched(value, false),
                },
                prevValue: {
                    getValue: () => null,
                },
                formattedValue: {
                    getValue: () => null,
                },
                fields: {
                    getValue: (child) => child,
                },
            },
        });
        return config.config;
    }
    createConfig() {
        const config = this.getInitialConfig();
        const descriptors2 = StateDescriptors.create();
        const indexMap = this._indexManager = IndexManager.create(config.index, {
            onChange: (event) => {
                this._userConfig?.onIndexChange?.(event);
            }
        });
        indexMap.forEach((index, key) => {
            descriptors2.addMeta(key, {
                get: () => index.get(),
            });
        });
        forEachObject(config.meta, (key, { getValue, ...config }) => {
            descriptors2.addMeta(key, {
                ...config,
                get() {
                    return getValue();
                }
            });
        });
        const gettersMap = GetterDescriptor.fromConfig(config.data);
        forEachObject(gettersMap, (key, getter) => {
            descriptors2.addData(key, {
                get: () => getter.data,
            });
        });
        return {
            config,
            gettersMap,
            indexMap,
            meta: descriptors2.defineMeta(),
            data: descriptors2.defineData(),
        };
    }
    getChildrenSize() {
        return this.childrenMap.size;
    }
    getActiveChildrenSize() {
        return this.childrenMap.size;
    }
    keys() {
        return [...this.childrenMap.keys()];
    }
    all() {
        return [...this.childrenMap.values()];
    }
    has(keyOrChild) {
        return this.childrenMap.has(KeyOrChild.key(keyOrChild));
    }
    get(keyOrChild) {
        return this.childrenMap.get(KeyOrChild.key(keyOrChild));
    }
    forEach(cb) {
        this.childrenMap.forEach(cb);
    }
    map(cb) {
        const items = [];
        this.childrenMap.forEach((child, key) => {
            const resolvedChild = cb(child, key);
            resolvedChild && items.push(resolvedChild);
        });
        return items;
    }
    isActive(keyOrChild) {
        return true;
    }
    onAddChild(child) { }
    onRemoveChild(child) { }
    register(key, child) {
        if (key && !this.has(key)) {
            const state = child['_state'];
            if (state['parentManager'] && (state['parentManager'] !== this || state.key !== key)) {
                state['parentManager'].remove(child);
            }
            this.childrenMap.set(key, child);
            state.props.key = key;
            state.props.parent = this._self();
            forEachObject(this._config.gettersMap, (key, getter) => {
                getter.add(child);
            });
            this.onAddChild(child);
        }
    }
    remove(keyOrChild) {
        const child = this.get(keyOrChild);
        if (child) {
            this._clearIndexes([keyOrChild]);
            forEachObject(this._config.gettersMap, (k, getter) => {
                getter.remove(child.key);
            });
            this.onRemoveChild(child);
            this.childrenMap.delete(child.key);
            if (child['_state']['parentManager'] === this) {
                child['_state'].props.parent = null;
            }
            this.updateParent();
            return true;
        }
    }
    _dumpMeta() {
        const keys = {};
        Object.entries(this.index.data).forEach(([key, index]) => {
            keys[key] = [...index.values()];
        });
        return keys;
    }
    _clearIndexes(childrenOrKeys) {
        if (childrenOrKeys) {
            this.index.update((index) => index.removeAll(childrenOrKeys));
        }
        else {
            this.index.update((index) => index.clear());
        }
    }
    updateChildren(props) {
        const {} = props;
        const updatedKeys = props.childrenOrKeys.filter(keyOrChild => {
            const child = this.get(keyOrChild);
            if (!child) {
                console.log('no child', keyOrChild, props);
                return false;
            }
            return this.index.update((index, k) => {
                if (props.ignoredKeys && props.ignoredKeys.includes(k))
                    return false;
                return child && index._update(child);
            }, props.keys);
        });
        if (updatedKeys) {
            this.updateParent();
            return true;
        }
    }
}
