import { AbstractFormType, SelectType } from "@f2w/form";
import { translate } from "Services/Translator";
import React from "react";
import { getRentalObjectAbstractRooms } from "Components/ObjectViewModal/services/objectViewModalServices";
export class RentalObjectAddRoomType extends AbstractFormType {
    abstractRooms = [];
    _useField(props) {
        super._useField(props);
        const [abstractRooms, setAbstractRooms] = React.useState([]);
        this.abstractRooms = abstractRooms;
        React.useEffect(() => {
            getRentalObjectAbstractRooms()
                .then(abstractRooms => setAbstractRooms(abstractRooms.map(abstractRoom => ({
                label: abstractRoom.name,
                value: abstractRoom.id
            }))));
        }, []);
    }
    buildFields() {
        return {
            abstractRoomId: new SelectType({
                label: translate('rentalObject.abstractRoom'),
                choiceOptions: () => this.abstractRooms
            })
        };
    }
}
