import React, { useCallback } from 'react';
import { functionalUpdate } from 'Utils/publicUtils';
export function useDataTableResponse({ initialData, getKey = (value) => value?.id, } = {}) {
    const [state, setState] = React.useState({
        results: initialData || [],
        totalElements: initialData?.length || 0,
        currentPage: 1,
        totalPages: 1,
    });
    const updateResults = useCallback((cb) => {
        setState(res => {
            res.results = res.results.map(value => cb(value));
            res.results['pageCount'] = res.totalPages;
            return {
                ...res,
            };
        });
    }, []);
    const update = useCallback((id, updater) => {
        setState(({ results, ...prev }) => {
            return {
                ...prev,
                results: results.map(value => {
                    if (getKey(value) === id) {
                        return functionalUpdate(updater, value);
                    }
                    return value;
                }),
            };
        });
    }, []);
    const setResponse = useCallback((res) => {
        res.results['pageCount'] = res.totalPages;
        setState(res);
    }, []);
    const setData = useCallback((data) => {
        setResponse({
            ...state,
            results: [...data],
            totalPages: 1,
            totalElements: data?.length || 0,
        });
    }, []);
    return {
        ...state,
        setResponse,
        setData,
        updateResults,
        update,
    };
}
