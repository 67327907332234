import React from 'react';
import FinancialAccountServices from 'Services/FinancialAccountServices';
export const useFinancialAccountsHook = ({ propertyId, initialFinancialAccounts, loadAccountsInitially = true, }) => {
    const [_financialAccounts, setFinancialAccounts] = React.useState();
    const financialAccounts = _financialAccounts ?? initialFinancialAccounts ?? [];
    React.useEffect(() => {
        if (!loadAccountsInitially)
            return;
        FinancialAccountServices.listFinancialAccountsForDropdown()
            .then(response => setFinancialAccounts(response));
    }, [loadAccountsInitially]);
    const groupedFinancialAccountOptions = React.useMemo(() => {
        const groupedFinancialAccounts = new Map();
        financialAccounts.forEach(financialAccount => {
            if (groupedFinancialAccounts.has(financialAccount.category)) {
                groupedFinancialAccounts.set(financialAccount.category, {
                    ...groupedFinancialAccounts.get(financialAccount.category),
                    options: [
                        ...groupedFinancialAccounts.get(financialAccount.category).options,
                        {
                            label: financialAccount.bankAccounts[propertyId]
                                ? `${financialAccount.label} (${financialAccount.bankAccounts[propertyId].label})`
                                : financialAccount.label,
                            value: financialAccount.id
                        }
                    ]
                });
            }
            else {
                groupedFinancialAccounts.set(financialAccount.category, {
                    label: financialAccount.category,
                    options: [
                        {
                            label: financialAccount.label,
                            value: financialAccount.id
                        }
                    ]
                });
            }
        });
        return Array.from(groupedFinancialAccounts.values());
    }, [propertyId, financialAccounts]);
    return {
        financialAccounts,
        groupedFinancialAccountOptions
    };
};
