import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { useServiceProviderForm } from 'Pages/ServiceProvider/hooks/useServiceProviderForm';
import { FormModal } from '@f2w/form';
import { createServiceProvider, deleteServiceProvider, getServiceProvider, listServiceProviders, updateServiceProvider, } from './ServiceProviderManager';
import { ServiceProviderDataTable } from 'Pages/ServiceProvider/table/ServiceProviderDataTable';
import { useModalDispatcherConfirm, useToastDispatcherApi } from 'Components/Dispatcher';
const ServiceProviderPage = ({ openUpsertModal }) => {
    const serviceProviderForm = useServiceProviderForm({
        onSubmit: () => {
            instance.refresh();
        },
        getServiceProvider,
        createServiceProvider,
        updateServiceProvider
    });
    const { success } = useToastDispatcherApi();
    const onDeleteConfirm = useModalDispatcherConfirm({
        onConfirm: (id) => {
            deleteServiceProvider(id)
                .then(() => {
                success({
                    message: translate('flash.serviceProviderDeleted')
                });
                instance.refresh();
            });
        },
        message: translate('serviceProvider.deleteConfirm'),
        title: translate('serviceProvider.delete'),
    });
    const instance = ServiceProviderDataTable.use({
        listServiceProviders,
        openUpsertForm: serviceProviderForm.openForm,
        deleteServiceProvider: onDeleteConfirm
    });
    React.useEffect(() => {
        if (openUpsertModal) {
            serviceProviderForm.openForm();
        }
    }, [openUpsertModal]);
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, title: translate('serviceProviders.title') }), _jsx(FormModal, { modal: serviceProviderForm })] }));
};
export default ServiceProviderPage;
