import { useFormModal } from "@f2w/form";
import { ManualPaymentType } from "Pages/Creditor/forms/ManualPaymentType";
import { translate } from "Services/Translator";
export const useManualPaymentForm = (createManualPayment, loadPaymentData, onSubmit) => useFormModal({
    name: 'manual-payment-form',
    type: () => new ManualPaymentType(),
    onLoad: async (id) => ({
        ...await loadPaymentData(id),
        id
    }),
    title: translate('creditor.actions.pay'),
    onSave: async ({ id, ...values }) => {
        return createManualPayment(id, values)
            .then(onSubmit);
    }
});
