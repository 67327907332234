import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
import { DebitorType } from "Pages/Debitor/components/DebitorView/forms/DebitorType";
export const useManualPaymentForm = (createManualPayment, loadPaymentData, onSubmit) => useFormModal({
    name: 'manual-payment-form',
    type: () => new DebitorType.PaymentType(),
    onLoad: async (id) => ({
        ...await loadPaymentData(id),
        id
    }),
    title: translate('debitor.actions.pay'),
    onSave: async ({ id, ...values }) => {
        return createManualPayment(id, values)
            .then(onSubmit);
    },
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('btn.save') })] });
    },
});
