import generateRoute from 'Services/Router';
import client from 'Utils/client';
export class CustomerApi {
    static async getBillingContact() {
        let response = await client.get(generateRoute('api.customer.billingContact'));
        return await response.data;
    }
    static getForUpdate() {
        const url = generateRoute('api.customer.get');
        return client
            .get(url)
            .then(response => response.data);
    }
    static update(data) {
        const url = generateRoute('api.customer.update');
        console.log(data);
        return client
            .patch(url, data)
            .then(response => response.data);
    }
}
