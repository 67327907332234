import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { useUncontrolled } from 'uncontrollable';
import { AccordionToggle } from './AccordionToggle';
import { AccordionCollapse } from './AccordionCollapse';
import { AccordionItem } from './AccordionItem';
import AccordionContext from './AccordionContext';
import { SelectableContext } from 'Components/Base';
const BaseAccordion = forwardRef((props, ref) => {
    const { as: Component = 'div', activeKey, children, className, onSelect, ...controlledProps } = useUncontrolled(props, {
        activeKey: 'onSelect',
    });
    const prefix = 'accordion';
    const finalClassName = classNames(className, prefix);
    return (_jsx(AccordionContext.Provider, { value: activeKey || null, children: _jsx(SelectableContext.Provider, { value: onSelect || null, children: _jsx(Component, { ref: ref, ...controlledProps, className: finalClassName, children: children }) }) }));
});
BaseAccordion.displayName = 'Accordion';
export const Accordion = Object.assign(BaseAccordion, {
    Item: AccordionItem,
    Toggle: AccordionToggle,
    Collapse: AccordionCollapse,
});
