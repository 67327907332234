import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { FlexCol } from 'Components/Atoms';
import { BankAccountType } from 'Pages/BankAccount/enums';
import { IbanType } from '@f2w/form';
import { Section } from 'Atoms/Layout/components';
import { Card } from 'Atoms/Card';
export const GeneralTab = ({ group }) => {
    const { data, } = group;
    const ownerAddress = data.ownerAddress;
    const showCreditor = [BankAccountType.CREDIT].includes(data.type);
    const showPerson = [BankAccountType.TENANT, BankAccountType.DEPOSIT].includes(data.type);
    const showRent = [BankAccountType.RENT].includes(data.type);
    return (_jsx(Section, { children: _jsxs(FlexCol, { gap: 24, children: [_jsxs(Card, { children: [_jsx(Card.Value, { title: translate(`bank.ibanType.${data.ibanType.toLowerCase()}`), children: IbanType.useFormat(data.iban) || null }), _jsx(Card.Value, { title: translate('bank.longBankName'), children: data.longBankName })] }), _jsxs("div", { style: { display: "grid", gridTemplateColumns: "1fr 1fr", gap: 24 }, children: [_jsx(Card, { children: _jsx(Card.Value, { title: translate('bank.type'), children: translate(`bank.type.${data.type.toLowerCase()}`) }) }), _jsx(Card, { children: _jsx(Card.Address, { title: translate('bank.owner'), label: data.ownerName, street: ownerAddress?.street, streetNr: ownerAddress?.houseNumber, city: ownerAddress?.city, zip: ownerAddress?.zip, countryCode: ownerAddress?.country }) }), showRent && _jsx(_Fragment, { children: _jsx(Card, { children: _jsx(Card.Value, { title: translate('bank.otherAccountNumber'), children: data.otherAccountNumber }) }) }), showRent &&
                            _jsxs(Card, { children: [_jsx(Card.Value, { title: translate('bank.qrIban'), children: data.qrIban ?? IbanType.useFormat(data.qrIban) }), _jsx(Card.Value, { title: translate('integrations.qr.besrCustomerId'), children: data.qrCustomerId })] })] }), showPerson && _jsx(_Fragment, { children: _jsx(Card, { children: _jsx(Card.Value, { title: translate('bank.persons'), children: data.person?.name || null }) }) }), showCreditor &&
                    _jsx(Card, { children: _jsx(Card.Value, { infoText: translate('bankAccount.financialAccount.help'), title: translate('bankAccount.financialAccount'), children: data.creditorFinancialAccount?.label || null }) })] }) }));
};
