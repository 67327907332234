import { jsx as _jsx } from "react/jsx-runtime";
import { AbstractFormType, BooleanType, DateType, StringType } from "@f2w/form";
import { translate } from "Services/Translator";
import React, { useEffect } from "react";
import FiscalYearServices from "Pages/Accounting/FiscalYear/services/fiscalYearServices";
import moment from "moment";
import { getGlobalContext } from "Services/GlobalContext";
import { Div, FlexRow } from "Atoms/Layout";
import { Spinner } from "Atoms/Spinner";
export default class FiscalYearType extends AbstractFormType {
    loading = true;
    _useField(props) {
        super._useField(props);
        const [loading, setLoading] = React.useState(true);
        this.loading = loading;
        const { scope } = getGlobalContext();
        useEffect(() => {
            if (this.field.value.id) {
                setLoading(false);
                return;
            }
            FiscalYearServices.getCreateFiscalYearSuggestion(scope.id)
                .then((data) => {
                this.children.label.field.setValue(data.label);
                this.children.starts.field.setValue(data.starts);
                this.children.ends.field.setValue(data.ends);
                setLoading(false);
            });
        }, [this.field.value.id]);
        useEffect(() => {
            const starts = this.field.value.starts;
            const ends = this.field.value.ends;
            if (moment(starts).isBefore(moment(ends))) {
                this.children.ends.field.setError(false);
            }
        }, [this.field.value.starts]);
        useEffect(() => {
            const starts = this.field.value.starts;
            const ends = this.field.value.ends;
            if (moment(starts).isBefore(moment(ends))) {
                this.children.starts.field.setError(false);
            }
        }, [this.field.value.ends]);
    }
    buildFields() {
        return {
            label: new StringType({
                label: translate('accounting.fiscalYear.form.labels.label'),
                required: true,
            }),
            starts: new DateType({
                label: translate('accounting.fiscalYear.form.labels.starts'),
                required: true,
                defaultValue: null,
                constraints: {
                    test: ['is-before-end', 'Von muss vor Bis liegen', (currentValue) => {
                            return this.field.value.ends
                                ? moment(currentValue).isSameOrBefore(moment(this.field.value.ends))
                                : true;
                        }]
                }
            }),
            ends: new DateType({
                label: translate('accounting.fiscalYear.form.labels.ends'),
                required: true,
                constraints: {
                    test: ['is-after-start', 'Von muss vor Bis liegen', (currentValue) => {
                            return this.field.value.starts
                                ? moment(currentValue).isSameOrAfter(moment(this.field.value.starts))
                                : true;
                        }]
                },
            }),
            transferBalances: new BooleanType({
                label: translate('accounting.fiscalYear.form.labels.transferBalances'),
                required: false,
                defaultValue: false,
                isVisible: () => !this.field.value.id
            }),
        };
    }
    _renderWidget(iProps) {
        if (this.loading) {
            return (_jsx(Div, { w: '100%', h: 300, children: _jsx(FlexRow, { h: '100%', alignCenter: true, justifyCenter: true, children: _jsx(Spinner, {}) }) }));
        }
        return super._renderWidget(iProps);
    }
}
