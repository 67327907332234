import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { UseStyled } from "@f2w/theme";
import { Input } from "Atoms/Input";
import React from "react";
const Filter = UseStyled.styled('div', () => ({
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
}));
export function AmountRangeFilter({ column: { filterValue, setFilter }, }) {
    const onChange = React.useCallback((key, value) => {
        setFilter({ ...filterValue, [key]: value });
    }, [filterValue]);
    return (_jsxs(Filter, { children: [_jsx("span", { children: translate('from') }), _jsx(Input, { type: 'number', value: filterValue?.from ?? '', onChange: (value) => onChange('from', value !== '' ? value : undefined) }), _jsx("span", { children: translate('to') }), _jsx(Input, { type: 'number', value: filterValue?.to ?? '', onChange: (value) => onChange('to', value !== '' ? value : undefined) })] }));
}
