export const flex = {
    flexWrapReverse: { flexWrap: 'wrap-reverse' },
    flexNoWrap: { flexWrap: 'nowrap' },
};
const alignSelf = {
    selfCenter: { alignSelf: 'center' },
    selfEnd: { alignSelf: 'end' },
    selfFlexEnd: { alignSelf: 'flex-end' },
    selfFlexStart: { alignSelf: 'flex-start' },
    selfSelfEnd: { alignSelf: 'self-end' },
    selfSelfStart: { alignSelf: 'self-start' },
    selfStart: { alignSelf: 'start' },
    selfBaseline: { alignSelf: 'baseline' },
    selfNormal: { alignSelf: 'normal' },
    selfStretch: { alignSelf: 'stretch' },
};
const alignContent = {
    contentCenter: { alignContent: 'center' },
    contentEnd: { alignContent: 'end' },
    contentFlexEnd: { alignContent: 'flex-end' },
    contentFlexStart: { alignContent: 'flex-start' },
    contentSelfEnd: { alignContent: 'self-end' },
    contentSelfStart: { alignContent: 'self-start' },
    contentStart: { alignContent: 'start' },
    contentBaseline: { alignContent: 'baseline' },
    contentNormal: { alignContent: 'normal' },
    contentStretch: { alignContent: 'stretch' },
};
const alignItems = {
    alignCenter: { alignItems: 'center' },
    alignEnd: { alignItems: 'end' },
    alignFlexEnd: { alignItems: 'flex-end' },
    alignFlexStart: { alignItems: 'flex-start' },
    alignSelfEnd: { alignItems: 'self-end' },
    alignSelfStart: { alignItems: 'self-start' },
    alignStart: { alignItems: 'start' },
    alignBaseline: { alignItems: 'baseline' },
    alignNormal: { alignItems: 'normal' },
    alignStretch: { alignItems: 'stretch' },
};
const justifyContent = {
    justifySpaceAround: { justifyContent: 'space-around' },
    justifySpaceBetween: { justifyContent: 'space-between' },
    justifySpaceEvenly: { justifyContent: 'space-evenly' },
    justifyFlexEnd: { justifyContent: 'flex-end' },
    justifyFlexStart: { justifyContent: 'flex-start' },
    justifyStretch: { justifyContent: 'stretch' },
    justifyCenter: { justifyContent: 'center' },
    justifyEnd: { justifyContent: 'end' },
    justifyStart: { justifyContent: 'start' },
    justifyLeft: { justifyContent: 'left' },
    justifyNormal: { justifyContent: 'normal' },
    justifyRight: { justifyContent: 'right' },
};
export const flexAlign = {
    ...alignContent,
    ...alignItems,
    ...alignSelf,
    ...justifyContent,
};
const flexWrap = {
    flexWrap: { flexWrap: 'wrap' },
    flexWrapReverse: { flexWrap: 'wrap-reverse' },
    flexNoWrap: { flexWrap: 'nowrap' },
};
const textAlign = {
    textRight: { textAlign: 'right' },
    textLeft: { textAlign: 'left' },
    textCenter: { textAlign: 'center' },
    textNoWrap: { whiteSpace: 'nowrap' },
};
const position = {
    relative: { position: 'relative' },
    static: { position: 'static' },
    absolute: { position: 'absolute' },
    fixed: { position: 'fixed' },
};
const cssRules = {
    ...flexAlign,
    ...flexWrap,
    ...textAlign,
    ...position,
};
export default cssRules;
