import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import classes from "Pages/DocumentTemplates/View/DocumentTemplatePage.module.scss";
import { useCallback, useState } from "react";
import DocumentTemplateServices from "Pages/DocumentTemplates/services/documentTemplateServices";
import { UploadImages } from "Components/ObjectViewModal/components/UploadImages";
import { Spinner } from "Atoms/Spinner";
const DEFAULT_CONFIG = {
    logoPosition: "left",
    header: "",
    footer: ""
};
const GlobalTemplateConfig = (props) => {
    const initialLogoUrl = props.globalConfig ? props.globalConfig.logoPath : null;
    const [loading, setIsLoading] = useState(false);
    const [logoUrl, updateLogoUrl] = useState(initialLogoUrl);
    const [globalConfig, updateGlobalConfig] = useState(props.globalConfig
        ? {
            ...props.globalConfig,
            header: props.globalConfig.header !== null ? props.globalConfig.header : "",
            footer: props.globalConfig.footer !== null ? props.globalConfig.footer : ""
        }
        : { ...DEFAULT_CONFIG });
    const updateTextArea = (key, value) => {
        updateGlobalConfig({
            ...globalConfig,
            [key]: value
        });
    };
    const updateLogoPlacement = (newPlacement) => {
        updateGlobalConfig({
            ...globalConfig,
            logoPosition: newPlacement
        });
    };
    const createLogoPlacementIconButton = (updatePlacementTo) => {
        const nonHighlightedClass = globalConfig.logoPosition !== updatePlacementTo
            ? classes.logoPositionNonSelected
            : "";
        const updatedIconClasses = `fas fa-align-${updatePlacementTo} icon-md ${nonHighlightedClass}`;
        return (_jsx("button", { type: "button", className: "btn btn-icon", onClick: () => updateLogoPlacement(updatePlacementTo), children: _jsx("i", { className: updatedIconClasses }) }));
    };
    const saveGlobalTemplateConfig = async () => {
        setIsLoading(true);
        const upsertDocumentTemplateGlobalConfigDto = {
            font: globalConfig.font,
            logoPlacement: globalConfig.logoPosition,
            header: globalConfig.header
        };
        await DocumentTemplateServices.updateGlobalTemplateConfig(upsertDocumentTemplateGlobalConfigDto)
            .finally(() => {
            setIsLoading(false);
        });
    };
    const clearLogo = async () => {
        setIsLoading(true);
        await DocumentTemplateServices.clearGlobalLogo()
            .then(() => {
            updateLogoUrl(null);
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    const onUpload = useCallback(async (files) => {
        if (files.length !== 0) {
            setIsLoading(true);
            const file = files[0];
            DocumentTemplateServices.uploadLogo(file.file)
                .then((response) => {
                updateLogoUrl(response.filePath);
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
    }, []);
    return (_jsxs("div", { children: [_jsxs("div", { className: "row", children: [_jsx("h3", { className: "col-md-10 kt-portlet__head-title border-0 mb-5", children: _jsx("span", { children: translate('templates.globalConfig') }) }), _jsx("div", { className: "col-md-2 pull-right", children: _jsx(Spinner, { isVisible: loading }) })] }), _jsxs("div", { className: "row", children: [_jsxs("div", { className: "col-md-12", children: [_jsx(UploadImages, { data: logoUrl !== null ? [{ id: 'logo', path: logoUrl }] : [], onUpload: onUpload, onDelete: clearLogo, multiple: false, imageFit: 'contain' }), _jsx("div", { className: "mt-2", children: translate('logoUpload.help') })] }), _jsx("div", { className: "col-md-12 mt-3", children: _jsx("h4", { children: translate('templates.globalConfig.logoPlacement') }) }), _jsxs("div", { className: "col-md-12", children: [createLogoPlacementIconButton("left"), createLogoPlacementIconButton("center"), createLogoPlacementIconButton("right")] }), _jsx("div", { className: "col-md-12 mt-3", children: _jsx("h4", { children: translate('templates.globalConfig.header') }) }), _jsx("div", { className: "col-md-12", children: _jsx("textarea", { className: "form-control", style: { minHeight: "100px" }, value: globalConfig.header, onChange: (e) => updateTextArea('header', e.target.value), rows: 3, maxLength: 255 }) }), _jsx("div", { className: "col-md-12 mt-3", children: _jsx("button", { disabled: loading, className: "btn btn-primary pull-right", onClick: saveGlobalTemplateConfig, children: translate('btn.save') }) })] })] }));
};
export default GlobalTemplateConfig;
