export const calcDropdownPosition = (triggerRef, dropdownRef, dropdownAnchor) => {
    const triggerComponent = triggerRef && triggerRef.current ? triggerRef.current : null;
    if (!triggerComponent) {
        return null;
    }
    const dropdownComponent = dropdownRef && dropdownRef.current ? dropdownRef.current : null;
    const { width: dropdownWidth } = dropdownComponent ? dropdownComponent.getBoundingClientRect() : { width: 300 };
    const win = triggerComponent.ownerDocument.defaultView;
    const { left: triggerLeft, width: triggerWidth, top: triggerTop, height: triggerHeight } = triggerComponent.getBoundingClientRect();
    const top = triggerTop + win.scrollY;
    const left = triggerLeft + win.scrollX;
    let deltaX = 0;
    let deltaY = 0;
    switch (dropdownAnchor) {
        case "center":
            deltaX = triggerWidth / 2 - dropdownWidth / 2;
            deltaY = triggerHeight;
            break;
        case "topRight":
            deltaX = triggerWidth;
            deltaY = 0;
            break;
        case "startRight":
            deltaX = triggerWidth;
            deltaY = top * -1;
            break;
        case "middleRight":
            deltaX = triggerWidth;
            deltaY = (triggerHeight - dropdownComponent.offsetHeight) / 2;
            if (top + deltaY + dropdownComponent.offsetHeight > win.innerHeight) {
                deltaY = triggerHeight - dropdownComponent.offsetHeight;
            }
            break;
        default:
        case "left":
            deltaX = 0;
            deltaY = triggerHeight;
            break;
    }
    return {
        top: Math.max(0, top + deltaY),
        left: Math.max(0, left + deltaX)
    };
};
