import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { QRCode } from "Components/Atoms/QRCode";
import { Button } from "Components/Atoms/Button";
import AuthCodeInput from "Components/Atoms/AuthCodeInput/AuthCodeInput";
import { activate2FA } from "Services/TwoFactorAuthManager";
import FormErrorMessage from "Components/Atoms/Form/FormErrorMessage";
import { SuccessPageIcon } from "Components/App/Icons";
import { translate } from "Services/Translator";
import { useTheme } from "@f2w/theme";
import styled, { css } from "styled-components";
import TwoFactorAuthManagementStyled from "Pages/TwoFactorAuth/TwoFactorAuthManagement/TwoFactorAuthManagementStyled";
const { Wrapper, Heading, Actions } = TwoFactorAuthManagementStyled;
const ManualCodeSection = styled.div(() => {
    const { rem, typography, palette } = useTheme();
    return css `
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        code {
            ${typography.body.css({ fontWeight: 600 })};
            color: ${palette.primary.$500};
            background: ${palette.primary.$50};
            border-radius: ${rem(8)}
        }
    `;
});
const ChunkedCode = ({ code }) => {
    const chunks = code.match(/.{1,4}/g);
    return (_jsx("div", { style: {
            display: "inline-flex",
            gap: ".6rem",
            flexWrap: "wrap",
            justifyContent: "center"
        }, children: chunks.map((chunk, index) => (_jsx("code", { children: chunk }, index))) }));
};
const ScanStep = ({ qrCodeData, authSecret, onSubmit, onCancel }) => (_jsxs(_Fragment, { children: [_jsx(Heading, { children: translate('2fa.activation.scan.title') }), _jsx("div", { children: translate('2fa.activation.scan.subtitle') }), _jsx(QRCode, { size: 256, value: qrCodeData }), _jsxs(ManualCodeSection, { children: [_jsx("div", { children: translate('2fa.activation.scan.manual') }), _jsx("div", { children: _jsx(ChunkedCode, { code: authSecret }) })] }), _jsxs(Actions, { children: [_jsx(Button, { variant: "neutral", onClick: onCancel, children: translate('2fa.activation.verify.back') }), _jsx(Button, { "$size": "lg", onClick: onSubmit, children: translate('2fa.activation.scan.submit') })] })] }));
const VerifyStep = ({ authSecret, onSubmit, onReset }) => {
    const [authCode, setAuthCode] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const onActivate = async (authCode) => {
        setErrorMessage(null);
        setLoading(true);
        try {
            await activate2FA(authCode, authSecret);
            onSubmit();
        }
        catch (error) {
            let errorMessage = error.message;
            if (error.response && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            setErrorMessage(errorMessage);
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (authCode.replace(/\s/g, '').length !== 6) {
            return;
        }
        onActivate(authCode);
    }, [authCode]);
    return (_jsxs(_Fragment, { children: [_jsx(Heading, { children: translate('2fa.activation.verify.title') }), _jsx("div", { children: translate('2fa.activation.verify.subtitle') }), _jsx("h3", { style: { marginBottom: "1rem" }, children: translate('2fa.activation.verify.codeLabel') }), _jsx(AuthCodeInput, { autoFocus: true, defaultValue: authCode, onChange: (value) => {
                    setAuthCode(value);
                    setErrorMessage(null);
                }, length: 6 }), !!errorMessage && _jsx(FormErrorMessage, { children: errorMessage }), _jsxs(Actions, { children: [_jsx(Button, { variant: "neutral", onClick: onReset, children: translate('2fa.activation.verify.back') }), _jsx(Button, { "$size": "lg", onClick: () => onActivate(authCode), loading: loading, children: translate('2fa.activation.verify.submit') })] })] }));
};
const SuccessStep = ({ onComplete }) => (_jsxs(_Fragment, { children: [_jsx(Heading, { children: translate('2fa.activation.success.title') }), _jsx(SuccessPageIcon, {}), _jsx("h3", { children: translate('2fa.activation.success.subtitle') }), _jsx("div", { children: translate('2fa.activation.success.description') }), _jsx("div", { children: _jsx(Button, { "$size": "lg", onClick: onComplete, children: translate('2fa.activation.success.action') }) })] }));
const TwoFactorAuthActivationView = ({ authSecret, qrCodeData, onComplete, onCancel }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const promoteStep = () => setCurrentStep((step) => step + 1);
    const resetFlow = () => setCurrentStep(1);
    return (_jsxs(Wrapper, { children: [currentStep === 1 && _jsx(ScanStep, { authSecret: authSecret, qrCodeData: qrCodeData, onSubmit: promoteStep, onCancel: onCancel }), currentStep === 2 && _jsx(VerifyStep, { authSecret: authSecret, onSubmit: promoteStep, onReset: resetFlow }), currentStep === 3 && _jsx(SuccessStep, { onComplete: onComplete })] }));
};
export default TwoFactorAuthActivationView;
