import { useFormModal } from "@f2w/form";
import { translate } from "Services/Translator";
import { EditCustomerUserType } from "Pages/Administration/Customers/types/EditCustomerUserType";
export const useUserEditForm = ({ getCustomerUserEditData, updateCustomerUserEditData, onSave }) => useFormModal({
    name: 'customer-user-edit-form',
    type: () => new EditCustomerUserType(),
    title: translate('customer.user.edit'),
    onLoad: async (id) => {
        return getCustomerUserEditData(id).then((data) => {
            return {
                ...data,
                id: id
            };
        });
    },
    onSave: async (values) => {
        return updateCustomerUserEditData(values.id, values)
            .then(onSave);
    }
});
