import client from "Utils/client";
import generateRoute from "Services/Router";
export const getBaseDataPropertiesList = (params) => {
    return client.get(generateRoute('api.properties.list'), {
        params
    }).then(r => r.data);
};
export const getBaseDataRentalObjectsList = (params) => {
    return client.get(generateRoute('rentalObjects.list'), {
        params
    }).then(r => r.data);
};
