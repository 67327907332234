import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef } from 'react';
import Previewer from 'Services/PrintPolyfill/previewer.js';
import { getGlobalContext } from "Services/GlobalContext";
export const PagePreview = ({ html, stylesheets }) => {
    const previewRef = useRef(null);
    const tempPreviewRef = useRef(null);
    const { appVersion } = getGlobalContext();
    let resizer = (e = undefined) => {
        let pages = document.querySelector(".pagedjs_pages");
        let previewWrap = previewRef.current;
        if (pages) {
            const scale = (previewWrap.offsetWidth / pages.offsetWidth);
            pages.style.transform = `scale(${scale})`;
            pages.style.transformOrigin = "top left";
            pages.style.marginBottom = 0;
            if (scale < 1) {
                const height = scale * previewWrap.offsetHeight;
                const marginBottom = (height / scale) - height;
                pages.style.marginBottom = `-${marginBottom}px`;
            }
        }
    };
    const previewer = useMemo(() => {
        return new Previewer();
    }, []);
    useEffect(() => {
        window.addEventListener("resize", resizer, false);
        return () => {
            window.removeEventListener("resize", resizer, false);
        };
    }, []);
    useEffect(() => {
        const update = (e) => {
            previewRef.current.innerHTML = tempPreviewRef.current.innerHTML;
            resizer();
        };
        previewer.on("rendered", update);
        previewer
            .preview(html, [...stylesheets, `/css/print-preview.css?v=${appVersion}`], tempPreviewRef.current);
        return () => {
            previewer.off('rendered', update);
        };
    }, [html]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: previewRef, style: { all: "initial", display: 'flex' } }), _jsx("div", { ref: tempPreviewRef, style: { visibility: 'hidden', position: 'fixed', zIndex: -10000, left: 0, top: 0 } })] }));
};
