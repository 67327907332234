import React from 'react';
import { isFunction, isString } from '@f2w/utils';
const _filterMatch = (val, filter) => {
    if (typeof val === "string") {
        if (typeof filter === "function") {
            return filter(val);
        }
        if (typeof filter === "string") {
            if (filter.startsWith("=")) {
                return val === filter.substring(1);
            }
            return val.startsWith(filter);
        }
        if (Array.isArray(filter)) {
            for (const f of filter) {
                if (_filterMatch(val, f)) {
                    return true;
                }
            }
        }
    }
    return false;
};
export function isFirstChildTypeOf(children, filter, debug) {
    let child = React.Children.toArray(children)[0];
    if (React.isValidElement(child)) {
        if (child.type === React.Fragment) {
            if (child?.props?.children) {
                return isFirstChildTypeOf(child.props.children, filter);
            }
        }
        debug && console.log("child", child?.type['displayName'], filter, child);
        if (child && child.type && _filterMatch(child.type['displayName'], filter)) {
            return true;
        }
    }
    return false;
}
export const isEmptyChildren = (children) => (!children || React.Children.count(children) === 0 || (Array.isArray(children) && children.filter(v => v).length === 0));
export function textContent(children) {
    const text = [];
    let index = 0;
    const parseChildren = (children) => {
        React.Children.forEach(children, (child) => {
            if (child?.props?.children) {
                parseChildren(child?.props?.children);
            }
            if (isString(child)) {
                text.push(child);
            }
        });
    };
    parseChildren(children);
    return text.join(' ');
}
function renderChildren(_children, _render) {
    if (isFunction(_children)) {
        _children = _children();
    }
    if (React.isValidElement(_children)) {
        if (_children.type === React.Fragment) {
            if (_children?.props?.children)
                return renderChildren(_children?.props?.children, _render);
            return null;
        }
    }
    const children = React.Children
        .map(_children, child => React.isValidElement(child) ? child : undefined)
        .filter(c => c);
    return children.map((child, index) => {
        const { displayName } = child.type;
        return _render(child, {
            displayName,
            first: index === 0,
            last: index === (children.length - 1),
        });
    });
}
export { renderChildren };
export function renderContent(value, props) {
    if (!value)
        return null;
    if (React.isValidElement(value))
        return React.cloneElement(value, props);
    if (isFunction(value))
        return React.createElement(value, props);
    return null;
}
