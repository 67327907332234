import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FlexCol } from "Atoms/Layout";
import { translate } from "Services/App";
import AuthCodeInput from "Atoms/AuthCodeInput/AuthCodeInput";
import { useCallback, useEffect, useState } from "react";
import FormErrorMessage from "Atoms/Form/FormErrorMessage";
import { Button } from "Atoms/Button";
import { Text } from "Atoms/Text";
import { generate } from "Services/Router";
export const TwoFactorSection = ({ twoFactorCheck }) => {
    const [authCode, setAuthCode] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const onSubmit = useCallback(async (authCode) => {
        await twoFactorCheck({ _auth_code: authCode })
            .then(response => {
            window.location.href = response.redirectUrl ?? "/";
        })
            .catch(error => {
            const status = error.response.status;
            if (status === 400) {
                setErrorMessage(error.response.data.message);
                return;
            }
            throw error;
        });
    }, []);
    useEffect(() => {
        if (authCode.replace(/\s/g, '').length !== 6) {
            return;
        }
        void onSubmit(authCode);
    }, [authCode]);
    return (_jsxs(FlexCol, { gap: 40, children: [_jsxs(FlexCol, { gap: 16, children: [_jsx(Text, { h6: 500, children: translate('2fa.login.title') }), _jsx(Text, { medium: 400, neutral: 500, children: translate('2fa.login.codeLabel') })] }), _jsxs(FlexCol, { gap: 16, alignCenter: true, children: [_jsx(AuthCodeInput, { autoFocus: true, defaultValue: authCode, onChange: (value) => {
                            setAuthCode(value);
                            setErrorMessage(null);
                        }, length: 6 }), !!errorMessage && _jsx(FormErrorMessage, { children: errorMessage })] }), _jsxs(FlexCol, { gap: 24, children: [_jsx(Button, { disabled: !!errorMessage, onClick: () => void onSubmit(authCode), children: "Login" }), _jsx(Button, { variant: 'link', href: generate('app_logout'), children: translate('user.logout') })] })] }));
};
