import { Palette, sizeToken } from '@f2w/theme';
export const ButtonColorTokens = {
    ...Palette.tokenMap,
};
export const buttonSizeToken = sizeToken.extend({
    xs: {
        styles: (prev) => ({
            ...prev,
        }),
    },
    sm: {
        styles: (prev) => ({
            ...prev,
        }),
    },
    md: {
        styles: (prev) => ({
            ...prev,
        }),
    },
    lg: {
        styles: (prev) => ({
            ...prev,
        }),
    },
});
export const ButtonVariantTokens = {
    neutral: ({ palette: { neutral } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$500 },
        hover: { color: color.$800 },
        disabled: { color: neutral.$300 },
    } : {
        base: { color: neutral.$500 },
        hover: { color: color.$500 },
        disabled: { color: neutral.$300 },
    }),
    link: ({ palette: { neutral: disabledColor } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$500 },
        hover: { color: color.$800 },
        disabled: { color: disabledColor.$300 },
    } : {
        base: { color: color.$500 },
        hover: { color: color.$800 },
        disabled: { color: disabledColor.$300 },
    }),
    textFill: ({ palette: { neutral: disabledColor, white } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$800, background: 'transparent' },
        hover: { color: color.$50, background: color.$500 },
        active: { color: color.$50, background: color.$500 },
        disabled: { color: disabledColor.$300 },
    } : {
        base: { color: 'inherit', background: 'var(--fw-btn-background, transparent)' },
        hover: { color: white, background: color.$500 },
        active: { color: white, background: color.$500 },
        disabled: { color: disabledColor.$300 },
    }),
    linkFill: ({ palette: { neutral: disabledColor, white } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$800, background: 'transparent' },
        hover: { color: color.$800, background: color.$100 },
        active: { color: color.$50, background: color.$800 },
        disabled: { color: disabledColor.$300 },
    } : {
        base: { color: color.$500, background: 'var(--fw-btn-background, transparent)' },
        hover: { color: color.$600, background: color.$50 },
        active: { color: color.$600, background: color.$100 },
        disabled: { color: disabledColor.$300 },
    }),
    outline: ({ palette: { neutral: disabledColor } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$500, background: 'transparent', borderColor: color.$500 },
        hover: { color: color.$600, background: color.$50, borderColor: color.$500 },
        active: { color: color.$700, background: color.$100, borderColor: color.$500 },
        disabled: { color: disabledColor.$300, borderColor: disabledColor.$300 },
    } : {
        base: { color: color.$500, background: 'var(--fw-btn-background, transparent)', borderColor: color.$500 },
        hover: { color: color.$600, background: color.$50, borderColor: color.$500 },
        active: { color: color.$700, background: color.$100, borderColor: color.$500 },
        disabled: { color: disabledColor.$300, borderColor: disabledColor.$300 },
    }),
    outlineFill: ({ palette: { neutral: disabledColor } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$500, background: 'transparent', borderColor: color.$500 },
        hover: { color: color.$700, background: color.$50, borderColor: color.$500 },
        active: { color: color.$50, background: color.$500, borderColor: color.$500 },
        disabled: { color: disabledColor.$300, borderColor: disabledColor.$300 },
    } : {
        base: { color: color.$500, background: 'var(--fw-btn-background, transparent)', borderColor: color.$500 },
        hover: { color: color.$700, background: color.$50, borderColor: color.$500 },
        active: { color: color.$50, background: color.$500, borderColor: color.$500 },
        disabled: { color: disabledColor.$300, borderColor: disabledColor.$300 },
    }),
    fill: ({ palette: { neutral: disabledColor }, palette }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: palette.white, background: color.$500, borderColor: color.$500 },
        hover: { color: palette.white, background: color.$600, borderColor: color.$600 },
        active: { color: palette.white, background: color.$600, borderColor: color.$600 },
        disabled: { color: disabledColor.$300, background: disabledColor.$100, borderColor: disabledColor.$100 },
    } : {
        base: { color: palette.white, background: color.$500, borderColor: color.$500 },
        hover: { color: palette.white, background: color.$600, borderColor: color.$600 },
        active: { color: palette.white, background: color.$600, borderColor: color.$600 },
        disabled: { color: disabledColor.$300, background: disabledColor.$100, borderColor: disabledColor.$100 },
    }),
};
function buttonStyles({ base, hover, focus, disabled, active, activeFocus }) {
    return {
        ...base,
        '&:hover': hover || base,
        '&:focus, &.focus': focus || hover || base,
        '&:is(.disabled, :disabled, [aria-disabled=true])': {
            opacity: .8,
            ...(disabled || base),
        },
        ['&:not(:is(.disabled, :disabled, [aria-disabled=true], [aria-busy=true])):is(:active, .is-selected), .show > &.fw-dropdown-toggle']: {
            ...(active || hover || base),
            '&:focus, &.focus': activeFocus || focus || active || hover || base,
        },
    };
}
export const ButtonColorDeprecated = {
    '': ({ palette: { dark: color } }) => buttonStyles({
        base: { background: 'transparent', borderColor: 'transparent', color: color.$600 },
        hover: { background: 'transparent', borderColor: 'transparent', color: color.$400 },
        active: { background: 'transparent', borderColor: 'transparent', color: color.$700 },
        disabled: { background: 'transparent', borderColor: 'transparent', color: color.$100 },
    }),
    primary: (t) => ButtonVariantTokens.fill(t, { color: t.palette.primary }),
    'primary-outline': (t) => ButtonVariantTokens.outline(t, { color: t.palette.primary }),
    secondary: (t) => ButtonVariantTokens.fill(t, { color: t.palette.secondary }),
    'secondary-outline': (t) => ButtonVariantTokens.outline(t, { color: t.palette.secondary }),
    dark: (t) => ButtonVariantTokens.fill(t, { color: t.palette.dark }),
    'dark-outline': (t) => ButtonVariantTokens.outline(t, { color: t.palette.dark }),
    success: (t) => ButtonVariantTokens.fill(t, { color: t.palette.success }),
    warning: (t) => ButtonVariantTokens.fill(t, { color: t.palette.warning }),
    error: (t) => ButtonVariantTokens.fill(t, { color: t.palette.error }),
    'error-outline': (t) => ButtonVariantTokens.outline(t, { color: t.palette.error }),
    link: ({ palette }) => buttonStyles({
        base: {
            color: palette.primary.$500,
        },
        hover: {
            color: palette.primary.$700,
        },
    }),
};
