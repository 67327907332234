import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { getIn } from 'formik';
import { Select } from 'Atoms/Select';
import { convertOptionsToValues } from "Utils/select";
const FormSelect = ({ field, form: { touched, errors, setFieldValue, setFieldTouched }, select, ...props }) => {
    const SelectComponent = select ? select : Select;
    const isFieldTouched = getIn(touched, field.name);
    const isFieldErrored = !!getIn(errors, field.name);
    const $color = isFieldTouched
        ? (isFieldErrored ? 'error' : 'primary')
        : 'primary';
    return (_jsx(_Fragment, { children: _jsx(SelectComponent, { "$color": $color, container: true, onChange: (option) => {
                setFieldValue(field.name, convertOptionsToValues(option));
            }, onBlur: () => {
                setFieldTouched(field.name);
            }, value: field.value, ...props }) }));
};
export default FormSelect;
