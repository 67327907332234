import { jsx as _jsx } from "react/jsx-runtime";
import { Select, UseOption } from 'Atoms/Select';
import { translate } from 'Services/Translator';
import { TransactionStatusType } from '../../types';
const options = [
    { value: TransactionStatusType.PRE_ASSIGNED, label: translate('bankingReconciliationTransactionsTable.status.PRE_ASSIGNED') },
    { value: TransactionStatusType.PENDING, label: translate('bankingReconciliationTransactionsTable.status.PENDING') },
    { value: TransactionStatusType.IGNORED, label: translate('bankingReconciliationTransactionsTable.status.IGNORED') },
    { value: TransactionStatusType.ASSIGNED, label: translate('bankingReconciliationTransactionsTable.status.ASSIGNED') },
];
export function StatusFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(Select, { value: filterValue !== undefined ? filterValue : '', isSearchable: false, isClearable: true, isMulti: true, useMaxContentWidth: true, options: options, onChange: (newValue) => {
            const value = UseOption.getValue(newValue);
            setFilter(value ?? undefined);
        } }));
}
