import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDataTableContext } from '@f2w/data-table/DataTableProvider';
import { Button, Div, FlexCol, FlexRow } from 'Components/Atoms';
import { useEventCallback } from '@restart/hooks';
import { translate } from 'Services/Translator';
export function SelectRows({ instance = useDataTableContext(), }) {
    if (!instance?.hasPlugin?.('useRowSelect')) {
        return null;
    }
    const { isAllRowsSelected, selectAllRows, deselectAllRows, manualControl, getAllIds, showSelectAll, pageTotalElements, state: { selectedRowIds, } } = instance;
    if (!showSelectAll || (manualControl && !getAllIds)) {
        return null;
    }
    const onSelectAllRows = useEventCallback((e) => {
        selectAllRows();
    });
    const onDeselectAllRows = useEventCallback((e) => {
        deselectAllRows();
    });
    const selectedRowsLength = Object.keys(selectedRowIds).length;
    return selectedRowsLength > 0 && (_jsx(FlexCol, { children: _jsxs(FlexRow, { alignItems: "center", children: [_jsx(Div, { children: translate('table.multiselect.selected', { count: selectedRowsLength, total: pageTotalElements }) }), !isAllRowsSelected && _jsx(Button, { "$color": 'link', onClick: onSelectAllRows, children: translate('table.multiselect.selectAll', { total: pageTotalElements }) }), _jsx(Button, { "$color": "link", onClick: onDeselectAllRows, children: translate('table.multiselect.deselectAll') })] }) }));
}
