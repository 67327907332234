import generateRoute from "Services/Router";
import client from "Utils/client";
export const globalSearch = async (query, accountId) => {
    let params = { query };
    if (accountId) {
        params['account'] = accountId;
    }
    const route = generateRoute('global_search', params);
    const { data } = await client.get(route);
    return data;
};
export const globalSearchHistory = async () => {
    const route = generateRoute('global_search_history');
    const { data } = await client.get(route);
    return data;
};
export const globalSearchStoreSelection = async (itemId, itemType) => {
    const route = generateRoute('global_search_store_selection');
    const { data } = await client.post(route, {
        id: itemId,
        type: itemType
    });
    return data;
};
