import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import { translate } from "Services/Translator";
const options = [
    { value: "kWh", label: translate('unit.kWh') },
    { value: "l", label: translate('unit.l') },
    { value: "m2", label: translate('unit.m2') },
    { value: "m3", label: translate('unit.m3') },
    { value: "he", label: translate('unit.he') },
    { value: "CHF", label: translate('unit.CHF') },
    { value: "kg", label: translate('unit.kg') },
    { value: "o/oo", label: translate('unit.o/oo') },
    { value: "o/o", label: translate('unit.o/o') },
];
const UnitSelect = ({ ...props }) => {
    return (_jsx(Select, { options: options, menuPortalTarget: document.body, ...props }));
};
export default UnitSelect;
