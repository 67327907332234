import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BankingFileDataTable } from "Pages/BankingReconciliation/table/BankingFileDataTable";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import { BankingFileService } from "Pages/BankingReconciliation";
import { useState } from "react";
import { BankingFileUploadModal } from "Pages/BankingReconciliation/components/BankingFileView/BankingFileUploadModal";
import { translate } from "Services/App";
import { useToastDispatcherApi } from "Components/Dispatcher";
export const BankingFileView = ({ upload = BankingFileService.upload, getUploads = BankingFileService.getUploads, deleteUpload = BankingFileService.deleteUpload, getBankAccounts, goToTransactionTab, transactionsTableRef }) => {
    const [filesToUpload, setFilesToUpload] = useState([]);
    const { success, error } = useToastDispatcherApi();
    const instance = BankingFileDataTable.use({
        goToTransactionTab,
        getUploads,
        getBankAccounts,
        deleteUpload: (id) => {
            deleteUpload(id).then(() => {
                instance.refresh();
                transactionsTableRef.current?.refresh();
                transactionsTableRef.current?.setFilter('upload', undefined);
                transactionsTableRef.current?.loadUploadOptions();
                success({
                    message: translate('bankingReconciliation.uploadFiles.uploadDeleted')
                });
            }).catch((e) => {
                if (e.isAxiosError && e.response?.data?.message && e.response?.status === 400) {
                    error({
                        message: translate(e.response.data.message)
                    });
                }
                else {
                    throw e;
                }
            });
        },
        openUploadModal: setFilesToUpload
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, inlineFilters: true }), filesToUpload.length > 0 &&
                _jsx(BankingFileUploadModal, { onClose: () => {
                        setFilesToUpload([]);
                        instance.refresh();
                        transactionsTableRef.current?.refresh();
                        transactionsTableRef.current?.loadUploadOptions();
                    }, filesToUpload: filesToUpload, upload: upload })] }));
};
