import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircleIcon } from 'Components/App/Icons';
import CheckoutSuccessStyled from './CheckoutSuccessStyled';
const { Body, GreenCircle, CheckoutSuccessText } = CheckoutSuccessStyled;
import { Button } from 'Components/Atoms';
import { translate } from "Services/Translator";
import generateRoute from "Services/Router";
const CheckoutSuccess = () => {
    return (_jsxs(Body, { children: [_jsx(GreenCircle, { children: _jsx(CheckCircleIcon, {}) }), _jsx(CheckoutSuccessText, { children: translate('subscriptions.checkoutSuccessText') }), _jsx(Button, { onClick: () => window.location.href = generateRoute('subscriptions'), color: 'primary', variant: 'link', "$size": 'md', children: translate('subscriptions.goBackButton') })] }));
};
export default CheckoutSuccess;
