import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { CustomerStatus, CustomerType } from 'Pages/Administration/Customers/enums';
import { translate, translateAdmin } from 'Services/Translator';
import { EmailType } from '@f2w/form';
import { TypeFilter } from 'Pages/Administration/Customers/components/TypeFilter';
import { StatusFilter } from 'Pages/Administration/Customers/components/StatusFilter';
import { CustomerUserTable } from 'Pages/Administration/Customers/table/CustomerUserTable';
import { Button, ButtonToolbar, FlexRow, Text } from 'Components/Atoms';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { getStatusBadge, getTypeBadge } from 'Pages/Administration/Customers/helpers/badges';
import { getGlobalContext } from "Services/GlobalContext";
import { UserType } from "Types/User";
const BaseTable = Table.factory()(() => ({
    tableId: 'CustomersData',
    manualControl: true,
    BulkActions: ({ selectedFlatRows, enableCustomers, disableCustomers, deleteSelectedCustomers }) => {
        const { user, roles } = getGlobalContext();
        if (user.userType !== UserType.FAIRWALTER) {
            return _jsx(_Fragment, {});
        }
        return (_jsxs(ButtonToolbar, { variant: 'outline', children: [_jsx(Button, { disabled: selectedFlatRows.length === 0, onClick: () => enableCustomers(selectedFlatRows.map(selected => Number(selected.id))), children: translateAdmin('customer.btn.enableSelected') }), _jsx(Button, { disabled: selectedFlatRows.length === 0, onClick: () => disableCustomers(selectedFlatRows.map(selected => Number(selected.id))), children: translateAdmin('customer.btn.disableSelected') }), _jsx(Button, { confirm: translateAdmin('customer.btn.deleteSelected.confirm'), disabled: selectedFlatRows.length === 0 || !roles.includes('ROLE_SUPER_ADMIN'), onClick: () => deleteSelectedCustomers(selectedFlatRows.map(selected => Number(selected.id))), children: translateAdmin('customer.btn.deleteSelected') })] }));
    },
    RowActions: ({ row: { original: { id, customerStatus, type, users } }, enableCustomer, disableCustomer, goToCustomerDetails, resendRegistrationEmail, deleteSelectedCustomers }) => {
        const { roles } = getGlobalContext();
        return (_jsxs(ActionsDropdown, { id: id, quickActions: [
                {
                    icon: 'eye',
                    title: translateAdmin('customer.details'),
                    onClick: () => goToCustomerDetails(id)
                }
            ], children: [getGlobalContext().user.userType === UserType.FAIRWALTER && customerStatus !== CustomerStatus.DELETED && (_jsxs(_Fragment, { children: [_jsx(ActionsDropdown.Link, { onClick: () => customerStatus === CustomerStatus.DISABLED
                                ? enableCustomer(id)
                                : disableCustomer(id), children: customerStatus === CustomerStatus.DISABLED
                                ? translateAdmin('customer.enable')
                                : translateAdmin('customer.disable') }), _jsx(ActionsDropdown.Link, { confirm: translateAdmin('customer.delete.confirm'), disabled: ![CustomerStatus.DISABLED, CustomerStatus.TO_DELETE].includes(customerStatus)
                                || !roles.includes('ROLE_SUPER_ADMIN'), onClick: () => [CustomerStatus.DISABLED, CustomerStatus.TO_DELETE].includes(customerStatus) && roles.includes('ROLE_SUPER_ADMIN')
                                ? deleteSelectedCustomers([id])
                                : null, children: translateAdmin('customer.delete') })] })), canResendRegistrationEmail(type, users) && _jsx(ActionsDropdown.Link, { onClick: () => resendRegistrationEmail(id), children: _jsx("span", { children: translate('customer.resendRegistrationEmail.actionTitle') }) })] }));
    },
    useSortBy: {
        initialState: {
            sortBy: [
                {
                    id: 'id',
                    desc: true
                }
            ]
        }
    },
    useGlobalFilter: {},
    usePagination: {},
    useExpanded: {
        renderSubRow: ({ row: { original }, instance: { enableCustomerUser, disableCustomerUser, editCustomerUser, resendCustomerUserInvitationEmail } }) => {
            return _jsx("div", { style: { padding: "16px 8px" }, children: _jsx(CustomerUserTable, { users: original.users, customerType: original.type, enableCustomerUser: enableCustomerUser, disableCustomerUser: disableCustomerUser, editCustomerUser: editCustomerUser, resendCustomerUserInvitationEmail: resendCustomerUserInvitationEmail }) });
        }
    },
    columns: {
        $rowSelect: {
            sticky: 'left',
        },
        $actions: {
            sticky: 'left'
        },
        id: {
            Header: "ID",
            Cell: ({ value }) => _jsx(FlexRow, { justifyFlexEnd: true, children: value }),
            useSortBy: {}
        },
        name: {
            width: '100%',
            Header: translateAdmin('customer.name'),
        },
        type: {
            Header: translateAdmin('customer.type'),
            Cell: ({ value }) => getTypeBadge(value),
            useFilters: {
                Filter: TypeFilter
            }
        },
        customerContactEmail: {
            Header: translateAdmin('customer.email'),
            Cell: ({ value }) => _jsx(Text, { nowrap: true, children: value ? EmailType.format(value) : translateAdmin('notAvailable') })
        },
        customerStatus: {
            Header: translateAdmin('customer.status'),
            Cell: ({ value }) => _jsx(FlexRow, { gap: 10, alignCenter: true, children: getStatusBadge(value) }),
            useFilters: {
                Filter: StatusFilter
            }
        },
        $rowExpand: {}
    }
}));
const canResendRegistrationEmail = (type, users) => {
    if (type !== CustomerType.PENDING) {
        return false;
    }
    if (users.length !== 1) {
        return false;
    }
    return users[0].emailVerifiedAt === null;
};
export const CustomersDataTable = Object.assign(BaseTable, {
    use: ({ getCustomers, enableCustomers, disableCustomers, enableCustomer, disableCustomer, enableCustomerUser, disableCustomerUser, goToCustomerDetails, deleteSelectedCustomers, editCustomerUser, resendRegistrationEmail, resendCustomerUserInvitationEmail }) => {
        return CustomersDataTable.useTable({
            enableCustomers,
            disableCustomers,
            enableCustomer,
            disableCustomer,
            enableCustomerUser,
            disableCustomerUser,
            deleteSelectedCustomers,
            editCustomerUser,
            goToCustomerDetails,
            resendRegistrationEmail,
            resendCustomerUserInvitationEmail,
        }, {
            getData: async ({ request }) => getCustomers(request)
        });
    }
});
