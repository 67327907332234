import { jsx as _jsx } from "react/jsx-runtime";
import { AbstractFormType, DateType, MoneyType, NumberType, SelectType, StringType, TextType } from '@f2w/form';
import { translate } from 'Services/Translator';
import React from 'react';
import { Div, FlexRow, Spinner } from "Components/Atoms";
import assetServices from "Pages/Asset/services/assetServices";
import { usePortfolio } from "Components/Base/PortfolioContext";
export class AssetType extends AbstractFormType {
    properties = [];
    buildings = [];
    rentalObjects = [];
    rooms = [];
    loading = true;
    _useField(props) {
        super._useField(props);
        const initialLoad = React.useRef(true);
        const [loading, setLoading] = React.useState(true);
        const [properties, setProperties] = React.useState([]);
        const [buildingMap, setBuildingMap] = React.useState(new Map());
        const [rentalObjectMap, setRentalObjectMap] = React.useState(new Map());
        const [roomMap, setRoomMap] = React.useState(new Map());
        this.properties = properties;
        this.buildings = buildingMap.get(this.field.value.propertyId) ?? [];
        this.rentalObjects = rentalObjectMap.get(this.field.value.buildingId) ?? [];
        this.rooms = roomMap.get(this.field.value.rentalObjectId) ?? [];
        this.loading = loading;
        const portfolio = usePortfolio();
        React.useEffect(() => {
            assetServices.getAssetsDropdownData().then(response => {
                const roomMap = this.prepareRoomMap(response);
                const properties = this.prepareProperties(portfolio);
                setRoomMap(roomMap);
                setProperties(properties);
                const buildingMap = new Map();
                portfolio.getBuildings().forEach(building => {
                    buildingMap.set(building.propertyId, [
                        ...(buildingMap.get(building.propertyId) ?? []),
                        {
                            label: building.name,
                            value: building.id
                        }
                    ]);
                });
                const rentalObjectMap = new Map();
                portfolio.getRentalObjects().forEach(rentalObject => {
                    rentalObjectMap.set(rentalObject.buildingId, [
                        ...(rentalObjectMap.get(rentalObject.buildingId) ?? []),
                        {
                            label: rentalObject.name,
                            value: rentalObject.id
                        }
                    ]);
                });
                setBuildingMap(buildingMap);
                setRentalObjectMap(rentalObjectMap);
                setLoading(false);
            });
        }, []);
        React.useEffect(() => {
            if (!initialLoad.current) {
                this.children.buildingId.field.setValue(undefined, false);
            }
            return () => {
                initialLoad.current = false;
            };
        }, [this.field.value.propertyId]);
        React.useEffect(() => {
            if (!initialLoad.current) {
                this.children.rentalObjectId.field.setValue(undefined, false);
            }
            return () => {
                initialLoad.current = false;
            };
        }, [this.field.value.buildingId]);
        React.useEffect(() => {
            if (!initialLoad.current) {
                this.children.roomId.field.setValue(undefined, false);
            }
            return () => {
                initialLoad.current = false;
            };
        }, [this.field.value.rentalObjectId]);
    }
    prepareRoomMap(response) {
        const roomMap = new Map();
        response.rooms.forEach(room => {
            if (roomMap.has(room.rentalObjectId)) {
                const roomsInMap = roomMap.get(room.rentalObjectId);
                roomsInMap.push({
                    value: room.id,
                    label: room.name
                });
                roomMap.set(room.rentalObjectId, roomsInMap);
            }
            else {
                roomMap.set(room.rentalObjectId, [{
                        value: room.id,
                        label: room.name
                    }]);
            }
        });
        return roomMap;
    }
    prepareProperties(portfolio) {
        return portfolio.getProperties().map(property => ({
            value: property.id,
            label: property.name
        }));
    }
    buildFields() {
        return {
            propertyId: new SelectType({
                label: translate('property'),
                choiceOptions: () => this.properties,
                nullable: false,
                searchable: true,
            }),
            buildingId: new SelectType({
                label: translate('building.title'),
                choiceOptions: () => this.buildings,
                nullable: false,
                searchable: true,
            }),
            rentalObjectId: new SelectType({
                label: translate('object'),
                choiceOptions: () => this.rentalObjects,
                nullable: false,
                searchable: true,
            }),
            roomId: new SelectType({
                label: translate('asset.form.room'),
                choiceOptions: () => this.rooms,
                searchable: true
            }),
            name: new StringType({
                label: translate('asset.form.name'),
                required: true,
                constraints: {
                    max: [255, translate('form.validation.noLongerThan', { length: 255 })]
                },
            }),
            cost: new MoneyType({
                label: translate('asset.form.cost'),
                required: false,
                constraints: {
                    min: [0, translate('form.validation.positiveValue')],
                },
            }),
            dateOfPurchase: new DateType({
                label: translate('asset.form.dateOfPurchase'),
                required: false,
            }),
            manufacturer: new StringType({
                label: translate('asset.form.manufacturer'),
                required: false,
                constraints: {
                    max: [255, translate('form.validation.noLongerThan', { length: 255 })]
                },
            }),
            model: new StringType({
                label: translate('asset.form.model'),
                required: false,
                constraints: {
                    max: [255, translate('form.validation.noLongerThan', { length: 255 })]
                },
            }),
            serialNo: new StringType({
                label: translate('asset.form.serialNo'),
                required: false,
                constraints: {
                    max: [255, translate('form.validation.noLongerThan', { length: 255 })]
                },
            }),
            buildYear: new DateType({
                label: translate('asset.form.buildYear'),
                required: false,
                type: 'year'
            }),
            guaranteePeriod: new NumberType({
                label: translate('asset.form.guaranteePeriod'),
                required: false,
                defaultValue: 2,
                getProps: () => ({
                    suffix: translate('years')
                }),
                constraints: {
                    min: [1, translate('form.validation.noLessThan', { length: 1 })],
                },
            }),
            lifeExpectancy: new NumberType({
                label: translate('asset.form.lifeExpectancy'),
                required: false,
                getProps: () => ({
                    suffix: translate('years')
                }),
                constraints: {
                    min: [1, translate('form.validation.noLessThan', { length: 1 })],
                },
            }),
            lastServiceDate: new DateType({
                label: translate('asset.form.lastServiceDate'),
                required: false,
            }),
            nextServiceDate: new DateType({
                label: translate('asset.form.nextServiceDate'),
                required: false,
            }),
            description: new TextType({
                label: translate('asset.form.notes'),
                required: false
            }),
        };
    }
    _renderWidget(iProps) {
        if (this.loading) {
            return (_jsx(Div, { w: '100%', h: 300, children: _jsx(FlexRow, { h: '100%', alignCenter: true, justifyCenter: true, children: _jsx(Spinner, {}) }) }));
        }
        return super._renderWidget(iProps);
    }
}
export class CreateAssetFromRoomType extends AssetType {
    buildFields() {
        const { name, cost, manufacturer, model, description, serialNo, lifeExpectancy, buildYear, guaranteePeriod, dateOfPurchase, lastServiceDate, nextServiceDate } = super.buildFields();
        return {
            name,
            cost,
            dateOfPurchase,
            manufacturer,
            model,
            serialNo,
            buildYear,
            guaranteePeriod,
            lifeExpectancy,
            lastServiceDate,
            nextServiceDate,
            description
        };
    }
}
