import { jsx as _jsx } from "react/jsx-runtime";
import { ContentTemplateNew, SplitViewTemplate } from '@f2w/view';
import { GeneralTab } from './GeneralTab';
export const AssetTemplate = (props) => {
    return _jsx(SplitViewTemplate, { ...props, Template: ContentTemplateNew, leftSide: {
            groups: ['general'],
            Template: props => _jsx(GeneralTab, { ...props }, props.id),
        }, rightSide: {
            groups: ['creditorInvoices'],
        } });
};
