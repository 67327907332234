import { isDevEnv } from '@f2w/utils';
export class BaseType {
    _props = Object.create(null);
    constructor(options) {
        this._props.initialOptions = { ...options };
    }
    __getDefaultOptions() {
        return {};
    }
    get key() {
        return this.specs.key;
    }
    __construct() {
        const { initialOptions } = this._props;
        if (this.specs && initialOptions) {
            this._props.initialOptions = null;
            const defaultOptions = this.__getDefaultOptions();
            const options = {
                ...defaultOptions,
                ...initialOptions,
                constraints: {
                    ...defaultOptions.constraints,
                    ...initialOptions.constraints
                }
            };
            if (isDevEnv())
                console.debug('initialize %o with %o', this.constructor.name, options);
            this.specs.init(this, options);
            return true;
        }
        return false;
    }
    init({ parent, initialValue, ...props }) {
        this.__construct();
        const valueType = this.specs.createValue(props);
        valueType.reset(initialValue);
        return valueType;
    }
}
BaseType.prototype.__is_fw_type = true;
