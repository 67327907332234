import { translate } from 'Services/Translator';
import { handleResponseError } from 'Utils/client';
import { useAppContext } from '../Base/AppContext';
export function useDispatcherApi() {
    const app = useAppContext();
    return {
        handleError: (error, message = null) => {
            handleResponseError(error, {
                dispatcher: app?.toastApi.current,
                defaultMessage: message ?? translate('client.response.error.loading'),
                onError: e => {
                    app?.toastApi.current?.error({
                        message: message ?? error['message'],
                    });
                },
            });
            return null;
        },
        get toast() {
            return app?.toastApi.current;
        },
        get modal() {
            return app?.modalApi.current;
        },
        get upload() {
            return app?.uploadManager.current;
        },
    };
}
export function useToastDispatcherApi() {
    return (useAppContext()?.toastApi?.current || {});
}
export function useModalDispatcherApi() {
    return (useAppContext()?.modalApi?.current || {});
}
export function useModalDispatcherConfirm(props) {
    const ref = useAppContext()?.modalApi;
    return (...args) => {
        ref?.current?._deprecatedApi?.({ ...props, _args: args });
    };
}
export function useModalDispatcherConfirmWithChangingProps(props) {
    const ref = useAppContext()?.modalApi;
    return (args = [], newProps = {}) => {
        ref?.current?._deprecatedApi?.({ ...props, ...newProps, _args: args });
    };
}
