import { forEachObject, isKeyOf, objectKeys, removeKeyOf } from 'Utils';
import { isPlainObject } from 'lodash';
export default function defineConstraintsModifiers(getValidators) {
    const _own = Object.create(null);
    const _options = Object.create(null);
    const _validators = getValidators({ self: _options, own: _own });
    const _propertyDescriptorMap = Object.create(null);
    const setConstraint = (key, constraint) => {
        if (!_validators[key]) {
            console.error(`Missing constraint validator with key "${key}". Possible validators are: %o`, objectKeys(_validators));
            return;
        }
        const args = [];
        let value, message;
        if (isPlainObject(constraint)) {
            isKeyOf(constraint, 'value') && args.push(value = constraint.value);
            isKeyOf(constraint, 'message') && args.push(message = constraint.message);
        }
        else {
            args.push(value = constraint);
        }
        if (args[0] !== undefined) {
            _validators[key](...args);
            _own[key] = value;
            return true;
        }
        removeKeyOf(_own, key);
    };
    forEachObject(getValidators({ self: _options, own: _own }), (key, validatorFn) => {
        _propertyDescriptorMap[key] = {
            configurable: false,
            enumerable: true,
            get: () => _own[key],
            set: (val) => setConstraint(key, val),
        };
    });
    return {
        validators: _validators,
        options: Object.defineProperties(_options, _propertyDescriptorMap),
        own: _own,
        clear: () => {
            forEachObject(_own, (key) => {
                removeKeyOf(_own, key);
            });
        },
        update: (options) => {
            forEachObject(options, (key, val) => {
                setConstraint(key, val);
            });
        },
    };
}
