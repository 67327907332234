import { BaseState, UpdateQueue } from './BaseState';
import { forEachObject, objectKeys, pick } from '@f2w/utils';
import { isUndefined } from 'lodash';
export class SimpleState extends BaseState {
    _config = createConfig(this._configureProps());
    constructor(config) {
        super(config);
    }
    get meta() {
        return this._config.meta;
    }
    get data() {
        return this._config.data;
    }
    get value() {
        return this.hasValue() ? this.data.value : this.data.initialValue;
    }
    hasInitialValue() {
        return 'initialValue' in this._own;
    }
    hasValue() {
        return 'value' in this._own;
    }
    _configureProps() {
        const config = SimpleState.createDe({
            touched: {
                isMeta: true,
                set: (value) => this._own.touched = value,
                getValue: () => (this._own.touched ?? false),
                getMetaValue: () => (this._own.touched ?? false),
            },
            validated: {
                isMeta: true,
                set: (value) => this._own.validated = value,
                getValue: () => (this._own.validated ?? (this._own.error != null)),
                getMetaValue: () => (this._own.validated ?? (this._own.error != null)),
            },
            validating: {
                isMeta: true,
                set: (value) => this._own.validating = value,
                getValue: () => (this._own.validating ?? false),
                getMetaValue: () => (this._own.validating ?? false),
            },
            disabled: {
                isMeta: true,
                set: (value) => this._own.disabled = value,
                getValue: () => this._own.disabled ?? false,
                getMetaValue: () => {
                    return this._own.disabled || this.parentManager.meta.disabled || false;
                },
            },
            dirty: {
                isMeta: true,
                getValue: () => {
                    return this.hasValue() && this._own.value !== this._own.initialValue;
                },
                getMetaValue: () => {
                    return this.hasValue() && this._own.value !== this._own.initialValue;
                },
            },
            valid: {
                isMeta: true,
                getValue: () => (this._own.error === ''),
                getMetaValue: () => (this._own.error === ''),
            },
            hasError: {
                isMeta: true,
                getValue: () => (this._own.error !== ''),
                getMetaValue: () => (this._own.error !== ''),
            },
            value: {
                getValue: () => this._own.value,
                set: (value) => {
                    this._own.prevValue = this._own.value;
                    this._own.value = value;
                },
            },
            initialValue: {
                getValue: () => this._own.initialValue,
                set: (value) => {
                    this._own.initialValue = value;
                },
            },
            error: {
                getValue: () => this._own.error,
                set: (value) => this._own.error = value,
            },
            prevValue: {
                getValue: () => this._own.prevValue,
            },
            formattedValue: {
                getValue: () => (this._own.formattedValue ?? this._own.value),
                set: (value) => this._own.formattedValue = value,
            },
        });
        return config;
    }
    clear(skipUpdate, ignoreValue) {
        this._own = pick(this._own, ['initialValue', ignoreValue ? ['value', 'formattedValue', 'prevValue'] : []]);
        if (!skipUpdate) {
            this.updateParent() && this._dispatchUpdate();
        }
    }
    reset(options) {
        let initialValue = this._own.initialValue;
        this.clear(true);
        if (!isUndefined(options?.newValue)) {
            initialValue = this._cast(options?.newValue, this.hasInitialValue());
        }
        else if (!this.hasInitialValue()) {
            initialValue = this._type().getDefault();
        }
        const updated = this.set('initialValue', initialValue);
        if (!options?.skipRender && (this.updateParent() || updated)) {
            this._dispatchUpdate();
        }
    }
    _updateQueue = UpdateQueue.create((props) => {
        this._userConfig?.onChange?.(props);
    });
    update(data) {
        const updatedKeys = objectKeys(data).flatMap(key => {
            return this._set(key, data[key]);
        });
        if (this._updateQueue.length) {
            updatedKeys?.length && this.updateParent(updatedKeys);
            this._updateQueue.flush();
            return true;
        }
    }
    set(key, value) {
        const updatedKeys = this._set(key, value);
        if (this._updateQueue.length) {
            updatedKeys?.length && this.updateParent(updatedKeys);
            this._updateQueue.flush();
            return true;
        }
    }
    _set(key, value) {
        switch (key) {
            case 'initialValue':
                if (this._setData(key, value)) {
                    return this.meta.touched ? ['dirty'] : [];
                }
                break;
            case 'value':
                if (this._setData(key, value, !this.hasValue())) {
                    return ['dirty', 'touched'];
                }
                break;
            case 'error':
                if (this._setData(key, value)) {
                    return ['validated', 'valid', 'hasError'];
                }
                break;
            default:
                if (this._setData(key, value)) {
                    return [key];
                }
                break;
        }
    }
    _setData(key, value, force) {
        const propConfig = this._config.props[key];
        if (propConfig.set) {
            const prevValue = propConfig.getValue();
            if (force || prevValue !== value) {
                propConfig.set(value);
                this._updateQueue.push({ propName: key, value, prevValue, force });
                return { prevValue, value };
            }
        }
    }
}
function createConfig(config) {
    const data = Object.create(null);
    const meta = Object.create(null);
    forEachObject(config, (key, configProp) => {
        const getterProps = {
            configurable: false,
            enumerable: true,
        };
        configProp.getValue && Object.defineProperty(data, key, { ...getterProps, get: () => configProp.getValue() });
        configProp.getMetaValue && Object.defineProperty(meta, key, { ...getterProps, get: () => configProp.getMetaValue() });
    });
    return {
        props: config,
        _mutable: {},
        data: data,
        meta: meta,
    };
}
