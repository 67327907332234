import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { SimpleType } from '../core';
import { Div, FormControl, Icon } from 'Components/Atoms';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input/input';
export class StringType extends SimpleType.ExtendString {
    constructor(options, parent) {
        super({
            ...options,
            constraints: {
                max: [options?.limit || 255, translate('form.validation.noLongerThan', { length: 255 })],
                ...options?.constraints,
            },
        }, parent);
    }
    _renderLabel(props) {
        return super._renderLabel({
            ...props,
            style: {
                flexDirection: 'row',
                alignItems: 'end',
                justifyContent: 'space-between'
            },
            text: !this.options.limit ? undefined : _jsxs(Div, { ml: 'auto', mr: 0, selfFlexEnd: true, children: ["(", this.value.length, "/", this.options.limit, ")"] })
        });
    }
    WidgetComponent(props) {
        if (this.options.autocomplete) {
            return _jsx(FormControl.Autocomplete, { ...props, ...this.options.autocomplete });
        }
        return _jsx(FormControl.Input, { type: 'text', maxLength: this.options.limit || undefined, ...props });
    }
}
export class EmailType extends SimpleType.ExtendString {
    constructor(options, parent) {
        super({
            label: translate('form.type.email.label'),
            ...options,
            constraints: {
                max: [255, translate('form.validation.noLongerThan', { length: 255 })],
                email: [translate('form.type.email.error')],
                ...options?.constraints,
            },
        }, parent);
    }
    WidgetComponent(props) {
        return super.WidgetComponent({
            type: 'email',
            prefix: _jsx(Icon, { icon: 'mail' }),
            ...props,
        });
    }
}
export class UrlType extends SimpleType.MaskedString {
    constructor(options, parent) {
        super({
            label: translate('form.type.url.label'),
            ...options,
            constraints: {
                max: [255, translate('form.validation.noLongerThan', { length: 255 })],
                ...options?.constraints,
                url: [translate('form.type.url.error')],
            },
            mask: {
                mask: '{http}[#]{://}`HOST`PATH',
                definitions: {
                    '#': {
                        mask: /[s]/
                    }
                },
                prepare: (chars, masked, flags) => {
                    if (chars && chars.charAt(0) !== 'h' && !masked.value && flags?.input) {
                        return 'https' + chars;
                    }
                    return chars;
                },
                blocks: {
                    HOST: {
                        mask: 'aHOST',
                        blocks: {
                            HOST: { mask: /^[a-z0-9\-\.]+$/ },
                        },
                    },
                    PATH: {
                        mask: '{/}PATH',
                        blocks: {
                            PATH: { mask: /^.*$/ },
                        }
                    },
                },
            },
        }, parent);
    }
    WidgetComponent(props) {
        return super.WidgetComponent({
            type: 'url',
            prefix: _jsx(Icon, { icon: 'globe' }),
            ...props,
        });
    }
}
export class PhoneType extends SimpleType.ExtendString {
    constructor({ ...options }, parent) {
        super({
            label: translate('form.type.phone.label'),
            ...options,
        }, parent);
    }
    validate(value) {
        return super.validate(value)
            .then(() => (!value || isValidPhoneNumber(value, { defaultCountry: 'CH' })
            ? ''
            : this.throwValidationError(translate('form.type.phone.error'))));
    }
    cast(value, ignoreDefaults) {
        let val = super.cast(value, ignoreDefaults);
        if (val) {
            if (val.startsWith('00'))
                val = `+${val.substring(2)}`;
            else if (val.startsWith('+00'))
                val = `+${val.substring(3)}`;
            else if (val.startsWith('+0'))
                val = `+${val.substring(2)}`;
            return val.replaceAll(' ', '');
        }
        return val;
    }
    format(value) {
        let val = this.cast(value);
        return formatPhoneNumberIntl(val);
    }
    WidgetComponent(props) {
        return super.WidgetComponent({
            prefix: _jsx(Icon, { icon: 'phone' }),
            inputAs: PhoneInput,
            type: 'tel',
            ...props,
        });
    }
}
