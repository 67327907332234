import { useLayoutProps } from './useLayoutProps';
import classNames from 'classnames';
const prefix = 'fw-col';
export function useColProps({ className, ...props }) {
    const spans = [];
    const classes = [];
    useColProps.DEVICE_SIZES.forEach((brkPoint) => {
        const propValue = props[brkPoint];
        delete props[brkPoint];
        let span;
        let offset;
        let order;
        if (typeof propValue === 'object' && propValue != null) {
            ({ span = true, offset, order } = propValue);
        }
        else {
            span = propValue;
        }
        const infix = brkPoint !== 'xs' ? `-${brkPoint}` : '';
        if (span)
            spans.push(span === true ? `${prefix}${infix}` : `${prefix}${infix}-${span}`);
        if (order != null)
            classes.push(`order${infix}-${order}`);
        if (offset != null)
            classes.push(`offset${infix}-${offset}`);
    });
    if (!spans.length) {
        spans.push(prefix);
    }
    props = useLayoutProps(props);
    return {
        ...props,
        className: classNames(className, ...spans, ...classes)
    };
}
(function (useColProps) {
    useColProps.DEVICE_SIZES = [
        'xl',
        'lg',
        'md',
        'sm',
        'xs',
    ];
})(useColProps || (useColProps = {}));
