import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button, Col, Div, FlexRow, JsonCode, JsonTree, Row } from 'Components/Atoms';
import { Header } from 'Atoms/Layout/components';
import { TabsNav } from './TabsNav';
export * from './TabsNav';
export const FormLayout = ({ children, ...props }) => {
    const { valueType, debug } = props;
    React.useEffect(() => {
        return () => {
        };
    }, []);
    return (_jsxs(DebugInfo, { ...props, children: [children, valueType.render(), _jsx(Header.Sticky, { sticky: { bottom: 0 }, py: 10, children: _jsxs(FlexRow, { gap: 20, ml: 'auto', children: [_jsx(Button, { color: "error", onClick: () => valueType.clear(), disabled: !valueType.meta.touched, label: 'Reset' }), _jsx(Button, { onClick: () => valueType.submit(), label: 'Save' })] }) })] }));
};
export const DebugInfo = ({ valueType, children, debug }) => {
    if (!debug || !valueType)
        return children;
    return (_jsxs(Row, { gap: 0, children: [_jsx(Col, { children: _jsx(Div, { p: 30, children: children }) }), _jsx(Col, { sm: 5, children: _jsx(DebugTabs, { valueType: valueType }) })] }));
};
export const DebugTabs = ({ valueType, }) => (_jsx(TabsNav, { styles: { maxh: 'calc(100vh - 20px)' }, tabs: [
        {
            name: 'Data',
            content: () => _jsxs(_Fragment, { children: [_jsx(JsonCode, { indent: 2, title: "Initial value", value: valueType.initialValue }), _jsx(JsonCode, { indent: 2, title: "Value", value: valueType.value }), _jsx(JsonCode, { indent: 2, title: "Error", value: valueType.error }), _jsx(JsonCode, { indent: 2, title: "Touched", value: valueType.touched })] }),
        },
        {
            name: 'State',
            content: () => _jsx(_Fragment, { children: _jsx(JsonCode, { title: "State", indent: 2, value: { ...valueType.data } }) }),
        },
        {
            name: 'Dump',
            isDefault: true,
            content: () => _jsx(_Fragment, { children: _jsx(JsonTree, { dataObject: valueType.__dump(), expanded: true }) }),
        },
        {
            name: 'Dump Props',
            content: () => _jsx(_Fragment, { children: _jsx(JsonTree, { dataObject: valueType.__dumpProps(), expanded: true }) }),
        },
        {
            name: 'Options dump',
            content: () => _jsx(_Fragment, { children: _jsx(JsonCode, { indent: 2, value: valueType.specs._dump() }) }),
        },
    ] }));
