import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Button, Title, UploadArea } from 'Components/Atoms';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { Header, Section } from 'Atoms/Layout/components';
import ViewModal from 'Components/ObjectViewModal/components/ViewModalStyled';
import { CustomerDocumentServices } from "Pages/CustomerDocuments/services";
export const UploadsTab = ({ group: { title, data: contract, }, view, }) => {
    const [showDropzone, setShowDropzone] = React.useState(false);
    const toastApi = useToastDispatcherApi();
    const onExecuteDeleteCustomerDocument = async (documentId) => {
        const { uploads } = contract;
        try {
            await CustomerDocumentServices.deleteCustomerDocument(documentId);
            view.updateData({
                uploads: uploads.filter(u => documentId !== u.documentId)
            });
        }
        catch (e) {
            toastApi?.error?.({ message: translate('generalError.default'), timeout: 5, showRemove: true });
            console.error(e);
        }
        finally {
        }
    };
    const onUpload = useCallback(async (files) => {
        const results = await CustomerDocumentServices.uploadCustomerDocuments(files.map(upload => upload.file), { contract: contract.id });
        const newUploads = [...contract.uploads];
        results.forEach(({ data: { documentId, fileName: name, filePath: path } }) => newUploads.push({ documentId, name, path }));
        view.updateData({ uploads: newUploads });
        setShowDropzone(false);
    }, [contract.id]);
    if (showDropzone) {
        return (_jsx(Section, { children: _jsx(UploadArea, { multiple: true, onUpload: onUpload, config: 'customer_documents', placeholderInfo: '', isEmpty: true, children: null }) }));
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Header.Sticky, { children: [_jsx(Title, { h6: true, as: 'h3', children: title }), _jsx(Button.Action, { icon: 'upload-cloud', label: translate('btn.upload'), onClick: () => setShowDropzone(true) })] }), _jsx(Section, { sticky: true, children: contract.uploads.map((upload, index) => (_jsx(ViewModal.DocumentLink, { icon: 'image', label: upload.name, onDownload: upload.path, onDeleteConfirm: {
                        icon: 'alert-triangle',
                        message: translate('flash.confirmation.areYouSure'),
                        help: translate('flash.confirmation.text'),
                        btnConfirm: translate('btn.confirm')
                    }, onDelete: () => onExecuteDeleteCustomerDocument(upload.documentId) }, `item-${index}`))) })] }));
};
