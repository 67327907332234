import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Div, FlexCol } from "Atoms/Layout";
import { Text } from "Atoms/Text";
import { translate } from "Services/App";
import { useState } from "react";
import { Button } from "Atoms/Button";
import { usePasswordForgetForm } from "Pages/Auth/hooks/usePasswordForgetForm";
import { Form, FormProvider } from "@f2w/form";
export const ForgotPasswordSection = ({ onBack, forgotPassword, initialEmail }) => {
    const [success, setSuccess] = useState(false);
    const form = usePasswordForgetForm({
        onSave: () => setSuccess(true),
        forgotPassword,
        initialEmail
    });
    return (_jsxs(FlexCol, { gap: 40, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('account.password.reset') }), _jsxs(FlexCol, { gap: 24, children: [_jsx(FormProvider, { form: form, children: _jsx(Form, { noValidate: true, children: form.render() }) }), _jsx(Button, { disabled: success, onClick: () => {
                            !success && form.submitForm();
                        }, children: translate('btn.next') }), _jsx(Button, { variant: 'link', onClick: onBack, children: translate('btn.backToLogin') })] }), success && (_jsx(Div, { brd: 8, p: 16, bg: 'success.$50', children: _jsx(Text, { medium: 400, success: 500, children: translate('flash.account.email.reset.sent') }) }))] }));
};
