import generateRoute from 'Services/Router';
import client from 'Utils/client';
const getIndexBases = () => {
    const route = generateRoute('api.inflation.indexBase.list');
    return client
        .get(route)
        .then(response => response.data);
};
const getInflationIndexesByIndexBase = (indexBaseId) => {
    const route = generateRoute('api.inflation.inflationIndex.list', {
        indexBase: indexBaseId
    });
    return client
        .get(route)
        .then(response => response.data);
};
const getReferenceInterestRates = () => {
    const route = generateRoute('api.inflation.referenceInterestRate.list');
    return client
        .get(route)
        .then(response => response.data);
};
const IndexServices = {
    getIndexBases,
    getInflationIndexesByIndexBase,
    getReferenceInterestRates
};
export default IndexServices;
