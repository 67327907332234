import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { isFunction, isObject, isPlainObject, isString } from 'Utils';
import { BaseMenuItem, NestedMenu, MenuDivider, MenuHeader, MenuLink, } from './builder';
export class MenuBuilder {
    _items = [];
    props;
    constructor(props) {
        this.props = {
            ...props,
        };
    }
    get id() {
        return this.props.id ?? 'fw-mb';
    }
    get label() {
        return this.props.label;
    }
    init() {
        if (!this.__isInitialized) {
            this.__isInitialized = true;
            this._items.forEach((child, key) => child.init(this.id));
        }
        return this;
    }
    render({ renderMode, ...props } = {}) {
        return this.hasChildren() ? _jsx(_Fragment, { children: this.getChildren().map((child, key) => child.render({ key }, renderMode)) }) : null;
    }
    getChildren() {
        return [...this._items];
    }
    hasChildren() {
        return this._items.length > 0;
    }
    builder(id) {
        return MenuBuilder.create(id);
    }
    static divider = (props) => new MenuDivider(props);
    divider(props) {
        return this.add(MenuBuilder.divider(props));
    }
    static header = (props) => new MenuHeader(props);
    header(...args) {
        let props = args.find(v => isObject(v)) || {};
        if (isString(args[0])) {
            props.label = args[0];
        }
        this.add(MenuBuilder.header(props));
        return this;
    }
    link(...args) {
        this.add(MenuBuilder.link.apply(MenuBuilder, args));
        return this;
    }
    dropdown(...args) {
        this.add(MenuBuilder.createItem(...args));
        return this;
    }
    add(...items) {
        NestedMenu.resolve(items).forEach(c => this._items.push(c));
        return this;
    }
    static create(id, label) {
        return new MenuBuilder({
            id: id ?? ('menu-' + IDS_COUNT++),
            label,
        });
    }
    static use(cb, deps = []) {
        return useMemo(() => cb(this), deps);
    }
}
let IDS_COUNT = 1;
var CreateItem2;
(function (CreateItem2) {
    CreateItem2.isChildType = (value) => isFunction(value) || Array.isArray(value) || value instanceof BaseMenuItem || value instanceof MenuBuilder;
})(CreateItem2 || (CreateItem2 = {}));
(function (MenuBuilder) {
    function createItem(...args) {
        const props = {};
        if (isString(args[0]))
            props.label = args.shift();
        if (isString(args[0]))
            props.url = args.shift();
        args.forEach(value => {
            if (isFunction(value) || Array.isArray(value) || value instanceof BaseMenuItem || value instanceof MenuBuilder)
                props.children = props.children ? [props.children, value] : value;
            else if (isPlainObject(value))
                Object.assign(props, value);
        });
        return new MenuLink(props);
    }
    MenuBuilder.createItem = createItem;
    function link(...args) {
        return createItem(...args);
    }
    MenuBuilder.link = link;
    function dropdown(...args) {
        return createItem(...args);
    }
    MenuBuilder.dropdown = dropdown;
})(MenuBuilder || (MenuBuilder = {}));
