import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import { DataTable } from '@f2w/data-table';
import { Div, Input, Text } from 'Components/Atoms';
import { DistributionKeyServices } from '../services';
import { Table } from '@f2w/data-table/table-types';
import { RentalObjectViewFormType } from 'Components/ObjectViewModal/forms/RentalObjectViewFormType';
const BaseTable = DataTable.factory()(() => ({
    tableId: 'DistributionKeyValues',
    manualControl: false,
    showEmpty: true,
    usePagination: {},
    useGlobalFilter: {},
    useSortBy: {
        initialState: { sortBy: [{ id: 'building' }] }
    },
    columns: {
        building: {
            Header: translate('building.title'),
            accessor: d => d.building.name,
            useSortBy: {},
            useFilters: {
                filter: 'exactText',
                Filter: props => _jsx(Table.filters.SelectFilter, { table: props, useMaxContentWidth: true, placeholder: translate('allBuildings') }),
            },
        },
        rentalObject: {
            Header: translate('rentalObject'),
            accessor: d => d.name,
            useSortBy: {},
            Cell: ({ value }) => _jsx(Text, { nowrap: true, value: value }),
        },
        tenant: {
            Header: translate('ancillaryExpense.distributionKeys.tenant'),
            useSortBy: {},
            accessor: d => d.contractParties?.[0]?.name,
        },
        floor: {
            Header: translate('ancillaryExpense.distributionKeys.floor'),
            useSortBy: {},
            Cell: ({ value }) => RentalObjectViewFormType.getFloorTrans(value),
        },
        area: {
            Header: translate('ancillaryExpense.distributionKeys.area'),
            useSortBy: {},
            Cell: ({ value }) => value ? _jsxs(_Fragment, { children: [value, " m", _jsx("sup", { children: "2" })] }) : translate('notAvailable'),
        },
        value: {
            Header: translate('ancillaryExpense.distributionKeys.value'),
            Cell: ({ value: _value, valuesApi, row: { id, original: { numberOfRooms, area, volume } }, keyType }) => {
                const { value = _value, } = valuesApi.getData(id);
                if (keyType === 'area') {
                    return _jsx(_Fragment, { children: area ? area : 0 });
                }
                if (keyType === 'volume') {
                    return _jsx(_Fragment, { children: volume ? volume : 0 });
                }
                if (keyType === 'rooms') {
                    return _jsx(_Fragment, { children: numberOfRooms ? numberOfRooms : 0 });
                }
                if (keyType === 'custom') {
                    return (_jsx(Div, { textNoWrap: true, children: _jsx(Input, { type: 'number', step: 0.01, "$size": 'sm', value: value ?? 0, onChange: (value) => {
                                valuesApi.update(id, value ? { value } : undefined);
                            } }) }));
                }
                return _jsx(_Fragment, { children: "1" });
            }
        },
    },
    postHook: hooks => {
        hooks.getRowProps.push((props, { row, instance }) => {
            const { valuesApi, isEditable } = instance;
            return isEditable ? {
                ...props,
                ...{
                    status: valuesApi?.isDirty(row.id) ? 'warning' : null,
                },
            } : props;
        });
    }
}));
export const DistributionKeyValuesTable = Object.assign(BaseTable, {
    use: ({ valuesApi, isEditable, propertyId, keyType, distributionKeyServices = DistributionKeyServices }) => {
        const getData = React.useCallback(() => distributionKeyServices
            .getDistributionKeyPropertyInfo(propertyId)
            .then(rentalObjects => {
            valuesApi.reset();
            return rentalObjects;
        }), [propertyId]);
        const table = BaseTable.useTable({
            getData,
            isEditable,
            keyType,
            initialState: {},
        }, {
            valuesApi,
        });
        return table;
    },
});
