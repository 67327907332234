import { UseStyled } from "@f2w/theme";
import { css } from "styled-components";
export const ImageBoxStyled = UseStyled.styled('div', ({ theme: { palette, typography, rem } }) => (css `
    border-radius: ${rem(16)};
    overflow: hidden;
    width: ${rem(130)};
    height: ${rem(80)};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    //for placeholder:
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${palette.neutral.$50};
    color: ${palette.neutral.$200};
`));
