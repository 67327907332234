import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import AccountLimitReachedModal from "Components/App/AccountLimits/AccountLimitReachedModal/AccountLimitReachedModal";
import generateRoute from "Services/Router";
import SubscriptionServices from "Pages/Subscription/services/subscriptionServices";
const AccountLimitsController = ({ limits, usages }) => {
    const [show, setShow] = useState(false);
    const [planName, setPlanName] = useState();
    useEffect(() => {
        SubscriptionServices.listSubscriptions().then(response => {
            setPlanName(response.length > 0 ? response[0].name : null);
            setShow(true);
        });
    }, []);
    return (_jsx(AccountLimitReachedModal, { planName: planName, limits: limits, usages: usages, show: show, onClose: () => setShow(false), onUpgrade: () => window.location.href = generateRoute('subscription.subscribe') }));
};
export default AccountLimitsController;
