import { useContext, useEffect } from 'react';
import TaskModalContext from "./TaskModalContext";
import { useFormikContext } from 'formik';
const ActiveFormContextHandler = () => {
    const formik = useFormikContext();
    const context = useContext(TaskModalContext);
    useEffect(() => {
        context.setActiveForm(formik);
    }, [formik]);
    return null;
};
export default ActiveFormContextHandler;
