import { jsx as _jsx } from "react/jsx-runtime";
import { useViewType } from "@f2w/view";
import { FormError } from "@f2w/form";
import { RentalObjectViewFormType } from "Components/ObjectViewModal/forms/RentalObjectViewFormType";
import { translate } from "Services/Translator";
import { RentalObjectTemplate } from '../components/RentalObjectView/RentalObjectTemplate';
export const useRentalObjectView = (loadData, updateRentalObject, waitingListServices, updateRentalObjectNameForBreadcrumb, handoverId) => useViewType(() => new RentalObjectViewFormType(), {
    id: 'rental-object-view',
    DefaultTemplate: props => _jsx(RentalObjectTemplate, { ...props }),
    load: async ({ id }) => {
        const data = await loadData(id);
        return {
            id,
            ...data,
            generalLeft: {
                name: data.name,
                ewid: data.ewid,
                type: data.type,
                floor: data.floor,
                numberOfRooms: data.numberOfRooms,
                area: data.area,
                volume: data.volume,
                isAncillaryExpenseRelevant: data.isAncillaryExpenseRelevant,
                notes: data.notes,
                rentBankAccountId: data.rentBankAccount?.id
            },
        };
    },
    saveError: (error) => {
        if (!('response' in error)) {
            return new Error(error);
        }
        const { response: { data, status } } = error;
        if ([422, 400].includes(status) || data?.message) {
            return new FormError(data.message);
        }
        return new FormError(data);
    },
    save: async () => { },
    defaultState: {
        handoverId: handoverId,
        waitingListServices
    },
}, () => ({
    generalLeft: {
        name: translate('baseData.rentalObject.edit'),
        showHeader: false,
        Form: ({ type }) => type.renderChild('generalLeft'),
        save: async (values, view) => {
            const data = view.type.getChild('generalLeft').getResolvedValue();
            const dataToSave = {
                ...data,
                floor: data.floor
            };
            return updateRentalObject(values.id, dataToSave)
                .then(() => {
                updateRentalObjectNameForBreadcrumb(values.id, data.name);
                view.updateData({
                    name: data.name,
                    type: data.type,
                    ewid: data.ewid,
                    floor: data.floor,
                    numberOfRooms: data.numberOfRooms,
                    area: data.area,
                    volume: data.volume,
                    isAncillaryExpenseRelevant: data.isAncillaryExpenseRelevant,
                    notes: data.notes,
                    generalLeft: { ...data }
                });
            });
        }
    },
    generalRight: {
        showHeader: false
    },
}));
