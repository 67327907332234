import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import PatchNotesModal from "Components/App/PatchNotes/PatchNotesModal";
export const ViewPatchNote = ({ getPatchNote, loadDataReference }) => {
    const [patchNote, setPatchNote] = useState();
    loadDataReference.current = useCallback((id) => {
        getPatchNote(id).then(data => setPatchNote(data));
    }, []);
    if (!patchNote) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(_Fragment, { children: patchNote && _jsx(PatchNotesModal, { patchNote: {
                images: patchNote.images,
                bugFixes: patchNote.bugFixes,
                features: patchNote.features,
                leadText: patchNote.leadText,
                title: patchNote.title,
            }, onHide: () => setPatchNote(null) }) }));
};
