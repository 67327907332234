var dot = require('dot-object');
function escapeRegExp(str) {
    return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}
function replaceVars(str, vars) {
    const varNames = Object.keys(vars)
        .map((varName) => escapeRegExp(varName))
        .join('|');
    const re = new RegExp('{{(' + varNames + ')}}', 'gi');
    return str.replace(re, function (_, templateVar) {
        return vars[templateVar];
    });
}
export function renderTemplate(template, vars) {
    vars.signatureDisplay = vars.signature ? 'block' : 'none';
    vars.registeredMailVisibility = vars.registeredMail ? 'visible' : 'hidden';
    const dotVars = dot.dot(vars);
    let result = replaceVars(template, dotVars);
    result = replaceVars(result, dotVars);
    return result;
}
