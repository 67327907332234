import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { TwoFactorAuthStatusView } from "Pages/TwoFactorAuth/TwoFactorAuthManagement/TwoFactorAuthStatusView";
import TwoFactorAuthActivationView from "Pages/TwoFactorAuth/TwoFactorAuthManagement/TwoFactorAuthActivationView";
import TwoFactorAuthDeactivationView from "Pages/TwoFactorAuth/TwoFactorAuthManagement/TwoFactorAuthDeactivationView";
import { get2FAStatus } from "Services/TwoFactorAuthManager";
import { SpinnerNew } from "Atoms/Spinner";
var TwoFactorView;
(function (TwoFactorView) {
    TwoFactorView["STATUS"] = "status";
    TwoFactorView["ACTIVATE"] = "activate";
    TwoFactorView["DEACTIVATE"] = "deactivate";
})(TwoFactorView || (TwoFactorView = {}));
export const TwoFactorAuthSettingsPage = () => {
    const [currentView, setCurrentView] = useState(TwoFactorView.STATUS);
    const [status, setStatus] = useState(null);
    const switchView = (view) => {
        if (view === TwoFactorView.STATUS) {
            setStatus(null);
        }
        setCurrentView(view);
    };
    useEffect(() => {
        if (currentView !== TwoFactorView.STATUS) {
            return;
        }
        get2FAStatus().then((response) => {
            setStatus(response.data);
        });
    }, [currentView]);
    if (status === null) {
        return _jsx(SpinnerNew, {});
    }
    return (_jsx(InnerPage, { status: status, currentView: currentView, switchView: switchView }));
};
const InnerPage = ({ status, currentView, switchView }) => {
    return (_jsxs(_Fragment, { children: [status !== null && currentView === TwoFactorView.STATUS
                && _jsx(TwoFactorAuthStatusView, { has2FA: status.enabled, activateClicked: () => switchView(TwoFactorView.ACTIVATE), deactivateClicked: () => switchView(TwoFactorView.DEACTIVATE) }), currentView === TwoFactorView.ACTIVATE
                && _jsx(TwoFactorAuthActivationView, { authSecret: status.connect.secret, qrCodeData: status.connect.qrCodeData, onComplete: () => switchView(TwoFactorView.STATUS), onCancel: () => switchView(TwoFactorView.STATUS) }), currentView === TwoFactorView.DEACTIVATE
                && _jsx(TwoFactorAuthDeactivationView, { onComplete: () => switchView(TwoFactorView.STATUS), onCancel: () => switchView(TwoFactorView.STATUS) })] }));
};
