import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalNew } from "Components/Modal";
import { Button } from "Components/Atoms";
import { translate } from "Services/Translator";
import { DataTable } from '@f2w/data-table';
import { CreditorInfoDataTable } from "Pages/Creditor/components/BankFilesTable/CreditorInfoDataTable";
export const CreditorInfoModal = ({ dropCreditorFromBankFile, bankFileId, creditorInvoices, onClose }) => {
    const instance = CreditorInfoDataTable.use({
        bankFileId,
        dropCreditorFromBankFile,
        onLastCreditorDeleted: onClose,
        creditorInvoices
    });
    return (_jsxs(ModalNew, { show: true, onHide: onClose, size: { maxWidth: '1000px', maxHeight: '70vh' }, children: [_jsx(ModalNew.Header, { onHide: onClose, closeButton: true, children: _jsx(ModalNew.Title, { children: translate('creditorInvoices') }) }), _jsx(ModalNew.Body, { children: _jsx(DataTable.Table, { instance: instance, variant: 'content' }) }), _jsx(ModalNew.Footer, { children: _jsx(Button, { onClick: onClose, children: translate('btn.close') }) })] }));
};
