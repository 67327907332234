import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab, Tabs, Title } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { Header, Section } from 'Atoms/Layout/components';
import { AccountServices, CalculationServices, DistributionKeyServices } from 'Pages/AncillaryExpense';
import { CalculationWizardStyled } from '../CalculationWizardStyled';
import { CalculationsTab } from './CalculationsTab';
import { AccountsTab } from './AccountsTab';
import { DistributionKeysTab } from "Pages/AncillaryExpense/components/OverviewPage/DistributionKeysTab";
export const AncillaryExpenseOverviewPage = ({ accountProps, calculationProps, accountServices = AccountServices, calculationServices = CalculationServices, distributionKeyServices = DistributionKeyServices, presetAccountServices }) => {
    return (_jsxs(CalculationWizardStyled, { children: [_jsx(Header, { children: _jsx(Title.H1, { h4: true, children: translate('ancillaryExpense') }) }), _jsx(Section, { children: _jsxs(Tabs, { id: "ancillary-expense-tabs", mountOnEnter: true, defaultActiveKey: "calculations-table", children: [_jsx(Tab, { eventKey: "calculations-table", title: translate('ancillaryExpenses.calculations'), children: _jsx(CalculationsTab, { ...calculationProps, accountServices: accountServices, calculationServices: calculationServices }) }), _jsx(Tab, { eventKey: "accounts-table", title: translate('ancillaryExpenses.accounts'), children: _jsx(AccountsTab, { ...accountProps, accountServices: accountServices, presetAccountServices: presetAccountServices }) }), _jsx(Tab, { eventKey: "distribution-keys-table", title: translate('ancillaryExpenses.distributionKeys'), children: _jsx(DistributionKeysTab, { ...accountProps, accountServices: accountServices, distributionKeyServices: distributionKeyServices }) })] }) })] }));
};
