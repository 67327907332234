import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from "styled-components";
import { useTheme } from "@f2w/theme";
import { translate } from "Services/Translator";
import { SafeAnchor } from "Components/Atoms";
import { Message } from './components/Message';
const Root = styled.div((p) => {
    const { palette, typography } = useTheme();
    return {
        minWidth: '420px',
        fontFamily: typography.fontFamily,
        background: palette.white,
        borderRadius: typography.borderRadius.rem,
        filter: 'drop-shadow(0px 12px 12px rgba(30, 43, 107, 0.12))',
    };
});
const Header = styled.div((p) => {
    const { palette, rem, typography } = useTheme();
    return {
        padding: [rem(10), rem(16)].join(' '),
        background: palette.white,
        borderRadius: typography.borderRadius.rem,
        fontSize: rem(12),
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        minHeight: '72px',
        lineHeight: 1.5,
        justifyContent: 'space-between',
    };
});
const HeaderTitle = styled.span((p) => {
    const { palette, rem, typography } = useTheme();
    return {
        display: 'inline-block',
        textTransform: 'uppercase',
        fontWeight: typography.fontWeight.$600,
        color: palette.dark.$400,
        fontSize: rem(12),
    };
});
const HeaderLinks = styled.div((p) => {
    return {
        display: 'flex',
        width: 'auto',
        marginLeft: 'auto',
    };
});
const HeaderBadge = styled.span((p) => {
    const { palette, rem, typography } = useTheme();
    return {
        display: 'inline-flex',
        marginLeft: rem(10),
        borderRadius: rem(12),
        padding: '2px 6px',
        lineHeight: 1.5,
        alignItems: 'center',
        fontSize: rem(10),
        color: palette.primary.$50,
        background: palette.primary.$500,
        fontWeight: typography.fontWeight.$400,
        textTransform: 'none',
    };
});
const Content = styled.div((p) => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
        flexDirection: 'column',
        overflowY: 'auto',
        minHeight: '60px',
        maxHeight: '400px',
        textAlign: 'center',
    };
});
const Footer = styled.div((p) => {
    const { palette, rem, typography } = useTheme();
    return {
        display: "block",
        fontSize: rem(12),
        paddingTop: '1em',
        paddingBottom: '1em',
        textAlign: "center",
        fontWeight: typography.fontWeight.$400,
        background: palette.primary.$50,
        borderBottomLeftRadius: typography.borderRadius.rem,
        borderBottomRightRadius: typography.borderRadius.rem,
    };
});
const NotificationList = ({ api }) => {
    const headerTitle = React.useMemo(() => {
        return translate('notifications.title');
    }, []);
    return (_jsxs(Root, { children: [_jsxs(Header, { children: [_jsx(HeaderTitle, { children: headerTitle }), api.unreadCount > 0 ? _jsxs(HeaderBadge, { children: [api.unreadCount, " ", translate('notifications.new')] }) : null, _jsx(HeaderLinks, { children: api.markAsRead && api.data.length > 0 && api.unreadCount > 0 && _jsx(SafeAnchor, { onClick: () => {
                                api.markAsRead();
                            }, children: translate('notifications.markAllAsRead') }) })] }), _jsx(Content, { children: api.data.length
                    ? api.data.map(v => (_jsx(Message, { api: api, data: v }, `notification-item-${v.id}`)))
                    : _jsx("div", { children: translate('notifications.emptyList') }) }), _jsx(Footer, {})] }));
};
export default NotificationList;
