import { FormError, useFormModal } from "@f2w/form";
import { translate } from "Services/Translator";
import { CustomerApi } from "Pages/Customer/services/CustomerApi";
import { EditCustomerPublicType } from "Pages/Customer/types/EditCustomerPublicType";
export const useAccountEditForm = () => {
    return useFormModal({
        name: 'account-edit-form',
        type: () => new EditCustomerPublicType(),
        title: translate('account'),
        onSave: async (data) => {
            try {
                return await CustomerApi.update(data);
            }
            catch ({ response }) {
                if (response.status === 400 || response.status === 422) {
                    throw new FormError(translate(response.data?.message || response.data));
                }
                else {
                    throw new FormError(translate('account.form.generalError'));
                }
            }
        },
        onLoad: async () => {
            return CustomerApi.getForUpdate();
        },
    });
};
