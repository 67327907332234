import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
const options = [
    { value: 0, label: '0.' },
    { value: 1, label: '1.' },
    { value: 2, label: '2.' },
    { value: 3, label: '3.' },
];
const ReminderStateSelect = ({ ...props }) => {
    return (_jsx(Select, { options: options, ...props }));
};
export default ReminderStateSelect;
