import client from "Utils/client";
import generateRoute from "Services/Router";
export var NotificationEventApi;
(function (NotificationEventApi) {
    NotificationEventApi.getList = async (lastId) => {
        let response = await client.get(generateRoute('api.notificationEvent.list'), {
            params: lastId !== null ? {
                lastId: lastId,
            } : {}
        });
        return await response.data;
    };
    NotificationEventApi.getSubscription = async () => {
        let response = await client.get(generateRoute('api.notificationEvent.subscription'));
        return await response.data;
    };
})(NotificationEventApi || (NotificationEventApi = {}));
