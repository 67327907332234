import { isNumLike, toNum } from 'Utils/types';
import { translate } from 'Services/Translator';
import { SimpleValue, SimpleValueType, } from './base';
import { Yup } from '../base';
export class NumberValueType extends SimpleValueType {
    __fw_type_name = 'number';
    constructor(props) {
        super(props);
    }
    static define(props) {
        return new NumberValueType(props);
    }
    createValue(props) {
        return new SimpleValue(this._type, props);
    }
    get schema() {
        return super.schema;
    }
    _createSchema() {
        return new Yup.NumberSchema();
    }
    _parse(value) {
        if (value != null) {
            if (isNumLike(value))
                return toNum(value);
        }
        if (this.isNullable)
            return null;
    }
    _typeCheck(value) {
        if (value instanceof Number)
            value = value.valueOf();
        return typeof value === 'number' && !isNaN(value);
    }
    getConstraints(props) {
        const { mutate } = props;
        return {
            ...super.getConstraints(props),
            min(value, message) {
                mutate(s => s.min(value, message ?? translate('form.validation.noLessThan', { length: value })));
                return value;
            },
            max(value, message) {
                mutate(s => s.max(value, message ?? translate('form.validation.noLongerThan', { length: value })));
                return value;
            },
            moreThan(value, message) {
                mutate((s) => s.moreThan(value, message));
                return value;
            },
            lessThan(value, message) {
                mutate((s) => s.lessThan(value, message));
                return value;
            },
            positive(value, message) {
                mutate((s) => s.positive(message));
                return value;
            },
            negative(value, message) {
                mutate((s) => s.negative(message));
                return value;
            },
            integer(value, message) {
                mutate((s) => s.integer(message));
                return value;
            },
        };
    }
}
