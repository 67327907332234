import useStableMemo from '@restart/hooks/useStableMemo';
import { translate } from 'Services/Translator';
import { inObject, Mime, parseBytes } from '@f2w/utils';
import { UploadError, UploadFile } from './UploadFile';
import { uploaderConfigs } from 'Components/Dispatcher/UploadManager/mime-configs';
export * from './UploadFile';
export class Upload {
    dispatcher;
    static MAX_TOTAL_SIZE = parseBytes('8MiB');
    static MAX_FILE_SIZE = parseBytes('8MiB');
    props;
    _files = new Map();
    _loading = new Set;
    queue = [];
    count = 0;
    size = 0;
    loading;
    loaded;
    constructor(props, dispatcher) {
        this.dispatcher = dispatcher;
        this.props = { ...props };
        this.title = this.title.bind(this);
        this.add = this.add.bind(this);
        this.process = this.process.bind(this);
        this.clear = this.clear.bind(this);
        this.remove = this.remove.bind(this);
    }
    static createOptions({ accept, minSize, maxSize, maxTotalSize, maxFiles, multiple }) {
        maxTotalSize = parseBytes(maxTotalSize ?? maxSize) ?? Upload.MAX_TOTAL_SIZE;
        maxSize = parseBytes(maxSize) ?? Upload.MAX_FILE_SIZE;
        minSize = parseBytes(minSize) ?? 0;
        maxTotalSize = Math.min(maxTotalSize, Upload.MAX_TOTAL_SIZE);
        maxSize = Math.min(maxSize, maxTotalSize, Upload.MAX_FILE_SIZE);
        minSize = Math.min(minSize, maxSize);
        return {
            accept: new Mime(accept),
            maxSize,
            minSize,
            maxTotalSize,
            maxFiles,
            multiple
        };
    }
    static createProps({ config, ...rest }) {
        const cfg = uploaderConfigs?.[config];
        return Upload._createProps({
            ...rest,
            ...cfg,
            messages: {
                ...rest.messages,
                ...cfg?.messages,
            }
        });
    }
    static _createProps({ accept, minSize, maxSize, maxTotalSize, maxFiles, multiple, messages, onUpload, progressTitle, }) {
        const options = useStableMemo(() => Upload.createOptions({
            accept, minSize, maxSize, maxTotalSize, maxFiles, multiple,
        }), [accept, minSize, maxSize, maxTotalSize, maxFiles, multiple]);
        return {
            options,
            onUpload,
            progressTitle,
            errors: messages,
        };
    }
    get files() {
        return [...this._files.values()];
    }
    get isCompleted() {
        return !this._loading.size && !this.queue.length;
    }
    get options() {
        return this.props.options;
    }
    title() {
        return this.props.progressTitle?.(this) ?? translate('uploadManager.progress.title', { count: this.files.length });
    }
    clear() {
        this._files.forEach((f, id, map) => {
            f.isCompleted && this._files.delete(id);
        });
        this.dispatcher?.forceUpdate();
    }
    remove(...ids) {
        Upload.resolveIds(ids).forEach(id => this._files.delete(id));
        this.dispatcher?.forceUpdate();
    }
    add(...files) {
        UploadFile.resolveList(files).forEach(file => this.addFile(file));
        this.dispatcher?.forceUpdate();
    }
    addFile(file) {
        file.setId(this.getFileId(file.name));
        this._files.set(file.id, file);
        if (!this.validate(file)) {
            this.size += file.size;
            this.queue.push(file);
            ++this.count;
        }
    }
    async process(props) {
        if (!this.queue.length)
            return;
        const { onUpload } = Object.assign(this.props, props);
        this.loading = true;
        const promises = this.getChunks().map((files) => {
            this._update(files, f => this._loading.add(f.setLoading().id));
            return onUpload(files)
                .then(() => {
                this._update(files, f => this._loading.delete(f.setLoaded().id));
                return files;
            })
                .catch((error) => {
                console.error(error);
                this._update(files, file => this._loading.delete(file.setError(this.createError('server', { file, error })).id));
                return files;
            });
        });
        return Promise.all(promises)
            .then(() => {
            this.loading = false;
            this.loaded = true;
        });
    }
    _update(files, cb) {
        files.forEach(cb);
        this.dispatcher?.forceUpdate();
    }
    getChunks() {
        if (!this.queue.length)
            return [];
        const { maxTotalSize } = this.options;
        let file, size = 0, files = [];
        while ((file = this.queue[0]) && (!maxTotalSize || (size + file.size) < maxTotalSize)) {
            size += file.size;
            files.push(this.queue.shift());
        }
        return [
            files,
            ...this.getChunks(),
        ];
    }
    validate(file) {
        const { accept, maxSize, minSize, maxFiles } = this.options;
        const props = { file };
        if (maxFiles && this.count > maxFiles)
            return this.createError('maxFiles', props);
        if (!accept.accept(file))
            return this.createError('fileType', props);
        if (maxSize && file.size > maxSize)
            return this.createError('fileMaxSize', props);
        if (minSize && file.size < minSize)
            return this.createError('fileMinSize', props);
    }
    getFileId(name, count = 0) {
        const id = count ? `${name}_${count}` : name;
        return this._files.has(id) ? this.getFileId(id, ++count) : id;
    }
    createError(type, props) {
        return props.file.setError(type, (this.props.errors[type] ?? UploadError.types[type])?.(this.options, props) ?? undefined).error;
    }
}
(function (Upload) {
    Upload.getId = (value) => inObject(value, 'id') ? value.id : value;
    Upload.resolveIds = (...values) => values.flat(4).map(Upload.getId);
})(Upload || (Upload = {}));
