import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { AbstractFormType, CollectionType, DateType, FormTheme, NumberType, SelectType, } from '@f2w/form';
import { translate } from 'Services/Translator';
import { Div, FlexCol } from 'Components/Atoms';
import { BookingType, CreditorState } from 'Pages/Creditor/enums';
import React from 'react';
import { formatMoney } from 'Services/NumberHelper';
import generateRoute from 'Services/Router';
import client from 'Utils/client';
import { CreditorServices } from 'Pages/Creditor/services';
import { useFinancialAccountsHook } from 'Hooks/useFinancialAccountsHook';
import { orFunction } from '@f2w/utils';
export class ApprovalType extends AbstractFormType {
    bankAccounts = [];
    financialAccounts = [];
    groupedFinancialAccounts = [];
    _useField(props) {
        super._useField(props);
        const [bankAccounts, setBankAccounts] = React.useState([]);
        const { groupedFinancialAccountOptions, financialAccounts } = useFinancialAccountsHook({
            propertyId: this.field.value.propertyId
        });
        this.bankAccounts = bankAccounts;
        this.financialAccounts = financialAccounts;
        this.groupedFinancialAccounts = groupedFinancialAccountOptions;
        React.useEffect(() => {
            if (!this.field.value?.id) {
                return;
            }
            CreditorServices.getDropdownDataForApproval(this.field.value?.id)
                .then(({ bankAccounts: bankAccountResults, financialAccounts }) => {
                const bankAccountGroups = new Map();
                let alreadySetBank = false;
                const bankPositionsFound = new Set();
                for (const bankAccount of bankAccountResults) {
                    const property = bankAccount.primaryProperty;
                    bankPositionsFound.add(property.position);
                    if (bankAccountGroups.has(property.id)) {
                        const foundGroup = bankAccountGroups.get(property.id);
                        bankAccountGroups.set(property.id, {
                            ...foundGroup,
                            options: [
                                ...foundGroup.options,
                                {
                                    label: bankAccount.name,
                                    value: bankAccount.id,
                                }
                            ]
                        });
                    }
                    else {
                        bankAccountGroups.set(property.id, {
                            label: property.name,
                            options: [
                                {
                                    label: bankAccount.name,
                                    value: bankAccount.id,
                                }
                            ]
                        });
                    }
                    if (!this.field.value.payerBankAccountId && !alreadySetBank && this.options?.creditorState?.() !== CreditorState.READY_FOR_PAYMENT) {
                        this.children.payerBankAccountId.field.setValue(bankAccount.id, false);
                        alreadySetBank = true;
                    }
                }
                if (!this.field.value.payerBankAccountId && !alreadySetBank && this.options?.creditorState?.() === CreditorState.READY_FOR_PAYMENT) {
                    this.children.payerBankAccountId.field.setValue(-1, false);
                }
                const bankAccounts = Array.from(bankAccountGroups.values());
                const missingBankPositions = Object.keys(financialAccounts)
                    .filter(pos => !bankPositionsFound.has(Number(pos)));
                bankAccounts.push({
                    label: translate('bankAccounts.group.other'),
                    options: [
                        ...missingBankPositions.map(pos => ({
                            label: translate('creditor.financialAccount.notLinkedToBankAccount', {
                                financialAccountName: financialAccounts[pos]
                            }),
                            value: -Number(pos),
                            isDisabled: true
                        })),
                        {
                            label: translate('creditor.payerBankAccount.manualPaymentOption'),
                            value: -1
                        }
                    ]
                });
                setBankAccounts(bankAccounts);
            });
        }, [this.field.value?.id]);
    }
    _renderWidget(iProps) {
        return (_jsxs(Div, { children: [_jsxs(FlexCol, { mb: 10, gap: 5, children: [_jsxs(Div, { children: [_jsxs("strong", { children: [translate('creditor.label'), ":"] }), " ", this.field.value.label ?? translate('notAvailable')] }), _jsxs(Div, { children: [_jsxs("strong", { children: [translate('creditor.comment'), ":"] }), " ", this.field.value.comment ?? translate('notAvailable')] }), _jsxs(Div, { children: [_jsxs("strong", { children: [translate('creditor.invoiceRelevancy'), ":"] }), " ", this.field.value.invoiceRelevancy ?? translate('notAvailable')] })] }), super._renderWidget(iProps)] }));
    }
    buildFields() {
        const isDisabledForAncillary = () => {
            return (this.value?.isFromAncillary === true);
        };
        return {
            debitAccountId: new SelectType({
                label: translate('debitAccount'),
                help: () => translate('creditor.debitAccount.helpText'),
                choiceOptions: () => this.groupedFinancialAccounts,
                getProps: () => ({ disabled: isDisabledForAncillary() }),
                isVisible: () => this.field.value?.bookingType === BookingType.COMBINED,
                searchable: true,
                required: true
            }),
            debitAccountPositions: new CollectionType({
                label: false,
                getProps: () => ({ disabled: isDisabledForAncillary() }),
                prototype: () => new ApprovalType.Position({
                    financialAccounts: () => this.financialAccounts,
                    propertyIds: () => this.field.value.positionPropertyIds,
                    disabled: isDisabledForAncillary()
                }),
                actions: false,
                isVisible: () => this.field.value?.bookingType === BookingType.SEPARATED
            }),
            paymentDate: new DateType({
                label: translate('creditor.paymentPer'),
                required: true
            }),
            payerBankAccountId: new SelectType({
                label: translate('invoice.payerBankAccount'),
                choiceOptions: () => this.bankAccounts,
                searchable: true,
                required: true
            })
        };
    }
}
(function (ApprovalType) {
    async function updateData(id, data) {
        const route = generateRoute('api.creditorInvoices.approvalStep', {
            creditorInvoice: id
        });
        await client.put(route, data);
    }
    ApprovalType.updateData = updateData;
    class Position extends AbstractFormType {
        groupedFinancialAccounts = [];
        _useField(props) {
            super._useField(props);
            const financialAccounts = this.options?.financialAccounts?.();
            const { groupedFinancialAccountOptions } = useFinancialAccountsHook({
                propertyId: this.options?.propertyIds?.()[this.field.value.id],
                loadAccountsInitially: false,
                initialFinancialAccounts: financialAccounts,
            });
            this.groupedFinancialAccounts = groupedFinancialAccountOptions;
        }
        __getDefaultOptions() {
            return {
                ...super.__getDefaultOptions(),
                label: true,
            };
        }
        _renderLabel(iProps) {
            const { Root, Text, Title } = FormTheme.Label;
            return _jsx(Root, { children: _jsxs(Title, { style: { fontWeight: 'bold', fontSize: '20px' }, children: [this.field.value.label, " - ", formatMoney(this.field.value.amount)] }) });
        }
        buildFields() {
            return {
                id: new NumberType({
                    isVisible: () => false,
                    required: false,
                }),
                debitAccountId: new SelectType({
                    label: translate('debitAccount'),
                    choiceOptions: () => this.groupedFinancialAccounts,
                    help: () => translate('creditor.debitAccount.helpText'),
                    disabled: orFunction(this.options.disabled),
                    required: true,
                    searchable: true
                })
            };
        }
    }
    ApprovalType.Position = Position;
})(ApprovalType || (ApprovalType = {}));
