import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, AddressType, BooleanType, DateType, EmailType, PhoneType, SelectType, StringType, TextType, UrlType } from "@f2w/form";
import { translate, translateAdmin } from "Services/Translator";
import { CustomerType, SupportPartner } from "Pages/Administration/Customers/enums";
import { useEffect } from "react";
import { Div, FlexCol } from "Atoms/Layout";
import { Text } from "Atoms/Text";
import useForceUpdate from "@restart/hooks/useForceUpdate";
export class ContactType extends AbstractFormType {
    _renderWidget(iProps) {
        if (this.options.wrapWithBorder !== true) {
            return super._renderWidget(iProps);
        }
        return (_jsx(Div, { p: 32, mb: 21, style: { border: '1px solid #D7D8DB', borderRadius: '8px' }, children: _jsxs(FlexCol, { gap: 24, children: [_jsx(Text, { h6: 500, neutral: 900, children: this.options.title }), super._renderWidget(iProps)] }) }));
    }
    buildFields() {
        return {
            sameAsCustomerContact: new BooleanType({
                label: translate('customer.contact.sameAsCustomerContact'),
                required: false,
                isVisible: () => this.options?.isBillingContact
            }),
            isLegalEntity: new BooleanType({
                label: translate('customer.contact.company'),
                defaultValue: false,
                required: false,
                isVisible: () => !this.field.value.sameAsCustomerContact
            }),
            companyName: new StringType({
                label: translate('customer.contact.companyName'),
                isVisible: () => !!(this.field.value.isLegalEntity && !this.field.value.sameAsCustomerContact)
            }),
            companyName2: new StringType({
                label: translate('customer.contact.companyName2'),
                isVisible: () => !!(this.field.value.isLegalEntity && !this.field.value.sameAsCustomerContact),
                required: false,
                defaultValue: '',
            }),
            firstName: new StringType({
                label: translate('customer.contact.firstName'),
                isVisible: () => !this.field.value.isLegalEntity && !this.field.value.sameAsCustomerContact
            }),
            lastName: new StringType({
                label: translate('customer.contact.lastName'),
                isVisible: () => !this.field.value.isLegalEntity && !this.field.value.sameAsCustomerContact
            }),
            email: new EmailType({
                label: translate('customer.contact.email', {}),
                isVisible: () => !this.field.value.sameAsCustomerContact
            }),
            phoneNumber: new PhoneType({
                label: translate('customer.contact.phoneNumber'),
                isVisible: () => !this.field.value.sameAsCustomerContact
            }),
            website: new UrlType({
                label: translate('customer.contact.website'),
                required: false,
                isVisible: () => !this.field.value.sameAsCustomerContact
            }),
            address: new AddressType({
                label: false,
                houseNumber: {
                    required: true
                },
                inheritData: true,
                isVisible: () => !this.field.value.sameAsCustomerContact
            })
        };
    }
}
export class EditCustomerType extends AbstractFormType {
    _useField(props) {
        super._useField(props);
        const dispatchUpdate = useForceUpdate();
        useEffect(() => {
            this.field.value.billingContact.sameAsCustomerContact = this.field.value.billingContactSameAsCustomer;
            dispatchUpdate();
        }, []);
        useEffect(() => {
            this.field.value.billingContactSameAsCustomer = this.field.value.billingContact.sameAsCustomerContact;
        }, [this.field.value.billingContact.sameAsCustomerContact]);
    }
    buildFields() {
        return {
            name: new StringType({
                label: translateAdmin('customer.name')
            }),
            type: new SelectType({
                label: translateAdmin('customer.type'),
                choices: {
                    [CustomerType.DEMO]: translateAdmin('customer.type.demo'),
                    [CustomerType.TRIAL]: translateAdmin('customer.type.trial'),
                    [CustomerType.TEST]: translateAdmin('customer.type.test'),
                    [CustomerType.PAID]: translateAdmin('customer.type.paid'),
                    [CustomerType.PENDING]: translateAdmin('customer.type.pending'),
                }
            }),
            terminatedAt: new DateType({
                label: translateAdmin('customer.terminatedAt'),
                required: false
            }),
            supportPartner: new SelectType({
                label: translateAdmin('customer.supportPartner'),
                choices: {
                    [SupportPartner.FAIRWALTER]: 'Fairwalter AG',
                    [SupportPartner.EXTENSO]: 'eXtenso IT-Services AG',
                }
            }),
            notes: new TextType({
                label: translateAdmin('customer.remarks'),
                required: false
            }),
            contact: new ContactType({
                label: false,
                title: translateAdmin('customer.contactAddress'),
                wrapWithBorder: true,
                isBillingContact: false
            }),
            billingContact: new ContactType({
                label: false,
                title: translateAdmin('customer.invoiceAddress'),
                wrapWithBorder: true,
                isBillingContact: true
            })
        };
    }
}
