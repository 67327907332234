import React, { useEffect, useMemo } from "react";
import { CalculationServices } from "Pages/AncillaryExpense";
import { translate } from "Services/Translator";
export const useDistributionSection = ({ calculationId, calculationServices = CalculationServices, selectedRentalObjectType }) => {
    const [_activeAccountId, _setActiveAccountId] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [distributionMap, _setDistributionMap] = React.useState(new Map());
    const [distributionMapFiltered, _setDistributionMapFiltered] = React.useState(new Map());
    const accountDistributions = distributionMapFiltered.get(_activeAccountId);
    const setAccount = React.useCallback((accountId) => {
        if (distributionMap.has(accountId)) {
            _setActiveAccountId(accountId);
        }
    }, [distributionMap]);
    const updateDistribution = React.useCallback((accountId, data) => {
        if (distributionMap.has(accountId)) {
            distributionMap.set(accountId, {
                ...(distributionMap.get(accountId)),
                [data.type]: convertItem(data)
            });
        }
    }, [distributionMap]);
    const updateDistributionKey = React.useCallback(async (distributionKeyId, type) => {
        if (_activeAccountId) {
            return calculationServices
                .updateDistributionKey(calculationId, {
                accountId: _activeAccountId,
                propertyDistributionKeyId: distributionKeyId,
                type: type
            })
                .then(() => {
                loadData();
            });
        }
    }, [_activeAccountId, calculationId]);
    const updateHeatingDaysShare = React.useCallback(async (heatingDaysShare, type) => {
        if (_activeAccountId) {
            return calculationServices
                .updateHeatingDaysShare(calculationId, heatingDaysShare, _activeAccountId, type)
                .then(() => {
                loadData();
            });
        }
    }, [_activeAccountId, calculationId]);
    const loadData = React.useCallback(async (initialLoad = false) => {
        setIsLoading(true);
        return calculationServices.listDistributions(calculationId)
            .then((results) => {
            const distributionMap = new Map();
            results?.forEach(result => {
                if (distributionMap.has(result.account.id)) {
                    distributionMap.set(result.account.id, {
                        ...(distributionMap.get(result.account.id)),
                        [result.type]: convertItem(result)
                    });
                }
                else {
                    distributionMap.set(result.account.id, {
                        accountId: result.account.id,
                        accountName: result.account.name,
                        [result.type]: convertItem(result)
                    });
                }
            });
            if (initialLoad) {
                _setActiveAccountId(distributionMap.keys().next().value);
            }
            _setDistributionMapFiltered(distributionMap);
            _setDistributionMap(distributionMap);
        })
            .finally(() => {
            setIsLoading(false);
        });
    }, [calculationId]);
    const rentalObjectTypeOptions = useMemo(() => {
        const availableRentalObjectTypes = [
            ...(new Set(Array.from(distributionMap.entries())
                .map(([_key, value]) => {
                const accountTypes = {
                    allocation_formula: value.allocation_formula,
                    fixed_cost: value.fixed_cost,
                    consumption: value.consumption,
                    common_area: value.common_area
                };
                return Object
                    .values(accountTypes)
                    .filter(type => type !== undefined)
                    .map(type => type.positions.map(pos => pos.rentalObjectType))
                    .flat();
            })
                .flat()
                .filter(type => type !== null && type !== undefined)))
        ];
        return availableRentalObjectTypes.map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }));
    }, [distributionMap]);
    useEffect(() => {
        if (!selectedRentalObjectType) {
            _setDistributionMapFiltered(distributionMap);
            return;
        }
        let distributionAccounts = new Map();
        distributionMap.forEach((distributionAccount, accountId) => {
            const accountTypes = {
                allocation_formula: distributionAccount.allocation_formula,
                fixed_cost: distributionAccount.fixed_cost,
                consumption: distributionAccount.consumption,
                common_area: distributionAccount.common_area
            };
            Object.keys(accountTypes).forEach((accountTypeKey) => {
                const accountType = accountTypes[accountTypeKey];
                if (!accountType) {
                    return;
                }
                const positions = accountType.positions
                    .filter((position) => position.rentalObjectType === selectedRentalObjectType);
                let item = distributionAccounts.has(accountId) ? distributionAccounts.get(accountId) : {
                    accountId: distributionAccount.accountId,
                    accountName: distributionAccount.accountName
                };
                item[accountTypeKey] = { ...distributionAccount[accountTypeKey], positions: [...positions] };
                distributionAccounts.set(accountId, item);
            });
        });
        _setDistributionMapFiltered(distributionAccounts);
    }, [selectedRentalObjectType, distributionMap]);
    useEffect(() => {
        void loadData(true);
    }, []);
    return {
        refreshData: loadData,
        distributionMap: distributionMap,
        activeAccountId: _activeAccountId,
        setAccount,
        accountDistributions,
        updateDistributionKey,
        updateHeatingDaysShare,
        setIsLoading,
        isLoading,
        rentalObjectTypeOptions
    };
};
const convertItem = ({ account, type, ...props }) => {
    return { ...props };
};
