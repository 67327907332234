import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import BadgeToken, { badgeSizeToken, badgeSlot } from './Badge.tokens';
import styled from 'styled-components';
import { ariaAttr, toPx } from '@fw/theme';
import { Icon } from 'Atoms/Icon';
import { BaseButton } from 'Atoms/Button/BaseButton';
import { isNumOrStr } from '@f2w/utils';
import { textContent } from '../../Base';
export const Badge = forwardRef(({ className, style, $size, $color, maxWidth, label: content, children, onClick, onRemove, onRemoveLabel, isBusy, ...props }, ref) => {
    const rootProps = {
        ...badgeSlot.attr('root'),
        ref,
        className,
        style,
        $size,
        $color,
        maxWidth,
    };
    if (isNumOrStr(children)) {
        content = children;
        children = null;
    }
    const contentProps = {
        ...badgeSlot.attr('content'),
        role: onClick ? 'button' : undefined,
        onClick,
        title: content ?? textContent(children),
        ...props,
    };
    return _jsxs(BadgeTheme.Root, { ...rootProps, "aria-busy": ariaAttr(isBusy), children: [_jsxs(BadgeTheme.Content, { ...contentProps, children: [content && _jsx("span", { children: content }), children] }), isBusy && (_jsx("span", { children: _jsx(Icon, { size: '1.2em', icon: 'spinner' }) })), onRemove && (_jsx(BaseButton, { ...badgeSlot.attr('remove'), as: 'div', nested: true, onClick: (e) => {
                    onRemove();
                }, children: onRemoveLabel ?? _jsx(Icon, { icon: 'x' }) }))] });
});
var BadgeTheme;
(function (BadgeTheme) {
    BadgeTheme.Content = styled.div ``;
    const Base = styled.div `
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
        display: inline-flex;
        flex-direction: row;
        align-items: stretch;
        flex-wrap: nowrap;
        border: 1px solid transparent;

        padding: 0;
        margin: 0;

        min-width: 0;
        max-width: var(--badge-max-width, 500px);
        min-height: var(--badge-height);
        border-radius: calc(var(--badge-height) * .5);

        > * {
            align-content: center;
            padding: var(--badge-padding-y) var(--badge-padding-x);
        }

        ${badgeSlot.css('content')} {
            text-overflow: ellipsis;
            overflow: hidden;
            flex-shrink: 1;
            white-space: nowrap;

            &[role=button] {
                cursor: pointer;
                user-select: none;
            }

            &:not(:last-child) {
                padding-right: 0;
            }
        }

        ${badgeSlot.css('remove')} {
            align-self: stretch;
            &:hover {
                background-color: rgb(from currentColor r g b / .3);
            }
        }
    `;
    BadgeTheme.Root = styled(Base)(({ $size = 'sm', $color = 'primary', maxWidth, theme, }) => {
        const { lineHeight, borderRadius, paddingX, paddingY, height, ...fontStyles } = badgeSizeToken.getStyles($size, { theme });
        const { base, hover, focus, disabled } = BadgeToken.useColorProps($color);
        const colorcss = {
            ...base,
            '&:hover': hover || base,
            '&:focus, &.focus': focus || hover || base,
            '&[aria-disabled], &:disabled, &.disabled': {
                pointerEvents: 'none',
                opacity: .8,
                ...(disabled || base)
            },
        };
        return {
            '--badge-padding-x': toPx(paddingX),
            '--badge-padding-y': toPx(paddingY),
            '--badge-height': toPx(height),
            '--badge-max-width': maxWidth ? toPx(maxWidth) : '500px',
            ...fontStyles,
            ...colorcss,
        };
    });
})(BadgeTheme || (BadgeTheme = {}));
