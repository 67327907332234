import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from "Services/Translator";
import { Button, SpinnerNew, Title } from "Components/Atoms";
import { CalculationWizardStyled } from "../CalculationWizardStyled";
import { CalculationView } from "./CalculationView";
import { CalculationServices, DistributionKeyServices, useCalculationWizardBasicsForm } from "../../index";
import generateRoute from "Services/Router";
import { Header, Section } from 'Atoms/Layout/components';
import { usePortfolio } from "Components/Base/PortfolioContext";
import { getScopePropertyId } from "Services/ScopeManager";
import { useToastDispatcherApi } from "Components/Dispatcher";
export const AncillaryExpenseCalculationPage = ({ calculationId: initialCalculationId, calculationServices = CalculationServices, distributionKeyServices = DistributionKeyServices, }) => {
    const portfolio = usePortfolio();
    const [calculationId, setCalculationId] = React.useState(initialCalculationId);
    const [basicDetails, setBasicDetails] = React.useState();
    const hasBasicDetails = !!basicDetails;
    const toastApi = useToastDispatcherApi();
    React.useEffect(() => {
        if (!initialCalculationId) {
            return;
        }
        loadCalculation(initialCalculationId);
    }, []);
    const loadCalculation = async (calculationId) => {
        const basicDetails = await calculationServices.getCalculation(calculationId);
        setCalculationId(calculationId);
        setBasicDetails(basicDetails);
    };
    const ancillaryExpenseAccountForm = useCalculationWizardBasicsForm({
        propertyId: getScopePropertyId(portfolio),
        getPropertyDetails: calculationServices.getCalculationPropertyDetails,
        onSubmit: async (basicDetails) => {
            if (!basicDetails.periodFrom || !basicDetails.periodTo) {
                return;
            }
            try {
                const { id: calculationId } = await calculationServices.createCalculationDraft(basicDetails);
                setCalculationId(calculationId);
                setBasicDetails(basicDetails);
                const redirectUrl = generateRoute('ancillaryExpenses.calculation.wizard', { calculation: calculationId });
                history.pushState({}, "", redirectUrl);
            }
            catch ({ response }) {
                let errorMessage;
                if (response.status === 400 || response.status === 422) {
                    errorMessage = translate(response.data?.message || response.data);
                }
                else {
                    errorMessage = translate('generalError.default');
                }
                toastApi.error({
                    timeout: 10,
                    message: errorMessage,
                });
            }
        },
    });
    if (calculationId && !hasBasicDetails) {
        return _jsx(SpinnerNew, {});
    }
    return (_jsx(CalculationWizardStyled, { children: hasBasicDetails ? (_jsx(CalculationView, { calculationId: calculationId, overviewData: basicDetails, calculationServices: calculationServices, distributionKeyServices: distributionKeyServices })) : (_jsxs(CalculationWizardStyled, { children: [_jsx(Header, { children: _jsx(Title.H1, { h4: true, children: translate('ancillaryExpenseCalculation.add') }) }), _jsxs(Section, { children: [ancillaryExpenseAccountForm.render(), _jsx("div", { style: { textAlign: "right" }, children: _jsx(Button, { icon: 'arrow-right', label: translate('ancillaryExpenseCalculation.continue'), onClick: () => ancillaryExpenseAccountForm.submitForm() }) })] })] })) }));
};
