import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { LinkAssetToCreditorType } from "Pages/Asset/forms/LinkAssetToCreditorType";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
export const useLinkAssetToCreditorForm = ({ linkAssetToCreditor, onSubmit }) => useFormModal({
    type: () => new LinkAssetToCreditorType(),
    onLoad: async (id) => ({ id }),
    title: () => translate('asset.linkWithCreditor'),
    name: 'link-asset-to-creditor',
    onSave: (values) => {
        return linkAssetToCreditor(values.id, values.creditorInvoiceId)
            .then(onSubmit);
    },
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('asset.linkWithCreditor') })] });
    },
});
