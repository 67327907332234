import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from 'Atoms/Layout/components';
import { FlexCol, FlexRow } from 'Components/Atoms';
import { HandoverPersonRole } from 'Pages/Handover/enums/HandoverPersonRole';
import TenantBlock from 'Pages/Handover/components/HandoverView/component/TenantBlock';
import PersonsTable from 'Pages/Handover/components/HandoverView/component/PersonsTable';
import { ProtocolPersonDirection } from 'Pages/Handover/enums/ProtocolPersonDirection';
import { translate } from 'Services/Translator';
import { Card } from 'Atoms/Card';
export const MoveInTab = ({ group: { data: handoverProtocol } }) => {
    const tenant = handoverProtocol.persons.find(person => person.direction === ProtocolPersonDirection.IN && person.role === HandoverPersonRole.TENANT);
    const movingInPersons = handoverProtocol.persons.filter(person => person.direction === ProtocolPersonDirection.IN && person.role !== HandoverPersonRole.TENANT);
    return (_jsx(Section, { children: _jsxs(FlexCol, { gap: 24, children: [_jsx(FlexRow, { children: _jsx(TenantBlock, { tenant: tenant, blockHeader: translate('handovers.detailView.tenantMovingIn'), bankAccountHeader: translate('handovers.detailView.bankAccount') }) }), movingInPersons.length > 0 && (_jsx(Card, { children: _jsx(Card.Content, { title: translate('handovers.detailView.additionalPersons'), children: _jsx(PersonsTable, { persons: movingInPersons }) }) }))] }) }));
};
