import { jsx as _jsx } from "react/jsx-runtime";
import { Select as DropdownSelect } from 'Atoms/Select';
import { translate } from "Services/Translator";
var RentState;
(function (RentState) {
    RentState["UPCOMING"] = "upcoming";
    RentState["ACTIVE"] = "active";
    RentState["INACTIVE"] = "inactive";
})(RentState || (RentState = {}));
export function RentStateFilter({ column: { filterValue, setFilter }, }) {
    const options = [
        { value: '', label: translate('rent.state.all') },
        { value: RentState.UPCOMING, label: translate(`rent.state.${RentState.UPCOMING}`) },
        { value: RentState.ACTIVE, label: translate(`rent.state.${RentState.ACTIVE}`) },
        { value: RentState.INACTIVE, label: translate(`rent.state.${RentState.INACTIVE}`) },
    ];
    return (_jsx(DropdownSelect, { value: filterValue || '', isSearchable: true, useMaxContentWidth: true, options: options, onChange: ({ value }) => {
            setFilter(value || undefined);
        } }));
}
