import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { useColProps } from 'Atoms/Layout/hooks';
import styled from 'styled-components';
export const Col = forwardRef(({ as: Component = 'div', ...rest }, ref) => {
    const props = useColProps(rest);
    return _jsx(Component, { ...props, ref: ref });
});
export const Col2 = styled.div.attrs((props) => {
    return useColProps(props);
}) ``;
