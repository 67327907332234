import { useCallback, useEffect, useState } from 'react';
import { UseOption } from './types';
export function useSelectValueManager({ options, defaultValue, value, props: _defaultProps, isMulti = _defaultProps?.isMulti, ...propss }) {
    const { onChange: _onChange, onRemove: _onRemove, } = propss;
    const [selectedOptions, _setSelectedOptions] = useState([]);
    const _set = (val) => {
        const opts = UseOption.findOptions(val, options).slice(0, isMulti ? undefined : 1);
        _setSelectedOptions(opts);
        _onChange?.(opts);
    };
    const _update = (val) => {
        _setSelectedOptions(prev => {
            const opts = UseOption.findOptions([prev, val].flat(3), options)
                .slice(0, isMulti ? undefined : 1);
            _onChange?.(opts);
            return opts;
        });
    };
    const _remove = (val) => {
        const values = UseOption.getValueMap(val);
        _setSelectedOptions(prev => {
            const removedOpts = [];
            const opts = prev.filter(o => {
                if (!values[o.value])
                    return true;
                removedOpts.push(o);
            });
            _onRemove?.(removedOpts);
            _onChange?.(opts);
            return opts;
        });
    };
    const _clear = () => {
        const removedOpts = selectedOptions;
        _setSelectedOptions([]);
        _onRemove?.(removedOpts);
        _onChange?.([]);
    };
    const setValue = useCallback(_set, [_onChange, _setSelectedOptions, options]);
    const removeValue = useCallback(_remove, [_onChange, _onRemove, _setSelectedOptions]);
    const clearValue = useCallback(_clear, [_onChange, _onRemove, _setSelectedOptions, selectedOptions]);
    const getValues = useCallback(_clear, [_onChange, _onRemove, _setSelectedOptions, selectedOptions]);
    useEffect(() => {
        defaultValue && setValue(defaultValue);
    }, []);
    useEffect(() => {
        if (value) {
            const opts = UseOption.findOptions(value, options);
            _setSelectedOptions(opts);
        }
    }, [value, options]);
    return {
        options,
        selectedOptions,
        setValue: setValue,
        removeValue: removeValue,
        clearValue: clearValue,
        isMulti,
        props: {
            ..._defaultProps,
            isMulti
        }
    };
}
