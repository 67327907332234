import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import { DamageStatus } from "Types/DamageStatus";
import { translate } from "Services/Translator";
const options = [
    { value: DamageStatus.New, label: translate("damageStatus." + DamageStatus.New) },
    { value: DamageStatus.OfferRequested, label: translate("damageStatus." + DamageStatus.OfferRequested) },
    { value: DamageStatus.OrderPlaced, label: translate("damageStatus." + DamageStatus.OrderPlaced) },
    { value: DamageStatus.InvoicePending, label: translate("damageStatus." + DamageStatus.InvoicePending) },
    { value: DamageStatus.CorrectionNeeded, label: translate("damageStatus." + DamageStatus.CorrectionNeeded) },
    { value: DamageStatus.Finished, label: translate("damageStatus." + DamageStatus.Finished) },
];
const DamageStatusSelect = ({ ...props }) => {
    return (_jsx(Select, { options: options, ...props }));
};
export default DamageStatusSelect;
