import { AbstractObjectType } from '../core';
export class ObjectType extends AbstractObjectType {
    constructor({ fields, ...options }, parent) {
        super(options, parent);
        this._initFields(fields);
    }
    static create(fields) {
        return new this({ fields });
    }
}
