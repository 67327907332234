import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { LineChart } from 'Components/Chart';
import styled from 'styled-components';
import { translate } from 'Services/Translator';
import { formatMoney } from 'Services/NumberHelper';
const config = {
    payload: [
        {
            key: 'objects',
            name: translate('dashboard.sections.vacancyQuota.data.nbObjects'),
            color: '#4766FF',
            dataKey: 'objects.percent'
        },
        {
            key: 'area',
            name: translate('dashboard.sections.vacancyQuota.data.vacantArea'),
            color: '#8756E8',
            unit: 'm2',
            dataKey: 'area.percent'
        },
        {
            key: 'rent',
            name: translate('dashboard.sections.vacancyQuota.data.netRent'),
            color: '#FCA524',
            unit: 'CHF',
            dataKey: 'rent.percent'
        },
    ]
};
const prepareValue = (value, total, formatter) => {
    const props = {
        percent: total ? value / total : 0,
        value: Math.round(value),
    };
    if (formatter) {
        props['formattedValue'] = formatter(props.value);
    }
    return props;
};
const convertRentPeriodAmountsToChartData = (item) => {
    const vacantRentalObjects = item.nbRentalObjects - Math.ceil(item.nbRentedRentalObjects);
    const vacantRentedArea = item.vacantRentedArea;
    const lostNetAmount = item.possibleNetAmount - item.actualNetAmount;
    const date = moment(item.period);
    return ({
        date: date.format('MMM YYYY'),
        objects: prepareValue(vacantRentalObjects, item.nbRentalObjects),
        area: prepareValue(vacantRentedArea, item.possibleRentedArea, val => _jsxs("span", { children: [val, " m", _jsx("sup", { children: "2" })] })),
        rent: prepareValue(lostNetAmount, item.possibleNetAmount, val => formatMoney(val, "$0,0")),
    });
};
const VacancyQuotaLineChart = ({ date, rentPeriodAmounts, currentRentPeriodAmounts }) => {
    const data = rentPeriodAmounts.map((item) => convertRentPeriodAmountsToChartData(item));
    const currentData = convertRentPeriodAmountsToChartData(currentRentPeriodAmounts);
    return (_jsx(LineChart, { data: data, config: config, legendData: currentData }));
};
export default VacancyQuotaLineChart;
const TooltipContentRoot = styled.div `
  background: white;
  padding: 18px 24px;
  box-shadow: 0 12px 12px rgba(26, 34, 51, 0.15);
  border: 1px solid ${p => p.theme.palette.dark.$100};
  border-radius: 8px;
  min-width: 230px;
  table {
    width: 100%;
      td {
        padding: 6px 0;
        ${p => p.theme.typography.pretitle.css()};
        vertical-align: middle;
      }
  }
`;
const TooltipContentItem = styled.div `
  color: ${p => p.theme.palette.dark.$500};
  ${p => p.theme.typography[p.size || 'pretitle'].css()};
  padding: 6px 0;
`;
