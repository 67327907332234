import React from 'react';
import { useEventCallback } from '@restart/hooks';
import * as copy from 'copy-to-clipboard';
export const CopyToClipboard = (props) => {
    const { value, onCopy, options, children, ...rest } = props;
    const elem = React.Children.only(children);
    const onClick = useEventCallback((event) => {
        const { value, onCopy, children, options } = props;
        const result = copy(value, options);
        if (onCopy) {
            onCopy(value, result);
        }
        if (elem && elem.props && typeof elem.props.onClick === 'function') {
            elem.props.onClick(event);
        }
    });
    return React.cloneElement(elem, { ...rest, onClick: onClick });
};
