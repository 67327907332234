import generateRoute from "Services/Router";
import client from "Utils/client";
import { BaseDataServices } from "Pages/BaseData/services";
export const getDistributionKeysForProperty = (propertyId) => {
    const route = generateRoute('api.ancillaryExpense.propertyDistributionKey.dropdownList', { propertyId });
    return client
        .get(route)
        .then(response => response.data)
        .then(data => {
        return data.results.map(({ id, title }) => ({ id, title }));
    });
};
export const getDistributionKey = (id) => {
    const route = generateRoute('api.ancillaryExpense.propertyDistributionKey.get', { id });
    return client
        .get(route)
        .then(response => response.data);
};
export const getDistributionKeyPropertyInfo = (propertyId) => {
    return BaseDataServices.getBaseDataRentalObjectsList({
        pageSize: 1000,
        pageIndex: 1,
        _for: propertyId ? `property-${propertyId}` : undefined
    });
};
export const listDistributionKeys = (params) => {
    const route = generateRoute('api.ancillaryExpense.propertyDistributionKey.list');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export const deleteDistributionKey = (id) => {
    const route = generateRoute('api.ancillaryExpense.propertyDistributionKey.delete', { id });
    return client.delete(route);
};
export const createDistributionKey = (data) => {
    const route = generateRoute('api.ancillaryExpense.propertyDistributionKey.create');
    return client.post(route, data);
};
export const updateDistributionKey = (id, data) => {
    const route = generateRoute('api.ancillaryExpense.propertyDistributionKey.update', { id });
    return client.put(route, data);
};
