export var CustomerType;
(function (CustomerType) {
    CustomerType["PENDING"] = "pending";
    CustomerType["TRIAL"] = "trial";
    CustomerType["PAID"] = "paid";
    CustomerType["TEST"] = "test";
    CustomerType["DEMO"] = "demo";
})(CustomerType || (CustomerType = {}));
export var CustomerStatus;
(function (CustomerStatus) {
    CustomerStatus["ACTIVE"] = "ACTIVE";
    CustomerStatus["TERMINATED"] = "TERMINATED";
    CustomerStatus["DISABLED"] = "DISABLED";
    CustomerStatus["TO_DELETE"] = "TO_DELETE";
    CustomerStatus["DELETED"] = "DELETED";
    CustomerStatus["CHURNED"] = "CHURNED";
})(CustomerStatus || (CustomerStatus = {}));
export var Tier;
(function (Tier) {
    Tier["PRIVATE"] = "PRIVAT";
    Tier["BASIC"] = "BASIC";
    Tier["PROFESSIONAL"] = "PROFESSIONAL";
    Tier["ENTERPRISE"] = "ENTERPRISE";
    Tier["LEGACY"] = "LEGACY";
})(Tier || (Tier = {}));
export var SupportPartner;
(function (SupportPartner) {
    SupportPartner[SupportPartner["FAIRWALTER"] = 1] = "FAIRWALTER";
    SupportPartner[SupportPartner["EXTENSO"] = 2] = "EXTENSO";
})(SupportPartner || (SupportPartner = {}));
export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["ENDED"] = "ENDED";
    SubscriptionStatus["PAST"] = "PAST";
    SubscriptionStatus["CHURNED"] = "CHURNED";
    SubscriptionStatus["ACTIVE"] = "ACTIVE";
    SubscriptionStatus["UPCOMING"] = "UPCOMING";
})(SubscriptionStatus || (SubscriptionStatus = {}));
export var SubscriptionCreation;
(function (SubscriptionCreation) {
    SubscriptionCreation[SubscriptionCreation["REGULAR"] = -1] = "REGULAR";
    SubscriptionCreation[SubscriptionCreation["UPGRADE"] = -2] = "UPGRADE";
    SubscriptionCreation[SubscriptionCreation["DOWNGRADE"] = -3] = "DOWNGRADE";
})(SubscriptionCreation || (SubscriptionCreation = {}));
export var HandoverInvoicedStatus;
(function (HandoverInvoicedStatus) {
    HandoverInvoicedStatus["OPEN"] = "open";
    HandoverInvoicedStatus["INVOICED"] = "invoiced";
})(HandoverInvoicedStatus || (HandoverInvoicedStatus = {}));
export var HandoverType;
(function (HandoverType) {
    HandoverType["IN"] = "in";
    HandoverType["OUT"] = "out";
    HandoverType["BOTH"] = "both";
})(HandoverType || (HandoverType = {}));
export var IntegrationType;
(function (IntegrationType) {
    IntegrationType["HANDOVER_APP"] = "handover_app";
    IntegrationType["QUICKSCHILD"] = "quickschild";
})(IntegrationType || (IntegrationType = {}));
