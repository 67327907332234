import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { DataTable } from '@f2w/data-table';
import { translate } from 'Services/Translator';
import moment from 'moment';
import { formatMoney } from 'Services/NumberHelper';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { Div } from 'Components/Atoms';
export const PaymentTableModel = DataTable.factory()(() => ({
    tableId: 'Payments',
    manualControl: false,
    useSortBy: false,
    usePagination: {
        hideInfo: true
    },
    initialState: {
        pageSize: 5
    },
    RowActions: ({ row: { original: { id } }, onPaymentDelete }) => (_jsx(ActionsDropdown, { id: id, quickActions: [
            {
                icon: 'trash',
                color: 'error',
                title: translate('payment.delete'),
                onClick: () => onPaymentDelete(id)
            }
        ] })),
    columns: {
        paidAt: {
            Header: translate('payment.paidAt'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ? moment(value).format('DD.MM.YYYY') : translate('notAvailable') }))
        },
        accountingLabel: {
            Header: translate('accountingRecord.label'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ?? translate('notAvailable') }))
        },
        amount: {
            Header: translate('payment.amount'),
            Cell: ({ value }) => (_jsx(Div, { textNoWrap: true, children: value !== undefined ? formatMoney(value) : translate('notAvailable') }))
        },
        creditAccountDescription: {
            Header: translate('creditAccount'),
            Cell: ({ value }) => (_jsx(_Fragment, { children: value ?? translate('notAvailable') }))
        }
    }
}));
