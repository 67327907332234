import { jsx as _jsx } from "react/jsx-runtime";
import { Select as DropdownSelect } from "Atoms/Select";
import { translate } from "Services/Translator";
import { CalculationState } from '../../../enums';
const options = [
    { value: CalculationState.DRAFT, label: translate('ancillaryExpense.calculation.draft') },
    { value: CalculationState.BILLED, label: translate('ancillaryExpense.calculation.billed') },
];
export function StatusFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: options, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
}
