import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { AbstractFormType, CollectionType, FormTheme, NumberType, SelectType } from '@f2w/form';
import { translate } from "Services/Translator";
import { BookingType } from 'Pages/Creditor/enums';
import React from "react";
import { usePortfolio } from "Components/Base/PortfolioContext";
import AssetServices from "Pages/Asset/services/assetServices";
import { formatMoney } from "Services/NumberHelper";
import generateRoute from 'Services/Router';
import client from 'Utils/client';
import { orFunction } from "@f2w/utils";
export class InvoicePositionsType extends AbstractFormType {
    properties = [];
    rentalObjects = [];
    assets = [];
    _useField(props) {
        super._useField(props);
        const [properties, setProperties] = React.useState([]);
        const [rentalObjects, setRentalObjects] = React.useState([]);
        const [assets, setAssets] = React.useState([]);
        this.properties = properties;
        this.rentalObjects = rentalObjects;
        this.assets = assets;
        const portfolio = usePortfolio();
        const { propertyId, rentalObjectId } = this.field.value;
        React.useEffect(() => {
            const allProperties = portfolio.getProperties();
            setProperties(allProperties.map(property => ({
                label: property.name,
                value: property.id
            })));
        }, []);
        React.useEffect(() => {
            if (propertyId) {
                const newRentalObjects = portfolio.getRentalObjectsByPropertyId(propertyId);
                setRentalObjects(newRentalObjects.map(rentalObject => ({
                    label: rentalObject.name,
                    value: rentalObject.id
                })));
                const rentalObjectId = this.field.value.rentalObjectId;
                if (rentalObjectId && !newRentalObjects.find(item => item.id === rentalObjectId)) {
                    this.children.rentalObjectId.field.setValue(null);
                    this.children.assetId.field.setValue(null);
                }
            }
            else {
                setRentalObjects([]);
                this.children.rentalObjectId.field.setValue(null);
                this.children.assetId.field.setValue(null);
            }
        }, [propertyId]);
        React.useEffect(() => {
            if (rentalObjectId) {
                AssetServices.getAssetsByRentalObjectForDropdown(rentalObjectId)
                    .then(newAssets => {
                    const rentalObjectId = this.field.value.rentalObjectId;
                    if (rentalObjectId) {
                        setAssets(newAssets.map(asset => ({
                            label: asset.name,
                            value: asset.id
                        })));
                        const assetId = this.field.value.assetId;
                        if (assetId && !newAssets.find(item => item.id === assetId)) {
                            this.children.assetId.field.setValue(null);
                        }
                    }
                    else {
                        setAssets([]);
                        this.children.assetId.field.setValue(null);
                    }
                });
            }
            else {
                setAssets([]);
                this.children.assetId.field.setValue(null);
            }
        }, [rentalObjectId]);
    }
    buildFields() {
        const isDisabledForAncillary = () => {
            return (this.value?.isFromAncillary === true);
        };
        return {
            propertyId: new SelectType({
                label: translate('property'),
                required: true,
                choiceOptions: () => this.properties,
                searchable: true,
                getProps: () => ({ isDisabled: isDisabledForAncillary() }),
                isVisible: () => this.field.value?.bookingType === BookingType.COMBINED
            }),
            rentalObjectId: new SelectType({
                label: translate('rentalObject'),
                required: false,
                choiceOptions: () => this.rentalObjects,
                searchable: true,
                getProps: () => ({ isDisabled: isDisabledForAncillary() }),
                isVisible: () => this.field.value?.bookingType === BookingType.COMBINED
            }),
            assetId: new SelectType({
                label: translate('asset'),
                required: false,
                choiceOptions: () => this.assets,
                searchable: true,
                getProps: () => ({ isDisabled: isDisabledForAncillary() }),
                isVisible: () => this.field.value?.bookingType === BookingType.COMBINED
            }),
            positions: new CollectionType({
                label: false,
                actions: false,
                getProps: () => ({ disabled: isDisabledForAncillary() }),
                isVisible: () => this.field.value?.bookingType === BookingType.SEPARATED,
                prototype: () => new InvoicePositionsType.Position({
                    properties: () => this.properties,
                    disabled: isDisabledForAncillary()
                })
            }),
        };
    }
}
(function (InvoicePositionsType) {
    async function updateData(id, data) {
        const route = generateRoute('api.creditorInvoices.invoicePositionsStep', {
            creditorInvoice: id
        });
        return client.put(route, data).then(res => res.data);
    }
    InvoicePositionsType.updateData = updateData;
    class Position extends AbstractFormType {
        rentalObjects = [];
        assets = [];
        __getDefaultOptions() {
            return {
                ...super.__getDefaultOptions(),
                label: true,
            };
        }
        _renderLabel(iProps) {
            const { Root, Text, Title } = FormTheme.Label;
            return _jsx(Root, { children: _jsx(Text, { children: _jsxs(Title, { style: { fontWeight: 'bold', fontSize: '20px' }, children: [this.field.value.label, " - ", formatMoney(this.field.value.amount)] }) }) });
        }
        _useField(props) {
            super._useField(props);
            const [rentalObjects, setRentalObjects] = React.useState([]);
            const [assets, setAssets] = React.useState([]);
            this.rentalObjects = rentalObjects;
            this.assets = assets;
            const portfolio = usePortfolio();
            const { propertyId, rentalObjectId } = this.field.value;
            React.useEffect(() => {
                if (propertyId) {
                    const newRentalObjects = portfolio.getRentalObjectsByPropertyId(propertyId);
                    setRentalObjects(newRentalObjects.map(rentalObject => ({
                        label: rentalObject.name,
                        value: rentalObject.id
                    })));
                    const rentalObjectId = this.field.value.rentalObjectId;
                    if (rentalObjectId && !newRentalObjects.find(item => item.id === rentalObjectId)) {
                        this.children.rentalObjectId.field.setValue(null);
                        this.children.assetId.field.setValue(null);
                    }
                }
                else {
                    setRentalObjects([]);
                }
            }, [propertyId]);
            React.useEffect(() => {
                if (rentalObjectId) {
                    AssetServices.getAssetsByRentalObjectForDropdown(rentalObjectId)
                        .then(newAssets => {
                        const rentalObjectId = this.field.value.rentalObjectId;
                        if (rentalObjectId) {
                            setAssets(newAssets.map(asset => ({
                                label: asset.name,
                                value: asset.id
                            })));
                            const assetId = this.field.value.assetId;
                            if (assetId && !newAssets.find(item => item.id === assetId)) {
                                this.children.assetId.field.setValue(null);
                            }
                        }
                        else {
                            setAssets([]);
                            this.children.assetId.field.setValue(null);
                        }
                    });
                }
                else {
                    setAssets([]);
                    this.children.assetId.field.setValue(null);
                }
            }, [rentalObjectId]);
        }
        buildFields() {
            const isDisabled = orFunction(this.options.disabled);
            return {
                id: new NumberType({
                    isVisible: () => false,
                    disabled: isDisabled,
                    required: false,
                }),
                propertyId: new SelectType({
                    label: translate('property'),
                    disabled: isDisabled,
                    required: true,
                    searchable: true,
                    choiceOptions: () => this.options.properties?.()
                }),
                rentalObjectId: new SelectType({
                    label: translate('rentalObject'),
                    disabled: isDisabled,
                    required: false,
                    searchable: true,
                    choiceOptions: () => this.rentalObjects
                }),
                assetId: new SelectType({
                    label: translate('asset'),
                    disabled: isDisabled,
                    required: false,
                    searchable: true,
                    choiceOptions: () => this.assets
                }),
            };
        }
    }
    InvoicePositionsType.Position = Position;
})(InvoicePositionsType || (InvoicePositionsType = {}));
