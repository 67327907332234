import { AbstractFormType, CollectionType, SelectType } from '@f2w/form';
import { translate } from 'Services/Translator';
import React from 'react';
import assetServices from "Pages/Asset/services/assetServices";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { getGlobalContext } from "Services/GlobalContext";
export class AssetCreditorInvoicesType extends AbstractFormType {
    properties = [];
    rentalObjects = [];
    rooms = [];
    _useField(props) {
        super._useField(props);
        const [rentalObjects, setRentalObjects] = React.useState([]);
        const [roomMap, setRoomMap] = React.useState(new Map());
        const [rooms, setRooms] = React.useState([]);
        const [properties, setProperties] = React.useState([]);
        this.properties = properties;
        this.rentalObjects = rentalObjects;
        this.rooms = rooms;
        const portfolio = usePortfolio();
        const { scope } = getGlobalContext();
        React.useEffect(() => {
            assetServices.getAssetsDropdownData().then(response => {
                const roomMap = this.prepareRoomMap(response);
                const rentalObjects = this.groupRentalObjects(portfolio, portfolio.getRentalObjects());
                const properties = this.prepareProperties(portfolio);
                setRoomMap(roomMap);
                setProperties(properties);
                setRentalObjects(rentalObjects);
            });
        }, []);
        React.useEffect(() => {
            if (!this.field.value.id) {
                switch (scope?.level) {
                    case "property": {
                        this.children.propertyId.field.setInternalValue(scope.id);
                        break;
                    }
                    case "rentalObject": {
                        this.children.propertyId.field.setInternalValue(portfolio.getPropertyByRentalObjectId(scope.id).id);
                        this.children.rentalObjectId.field.setInternalValue(scope.id);
                        break;
                    }
                }
            }
        }, [scope?.level, scope?.id]);
        const rentalObjectId = this.field.value.rentalObjectId;
        React.useEffect(() => {
            if (rentalObjectId && roomMap.size > 0) {
                const foundRooms = roomMap.get(rentalObjectId) ?? [];
                setRooms(foundRooms);
                const roomId = this.field.value.roomId;
                if (roomId && !foundRooms.find(room => room.value === roomId)) {
                    this.children.roomId.field.setValue(null);
                }
            }
        }, [rentalObjectId, roomMap]);
        const propertyId = this.field.value.propertyId;
        React.useEffect(() => {
            if (propertyId) {
                const rentalObjects = portfolio.getRentalObjectsByPropertyId(propertyId);
                setRentalObjects(this.groupRentalObjects(portfolio, rentalObjects));
                const rentalObjectId = this.field.value.rentalObjectId;
                if (rentalObjectId && !rentalObjects.find(rentalObject => rentalObject.id === rentalObjectId)) {
                    this.children.roomId.field.setValue(null);
                    this.children.rentalObjectId.field.setValue(null);
                }
            }
        }, [propertyId]);
    }
    prepareRoomMap(response) {
        const roomMap = new Map();
        response.rooms.forEach(room => {
            if (roomMap.has(room.rentalObjectId)) {
                const roomsInMap = roomMap.get(room.rentalObjectId);
                roomsInMap.push({
                    value: room.id,
                    label: room.name
                });
                roomMap.set(room.rentalObjectId, roomsInMap);
            }
            else {
                roomMap.set(room.rentalObjectId, [{
                        value: room.id,
                        label: room.name
                    }]);
            }
        });
        return roomMap;
    }
    prepareProperties(portfolio) {
        return portfolio.getProperties().map(property => ({
            value: property.id,
            label: property.name
        }));
    }
    groupRentalObjects(portfolio, rentalObjects) {
        const groupedRentalObjects = new Map();
        rentalObjects.forEach(rentalObject => {
            if (groupedRentalObjects.has(rentalObject.buildingId)) {
                const rentalObjectGroup = groupedRentalObjects.get(rentalObject.buildingId);
                groupedRentalObjects.set(rentalObject.buildingId, {
                    ...rentalObjectGroup,
                    options: [
                        ...(rentalObjectGroup.options),
                        {
                            label: rentalObject.name,
                            value: rentalObject.id
                        }
                    ]
                });
            }
            else {
                groupedRentalObjects.set(rentalObject.buildingId, {
                    label: portfolio.getBuildingById(rentalObject.buildingId).name,
                    options: [
                        {
                            label: rentalObject.name,
                            value: rentalObject.id
                        }
                    ]
                });
            }
        });
        return Array.from(groupedRentalObjects.values());
    }
    buildFields() {
        return {
            properties: new CollectionType({
                label: translate('properties'),
                actions: { add: true, delete: true },
                prototype: () => new SelectType({
                    label: false,
                    choiceOptions: () => this.properties,
                    nullable: false,
                    searchable: true,
                }),
            }),
        };
    }
}
