import { useFormModal } from "@f2w/form";
import { CreateRoomType } from "Pages/Asset/forms/CreateRoomType";
import { translate } from "Services/Translator";
import { getGlobalContext } from "Services/GlobalContext";
export const useCreateRoomForm = ({ updateRoomsRef, portfolio, addRentalObjectRoom, onSave }) => useFormModal({
    name: 'create-room',
    type: () => new CreateRoomType(),
    title: translate('rentalObject.addRoom'),
    onLoad: async () => {
        const { scope } = getGlobalContext();
        switch (scope.level) {
            case "property": {
                return {
                    propertyId: scope.id
                };
            }
            case "rentalObject": {
                return {
                    propertyId: portfolio.getPropertyByRentalObjectId(scope.id).id,
                    buildingId: portfolio.getBuildingByRentalObjectId(scope.id).id,
                    rentalObjectId: scope.id
                };
            }
        }
        return {};
    },
    onSave: async (values) => {
        return addRentalObjectRoom(values.rentalObjectId, values.abstractRoomId)
            .then((room) => {
            updateRoomsRef.current?.((prev) => {
                const modifiedMap = new Map(prev);
                modifiedMap.set(values.rentalObjectId, [
                    ...(modifiedMap.get(values.rentalObjectId) ?? []),
                    {
                        value: room.id,
                        label: room.name
                    }
                ]);
                return modifiedMap;
            });
            onSave();
        });
    }
});
