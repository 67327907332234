import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { FormError } from '@f2w/form';
import { translate } from 'Services/Translator';
import { FlowTemplate, useStepView } from '@f2w/view';
import { Section } from 'Atoms/Layout/components';
import { RegistrationType } from '../types/RegistrationType';
import { Button, Icon, Text } from 'Components/Atoms';
import RegistrationManager from '../services/RegistrationManager';
import { RegistrationTemplate } from '../components/RegistrationTemplate';
import { sendRegistrationStep } from 'Services/GtmServices';
import { isAppEnv } from '@f2w/utils';
export const useCompleteAccountView = ({ manager = RegistrationManager, membershipType, initialValues: { membershipType: _, ...initialValues }, }) => useStepView(() => RegistrationType.create(membershipType), {
    id: 'complete-registration',
    load: async () => ({
        ...initialValues,
    }),
    DefaultFormTemplate: ({ view: { form }, formChildren, view, }) => {
        return _jsxs(Section, { "data-form": true, children: [formChildren, form?.status?.error && _jsx(Text, { style: { marginBottom: 'var(--form-row-spacing,1.5rem)' }, className: 'fw-form-general-error', as: 'div', error: true, children: form?.status?.error }), _jsx(Button, { label: translate('btn.next'), "$size": "lg", block: true, loading: form?.loading, onClick: () => view.saveForm(), children: _jsx(Icon, { icon: 'arrow-right' }) })] });
    },
    DefaultTemplate: ({ view: { step: stepApi, activeGroup: group }, view }) => {
        useEffect(() => {
            switch (group.key) {
                case 'step_1':
                    sendRegistrationStep({
                        path: 'registration-email-validated',
                        title: 'Email Validated',
                        partner: membershipType
                    });
                    break;
                case 'step_2':
                    sendRegistrationStep({
                        path: 'registration-profile-data',
                        title: 'Profile Data',
                        partner: membershipType
                    });
                    break;
                case 'step_3':
                    sendRegistrationStep({
                        path: 'registration-contact-info',
                        title: 'Contact Info',
                        partner: membershipType
                    });
                    break;
            }
        }, []);
        return _jsx(RegistrationTemplate, { view: view, header: _jsx(FlowTemplate, { nbSteps: stepApi.maxStep + 1, currentStep: stepApi.currentStep + 1, lastFinishedStep: stepApi.lastFinishedStep + 1, onSetStep: (p) => {
                    stepApi.setStep(p);
                } }), children: view.renderForm() });
    },
    saveStep: async (data, { step }) => {
        switch (step.getStepName()) {
            case 'step_1':
                break;
            case 'step_2':
                break;
            case 'step_3':
                break;
        }
        console.log('GTM', {
            stepName: step.getStepName(),
            data,
        });
    },
    save: async (submitData, view) => {
        if (isAppEnv()) {
            await manager.completeAccount(submitData);
            sendRegistrationStep({
                path: 'registration-success',
                title: 'Success',
                partner: membershipType
            });
        }
        view.dispatcher.toast.success({
            message: translate('account.register.created'),
            timeout: 2,
            onClose: () => {
                if (isAppEnv()) {
                    window.location.href = '/';
                }
            }
        });
    },
    saveError: (error) => {
        return new FormError(error.response?.data?.message ?? error.message);
    },
}, () => ({
    step_1: {
        name: translate('flow.getting_started.account'),
        Form: ({ type }) => {
            return type.renderGroup('step_1');
        },
    },
    step_2: {
        name: translate('flow.getting_started.account'),
        Form: ({ type }) => {
            return type.renderGroup('step_2');
        },
        forceValidation: true,
    },
    step_3: {
        name: translate('flow.getting_started.contact'),
        Form: ({ type }) => {
            return type.renderGroup('step_3');
        },
    },
}));
