import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { dataAttr, slotAttr, slotCss, styledModule, } from '@f2w/theme';
import styled from 'styled-components';
import { useUuid } from 'Hooks/useId';
import './Tooltip.styles.scss';
export const tooltipSlotAttr = (slot) => slotAttr('tooltip', slot);
export const tooltipSlotCss = (...slots) => slotCss('tooltip', ...slots);
const slot = styledModule(() => {
    const Root = styled.div ``;
    const Content = styled.div ``;
    const Arrow = styled.div ``;
    return {
        Root,
        Content,
        Arrow
    };
});
const TooltipBase = forwardRef(({ placement = 'right', className, size, style, children, arrowProps, popper: _, show: _2, id: _id, ...props }, ref) => {
    const [primaryPlacement] = placement?.split('-') || [];
    const id = useUuid(_id);
    return (_jsxs(slot.Root, { role: "tooltip", ...tooltipSlotAttr('root'), ref: ref, style: style, id: id, "x-placement": primaryPlacement, "data-placement": dataAttr(primaryPlacement), "data-size": dataAttr(size), className: classNames(className), ...props, children: [_jsx(slot.Arrow, { ...tooltipSlotAttr('arrow'), ...arrowProps }), _jsx(slot.Content, { ...tooltipSlotAttr('content'), children: children })] }));
});
TooltipBase.displayName = 'Tooltip';
export { TooltipBase as Tooltip };
