import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FlexCol, FlexRow, SafeAnchor } from 'Components/Atoms';
import { formatPersonName } from 'Pages/Handover/services/HandoverFormatter';
import { UseStyled } from '@fw/theme';
import { IbanType } from '@f2w/form';
import { Card } from 'Atoms/Card';
const VerticalLine = UseStyled.styled('div', ({ theme: { palette } }) => ({
    borderLeft: `2px solid ${palette.neutral.$100}`,
    height: '100%'
}));
const TenantBlock = ({ tenant, blockHeader, bankAccountHeader }) => {
    const newAddress = tenant.newAddress;
    const bankAccount = tenant.bankAccount;
    return (_jsx(Card, { children: _jsxs(FlexRow, { gap: 24, children: [_jsx(FlexCol, { flex: '50%', children: _jsxs(Card.Content, { title: blockHeader, children: [_jsx("b", { children: formatPersonName(tenant) }), _jsx("br", {}), tenant.email !== '' && (_jsx(SafeAnchor, { href: 'mailto:' + tenant.email, children: tenant.email }))] }) }), _jsx(FlexCol, { pl: 10, pr: 10, children: _jsx(VerticalLine, {}) }), _jsxs(FlexCol, { flex: '50%', children: [bankAccount !== null && _jsx("div", { children: _jsxs(Card.Content, { title: bankAccountHeader, children: [_jsx(Card.Value, { empty: true, children: IbanType.useFormat(bankAccount.iban) }), _jsxs("div", { children: [bankAccount.name, " ", _jsx("br", {}), bankAccount.zip, " ", bankAccount.city] })] }) }), bankAccount !== null && newAddress !== null && _jsx("hr", {}), newAddress !== null && _jsx("div", { children: _jsxs(Card.Content, { title: 'Neue Adresse', children: [newAddress.street, _jsx("br", {}), newAddress.zip, " ", newAddress.city] }) })] })] }) }));
};
export default TenantBlock;
