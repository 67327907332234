import { useForm } from "@f2w/form";
import { isStorybookEnv } from '@f2w/utils';
import { CalculationWizardBasicsType } from '../types';
export const useCalculationWizardBasicsForm = ({ propertyId, onSubmit, getPropertyDetails }) => useForm({
    name: 'calculation-wizard-basics-form',
    type: () => new CalculationWizardBasicsType({ getPropertyDetails }),
    initialValues: isStorybookEnv() ? {
        propertyId: 238,
        periodFrom: '2021-11-02',
        periodTo: '2022-11-01',
        administrativeCost: 7.0,
    } : {
        propertyId: propertyId,
        periodFrom: undefined,
        periodTo: undefined,
        administrativeCost: undefined,
    },
    successMessage: null,
    onSubmit: (values) => {
        onSubmit(values);
    },
});
