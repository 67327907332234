import { TransactionAssignmentType, TransactionStatus, TransactionStatusType, UpdateTransactionDtoAction, } from '../types';
import { useCallback, useRef } from 'react';
import { mapIds } from 'Utils/types';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { useStateManager } from '@f2w/data-table/helpers';
import * as TransactionUpdate from './BankingTransactionService';
export const useTransactionManager = Object.assign((props) => {
    const { updateLoadedData, onSave, } = props;
    const toastApi = useToastDispatcherApi();
    const isSavingRef = useRef(false);
    const stateApi = useStateManager(props);
    const isEditable = useCallback((id) => TransactionStatus.isEditable(stateApi.getData(id)?.status), [stateApi.getData]);
    const isAssignable = useCallback((id) => TransactionStatus.isAssignable(stateApi.getData(id)?.status), [stateApi.getData]);
    const isIgnored = useCallback((id) => TransactionStatusType.IGNORED === stateApi.getData(id)?.status, [stateApi.getData]);
    const canSave = (id) => stateApi.isDirty(id) || TransactionStatusType.PRE_ASSIGNED === stateApi.getLoadedData(id)?.status;
    const handleRes = (result) => {
        const succeeded = result?.succeeded?.map?.((data) => {
            const _updateData = stateApi.getUpdateData(data.id);
            let updateData = {
                ..._updateData,
                status: data.status,
            };
            updateLoadedData(data.id, {
                ...updateData,
            });
            stateApi._removeDirty(data.id);
            stateApi.setState(data.id, () => ({
                $status: 'success',
                initial: updateData,
            }));
            return {
                id: data.id,
                data: { ...updateData },
            };
        });
        const failed = result?.failed?.map?.((data) => {
            stateApi.setState(data.id, {
                $status: 'error',
                $statusMessage: data?.reason in TransactionUpdate.ExceptionType
                    ? translate(`bankingReconciliationTransactionsTable.error.${data.reason}`)
                    : undefined
            });
            return data;
        });
        const skipped = result?.skipped;
        onSave?.(succeeded, failed);
        toastApi.success({
            message: translate('bankingReconciliationTransactionsTable.success', {
                nbSucceed: succeeded?.length ?? 0,
                nbSkipped: skipped?.length ?? 0,
                nbFailed: failed?.length ?? 0,
            }),
            timeout: 20,
            showRemove: true,
        });
        return result;
    };
    const _updateRequest = async (request) => {
        isSavingRef.current = true;
        return TransactionUpdate.send(request)
            .then(result => {
            handleRes(result);
            return result;
        })
            .catch(e => {
            toastApi.error({ message: translate('generalError.default') });
            throw e;
        }).finally(() => {
            isSavingRef.current = false;
        });
    };
    const ignore = (ids) => _updateRequest(mapIds(ids, {
        action: UpdateTransactionDtoAction.IGNORE,
    }));
    const unignore = (ids) => _updateRequest(mapIds(ids, {
        action: UpdateTransactionDtoAction.UNIGNORE,
    }));
    const assign = (ids) => _updateRequest(mapIds(ids, id => {
        return prepareRequest(id, stateApi.getState(id));
    }));
    const save = (ids) => _updateRequest(mapIds(ids, id => {
        return prepareRequest(id, stateApi.getState(id));
    }));
    const loadInfo = async (filters) => {
        return TransactionUpdate.loadInfo(filters).then(ids => {
            return ids;
        });
    };
    const approveAllPreAssigned = async (filters) => {
        return await TransactionUpdate.approveAllPreAssgned()
            .then(res => {
            handleRes(res.data);
        });
    };
    return {
        ...stateApi,
        isSaving: isSavingRef.current,
        isEditable,
        isAssignable,
        isIgnored,
        canSave,
        assign,
        ignore,
        unignore,
        save,
        loadInfo,
        approveAllPreAssigned,
    };
}, {
    initialStateAccessor: (initialData) => {
        if (!TransactionStatus.isEditable(initialData?.status))
            return {};
        const initial = prepareState(initialData);
        return { initial, update: initial };
    },
});
function prepareState(initialData) {
    return {
        id: initialData?.id,
        status: initialData?.status,
        property: initialData?.property || null,
        assignmentType: initialData?.assignmentType || null,
        accountingRecordLabel: initialData?.accountingRecordLabel || translate('bankingReconciliation.accountingDescription.manual', {
            'transactionDescription': initialData?.description
        }),
        assignment: {
            creditAccount: initialData?.creditAccount || undefined,
            creditorInvoice: initialData?.creditorInvoice || undefined,
            debitAccount: initialData?.debitAccount || undefined,
            debitorInvoice: initialData?.debitorInvoice || undefined,
            accountingRecordLabel: initialData?.accountingRecordLabel || translate('bankingReconciliation.accountingDescription.manual', {
                'transactionDescription': initialData?.description
            }),
        },
    };
}
function prepareRequest(id, { initial, update: _update, $isDirty }) {
    if (!$isDirty) {
        return { id };
    }
    const update = {
        ...initial,
        ..._update,
        accountingRecordLabel: _update.accountingRecordLabel ?? initial.accountingRecordLabel,
        assignment: {
            ...initial?.assignment,
            ..._update?.assignment
        }
    };
    if (update.status === TransactionStatusType.IGNORED) {
        return { id, action: UpdateTransactionDtoAction.IGNORE };
    }
    if (TransactionStatus.isEditable(update.status)) {
        const requestData = {
            id,
            propertyId: update.property?.id,
            assignmentType: update.assignmentType,
            ...((assignmentType, assignment, accountingRecordLabel) => {
                switch (assignmentType) {
                    case TransactionAssignmentType.DEBITOR_INVOICE:
                        return {
                            action: UpdateTransactionDtoAction.ASSIGN,
                            debitorInvoiceId: assignment?.debitorInvoice?.id,
                        };
                    case TransactionAssignmentType.CREDITOR_INVOICE:
                        return {
                            action: UpdateTransactionDtoAction.ASSIGN,
                            creditorInvoiceId: assignment?.creditorInvoice?.id,
                        };
                    case TransactionAssignmentType.MANUAL:
                        return {
                            action: UpdateTransactionDtoAction.ASSIGN,
                            debitAccountId: assignment?.debitAccount?.id,
                            creditAccountId: assignment?.creditAccount?.id,
                            accountingRecordLabel: assignment?.accountingRecordLabel ?? accountingRecordLabel,
                            accountingRecordReceiptNumber: assignment?.accountingRecordReceiptNumber,
                        };
                }
            })(update.assignmentType, update.assignment, update.accountingRecordLabel),
        };
        if (requestData.action) {
            return { ...requestData };
        }
    }
}
