import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Header, Section } from "Atoms/Layout/components";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { Card } from "Atoms/Card";
import { translate } from "Services/Translator";
import { Title } from "Atoms/Text";
import { Button } from "Atoms/Button";
export const GeneralTab = ({ view }) => {
    const { data } = view;
    return (_jsxs(_Fragment, { children: [_jsxs(Header.Sticky, { children: [_jsx(Title, { h6: true, as: 'h3', children: data.name }), _jsx(Div, { children: _jsx(Button.Edit, { onClick: () => view.openForm('general') }) })] }), _jsx(Section, { children: _jsxs(FlexRow, { gap: 24, children: [_jsxs(FlexCol, { gap: 24, flex: '50%', children: [_jsxs(Card, { children: [_jsx(Card.Value, { title: translate('property'), children: data.propertyName }), _jsx(Card.Value, { title: translate('building.title'), children: data.buildingName }), _jsx(Card.Value, { title: translate('rentalObject'), children: data.rentalObjectName })] }), _jsx(Card, { children: _jsx(Card.Value, { title: translate('rentalObjectRoom.area'), children: data.area ? _jsxs(_Fragment, { children: [data.area, "m", _jsx("sup", { children: "2" })] }) : '' }) })] }), _jsx(FlexCol, { gap: 24, flex: '50%', children: _jsx(Card, { children: _jsx(Card.Value, { title: translate('rentalObjectRoom.notes'), children: data.notes }) }) })] }) })] }));
};
