import { jsx as _jsx } from "react/jsx-runtime";
import { FinancialsPieChart } from "./FinancialsPieChart";
import { translate } from "Services/Translator";
const convertPropertyStatsToChartData = (item, totalInvestmentValue) => {
    return ({
        name: item.property ? item.property.name : translate('dashboard.sections.financials.data.other'),
        netRentPossible: item.possibleNetAmount,
        netRentActual: {
            value: item.actualNetAmount,
            percent: item.possibleNetAmount ? item.actualNetAmount / item.possibleNetAmount : 0.0,
        },
        investmentValue: {
            value: item.investmentValue,
            percent: totalInvestmentValue ? item.investmentValue / totalInvestmentValue : 0.0,
        },
        mortgageValues: item.mortgageValues,
        profit: item.profit,
    });
};
const DashboardFinancialsPieChart = ({ propertyStats, totalInvestmentValue }) => {
    const data = propertyStats.map((item) => convertPropertyStatsToChartData(item, totalInvestmentValue));
    return (_jsx(FinancialsPieChart, { data: data }));
};
export default DashboardFinancialsPieChart;
