import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import DunningServices from 'Pages/Dunning/services/dunningServices';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { translate } from 'Services/Translator';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { PersonShortInfoModal } from 'Pages/Dunning/components/PersonShortInfoModal';
import { PersonView } from 'Pages/Person/Details/components/PersonView';
import { PersonDetailsServices } from 'Pages/Person/Details/services/personDetailsServices';
import { FormModal } from '@f2w/form';
import { useCreateRemindersForm } from 'Pages/Dunning/hooks/useCreateRemindersForm';
import { DunningGroupedDataTable } from './table/DunningGroupedDataTable';
import { DebitorView } from "Pages/Debitor/components/DebitorView";
import * as DebitorServices from "Pages/Debitor/services/debitorServices";
import { PersonAccountStatementModal } from "Pages/Dunning/components/PersonAccountStatementModal";
import { usePersonAccountStatementReportsExportForm } from "./components/PersonAccountStatementReportsExportForm";
export const DunningPage = ({ dunningServices = DunningServices, personServices = PersonDetailsServices, debitorServices = DebitorServices, }) => {
    const [personId, setPersonId] = useState();
    const [debitorInvoiceId, setDebitorInvoiceId] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const form = useCreateRemindersForm({
        onClose: () => instance.refresh(),
        selectedRows: selectedRows,
        createReminders: dunningServices.createReminders
    });
    const { openViewModalObj } = useObjectViewModalContext();
    const instance = DunningGroupedDataTable.use({
        listDunning: dunningServices.listDebitor,
        openPersonAccountStatementReportsExportModal: () => exportForm.openForm(),
        openPersonInfo: (row) => {
            setSelectedRow({ type: 'shortPersonInfo', row });
        },
        openPersonAccountStatement: (row) => {
            setSelectedRow({ type: 'personAccountStatement', row });
        },
        openDebitorView: setDebitorInvoiceId,
        openCreateRemindersForm: async (selectedRows) => {
            await setSelectedRows(selectedRows);
            await form.openForm();
        },
    });
    const getSelectedPersonIds = React.useCallback(() => {
        return [
            ...new Set(Object
                .values(instance.state.rowState)
                .map(selectedState => selectedState.personId)
                .filter(personId => personId !== null && personId !== undefined))
        ];
    }, [instance.state.rowState]);
    const exportForm = usePersonAccountStatementReportsExportForm({
        getPersonIds: getSelectedPersonIds,
        getPersonAccountStatementReports: dunningServices.getPersonAccountStatementReports
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { title: translate('dunning'), variant: 'content', instance: instance, showFilters: true }), _jsx(PersonShortInfoModal, { row: selectedRow?.type === 'shortPersonInfo' ? selectedRow.row : undefined, openPersonDetails: setPersonId, onClose: () => setSelectedRow(undefined) }), personId && (_jsx(PersonView, { updatePerson: personServices.updatePerson, getPersonById: personServices.getPersonById, onStreetClick: (id) => () => openViewModalObj({ id, view: ActiveView.RENTAL_OBJECT }), createPersonAddress: personServices.createPersonAddress, updatePersonAddress: personServices.updatePersonAddress, onClose: () => {
                    setPersonId(undefined);
                    instance.refresh();
                }, personId: personId })), debitorInvoiceId && _jsx(DebitorView, { debitorInvoiceId: debitorInvoiceId, onClose: () => {
                    setDebitorInvoiceId(undefined);
                    instance.refresh();
                }, getDebitorInvoiceDetails: debitorServices.getDebitorInvoiceDetails, updateDebitorInvoiceGeneralData: debitorServices.updateDebitorInvoiceGeneralData }), _jsx(PersonAccountStatementModal, { person: selectedRow?.type === 'personAccountStatement' ? selectedRow.row : undefined, onClose: () => setSelectedRow(undefined), getPersonAccountStatement: dunningServices.getPersonAccountStatement, getPersonAccountStatementReport: dunningServices.getPersonAccountStatementReport }), _jsx(FormModal, { modal: form }), _jsx(FormModal, { modal: exportForm })] }));
};
