import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { translate } from 'Services/Translator';
import { Image } from './Image';
export const AvatarImage = (props) => (_jsx(Image, { editable: true, round: true, uploadLabel: translate('form.control.avatar.action.upload'), deleteLabel: translate('form.control.avatar.action.delete'), deleteConfirmLabel: translate('form.control.avatar.action.delete.confirm'), ...props, children: ({ src, round, value, placeholder, $size }) => (_jsx(AvatarRoot, { image: src, round: round, "$size": $size || 74, children: value ? null : placeholder })) }));
const AvatarRoot = styled.div `
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1em;
  background-size: cover;
  background-position: center;
  flex: 0;
  ${p => {
    const color = p.theme.palette[p.color ?? 'primary'];
    return {
        minWidth: p.$size,
        minHeight: p.$size,
        backgroundImage: p.image && `url(${p.image})`,
        ...(p.round ? {
            borderRadius: '50%',
            backgroundColor: color.$50,
            color: color.$500,
        } : {
            borderRadius: p.theme.rem(6),
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: p.theme.palette.neutral.$100,
            backgroundColor: 'transparent',
        }),
    };
}};
`;
