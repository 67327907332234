import { SlotFactory, SlotFactory2 } from '@fw/theme';
import './LayoutTheme.scss';
export const layoutSlot = new SlotFactory('layout');
export const layoutSlot2 = new SlotFactory2('layout', {
    'root': {},
    'header': {
        as: 'header'
    },
    'footer': {
        as: 'footer'
    },
    'side': {
        as: 'aside'
    },
    'main': {},
    'preview': {
        as: 'aside'
    },
});
