import { jsx as _jsx } from "react/jsx-runtime";
import { useModalContext } from 'Components/Modal';
import { UseSelect } from '../base';
import { Icon } from 'Atoms/Icon';
import defaultTheme from './defaultTheme';
import { mergeStyles } from 'react-select';
export const SelectDefault = ({ container: _fixed, ...props }) => {
    const modalCon = useModalContext();
    _fixed = _fixed === true && !!modalCon;
    const selectProps = {
        closeMenuOnSelect: !props.isMulti,
        menuShouldBlockScroll: !!(_fixed || props.menuPortalTarget),
        menuPosition: _fixed ? 'fixed' : 'absolute',
        menuPlacement: 'auto',
        minMenuHeight: 140,
        maxMenuHeight: 320,
        ...defaultProps,
        ...props,
    };
    return _jsx(UseSelect, { ...selectProps });
};
const { styles, components } = defaultTheme;
export const defaultProps = {
    components: {
        ...components,
        DropdownIndicator: ({ selectProps, theme }) => (_jsx(Icon, { icon: selectProps.menuIsOpen ? 'chevron-up' : 'chevron-down', size: theme?.vars?.$size === 'sm' ? 18 : 22, color: "#CED1DB" })),
    },
    styles: mergeStyles(styles, {
        container: (base) => {
            return {
                ...base,
            };
        },
        menuPortal: base => ({
            ...base,
            zIndex: 10010070,
        }),
        menu: (base, state) => {
            const { alignMenu, useMaxContentWidth, theme: { palette, rem } } = state.theme.vars;
            const extra = useMaxContentWidth ? {
                width: 'max-content',
                minWidth: '100%',
                maxWidth: 520,
            } : {};
            if (alignMenu === 'right') {
                extra.right = 0;
            }
            return {
                ...base,
                ...extra,
                zIndex: 10010070,
                border: `2px solid ${palette.primary.$50}`,
                boxShadow: 'none',
                borderRadius: rem(8),
            };
        },
        menuList: (base, state) => {
            const { menuListStyles } = state.theme.vars;
            return {
                ...base,
                ...menuListStyles,
            };
        },
        indicatorsContainer: (base, state) => {
            const { paddingY, $inline } = state.theme.vars;
            return {
                ...base,
                display: $inline ? 'none' : base?.display,
                padding: `0 ${paddingY}px 0 0`,
                '& > div': {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            };
        },
    }),
};
