import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Table } from '@f2w/data-table/table-types';
import { AuditEventGroup } from '../enums';
import moment from 'moment';
import { Select } from "Atoms/Select";
import { Div } from "Atoms/Layout";
const BaseTable = Table.factory()(() => ({
    manualControl: true,
    tableId: 'audit-log-table',
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    useFilters: {},
    initialState: {
        sortBy: [
            { id: 'timestamp', desc: true }
        ]
    },
    columns: {
        $actions: {
            sticky: 'left',
        },
        timestamp: {
            Header: 'Timestamp',
            useSortBy: {},
            Cell: ({ value: timestamp }) => (_jsx(Div, { children: moment(timestamp).format('DD.MM.YYYY HH:mm:ss') }))
        },
        event: {
            Header: 'Event',
        },
        eventGroup: {
            Header: 'Type',
            useFilters: {
                Filter: ({ column: { setFilter, filterValue } }) => {
                    const options = useMemo(() => {
                        return [
                            { value: AuditEventGroup.DebitorInvoice, label: 'Debitor Invoice' },
                            { value: AuditEventGroup.CreditorInvoice, label: 'Creditor Invoice' },
                            { value: AuditEventGroup.Contract, label: 'Contract' },
                            { value: AuditEventGroup.Person, label: 'Person' },
                            { value: AuditEventGroup.BankAccount, label: 'Bank account' },
                            { value: AuditEventGroup.Auth, label: 'Authentication' },
                            { value: AuditEventGroup.BankingReconciliation, label: 'Banking Reconciliation' },
                            { value: AuditEventGroup.TwoFactorAuth, label: 'Two Factor Authentication' },
                        ];
                    }, []);
                    return _jsx(Select, { value: filterValue, options: options, isSearchable: true, isClearable: true, onChange: (newValue) => setFilter(newValue?.value) });
                }
            }
        },
        objectId: {
            Header: 'ID',
        },
        user: {
            Header: 'User',
            useSortBy: {},
            Cell: ({ value: user }) => {
                if (!user) {
                    return '-';
                }
                return _jsxs(_Fragment, { children: [_jsxs("div", { children: [user.firstName, " ", user.lastName, " [#", user.id, "]"] }), _jsx("div", { children: user.email })] });
            }
        },
        customer: {
            Header: 'Customer',
            useSortBy: {},
            useFilters: {
                Filter: ({ column: { setFilter, filterValue }, getActiveCustomers }) => {
                    const [options, setOptions] = useState([]);
                    useEffect(() => {
                        getActiveCustomers().then(data => setOptions(data.map(({ id: value, name: label }) => ({ value, label }))));
                    }, []);
                    return (_jsx(Select, { value: filterValue, options: options, isSearchable: true, isClearable: true, onChange: (newValue) => setFilter(newValue?.value) }));
                }
            },
            Cell: ({ value: account }) => {
                if (!account) {
                    return '-';
                }
                return _jsxs(_Fragment, { children: [account.name, " [#", account.id, "]"] });
            }
        },
    },
}));
export const AuditLogDataTable = Object.assign(BaseTable, {
    use({ listAuditLog, getActiveCustomers }) {
        return AuditLogDataTable.useTable({
            getActiveCustomers
        }, {
            getData: async ({ request }) => listAuditLog(request)
        });
    }
});
