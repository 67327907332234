import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Button, ButtonToolbar, Div, FlexCol, FlexRow, Icon, Select, Text } from "Components/Atoms";
import { translate } from "Services/Translator";
import { CalculationDistributionConsumptionTable, CalculationDistributionFixedTable } from "../../../table";
import { DataTable } from "@f2w/data-table";
import { FixCostModal } from "./FixCostModal";
import { formatMoney } from "Services/NumberHelper";
import { useModalDispatcherConfirm } from "Components/Dispatcher";
export const ConsumptionDistributionTable = ({ accountDistributions, getDistributionKeysForProperty, updateDistributionKey, loadData, overviewData, setEditMode, calculationId, createFixCostDistribution, createCommonAreaDistribution, saveMeters, onDeleteFixCost, onDeleteCommonArea, }) => {
    const consumptionAccount = accountDistributions.consumption;
    const commonAreaAccount = accountDistributions.common_area;
    const fixedCostAccount = accountDistributions.fixed_cost;
    const [fixCostModalOpen, setFixCostModalOpen] = React.useState(false);
    const [distributionKeys, setDistributionKeys] = React.useState([]);
    const onDeleteFixCostConfirmation = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: () => onDeleteFixCost(calculationId, accountDistributions.accountId),
        message: translate('consumptionTable.fixCostDistribution.remove.message'),
        help: translate('consumptionTable.fixCostDistribution.remove.help'),
        btnConfirm: translate('btn.confirm')
    });
    const onDeleteCommonAreaConfirmation = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: () => onDeleteCommonArea(calculationId, accountDistributions.accountId),
        message: translate('consumptionTable.commonAreaDistribution.remove.message'),
        help: translate('consumptionTable.commonAreaDistribution.remove.help'),
        btnConfirm: translate('btn.confirm')
    });
    const [hasCommonArea, commonAreaMeterDistributionKey, commonAreaPositions, commonAreaAmount] = React.useMemo(() => {
        return [
            !!commonAreaAccount,
            commonAreaAccount?.propertyDistributionKeyId,
            commonAreaAccount?.positions ?? [],
            commonAreaAccount?.totalCost ?? 0.0
        ];
    }, [commonAreaAccount]);
    const [hasFixCost, fixCostAmount, fixCostPositions, selectedDistributionKey] = React.useMemo(() => {
        return [
            !!fixedCostAccount,
            fixedCostAccount?.totalCost ?? 0.0,
            fixedCostAccount?.positions ?? [],
            fixedCostAccount?.propertyDistributionKeyId
        ];
    }, [fixedCostAccount]);
    const consumptionTable = CalculationDistributionConsumptionTable.use({
        loadData,
        overviewData,
        data: consumptionAccount?.positions ?? [],
        calculationId,
        saveMeters,
        ancillaryAccountId: accountDistributions.accountId
    });
    const fixCostTable = CalculationDistributionFixedTable.use({
        loadData,
        overviewData,
        distributionAccount: fixedCostAccount,
        data: fixCostPositions,
        updateDistributionKey: updateDistributionKey,
        type: 'fixed_cost'
    });
    const commonAreaTable = CalculationDistributionFixedTable.use({
        loadData,
        overviewData,
        distributionAccount: fixedCostAccount,
        data: commonAreaPositions,
        updateDistributionKey: updateDistributionKey,
        type: 'common_area'
    });
    const createCommonArea = React.useCallback(() => {
        createCommonAreaDistribution(calculationId, { accountId: accountDistributions.accountId, amount: 0 })
            .then(loadData);
    }, [calculationId, accountDistributions.accountId]);
    React.useEffect(() => {
        getDistributionKeysForProperty()
            .then(response => setDistributionKeys(response.map(({ id, title }) => ({ label: title, value: id }))));
    }, []);
    const disableCreationButtons = consumptionTable.editMode || hasCommonArea || hasFixCost;
    return (_jsxs(_Fragment, { children: [_jsxs(FlexRow, { gap: 20, alignCenter: true, pt: 20, justifySpaceBetween: true, children: [_jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsx(Text, { large: 300, style: { margin: 0 }, children: translate('consumptionTable.title') }), _jsx(Text, { primary: true, children: formatMoney(consumptionAccount.totalCost) })] }), consumptionTable.editMode
                        ? (_jsxs(ButtonToolbar, { children: [_jsx(Button, { color: 'error', variant: 'outline', style: { border: 'none' }, onClick: () => {
                                        consumptionTable.updateApi.resetAll();
                                        consumptionTable.setEditMode(false);
                                        setEditMode(false);
                                    }, children: translate('btn.cancel') }), _jsx(Button, { onClick: async () => {
                                        await consumptionTable.onSave(consumptionTable);
                                        setEditMode(false);
                                    }, children: translate('btn.save') })] }))
                        : (_jsxs(ButtonToolbar, { children: [_jsxs(Button, { variant: 'outline', style: { border: 'none' }, onClick: () => setFixCostModalOpen(true), disabled: disableCreationButtons, children: [_jsx(Icon, { icon: 'plus' }), " ", translate('consumptionTable.fixCostDistribution.add')] }), _jsxs(Button, { variant: 'outline', style: { border: 'none' }, onClick: createCommonArea, disabled: disableCreationButtons, children: [_jsx(Icon, { icon: 'plus' }), " ", translate('consumptionTable.commonAreaDistribution.add')] }), _jsx(Button, { onClick: () => {
                                        consumptionTable.setEditMode(true);
                                        setEditMode(true);
                                    }, children: translate('btn.edit') })] }))] }), _jsx(DataTable.SimpleTable, { filter: 'inline', instance: consumptionTable }), hasFixCost && (_jsxs(_Fragment, { children: [_jsxs(FlexRow, { justifySpaceBetween: true, alignCenter: true, children: [_jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsx(Text, { large: 300, style: { margin: 0 }, children: translate('consumptionTable.fixCost.title') }), _jsx(Text, { primary: true, children: formatMoney(fixCostAmount) }), _jsx(Button, { style: { border: 'none', padding: 0 }, variant: 'outline', onClick: () => setFixCostModalOpen(true), children: _jsx(Icon, { icon: 'edit' }) })] }), _jsxs(FlexRow, { gap: 10, children: [_jsx(Div, { w: 200, children: _jsx(Select, { ...{
                                                isLoading: fixCostTable.isLoading,
                                                isDisabled: fixCostTable.isLoading,
                                                options: distributionKeys,
                                                value: selectedDistributionKey,
                                                onChange: (opt) => {
                                                    if (opt?.value)
                                                        fixCostTable.updateDistributionKey(opt?.value);
                                                },
                                            } }) }), _jsx(Button, { onClick: () => onDeleteFixCostConfirmation(), children: translate('consumptionTable.fixCostDistribution.remove.btn') })] })] }), _jsx(DataTable.SimpleTable, { filter: 'inline', instance: fixCostTable })] })), hasCommonArea && (_jsxs(FlexCol, { gap: 20, pt: 20, children: [_jsxs(FlexRow, { justifySpaceBetween: true, alignCenter: true, children: [_jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsx(Text, { large: 300, style: { margin: 0 }, children: translate('consumptionTable.commonArea') }), _jsx(Text, { primary: true, children: formatMoney(commonAreaAmount) })] }), _jsxs(FlexRow, { gap: 10, children: [_jsx(Div, { w: 200, children: _jsx(Select, { ...{
                                                isLoading: commonAreaTable.isLoading,
                                                isDisabled: commonAreaTable.isLoading,
                                                options: distributionKeys,
                                                value: commonAreaMeterDistributionKey,
                                                onChange: (opt) => {
                                                    if (opt?.value)
                                                        commonAreaTable.updateDistributionKey(opt?.value);
                                                },
                                            } }) }), _jsx(Button, { onClick: () => onDeleteCommonAreaConfirmation(), children: translate('consumptionTable.commonAreaDistribution.remove.btn') })] })] }), _jsx(DataTable.SimpleTable, { filter: 'inline', instance: commonAreaTable })] })), fixCostModalOpen && (_jsx(FixCostModal, { distributionAmount: consumptionAccount.totalCost + consumptionAccount.distributableFixedCost, previousAmount: fixCostAmount, onClose: () => {
                    setFixCostModalOpen(false);
                    consumptionTable.refresh();
                }, accountId: accountDistributions.accountId, calculationId: calculationId, createFixCostDistribution: createFixCostDistribution }))] }));
};
