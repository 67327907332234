import { useTheme } from "@f2w/theme";
import styled, { css } from "styled-components";
const FormErrorMessage = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        color: ${palette.colors.error.$500};
        margin: ${rem(10)} 0;
    `;
});
export default FormErrorMessage;
