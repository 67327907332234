import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { DataTableProvider, useDataTableContext } from '../DataTableProvider';
import { Table, Tbody, Td, Tfoot, Th, Thead, Tr } from 'Atoms/Table';
import { Icon } from 'Atoms/Icon';
import { FlexRow } from 'Atoms/Layout';
import { translate } from 'Services/Translator';
import styled from 'styled-components';
import mergeProps from 'merge-props';
const ButtonSortRoot = styled.span `
  margin-left: 5px;
  width: 24px;
  text-align: center;
  pointer-events: none;
`;
const ButtonSort = ({ column }) => {
    return _jsx(ButtonSortRoot, { children: _jsx(Icon, { size: 16, icon: column.isSorted ? (column.isSortedDesc ? "sortDesc" : "sortAsc") : "sort" }) });
};
const ButtonGroupBy = ({ column }) => {
    return _jsx(ButtonSortRoot, { ...column.getGroupByToggleProps(), children: _jsx(Icon, { icon: column.isGrouped ? 'minus-circle' : 'plus-circle' }) });
};
const BaseDataTable = ({ instance = useDataTableContext(), hideHeader = false, children: _children, options, hideEmpty = instance?.hideEmpty, EmptyContent = instance?.EmptyContent, ...rest }) => {
    const { isLoaded, isLoading, _render, } = instance;
    if ((!isLoaded) && hideEmpty || EmptyContent) {
        return _render(EmptyContent);
    }
    const { getTableBodyProps, getTableProps, visibleColumns, pageRows, dropzone, EmptyRow, Footer, } = instance;
    const props = {
        variant: 'content',
        EmptyRow: () => _render(EmptyRow) ?? (isLoading ? '' : translate('table.emptyData')),
        ...getTableProps(),
        ...options,
        ...rest,
        isLoading,
        dropzone,
        colSpan: visibleColumns.length,
    };
    const children = _jsxs(Table, { ...props, children: [hideHeader ? null : _jsx(Thead, { children: _jsx(Tr, { role: 'row', children: visibleColumns.map((column) => renderHeader({ column, instance })) }) }), _jsx(Tbody, { ...getTableBodyProps(), children: pageRows.map((row, index, rows) => renderRow({
                    row,
                    instance,
                    meta: { index, rows },
                })) }), Footer && _jsx(Tfoot, { children: _render(Footer) })] });
    if (_children)
        return _children({ instance, children });
    return children;
};
const renderRow = ({ row, instance, isSubTable, meta = { index: 0, rows: [row] }, }) => {
    instance.prepareRow(row);
    const { key, ...rowProps } = row.getRowProps();
    const cells = isSubTable ? row.allCells.filter(({ column: c }) => (c.isSubTable || c.type === 'actions')) : row.cells;
    const expandableRows = () => {
        if (row.isExpanded) {
            if (instance.renderSubRow)
                return (_jsx(Tr, { children: instance.renderSubRow({ row, instance }) }, `${key}-subrow`));
            if (row.subRows?.length) {
                const subRows = row.subRows;
                if (instance.subTable) {
                    const headers = instance.allColumns.filter(c => (c.isSubTable || c.type === 'actions')).map((column) => renderHeader({
                        column,
                        instance,
                        isSubTable: true
                    }));
                    return (_jsx(Tr, { children: _jsxs(Table, { colSpan: headers.length, children: [_jsx(Thead, { children: _jsx(Tr, { role: 'row', children: headers }) }), _jsx(Tbody, { ...instance.getTableBodyProps(), children: subRows.map((row, index, rows) => renderRow({
                                        row,
                                        isSubTable: true,
                                        instance,
                                        meta: { index, rows },
                                    })) })] }) }, `${key}-subrow`));
                }
                return subRows.map((row, index, rows) => (renderRow({ row, instance, meta: { index, rows } })));
            }
        }
        return [];
    };
    const children = (_jsx(Tr, { ...rowProps, role: "row", expanded: row.isExpanded, expandableRows: expandableRows, selected: row.isSelected, children: cells.map((cell, index, cells) => renderCell({
            row,
            cell,
            instance,
            meta: { cells, index }
        })) }));
    return _jsx(React.Fragment, { children: instance.renderRow ? instance.renderRow({ row, instance, children }) : children }, key);
};
const renderCell = ({ row, cell, instance: { hidePlaceholder }, meta = {
    cells: [cell],
    index: 0
} }) => {
    if (cell.isPlaceholder && hidePlaceholder) {
        return null;
    }
    const prev = meta.cells[meta.index - 1];
    let children;
    if (!cell.column.isCustom && cell.isAggregated) {
        children = (cell.column.aggregate) ? cell.render('Aggregated') : null;
    }
    else if (cell.isPlaceholder) {
        children = cell.column.PlaceholderCell ? cell.render('PlaceholderCell') : null;
    }
    else {
        children = cell.render('Cell');
    }
    return (_jsx(Td, { ...cell.getCellProps(), ...cell.column?.cellProps, colSpan: (prev?.isPlaceholder && hidePlaceholder) ? 2 : undefined, children: children }));
};
const renderHeader = ({ column, isSubTable, }) => {
    const canSort = !column.hideSort && column.canSort;
    const props = mergeProps(column.getHeaderProps(), canSort ? column.getSortByToggleProps() : undefined);
    props.colSpan = props.colSpan > 1 ? props.colSpan : undefined;
    props.title = canSort ? translate('table.sortBy') : '';
    if (column.CustomHeader) {
        return _jsx(Td, { ...props, children: isSubTable ? null : column.render('CustomHeader') });
    }
    const headerValue = column.CustomHeader ? column.render('CustomHeader') : column.render('Header');
    return (_jsx(Th, { ...props, children: _jsxs(FlexRow, { alignItems: "center", wrap: "nowrap", children: [typeof headerValue === 'string' ? _jsx("span", { style: { whiteSpace: "nowrap" }, children: headerValue }) : headerValue, canSort && _jsx(ButtonSort, { column: column }), (!column.hideGroupBy && column.canGroupBy) && _jsx(ButtonGroupBy, { column: column })] }) }));
};
export const DataTable = ({ instance, use, ...props }) => {
    let context = useDataTableContext();
    if (context) {
        return _jsx(BaseDataTable, { ...props, instance: context });
    }
    if (!instance && use) {
        instance = use();
    }
    return (_jsx(DataTableProvider, { instance: instance, children: _jsx(BaseDataTable, { ...props }) }));
};
