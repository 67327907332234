import { isUndefined } from "Utils/types";
import { translate } from "Services/Translator";
export var BankAccountType;
(function (BankAccountType) {
    BankAccountType["RENT"] = "RENT";
    BankAccountType["CREDIT"] = "CREDIT";
    BankAccountType["TENANT"] = "TENANT";
    BankAccountType["DEPOSIT"] = "DEPOSIT";
})(BankAccountType || (BankAccountType = {}));
(function (BankAccountType) {
    BankAccountType.types = [
        BankAccountType.RENT,
        BankAccountType.CREDIT,
        BankAccountType.TENANT,
        BankAccountType.DEPOSIT,
    ];
    function fromUrlParam(type) {
        return BankAccountType[type] || undefined;
    }
    BankAccountType.fromUrlParam = fromUrlParam;
    function map(cb) {
        const map = [];
        for (const type of BankAccountType.types) {
            let val = cb(type);
            isUndefined(val) || map.push(val);
        }
        return map;
    }
    BankAccountType.map = map;
    function toOptions() {
        return map(bankType => ({
            value: bankType,
            label: translate(`bank.type.${bankType.toLowerCase()}`)
        }));
    }
    BankAccountType.toOptions = toOptions;
})(BankAccountType || (BankAccountType = {}));
