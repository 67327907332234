import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
import RentServices from "Pages/Rent/services/rentServices";
import { RenterMirrorDateExportFormType } from "Pages/Rent/forms/RenterMirror/RenterMirrorDateExportForm/RenterMirrorDateExportFormType";
export const useRenterMirrorDateExportForm = () => {
    return useFormModal({
        name: 'renter-mirror-date-export-form',
        type: () => new RenterMirrorDateExportFormType(),
        onLoad: async (presetValues) => {
            return {
                date: presetValues.date ?? new Date(),
                propertyId: presetValues.propertyId ?? null,
                mandateId: presetValues.mandateId ?? null,
                buildingId: presetValues.buildingId ?? null,
                rentalObjectId: presetValues.rentalObjectId ?? null,
                type: presetValues.type,
            };
        },
        title: () => `${translate('rent.exportDateTitle')}`,
        Footer: ({ saveForm, closeForm, form: { loading } }) => {
            return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.cancel') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('btn.download') })] });
        },
        onSave: async (data) => {
            await RentServices.downloadRenterMirrorDateReport(data);
        },
        ignoreConfirm: true,
        successMessage: null,
    });
};
