import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { useAncillaryExpenseAccountForm, useAncillaryExpenseDuplicateAccountsForm } from '../../hooks';
import { AncillaryExpenseAccountsDataTable } from '../../table';
import { AccountServices } from '../../index';
import { FormModal } from '@f2w/form';
import { getScopePropertyId } from "Services/ScopeManager";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { ChoosePresetAccountsModal } from "Pages/AncillaryExpense/components/OverviewPage/components/ChoosePresetAccountsModal";
export const AccountsTab = ({ accountServices = AccountServices, presetAccountServices }) => {
    const portfolio = usePortfolio();
    const { openViewModal } = useObjectViewModalContext();
    const [presetAccountModalOpen, setPresetAccountModalOpen] = React.useState(false);
    const ancillaryExpenseAccountForm = useAncillaryExpenseAccountForm({
        onSaved: () => instance.refresh(),
        accountServices,
    });
    const duplicateAncillaryExpenseAccountForm = useAncillaryExpenseDuplicateAccountsForm({
        onSaved: () => instance.refresh(),
        accountServices,
    });
    const instance = AncillaryExpenseAccountsDataTable.use({
        onPropertyClick: openViewModal(ActiveView.PROPERTY),
        accountServices,
        onCreateAncillaryExpenseAccount: () => ancillaryExpenseAccountForm.openNewForm({ propertyId: getScopePropertyId(portfolio) }),
        onEditAncillaryExpenseAccount: (id) => ancillaryExpenseAccountForm.openForm(id),
        onDuplicateAncillaryExpenseAccount: (propertyId) => duplicateAncillaryExpenseAccountForm.openForm(propertyId),
        onCreatePresetAncillaryExpenseAccount: () => setPresetAccountModalOpen(true)
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { variant: 'content', instance: instance }), _jsx(FormModal, { modal: ancillaryExpenseAccountForm }), _jsx(FormModal, { modal: duplicateAncillaryExpenseAccountForm }), presetAccountModalOpen && (_jsx(ChoosePresetAccountsModal, { getAllPresetAccounts: presetAccountServices.getAllPresetAccounts, createAccountsUsingPresetForProperties: presetAccountServices.createAccountsUsingPresetForProperties, onClose: () => {
                    setPresetAccountModalOpen(false);
                    instance.refresh();
                } }))] }));
};
