import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import { ContentTemplateNew, DetailViewModal, SplitViewTemplate } from '@f2w/view';
import { BankIcon } from 'Components/App/Icons';
import { useBankAccountView } from 'Pages/BankAccount/view/useBankAccountView';
import BankAccountServices from 'Pages/BankAccount/services/bankAccountServices';
import { BankAccountType } from 'Pages/BankAccount/enums';
import { Header } from 'Atoms/Layout/components';
const HeaderTemplate = ({ view: { data: bankAccount, }, }) => (_jsx(Header.Avatar, { avatarText: _jsx(BankIcon, {}), title: bankAccount.label }));
export const BankAccountTemplate = (props) => (_jsx(SplitViewTemplate, { ...props, Template: ContentTemplateNew, leftSide: {
        Header: HeaderTemplate,
        groups: props.view.data.type === BankAccountType.RENT
            ? ['general', 'properties']
            : ['general'],
    }, rightSide: {
        groups: ['connections'],
    } }));
export const BankAccountView = ({ reloadTable, bankAccountId, getBankAccountDetails = BankAccountServices.getBankAccountDetails, updateRentBankAccount = BankAccountServices.updateRentBankAccount, updatePersonBankAccount = BankAccountServices.updatePersonBankAccount, updateCreditBankAccount = BankAccountServices.updateCreditBankAccount, updateBankAccountProperties = BankAccountServices.updateBankAccountProperties, onClose, }) => {
    const bankAccountView = useBankAccountView(reloadTable, getBankAccountDetails, updateRentBankAccount, updatePersonBankAccount, updateCreditBankAccount, updateBankAccountProperties);
    React.useEffect(() => {
        bankAccountView.load({ id: bankAccountId });
    }, []);
    return (_jsx(DetailViewModal, { view: bankAccountView, onClose: onClose, title: () => {
            if (!bankAccountView.data.type) {
                return '';
            }
            return translate(`bank.type.${bankAccountView.data.type.toLowerCase()}`) + ' / ' + bankAccountView.data.ownerName;
        } }));
};
