import { AbstractFormType, MoneyType, SelectType } from "@f2w/form";
import React from "react";
import AssetServices from "Pages/Asset/services/assetServices";
import moment from "moment";
import { translate } from "Services/Translator";
export class LinkAssetToCreditorType extends AbstractFormType {
    creditorInvoices = [];
    _useField(props) {
        super._useField(props);
        const [creditorInvoices, setCreditorInvoices] = React.useState([]);
        this.creditorInvoices = creditorInvoices;
        React.useEffect(() => {
            AssetServices
                .getCreditorsAvailableToAsset(this.field.value.id)
                .then(creditors => {
                setCreditorInvoices(creditors.map(creditor => ({
                    label: `${creditor.label} - ${creditor.invoiceDate ? moment(creditor.invoiceDate).format('DD.MM.YYYY') : ''} - ${MoneyType.format(creditor.amount)}`,
                    value: creditor.id
                })));
            });
        }, []);
    }
    buildFields() {
        return {
            creditorInvoiceId: new SelectType({
                label: translate('creditorInvoices'),
                choiceOptions: () => this.creditorInvoices,
            })
        };
    }
}
