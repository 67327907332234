import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, FormError, FormProvider, useForm } from "@f2w/form";
import { ChangePasswordType } from "Pages/Profile/ChangePassword/ChangePasswordType";
import { FlexCol, FlexRow } from "Atoms/Layout";
import { SubmitButton } from "Pages/Profile/components/SubmitButton";
import React from "react";
import ProfileService from "Pages/Profile/services/ProfileService";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { translate } from "Services/Translator";
export const ChangePasswordForm = () => {
    const [loading, setLoading] = React.useState(false);
    const { error } = useToastDispatcherApi();
    const form = useForm({
        name: 'change-password-form',
        type: () => new ChangePasswordType(),
        onSave: async (values) => {
            try {
                await ProfileService.changePassword(values);
                return Promise.resolve({
                    updateValues: {
                        currentPassword: '',
                        newPassword: {
                            password: '',
                            repeatPassword: '',
                        },
                    }
                });
            }
            catch (requestError) {
                const response = requestError.response;
                if (response.status === 422) {
                    error({
                        timeout: 5,
                        message: response.data.message,
                    });
                    throw new FormError('');
                }
                else {
                    error({
                        timeout: 5,
                        message: translate('generalError.default'),
                    });
                    throw new FormError('generalError.default');
                }
            }
        }
    });
    return (_jsx(FlexCol, { style: { width: 540, marginBottom: 20 }, children: _jsx(FormProvider, { form: form, children: _jsxs(Form, { noValidate: true, children: [form.render(), _jsx(FlexRow, { justifyContent: "flex-end", children: _jsx(SubmitButton, { updateLoadingState: setLoading, form: form, loading: loading }) })] }) }) }));
};
