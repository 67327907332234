import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ModalNew } from 'Components/Modal';
import { Button } from 'Atoms/Button';
import { translate } from 'Services/Translator';
import { Spinner, UploadArea } from 'Components/Atoms';
import { useDispatcherApi } from 'Components/Dispatcher';
import { CalculationServices } from 'Pages/AncillaryExpense';
import { DataTable } from '@f2w/data-table';
import { ImportAccountsTable, } from 'Pages/AncillaryExpense/components/CalculationPage/components/ImportAccountsTable';
export const VKAImportModal = ({ calculationId, onClose: closeModal }) => {
    const { toast: toastApi } = useDispatcherApi();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState();
    const [importAccounts, setImportAccounts] = useState([]);
    const { upload } = useDispatcherApi();
    const onClose = (needsReload = false) => {
        upload.close();
        closeModal(needsReload);
    };
    const onUpload = async (files) => {
        setIsLoading(true);
        const file = files[0];
        try {
            const { accounts } = await CalculationServices.importVkaFile(calculationId, file, true);
            upload.close();
            setImportAccounts(accounts);
            setFile(file);
        }
        catch (error) {
            const errors = error.response?.data?.errors || [translate('generalError.default')];
            const topErrors = errors.slice(0, 5);
            for (const errorMessage of topErrors) {
                toastApi.error({
                    timeout: 5,
                    message: errorMessage,
                });
            }
        }
        finally {
            setIsLoading(false);
        }
    };
    const onImport = async () => {
        upload.close();
        setIsLoading(true);
        try {
            await CalculationServices.importVkaFile(calculationId, file, false);
            toastApi.success({
                timeout: 5,
                message: translate('vka.import.success'),
            });
            onClose(true);
        }
        catch (error) {
            const errors = error.response?.data?.errors || [translate('generalError.default')];
            const topErrors = errors.slice(0, 5);
            for (const errorMessage of topErrors) {
                toastApi.error({
                    timeout: 5,
                    message: errorMessage,
                });
            }
        }
        finally {
            setIsLoading(false);
        }
    };
    const table = ImportAccountsTable.use({
        importAccounts: importAccounts
    });
    const showTable = importAccounts.length > 0;
    return (_jsxs(ModalNew, { show: true, onHide: () => onClose(), size: "form", children: [_jsx(ModalNew.Header, { onHide: () => onClose(), closeButton: true, children: _jsx(ModalNew.Title, { children: translate('vka.import.title') }) }), _jsx(ModalNew.Body, { children: isLoading ?
                    _jsx("div", { children: _jsx(Spinner, { align: "center", isVisible: true }) })
                    : _jsxs(_Fragment, { children: [showTable && _jsx(DataTable.SimpleTable, { instance: table }), !showTable && _jsx(UploadArea, { onUpload: onUpload, maxSize: '2MiB', placeholderInfo: '', isEmpty: true, children: null, accept: ['.xml'] })] }) }), _jsxs(ModalNew.Footer, { children: [_jsx(Button, { link: true, color: 'dark', onClick: () => onClose(), children: translate('btn.cancelEdit') }), _jsx(Button, { disabled: isLoading, onClick: () => onImport(), children: translate('btn.import') })] })] }));
};
