import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from 'Atoms/Layout/components';
import { FlexCol, FlexRow } from 'Components/Atoms';
import { trans, translate } from 'Services/Translator';
import moment from 'moment';
import ProtocolItemsTable from 'Pages/Handover/components/HandoverView/component/ProtocolItemsTable';
import { Card } from 'Atoms/Card';
import { formatPersonName } from '../../../services/HandoverFormatter';
export const GeneralTab = ({ group: { data: handoverProtocol } }) => {
    return (_jsx(Section, { children: _jsxs(FlexCol, { gap: 24, children: [_jsxs(FlexRow, { gap: 24, style: { alignItems: 'flex-start' }, children: [_jsx(FlexCol, { flex: '50%', children: _jsx(Card, { children: _jsx(Card.Value, { title: translate('handovers.detailView.handoverDate'), children: moment(handoverProtocol.handoverDate).format('DD.MM.YYYY HH:mm') }) }) }), _jsx(FlexCol, { flex: '50%', children: _jsx(Card, { children: _jsx(Card.Value, { title: translate('handovers.detailView.manager'), children: formatPersonName(handoverProtocol.manager, trans('handovers.detailView.deletedUser')) }) }) })] }), _jsx(Card, { children: _jsx(Card.Content, { title: translate('handovers.detailView.damagesAndCosts'), children: handoverProtocol.items?.length ? _jsx(ProtocolItemsTable, { items: handoverProtocol.items }) : null }) })] }) }));
};
