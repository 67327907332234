import _router from '../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/js/router';
import { cleanObj } from 'Utils';
const originalRoutes = require('js/routes.json');
const _instance = () => {
    if (!window.oRouter) {
        _router.setRoutingData(originalRoutes);
    }
    return window.oRouter = _router;
};
export const getRouteManager = () => _instance();
export const hasRoute = (route) => {
    try {
        return !!_instance().getRoute(route);
    }
    catch (e) {
        return false;
    }
};
export const setBaseUrl = (baseUrl) => _instance().setBaseUrl(baseUrl);
export const setLocale = (locale) => _instance().setLocale(locale);
export const generate = (...args) => {
    const instance = _instance();
    const props = {
        withScope: true,
        ...(typeof args[0] === 'string' ? {
            route: args.shift(),
            params: cleanObj(args.shift()),
        } : args.shift())
    };
    let url;
    if (props?.locale != null) {
        const prevLocale = instance.getLocale();
        instance.setLocale(props.locale);
        url = instance.generate(props.route, props.params, props.absolute);
        instance.setLocale(prevLocale);
    }
    else {
        url = instance.generate(props.route, props.params, props.absolute);
    }
    if (props.withScope) {
        url = Array.isArray(props.withScope) ? withScope(url, ...props.withScope) : withScope(url);
    }
    return url;
};
export function withScope(...args) {
    const url = new URL(args.shift(), window.location.origin);
    const scope = (args.length ? args : _getScope());
    if (scope.length === 2 && scope[0] !== 'portfolio')
        url.searchParams.set('_for', scope.join('-'));
    else
        url.searchParams.delete('_for');
    return url.toString();
}
const _getScope = (href = window.location.href, origin = window.location.origin) => ((new URL(href, origin)).searchParams.get('_for') || 'portfolio-0').split('-');
