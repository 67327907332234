import { jsx as _jsx } from "react/jsx-runtime";
import qsa from 'dom-helpers/querySelectorAll';
import React, { useContext, useEffect, useRef } from 'react';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import NavContext from './NavContext';
import SelectableContext, { makeEventKey } from './SelectableContext';
import TabContext from './TabContext';
const noop = () => { };
const AbstractNav = React.forwardRef(({ as: Component = 'ul', onSelect, activeKey, role, onKeyDown, ...props }, ref) => {
    const forceUpdate = useForceUpdate();
    const needsRefocusRef = useRef(false);
    const parentOnSelect = useContext(SelectableContext);
    const tabContext = useContext(TabContext);
    let getControlledId, getControllerId;
    if (tabContext) {
        role = role || 'tablist';
        activeKey = tabContext.activeKey;
        getControlledId = tabContext.getControlledId;
        getControllerId = tabContext.getControllerId;
    }
    const listNode = useRef(null);
    const getNextActiveChild = (offset) => {
        const currentListNode = listNode.current;
        if (!currentListNode)
            return null;
        const items = qsa(currentListNode, '[data-rb-event-key]:not(.disabled)');
        const activeChild = currentListNode.querySelector('.active');
        if (!activeChild)
            return null;
        const index = items.indexOf(activeChild);
        if (index === -1)
            return null;
        let nextIndex = index + offset;
        if (nextIndex >= items.length)
            nextIndex = 0;
        if (nextIndex < 0)
            nextIndex = items.length - 1;
        return items[nextIndex];
    };
    const handleSelect = (key, event) => {
        if (key == null)
            return;
        if (onSelect)
            onSelect(key, event);
        if (parentOnSelect)
            parentOnSelect(key, event);
    };
    const handleKeyDown = (event) => {
        if (onKeyDown) {
            const proceed = onKeyDown(event);
            if (proceed === false) {
                return;
            }
        }
        let nextActiveChild;
        switch (event.key) {
            case 'ArrowLeft':
            case 'ArrowUp':
                nextActiveChild = getNextActiveChild(-1);
                break;
            case 'ArrowRight':
            case 'ArrowDown':
                nextActiveChild = getNextActiveChild(1);
                break;
            default:
                return;
        }
        if (!nextActiveChild)
            return;
        event.preventDefault();
        handleSelect(nextActiveChild.dataset.rbEventKey, event);
        needsRefocusRef.current = true;
        forceUpdate();
    };
    useEffect(() => {
        if (listNode.current && needsRefocusRef.current) {
            const activeChild = listNode.current.querySelector('[data-rb-event-key].active');
            if (activeChild)
                activeChild.focus();
        }
        needsRefocusRef.current = false;
    });
    const mergedRef = useMergedRefs(ref, listNode);
    return (_jsx(SelectableContext.Provider, { value: handleSelect, children: _jsx(NavContext.Provider, { value: {
                role,
                activeKey: makeEventKey(activeKey),
                getControlledId: getControlledId || noop,
                getControllerId: getControllerId || noop,
            }, children: _jsx(Component, { ...props, onKeyDown: handleKeyDown, ref: mergedRef, role: role }) }) }));
});
export default AbstractNav;
