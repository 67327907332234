import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import FormikPatchTouched from "Components/FormikPatchTouched";
import ActiveFormContextHandler from "./../ActiveFormContextHandler";
import { TaskPriority } from "Types/Task";
import FormInput from "Components/Atoms/Form/FormInput";
import FormErrorMessage from "Components/Atoms/Form/FormErrorMessage";
import { useTheme } from "@f2w/theme";
import styled, { css } from "styled-components";
import { translate } from "Services/Translator";
import TaskPrioritySelect from "Pages/Task/TaskForm/TaskPrioritySelect";
import FormTextArea from "Components/Atoms/Form/FormTextArea";
import FormDatePicker from "Components/Atoms/Form/FormDatePicker";
import FormSelect from "Components/Atoms/Form/FormSelect";
import UserSelect from "Components/App/UserSelect";
import { getCurrentUser } from "Services/UserServices";
const validationSchema = Yup.object().shape({
    title: Yup.string()
        .max(250, 'Too Long!')
        .required('Title is required'),
    description: Yup.string()
        .max(1000, 'Too Long!'),
    priority: Yup.mixed()
        .required()
        .oneOf([null, ...Object.values(TaskPriority)]),
});
const FormBody = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        color: ${palette.dark.$900};
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.02em;
    `;
});
const Label = styled.label(() => {
    const { rem, palette } = useTheme();
    return css `
        margin-bottom: ${rem(12)};
    `;
});
const FieldGroup = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
      margin-bottom: ${rem(32)};
    `;
});
const TaskForm = ({ task, users, onSubmit }) => {
    let init = task || {
        id: null,
        title: "",
        priority: TaskPriority.NORMAL,
        dueTo: null,
        description: "",
        assignee: getCurrentUser()
    };
    init.assignee = init.assignee ? init.assignee.id : null;
    return (_jsx(Formik, { initialValues: init, validationSchema: validationSchema, onSubmit: (task, { resetForm }) => {
            const submittedTask = { ...task };
            return onSubmit(submittedTask, { resetForm });
        }, children: ({ values }) => {
            return (_jsxs(Form, { children: [_jsx(ActiveFormContextHandler, {}), _jsx(FormikPatchTouched, {}), _jsxs(FormBody, { children: [_jsxs(FieldGroup, { children: [_jsx(Label, { htmlFor: "taskTitle", children: translate('task.field.title') }), _jsx(Field, { component: FormInput, name: "title", id: "taskTitle" }), _jsx(ErrorMessage, { component: FormErrorMessage, name: "title" })] }), _jsxs(FieldGroup, { children: [_jsx(Label, { htmlFor: "taskDescription", children: translate('task.field.description') }), _jsx(Field, { component: FormTextArea, rows: 5, name: "description", id: "taskDescription" }), _jsx(ErrorMessage, { component: FormErrorMessage, name: "description" })] }), _jsxs(FieldGroup, { children: [_jsx(Label, { htmlFor: "taskPriority", children: translate('task.field.priority') }), _jsx(Field, { component: FormSelect, select: TaskPrioritySelect, name: "priority", id: "taskPriority" }), _jsx(ErrorMessage, { component: FormErrorMessage, name: "priority" })] }), _jsxs(FieldGroup, { children: [_jsx(Label, { htmlFor: "taskDueTo", children: translate('task.field.dueTo') }), _jsx("div", { children: _jsx(Field, { component: FormDatePicker, name: "dueTo", id: "taskDueTo" }) }), _jsx(ErrorMessage, { component: FormErrorMessage, name: "dueTo" })] }), _jsxs(FieldGroup, { children: [_jsx(Label, { htmlFor: "taskAssignee", children: translate('task.field.assignee') }), _jsx("div", { children: _jsx(Field, { component: FormSelect, select: UserSelect, users: users, name: "assignee", id: "taskAssignee" }) }), _jsx(ErrorMessage, { component: FormErrorMessage, name: "assignee" })] })] })] }));
        } }));
};
export default TaskForm;
