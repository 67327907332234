import { useEffect, useState } from 'react';
const _window = typeof window === 'undefined' ? undefined : window;
export var StorageEvent;
(function (StorageEvent) {
    StorageEvent["CHANGE"] = "localStorage.change";
    StorageEvent["READ"] = "localStorage.read";
    StorageEvent["WRITE"] = "localStorage.write";
})(StorageEvent || (StorageEvent = {}));
export class LocalStorageManager {
    _validate;
    static EventName = 'localStorageChange';
    cache = Object.create(null);
    events = _window && new _window.EventTarget();
    key;
    defaultValue;
    constructor(key, _validate, options) {
        this._validate = _validate;
        this.key = `${options?.prefix || ''}${key}`;
        this.defaultValue = _validate(options?.defaultValue);
        _window?.addEventListener(LocalStorageManager.EventName, this._localListener);
        _window?.addEventListener('storage', this._multiTabListener);
    }
    _read() {
        let value = _window?.localStorage?.getItem(this.key);
        if (value == null)
            return this.defaultValue;
        try {
            return this._validate(JSON.parse(value));
        }
        catch (_) {
            return this.defaultValue;
        }
    }
    _write(value = this.cache.value) {
        _window?.localStorage?.setItem(this.key, JSON.stringify(value));
    }
    get() {
        if (!('value' in this.cache)) {
            this.cache.value = this._read();
        }
        return this.cache.value;
    }
    set(data) {
        const newValue = data instanceof Function ? data(this.get()) : data;
        this._write();
        this.cache.value = newValue;
        this._dispatchChange();
    }
    remove() {
        _window?.localStorage.removeItem(this.key);
        this.cache.value = this.defaultValue;
        this._dispatchChange();
    }
    watch(listener) {
        const wrappedListener = () => listener(this.get());
        this.events?.addEventListener(LocalStorageManager.EventName, wrappedListener);
        return () => {
            this.events?.removeEventListener(LocalStorageManager.EventName, wrappedListener);
        };
    }
    use() {
        const [value, setValue] = useState(this.get);
        useEffect(() => this.watch(setValue), []);
        return value;
    }
    destroy() {
        _window?.removeEventListener(LocalStorageManager.EventName, this._localListener);
        _window?.removeEventListener('storage', this._multiTabListener);
    }
    _createLocalEvent() {
        return new CustomEvent(LocalStorageManager.EventName, {
            detail: this.cache,
        });
    }
    _dispatchChange() {
        _window?.dispatchEvent(this._createLocalEvent());
    }
    _localListener = (event) => {
        const customEvent = event;
        if ('value' in customEvent.detail) {
            this.cache.value = customEvent.detail.value;
        }
        else {
            delete this.cache.value;
        }
        this.events?.dispatchEvent(this._createLocalEvent());
    };
    _multiTabListener = () => {
        delete this.cache.value;
        this.events?.dispatchEvent(this._createLocalEvent());
    };
}
