import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Bar, BarChart as BaseBarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { useTheme } from '@f2w/theme';
import { ChartTooltip } from '../components/ChartTooltip';
import { Text } from 'Atoms/Text';
import { formatMoney, formatPercentage } from 'Services/NumberHelper';
import styled from 'styled-components';
import classNames from 'classnames';
import { BarChartOffset } from './BarChart';
import { translate } from "Services/Translator";
export const StackedBarChart = ({ data, barColor: _barColor = 'primary', config, className, offset = BarChartOffset, }) => {
    const { palette } = useTheme();
    const color = palette[_barColor] ?? palette.primary;
    const [current, setCurrent] = React.useState({});
    const barSize = 30;
    const isActive = useCallback((o) => (o.index === current.index && o.stackIndex === current.stackIndex), [current]);
    const meta = {
        ...current,
        activeData: data?.[current.index] ?? null,
        activeStack: config.stacks?.[current.stackIndex] ?? null,
    };
    const renderBars = () => config.stacks.map(({ name, ...props }, stackIndex) => {
        return _jsx(Bar, { dataKey: `stacks[${stackIndex}]`, name: name, stackId: "a", className: stackIndex === current.stackIndex ? 'fw-bar--selected' : '', onMouseEnter: (bar, index) => setCurrent({ stackIndex, index }), onMouseLeave: () => setCurrent({}), children: data.map((entry, index) => {
                const active = isActive({ stackIndex, index });
                return _jsx(Cell, { cursor: "pointer", fill: active ? color.$800 : color.$500, stroke: palette.white, strokeWidth: 1 }, `cell-${index}`);
            }) }, `bar-${stackIndex}`);
    });
    return (_jsx(ResponsiveContainer, { width: "100%", height: "100%", minHeight: 340, className: classNames(className), children: _jsxs(BaseBarChart, { data: data, margin: {
                ...BarChartOffset.margin,
                ...offset?.margin,
            }, maxBarSize: barSize, children: [_jsx(CartesianGrid, { stroke: palette.neutral.$100, fill: palette.white, vertical: false }), _jsx(XAxis, { dataKey: "name", dy: 5, tickMargin: 2, padding: {
                        ...BarChartOffset.padding,
                        ...offset?.padding,
                    }, minTickGap: -150, axisLine: false, angle: -45, textAnchor: 'end' }), _jsx(Tooltip, { ...{
                        config,
                        meta,
                        isAnimationActive: false,
                        offset: 20,
                        cursor: { fill: 'transparent' },
                        wrapperStyle: { outline: 'none', border: 'none' },
                        content: TooltipContent,
                    } }), renderBars()] }) }));
};
const Root = styled.div `
  .fw-bar--selected {
    z-index: 1000;
  }
`;
const TooltipContent = ({ meta: { stackIndex, activeStack, activeData, }, }) => {
    const value = activeData?.stacks[stackIndex] ?? 0;
    return _jsx(ChartTooltip.Root, { active: !!activeStack, children: activeStack && _jsxs(_Fragment, { children: [_jsx(Text, { pretitle: true, neutral: 200, children: activeStack.name }), _jsxs(ChartTooltip.Content, { children: [_jsx("div", { children: _jsx(Text, { pretitle: true, neutral: 200, children: formatMoney(activeStack.label) }) }), _jsx(Text, { body: 500, children: formatMoney(value) }), !!activeStack.effectiveInterest &&
                            _jsxs(Text, { small: true, neutral: 100, style: { paddingLeft: 4 }, children: [" ", translate('at'), " ", formatPercentage(activeStack.effectiveInterest, '0.0[00]%')] })] })] }) });
};
