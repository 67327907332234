import { inObject, isUndefined, objectKeys } from '@f2w/utils';
import { toString } from 'lodash';
export class BaseState {
    _userConfig;
    _own = Object.create(null);
    props = Object.create(null);
    get key() {
        return this.props.key ?? '';
    }
    get parent() {
        return this.props.parent;
    }
    get value() {
        return this.data.value;
    }
    constructor(config) {
        this._userConfig = config;
    }
    init(props) {
        this._updateProps(props);
    }
    get parentManager() {
        return this.props.parent?.['_state'];
    }
    updateParent(keys, ignoredKeys) {
        return this.parentManager?.updateChildren({
            childrenOrKeys: [this.key],
            keys: keys,
            ignoredKeys,
        });
    }
    _self() {
        return this._userConfig.getSelf();
    }
    _type() {
        return this._self().specs;
    }
    _cast(v, ignoreDefaults) {
        return this._type().cast(v, { ignoreDefaults });
    }
    _dispatchUpdate(p) {
        this._userConfig.getSelf()['_handleUpdate2'](p);
    }
    _updateProps(props) {
        return objectKeys({ ...props }).filter(key => {
            if (!isUndefined(props[key]) && this.props[key] !== props[key]) {
                this.props[key] = props[key];
                return true;
            }
        });
    }
    static createDe(c) {
        return c;
    }
}
export class UpdateQueue {
    _items = [];
    _props;
    constructor(cb) {
        this._props = {
            callback: cb
        };
    }
    static create(cb) {
        return new UpdateQueue(cb);
    }
    get length() {
        return this._items.length;
    }
    all() {
        return this._items.slice(0);
    }
    keys() {
        return this._items.map(v => v.propName);
    }
    push(...args) {
        this._items.push(...args);
        return this;
    }
    prepend(...args) {
        this._items.unshift(...args);
        return this;
    }
    flush() {
        if (this._flush())
            this.flush();
        return this;
    }
    _flush() {
        if (this._items.length && false !== this._props.callback(this._items[0])) {
            this._items.shift();
            return true;
        }
    }
}
export var KeyOrChild;
(function (KeyOrChild) {
    function key(childOrKey) {
        return inObject(childOrKey, 'key') ? childOrKey.key : toString(childOrKey);
    }
    KeyOrChild.key = key;
})(KeyOrChild || (KeyOrChild = {}));
