import clone from 'lodash/clone';
import toPath from 'lodash/toPath';
const isInteger = (obj) => String(Math.floor(Number(obj))) === obj;
const isObject = (obj) => obj !== null && typeof obj === 'object';
export function getIn(obj, key, def, p = 0) {
    const path = toPath(key);
    while (obj && p < path.length) {
        obj = obj[path[p++]];
    }
    return obj === undefined ? def : obj;
}
export function setIn(obj, path, value) {
    let res = clone(obj);
    let resVal = res;
    let i = 0;
    let pathArray = toPath(path);
    for (; i < pathArray.length - 1; i++) {
        const currentPath = pathArray[i];
        let currentObj = getIn(obj, pathArray.slice(0, i + 1));
        if (currentObj && (isObject(currentObj) || Array.isArray(currentObj))) {
            resVal = resVal[currentPath] = clone(currentObj);
        }
        else {
            const nextPath = pathArray[i + 1];
            resVal = resVal[currentPath] =
                isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
        }
    }
    if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
        return obj;
    }
    if (value === undefined) {
        delete resVal[pathArray[i]];
    }
    else {
        resVal[pathArray[i]] = value;
    }
    if (i === 0 && value === undefined) {
        delete res[pathArray[i]];
    }
    return res;
}
