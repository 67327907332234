import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
import RentServices from "Pages/Rent/services/rentServices";
import { RenterMirrorDateRangeExportFormType } from "Pages/Rent/forms/RenterMirror/RenterMirrorDateRangeExportForm/RenterMirrorDateRangeExportFormType";
import moment from "moment";
export const useRenterMirrorDateRangeExportForm = () => {
    return useFormModal({
        name: 'renter-mirror-date-range-export-form',
        type: () => new RenterMirrorDateRangeExportFormType(),
        onLoad: async (presetValues) => {
            return {
                startDate: presetValues.startDate ?? moment().startOf('year').toDate(),
                endDate: presetValues.endDate ?? moment().endOf('year').toDate(),
                mandateId: presetValues.mandateId ?? null,
                propertyId: presetValues.propertyId ?? null,
                buildingId: presetValues.buildingId ?? null,
                rentalObjectId: presetValues.rentalObjectId ?? null,
                type: presetValues.type,
            };
        },
        title: () => `${translate('rent.exportDateRangeTitle')}`,
        Footer: ({ saveForm, closeForm, form: { loading } }) => {
            return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.cancel') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('btn.download') })] });
        },
        onSave: async (data) => {
            await RentServices.downloadRenterMirrorDateRangeReport(data);
        },
        ignoreConfirm: true,
        successMessage: null,
    });
};
