import moment from "moment";
import { gbToBytes } from "@f2w/utils";
export const calcExtraLimits = (oldLimits, limits) => {
    return {
        nbRentalObjects: Math.max(0, limits.nbRentalObjects - oldLimits.nbRentalObjects),
        nbUsers: Math.max(0, limits.nbUsers - oldLimits.nbUsers),
        dmsLimitInBytes: Math.max(0, limits.dmsLimitInBytes - oldLimits.dmsLimitInBytes),
    };
};
export const calcTotalIncrement = (totalQuantity, increment) => {
    const nbIncrements = Math.ceil(totalQuantity / increment.quantity);
    const billableQuantity = nbIncrements * increment.quantity;
    const totalPrice = nbIncrements * increment.price;
    return {
        quantity: billableQuantity,
        price: totalPrice
    };
};
export const calcTotalIncrements = (extras, priceIncrements) => {
    let increments = { dmsLimitInBytes: undefined, nbRentalObjects: undefined };
    const extrasKeys = Object.keys(extras);
    for (const key of extrasKeys) {
        const priceIncrement = priceIncrements[key];
        if (!priceIncrement) {
            continue;
        }
        increments[key] = calcTotalIncrement(extras[key], priceIncrement);
    }
    return increments;
};
export const calcSubtotalPrice = (subscriptionPlanPrice, totalPriceIncrements) => {
    return subscriptionPlanPrice + Object.keys(totalPriceIncrements).reduce((accumulator, key) => accumulator + totalPriceIncrements[key].price, 0);
};
export const calcTaxes = (subTotal) => {
    return (subTotal * getVatPercent()) / 100;
};
export const calcSubtotalPriceForSubscriptionPlan = (subscriptionPlan, limits) => {
    const extraLimits = calcExtraLimits(subscriptionPlan.limits, limits);
    const totalPriceIncrements = calcTotalIncrements(extraLimits, getDefaultPriceIncrements());
    return calcSubtotalPrice(subscriptionPlan.price, totalPriceIncrements);
};
const vatPercent77 = 7.7;
const vatPercent81 = 8.1;
const defaultPriceIncrements = {
    nbRentalObjects: {
        quantity: 100,
        price: 720,
    },
    dmsLimitInBytes: {
        quantity: gbToBytes(50),
        price: 118.8,
    },
};
export const getVatPercent = () => {
    const today = moment();
    if (today.isSameOrAfter(moment("01.01.2024"))) {
        return vatPercent81;
    }
    return vatPercent77;
};
export const getDefaultPriceIncrements = () => {
    return defaultPriceIncrements;
};
