import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Text } from 'Atoms/Text';
import { formatAmount } from 'Services/NumberHelper';
export var RentalObjectStyled;
(function (RentalObjectStyled) {
    RentalObjectStyled.DualAmount = (props) => {
        const { amount, amountTotal, color } = props;
        const colorName = useMemo(() => {
            if (!color || amountTotal == null)
                return undefined;
            const amountDiffPercentage = (amount / amountTotal);
            if (amountDiffPercentage > 0.2 && amountDiffPercentage < 0.8) {
                return 'warning.$500';
            }
            else if (amountDiffPercentage > 0.8) {
                return 'error.$500';
            }
            return 'success.$500';
        }, [amount, amountTotal, color]);
        return _jsxs(Text, { body: 500, children: ["CHF ", _jsx(Text, { colorName: colorName, children: formatAmount(props.amount) }), amountTotal == null ? null : _jsxs(Text, { small: 400, children: [" / ", formatAmount(props.amountTotal)] })] });
    };
})(RentalObjectStyled || (RentalObjectStyled = {}));
export default RentalObjectStyled;
