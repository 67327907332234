import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { useAccountingRecordJournalTable } from './hooks/useAccountingRecordJournalTable';
import AccountingRecordServices from './services/accountingRecordServices';
import useAccountingRecordForm from 'Pages/Accounting/AccountingRecord/hooks/useAccountingRecordForm';
import { FormModal } from '@f2w/form';
export const AccountingRecordJournalPage = ({ propertyId, initialDateRange, fiscalYearId, accountingRecordServices, searchQuery, }) => {
    const { instance } = useAccountingRecordJournalTable(fiscalYearId, initialDateRange, () => accountingRecordForm.openForm(), (id) => { accountingRecordForm.openForm(id); }, accountingRecordServices, searchQuery);
    const createAccountingRecord = (upsertDto) => {
        return accountingRecordServices.createAccountingRecord(upsertDto);
    };
    const accountingRecordForm = useAccountingRecordForm({
        propertyId,
        onSave: () => onSave(),
        createAccountingRecord: createAccountingRecord,
        updateAccountingRecord: AccountingRecordServices.updateAccountingRecord,
        getAccountingRecordById: AccountingRecordServices.getAccountingRecordById,
    });
    const onSave = React.useCallback(() => {
        instance.refresh();
    }, [instance]);
    return (_jsxs("div", { children: [_jsx(PageOverview, { title: null, content: null, showFilters: true, instance: instance, rootStyles: { padding: 0 } }), _jsx(FormModal, { modal: accountingRecordForm })] }));
};
