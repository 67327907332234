import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { AccordionTable, AddressType, ArrayType, BaseFormTypeOld, DateType, FloorType, FormModal, NumberType, SelectType, StringType, } from '@f2w/form-new';
import { useMemo } from 'react';
import { Col, Row } from 'Atoms/Grid';
import { options } from 'Components/Molecules/Floors/options';
import { getPropertyNameFromGroupedData } from '../utils/namingHelpers';
import { Button } from 'Atoms/Button';
import { FlexRow } from 'Atoms/Layout';
import { RentalObjectType, rentalObjectTypeOptions } from 'Enums/RentalObjectType';
import { FloorsRange } from "Pages/BaseData/types/BaseData";
export class CreatePropertyType extends BaseFormTypeOld {
    createValue(props) {
        return this.specs.createValueType(this, props);
    }
    getResolvedValue(value) {
        const { property, buildings } = value;
        return {
            ...property,
            buildings,
        };
    }
    buildFields() {
        return {
            property: new CreatePropertyType.Property({
                label: translate('createProperty.property'),
                inherit: { view: true }
            }),
            buildings: new ArrayType({
                label: translate('createProperty.buildings'),
                actions: { delete: true },
                selectable: {
                    onChange: (typeValue, props) => {
                        CreatePropertyType.updateName(typeValue.root());
                    },
                    getChild: (valueType) => {
                        return valueType.children.rentalObjects;
                    },
                },
                prototype: () => new CreatePropertyType.Building({
                    isDisabled: (v) => !v.parent?.isSelected(v)
                }),
                render: (arrayValue) => {
                    arrayValue.setTheme({ size: 'sm' });
                    return _jsxs(_Fragment, { children: [_jsx(AccordionTable, { arrayValue: arrayValue, Header: (v) => {
                                    return v.value.name;
                                }, Row: (row) => {
                                    return _jsxs(_Fragment, { children: [_jsx(FlexRow, { alignStart: true, gap: 10, children: [
                                                    { value: row.children.egid, style: {} },
                                                    { value: row.children.street, style: {} },
                                                    { value: row.children.houseNumber, style: { width: 90 } },
                                                    { value: row.children.name, style: { flex: 1 } },
                                                    { value: row.children.buildYear, style: { width: 90 } },
                                                    { value: row.children.startOfRenting, style: { width: 150 } },
                                                    { value: row.children.numberOfFloors, style: { width: 120 } },
                                                ].map(({ value: child, ...rest }, i) => {
                                                    return (_jsx("div", { ...rest, children: child.render() }, `${child.id}--table-col`));
                                                }) }), row.children.rentalObjects.render()] });
                                } }), _jsx("div", { children: _jsx(Button, { link: true, color: 'primary', "$size": 'md', onClick: () => arrayValue.push(), label: translate('createProperty.buildings.action.crate'), icon: 'plus-circle' }) })] });
                },
            }),
        };
    }
}
(function (CreatePropertyType) {
    function updateName(root) {
        const buildings = root?.children?.buildings;
        const propertyName = root?.children?.property?.children?.name;
        if (!propertyName || !buildings)
            return;
        if (propertyName.meta.dirty || !buildings?.indexes?.selected.size) {
            return;
        }
        const groupedStreets = buildings
            .mapSelected((building) => {
            if (building.value.street)
                return building.value;
        })
            .reduce((groupedAddresses, building) => ({
            ...groupedAddresses,
            [building.street]: [
                ...(groupedAddresses[building.street] ?? []),
                Number(building.houseNumber),
            ],
        }), {});
        try {
            let newName = getPropertyNameFromGroupedData(groupedStreets);
            propertyName.setValue(newName, true, true);
        }
        catch (e) {
            console.error('Error updating property name', e);
        }
    }
    CreatePropertyType.updateName = updateName;
    class Property extends BaseFormTypeOld {
        __getSpecsProps() {
            return super.__getSpecsProps({
                defaultOptions: () => ({
                    label: translate('createProperty.property'),
                }),
            });
        }
        getResolvedValue(prev) {
            return {
                country: AddressType.CountryCode.CH,
                ...prev,
            };
        }
        buildFields() {
            return {
                egrid: new StringType({
                    label: translate('createProperty.property.egrid'),
                    constraints: {
                        max: 14
                    }
                }),
                name: new StringType({
                    label: translate('createProperty.property.name'),
                    required: true,
                }),
                zip: AddressType.zip({
                    required: true,
                    label: translate('createProperty.property.zip'),
                    getCityValue: (v) => v.parent.get('city'),
                }),
                city: AddressType.city({
                    required: true,
                    label: translate('createProperty.property.city'),
                    getZipValue: (v) => v.parent.get('zip'),
                }),
                country: AddressType.country({
                    required: true,
                    nullable: false,
                    allowed: [AddressType.CountryCode.CH, AddressType.CountryCode.LI],
                    defaultValue: () => AddressType.CountryCode.CH,
                    align: 'right'
                }),
                mandateId: SelectType.number({
                    label: translate('createProperty.property.mandate'),
                    required: true,
                    choices: (v) => useMemo(() => this.getSettings(v).mandates
                        .map(m => ({ value: m.id, label: m.name })), []),
                }),
                managerId: SelectType.number({
                    label: translate('createProperty.property.manager'),
                    required: true,
                    isVisible: (v) => !!v.parent.get('mandateId').value,
                    choices: (v) => {
                        const mandateId = v.parent?.value.mandateId;
                        return useMemo(() => {
                            const managers = this.getSettings(v).managers
                                .filter((m) => m.mandates.includes(mandateId))
                                .map(m => ({ value: m.id, label: m.name }));
                            const managerId = v.value;
                            if (managerId && !managers.find(m => m.value == managerId))
                                v.reset(null, v.meta.touched);
                            return managers;
                        }, [mandateId]);
                    }
                }),
            };
        }
        getSettings(v) {
            return v.root().value.settings;
        }
        _renderWidget({ field }) {
            return _jsxs(_Fragment, { children: [field.renderChildren(['egrid', 'name']), AddressType.render(field.children), field.renderChildren(['mandateId', 'managerId'])] });
        }
    }
    CreatePropertyType.Property = Property;
    class Building extends BaseFormTypeOld {
        __getSpecsProps() {
            return super.__getSpecsProps({
                defaultOptions: () => ({
                    label: translate('createProperty.building'),
                }),
            });
        }
        getResolvedValue(prev) {
            const { uuid, numberOfFloors, bunker, ...data } = prev;
            return {
                bunker: bunker ?? false,
                taxValue: 0,
                numberOfFloors,
                ...data,
            };
        }
        buildFields() {
            return {
                egid: new StringType({
                    label: translate('createProperty.building.egid'),
                    nullable: true,
                }),
                street: AddressType.street({
                    label: translate('createProperty.building.street'),
                    required: true,
                    onChange: (typeValue, meta) => {
                        CreatePropertyType.updateName(typeValue.root());
                    },
                }),
                houseNumber: AddressType.streetNumber({
                    label: translate('createProperty.building.houseNumber'),
                    required: true,
                    onChange: (typeValue, meta) => {
                        CreatePropertyType.updateName(typeValue.root());
                    },
                }),
                name: new StringType({
                    label: translate('createProperty.building.name'),
                    required: true,
                    nullable: false,
                }),
                buildYear: new NumberType({
                    label: translate('createProperty.building.yearOfConstruction'),
                    nullable: true,
                }),
                startOfRenting: new DateType({
                    label: translate('createProperty.building.startOfRenting'),
                    nullable: true,
                }),
                numberOfFloors: new NumberType({
                    label: translate('createProperty.building.numberOfFloors'),
                    nullable: false,
                    required: true,
                    step: 1,
                    min: 0,
                    max: FloorsRange.MAX - FloorsRange.MIN + 1,
                    defaultValue: () => 0,
                }),
                rentalObjects: new ArrayType({
                    label: translate('createProperty.rentalObjects'),
                    prototype: () => new RentalObject({
                        isDisabled: (v) => !v.parent?.isSelected(v)
                    }),
                    actions: { delete: true },
                    selectable: {},
                    render: (arrayValue) => {
                        const modalRef = FormModal.useRef();
                        return _jsxs("div", { children: [_jsx(AccordionTable, { arrayValue: arrayValue, Header: (v) => {
                                        return v.value.name;
                                    }, Row: (row) => {
                                        return _jsx(FlexRow, { alignStart: true, gap: 10, children: [
                                                { value: row.children.type, style: { width: 180 } },
                                                { value: row.children.numberOfRooms, style: { width: 120 } },
                                                { value: row.children.floor, style: { width: 120 } },
                                                { value: row.children.name, style: { flex: 1 } },
                                                { value: row.children.area, style: { width: 90 } },
                                                { value: row.children.ewid, style: { width: 90 } },
                                            ].map(({ value: child, ...rest }, i) => {
                                                return (_jsx("div", { ...rest, children: child.render() }, `${child.id}--table-col`));
                                            }) });
                                    } }), _jsxs(Button.Group, { variant: 'link', color: 'primary', size: 'md', children: [_jsx(Button, { label: translate('createProperty.rentalObjects.action.crate'), icon: 'plus-circle', onClick: () => arrayValue.push() }), _jsx(Button, { label: translate('createProperty.rentalObjects.crateByType.action'), icon: 'plus-circle', onClick: () => modalRef?.current?.open({ arrayRel: arrayValue }) })] }), _jsx(FormModal, { apiRef: modalRef, use: (props, api) => {
                                        const title = translate('createProperty.rentalObjects.crateByType.title');
                                        const valueType = CreateRentalObjectBulk.useValue({
                                            options: { settings: arrayValue.root().value.settings },
                                        });
                                        return {
                                            valueType,
                                            title,
                                            onSave: () => {
                                                const data = [];
                                                for (let i = valueType.value.number; i > 0; i--) {
                                                    data.push({ type: valueType.value.type });
                                                }
                                                arrayValue.pushAll(data);
                                                modalRef.current.close();
                                            }
                                        };
                                    } })] });
                    },
                }),
            };
        }
    }
    CreatePropertyType.Building = Building;
    class CreateBuilding extends Building {
        getResolvedValue(prev) {
            const { street, taxValue, houseNumber, egid, numberOfFloors, buildYear, startOfRenting, name } = super.getResolvedValue(prev);
            return {
                street,
                taxValue,
                houseNumber,
                egid,
                numberOfFloors,
                buildYear,
                startOfRenting,
                name
            };
        }
        buildFields() {
            const { rentalObjects, ...fields } = super.buildFields();
            return fields;
        }
        _renderWidget({ field }) {
            return _jsxs(_Fragment, { children: [field.renderChildren(['name']), AddressType.render(field.children), _jsx(FlexRow, { gap: 10 }), _jsxs(Row, { gap: 10, children: [_jsx(Col, { children: field.children.egid.render() }), _jsx(Col, { children: field.children.buildYear.render() }), _jsx(Col, { children: field.children.startOfRenting.render() }), _jsx(Col, { sm: 'auto', children: field.children.numberOfFloors.render() })] })] });
        }
    }
    CreatePropertyType.CreateBuilding = CreateBuilding;
    class RentalObject extends BaseFormTypeOld {
        getResolvedValue(prev) {
            const { uuid, ...data } = prev;
            return {
                ...data,
            };
        }
        buildFields() {
            return {
                type: SelectType.string({
                    label: translate('createProperty.rentalObject.subType'),
                    nullable: false,
                    required: true,
                    choices: () => rentalObjectTypeOptions,
                    onChange: (typeValue, _meta) => {
                        RentalObject.updateName(typeValue.parent);
                    },
                }),
                numberOfRooms: new NumberType({
                    label: translate('createProperty.rentalObject.numberOfRooms'),
                    required: true,
                    step: .5,
                    min: 0,
                    defaultValue: 0,
                    onChange: (typeValue, _meta) => {
                        RentalObject.updateName(typeValue.parent);
                    },
                }),
                floor: new FloorType({
                    label: translate('createProperty.rentalObject.floor'),
                    required: true,
                    defaultValue: 0,
                    onChange: (typeValue, _meta) => {
                        RentalObject.updateName(typeValue.parent);
                    },
                }),
                name: new StringType({
                    label: translate('createProperty.rentalObject.name'),
                    required: true,
                    nullable: false
                }),
                area: new NumberType({
                    label: translate('createProperty.rentalObject.area'),
                    required: false,
                    nullable: true,
                    defaultValue: 0,
                }),
                ewid: new StringType({
                    label: translate('createProperty.rentalObject.ewid'),
                    required: false,
                    nullable: true,
                    constraints: {
                        max: 10
                    }
                }),
            };
        }
        static updateName(rentalObject) {
            const nameType = rentalObject?.children?.name;
            if (!nameType || nameType.meta.dirty || !rentalObject.root().value.settings) {
                return;
            }
            const arrayType = rentalObject.parent?.value || {};
            const type = rentalObject.value.type;
            const typeName = translate(`rentalObjectType.${type}`);
            switch (type) {
                case RentalObjectType.RESIDENTIAL_APARTMENT:
                case RentalObjectType.RESIDENTIAL_MAISONETTE:
                case RentalObjectType.RESIDENTIAL_ATTIC_FLAT:
                case RentalObjectType.RESIDENTIAL_STEPPED_FLAT:
                case RentalObjectType.RESIDENTIAL_STUDIO:
                case RentalObjectType.RESIDENTIAL_SINGLE_ROOM:
                case RentalObjectType.RESIDENTIAL_GRANNY_FLAT:
                case RentalObjectType.RESIDENTIAL_LOFT:
                case RentalObjectType.RESIDENTIAL_FURNISHED_FLAT: {
                    const floorLabel = options
                        .find(o => o.value === rentalObject.value.floor || (rentalObject.value.floor === 0 && o.value === 'ground'))?.label;
                    if (!floorLabel) {
                        return;
                    }
                    const newName = `${rentalObject.value.numberOfRooms}-Zi-${typeName} ${floorLabel}`.trim();
                    nameType.setValue(newName, false, true);
                    break;
                }
                case RentalObjectType.RESIDENTIAL_DETACHED_HOUSE:
                case RentalObjectType.RESIDENTIAL_ROW_HOUSE:
                case RentalObjectType.RESIDENTIAL_DUPLEX_HOUSE:
                case RentalObjectType.RESIDENTIAL_CHALET:
                case RentalObjectType.RESIDENTIAL_RUSTICO: {
                    const newName = `${rentalObject.value.numberOfRooms}-Zi-${typeName}`.trim();
                    nameType.setValue(newName, false, true);
                    break;
                }
                case RentalObjectType.PARKING_SPACE_OUTSIDE:
                case RentalObjectType.PARKING_CARPORT: {
                    const totalParkingSpaces = Object.values(arrayType)
                        .filter((ro, index) => [
                        RentalObjectType.PARKING_SPACE_OUTSIDE,
                        RentalObjectType.PARKING_CARPORT
                    ].includes(ro.type) && index !== Number(rentalObject.key))
                        .length;
                    const newName = `Parkplatz ${totalParkingSpaces + 1}`.trim();
                    nameType.setValue(newName, false, true);
                    break;
                }
                case RentalObjectType.PARKING_SPACE_OUTSIDE_E:
                case RentalObjectType.PARKING_CARPORT_E: {
                    const totalParkingSpaces = Object.values(arrayType)
                        .filter((ro, index) => [
                        RentalObjectType.PARKING_SPACE_OUTSIDE_E,
                        RentalObjectType.PARKING_CARPORT_E
                    ].includes(ro.type) && index !== Number(rentalObject.key))
                        .length;
                    const newName = `E-Parkplatz ${totalParkingSpaces + 1}`.trim();
                    nameType.setValue(newName, false, true);
                    break;
                }
                case RentalObjectType.PARKING_SPACE_INSIDE:
                case RentalObjectType.PARKING_SPACE_INSIDE_E:
                case RentalObjectType.PARKING_GARAGE:
                case RentalObjectType.PARKING_MOTO_OUTSIDE:
                case RentalObjectType.PARKING_MOTO_INSIDE: {
                    const totalParkingSpaces = Object.values(arrayType)
                        .filter((ro, index) => ro.type === rentalObject.value.type && index !== Number(rentalObject.key))
                        .length;
                    const newName = `${typeName} ${totalParkingSpaces + 1}`.trim();
                    nameType.setValue(newName, false, true);
                    break;
                }
                case RentalObjectType.COMMERCIAL_OFFICE:
                case RentalObjectType.COMMERCIAL_RETAIL:
                case RentalObjectType.COMMERCIAL_DOCTOR:
                case RentalObjectType.COMMERCIAL_ADVERTISING:
                case RentalObjectType.COMMERCIAL_WORKSHOP:
                case RentalObjectType.COMMERCIAL_HOBBY_ROOM:
                case RentalObjectType.COMMERCIAL_ATELIER:
                case RentalObjectType.STORAGE_STORAGE:
                case RentalObjectType.GASTRONOMY_RESTAURANT:
                case RentalObjectType.GASTRONOMY_BAR:
                case RentalObjectType.GASTRONOMY_CAFE: {
                    const floorLabel = options
                        .find(o => o.value === rentalObject.value.floor || (rentalObject.value.floor === 0 && o.value === 'ground'))?.label;
                    if (!floorLabel) {
                        return;
                    }
                    const newName = `${typeName} ${floorLabel}`.trim();
                    nameType.setValue(newName, false, true);
                    break;
                }
                case RentalObjectType.AGRICULTURAL_AGRICULTURAL:
                case RentalObjectType.INDUSTRIAL_INDUSTRIAL:
                case RentalObjectType.CONSTRUCTION_PLOT:
                case RentalObjectType.INVESTMENT_INVESTMENT:
                case RentalObjectType.VACATION_VACATION:
                case RentalObjectType.OTHER_OTHER: {
                    nameType.setValue(typeName, false, true);
                    break;
                }
            }
        }
    }
    CreatePropertyType.RentalObject = RentalObject;
    class CreateRentalObject extends RentalObject {
        getResolvedValue(prev) {
            const { name, type, floor, area, numberOfRooms, } = super.getResolvedValue(prev);
            return {
                name,
                type,
                floor,
                area,
                numberOfRooms,
            };
        }
        buildFields() {
            const { ...fields } = super.buildFields();
            return fields;
        }
        _renderWidget({ field }) {
            return _jsxs(_Fragment, { children: [_jsxs(Row, { gap: 10, children: [_jsx(Col, { children: field.children.type.render() }), _jsx(Col, { sm: 'auto', children: field.children.numberOfRooms.render() }), _jsx(Col, { sm: 'auto', children: field.children.floor.render() })] }), field.children.name.render(), _jsxs(Row, { gap: 10, children: [_jsx(Col, { children: field.children.area.render() }), _jsx(Col, { children: field.children.ewid.render() })] })] });
        }
    }
    CreatePropertyType.CreateRentalObject = CreateRentalObject;
    class CreateRentalObjectBulk extends BaseFormTypeOld {
        getResolvedValue(prev) {
            const { ...data } = prev;
            return {
                ...data,
            };
        }
        buildFields() {
            return {
                type: SelectType.string({
                    label: translate('createProperty.rentalObject.subType'),
                    nullable: false,
                    required: true,
                    choices: () => rentalObjectTypeOptions
                }),
                number: new NumberType({
                    label: translate('createProperty.rentalObject.number'),
                    required: true,
                    step: 1,
                    min: 0,
                    defaultValue: 1,
                }),
            };
        }
        _renderWidget({ field }) {
            return _jsx(_Fragment, { children: _jsxs(Row, { gap: 10, children: [_jsx(Col, { children: field.renderChild('type') }), _jsx(Col, { xs: 2, minw: 140, children: field.renderChild('number') })] }) });
        }
    }
    CreatePropertyType.CreateRentalObjectBulk = CreateRentalObjectBulk;
})(CreatePropertyType || (CreatePropertyType = {}));
