import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import ReactAutocomplete from 'react-autocomplete';
import styled from 'styled-components';
import { isString, tryFunction } from '@f2w/utils';
import { Input } from '../Input';
import { Dropdown } from 'Atoms/Dropdown/Dropdown';
import useDebouncedCallback from '@restart/hooks/useDebouncedCallback';
export function Autocomplete({ onChange: _onChange, getItems, onSelect, getItemValue, getItemLabel = getItemValue, getItemId = getItemLabel, minValueLength = 1, value, style, ...inputProps }) {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const loadItems = useDebouncedCallback((value) => {
        if (isString(value) && value.length >= minValueLength) {
            setLoading(true);
            getItems(value)
                .then((items) => {
                setItems(items);
            })
                .catch(error => {
                setItems([]);
                throw error;
            })
                .finally(() => {
                setLoading(false);
            });
        }
        else {
            setItems([]);
        }
    }, 400);
    return (_jsx(ReactAutocomplete, { items: items, value: value, open: isOpen && items.length > 0, onMenuVisibilityChange: isOpen => { setIsOpen(isOpen); }, getItemValue: getItemValue, wrapperStyle: { ...style }, wrapperProps: {}, onSelect: (value, item) => {
            setItems([]);
            tryFunction(_onChange, [value]);
            tryFunction(onSelect, [item]);
        }, onChange: (event, value) => {
            tryFunction(_onChange, [value]);
            loadItems(value);
        }, inputProps: inputProps, renderInput: (props) => (_jsx(Input, { loading: loading, rawOnChange: true, ...props })), renderMenu: (items, value, style) => {
            return (_jsx(Root, { className: "fw-dropdown-menu fw-menu fw-menu--light fw-menu--root show", style: {
                    position: 'fixed',
                    marginLeft: '-10px',
                    marginTop: '10px',
                    ...style
                }, children: _jsx("div", { children: _jsx("div", { children: items }) }) }));
        }, renderItem: (item, isHighlighted, style) => {
            return (_jsx(Dropdown.Link, { active: isHighlighted, label: getItemLabel(item), scrollIntoView: true }, getItemId(item)));
        } }));
}
export const AutocompleteControlled = ({ onChange: _onChange, defaultValue, ...props }) => {
    const [value, setValue] = useState(defaultValue);
    return (_jsx(Autocomplete, { ...props, value: value, onChange: (value, event) => {
            setValue(value);
            _onChange?.(value);
        } }));
};
const Root = styled.div `
  && {
    --menu-active-background-opacity: 1;
    --menu-hover-background-opacity: 1;
    --menu-active-border-color: var(--fw-color-primary-500);
    --menu-active-color: var(--fw-color-primary-600);
    --menu-active-background-color: var(--fw-color-primary-50);
    --menu-active-hover-border-color: var(--fw-color-primary-500);
    --menu-active-hover-color: var(--fw-color-primary-600);
    --menu-active-hover-background-color: var(--fw-color-primary-50);

    padding: 0;

    > div {
      overflow: auto;
      overscroll-behavior: contain;
      max-height: 320px;

      > div {
        padding: 10px
      }
    }
  }
`;
