import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { translate } from 'Services/Translator';
import { Schema, SimpleType } from '../core';
import generateRoute from 'Services/Router';
import client from 'Utils/client';
import { Icon } from 'Components/Atoms';
import { orFunction, OrFunction } from 'Utils';
var IbanTypeEnum;
(function (IbanTypeEnum) {
    IbanTypeEnum["IBAN"] = "REGULAR";
    IbanTypeEnum["QR_IBAN"] = "QR";
})(IbanTypeEnum || (IbanTypeEnum = {}));
export class IbanType extends SimpleType.MaskedString {
    static IBAN = IbanTypeEnum.IBAN;
    static QR_IBAN = IbanTypeEnum.QR_IBAN;
    constructor(options, parent) {
        super({
            ...options,
            constraints: {
                min: [15, translate('form.validation.noLessThan', { length: 15 })],
                max: [33, translate('form.validation.noLongerThan', { length: 33 })],
                ...options?.constraints,
            },
            mask: () => {
                const definitions = {
                    '#': { mask: /[0-9A-Z]/ },
                    '$': { mask: /[A-Z]/ },
                };
                const p = {
                    eager: 'remove',
                };
                return {
                    prepareChar: str => str.toUpperCase(),
                    mask: [
                        { startsWith: 'CH', mask: '{CH}00 0000 0### #### #### [#]', definitions, ...p },
                        { startsWith: 'LI', mask: '{LI}00 0000 0### #### #### [#]', definitions, ...p },
                        { startsWith: 'AL', mask: '{AL}00 #### #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'AZ', mask: '{AZ}00 #### #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'BH', mask: '{BH}00 #### #### #### #### ##', definitions, ...p },
                        { startsWith: 'BE', mask: '{BE}00 #### #### ##00', definitions, ...p },
                        { startsWith: 'BA', mask: '{BA39} #### #### #### ##00', definitions, ...p },
                        { startsWith: 'BR', mask: '{BR}00 #### #### #### ##00', definitions, ...p },
                        { startsWith: 'VG', mask: '{VG}00 #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'BG', mask: '{BG}00 #### #### #### #### ##', definitions, ...p },
                        { startsWith: 'BI', mask: '{BI}00 0000 0000 0000 0000 0000 000', definitions, ...p },
                        { startsWith: 'CR', mask: '{CR}00 {0}### #### #### #### ##', definitions, ...p },
                        { startsWith: 'DK', mask: '{DK}00 #### #### #### #0', definitions, ...p },
                        { startsWith: 'DE', mask: '{DE}00 #### #### #### #### ##', definitions, ...p },
                        { startsWith: 'DO', mask: '{DO}00 #### #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'SV', mask: '{SV}00 #### #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'EE', mask: '{EE}00 #### #### #### ###0', definitions, ...p },
                        { startsWith: 'FO', mask: '{FO}00 #### #### #### #0', definitions, ...p },
                        { startsWith: 'FI', mask: '{FI}00 #### #### #### #0', definitions, ...p },
                        { startsWith: 'FR', mask: '{FR}00 #### #### #### #### #### #00', definitions, ...p },
                        { startsWith: 'GE', mask: '{GE}00 #### #### #### #### ##', definitions, ...p },
                        { startsWith: 'GI', mask: '{GI}00 #### #### #### #### ###', definitions, ...p },
                        { startsWith: 'GR', mask: '{GR}00 #### #### #### #### #### ###', definitions, ...p },
                        { startsWith: 'GL', mask: '{GL}00 #### #### #### #0', definitions, ...p },
                        { startsWith: 'GT', mask: '{GT}00 #### #### #### #0', definitions, ...p },
                        { startsWith: 'IQ', mask: '{IQ}00 #### #### #### #### ###', definitions, ...p },
                        { startsWith: 'IE', mask: '{IE}00 #### #### #### #### ##', definitions, ...p },
                        { startsWith: 'IS', mask: '{IS}00 0000 #### #### 0000 0000 00', definitions, ...p },
                        { startsWith: 'IL', mask: '{IL}00 0000 0000 0000 0000 0000 000', definitions, ...p },
                        { startsWith: 'IT', mask: '{IT}00 #### #### #### #### #### ###', definitions, ...p },
                        { startsWith: 'JO', mask: '{JO}00 #### #### #### #### #### #### ##', definitions, ...p },
                        { startsWith: 'KZ', mask: '{KZ}00 #### #### #### ####', definitions, ...p },
                        { startsWith: 'QA', mask: '{QA}00 #### #### #### #### #### #### #', definitions, ...p },
                        { startsWith: 'HR', mask: '{HR}00 #### #### #### #### #', definitions, ...p },
                        { startsWith: 'KW', mask: '{KW}00 #### #### #### #### #### #### ##', definitions, ...p },
                        { startsWith: 'LV', mask: '{LV}00 #### #### #### #### #', definitions, ...p },
                        { startsWith: 'LY', mask: '{LY}00 #### #### #### #### #### #', definitions, ...p },
                        { startsWith: 'LB', mask: '{LB}00 #### #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'LT', mask: '{LT}00 #### #### #### ####', definitions, ...p },
                        { startsWith: 'LU', mask: '{LU}00 #### #### #### ####', definitions, ...p },
                        { startsWith: 'MT', mask: '{MT}00 #### #### #### #### #### #### ###', definitions, ...p },
                        { startsWith: 'MR', mask: '{MR13} #### #### #### #### #### #00', definitions, ...p },
                        { startsWith: 'MU', mask: '{MU}00 #### #### #### #### #### {000}# ##', definitions, ...p },
                        { startsWith: 'MD', mask: '{MD}00 #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'MC', mask: '{MC}00 #### #### #### #### #### #00', definitions, ...p },
                        { startsWith: 'MN', mask: '{MN}00 #### #### #### ####', definitions, ...p },
                        { startsWith: 'NL', mask: '{NL}00 #### #### #### ##', definitions, ...p },
                        { startsWith: 'NI', mask: '{NI}00 $$$$ 0000 0000 0000 0000 0000 0000', definitions, ...p },
                        { startsWith: 'NO', mask: '{NO}00 #### #### ##0', definitions, ...p },
                        { startsWith: 'AT', mask: '{AT}00 #### #### #### ####', definitions, ...p },
                        { startsWith: 'PK', mask: '{PK}00 #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'PS', mask: '{PS}00 #### #### #### #### #### #### #', definitions, ...p },
                        { startsWith: 'PL', mask: '{PL}00 #### #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'RO', mask: '{RO}00 #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'RU', mask: '{RU}00 #### #### #### #### #### #### #### #', definitions, ...p },
                        { startsWith: 'SM', mask: '{SM}00 0### #### #### #### #### ###', definitions, ...p },
                        { startsWith: 'ST', mask: '{ST}00 #### #### #### #### #### #', definitions, ...p },
                        { startsWith: 'SA', mask: '{SA}00 #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'SE', mask: '{SE}00 #### #### #### #### ###0', definitions, ...p },
                        { startsWith: 'SC', mask: '{SC}00 #### ## ## #### #### #### #### ###', definitions, ...p },
                        { startsWith: 'SD', mask: '{SD}00 #### #### #### ##', definitions, ...p },
                        { startsWith: 'SK', mask: '{SK}00 #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'SI', mask: '{SI56} #### #### #### #00', definitions, ...p },
                        { startsWith: 'ES', mask: '{ES}00 #### #### 00## #### ####', definitions, ...p },
                        { startsWith: 'LC', mask: '{LC}00 #### #### #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'CZ', mask: '{CZ}00 #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'TR', mask: '{TR}00 #### #{0}## #### #### #### ##', definitions, ...p },
                        { startsWith: 'UA', mask: '{UA}00 #### #### #### #### #### #### #', definitions, ...p },
                        { startsWith: 'HU', mask: '{HU}00 #### ###0 #### #### #### ###0', definitions, ...p },
                        { startsWith: 'VA', mask: '{VA}00 #### #### #### #### ##', definitions, ...p },
                        { startsWith: 'AE', mask: '{AE}00 #### #### #### #### ###', definitions, ...p },
                        { startsWith: 'GB', mask: '{GB}00 #### #### #### #### ##', definitions, ...p },
                        { startsWith: 'CY', mask: '{CY}00 #### #### #### #### #### ####', definitions, ...p },
                        { startsWith: 'MK', mask: '{MK07} #### #### #### #00', definitions },
                        { startsWith: 'TL', mask: '{TL38} #### #### #### #### #00', definitions },
                        { startsWith: 'PT', mask: '{PT50} 0000 0000 0000 0000 0000 0', definitions },
                        { startsWith: 'RS', mask: '{RS35} #### #### #### #### 00', definitions },
                        { startsWith: 'TN', mask: '{TN59} #### #### #### #### ##00', definitions },
                        { startsWith: 'ME', mask: '{ME25} #### #### #### #### 00', definitions, ...p },
                        { startsWith: '', mask: '$$', definitions },
                    ],
                    dispatch: (appended, dynamicMasked) => {
                        return dynamicMasked.compiledMasks.find(m => {
                            const value = (dynamicMasked.value + appended).toUpperCase();
                            return value.startsWith(m.startsWith);
                        });
                    }
                };
            },
        }, parent);
    }
    ibanType = (() => {
        const type = this;
        const transLabel = (type) => translate(`form.type.iban.${type.toLowerCase()}.label`);
        let _label, _value;
        return {
            get isQr() {
                return this.value === IbanTypeEnum.QR_IBAN;
            },
            get key() {
                return type.options.identifierTypeKey || `${type.field.key}Type`;
            },
            get label() {
                return _label ?? transLabel(this.value);
            },
            get value() {
                return _value ?? type.field.parent?.initialValue[this.key] ?? IbanTypeEnum.IBAN;
            },
            set value(value) {
                if (_value === value)
                    return;
                _value = value;
                _label = transLabel(value);
                if (type.field.parent) {
                    if (!type.field.parent.initialValue[this.key]) {
                        type.field.parent.initialValue[this.key] = value;
                    }
                    type.field.parent.value[this.key] = value;
                }
            }
        };
    })();
    _createField(props) {
        return super._createField({
            ...props,
            label: () => this.ibanType.label
        });
    }
    _useField(props) {
        super._useField(props);
        this.ibanType.value = useMemo(() => {
            if (orFunction(this.options.forceQrIban)) {
                return IbanTypeEnum.QR_IBAN;
            }
            if (orFunction(this.options.forceIban)) {
                return IbanTypeEnum.IBAN;
            }
            return IbanType.getIbanType(this.value);
        }, [this.value, this.options.forceQrIban, this.options.forceIban]);
    }
    getWidgetProps() {
        return {
            ...super.getWidgetProps(),
            autoComplete: 'off',
            prefix: _jsx(Icon, { icon: 'credit-card' }),
        };
    }
    _validateDebounced() {
        return [
            (value) => {
                if (!value) {
                    return Promise.resolve('');
                }
                const ibanType = this.ibanType.value;
                const isQr = ibanType === IbanType.QR_IBAN;
                return IbanType.validateIban(value, isQr)
                    .then((serverError) => {
                    if (!serverError && orFunction(this.options.forceIban) && IbanType.getIbanType(value) === IbanType.QR_IBAN) {
                        throw new Schema.ValidationError(translate('enterRegularIBAN'), value, this.field.path);
                    }
                    if (serverError) {
                        let error = OrFunction(this.options.ibanErrorMessage, isQr) ?? serverError;
                        if (error) {
                            throw new Schema.ValidationError(error, value, this.field.path);
                        }
                    }
                    return '';
                });
            },
        ];
    }
    static validateIban(iban, isQrIban) {
        const validateIbanRoute = generateRoute('validate_bank_account_iban', {
            iban,
            isQrIban: isQrIban ? 1 : 0,
        });
        return client
            .get(validateIbanRoute)
            .then(r => {
            return '';
        })
            .catch((error) => {
            return error.response?.data?.data ?? error.response.data;
        });
    }
    static isQrIban(v) {
        const value = String(v)
            .replaceAll(/[^a-z0-9]*/gi, '')
            .substring(4, 5);
        return value === '3';
    }
    static getIbanType(v) {
        const value = String(v)
            .replaceAll(/[^a-z0-9]*/gi, '')
            .substring(4, 5);
        return value === '3' ? IbanType.QR_IBAN : IbanType.IBAN;
    }
}
