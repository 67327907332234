import { sleep } from 'Utils/delay';
import { BaseValue, SimpleState, ValidationError } from '../../base';
import { TimeoutHandler } from '@f2w/utils';
export class SimpleValue extends BaseValue {
    _validationError;
    _state;
    timeout = new TimeoutHandler();
    constructor(type, props) {
        super(type, props);
        this._state = new SimpleState({
            getSelf: () => this,
            onChange: (event) => {
                switch (event.propName) {
                    case 'value':
                        this.options.onChange?.(this, { initial: false });
                        break;
                    case 'initialValue':
                        this.options.onChange?.(this, { initial: true });
                        break;
                }
            }
        });
    }
    toString() {
        return String(this.value ?? '');
    }
    use(props) {
        return this;
    }
    destroy() {
        this.timeout.clear();
    }
    getResolvedValue(options) {
        return this.value;
    }
    get error() {
        return this._state.data.error;
    }
    _updateMeta(data, skipUpdate) {
        if (this._state.update(data)) {
            this._handleUpdate(skipUpdate);
            return true;
        }
    }
    clear(skipRender) {
        this._state.clear(skipRender);
    }
    reset(value, shouldValidate, skipRender) {
        this._state.reset({ newValue: value, shouldValidate, skipRender });
    }
    setValue(initialValue, shouldValidate, skipRender) {
        initialValue = this._state._cast(initialValue);
        if (this._state.set('initialValue', initialValue)) {
            this._handleUpdate(skipRender, shouldValidate);
        }
    }
    updateValue(value, shouldValidate, skipRender) {
        value = this._state._cast(value, true);
        if (this._state.set('value', value)) {
            this._handleUpdate(skipRender, shouldValidate);
        }
    }
    setTouched(value, shouldValidate, skipRender) {
        if (this._state.set('touched', value)) {
            this._handleUpdate(skipRender, shouldValidate);
        }
    }
    setError(value, skipRender) {
        if (this._state.set('error', value)) {
            this._handleUpdate(skipRender);
        }
    }
    async validate(force, skipRender) {
        if (this.meta.disabled) {
            this.setError('', skipRender);
            return undefined;
        }
        await sleep(20);
        return new Promise((resolve, reject) => {
            if (this.isValidated && !force) {
                resolve(this.error);
            }
            else {
                this._validationError = undefined;
                this._state.set('validating', true);
                this.specs.validate(this.value)
                    .then((value) => {
                    this._updateError(skipRender, resolve);
                })
                    .catch(error => {
                    if (isValidationError(error)) {
                        this._validationError = true;
                        this._updateError(skipRender, resolve, error);
                    }
                    else {
                        this._validationError = undefined;
                        reject(error);
                    }
                });
            }
        });
    }
    _updateError(skipRender, resolve, error) {
        const msg = error ? (error?.message || '') : undefined;
        if (error) {
            this._state.set('validating', false);
            this._state.set('error', error?.message || '');
            resolve(error?.message);
        }
        else {
            this._state.set('validating', false);
            this._state.set('error', '');
            resolve();
        }
        this._handleUpdate(skipRender);
    }
}
function isValidationError(err) {
    return err instanceof ValidationError || err.name === 'ValidationError';
}
