import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useRoomView } from "Components/ObjectViewModal/hooks/useRoomView";
import { useCreateAssetFromRoomForm } from "Components/ObjectViewModal/hooks/useCreateAssetFromRoomForm";
import AssetServices from "Pages/Asset/services/assetServices";
import { FormModal } from "@f2w/form";
export const RoomView = ({ roomId, rentalObjectId, getRentalObjectRoomDetails, updateRentalObjectRoom, createAsset = AssetServices.createAsset }) => {
    const createAssetFromRoomForm = useCreateAssetFromRoomForm({
        roomId,
        rentalObjectId,
        createAsset,
        onSave: (asset) => view.updateData((data) => ({
            assets: [asset, ...data.assets]
        }))
    });
    const view = useRoomView({
        getRentalObjectRoomDetails,
        updateRentalObjectRoom,
        createAssetFromRoomForm
    });
    React.useEffect(() => {
        view.load(roomId);
    }, [roomId]);
    return (_jsxs(_Fragment, { children: [view.render(), _jsx(FormModal, { modal: createAssetFromRoomForm })] }));
};
