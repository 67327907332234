import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Select } from 'Atoms/Select';
import { FormControl } from 'Atoms/Input';
export function SelectFilter({ table: { column: { filterValue, setFilter, preFilteredRows, id } }, ...props }) {
    const options = React.useMemo(() => {
        const options = new Set;
        const loadedOptions = {};
        preFilteredRows.forEach(row => {
            const value = row.values[id];
            loadedOptions[value] = { value, label: row.values[id] };
            options.add({ value: row.values[id], label: row.values[id] });
        });
        return [...Object.values(loadedOptions)];
    }, [id, preFilteredRows]);
    const onChange = useCallback((p) => {
        let value = undefined;
        if (p) {
            if (props.isMulti) {
                if (Array.isArray(p)) {
                    value = p.map(v => v?.value).filter(v => v);
                }
            }
            else {
                value = p?.value;
            }
        }
        setFilter(value);
    }, [setFilter]);
    return (_jsx(Select, { isSearchable: true, isClearable: true, options: options, ...props, value: filterValue, onChange: onChange }));
}
SelectFilter.displayName = 'TableSelectFilter';
export function DateRangeFilter({ filterValue, min, max, onChange, isClearable, ...iProps }) {
    return (_jsx(FormControl.DateRangePicker, { ...iProps, startDate: filterValue?.from, endDate: filterValue?.to, minDate: min, maxDate: max, onChange: (value, meta) => {
            onChange?.({
                from: FormControl.DateManager.format(value.startDate) || undefined,
                to: FormControl.DateManager.format(value.endDate) || undefined,
            }, meta);
        } }));
}
