import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { ModalNew } from 'Components/Modal';
import { Box, FlexRow } from 'Atoms/Layout';
import { Button } from 'Atoms/Button';
import { translate } from 'Services/Translator';
import { orFunction } from 'Utils';
import { renderContent } from 'Components/Base';
const DefaultRenderer = ({ children }) => (_jsx(_Fragment, { children: children }));
export const ServerError = styled.div `
  color: var(--state-color, ${p => p.theme.palette.error.$500});
  //margin: 6px 0 0;
`;
export function FormModalTemplate({ modal, form, noPadding, ...rest }) {
    const props = {
        ...form.options,
        ...modal,
        ...rest,
        form,
    };
    const { form: { loading, render, }, closeForm, saveForm, title, Content, Header = DefaultRenderer, Footer = DefaultRenderer, } = props;
    return _jsxs(_Fragment, { children: [_jsx(ModalNew.Header, { closeButton: true, children: _jsx(Header, { ...props, children: title && _jsx(ModalNew.Title, { children: orFunction(title, form) }) }) }), _jsx(ModalNew.Body, { paddingY: noPadding ? 0 : 24, ...(noPadding ? { paddingX: 0 } : {}), children: Content ? renderContent(Content, { form, type: form.getType() }) : render() }), _jsxs(ModalNew.Footer, { style: { justifyContent: 'stretch', flexWrap: 'nowrap' }, children: [_jsx(Box, { flex: '1 1 auto', children: !!form.status?.error && _jsx(ServerError, { dangerouslySetInnerHTML: { __html: form.status.error } }) }), _jsx(FlexRow, { "data-flex": '1 1 auto', children: _jsxs(Footer, { ...props, children: [_jsx(Button, { "$color": "", type: "button", onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { onClickAsync: () => saveForm(true), loading: loading, disabled: loading || !form.dirty, children: translate('btn.save') })] }) })] })] });
}
function renderContent2() {
}
