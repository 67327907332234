import React from 'react';
import { isString } from 'Utils/types';
import camelToSnakeCase from 'Utils/camelToSnakeCase';
import BaseCssClasses from './Table.module.scss';
import context from './TableContext';
import styled from 'styled-components';
const CssSelectors = {};
const CssClasses = {
    ...BaseCssClasses
};
(() => {
    const debug = false;
    for (const key in CssClasses) {
        if (!isString(CssClasses[key]) || !isNaN(CssClasses[key]))
            continue;
        CssSelectors[key] = `.${CssClasses[key]}`;
        if (debug) {
            const value = CssClasses[key];
            const id = `__${camelToSnakeCase(key)}__`;
            if (value.indexOf(id) === -1) {
                CssClasses[key] = `${id} ${value}`;
            }
        }
    }
})();
export { CssSelectors, CssClasses, CssClasses as Css };
export const variants = {
    default: {
        hover: true,
        rowDivider: true,
        borderRadius: true,
    },
    content: {
        hover: true,
        rowDivider: true,
        rowRadius: false,
        border: true,
        borderRadius: true,
    },
    section: {
        hover: true,
        scroll: false,
        borderRadius: false,
    },
    accordion: {
        hover: true,
        rowSpacing: true,
        borderRadius: true,
    },
    list: {
        hover: true,
        rowSpacing: true,
        borderRadius: true,
        scroll: false,
    },
};
export function splitProps(initialProps) {
    const { variant, colSpan = 1, size = "md", hover = false, fixed = false, scroll = true, stickyHead = undefined, stickyFoot = undefined, border = false, borderRadius = false, rowRadius = borderRadius, rowDivider = false, rowSpacing = false, EmptyRow = undefined, HeaderRow = undefined, height = undefined, maxHeight = undefined, minHeight = undefined, ...rest } = {
        ...variants[initialProps.variant],
        ...initialProps,
    };
    const theme = {
        variant,
        colSpan,
        size,
        hover,
        fixed,
        scroll,
        stickyHead,
        stickyFoot,
        border,
        borderRadius,
        rowRadius,
        rowDivider,
        rowSpacing,
        EmptyRow,
        HeaderRow,
        height,
        maxHeight,
        minHeight,
        Css: CssClasses,
    };
    return [
        theme,
        rest,
    ];
}
export const use = () => React.useContext(context);
export const RootSlot = styled.div ``;
export const MainSlot = styled.div ``;
export const TableSlot = styled.table ``;
