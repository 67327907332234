import { css } from 'styled-components';
import { SlotFactory } from '@f2w/theme';
import './Dropdown.styles.scss';
export const dropdownSlot = new SlotFactory('dropdown');
export const dropdownMenuCss = ({ theme: { palette, typography, }, }) => css `
  && {
    ${typography.small.fontStyles};

    .fw-dropdown-header {
      padding: 7px 6px;
      ${typography.pretitle.css({ fontFamily: undefined, fontWeight: 600 })};
      color: var(--menu-header-color);
    }
  }
`;
