import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useUncontrolled } from 'uncontrollable';
import TabContext from 'Components/Base/TabContext';
import SelectableContext from 'Components/Base/SelectableContext';
const validateId = (props, ...args) => {
    let error = null;
    if (!props.generateChildId) {
        error = PropTypes.string(props, ...args);
        if (!error && !props.id) {
            error = new Error('In order to properly initialize Tabs in a way that is accessible ' +
                'to assistive technologies (such as screen readers) an `id` or a ' +
                '`generateChildId` prop to TabContainer is required');
        }
    }
    return error;
};
export const TabContainer = (props) => {
    const { id, generateChildId: generateCustomChildId, onSelect, activeKey, transition, mountOnEnter, unmountOnExit, children, } = useUncontrolled(props, { activeKey: 'onSelect' });
    const generateChildId = useMemo(() => generateCustomChildId ||
        ((key, type) => (id ? `${id}-${type}-${key}` : null)), [id, generateCustomChildId]);
    const tabContext = useMemo(() => ({
        onSelect,
        activeKey,
        transition,
        mountOnEnter: mountOnEnter || false,
        unmountOnExit: unmountOnExit || false,
        getControlledId: (key) => generateChildId(key, 'tabpane'),
        getControllerId: (key) => generateChildId(key, 'tab'),
    }), [
        onSelect,
        activeKey,
        transition,
        mountOnEnter,
        unmountOnExit,
        generateChildId,
    ]);
    return (_jsx(TabContext.Provider, { value: tabContext, children: _jsx(SelectableContext.Provider, { value: onSelect || null, children: children }) }));
};
