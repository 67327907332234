import * as rt from 'react-table';
export const useGroupBy = {
    initOptions: (options, table) => ({
        defaultCanGroupBy: false,
        disableGroupBy: !options.enabled,
        expandSubRows: false,
        defaultColumn: {
            disableGroupBy: true,
            defaultCanGroupBy: false,
            hideGroupBy: true,
        },
    }),
    createColumnOptions: ({ enabled, ...options }, column, defaultColumn) => {
        return options;
    },
    hook: hooks => {
        rt.useGroupBy(hooks);
    },
};
