import * as polished from 'polished';
import { isNum } from '@f2w/utils';
const _HSBToRGB = (h, s, b) => {
    s /= 100;
    b /= 100;
    const k = (n) => (n + h / 60) % 6;
    const f = (n) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
    return [
        Math.round(255 * f(5)),
        Math.round(255 * f(3)),
        Math.round(255 * f(1)),
    ];
};
const _HSBToHex = (h, s, b) => {
    const rgb = _HSBToRGB(h, s, b);
    return _RGBToHex(rgb.shift(), rgb.shift(), rgb.shift());
};
const _RGBToHex = (r, g, b) => ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0');
export function rgbToHex(r, g, b) {
    return `#${_RGBToHex(r, g, b)}`;
}
export function hsbToRgb(h, s, b) {
    return `rgb(${_HSBToRGB(h, s, b).join(',')})`;
}
export function hsbToHex(h, s, b) {
    return `#${_HSBToHex(h, s, b)}`;
}
export const getValue = (v) => polished.getValueAndUnit(v)[0];
export const getUnit = (v) => polished.getValueAndUnit(v)[1];
export const setUnit = (v, unit) => `${getValue(v)}${unit}`;
export const isUnit = (v, ...units) => units.includes(getUnit(v));
export const toUnit = (v, defaultUnit = 'px') => getUnit(v) ? v : setUnit(v, defaultUnit);
export const borderTopRadius = ($radius) => ({
    borderTopLeftRadius: $radius,
    borderTopRightRadius: $radius,
});
export const borderRightRadius = ($radius) => ({
    borderTopRightRadius: $radius,
    borderBottomRightRadius: $radius,
});
export const borderBottomRadius = ($radius) => ({
    borderBottomRightRadius: $radius,
    borderBottomLeftRadius: $radius,
});
export const borderLeftRadius = ($radius) => ({
    borderTopLeftRadius: $radius,
    borderBottomLeftRadius: $radius,
});
export const remToPx = (v, baseSize) => (isUnit(v, 'px') ? v : polished.remToPx(getValue(v), setUnit(baseSize, 'px')));
export const toPx = (v) => (isNum(v) ? `${v}px` : v);
export const pxToRem = (v, baseSize) => (isUnit(v, 'rem') ? v : polished.rem(getValue(v), setUnit(baseSize, 'px')));
export function createUnitHelper(currentSize, baseSize) {
    const converter = createUnitConverter(currentSize, baseSize);
    const create = function (unit, value, outputUnit = unit) {
        const currentUnit = (getUnit(value) || unit);
        if (currentUnit !== unit) {
            if (!converter[currentUnit]) {
                throw new Error(`Unit converter ${currentUnit} not found. Available converters are: ${Object.keys(converter).join(', ')}`);
            }
            if (!converter[currentUnit][unit]) {
                throw new Error(`Unit converter ${currentUnit}.${unit} not found. Available converters are: ${Object.keys(converter[currentUnit]).join(', ')}`);
            }
            value = converter[currentUnit][unit](getValue(value));
        }
        const valueConverter = converter[unit];
        const numberValue = getValue(value);
        value = new UnitValue(valueConverter, numberValue, unit, outputUnit);
        return value;
    };
    return {
        fontSize: getValue(currentSize),
        htmlFontSize: getValue(baseSize),
        ...converter,
        create
    };
}
export class UnitValue extends String {
    #converter;
    value;
    unit;
    outputUnit;
    constructor(converter, value, unit, outputUnit = unit) {
        super(converter[outputUnit](value));
        this.#converter = converter;
        this.value = value;
        this.unit = unit;
        this.outputUnit = outputUnit;
    }
    get cssValue() {
        return this[this.outputUnit];
    }
    get px() {
        return this.#converter['px'](this.value);
    }
    get rem() {
        return this.#converter['rem'](this.value);
    }
    get em() {
        return this.#converter['em'](this.value);
    }
    get perc() {
        return this.#converter['%'](this.value);
    }
    get pxValue() {
        return getValue(this.px);
    }
    get remValue() {
        return getValue(this.rem);
    }
    get emValue() {
        return getValue(this.em);
    }
    get percValue() {
        return getValue(this.perc);
    }
}
function createUnitConverter(currentSize, baseSize) {
    currentSize = setUnit(currentSize, 'px');
    baseSize = setUnit(baseSize, 'px');
    const pxToRem = (v) => (isUnit(v, 'rem') ? v : polished.rem(getValue(v), baseSize));
    const pxToEm = (v) => (isUnit(v, 'em') ? v : polished.em(getValue(v), currentSize));
    const pxToPerc = (v) => (isUnit(v, '%') ? v : setUnit(getValue(pxToEm(v)) * 100, '%'));
    const remToPx = (v) => (isUnit(v, 'px') ? v : polished.remToPx(getValue(v), baseSize));
    const remToEm = (v) => (isUnit(v, 'em') ? v : pxToEm(remToPx(v)));
    const remToPerc = (v) => (isUnit(v, '%') ? v : pxToPerc(remToPx(v)));
    const emToPx = (v) => (isUnit(v, 'px') ? v : polished.remToPx(getValue(v), currentSize));
    const emToRem = (v) => (isUnit(v, 'rem') ? v : pxToRem(emToPx(v)));
    const emToPerc = (v) => (isUnit(v, '%') ? v : pxToPerc(emToPx(v)));
    const percToPx = (v) => (isUnit(v, 'px') ? v : emToPx(getValue(v) / 100));
    const percToRem = (v) => (isUnit(v, 'rem') ? v : pxToRem(percToPx(v)));
    const percToEm = (v) => (isUnit(v, 'em') ? v : pxToEm(percToPx(v)));
    const converters = {
        px: {
            px: (v) => setUnit(v, 'px'),
            rem: pxToRem,
            em: pxToEm,
            '%': pxToPerc,
        },
        rem: {
            rem: (v) => setUnit(v, 'rem'),
            px: remToPx,
            em: remToEm,
            '%': remToPerc,
        },
        em: {
            em: (v) => setUnit(v, 'em'),
            px: emToPx,
            rem: emToRem,
            '%': emToPerc,
        },
        '%': {
            '%': (v) => setUnit(v, '%'),
            px: percToPx,
            rem: percToRem,
            em: percToEm,
        },
    };
    return {
        ...converters,
    };
}
export const Unit = {
    getValue,
    getUnit,
    setUnit,
    isUnit,
    isEm: (v) => isUnit(v, 'em'),
    isPx: (v) => isUnit(v, 'px'),
    isRem: (v) => isUnit(v, 'rem'),
    isPerc: (v) => isUnit(v, '%'),
    remToPx,
    pxToRem,
};
