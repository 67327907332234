import generateRoute from "Services/Router";
import client from "Utils/client";
export var QuickschildIntegrationApi;
(function (QuickschildIntegrationApi) {
    QuickschildIntegrationApi.activateIntegration = (properties) => {
        const route = generateRoute('api.quickschild.integration.activate');
        return client.post(route, { properties: properties });
    };
    QuickschildIntegrationApi.getIntegration = async () => {
        const { data } = await client.get(generateRoute('api.quickschild.integration'));
        return data;
    };
    QuickschildIntegrationApi.getProperties = async (dataTableParams) => {
        const { data } = await client.get(generateRoute('api.quickschild.integration.properties'), { params: dataTableParams });
        return data;
    };
    QuickschildIntegrationApi.getPropertiesIds = async (dataTableParams) => {
        const { data } = await client.get(generateRoute('api.quickschild.integration.propertiesIds'), { params: dataTableParams });
        return data;
    };
})(QuickschildIntegrationApi || (QuickschildIntegrationApi = {}));
