import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { translate } from "Services/Translator";
import { ContractRole, NameplateOrderType } from "Pages/Quickschild/forms/NameplateOrderType";
import { Button } from "Atoms/Button";
import { NameplateApi } from "Pages/Quickschild/services/NameplateApi";
import { UseStyled } from "@fw/theme";
import { css } from "styled-components";
import moment from "moment/moment";
const BlueText = UseStyled.styled('div', ({ theme: { palette, typography, rem } }) => (css `
    background: ${palette.primary.$50};
    border-radius: ${rem(8)};
    padding: ${rem(16)};
    display: flex;
    align-items: center;
    align-self: stretch;
    color: ${palette.primary.$500};
    ${typography.medium.fontStyles}
`));
const WarningText = UseStyled.styled('div', ({ theme: { palette, typography, rem } }) => (css `
    background: ${palette.warning.$50};
    border-radius: ${rem(8)};
    padding: ${rem(16)};
    margin-bottom: ${rem(16)};
    display: flex;
    align-items: center;
    align-self: stretch;
    color: ${palette.warning.$500};
    ${typography.medium.fontStyles}
`));
export const useQuickschildOrderForm = () => useFormModal({
    type: () => new NameplateOrderType(),
    name: 'contract-quickschild-order',
    title: translate('quickschild.integrationView.orderForm.title'),
    onSave: (values) => {
        return NameplateApi.order({ ...values, orderData: undefined });
    },
    successMessage: translate('quickschild.integrationView.orderForm.successMessage'),
    Content: ({ form }) => {
        return _jsxs(_Fragment, { children: [form.values.orderData.order && _jsx(WarningText, { children: translate('quickschild.integrationView.orderForm.orderExistsWarning', {
                        orderDate: moment(form.values.orderData.order.createdAt).format('DD.MM.YYYY')
                    }) }), form.render(), _jsx(BlueText, { children: translate('quickschild.integrationView.orderForm.fields.priceNote') })] });
    },
    onLoad: async (contractId) => {
        const orderData = await NameplateApi.getMetadata(contractId);
        return {
            contractId: orderData.contract.id,
            rentalObjectId: orderData.rentalObject.id,
            propertyId: orderData.property.id,
            row1: orderData.contract.primaryContractPartyName,
            row2: orderData.contract.secondaryContractPartyName ?? '',
            nameplatesTo: orderData.property.hasFacilityManager
                ? ContractRole.FACILITY_MANAGER
                : ContractRole.TENANT,
            orderData: orderData,
        };
    },
    Footer: ({ saveForm, form: { loading } }) => {
        return _jsx(Button, { loading: loading, disabled: loading, onClick: () => saveForm(true), children: translate('quickschild.integrationView.orderForm.order') });
    },
});
