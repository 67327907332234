export var CostType;
(function (CostType) {
    CostType["_01"] = "heating";
    CostType["_02"] = "hot_water";
    CostType["_03"] = "ventilation";
    CostType["_04"] = "operating_costs";
    CostType["_05"] = "cold_water";
    CostType["_06"] = "electricity";
    CostType["_07"] = "cold";
    CostType["_08"] = "user_change";
    CostType["_09"] = "heating_hot_water";
    CostType["_10"] = "sewage";
})(CostType || (CostType = {}));
