import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classes from '../styles';
import { translate } from 'Services/Translator';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { FavouriteToggle } from './ListGroup';
import classNames from 'classnames';
import Portfolio from 'Models/Portfolio';
import { FlexCol, FlexRow } from 'Atoms/Layout';
import { EllipsisText, Text } from 'Atoms/Text';
export const RentalObjectItem = ({ rentalObject, onSelected: _onSelected, isSelectedItem = Portfolio.hasRentalObjectScope(rentalObject.id), onToggleFavourite, hideAddress, forwardedRef = null, }) => {
    const portfolio = usePortfolio();
    const data = useMemo(() => {
        const building = portfolio.getBuildingById(rentalObject.buildingId);
        const contracts = portfolio.getContractsByRentalObjectId(rentalObject.id);
        const tenants = contracts?.[0]?.tenants?.join?.(' & ');
        return {
            building,
            tenants,
        };
    }, [rentalObject.id]);
    return (_jsx("li", { tabIndex: 0, ref: forwardedRef, className: classNames({
            [`${classes.selected}`]: isSelectedItem
        }), onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            _onSelected({ level: "rentalObject", id: rentalObject.id });
        }, children: _jsxs(FlexRow, { alignCenter: true, children: [_jsx(FavouriteToggle, { active: portfolio.isFavouriteRentalObject(rentalObject.id), onToggle: () => {
                        portfolio.toggleFavouriteRentalObject(rentalObject.id);
                        onToggleFavourite();
                    } }), _jsxs(FlexCol, { flexGrow: 1, gap: 2, children: [_jsx(Text, { as: EllipsisText, body: 500, colorName: data?.tenants ? undefined : 'neutral.$300', children: data?.tenants || translate('rentalObject.state.vacancy') }), _jsxs(Text, { as: FlexRow, small: 400, gap: 8, alignCenter: true, children: [_jsx("span", { children: rentalObject.name }), (!hideAddress && data?.building) && _jsxs(_Fragment, { children: [_jsx(Text, { body: true, children: "\u00B7" }), _jsx(Text, { neutral: 300, children: data?.building.name })] })] })] })] }) }));
};
