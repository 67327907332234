import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef, useMemo } from 'react';
import { cssVars } from '@f2w/theme';
const prefix = 'fw-row';
const DEVICE_SIZES = ['xl', 'lg', 'md', 'sm', 'xs'];
const BaseRow = forwardRef(({ className, noGutters, style, gap, as: Component = 'div', ...props }, ref) => {
    const sizePrefix = `${prefix}-cols`;
    const classes = [];
    DEVICE_SIZES.forEach((brkPoint) => {
        const propValue = props[brkPoint];
        delete props[brkPoint];
        let cols;
        if (propValue != null && typeof propValue === 'object') {
            ({ cols } = propValue);
        }
        else {
            cols = propValue;
        }
        const infix = brkPoint !== 'xs' ? `-${brkPoint}` : '';
        if (cols != null)
            classes.push(`${sizePrefix}${infix}-${cols}`);
    });
    const _vars = useMemo(() => cssVars({
        gap
    }, '--fw-row-'), [gap]);
    return (_jsx(Component, { ref: ref, ...props, style: { ..._vars, ...style }, className: classNames(className, prefix, noGutters && 'no-gutters', ...classes) }));
});
BaseRow.displayName = 'Row';
export const Row = Object.assign(BaseRow, {
    Root: `.${prefix}`,
});
