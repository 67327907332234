export function OrArray(value) {
    return Array.isArray(value) ? value : [value];
}
export function toArray(value) {
    return value == null ? [] : [].concat(value);
}
export const isEmptyArray = (value) => Array.isArray(value) && value.length === 0;
export const range = (length, startWith = 0) => [
    ...Array(length).keys()
].map(i => i + startWith);
export const arrayNext = (arr, index) => (++index < arr.length ? arr[index] : undefined);
export const arrayPrev = (arr, index) => --index >= 0 ? arr[index + 1] : undefined;
export const arrayFirst = (arr) => arr[0];
export const arrayLast = (arr) => arr[arr.length - 1];
export const arrayUnique = (arr) => (arr.filter((v, i, array) => v && array.indexOf(v) === i));
