export var ActiveView;
(function (ActiveView) {
    ActiveView[ActiveView["CUSTOMERS"] = 0] = "CUSTOMERS";
    ActiveView[ActiveView["CUSTOMER_DETAILS"] = 1] = "CUSTOMER_DETAILS";
    ActiveView[ActiveView["CUSTOMER_SUBSCRIPTION_DETAILS"] = 2] = "CUSTOMER_SUBSCRIPTION_DETAILS";
    ActiveView[ActiveView["FEATURE_FLAGS"] = 3] = "FEATURE_FLAGS";
    ActiveView[ActiveView["PATCH_NOTES"] = 4] = "PATCH_NOTES";
    ActiveView[ActiveView["MAINTENANCE"] = 5] = "MAINTENANCE";
    ActiveView[ActiveView["AUDIT_LOG"] = 6] = "AUDIT_LOG";
    ActiveView[ActiveView["HANDOVER_COST_OVERVIEW"] = 7] = "HANDOVER_COST_OVERVIEW";
})(ActiveView || (ActiveView = {}));
