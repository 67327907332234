import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "Atoms/Button";
import { Icon } from "Atoms/Icon";
import { OverlayTrigger, Tooltip } from "Atoms/Overlay";
import { UseStyled } from "@fw/theme";
const ButtonWrapper = UseStyled.styled('div', () => ({
    display: 'flex',
}));
const renderRentPotentialButton = (rent, showRentPotential) => (_jsx(ButtonWrapper, { children: _jsx(Button, { disabled: !rent.canCalculateRentPotential, style: { border: 'none' }, color: 'primary', variant: 'outline', "$size": 'lg', onClick: () => showRentPotential(rent.id), children: _jsx(Icon, { icon: 'stock' }) }) }));
const wrapWithTooltipOverlay = (content, id) => (_jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: 'potential-tooltip-' + id, children: _jsxs("div", { style: { textAlign: "left" }, children: [_jsx("p", { children: "F\u00FCr den Mietzinsrechner m\u00FCssen folgende Angaben auf dem Mietzins hinterlegt sein:" }), _jsxs("ul", { children: [_jsx("li", { children: "Konsumentenpreisindex Basis und Stand" }), _jsx("li", { children: "Letzte Anpassung Referenzzinssatz" }), _jsx("li", { children: "Letzte Kostenaufrechnung" })] })] }) }), children: content }));
export const RentPotentialCalculationButton = ({ rent, onClick }) => {
    return !rent.canCalculateRentPotential
        ? wrapWithTooltipOverlay(renderRentPotentialButton(rent, onClick), rent.id)
        : renderRentPotentialButton(rent, onClick);
};
