export class TimeoutHandler {
    timeoutHandle;
    clear() {
        clearTimeout(this.timeoutHandle);
        this.timeoutHandle = null;
    }
    set(fn, delayMs = 0) {
        this.clear();
        this.timeoutHandle = setTimeout(fn, delayMs);
    }
}
