import { UseStyled, useTheme } from "@f2w/theme";
import { css } from "styled-components";
const CheckoutSubscriptionCartBase = UseStyled.styled('div', () => ({}));
const SectionTitle = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        color:  ${palette.neutral.$900};
        margin-bottom: ${rem(32)};
        ${typography.large.css({ fontWeight: 500 })};
    `;
});
const PlanRow = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display:flex;
        justify-content: space-between;
        color:  ${palette.neutral.$500};
        margin-bottom: ${rem(16)};
        ${typography.medium.fontStyles};
    `;
});
const MoneyStyle = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        color:  ${palette.neutral.$900};
    `;
});
const MoneyTotalStyle = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        color:  ${palette.neutral.$900};
        margin-bottom: ${rem(24)};
        ${typography.body.css({ fontWeight: 600 })};
    `;
});
const QuantityStyle = UseStyled.styled('span', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        color:  ${palette.primary.$500};
    `;
});
const QuantityPlanNameSection = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        ${typography.medium.fontStyles};
        color:  ${palette.neutral.$500};
    `;
});
const CheckoutSubscriptionCartStyled = Object.assign(CheckoutSubscriptionCartBase, {
    SectionTitle,
    PlanRow,
    MoneyStyle,
    MoneyTotalStyle,
    QuantityStyle,
    QuantityPlanNameSection
});
export default CheckoutSubscriptionCartStyled;
