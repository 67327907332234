import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, } from 'react';
import getComponentName from "Utils/getComponentName";
import hoistStatics from "hoist-non-react-statics";
const context = React.createContext({});
export const DataTableConsumer = context.Consumer;
export const DataTableProvider = (props) => {
    if (!props.children || !props.instance) {
        return null;
    }
    return _jsx(context.Provider, { value: { instance: props.instance }, children: props.children });
};
export const useDataTableContext = () => {
    return useContext(context).instance;
};
export function withDataTable(Component, SubComponents) {
    const Instance = React.forwardRef((props, ref) => {
        const instance = props['instance'] || useDataTableContext();
        if (process.env.NODE_ENV !== 'production' && !instance) {
            console.warn(`[withDataTable] You are not using a DataTableProvider or passing a instance prop in component class "${getComponentName(Component)}"`);
        }
        return _jsx(Component, { ...props, instance: instance, ref: ref });
    });
    SubComponents && hoistStatics(Instance, SubComponents);
    hoistStatics(Instance, Component);
    Instance.displayName = `WithDataTable(${getComponentName(Component)})`;
    return Instance;
}
