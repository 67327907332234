import { jsx as _jsx } from "react/jsx-runtime";
import { Select, UseOption } from 'Atoms/Select';
import { useMemo } from 'react';
import { CreditorState } from 'Pages/Creditor/enums';
import { translate } from 'Services/Translator';
export const CreditorStateFilter = ({ column: { filterValue, setFilter } }) => {
    const options = useMemo(() => [
        { value: CreditorState.QR_PENDING, label: translate('creditor.state.qr_pending') },
        { value: CreditorState.QR_COMPLETED, label: translate('creditor.state.qr_completed') },
        { value: CreditorState.INFO_PENDING, label: translate('creditor.state.info_pending') },
        { value: CreditorState.INFO_CONFIRMED, label: translate('creditor.state.info_confirmed') },
        { value: CreditorState.INVOICE_ASSIGNED, label: translate('creditor.state.invoice_assigned') },
        { value: CreditorState.READY_FOR_PAYMENT, label: translate('creditor.state.ready_for_payment') },
        { value: CreditorState.BANK_FILE_CREATED, label: translate('creditor.state.bank_file_created') },
        { value: CreditorState.PARTIALLY_PAID, label: translate('creditor.state.partially_paid') },
        { value: CreditorState.PAID, label: translate('creditor.state.paid') },
        { value: CreditorState.OVERPAID, label: translate('creditor.state.overpaid') },
    ], []);
    return (_jsx(Select, { value: filterValue !== undefined ? filterValue : '', isClearable: true, isSearchable: true, isMulti: true, options: options, onChange: (newValue) => setFilter(UseOption.getValue(newValue)) }));
};
