export var MaintenanceType;
(function (MaintenanceType) {
    MaintenanceType["INTERNAL"] = "internal";
    MaintenanceType["INTERNAL_EXTERNAL"] = "internal_external";
    MaintenanceType["EXTERNAL"] = "external";
})(MaintenanceType || (MaintenanceType = {}));
export var MaintenanceSeverity;
(function (MaintenanceSeverity) {
    MaintenanceSeverity["INFO"] = "info";
    MaintenanceSeverity["WARNING"] = "warning";
    MaintenanceSeverity["ERROR"] = "error";
})(MaintenanceSeverity || (MaintenanceSeverity = {}));
