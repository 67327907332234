import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import camelize from 'dom-helpers/camelize';
export function dataAttr(value) {
    return (value === true ? '' : value ? String(value) : undefined);
}
export function ariaAttr(value) {
    return (value === true ? true : value ? String(value) : undefined);
}
export function slotAttr(scope, part) {
    return ({
        'data-scope': scope || undefined,
        'data-part': part || undefined,
    });
}
export function slotCss(scope, ...parts) {
    const scopeCss = scope ? `[data-scope="${scope}"]` : '';
    const cssParts = parts.flatMap(part => (part ? `[data-part="${part}"]` : []));
    if (!cssParts.length)
        return scopeCss;
    if (cssParts.length === 1)
        return `${scopeCss}${cssParts.shift()}`;
    return `${scopeCss}:is(${cssParts.join(',')})`;
}
export function createWithScope(scope, part, options = Object.create(null)) {
    const { tag: InitialTag, defaultProps, props: getProps = p => p, className: initialClassName, displayName = pascalCase(`${scope}-${part}`), } = options;
    const scopeProps = slotAttr(scope, part);
    const RenderFn = ({ tag: Tag = InitialTag || 'div', ...rest }, ref) => {
        const props = getProps({ ref, ...scopeProps, ...rest });
        if (initialClassName)
            props.className = classNames(initialClassName, props.className);
        return (_jsx(Tag, { ref: ref, ...props }));
    };
    const RefComponent = React.forwardRef(RenderFn);
    RefComponent.displayName = displayName;
    RefComponent.defaultProps = defaultProps;
    RefComponent.css = slotCss(scope, part);
    return RefComponent;
}
const pascalCase = (str) => str[0].toUpperCase() + camelize(str).slice(1);
