import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Select } from 'Atoms/Select';
const FiscalYearSelect = ({ fiscalYears, ...props }) => {
    const options = useMemo(() => fiscalYears.map((fiscalYear) => ({
        value: fiscalYear.id,
        label: fiscalYear.label,
    })), [fiscalYears]);
    return (_jsx(Select, { options: options, menuPortalTarget: document.body, ...props }));
};
export default FiscalYearSelect;
