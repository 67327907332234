import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { translate } from "Services/Translator";
import { Badge, Button, FlexCol, Icon, SafeAnchor, Tbody, Td, TdActions, Text, Tr } from 'Components/Atoms';
import { ContractState } from "@fw/enums/ContractState";
import generateRoute from "Services/Router";
import { Section } from 'Atoms/Layout/components';
const getStateBadge = (state) => {
    let color = 'primary';
    switch (state) {
        case ContractState.ACTIVE:
            color = 'success';
            break;
        case ContractState.UPCOMING:
            color = 'primary';
            break;
        case ContractState.TERMINATED:
            color = 'danger';
            break;
        case ContractState.NOTICED:
            color = 'warning';
            break;
    }
    return _jsx(Badge, { "$size": 'md', "$color": color, children: translate(`contract.state.${state}`) });
};
export const ContractsTab = ({ group: { data }, onStreetClick }) => {
    const getContractDetailsUrl = React.useCallback((contractId) => {
        return generateRoute('contracts.overview', { contractId });
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Section.Table, { hover: false, variant: 'list', stickyHead: 0, colSpan: 4, children: _jsx(Tbody, { children: data.contracts.map(contract => (_jsxs(Tr, { children: [_jsx(Td, { min: true, children: _jsx(Icon, { icon: 'map-pin', size: 24 }) }), _jsx(Td, { style: { width: "100%" }, children: _jsxs(FlexCol, { children: [_jsx(Text, { body: 500, children: _jsxs(SafeAnchor, { nested: true, onClick: () => onStreetClick(contract.rentalObject.id)(), children: [contract.building.name, " | ", contract.rentalObject.name] }) }), _jsx(Text, { pretitle: 400, neutral: 300, children: translate(`contract.person.role.${contract.role}`) })] }) }), _jsx(Td, { align: 'left', children: getStateBadge(contract.status) }), _jsx(TdActions, { id: contract.id, sticky: 'right', quickActions: [
                                    {
                                        icon: 'eye',
                                        $size: 'lg',
                                        title: translate('btn.view'),
                                        href: getContractDetailsUrl(contract.id),
                                        target: "_blank"
                                    },
                                ] })] }, contract.id))) }) }), _jsx(Section, { sticky: { bottom: 0 }, style: { textAlign: 'right' }, children: _jsx(Button, { label: translate('person.details.seeAll'), href: generateRoute('contracts.overview', { personId: data.id }), target: '_blank' }) })] }));
};
