import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Body, Container, Header, Section } from 'Atoms/Layout/components';
import { Button, FlexCol, Text } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import styled from 'styled-components';
import Logo from 'Components/Atoms/Logo';
import { FormTheme } from '@f2w/form';
const HeaderLogo = styled.div `
  display: flex;
  justify-content: ${p => p.partner ? 'space-between' : 'center'};
  align-items: center;

  > * {
    flex-shrink: 1;
  }
`;
const Registration = Object.assign(styled(Body) `
  --fw-padding-y: 0;
  --fw-padding-x: 0;
  --fw-margin-y: 72px;
  --fw-max-width: 520px;

  overflow: visible;
  margin: 0 auto;

  ${Section.Base} {
    text-align: center;
    //p {
    //
    //  text-align: left;
    //}
  }

  ${Section.Base}[data-form] {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    //gap: 20px;
    --form-row-spacing: 40px;

  ${FormTheme.Group.Label} ${FormTheme.Label.Text} {
      ${p => p.theme.typography.h6.fontSizeStyles};
      margin: 24px 0;
    }
  }
`, {});
const FormTemplate = ({ view, view: { form, activeGroup: group }, formChildren, }) => {
    return _jsxs(Section, { "data-form": true, children: [formChildren, form?.status?.error && (_jsxs(FlexCol, { gap: 10, style: { marginBottom: 'var(--form-row-spacing,1.5rem)' }, children: [_jsx(Text, { className: 'fw-form-general-error', as: 'div', error: true, children: form?.status?.error }), _jsx(Text, { dangerouslySetInnerHTML: { __html: translate('registration.error.contactUs') } })] })), _jsx(Button, { label: translate('account.create.action'), "$size": "lg", block: true, loading: form?.loading, onClick: () => view.saveForm() })] });
};
const RegistrationTemplateBase = ({ view: { activeGroup: group, type: { templateProps: { logo } } }, children, header, }) => {
    const logoImage = logo && _jsx("img", { ...logo });
    const title = group?.title;
    return _jsx(Registration, { children: _jsxs(Container, { children: [_jsxs(Header, { children: [_jsxs(HeaderLogo, { partner: !!logoImage, children: [_jsx(Text, { as: Logo, primary: true }), logoImage] }), header && _jsx(Section.Base, { children: header }), _jsx(Section.Base, { children: group?.render('ContentHeader') ?? (title && _jsx(Text, { as: 'h3', size: 'h3', children: title })) })] }), children] }) });
};
export const RegistrationTemplate = Object.assign(RegistrationTemplateBase, {
    Root: Registration,
    Form: FormTemplate,
});
