import { AbstractFormType, BooleanType, DateType, EmailType, GenderType, ImageType, PhoneType, SelectType, StringType, } from '@f2w/form';
import { translate } from 'Services/Translator';
import { UserLanguage } from 'Pages/Profile/Profile/UserLanguage';
import getFirstLetters from 'Utils/getFirstLetters';
export class ProfileEditType extends AbstractFormType {
    buildFields() {
        const fields = {
            image: new ImageType({
                required: false,
                variant: 'avatar',
                getProps: () => ({
                    placeholder: getFirstLetters(`${fields.firstName.value} ${fields.lastName.value}`)
                }),
            }),
            gender: new GenderType({
                label: translate('user.salutation'),
                nullable: true,
            }),
            firstName: new StringType({
                label: translate('user.firstName'),
                required: true,
                constraints: {
                    max: 16
                }
            }),
            lastName: new StringType({
                label: translate('user.lastName'),
                required: true,
                constraints: {
                    max: 16
                }
            }),
            email: new EmailType({
                label: translate('user.email'),
                required: true,
            }),
            cellPhoneNumber: new PhoneType({
                label: translate('user.cellPhoneNumber'),
                required: false,
            }),
            dateOfBirth: new DateType({
                label: translate('user.dateOfBirth'),
                nullable: true,
            }),
            jobTitle: new StringType({
                label: translate('user.jobTitle'),
                required: false,
                constraints: {
                    max: 32
                }
            }),
            preferredLanguage: new SelectType({
                label: translate('person.preferredLanguage'),
                choices: {
                    [UserLanguage.GERMAN]: translate('language.de'),
                    [UserLanguage.ENGLISH]: translate('language.en'),
                    [UserLanguage.FRENCH]: translate('language.fr'),
                    [UserLanguage.ITALIAN]: translate('language.it'),
                },
                nullable: false,
                defaultValue: UserLanguage.GERMAN,
                menuPlacement: 'bottom',
            }),
            grantImpersonation: new BooleanType({
                label: translate('user.grantImpersonation'),
                help: translate('user.grantImpersonation.help'),
                required: false,
                defaultValue: false
            }),
        };
        return fields;
    }
}
