import { Table } from '@f2w/data-table/table-types';
const isObjectOrArray = (v) => v && typeof v === 'object';
const isObject = (v) => v && typeof v === 'object' && !Array.isArray(v);
const toObject = (v) => isObject(v) ? v : {};
function _convertValues(converter, ...args) {
    const src = toObject(args.shift());
    for (let target of args) {
        if (isObject(target)) {
            for (let key in target) {
                const val = target[key] ? converter(key, src[key], target[key]) : target[key];
                if (val === undefined || val === null) {
                    src[key] = null;
                    delete src[key];
                }
                else {
                    src[key] = val;
                }
            }
        }
    }
    return src;
}
function _mergeValues(converter, ...args) {
    const src = toObject(args.shift());
    for (let target of args) {
        if (isObject(target)) {
            converter(src, target);
            for (let key in target) {
                src[key] = target[key] ? converter(key, src[key], target[key]) : target[key];
            }
            return src;
        }
    }
    return src;
}
export const mergePluginOptions = (plugin, ...args) => _convertValues((key, src, target) => {
    if (typeof plugin.mergeOption === 'function')
        target = plugin.mergeOption(key, src, target);
    if (isObjectOrArray(target)) {
        switch (key) {
            case 'defaultColumn':
                return mergeColumnOptions(src, target);
            case 'initialState':
                return { ...src, ...target };
        }
    }
    return target;
}, ...args);
export const mergeColumnOptions = (...args) => _convertValues((key, src, target) => {
    if (!isObjectOrArray(target))
        return target;
    switch (key) {
        case 'columns':
            return mergeColumnsOptions(src, target);
        case 'style':
            return { ...src, ...target };
    }
    if (Table.plugins[key]) {
        return mergeColumnPluginOptions(Table.plugins[key], src, target);
    }
    return target;
}, ...args);
export const mergeColumnPluginOptions = (plugin, ...args) => _convertValues((key, src, target) => {
    if (typeof plugin.mergeColumnOption === 'function')
        target = plugin.mergeColumnOption(key, src, target);
    return target;
}, ...args);
export const mergeColumnsOptions = (...args) => _convertValues((key, src, target) => {
    if (!isObject(target))
        return undefined;
    return mergeColumnOptions(src, target);
}, ...args);
const mergeOptions = (...args) => _convertValues((key, src, target) => {
    if (!isObjectOrArray(target))
        return target;
    switch (key) {
        case 'defaultColumn':
            return mergeColumnOptions(src, target);
        case 'initialState':
            return Object.assign(toObject(src), toObject(target));
        case 'columns':
            return mergeColumnsOptions(src, target);
    }
    if (Table.plugins[key]) {
        return mergePluginOptions(Table.plugins[key], src, target);
    }
    return target;
}, ...(resolveArgs(args)));
export default mergeOptions;
function resolveArgs(args) {
    return args.flat(2).map(v => (typeof v === "function" ? v() : v)).filter(v => isObject(v));
}
