import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef, useContext, useEffect, useRef } from 'react';
import useEventCallback from '@restart/hooks/useEventCallback';
import { Icon } from 'Atoms/Icon';
import { UnstyledButton } from 'Atoms/Button/UnstyledButton';
import { makeEventKey, NavContext, SelectableContext } from 'Components/Base';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import { dropdownSlot } from '../Dropdown.theme';
export const DropdownHeader = dropdownSlot.create('header', {
    className: 'fw-dropdown-header',
    defaultProps: {
        role: 'heading',
    },
});
export const DropdownDivider = dropdownSlot.create('divider', {
    className: 'fw-dropdown-divider',
    defaultProps: {
        role: 'separator',
    },
});
export const DropdownItemText = dropdownSlot.create('itemText', {
    tag: 'span',
    className: 'fw-dropdown-item-text',
    defaultProps: {},
});
const prefix = 'fw-dropdown-item';
export const DropdownItem = forwardRef(({ className, children, eventKey, disabled = false, href, onClick, onSelect, icon, keepOpen, active: propActive, scrollIntoView, label, as2: Component = UnstyledButton, ...props }, ref) => {
    const onSelectCtx = useContext(SelectableContext);
    const navContext = useContext(NavContext);
    const elRef = useRef();
    const { activeKey } = navContext || {};
    const key = makeEventKey(eventKey, href);
    const active = propActive == null && key != null
        ? makeEventKey(activeKey) === key
        : propActive;
    if (active)
        props['aria-selected'] = true;
    if (disabled)
        props['aria-disabled'] = true;
    const handleClick = useEventCallback((event) => {
        if (disabled)
            return;
        if (onClick)
            onClick(event);
        if (!keepOpen && onSelectCtx)
            onSelectCtx(key, event);
        if (onSelect)
            onSelect(key, event);
    });
    const mergedRef = useMergedRefs(ref, elRef);
    useEffect(() => {
        if (active && scrollIntoView && elRef.current) {
            elRef.current.scrollIntoViewIfNeeded();
        }
    }, [active, scrollIntoView]);
    return (_jsxs(Component, { ...dropdownSlot.attr('item'), ...props, ref: mergedRef, href: href, disabled: disabled, className: classNames(className, prefix, active && 'active', disabled && 'disabled'), onClick: handleClick, children: [icon && _jsx(Icon, { strokeWidth: 1.8, icon: icon }), label && _jsx("span", { children: label }), children] }));
});
DropdownItem.displayName = 'DropdownItem';
