import React from 'react';
import ProcessDataTableModel from 'Pages/Process/models/ProcessDataTableModel';
import { useModalDispatcherConfirm, useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
export const useProcessTable = (processServices) => {
    const tableModel = React.useMemo(() => ProcessDataTableModel.create({
        manualControl: true,
    }), []);
    const getData = React.useCallback(({ request }) => {
        return processServices.listProcesses(request);
    }, []);
    const { success, error } = useToastDispatcherApi();
    const onDeleteCreditorInvoice = React.useCallback((id, refresh) => {
        processServices.deleteProcess(id)
            .then(() => {
            success({
                timeout: 2,
                message: translate('flash.process.deletion.success')
            });
            refresh();
        })
            .catch(({ response }) => {
            const errorMessage = (response.status === 400)
                ? response.data.message
                : translate('flash.process.deletion.generalError');
            error({
                timeout: 5,
                message: errorMessage
            });
        });
    }, []);
    const onDeleteConfirmation = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: onDeleteCreditorInvoice,
        message: translate('process.deletion.areYouSure'),
        help: translate('process.deletion.confirmation'),
        btnConfirm: translate('btn.confirm')
    });
    const instance = tableModel.createRemote({
        getData,
        onDeleteClicked: onDeleteConfirmation
    });
    return {
        instance
    };
};
