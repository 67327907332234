import { AbstractFormType, DateType, MoneyType, SelectType, StringType } from "@f2w/form";
import { translate } from "Services/Translator";
import React from "react";
import moment from "moment";
import { useFinancialAccountsHook } from "Hooks/useFinancialAccountsHook";
export class ManualPaymentType extends AbstractFormType {
    financialAccounts = [];
    _useField(props) {
        super._useField(props);
        const { groupedFinancialAccountOptions } = useFinancialAccountsHook({
            propertyId: this.field.value.propertyId
        });
        this.financialAccounts = groupedFinancialAccountOptions;
        React.useEffect(() => {
            this.getChild('paidAt').field.setValue(moment().format('YYYY-MM-DD'), false);
        }, []);
    }
    buildFields() {
        return {
            label: new StringType({
                label: translate('creditor.label'),
                required: true
            }),
            amount: new MoneyType({
                label: translate('amount'),
                required: true
            }),
            paidAt: new DateType({
                label: translate('payment.paidAt'),
                required: true
            }),
            creditAccountId: new SelectType({
                label: translate('creditor.payment.position'),
                required: true,
                choiceOptions: () => this.financialAccounts
            })
        };
    }
}
