import { AbstractFormType, BooleanType, FloorType, NumberType, SelectType, StringType, TextType } from '@f2w/form';
import { translate } from "Services/Translator";
import React from "react";
import { ObjectViewModalServices } from "Components/ObjectViewModal";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { rentalObjectTypeOptions } from "Enums/RentalObjectType";
export class RentalObjectViewFormType extends AbstractFormType {
    static getFloorTrans(floor) {
        if (floor > 0) {
            return translate('baseData.rentalObject.floor.upper', {
                floor: floor,
            });
        }
        if (floor < 0) {
            return translate('baseData.rentalObject.floor.basement', {
                floor: Math.abs(floor),
            });
        }
        return translate('baseData.rentalObject.floor.ground');
    }
    static useFloorTranslation(floor) {
        return React.useMemo(() => RentalObjectViewFormType.getFloorTrans(floor), [floor]);
    }
    buildFields() {
        return {
            generalLeft: new RentalObjectType({}),
        };
    }
}
export class RentalObjectType extends AbstractFormType {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: false,
        };
    }
    bankAccounts = [];
    _useField(props) {
        super._useField(props);
        const [bankAccounts, setBankAccounts] = React.useState([]);
        this.bankAccounts = bankAccounts;
        const portfolio = usePortfolio();
        React.useEffect(() => {
            ObjectViewModalServices
                .getRentalObjectViewDropdownData()
                .then(response => {
                const propertyForRentalObject = portfolio.getPropertyByRentalObjectId(this.parent.field.value.id);
                const mainBankAccountGroup = {
                    label: propertyForRentalObject.name,
                    options: []
                };
                const otherBankAccountGroup = {
                    label: translate('bankAccounts.group.other'),
                    options: []
                };
                response?.bankAccounts?.forEach(bankAccount => {
                    const foundProperty = bankAccount.properties.find(property => property.id === propertyForRentalObject.id);
                    if (foundProperty) {
                        mainBankAccountGroup.options.push({
                            value: bankAccount.id,
                            label: bankAccount.name
                        });
                    }
                    else {
                        otherBankAccountGroup.options.push({
                            value: bankAccount.id,
                            label: bankAccount.name
                        });
                    }
                });
                setBankAccounts([
                    mainBankAccountGroup,
                    otherBankAccountGroup
                ]);
            });
        }, []);
    }
    buildFields() {
        return {
            name: new StringType({
                label: translate('baseData.rentalObject.name'),
                required: true
            }),
            ewid: new StringType({
                label: translate('baseData.rentalObject.ewid'),
                required: false,
                constraints: {
                    max: [10, translate('form.validation.noLongerThan', { length: 10 })]
                }
            }),
            type: new SelectType({
                label: translate('baseData.rentalObject.type'),
                required: true,
                choiceOptions: () => rentalObjectTypeOptions
            }),
            floor: new FloorType({
                label: translate('createProperty.rentalObject.floor'),
                defaultValue: 0,
            }),
            numberOfRooms: new NumberType({
                label: translate('createProperty.rentalObject.numberOfRooms'),
                step: .5,
                min: 0,
                defaultValue: 0,
            }),
            area: new NumberType({
                label: translate('baseData.rentalObject.area'),
                required: false,
                constraints: {
                    min: [0, translate('baseData.rentalObject.editForm.validation.areaMustBeGreaterOrEqualsZero')]
                }
            }),
            volume: new NumberType({
                label: translate('baseData.rentalObject.volume'),
                required: false,
                constraints: {
                    min: [0, translate('baseData.rentalObject.editForm.validation.volumeMustBeGreaterOrEqualsZero')]
                }
            }),
            rentBankAccountId: new SelectType({
                label: translate('baseData.rentalObject.rentBankAccount'),
                choiceOptions: () => this.bankAccounts,
                searchable: true,
                required: false,
                clearable: true
            }),
            isAncillaryExpenseRelevant: new BooleanType({
                label: translate('baseData.rentalObject.isAncillaryExpenseRelevant'),
                required: false,
                defaultValue: true
            }),
            notes: new TextType({
                label: translate('baseData.rentalObject.notes'),
                required: false,
                nullable: false,
            })
        };
    }
}
