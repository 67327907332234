import { Category } from "Pages/CustomerDocuments/enums";
import { translate } from "Services/App";
export const categoryOptions = Object.values(Category).filter(value => typeof value === 'number').map(value => ({
    value: value,
    label: translate(`customerDocuments.category.${value}`),
})).sort((a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
});
