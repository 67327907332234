import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import { FlexRow } from 'Components/Atoms';
import { formatMoney } from 'Services/NumberHelper';
import { Section } from 'Atoms/Layout/components';
import { AncillaryExpenseType } from 'Pages/AncillaryExpense/enums';
import { MoneyType } from '@f2w/form';
import { Card } from 'Atoms/Card';
export const AncillaryExpensesTab = ({ group: { data: contract, }, }) => {
    const { rent, ancillaryExpenses } = contract;
    const [floatingExpenses, fixedExpenses, fixedSum] = React.useMemo(() => {
        const floatingExpenses = [];
        const fixedExpenses = [];
        let fixedSum = 0;
        for (const ae of ancillaryExpenses) {
            if (ae.collectionType === AncillaryExpenseType.FIXED) {
                fixedExpenses.push({
                    ...ae,
                    value: ae.value ? formatMoney(ae.value) : null
                });
                fixedSum += ae.value;
            }
            else {
                floatingExpenses.push(ae);
            }
        }
        return [floatingExpenses, fixedExpenses, fixedSum];
    }, [ancillaryExpenses]);
    const totalAmount = (rent?.grossAmount ?? 0) - (rent?.netAmount ?? 0);
    const floatingSum = totalAmount - fixedSum;
    return (_jsx(Section, { children: _jsxs(FlexRow, { gap: 16, alignFlexStart: true, justifySpaceBetween: true, children: [_jsx(Card, { children: _jsx(Card.Value, { title: translate('contract.details.ancillaryExpenses.total'), children: MoneyType.format(totalAmount) }) }), _jsxs(Card, { children: [_jsx(Card.Value, { title: translate('contract.details.ancillaryExpenses.floating'), children: MoneyType.format(floatingSum) }), _jsx(Card.Text, { children: floatingExpenses.map(ae => (_jsx(FlexRow, { justifyContent: "space-between", children: _jsx("div", { children: ae.label }) }, `floating-${ae.id}`))) })] }), _jsxs(Card, { children: [_jsx(Card.Value, { title: translate('contract.details.ancillaryExpenses.fixed'), children: MoneyType.format(fixedSum) }), _jsx(Card.Text, { children: fixedExpenses.map(ae => (_jsxs(FlexRow, { justifyContent: "space-between", children: [_jsx("div", { children: ae.label }), _jsx("div", { children: ae.value ? formatMoney(ae.value) : '' })] }, `fixed-${ae.id}`))) })] })] }) }));
};
