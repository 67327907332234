import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from 'Services/App';
import { Select } from 'Atoms/Select';
import { TransactionAssignmentType, TransactionStatusType } from '../../types';
import { PortfolioPropertySelect } from 'Components/App/PortfolioPropertySelect';
import { AssignmentTypeSelect } from 'Pages/BankingReconciliation/table/components/AssignmentTypeSelect';
import styled from 'styled-components';
import { FormControl } from 'Atoms/Input';
import { Div } from 'Atoms/Layout';
import generateRoute, { generate } from 'Services/Router';
import client from 'Utils/client';
export const defaultSelectProps = {
    $size: 'sm',
    $color: 'transparent',
    menuShouldBlockScroll: true,
    menuPortalTarget: document.body,
    useMaxContentWidth: true,
};
export function InPlaceText({ status = undefined, children }) {
    const isIgnored = status === TransactionStatusType.IGNORED;
    return _jsx("span", { style: { display: 'inline-block', color: isIgnored ? '#ccc' : 'inherit', }, children: children });
}
export function PropertyEdit({ updateApi, rowId }) {
    const { property, status, } = updateApi.getData(rowId);
    if (updateApi.isAssignable(rowId)) {
        return _jsx(PortfolioPropertySelect, { ...defaultSelectProps, placeholder: translate('bankingReconciliation.selectProperty'), value: property?.id, onChange: (option) => {
                updateApi.update(rowId, {
                    property: {
                        id: option?.value,
                        name: option?.label,
                    },
                    assignmentType: TransactionAssignmentType.DEBITOR_INVOICE,
                    assignment: {},
                });
            } });
    }
    return _jsx(InPlaceText, { status: status, children: property?.name });
}
export function AssignmentTypeEdit({ updateApi, rowId }) {
    const { assignmentType, status, } = updateApi.getData(rowId);
    if (updateApi.isAssignable(rowId)) {
        return _jsx(AssignmentTypeSelect, { ...defaultSelectProps, alignMenu: 'right', placeholder: translate('bankingReconciliation.selectType'), value: assignmentType || null, onChange: (option) => {
                updateApi.update(rowId, {
                    assignmentType: option?.value,
                    assignment: {},
                });
            } });
    }
    return _jsx(InPlaceText, { status: status, children: assignmentType ? translate('bankingReconciliation.assignTransactions.type.' + assignmentType) : '' });
}
var OptionsLoader;
(function (OptionsLoader) {
    async function financialAccount(inputValue) {
        const route = generateRoute('api.financialAccount.search', {
            q: inputValue
        });
        const { data } = await client.get(route);
        return data.map(({ id: value, label }) => ({ value, label }));
    }
    OptionsLoader.financialAccount = financialAccount;
    async function debitorInvoice(inputValue, propertyId) {
        const route = generate('json_debitorInvoice', {
            q: inputValue,
            property: propertyId,
            onlyPending: 1,
            excludeSubInvoices: 1
        });
        const { data } = await client.get(route);
        return data.map(({ value, text: label }) => ({ value, label }));
    }
    OptionsLoader.debitorInvoice = debitorInvoice;
    async function creditorInvoice(inputValue, propertyId) {
        const route = generate('json_creditorInvoice', {
            q: inputValue,
            property: propertyId,
            onlyPending: 1
        });
        const { data } = await client.get(route);
        return data.map(({ value, text: label }) => ({ value, label }));
    }
    OptionsLoader.creditorInvoice = creditorInvoice;
})(OptionsLoader || (OptionsLoader = {}));
export function InvoiceEdit({ updateApi, rowId }) {
    const selectProps = {
        ...defaultSelectProps,
        isClearable: true,
        isSearchable: true,
        alignMenu: 'right',
    };
    const { property, assignmentType, status, ...assignmentData } = updateApi.getData(rowId);
    switch (assignmentType) {
        case TransactionAssignmentType.DEBITOR_INVOICE: {
            const invoice = assignmentData?.debitorInvoice;
            const invoiceOption = invoice && { value: invoice.id, label: invoice.name };
            return _jsx(_Fragment, { children: !updateApi.isAssignable(rowId)
                    ? _jsx(InPlaceText, { status: status, children: invoice?.name })
                    : _jsx(Select, { ...selectProps, placeholder: translate('bankingReconciliation.selectInvoice'), value: invoiceOption || null, defaultOptions: invoiceOption ? [invoiceOption] : null, loadOptions: v => OptionsLoader.debitorInvoice(v, property?.id), onChange: (option) => {
                            updateApi.update(rowId, prev => ({
                                ...prev,
                                assignment: {
                                    debitorInvoice: option ? {
                                        id: option.value,
                                        name: option.label,
                                    } : undefined,
                                },
                            }));
                        } }) });
        }
        case TransactionAssignmentType.CREDITOR_INVOICE: {
            const invoice = assignmentData?.creditorInvoice;
            const invoiceOption = invoice && { value: invoice.id, label: invoice.name };
            return _jsx(_Fragment, { children: !updateApi.isAssignable(rowId)
                    ? _jsx(InPlaceText, { status: status, children: invoice?.name })
                    : _jsx(Select, { ...selectProps, placeholder: translate('bankingReconciliation.selectInvoice'), value: invoiceOption || null, defaultOptions: invoiceOption ? [invoiceOption] : null, loadOptions: v => OptionsLoader.creditorInvoice(v, property?.id), onChange: (option) => {
                            updateApi.update(rowId, prev => ({
                                ...prev,
                                assignment: {
                                    creditorInvoice: option ? {
                                        id: option.value,
                                        name: option.label,
                                    } : undefined,
                                },
                            }));
                        } }) });
        }
        case TransactionAssignmentType.MANUAL: {
            const debitAccount = assignmentData?.debitAccount;
            const creditAccount = assignmentData?.creditAccount;
            if (!updateApi.isAssignable(rowId)) {
                return _jsx(InPlaceText, { status: status, children: _jsxs(Div, { children: [debitAccount?.label, " / ", creditAccount?.label, " - ", assignmentData.accountingRecordLabel, "\u00A0", assignmentData.accountingRecordReceiptNumber
                                ? `(${assignmentData.accountingRecordReceiptNumber})`
                                : ''] }) });
            }
            const debitAccountOption = debitAccount && {
                value: debitAccount?.id,
                label: debitAccount?.label,
            };
            const creditAccountOption = creditAccount && {
                value: creditAccount?.id,
                label: creditAccount?.label,
            };
            return (_jsxs(ManualInvoiceRoot, { children: [_jsxs("div", { "data-slot": 'account', children: [_jsx(Select, { ...defaultSelectProps, isSearchable: true, placeholder: translate('bankingReconciliation.selectDebitAccount'), value: debitAccountOption, defaultOptions: debitAccountOption ? [debitAccountOption] : undefined, loadOptions: v => OptionsLoader.financialAccount(v), onChange: (option) => {
                                    updateApi.update(rowId, prev => ({
                                        ...prev,
                                        assignment: {
                                            ...prev?.assignment,
                                            debitAccount: option ? {
                                                id: option.value,
                                                label: option.label,
                                            } : undefined,
                                        },
                                    }));
                                } }), _jsx(Select, { ...selectProps, placeholder: translate('bankingReconciliation.selectCreditAccount'), value: creditAccountOption, loadOptions: v => OptionsLoader.financialAccount(v), defaultOptions: creditAccountOption ? [creditAccountOption] : undefined, onChange: (option) => {
                                    updateApi.update(rowId, prev => ({
                                        ...prev,
                                        assignment: {
                                            ...prev?.assignment,
                                            creditAccount: option ? {
                                                id: option.value,
                                                label: option.label,
                                            } : undefined,
                                        },
                                    }));
                                } })] }), _jsxs("div", { "data-slot": 'info', children: [_jsx(FormControl.Input, { placeholder: translate('accountingRecord.label'), "$size": 'sm', value: assignmentData?.accountingRecordLabel || '', onChange: (value) => {
                                    updateApi.update(rowId, prev => ({
                                        ...prev,
                                        assignment: {
                                            ...prev?.assignment,
                                            accountingRecordLabel: value
                                        },
                                    }));
                                } }), _jsx(FormControl.Input, { placeholder: translate('accountingRecord.customizableId.short'), "$size": 'sm', value: assignmentData?.accountingRecordReceiptNumber || '', onChange: (value) => {
                                    updateApi.update(rowId, prev => ({
                                        ...prev,
                                        assignment: {
                                            ...prev?.assignment,
                                            accountingRecordReceiptNumber: value
                                        },
                                    }));
                                } })] })] }));
        }
    }
    return null;
}
const ManualInvoiceRoot = styled.div `
    display: flex;
    flex-direction: column;
    gap: 10px;

    [data-slot="account"] {
        display: flex;
        gap: 10px;

        > * {
            flex: 1;
        }
    }
    [data-slot="info"] {
        display: flex;
        gap: 10px;

        > * {
            flex: 1;
        }
    }
`;
