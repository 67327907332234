import { IbanType } from "@f2w/form";
import { BaseBankAccountType } from "Pages/BankAccount/forms/BaseBankAccountType";
import { translate } from "Services/Translator";
export class UpdateRentBankAccountType extends BaseBankAccountType {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: false,
        };
    }
    buildFields() {
        const { label, owner, openingBalance, qrIntegration } = super.buildFields();
        openingBalance.isVisible = () => true;
        qrIntegration.isVisible = () => true;
        return {
            label,
            iban: new IbanType({
                required: true,
                forceIban: true
            }),
            owner,
            qrIntegration,
            openingBalance,
        };
    }
}
export class UpdateDepositBankAccountType extends BaseBankAccountType {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: false,
        };
    }
    buildFields() {
        const { label, iban, owner, personId } = super.buildFields();
        personId.isVisible = () => true;
        return {
            label,
            iban,
            owner,
            personId
        };
    }
}
export class UpdateTenantBankAccountType extends BaseBankAccountType {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: false,
        };
    }
    buildFields() {
        const { label, iban, owner, personId } = super.buildFields();
        personId.isVisible = () => true;
        personId.options.confirm = ({ current, prev }) => {
            if (this.field.initialValue.personId !== current && this.field.initialValue.personId != undefined) {
                return translate('person.changeTenantBankAccount.confirm');
            }
        };
        return {
            personId,
            label,
            iban,
            owner,
        };
    }
}
export class UpdateCreditBankAccountType extends BaseBankAccountType {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: false,
        };
    }
    buildFields() {
        const { label, iban, owner, creditorFinancialAccountId } = super.buildFields();
        creditorFinancialAccountId.isVisible = () => true;
        return {
            label,
            iban,
            owner,
            creditorFinancialAccountId
        };
    }
}
