import { jsx as _jsx } from "react/jsx-runtime";
import { UseStyled } from '@f2w/theme';
import { Title as BaseTitle } from 'Atoms/Text';
export const Title = (props) => _jsx(BaseTitle, { as: 'h1', h3: true, ...props });
export const Content = UseStyled.styled('div', ({ theme: { typography: { body: { fontStyles } }, palette: { dark } }, max, }) => ({
    ...fontStyles,
    color: dark.$500,
    margin: '0 auto 0 0',
    maxWidth: max ?? 'none'
}));
export const TableActions = UseStyled.styled('div', () => ({
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 0,
}));
export const TableActionsSearch = UseStyled.styled('div', () => ({
    flexGrow: 1,
    maxWidth: '400px',
    minWidth: '200px'
}));
export const TableFilters = UseStyled.styled('div', ({ show }) => ({
    display: show ? 'block' : 'none',
    margin: 0,
}));
