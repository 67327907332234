import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Collapse } from '../Collapse';
import AccordionContext from './AccordionContext';
import { SelectableContext } from 'Components/Base';
export const AccordionCollapse = React.forwardRef(({ children, eventKey, ...props }, ref) => {
    const contextEventKey = useContext(AccordionContext);
    return (_jsx(SelectableContext.Provider, { value: null, children: _jsx(Collapse, { ref: ref, in: contextEventKey === eventKey, ...props, children: _jsx("div", { children: React.Children.only(children) }) }) }));
});
AccordionCollapse.displayName = 'AccordionCollapse';
