import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import { formatMoney } from 'Services/NumberHelper';
import { formatDateShort } from 'Services/DateHelper';
import moment from 'moment';
import { formatBytes } from "@f2w/utils";
export const SubscriptionTableModel = Table.factory()(() => ({
    tableId: 'Subscription',
    usePagination: {},
    columns: {
        name: {
            Header: translate('subscription.payment.subscription.table.name.title')
        },
        price: {
            Header: translate('subscription.payment.subscription.table.price.title'),
            Cell: ({ value, row: { original } }) => original.isFree
                ? translate('subscription.noCharge')
                : formatMoney(value)
        },
        nextBillingDate: {
            Header: translate('subscription.payment.subscription.table.period.title'),
            Cell: ({ value }) => value ? formatDateShort(moment(value)) : '–'
        },
        limits: {
            Header: 'Limits',
            Cell: ({ value: limits }) => _jsxs(_Fragment, { children: [translate('subscriptions.nbRentalObjects'), ": ", limits.objects, _jsx("br", {}), translate('subscriptions.nbUsers'), ": ", limits.users, _jsx("br", {}), translate('subscriptions.storage'), ": ", formatBytes(limits.space * 1000 * 1000)] })
        }
    }
}));
