import { FormError, useFormModal } from "@f2w/form";
import { translate } from "Services/Translator";
import { SplitPaymentType } from "../components/DebitorView/forms/SplitPaymentType";
const handleError = (error) => {
    const status = error.response.status;
    if (status === 422) {
        throw new FormError(error.response.data.message);
    }
    else if (status == 400) {
        throw new FormError(error.response.data);
    }
    throw new FormError("");
};
export const useSplitPaymentForm = (createSplitPayment, loadPaymentData, onSubmit) => useFormModal({
    name: 'split-payment-form',
    type: () => new SplitPaymentType(),
    onLoad: async (debitorInvoiceId) => ({
        debitorInvoiceId
    }),
    title: translate('debitor.actions.split'),
    onSave: async ({ debitorInvoiceId, ...values }) => {
        return createSplitPayment(debitorInvoiceId, values)
            .then(onSubmit)
            .catch(handleError);
    }
});
