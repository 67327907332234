import { useForm } from "@f2w/form";
import { translate } from "Services/Translator";
import { ManualPaymentType } from "../forms/ManualPaymentType";
import moment from "moment/moment";
export const useManualPaymentForm = (creditorId, creditorLabel, creditorAmountRemaining, closeForm, createManualPayment, creditAccountId, payerBankAccountId, propertyId) => useForm({
    name: 'manual-payment-form',
    initialValues: {
        label: translate("creditor.payment.defaultLabel", {
            invoiceLabel: creditorLabel
        }),
        amount: creditorAmountRemaining,
        paidAt: moment().format('YYYY-MM-DD'),
        creditAccountId: creditAccountId,
        payerBankAccountId: payerBankAccountId
    },
    type: () => new ManualPaymentType({
        propertyId: () => propertyId
    }),
    title: translate('creditor.actions.pay'),
    onSave: async (values) => {
        return createManualPayment(creditorId, values).then(closeForm);
    }
});
