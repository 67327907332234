import { useRef, useState } from 'react';
import { calcDropdownPosition } from "Services/ModalHelper";
const useDropdown = (dropdownAnchor = "center") => {
    const [dropdownPosition, setDropdownPosition] = useState(null);
    const [isPreparing, setIsPreparing] = useState(false);
    const [animation, setAnimation] = useState(null);
    const showDropdown = () => {
        const position = calcDropdownPosition(triggerRef, dropdownRef, dropdownAnchor);
        if (!position) {
            return;
        }
        setIsPreparing(false);
        setDropdownPosition(position);
    };
    const refreshPosition = () => {
        if (dropdownPosition === null) {
            return;
        }
        showDropdown();
    };
    const hideDropdown = async () => {
        setAnimation('close');
        await new Promise(r => setTimeout(r, 100));
        setAnimation(null);
        setDropdownPosition(null);
    };
    const toggleDropdown = () => {
        if (dropdownPosition) {
            hideDropdown();
        }
        else {
            onTrigger();
        }
    };
    const triggerRef = useRef(null);
    const dropdownRef = useRef(null);
    const onTrigger = () => {
        if (isPreparing || dropdownPosition !== null) {
            return;
        }
        setIsPreparing(true);
    };
    return {
        triggerRef,
        dropdownRef,
        onTrigger,
        isPreparing,
        dropdownPosition,
        showDropdown,
        hideDropdown,
        toggleDropdown,
        refreshPosition,
        dropdownAnchor,
        animation
    };
};
export default useDropdown;
