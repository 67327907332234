import { AbstractFormType, } from '@f2w/form';
import { UpdateCreditBankAccountType, UpdateDepositBankAccountType, UpdateRentBankAccountType, UpdateTenantBankAccountType } from "Pages/BankAccount/forms/UpdateBankAccountTypes";
export class BankAccountViewFormType extends AbstractFormType {
    buildFields() {
        return {
            generalRent: new UpdateRentBankAccountType({
                inheritData: true,
            }),
            generalDeposit: new UpdateDepositBankAccountType({
                inheritData: true,
            }),
            generalTenant: new UpdateTenantBankAccountType({
                inheritData: true,
            }),
            generalCredit: new UpdateCreditBankAccountType({
                inheritData: true,
            }),
        };
    }
}
