import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { Text, Title } from "Atoms/Text";
import { getGlobalContext, translate } from "Services/App";
import { Button, ButtonToolbar } from "Atoms/Button";
import { Select } from "Atoms/Select";
import { SafeAnchor } from "Atoms/SafeAnchor";
import generateRoute from "Services/Router";
import { DateType } from "@f2w/form";
import { Section } from "Atoms/Layout/components";
import { useCallback, useEffect, useState } from "react";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { Tier } from "Pages/Administration/Customers/enums";
export const WaitingListSection = ({ waitingListServices, waitingList, rentalObjectId, updateData }) => {
    const { customer: { currentSubscriptionTier } } = getGlobalContext();
    const [selectedPotentialPersonId, setSelectedPotentialPersonId] = useState();
    const [isAddPersonToWaitingListVisible, setIsAddPersonToWaitingListVisible] = useState(false);
    const [potentialPersonsForWaitingList, setPotentialPersonsForWaitingList] = useState([]);
    const { success } = useToastDispatcherApi();
    const addPersonToWaitingList = useCallback(async (personId, rentalObjectId, waitingList) => {
        await waitingListServices.save(rentalObjectId, personId)
            .then((newWaitingList) => {
            updateData({
                waitingList: [
                    ...waitingList,
                    newWaitingList
                ]
            });
            setSelectedPotentialPersonId(undefined);
            setIsAddPersonToWaitingListVisible(false);
            success({
                message: translate('baseData.rentalObject.addPersonToWaitingList.success'),
            });
        });
    }, []);
    const removePersonFromWaitingList = useCallback(async (id, waitingList) => {
        await waitingListServices.deleteWaitingList(id)
            .then(() => {
            updateData({
                waitingList: waitingList.filter(item => item.id !== id)
            });
            success({
                message: translate('baseData.rentalObject.waitingList.delete.success'),
            });
        });
    }, []);
    useEffect(() => {
        if (isAddPersonToWaitingListVisible) {
            waitingListServices.getPotentialPersons(rentalObjectId)
                .then(data => setPotentialPersonsForWaitingList(data.map(person => ({
                label: person.name,
                value: person.id
            }))));
        }
    }, [isAddPersonToWaitingListVisible]);
    if (Tier.PRIVATE === currentSubscriptionTier) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Section.Divider, {}), _jsxs(Section, { children: [_jsxs(FlexRow, { pb: 10, justifySpaceBetween: true, alignCenter: true, children: [_jsx(Title, { h6: true, as: 'h3', children: translate('baseData.rentalObject.waitingList') }), _jsx(Button, { variant: 'outline', disabled: isAddPersonToWaitingListVisible, onClick: () => setIsAddPersonToWaitingListVisible(true), children: translate('baseData.rentalObject.waitingList.addContact') })] }), isAddPersonToWaitingListVisible && (_jsxs(FlexRow, { pb: 10, gap: 15, alignCenter: true, children: [_jsx(Div, { w: 300, children: _jsx(Select, { value: selectedPotentialPersonId, options: potentialPersonsForWaitingList, onChange: (newValue) => setSelectedPotentialPersonId(newValue.value), placeholder: translate('baseData.rentalObject.waitingList.contact') }) }), _jsxs(ButtonToolbar, { children: [_jsx(Button.Save, { onClick: async () => await addPersonToWaitingList(selectedPotentialPersonId, rentalObjectId, waitingList), disabled: selectedPotentialPersonId === undefined }), _jsx(Button, { color: 'dark', variant: 'linkFill', onClick: () => {
                                            setSelectedPotentialPersonId(undefined);
                                            setIsAddPersonToWaitingListVisible(false);
                                        }, children: translate('Cancel') })] })] })), _jsxs(FlexCol, { children: [waitingList.map((item, index) => (_jsxs(FlexRow, { justifySpaceBetween: true, py: 16, children: [_jsxs(FlexRow, { gap: 12, children: [_jsx(FlexRow, { w: 40, h: 40, justifyCenter: true, alignCenter: true, p: 8, bg: 'primary.$50', brd: 40, children: _jsx(Text, { body: 400, primary: 500, children: index + 1 }) }), _jsxs(FlexCol, { children: [_jsx(SafeAnchor, { href: generateRoute('person.list', { personId: item.personId }), target: '_blank', children: item.personName }), _jsx(Text, { small: 400, neutral: 500, children: translate('baseData.rentalObject.waitingList.createdAt', {
                                                            createdAt: DateType.format(item.createdAt)
                                                        }) })] })] }), _jsx(Button.Delete, { onClick: async () => await removePersonFromWaitingList(item.id, waitingList), confirm: translate('baseData.rentalObject.waitingList.delete.confirm') })] }, item.id))), waitingList.length === 0 && (_jsx(Text, { children: translate('baseData.rentalObject.waitingList.empty') }))] })] })] }));
};
