import axios from "axios";
import generateRoute from "Services/Router";
import client from "Utils/client";
export const activate2FA = async (authCode, secret) => {
    return axios.post(generateRoute('api.user.2faActivate'), {
        authCode: authCode.trim(),
        secret
    });
};
export const deactivate2FA = async (authCode) => {
    return axios.post(generateRoute('api.user.2faDeactivate'), {
        authCode: authCode.trim()
    });
};
export const get2FAStatus = () => {
    return client.get(generateRoute('api.user.2faStatus'));
};
