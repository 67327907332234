import React, { useCallback, useRef } from 'react';
import { pick } from 'Utils';
import * as rt from 'react-table';
import { handleResponseError } from 'Utils/client';
import { translate } from 'Services/Translator';
import { resolveFilters, resolveSort } from '../index';
import { UseCore } from './table';
import { useDataTableResponse } from '../manager';
import { isDevEnv } from '@f2w/utils';
export function useDataTableUncontrolled({ ...initialOptions }, hooks) {
    const { data, isRemote, } = initialOptions;
    const options = {
        ...(isRemote ? {} : {
            isLoaded: true,
            hasData: data.length > 0,
        }),
        ...initialOptions,
    };
    return UseCore._createInstance({
        ...options
    }, hooks);
}
export function useDataTable(options, hooks) {
    try {
        if (options.getData) {
            if (options.manualControl)
                return _createManualRemoteTable(options, hooks);
            return _createPreloadRemoteTable(options, hooks);
        }
        if (!options.manualControl)
            return useControlledDataTable(options, hooks);
    }
    catch (e) {
        console.error("_useTable ERROR", e.message);
        throw e;
    }
    throw new Error('_useTable: you need to provide getData() option when manualControl is enabled');
}
function useControlledDataTable({ data: initialData, ...options }, hooks) {
    const dataResponse = useDataTableResponse({ initialData: initialData });
    return useDataTableUncontrolled(Object.assign(options, {
        dataResponse,
        data: dataResponse.results,
    }), hooks);
}
function _createBaseRemoteTable({ dataResponse, getData: _getData, ...options }, hooks) {
    const infoRef = useRef({});
    const instance = UseCore._createInstance({
        isRemote: true,
        isLoading: true,
        isLoaded: false,
        ...options,
        ...infoRef.current,
        dataResponse,
        data: dataResponse.results,
    }, hooks);
    const loadData = useCallback(async (params) => {
        instance.setLoading(true);
        return _getData(params, instance)
            .then(response => {
            return response;
        })
            .catch((e) => {
            handleResponseError(e, {
                dispatcher: instance?.dispatcher?.toast,
                defaultMessage: translate('table.errorLoadingData'),
            });
            throw e;
        })
            .finally(() => {
            instance.setLoading(false);
        });
    }, [_getData, instance.manualControl, instance.setLoading]);
    return [
        instance,
        loadData,
        infoRef
    ];
}
function _createPreloadRemoteTable(options, hooks) {
    const dataResponse = useDataTableResponse();
    const [instance, _loadData, infoRef] = _createBaseRemoteTable({
        dataResponse,
        ...options,
        hasData: dataResponse.results?.length > 0,
    }, hooks);
    const loadData = React.useCallback(async (params) => _loadData(params)
        .then(response => {
        infoRef.current.isLoaded = response?.results?.length > 0;
        if (Array.isArray(response?.results)) {
            dataResponse.setData(response.results);
        }
    }), [_loadData]);
    const refresh = React.useCallback(async () => {
        await loadData();
    }, [loadData]);
    React.useEffect(() => {
        refresh();
    }, [refresh]);
    return Object.assign(instance, {
        refresh,
        load: loadData,
    });
}
function _createManualRemoteTable(options, hooks) {
    const dataResponse = useDataTableResponse();
    const [instance, _loadData, infoRef] = _createBaseRemoteTable({
        dataResponse,
        ...options,
    }, hooks);
    const { pageIndex = 0, pageSize = options.usePagination ? 10 : 500, globalFilter, filters, sortBy, } = instance.state;
    const loadData = React.useCallback(async (params) => _loadData(params)
        .then(response => {
        if (Array.isArray(response?.results)) {
            if (infoRef.current.isLoaded === undefined) {
                infoRef.current.isLoaded = response.results.length > 0;
            }
            dataResponse.setResponse(response);
        }
    }), [_loadData]);
    const _loadDebounced = rt.useAsyncDebounce(loadData, 250);
    const loadDebounced = useCallback((params) => {
        instance.setLoading(true);
        return _loadDebounced(params);
    }, [loadData]);
    const refresh = React.useCallback(async () => {
        await loadDebounced({
            hasRefreshed: true,
            request: {
                pageSize,
                query: globalFilter,
                pageIndex: pageIndex + 1,
                sorts: resolveSort(sortBy),
                filters: resolveFilters(filters)
            },
            params: {
                pageIndex,
                pageSize,
                query: globalFilter,
                sort: sortBy,
                filters,
            },
            state: instance.state,
            pageIndex,
            page: pageIndex + 1,
            pageSize,
            sortBy: sortBy,
            sort: sortBy,
            globalFilter,
            query: globalFilter,
            filters,
        });
    }, [loadDebounced, pageIndex, pageSize, sortBy, globalFilter, filters]);
    React.useEffect(() => {
        if (isDevEnv())
            console.debug('refresh2', pick(instance, [
                'hideEmpty',
                'hasData',
                'isRemote',
                'isLoaded',
            ]));
        refresh();
    }, [refresh]);
    return Object.assign(instance, {
        refresh,
    });
}
