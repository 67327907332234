import client from "Utils/client";
import generateRoute from "Services/Router";
export const registerUser = (data) => {
    const url = generateRoute('api.register');
    return client.post(url, data);
};
export const completeAccount = (data) => {
    data.billingContactSameAsCustomer = data.billingContact.sameAsCustomerContact;
    const url = generateRoute('api.register.complete');
    return client
        .post(url, data)
        .then(response => response.data);
};
export default {
    registerUser,
    completeAccount,
};
