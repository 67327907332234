import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classes from './DocumentLink.module.scss';
import { generateDocument } from "Services/DocumentGenerator";
import { DownloadIcon } from "Components/App/Icons";
const DocumentLink = ({ document }) => {
    const { documentName, templateName, sourceId, sourceName, mandated, mandatedDocumentName } = document;
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsxs("a", { className: classes.root, href: "#", onClick: (e) => {
                        e.preventDefault();
                        generateDocument(templateName, sourceName, sourceId);
                    }, children: [_jsx("span", { className: classes.icon, children: _jsx(DownloadIcon, {}) }), documentName] }) }), mandated &&
                _jsx("div", { children: _jsxs("a", { className: classes.root, href: "#", onClick: (e) => {
                            e.preventDefault();
                            generateDocument(templateName, sourceName, sourceId, mandated);
                        }, children: [_jsx("span", { className: classes.icon, children: _jsx(DownloadIcon, {}) }), mandatedDocumentName] }) })] }));
};
export default DocumentLink;
