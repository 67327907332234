import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
const createAssetOption = (asset) => ({
    value: asset.id,
    label: asset.name,
});
const labelSorter = (a, b) => a.label.localeCompare(b.label, 'de', { numeric: true });
const AssetSelect = ({ roomsWithAssets, value: selectedAsset, ...props }) => {
    const options = (roomsWithAssets || [])
        .map(room => ({
        label: room.name,
        options: room.assets
            .map(createAssetOption)
            .sort(labelSorter)
    }))
        .sort(labelSorter);
    const selectedOption = selectedAsset
        ? options
            .map(({ options: subOptions }) => subOptions)
            .flat()
            .find(({ value }) => selectedAsset === value)
        : null;
    return (_jsx(Select, { value: selectedOption, options: options, isSearchable: true, menuPortalTarget: document.body, ...props }));
};
export default AssetSelect;
