import { jsx as _jsx } from "react/jsx-runtime";
import Modal from 'Components/App/Modal';
import { useEffect } from 'react';
import classes from './DropdownModal.module.scss';
const DropdownModal = ({ dropdown, children, onClickOutside = undefined, modalTheme = "default", id = undefined }) => {
    useEffect(() => {
        if (!dropdown.isPreparing) {
            return;
        }
        dropdown.showDropdown();
    }, [dropdown.isPreparing]);
    useEffect(() => {
        function handleResize() {
            dropdown.refreshPosition();
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [dropdown]);
    const position = dropdown.dropdownPosition;
    if (!position && !dropdown.isPreparing) {
        return null;
    }
    let style = position ? { left: position['left'], top: position['top'] } : {};
    style['visibility'] = dropdown.isPreparing ? 'hidden' : 'visible';
    style['position'] = 'absolute';
    style['zIndex'] = 100;
    const className = classes['modal_' + modalTheme] + ' ' + classes['anchor_' + dropdown.dropdownAnchor];
    const onModalClickOutside = (e) => {
        if (onClickOutside === undefined) {
            return dropdown.hideDropdown();
        }
        if (typeof (onClickOutside) === 'function') {
            return onClickOutside(e);
        }
        return;
    };
    return (_jsx(Modal, { onClickOutside: onModalClickOutside, children: _jsx("div", { id: id, className: className, ref: dropdown.dropdownRef, style: style, children: children }) }));
};
export default DropdownModal;
