import generateRoute from "Services/Router";
import client from "Utils/client";
const listTaxRates = () => {
    const route = generateRoute('api.taxRates.list');
    return client
        .get(route)
        .then(response => response.data);
};
export default {
    listTaxRates
};
