import { jsx as _jsx } from "react/jsx-runtime";
import ReactDom from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
const Modal = ({ children, onClickOutside }) => {
    if (!document.getElementById('modal-root')) {
        const div = document.createElement('div');
        div.setAttribute('id', 'modal-root');
        document.body.appendChild(div);
    }
    return ReactDom.createPortal(_jsx(OutsideClickHandler, { onOutsideClick: onClickOutside, children: children }), document.getElementById('modal-root'));
};
export default Modal;
