import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTabView } from "@f2w/view";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { SafeAnchor } from "Atoms/SafeAnchor";
import { translate, translateAdmin } from "Services/Translator";
import { UserIcon } from "Components/App/Icons";
import { UseStyled } from "@fw/theme";
import { css } from "styled-components";
import { Text } from "Atoms/Text";
import { getSubscriptionStatusBadge } from "Pages/Administration/Customers/helpers/badges";
import { Button, ButtonToolbar } from "Components/Atoms";
import { Body, Container } from "Atoms/Layout/components";
import { useActiveViewContext } from "Pages/Administration/context/ActiveViewContext";
import { ActiveView } from "Pages/Administration/enums";
import { getGlobalContext } from "Services/GlobalContext";
import { UserType } from "Types/User";
import { DateType } from "@f2w/form";
import { useToastDispatcherApi } from "Components/Dispatcher";
const IconWrapper = UseStyled.styled('div', ({ theme: { palette } }) => (css `
        border-radius: 50%;
        background: ${palette.primary.$50};
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${palette.primary.$500};
    `));
const VerticalLine = UseStyled.styled('div', ({ theme: { palette } }) => ({
    borderLeft: `1px solid ${palette.neutral.$100}`,
    height: '100%'
}));
const HeaderTemplate = (props) => {
    const { view, } = props;
    const data = view.data;
    const { success } = useToastDispatcherApi();
    const { setActiveView, setDetailsActiveView } = useActiveViewContext();
    const { onEdit } = view.options.actions;
    const { deleteSubscription } = view.options.actions;
    const { user } = getGlobalContext();
    return (_jsxs(FlexCol, { gap: 40, children: [_jsxs(FlexRow, { gap: 5, children: [_jsx(SafeAnchor, { onClick: () => {
                            setActiveView(ActiveView.CUSTOMERS);
                        }, children: translateAdmin('customers') }), _jsx("span", { children: "/" }), _jsx(SafeAnchor, { onClick: () => {
                            setDetailsActiveView(data.customerId, ActiveView.CUSTOMER_DETAILS);
                        }, children: data.customerName }), _jsx("span", { children: "/" }), data.name] }), _jsxs(FlexRow, { justifySpaceBetween: true, children: [_jsxs(FlexRow, { gap: 16, alignCenter: true, children: [_jsx(IconWrapper, { children: _jsx(UserIcon, {}) }), _jsx(Text, { body: 500, style: { fontWeight: 'bold' }, children: data.name }), _jsx(Div, { children: getSubscriptionStatusBadge(data.status) })] }), user.userType === UserType.FAIRWALTER && (_jsxs(ButtonToolbar, { children: [_jsx(Button.Delete, { outline: true, title: translate('btn.delete'), label: translate('btn.delete'), onClick: () => deleteSubscription(data.id).then(() => {
                                    success({
                                        message: translateAdmin('customer.subscription.deleteSuccess')
                                    });
                                    setDetailsActiveView(data.customerId, ActiveView.CUSTOMER_DETAILS);
                                }) }), _jsx(Button.Edit, { fill: true, title: translate('btn.edit'), label: translate('btn.edit'), onClick: onEdit })] }))] }), _jsxs(FlexRow, { justifySpaceAround: true, children: [_jsxs(FlexCol, { p: 16, alignCenter: true, gap: 5, children: [_jsx(Text, { h6: 500, neutral: 500, children: DateType.useFormat(data.start) }), _jsx(Text, { medium: 400, neutral: 200, children: translateAdmin('customer.subscription.start') })] }), _jsx(FlexCol, { pl: 10, pr: 10, children: _jsx(VerticalLine, {}) }), _jsxs(FlexCol, { p: 16, alignCenter: true, gap: 5, children: [_jsx(Text, { h6: 500, neutral: 500, children: DateType.useFormat(data.end) }), _jsx(Text, { medium: 400, neutral: 200, children: translateAdmin('customer.subscription.end') })] })] })] }));
};
export const CustomerSubscriptionTemplate = (props) => {
    const [renderLeft] = useTabView({
        ...props,
        groups: ['info'],
        Header: HeaderTemplate,
        stateKey: 'leftActiveTab'
    });
    const [renderRight] = useTabView({
        ...props,
        groups: [''],
        stateKey: 'rightActiveTab'
    });
    return (_jsxs(Body, { split: true, style: { height: '100%' }, children: [_jsx(Container, { style: { flexBasis: '60%' }, children: renderLeft() }), _jsx(Container, { style: { flexBasis: '40%' }, children: renderRight() })] }));
};
