import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { Select as DropdownSelect } from "Atoms/Select";
import { DocumentType } from "Pages/DocumentTemplates/enums/DocumentType";
const options = Object.values(DocumentType).map(type => ({
    label: translate('document.v2.type.' + type, {}, 'documents'),
    value: type
}));
export function TypeFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: options, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
}
