import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
const Actions = ({ cell: { value: rent }, row, onViewRent }) => {
    return (_jsx(ActionsDropdown, { id: row.id, quickActions: [
            {
                icon: 'eye',
                title: translate('rent.view'),
                onClick: () => {
                    onViewRent(rent);
                },
            },
        ] }));
};
export default Actions;
