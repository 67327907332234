import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { Select } from "Atoms/Select";
import { DunningLevel } from "Pages/Dunning/types";
import { convertOptionsToValues } from "Utils/select";
const options = Object
    .values(DunningLevel)
    .filter(level => isNaN(Number(level)))
    .map((level, index) => ({
    value: index,
    label: translate(`dunning.level${level.toString().charAt(0).toUpperCase() + level.toString().slice(1).toLowerCase()}`)
}));
export function MaxDunningLevelFilter({ column: { filterValue, setFilter }, }) {
    return _jsx(Select, { isClearable: true, isMulti: true, isSearchable: true, value: filterValue, options: options, onChange: (value) => setFilter(convertOptionsToValues(value)) });
}
