import { createWithScope } from '@fw/theme';
import { useModalContext } from '../ModalContext';
export const ModalBackdrop = createWithScope('modal', 'backdrop', {
    props: (props) => {
        const { zIndex } = useModalContext();
        return {
            ...props,
            style: { ...props.style, zIndex },
        };
    },
});
