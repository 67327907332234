import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { InputTheme } from '../InputTheme';
import { useEventCallback } from '@restart/hooks';
export * from '../InputTheme';
const getInputEventTarget = (e) => (e?.currentTarget ?? e?.target);
export const useInputOnChange = (trigger) => {
    return useEventCallback((event) => {
        if (trigger) {
            const target = getInputEventTarget(event);
            if (!target)
                return trigger(event, null);
            if (['radio', 'checkbox'].includes(target.type))
                return trigger(target.checked, event);
            return trigger(target.value, event);
        }
    });
};
export const BaseInput = forwardRef(({ icon, prefix, children, ...props }, ref) => {
    return (_jsx(InputTheme.Provider, { ...props, children: ({ inputProps, icon: stateIcon }) => (_jsxs(_Fragment, { children: [prefix && _jsx(InputTheme.Side, { children: prefix }), icon && _jsx(InputTheme.Side, { children: _jsx(InputTheme.Icon, { icon: icon }) }), _jsxs(InputTheme.Main, { children: [children ?? _jsx(InputTheme.Input, { ...inputProps, ref: ref }), stateIcon && _jsx(InputTheme.StateIcon, { icon: stateIcon })] })] })) }));
});
