export var SortDirection;
(function (SortDirection) {
    SortDirection["ASC"] = "ASC";
    SortDirection["DESC"] = "DESC";
})(SortDirection || (SortDirection = {}));
export var Sort;
(function (Sort) {
    function fromBool(desc) {
        return desc ? SortDirection.DESC : SortDirection.ASC;
    }
    Sort.fromBool = fromBool;
    function fromState(sortBy) {
        const sortParam = {};
        for (const s of sortBy) {
            sortParam[s.id] = fromBool(s.desc);
        }
        return sortParam;
    }
    Sort.fromState = fromState;
})(Sort || (Sort = {}));
