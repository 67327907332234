import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import UnitSelect from 'Pages/AncillaryExpense/components/Export/components/UnitSelect';
import CostTypeSelect from 'Pages/AncillaryExpense/components/Export/components/CostTypeSelect';
import { CostType } from 'Pages/AncillaryExpense/types/CostType';
const _defaultSelectProps = {
    $size: 'sm',
    $color: 'transparent',
    menuShouldBlockScroll: true,
    useMaxContentWidth: true,
};
const BaseTable = Table.factory()(() => ({
    useRowState: {
        getStatusProps: ({ row, updateApi }) => {
            if (row.isSelected) {
                const data = updateApi.getData(row.id);
                if (!data?.costType || !data?.unit) {
                    return {
                        type: 'error',
                        message: 'All values are required!',
                    };
                }
            }
        },
    },
    getId: d => d.id,
    columns: {
        $rowSelect: {
            sticky: 'left',
        },
        name: {
            Header: translate('ancillaryExpenseAccount'),
        },
        unit: {
            Header: translate('ancillaryExpenseAccount.unit'),
            Cell: ({ value, row, updateApi, }) => {
                if (value)
                    return _jsx(_Fragment, { children: translate('unit.' + value) });
                if (!row.isSelected)
                    return '—';
                const data = updateApi.getData(row.id);
                return (_jsx(_Fragment, { children: _jsx(UnitSelect, { ..._defaultSelectProps, value: data?.unit, width: 20, "$variant": !data?.unit ? 'error' : 'default', onChange: (option) => {
                            updateApi.updateData(row.id, {
                                unit: option?.value,
                            });
                        } }) }));
            },
        },
        costType: {
            Header: translate('ancillaryExpenseAccount.costType'),
            Cell: ({ row, updateApi, }) => {
                const data = updateApi.getUpdateData(row.id, true);
                const costType = data.costType ? '_' + data.costType : null;
                if (!row.isSelected || row.original.costType) {
                    if (!row.original.costType) {
                        return '—';
                    }
                    return _jsx(_Fragment, { children: translate('ancillaryExpenseAccount.costType.' + CostType['_' + row.original.costType]) });
                }
                return _jsx(CostTypeSelect, { ..._defaultSelectProps, value: costType, "$variant": !costType ? 'error' : 'default', onChange: (option) => {
                        updateApi.setState(row.id, (prev) => ({
                            ...prev,
                            update: {
                                ...prev?.update,
                                costType: option?.value.substring(1),
                            },
                        }));
                    } });
            },
        }
    }
}));
export const AncillaryExpenseVkaExportDataTable = Object.assign(BaseTable, {
    use({ exportAccounts, onExport: _onExport }) {
        const exportSelectedAction = () => {
            const updateApi = instance.updateApi;
            const selectedIds = Object.keys(instance.state.selectedRowIds).map((id) => parseInt(id));
            if (selectedIds.length === 0) {
                return null;
            }
            let changes = selectedIds.map((id) => {
                const update = updateApi.getData(id);
                if (!update.unit || !update.costType) {
                    return null;
                }
                return ({
                    id: update.id,
                    unit: update.unit,
                    costType: update.costType,
                });
            });
            const find = changes.findIndex(v => v === null);
            if (find !== -1 || !changes) {
                return null;
            }
            _onExport(changes);
        };
        const instance = BaseTable.useTable({
            data: exportAccounts,
            exportVkaAction: exportSelectedAction,
        }, () => ({
            initialState: {
                selectedRowIds: Object.fromEntries(exportAccounts.map(obj => {
                    return [obj.id, true];
                }))
            },
            postHook: (hooks) => {
                hooks.useInstance.push((instance) => {
                });
            },
        }));
        return instance;
    }
});
export default AncillaryExpenseVkaExportDataTable;
