import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useContext } from 'react';
import AccordionContext from './AccordionContext';
import { SelectableContext } from 'Components/Base';
export function useAccordionToggle(eventKey, onClick) {
    const contextEventKey = useContext(AccordionContext);
    const onSelect = useContext(SelectableContext);
    return (e) => {
        const eventKeyPassed = eventKey === contextEventKey ? null : eventKey;
        if (onSelect)
            onSelect(eventKeyPassed, e);
        if (onClick)
            onClick(e);
    };
}
export const AccordionToggle = forwardRef(({ as: Component = 'button', children, eventKey, onClick, ...props }, ref) => {
    const accordionOnClick = useAccordionToggle(eventKey, onClick);
    if (Component === 'button') {
        props.type = 'button';
    }
    return (_jsx(Component, { ref: ref, onClick: accordionOnClick, ...props, children: children }));
});
AccordionToggle.displayName = 'AccordionToggle';
