import { useContext, useEffect } from 'react';
import DamageModalContext from "./DamageModalContext";
import { useFormikContext } from 'formik';
const ActiveFormContextHandler = () => {
    const formik = useFormikContext();
    const context = useContext(DamageModalContext);
    useEffect(() => {
        if (!context) {
            return;
        }
        context.setActiveForm(formik);
    }, [formik]);
    return null;
};
export default ActiveFormContextHandler;
