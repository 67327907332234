import { getGlobalContext } from 'Services/GlobalContext';
export function isScopeFilterEnabled() {
    const { scope } = getGlobalContext();
    return (scope.level !== "portfolio");
}
export function getScopeFilterLevel() {
    const { scope } = getGlobalContext();
    return scope.level;
}
export function getScopeFilters(portfolio) {
    const propertyId = getScopePropertyId(portfolio);
    const rentalObject = getRentalObject(portfolio);
    const property = propertyId ? portfolio.getPropertyById(propertyId) : null;
    return ({
        mandateId: property?.mandate?.id,
        propertyId: propertyId,
        buildingId: rentalObject?.buildingId,
        rentalObjectId: rentalObject?.id,
    });
}
export function getScopePropertyId(portfolio) {
    const { scope } = getGlobalContext();
    if (!scope?.level)
        return null;
    if (scope.level === "portfolio") {
        return null;
    }
    if (scope.level === "property") {
        return scope.id;
    }
    const rentalObject = portfolio.getRentalObjectById(scope.id);
    if (!rentalObject) {
        return null;
    }
    const building = portfolio.getBuildingById(rentalObject.buildingId);
    if (!building) {
        return null;
    }
    return building.propertyId;
}
function getRentalObject(portfolio) {
    const { scope } = getGlobalContext();
    if (scope.level !== "rentalObject") {
        return null;
    }
    return portfolio.getRentalObjectById(scope.id);
}
export function redirectToPropertyScope(propertyId) {
    let href = new URL(window.location.href);
    href.searchParams.set('_for', `property-${propertyId}`);
    window.location.href = href.toString();
}
