import { jsx as _jsx } from "react/jsx-runtime";
import { Select as DropdownSelect } from "Atoms/Select";
import { ContractState } from "@fw/enums/ContractState";
import { translate } from "Services/Translator";
const options = Object.values(ContractState).map(status => ({
    value: status,
    label: translate(`contract.state.${status}`)
}));
export function ContractStatusFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: options, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
}
