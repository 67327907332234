import { jsx as _jsx } from "react/jsx-runtime";
import useTimeout from '@restart/hooks/useTimeout';
import React, { useEffect, useRef } from 'react';
import { addClass } from 'dom-helpers';
import { Toast } from 'Atoms/Toast';
import { isPlainObject, orFunction } from 'Utils';
export function ToastMessage({ message, ...props }) {
    const { onRemove, showRemove = true, timeout: _timeout = 5, id, ...rest } = props;
    const { set, clear } = useTimeout();
    const elRef = useRef();
    let resolvedTimeout = _timeout ? Math.max(_timeout, showRemove ? 0 : 2) : 0;
    useEffect(() => {
        if (elRef.current) {
            addClass(elRef.current, 'fw-toast-message-show');
        }
    }, []);
    resolvedTimeout && set(() => onRemove(), resolvedTimeout * 1000);
    return _jsx("div", { ref: elRef, children: _jsx(Toast, { ...rest, onClose: showRemove && onRemove, message: orFunction(message, props) }) });
}
(function (ToastMessage) {
    ToastMessage.isMessageProps = (value) => (isPlainObject(value) && !React.isValidElement(value));
    ToastMessage.resolveProps = (props) => (ToastMessage.isMessageProps(props) ? props : { message: props });
    function create2(types, a) {
        const _types = {};
        for (const [key, cb] of Object.entries(types)) {
            _types[key] = (props) => cb(ToastMessage.resolveProps(props));
        }
        return _types;
    }
    ToastMessage.create2 = create2;
})(ToastMessage || (ToastMessage = {}));
