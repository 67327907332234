import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { AutomaticInvoicingType } from "Pages/AutoInvoicing/types/AutomaticInvoicingType";
import client from "Utils/client";
import generateRoute from "Services/Router";
import { formatYearMonthInverse } from "Services/DateHelper";
import moment from "moment";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
export const useAutomaticInvoicingFormModal = ({ onSave, periodMin, periodMax }) => useFormModal({
    name: 'auto-invoicing-modal',
    type: () => new AutomaticInvoicingType({
        periodMin,
        periodMax
    }),
    title: translate('automaticInvoices.helper.title'),
    ignoreConfirm: true,
    successMessage: null,
    errorMessage: translate('automaticInvoices.failed'),
    onSave: async (data) => {
        return client
            .post(generateRoute('api.automaticInvoicing.create', {
            period: moment(data.period).format('YYYYMM')
        }))
            .then(res => res.data)
            .then(({ nbInvoices }) => onSave(formatYearMonthInverse(moment(data.period, "YYYYMM")), nbInvoices));
    },
    Footer: ({ saveForm, closeForm, form: { loading, isTouched } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, disabled: loading || !isTouched, onClick: () => saveForm(true), children: translate('Add') })] });
    }
});
