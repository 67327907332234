import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import classes from '../styles';
import { translate } from 'Services/Translator';
import { Icon } from 'Atoms/Icon';
export const SearchBar = ({ onChange }) => {
    const [searchQuery, setSearchQuery] = useState('');
    useEffect(() => {
        onChange?.(searchQuery);
    }, [searchQuery]);
    return (_jsxs("div", { className: classes.search, children: [_jsx(Icon, { icon: "search", size: 22 }), _jsx("input", { autoFocus: true, onChange: (e) => setSearchQuery(e.target.value), value: searchQuery, placeholder: translate('focusSelector.search') }), searchQuery && (_jsx("span", { style: { "cursor": "pointer" }, onClick: () => setSearchQuery(''), children: _jsx(Icon, { size: 20, icon: "x" }) }))] }));
};
