import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { useInputOnChange } from './base';
export const TextareaRoot = styled.textarea ``;
export const TextArea = forwardRef(({ ...props }, ref) => {
    const onChange = useInputOnChange(props.onChange);
    return (_jsx(TextAreaWrapper, { children: _jsx(TextareaRoot, { ...{
                ...props,
                onChange,
                ref,
            } }) }));
});
const TextAreaWrapper = styled.div(({ theme: { rem, palette } }) => {
    const $color = "primary";
    const borderColor = palette.colors['primary'].$50;
    const activeColor = ($color === "primary" ? palette.dark.$900 : borderColor);
    const enabled = true;
    let padding = `${rem(8)} ${rem(12)}`;
    return css `
        display: flex;
        align-items: center;
        border-radius: ${rem(8)};
        overflow: hidden;
        padding: ${padding};
        transition: box-shadow 0.1s ease-in-out, border 0.1s ease-in-out;

        textarea {
            width: 100%;
            border: none;
            outline: 0;
        }

        ${enabled && css `
          color: ${activeColor};
          background: ${palette.white};
          border: 2px solid ${borderColor};

          &:hover {
            border-color: transparent;
            box-shadow: 0 0 0 4px ${palette[$color].$100};
          }

          &:focus-within {
            border-color: ${palette[$color].$500};
          }
        `}

        ${!enabled && css `
          background: ${palette.dark.$50};
          color: ${palette.dark.$100};
        `}
    `;
});
