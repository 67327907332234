import StringSchema from "yup/lib/string";
import BooleanSchema from "yup/lib/boolean";
import NumberSchema from "yup/lib/number";
import ObjectSchema from "yup/lib/object";
import DateSchema from "yup/lib/date";
import ArraySchema from "yup/lib/array";
import MixedSchema from 'yup/lib/mixed';
import BaseSchema from "yup/lib/schema";
export { BaseSchema, StringSchema, NumberSchema, BooleanSchema, DateSchema, ObjectSchema, ArraySchema, MixedSchema, };
BaseSchema.prototype.getFormType = function () {
    return this.spec.meta.getType();
};
export function string(options) {
    return new StringSchema();
}
string.prototype = StringSchema.prototype;
export function number() {
    return new NumberSchema();
}
number.prototype = NumberSchema.prototype;
export function boolean() {
    return new BooleanSchema();
}
boolean.prototype = BooleanSchema.prototype;
export { boolean as bool };
export function date() {
    return new DateSchema();
}
date.prototype = DateSchema.prototype;
export function object(spec) {
    return new ObjectSchema(spec);
}
object.prototype = ObjectSchema.prototype;
export function array(type) {
    return new ArraySchema(type);
}
array.prototype = ArraySchema.prototype;
export function choice(options) {
    return (new StringSchema()).oneOf(options);
}
export function gender() {
    return choice(['male', 'female', 'other']);
}
export function email() {
    return string().email();
}
