import { useHref, useLinkClickHandler } from 'react-router-dom';
export function useLink(to, options) {
    if (to) {
        options = { replace: false, ...options };
        const href = useHref(to, options);
        const onClick = useLinkClickHandler(to, options);
        return {
            href,
            onClick
        };
    }
}
