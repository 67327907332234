import { MortgageTableModel } from "Components/ObjectViewModal/components/PropertyView/models/MortgageTableModel";
export const useMortgagesTable = ({ mortgages, saronInterest }) => {
    const table = MortgageTableModel.useCreateTable({
        data: mortgages,
        saronInterest
    });
    return {
        table
    };
};
