import client from 'Utils/client';
import generateRoute from 'Services/Router';
export const listServiceProviders = async (params) => {
    const route = generateRoute('api.serviceProviders.list');
    const { data } = await client.get(route, { params });
    return data;
};
export const createServiceProvider = async (serviceProviderDto) => {
    const { data } = await client.post(generateRoute('api.serviceProviders.create'), serviceProviderDto);
    return data;
};
export const updateServiceProvider = async (id, serviceProviderDto) => {
    const { data } = await client.post(generateRoute('api.serviceProvider.update', { id }), serviceProviderDto);
    return data;
};
export const deleteServiceProvider = async (id) => {
    return client.delete(generateRoute('api.serviceProviders.delete', {
        serviceProvider: id
    }));
};
export const getServiceProvider = async (id) => {
    const { data } = await client.get(generateRoute('api.serviceProvider', { id }));
    return data;
};
export const getServiceProviderDomains = async () => {
    const { data } = await client.get(generateRoute('api.serviceProviderDomains.list'));
    return data;
};
