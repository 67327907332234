import { AbstractFormType, DateType, MoneyType, SelectType, StringType, } from '@f2w/form';
import { translate } from "Services/Translator";
import { useFinancialAccountsHook } from "Hooks/useFinancialAccountsHook";
import { getGlobalContext } from "Services/GlobalContext";
export class AccountingRecordType extends AbstractFormType {
    groupedFinancialAccountOptions = [];
    _useField(props) {
        super._useField(props);
        const { scope } = getGlobalContext();
        const { groupedFinancialAccountOptions } = useFinancialAccountsHook({
            propertyId: scope.level === 'property' ? scope.id : undefined
        });
        this.groupedFinancialAccountOptions = groupedFinancialAccountOptions;
    }
    buildFields() {
        return {
            date: new DateType({
                label: translate('accounting.record.form.labels.date'),
                required: true,
                constraints: {
                    min: [new Date(this.options.maxDateRange.from), translate('accountingRecord.dateOutsideFiscalYears')],
                    max: [new Date(this.options.maxDateRange.to), translate('accountingRecord.dateOutsideFiscalYears')]
                }
            }),
            customizableId: new StringType({
                label: translate('accounting.record.form.labels.customizableId'),
                required: false,
            }),
            label: new StringType({
                label: translate('accounting.record.form.labels.label'),
                required: true,
            }),
            amount: new MoneyType({
                label: translate('accounting.record.form.labels.amount'),
                required: true,
                constraints: {
                    typeError: translate('form.validation.notValidNumber'),
                }
            }),
            debitAccountId: new SelectType({
                label: translate('accounting.record.form.labels.debitAccount'),
                required: true,
                searchable: true,
                choiceOptions: () => this.groupedFinancialAccountOptions
            }),
            creditAccountId: new SelectType({
                label: translate('accounting.record.form.labels.creditAccount'),
                required: true,
                searchable: true,
                choiceOptions: () => this.groupedFinancialAccountOptions
            }),
        };
    }
}
