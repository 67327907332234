import styled, { css } from 'styled-components';
import { UnstyledButton } from './UnstyledButton';
export const BaseButton = styled(UnstyledButton).attrs((props) => ({
    className: `fw-btn${props.noGap ? ' fw-btn-nogap' : ''}`,
})) `
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  flex-wrap: nowrap;
  white-space: nowrap;

  font-size: var(--btn-font-size, inherit);
  line-height: var(--btn-line-height, 1.25em);

  padding: var(--btn-padding-y, 0) var(--btn-padding-x, 0);
  margin: 0;
  gap: .5em;
  min-height: var(--btn-min-height, auto);
  min-width: var(--btn-min-height, auto);

  user-select: none;
  cursor: pointer;
  color: inherit;

  outline: none;
  border: var(--btn-border-size, 0) solid transparent;
  border-radius: var(--btn-border-radius, 0);
  background-color: transparent;

    ${p => p.truncate ? css `
        max-width: 100%;
        > span {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            min-width: 0;
        }
    ` : undefined};


  &.fw-btn-icon {
    --btn-padding-x: 0;
  }

  &:hover {
    outline: 0;
    text-decoration: none;
  }

  &:is(:focus, .focus) {
    outline: 0;
  }

  &:is(.disabled, :disabled, [aria-disabled=true], [aria-busy=true]) {
      user-select: none;
      cursor: default;
  }
`;
BaseButton.defaultProps = {
    truncate: true
};
BaseButton.displayName = 'BaseButton';
