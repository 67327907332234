import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { Button, Div, Loader, Text } from 'Components/Atoms';
import { Body, Container, Header, Section } from 'Atoms/Layout/components';
import { CreatePropertyTemplate } from './components/CreatePropertyTemplate/CreatePropertyTemplate';
import { CreatePropertySearch, useCreatePropertySearch, } from './components/CreatePropertyTemplate/CreatePropertySearch';
import { DebugInfo } from '@f2w/form-new';
import { Content, Title } from '@f2w/data-table/layout/PageOverviewStyles';
export const CreatePropertyPage = ({ services, locationId, openManualFormFor: initialManualForm, }) => {
    const createApi = useCreatePropertySearch({
        ...services,
        locationId,
    });
    const { loading, settings, locationValue, load, } = createApi;
    return (_jsx(DebugInfo, { valueType: locationValue, debug: locationValue?.value?.debug, children: _jsx(Body, { style: { position: 'relative' }, paddingX: 0, children: _jsxs(Container, { children: [_jsx(Loader, { floating: true, visible: loading }), _jsxs(Header, { style: { paddingTop: 0 }, children: [_jsx(Title, { children: translate('createProperty.title') }), _jsx(Content, { style: { marginTop: 24 }, max: 'none', children: translate('createProperty.description') })] }), _jsxs(_Fragment, { children: [_jsxs(Section.Row, { "aria-disabled": !settings, children: [_jsxs(Div, { children: [_jsx(Text, { as: 'label', colorName: 'neutral.$500', size: 'body', htmlFor: 'fw-property-search', children: translate('createProperty.searchLabel') }), _jsx(CreatePropertySearch, { id: 'fw-property-search', onSelect: (id) => createApi.loadById(id) })] }), _jsx(Section.Side, { right: true, spacing: 24, contentEnd: true, children: _jsx(Button, { label: translate('createProperty.manualAction'), onClick: () => {
                                                return load({});
                                            } }) })] }), locationValue && _jsx(CreatePropertyTemplate, { valueType: locationValue })] })] }) }) }));
};
