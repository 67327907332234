import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
const OpeningBalanceContext = React.createContext({
    modalOpen: false,
    closeModal: () => { },
    openForm: () => { },
    fiscalYearId: undefined,
    financialAccountId: undefined,
    disableOpeningBalanceModification: false,
});
const OpeningBalanceProvider = ({ children, openForm, disableOpeningBalanceModification, accountingRecordServices: { getOpeningBalance } }) => {
    const [initialFormData, setInitialFormData] = React.useState();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [financialAccountId, setFinancialAccountId] = React.useState();
    const [fiscalYearId, setFiscalYearId] = React.useState();
    const onClose = React.useCallback(() => setModalOpen(false), []);
    return (_jsx(OpeningBalanceContext.Provider, { value: {
            initialFormData,
            modalOpen,
            closeModal: onClose,
            openForm,
            financialAccountId,
            fiscalYearId,
            disableOpeningBalanceModification
        }, children: children }));
};
export const useOpeningBalanceContext = () => React.useContext(OpeningBalanceContext);
export default OpeningBalanceProvider;
