import { jsx as _jsx } from "react/jsx-runtime";
import moment from "moment/moment";
import { formatDateShort } from "Services/DateHelper";
import { OverlayTrigger, Tooltip } from "Components/Atoms";
export const ContractProgress = ({ calculationFrom: strCalculationFrom, calculationTo: strCalculationTo, isVacancy, contractFrom: strContractFrom, contractTo: strContractTo }) => {
    const calculationFrom = moment(strCalculationFrom);
    const calculationTo = moment(strCalculationTo);
    const nbDays = calculationTo.diff(calculationFrom, 'days');
    const contractFrom = moment.max(moment(strContractFrom), calculationFrom);
    const contractTo = strContractTo ? moment.min(moment(strContractTo), calculationTo) : calculationFrom;
    const startDayNumber = contractFrom.diff(calculationFrom, 'days');
    const nbContractDays = contractTo.diff(contractFrom, 'days');
    const left = (startDayNumber / nbDays) * 100;
    const width = (nbContractDays / nbDays) * 100;
    const vacancyColor = '#EBF0FF';
    const occupancyColor = '#4866FF';
    return (_jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: `tooltip-vla`, children: `${formatDateShort(contractFrom)} — ${formatDateShort(contractTo)}` }), children: _jsx("div", { style: { background: vacancyColor, position: "relative", overflow: "hidden", height: 15 }, children: _jsx("div", { style: {
                    background: occupancyColor,
                    position: "absolute",
                    top: 0,
                    height: "100%",
                    left: `${left}%`,
                    width: `${width}%`
                } }) }) }));
};
