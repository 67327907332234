import { jsx as _jsx } from "react/jsx-runtime";
import * as rt from 'react-table';
import { Input } from 'Atoms/Input';
import { SelectFilter } from '../filters';
import { setDebugVariable } from '@f2w/utils';
export const useFilters = {
    initOptions: (options, table) => {
        return {
            autoResetFilters: false,
            disableFilters: !options.enabled,
            defaultCanFilter: false,
            defaultColumn: {
                disableFilters: true,
                defaultCanFilter: false,
                Filter: ({ column: { filterValue, preFilteredRows, setFilter }, }) => {
                    const count = preFilteredRows ? preFilteredRows.length : 0;
                    return (_jsx(Input, { value: filterValue || '', onChange: value => {
                            setFilter(value || undefined);
                        }, placeholder: `Search ${count} records...` }));
                },
            },
        };
    },
    initColumnOptions: (options, column) => {
        return options;
    },
    createOptions: (opts, { manualControl }) => {
        return {
            manualFilters: !!manualControl,
        };
    },
    createColumnOptions: ({ enabled, selectFilter: selectFilterProps, ...initProps }, column) => {
        let props = { ...initProps };
        column.id === 'status' && setDebugVariable('ff', { column, options: initProps, SelectFilter });
        if (selectFilterProps) {
            props = {
                ...props,
                Filter: (table) => _jsx(SelectFilter, { table: table, ...selectFilterProps }),
            };
            if (selectFilterProps.isMulti) {
                props.filter = (rows, columnIds, filterValue) => {
                    return filterValue.length === 0 ? rows : rows.filter((row) => {
                        column.id === 'status' && setDebugVariable({ ffrow: row });
                        return columnIds.filter(id => filterValue.includes(String(row.values[id]))).length !== 0;
                    });
                };
            }
        }
        return {
            disableFilters: column.isCustom || !enabled,
            ...props,
        };
    },
    hook: hooks => {
        const red = '#ff1c1c';
        const green = '#19e619';
        const padLength = 12;
        rt.useFilters(hooks);
    },
};
