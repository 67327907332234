import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { Button } from 'Atoms/Button';
import { translate } from 'Services/Translator';
import { Icon } from 'Atoms/Icon';
import DataTableActions from '../components/ServiceProviderDataTableActions';
const BaseTable = Table.factory()(() => ({
    tableId: 'ServiceProvider',
    Actions: ({ openUpsertForm }) => (_jsx("div", { children: _jsxs(Button, { onClick: () => openUpsertForm(), title: translate('Neu'), children: [_jsx(Icon, { icon: 'plus' }), _jsx("span", { style: { verticalAlign: 'sub' }, children: translate('new') })] }) })),
    RowActions: DataTableActions,
    usePagination: {},
    useGlobalFilter: {},
    manualControl: true,
    columns: {
        $actions: {
            sticky: 'left',
        },
        name: {
            Header: translate('serviceProvider.name'),
            width: 260,
            useSortBy: {},
        },
        phone: {
            Header: translate('serviceProvider.phoneNumber'),
            cellProps: { noWrap: true },
            width: 200,
            useSortBy: {},
        },
        email: {
            Header: translate('serviceProvider.emailAddress'),
            cellProps: { noWrap: true },
            Cell: ({ value }) => (_jsx("a", { href: 'mailto:' + value, children: value })),
            useSortBy: {},
        },
    }
}));
export const ServiceProviderDataTable = Object.assign(BaseTable, {
    use: ({ listServiceProviders, openUpsertForm, deleteServiceProvider }) => {
        return ServiceProviderDataTable.useTable({
            openUpsertForm,
            deleteServiceProvider
        }, () => ({
            getData: async ({ request }) => listServiceProviders(request)
        }));
    }
});
