import { cleanObj, orFunction } from '@f2w/utils';
import { cssRulesMap, useTheme } from '@f2w/theme';
export function useLayoutProps({ color, bg, background = bg, font, size, basis = size, flexBasis = basis, grow, flexGrow = grow, shrink, flexShrink = shrink, flow: flexFlow, flex, wrap: flexWrap, gap, columnGap, rowGap, justifyContent, justifySelf, justifyItems, alignContent, alignSelf, alignItems, posX, posY, pos = [posY, posX], top = pos?.[0], right = pos?.[1] ?? top, bottom = pos?.[2] ?? top, left = pos?.[3] ?? right, px, py, p, pt = py, pb = py, pl = px, pr = px, mx, my, m, mt = my, mb = my, ml = mx, mr = mx, bx, by, b, bt = by, bb = by, bl = bx, br = bx, brd, w: width, minw: minWidth, maxw: maxWidth, h: height, minh: minHeight, maxh: maxHeight, z: zIndex, ...props }) {
    const { palette, typography } = useTheme();
    const styles = cleanObj({
        zIndex,
        top,
        bottom,
        left,
        right,
        padding: Array.isArray(p) ? p.join(' ') : p,
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
        margin: Array.isArray(m) ? m.join(' ') : m,
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
        border: b,
        borderTop: bt,
        borderBottom: bb,
        borderLeft: bl,
        borderRight: br,
        borderRadius: brd,
        width,
        minWidth,
        maxWidth,
        height,
        minHeight,
        maxHeight,
        flexBasis,
        flexGrow,
        flexShrink,
        flexFlow,
        flex,
        flexWrap,
        gap,
        columnGap,
        rowGap,
        justifyContent,
        justifySelf,
        justifyItems,
        alignContent,
        alignSelf,
        alignItems,
        color: color ? palette.color(color.split('.')) : undefined,
        backgroundColor: palette.parse(background) ?? background,
        ...typography.variants[font]?.fontStyles,
    });
    for (const key of Object.keys(props)) {
        if (!cssRulesMap[key]) {
            continue;
        }
        Object.assign(styles, orFunction(cssRulesMap[key], props[key]));
        delete props[key];
    }
    return {
        ...props,
        style: {
            ...props.style,
            ...styles,
        },
    };
}
