import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, NumberType, SelectType, TextType } from "@f2w/form";
import React from "react";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { translate } from "Services/Translator";
export class RoomType extends AbstractFormType {
    buildFields() {
        return {
            general: new RoomType.General({
                inheritData: true,
                label: false
            })
        };
    }
}
(function (RoomType) {
    class General extends AbstractFormType {
        properties = [];
        buildings = [];
        rentalObjects = [];
        _useField(props) {
            super._useField(props);
            const initialLoad = React.useRef(true);
            const [properties, setProperties] = React.useState([]);
            const [buildingMap, setBuildingMap] = React.useState(new Map());
            const [rentalObjectMap, setRentalObjectMap] = React.useState(new Map());
            this.properties = properties;
            this.buildings = buildingMap.get(this.field.value.propertyId) ?? [];
            this.rentalObjects = rentalObjectMap.get(this.field.value.buildingId) ?? [];
            const portfolio = usePortfolio();
            React.useEffect(() => {
                const buildingMap = new Map();
                portfolio.getBuildings().forEach(building => {
                    buildingMap.set(building.propertyId, [
                        ...(buildingMap.get(building.propertyId) ?? []),
                        {
                            label: building.name,
                            value: building.id
                        }
                    ]);
                });
                const rentalObjectMap = new Map();
                portfolio.getRentalObjects().forEach(rentalObject => {
                    rentalObjectMap.set(rentalObject.buildingId, [
                        ...(rentalObjectMap.get(rentalObject.buildingId) ?? []),
                        {
                            label: rentalObject.name,
                            value: rentalObject.id
                        }
                    ]);
                });
                setProperties(portfolio.getProperties().map(property => ({
                    label: property.name,
                    value: property.id
                })));
                setBuildingMap(buildingMap);
                setRentalObjectMap(rentalObjectMap);
            }, []);
            React.useEffect(() => {
                if (!initialLoad.current) {
                    this.children.buildingId.field.setValue(undefined, false);
                    this.children.rentalObjectId.field.setValue(undefined, false);
                }
                return () => {
                    initialLoad.current = false;
                };
            }, [this.field.value.propertyId]);
        }
        buildFields() {
            return {
                propertyId: new SelectType({
                    label: translate('property'),
                    searchable: true,
                    choiceOptions: () => this.properties
                }),
                buildingId: new SelectType({
                    label: translate('building.title'),
                    searchable: true,
                    choiceOptions: () => this.buildings
                }),
                rentalObjectId: new SelectType({
                    label: translate('rentalObject'),
                    searchable: true,
                    choiceOptions: () => this.rentalObjects
                }),
                area: new NumberType({
                    label: translate('rentalObjectRoom.area'),
                    required: false,
                    getProps: () => ({
                        suffix: _jsxs(_Fragment, { children: ["m", _jsx("sup", { children: "2" })] })
                    })
                }),
                notes: new TextType({
                    label: translate('rentalObjectRoom.notes'),
                    required: false
                })
            };
        }
    }
    RoomType.General = General;
})(RoomType || (RoomType = {}));
