import { jsx as _jsx } from "react/jsx-runtime";
import { parseJsonObject } from 'Utils/parseJson';
import { OldAppLayout } from './layout';
export const entryPoints = {};
export async function registerEntrypoint(id, ...args) {
    const el = document.getElementById(id);
    if (el) {
        const Component = args.pop();
        const { resolveProps, ...props } = { ...args.shift() };
        const meta = {
            id,
            el,
            props,
            render: () => {
                let entryProps = parseJsonObject(el?.dataset?.props);
                if (resolveProps)
                    entryProps = resolveProps(entryProps);
                return (_jsx(OldAppLayout, { ...props, children: _jsx(Component, { ...entryProps }) }));
            },
        };
        entryPoints[id] = meta;
    }
}
