import { jsx as _jsx } from "react/jsx-runtime";
import { Schema, SimpleType } from '../core';
import { translate } from 'Services/Translator';
import moment from 'moment';
import { FormControl } from 'Atoms/Input';
import { DateManager } from 'Atoms/Input/controls';
export class DateType extends SimpleType {
    static MIN = DateManager.MIN_DATE;
    static MAX = DateManager.MAX_DATE;
    FORMAT = DateManager.FORMAT;
    constructor(options, parent) {
        super({
            format: 'dd.MM.yyyy',
            ...options,
        }, parent, Schema.date());
    }
    __configureOptions({ min, max, ...options }) {
        options = super.__configureOptions(options);
        const { maxDate, minDate } = DateManager({
            minDate: min,
            maxDate: max,
        });
        return {
            ...options,
            min: minDate,
            max: maxDate,
            constraints: {
                min: [minDate, translate('form.type.date.error.min')],
                max: [maxDate, translate(('form.type.date.error.max'))],
                ...options?.constraints,
            }
        };
    }
    static format(value, format = 'L') {
        let dateValue = value && moment(value);
        return dateValue?.isValid() ? dateValue.format(format) : '';
    }
    static useFormat(value, format = 'L') {
        const dateValue = value && moment(value);
        return dateValue && dateValue.isValid() ? dateValue.format(format) : null;
    }
    cast(value, ignoreDefaults) {
        if (value == null || value === "") {
            if (this.isNullable)
                return null;
            if (!ignoreDefaults) {
                return this.cast(this.schema.getDefault(), true);
            }
            return undefined;
        }
        const date = moment(value);
        if (date.isValid()) {
            return date.format(this.FORMAT);
        }
        if (this.isNullable)
            return null;
    }
    getWidgetProps() {
        return {
            ...super.getWidgetProps(),
            type: this.options.type ?? 'date',
        };
    }
    WidgetComponent(props) {
        return _jsx(FormControl.DatePicker, { ...{
                ...props,
                dateFormat: this.options.format,
                minDate: this.options.min,
                maxDate: this.options.max,
                inline: this.options.inline,
                startDate: this.options.startDate,
                endDate: this.options.endDate,
                type: this.options.type,
                variant: this.options.variant,
                selectsStart: this.options.selectsStart,
                selectsEnd: this.options.selectsEnd,
                includeDates: this.options.includeDates,
                includeDateIntervals: this.options.includeDateIntervals,
                excludeDates: this.options.excludeDates,
                excludeDateIntervals: this.options.excludeDateIntervals,
            } });
    }
}
