import { useCallback, useMemo, useState } from 'react';
import * as f from 'formik';
import { orFunction, toPromise } from 'Utils/types';
import { useDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { setDebugVariable } from '@f2w/utils';
import { FormError } from '../core';
import useUpdatedRef from '@restart/hooks/useUpdatedRef';
import { useEventCallback } from '@restart/hooks';
export var FormApi;
(function (FormApi) {
    function use(type, props) {
        const dispatcher = useDispatcherApi();
        const [loading, setLoading] = useState(false);
        const { name: _formName = type.formName, initialValues: _initialValues, updateData: _updateData, onSave: _onSave, onReset: _onReset, successMessage = translate('form.response.success'), errorMessage = translate('form.response.success'), enableReinitialize, onLoad, ...options } = props;
        let _console = console;
        _console = undefined;
        const initialValues = useMemo(() => {
            const initialValues = type.cast(props.initialValues);
            _console?.log('[FORM.initialValues]', { initialValues });
            return initialValues;
        }, [type, enableReinitialize && props.initialValues]);
        const onSubmit = useCallback(async (values, utils) => {
            if (!loading) {
                const _handleSubmit = async () => {
                    if (_updateData) {
                        return _updateData(values, { utils, type })
                            .then((data) => {
                            _console?.log('updateData', data);
                            utils.resetForm({ values });
                            return { data };
                        });
                    }
                    if (_onSave) {
                        return toPromise(_onSave, values, utils, type)
                            .then((response) => {
                            if (response) {
                                _console?.log('onSave', response);
                                utils.resetForm({ values: type.cast(response?.updateValues ?? values) });
                                return { ...response, data: response?.data || true };
                            }
                            return { data: true };
                        });
                    }
                };
                setLoading(true);
                _console?.group('FORM.onSubmit', { utils, type });
                return _handleSubmit()
                    .then((response) => {
                    _console?.log('success', response);
                    utils.setStatus({});
                    successMessage && dispatcher.toast?.success({ message: successMessage });
                    return response;
                })
                    .catch(error => {
                    FormError.handleError({
                        error,
                        type,
                        setStatus: utils.setStatus,
                        dispatcher: dispatcher.toast,
                        defaultMessage: (errorMessage ?? translate('form.response.error'))
                    });
                    return false;
                })
                    .finally(() => {
                    setLoading(false);
                    _console?.groupEnd();
                });
            }
        }, [type, _updateData, _onSave, loading, setLoading]);
        const onReset = useCallback((values, util) => {
            type.field.reset();
        }, [type]);
        const form = f.useFormik({
            validateOnChange: true,
            validateOnMount: false,
            validateOnBlur: true,
            enableReinitialize,
            initialValues,
            onSubmit,
            onReset,
        });
        const isTouched = useMemo(() => Object.keys(form.touched).length !== 0, [form.touched]);
        const handleSave = useEventCallback(async ({ force = true, close = !force } = {}) => {
            let form = formRef.current;
            if (force || form.dirty) {
                const response = await form.submitForm();
                form = formRef.current;
                const isValid = form?.isValid && response && 'data' in response;
                _console?.log('FORM::save', { isValid });
                if (isValid)
                    return response;
            }
        });
        const api = {
            options: options,
            name: _formName,
            loading: loading,
            isTouched: isTouched,
            setLoading: setLoading,
            getType: () => type,
            render: type.render,
            save: handleSave,
        };
        const formRef = useUpdatedRef(form);
        Object.assign(form, api);
        type.setFormik(form);
        setDebugVariable('fw_form', form);
        setDebugVariable('fw_type', type);
        return form;
    }
    FormApi.use = use;
})(FormApi || (FormApi = {}));
export function useForm(...args) {
    const { name: _formName, type: createType, onSubmit: _onSubmit, onSave = _onSubmit, onSave2, ...props } = (args.length === 2 ? { type: args.shift(), ...args.shift() } : args.shift());
    const formName = useMemo(() => orFunction(_formName), [_formName]);
    const type = useMemo(() => createType().createOld({ name: formName }), [formName]);
    const form = FormApi.use(type, {
        ...props,
        onSave,
        name: formName,
    });
    return form;
}
