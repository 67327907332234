import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { CreditorProcessStep, CreditorState } from 'Pages/Creditor/enums';
import { ModalNew } from 'Components/Modal';
import { translate } from 'Services/Translator';
import { Form, FormProvider, ServerError, useStepForm } from '@f2w/form';
import { Box, Button, Div, FlexRow, Spinner } from 'Components/Atoms';
import { CreditorServices } from 'Pages/Creditor/services';
import parse from 'html-react-parser';
import { Body, Container, Content, Footer, Header, Section } from 'Atoms/Layout/components';
import { Step } from './components/Step';
import { UseStyled } from '@fw/theme';
import { CreditorInvoiceState } from 'Pages/Creditor/components/CreditorInvoiceState';
import { ProcessingType } from 'Pages/Creditor/components/CreditorProcessForm/forms/ProcessingType';
import { InvoicePositionsType } from 'Pages/Creditor/components/CreditorProcessForm/forms/InvoicePositionsType';
import { ApprovalType } from 'Pages/Creditor/components/CreditorProcessForm/forms/ApprovalType';
import { toNum } from 'Utils';
import { useEventCallback } from '@restart/hooks';
import { MediaPreview } from 'Atoms/Media';
import { useDispatcherApi, useToastDispatcherApi } from 'Components/Dispatcher';
const Label = UseStyled.styled('div', ({ cursorPointer }) => ({
    cursor: cursorPointer ? 'pointer' : 'default'
}));
export const CreditorProcessView = (props) => {
    const { formData, setFormData, onRefresh: _onRefresh, } = props;
    const onClose = async () => {
        await setFormData(null);
        _onRefresh();
    };
    return (_jsxs(ModalNew, { show: !!formData?.id, onHide: onClose, size: 'detailView', children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onClose, children: _jsx(ModalNew.Title, { children: translate('creditor.edit') }) }), formData?.id && _jsx(CreditorProcessFormTemplate, { ...props, onClose: onClose })] }));
};
const CreditorProcessFormTemplate = ({ onClose, formData, setFormData, services, }) => {
    const setCreditorForm = useEventCallback((currentStep, state) => (setFormData(prev => ({
        ...prev,
        currentStep,
        state: state ?? prev.state
    }))));
    const stepForm = useStepForm({
        stepId: formData?.currentStep,
        onClose,
        onUpdate: async (state, { step, nextStep, prevStep, name }) => {
            if (nextStep) {
                await setCreditorForm(toNum(nextStep), state);
            }
        },
        steps: {
            [CreditorProcessStep.PROCESSING]: {
                name: 'creditor-processing-form',
                type: () => new ProcessingType(),
                loadData: async () => services.getProcessingData(formData.id),
                updateData: async (data) => services.updateProcessingStepData(formData.id, data),
            },
            [CreditorProcessStep.INVOICE_POSITIONS]: {
                name: 'creditor-invoice-positions-form',
                type: () => new InvoicePositionsType(),
                loadData: async () => services.getInvoicePositionsData(formData.id),
                updateData: async (data) => services.updateInvoicePositionsStepData(formData.id, data),
            },
            [CreditorProcessStep.APPROVAL]: {
                name: 'creditor-approval-form',
                type: () => new ApprovalType({
                    creditorState: () => formData.state
                }),
                loadData: async () => services.getApprovalData(formData.id).then((data) => ({
                    ...data,
                    id: formData.id,
                    payerBankAccountId: data.payerBankAccountId === -1 ? undefined : data.payerBankAccountId,
                })),
                updateData: async (data, meta) => services.updateApprovalStepData(formData.id, {
                    ...data,
                    payerBankAccountId: data.payerBankAccountId === -1 ? undefined : data.payerBankAccountId,
                }).then(() => undefined),
            },
        },
    });
    useEffect(() => {
        loadForm();
    }, [formData?.currentStep, formData?.id]);
    const { form, currentStep, saveForm, closeForm, loadForm } = stepForm;
    const { modal } = useDispatcherApi();
    const { error } = useToastDispatcherApi();
    const switchToNonAccountingStep = async () => {
        if (formData.state !== CreditorState.READY_FOR_PAYMENT) {
            return { switch: true, state: formData.state };
        }
        const confirm = await modal.confirmAsync({
            message: translate('creditor.resetAccountingConfirmation.title'),
            help: translate('creditor.resetAccountingConfirmation.text'),
            btnConfirm: translate('creditor.resetAccountingConfirmation.confirmBtn'),
        });
        if (!confirm) {
            return { switch: false, state: formData.state };
        }
        try {
            await CreditorServices.resetCreditorAccounting(formData.id);
        }
        catch (e) {
            const status = e.response.status;
            const data = e.response.data;
            if (status === 400) {
                error({
                    timeout: 5,
                    message: data.message
                });
            }
            else {
                error({
                    timeout: 5,
                    message: translate('client.response.error.saving')
                });
            }
            return { switch: false, state: formData.state };
        }
        return { switch: true, state: CreditorState.INVOICE_ASSIGNED };
    };
    return (_jsxs(Body, { split: true, children: [_jsxs(Container, { flex: true, children: [_jsx(Header, { children: _jsx(Header.Avatar, { avatarIcon: 'file', title: _jsxs(_Fragment, { children: ["#", formData.id, " ", translate('creditorInvoice')] }), actions: _jsx(_Fragment, { children: _jsx(CreditorInvoiceState, { state: formData.state }) }) }) }), _jsxs(Section, { sticky: 0, children: [_jsxs(FlexRow, { justifySpaceBetween: true, pt: 40, pb: 15, children: [_jsx(Step, { pl: 23, onStepClick: async () => {
                                            const switchConfirmation = await switchToNonAccountingStep();
                                            if (switchConfirmation.switch) {
                                                setCreditorForm(CreditorProcessStep.PROCESSING, switchConfirmation.state);
                                            }
                                        }, stepNumber: 1, isPassed: [
                                            CreditorState.INFO_CONFIRMED,
                                            CreditorState.INVOICE_ASSIGNED,
                                            CreditorState.READY_FOR_PAYMENT
                                        ].includes(formData.state) }), _jsx(Div, { pl: 20, pr: 20, pt: 10, flexGrow: 1, children: _jsx("hr", { style: {
                                                borderTop: "3px solid #D7D8DB"
                                            } }) }), _jsx(Step, { onStepClick: [
                                            CreditorState.INFO_CONFIRMED,
                                            CreditorState.INVOICE_ASSIGNED,
                                            CreditorState.READY_FOR_PAYMENT
                                        ].includes(formData.state)
                                            ? async () => {
                                                const switchConfirmation = await switchToNonAccountingStep();
                                                if (switchConfirmation.switch) {
                                                    setCreditorForm(CreditorProcessStep.INVOICE_POSITIONS, switchConfirmation.state);
                                                }
                                            }
                                            : undefined, stepNumber: 2, isPassed: [
                                            CreditorState.INVOICE_ASSIGNED,
                                            CreditorState.READY_FOR_PAYMENT
                                        ].includes(formData.state) }), _jsx(Div, { pl: 20, pr: 20, pt: 10, flexGrow: 1, children: _jsx("hr", { style: {
                                                borderTop: "3px solid #D7D8DB"
                                            } }) }), _jsx(Step, { pr: 11, onStepClick: [
                                            CreditorState.INFO_PENDING,
                                            CreditorState.QR_PENDING,
                                            CreditorState.QR_COMPLETED,
                                            CreditorState.INFO_CONFIRMED
                                        ].includes(formData.state)
                                            ? undefined
                                            : () => setCreditorForm(CreditorProcessStep.APPROVAL), stepNumber: 3, isPassed: [
                                            CreditorState.READY_FOR_PAYMENT
                                        ].includes(formData.state) })] }), _jsxs(FlexRow, { justifySpaceBetween: true, pb: 40, children: [_jsx(Label, { cursorPointer: true, onClick: () => setCreditorForm(CreditorProcessStep.PROCESSING), children: translate('creditor.process.processing') }), _jsx(Label, { style: {
                                            paddingRight: '25px'
                                        }, cursorPointer: [
                                            CreditorState.INFO_PENDING,
                                            CreditorState.QR_PENDING,
                                            CreditorState.QR_COMPLETED,
                                        ].includes(formData.state), onClick: [
                                            CreditorState.INFO_PENDING,
                                            CreditorState.QR_PENDING,
                                            CreditorState.QR_COMPLETED,
                                        ].includes(formData.state)
                                            ? undefined
                                            : () => setCreditorForm(CreditorProcessStep.INVOICE_POSITIONS), children: translate('creditor.process.positions') }), _jsx(Label, { cursorPointer: [
                                            CreditorState.INFO_PENDING,
                                            CreditorState.QR_PENDING,
                                            CreditorState.QR_COMPLETED,
                                            CreditorState.INFO_CONFIRMED
                                        ].includes(formData.state), onClick: [
                                            CreditorState.INFO_PENDING,
                                            CreditorState.QR_PENDING,
                                            CreditorState.QR_COMPLETED,
                                            CreditorState.INFO_CONFIRMED
                                        ].includes(formData.state)
                                            ? undefined
                                            : () => setCreditorForm(CreditorProcessStep.APPROVAL), children: translate('creditor.process.approval') })] })] }), _jsx(Section, { children: form ? (_jsx(FormProvider, { form: form, children: _jsx(Form, { noValidate: true, style: { padding: '0 15px' }, children: form.render() }) })) : (_jsx(Spinner, { align: 'center' })) }), _jsx(Footer, { sticky: { bottom: 0 }, children: _jsxs(FlexRow, { alignCenter: true, mt: 10, children: [_jsx(Box, { flex: '1 1 auto', children: _jsx(ServerError, { children: form.status?.error ? parse(form.status?.error) : undefined }) }), _jsxs(FlexRow, { justifyContent: 'flex-end', gap: 5, children: [_jsx(Button, { "$color": '', label: translate('btn.close'), onClick: () => closeForm() }), _jsx(Button, { type: 'submit', variant: currentStep.nextStep ? 'outline' : 'fill', label: translate('btn.saveAndClose'), onClickAsync: async () => {
                                                await saveForm(true);
                                            } }), currentStep.nextStep && (_jsx(Button, { type: 'submit', label: translate('btn.nextStep'), onClickAsync: async () => {
                                                await saveForm(false);
                                            } }))] })] }) })] }), _jsx(Container, { style: { borderLeft: '1px solid #d7d8db' }, children: _jsx(Content, { style: { justifyContent: 'center', alignItems: 'center' }, children: _jsx(MediaPreview, { ...formData?.receipt }) }) })] }));
};
