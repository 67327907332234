import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
const UserSelect = (props) => {
    const { users, ...rest } = props;
    const options = users.map((user) => ({
        value: user.id,
        label: user.fullName,
        user,
    }));
    return (_jsx(Select, { options: options, ...rest }));
};
export default UserSelect;
