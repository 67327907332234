import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { components } from 'react-select';
import { SearchIcon } from './Icons';
const createPropertyOption = ({ id, name, zip, city }) => ({
    value: id,
    label: `${name}, ${zip} ${city}`
});
const labelSorter = (a, b) => a.label.localeCompare(b.label, 'de', { numeric: true });
export const PortfolioPropertySelect = ({ value = undefined, $variant = "default", ...props }) => {
    const portfolio = usePortfolio();
    const properties = portfolio ? portfolio.getProperties() : [];
    const options = properties
        .map((property) => createPropertyOption(property))
        .sort(labelSorter);
    const selectedProperty = value ? portfolio.getPropertyById(value) : null;
    const selectedOption = selectedProperty
        ? createPropertyOption(selectedProperty)
        : null;
    return (_jsx(Select, { value: selectedOption, options: options, isSearchable: true, menuPortalTarget: document.body, ...props }));
};
export const PortfolioPropertySelect2 = ({ value = undefined, $variant = "default", ...props }) => {
    const portfolio = usePortfolio();
    const properties = portfolio ? portfolio.getProperties() : [];
    const options = properties.map(property => createPropertyOption(property));
    const selectedProperty = value ? portfolio.getPropertyById(value) : null;
    const selectedOption = selectedProperty
        ? createPropertyOption(selectedProperty)
        : null;
    return (_jsx(Select, { value: selectedOption, options: options, components: $variant === 'search' ? {
            ValueContainer: ({ children, ...props }) => {
                return _jsxs(components.ValueContainer, { ...props, children: [_jsx("div", { style: { marginRight: 20, color: "#6C7487" }, children: _jsx(SearchIcon, {}) }), children] });
            },
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
        } : {}, isSearchable: true, menuPortalTarget: document.body, ...props }));
};
