import { useFormModal } from "@f2w/form";
import { BaseBankAccountType } from "Pages/BankAccount/forms/BaseBankAccountType";
import { translate } from "Services/Translator";
import { BankAccountType } from "Pages/BankAccount/enums";
export const useCreateBankAccountForm = ({ fixedBankType, createBankAccount, onSubmit, initialIban }) => useFormModal({
    name: 'create-bank-account-form',
    type: () => new BaseBankAccountType({
        fixedType: fixedBankType,
        initialIban: initialIban
    }),
    title: () => {
        if (!fixedBankType) {
            return translate('bankAccount.new');
        }
        switch (fixedBankType) {
            case BankAccountType.CREDIT: return translate('bankAccount.new.credit');
            case BankAccountType.DEPOSIT: return translate('bankAccount.new.deposit');
            case BankAccountType.RENT: return translate('bankAccount.new.rent');
            case BankAccountType.TENANT: return translate('bankAccount.new.tenant');
        }
    },
    onSave: async (values) => {
        const data = { ...values };
        return createBankAccount(data).then(onSubmit);
    }
});
