export var ActiveView;
(function (ActiveView) {
    ActiveView["PROPERTY"] = "property";
    ActiveView["BUILDING"] = "building";
    ActiveView["RENTAL_OBJECT"] = "rentalObject";
    ActiveView["RENTAL_OBJECT_ROOM"] = "rentalObjectRoom";
    ActiveView["CONTRACT"] = "contract";
    ActiveView["RENT"] = "rent";
    ActiveView["ASSET"] = "asset";
})(ActiveView || (ActiveView = {}));
export var BillingCycle;
(function (BillingCycle) {
    BillingCycle[BillingCycle["MONTHLY"] = 1] = "MONTHLY";
    BillingCycle[BillingCycle["QUARTERLY"] = 3] = "QUARTERLY";
    BillingCycle[BillingCycle["HALF_ANNUALLY"] = 6] = "HALF_ANNUALLY";
    BillingCycle[BillingCycle["ANNUALLY"] = 12] = "ANNUALLY";
})(BillingCycle || (BillingCycle = {}));
