import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useUncontrolled } from 'uncontrollable';
import { Nav, NavItem, NavLink } from 'Components/Atoms/Nav';
import { TabContainer, TabContent, TabPane } from './components';
import { forEach, map } from 'Components/Base/ElementChildren';
import { UseStyled } from '@f2w/theme';
import { isVertical } from 'Components/Atoms/Nav/Nav';
import classnames from 'classnames';
import PropTypes from 'prop-types';
export function Tabs(props) {
    const { id, onSelect, transition, mountOnEnter, unmountOnExit, fill, children, position, activeKey = getDefaultActiveKey(children), ...controlledProps } = useUncontrolled(props, {
        activeKey: 'onSelect',
    });
    return (_jsx(TabContainer, { id: id, activeKey: activeKey, onSelect: onSelect, transition: transition, mountOnEnter: mountOnEnter, unmountOnExit: unmountOnExit, children: _jsxs(Tabs.Root, { position: position, className: classnames({
                ['fw-tabs--fill']: fill
            }), children: [_jsx(Nav, { ...controlledProps, position: position, role: "tablist", as: "nav", children: map(children, renderTab) }), _jsx(TabContent, { children: map(children, (child) => {
                        const childProps = { ...child.props };
                        delete childProps.title;
                        delete childProps.disabled;
                        delete childProps.tabClassName;
                        return _jsx(TabPane, { ...childProps });
                    }) })] }) }));
}
Tabs.defaultProps = {
    variant: 'tabs',
    mountOnEnter: false,
    unmountOnExit: false,
};
Tabs.displayName = 'Tabs';
(function (Tabs) {
    Tabs.Root = UseStyled.styled('div', ({ theme: { palette }, position = 'top', }) => ({
        display: "flex",
        flexDirection: isVertical(position) ? 'row' : 'column',
        flex: '1 100%',
        gap: '1.5rem',
        '.fw-tabs--fill': {
            flex: '1 100%',
            minHeight: 0,
            height: '100%'
        }
    }), ({ theme, position, }) => {
        switch (position) {
            case "top":
                return {};
            case "left":
                return {};
            case "bottom":
            case "right":
                return {
                    [`${Nav.Root}`]: {
                        order: 2
                    },
                };
        }
    });
})(Tabs || (Tabs = {}));
export class Tab extends React.Component {
    static propTypes = {
        title: PropTypes.node.isRequired,
        eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };
    static Container = TabContainer;
    static Content = TabContent;
    static Pane = TabPane;
    render() {
        throw new Error('ReactBootstrap: The `Tab` component is not meant to be rendered! ' +
            "It's an abstract component that is only valid as a direct Child of the `Tabs` Component. " +
            'For custom tabs components use TabPane and TabsContainer directly');
        return null;
    }
}
function getDefaultActiveKey(children) {
    let defaultActiveKey;
    forEach(children, (child) => {
        if (defaultActiveKey == null) {
            defaultActiveKey = child.props.eventKey;
        }
    });
    return defaultActiveKey;
}
function renderTab(child) {
    const { title, eventKey, disabled, tabClassName, id } = child.props;
    if (title == null) {
        return null;
    }
    return (_jsx(NavItem, { as: NavLink, eventKey: eventKey, disabled: disabled, id: id, className: tabClassName, children: title }));
}
