export default {
    components: {
        IndicatorSeparator: null,
    },
    styles: {
        groupHeading: (base, state) => {
            const { theme: { palette, rem } } = state.theme.vars;
            return {
                ...base,
                fontSize: '90%',
                fontWeight: 600,
                color: palette.dark.$500,
            };
        },
        option: (base, state) => {
            const { font, menuListStyles, borderRadius, theme: { palette, rem } } = state.theme.vars;
            const isUnactive = state?.data && state.data['isUnactive'];
            return {
                ...base,
                ...font,
                ...menuListStyles,
                padding: '8px 16px',
                margin: '4px 0',
                minHeight: 45,
                gap: 8,
                display: 'flex',
                alignItems: 'center',
                borderRadius: borderRadius,
                cursor: 'pointer',
                transition: "background-color .15s ease-in-out, border-color .15s ease-in-out",
                background: palette.white,
                border: `1px solid transparent`,
                color: palette.dark.$400,
                ':hover': {
                    background: palette.dark.$50,
                },
                ':active': {
                    backgroundColor: palette.dark.$200,
                },
                ':not(:hover)': (state.isFocused ? {
                    borderColor: palette.primary.$100,
                } : undefined),
                ...(state.isSelected ? {
                    color: palette.primary.$500,
                    background: palette.primary.$50,
                    ':hover': {
                        background: palette.primary.$50,
                    },
                } : (state.isDisabled || isUnactive ? {
                    ...(state.isDisabled && { pointerEvents: 'none' }),
                    color: palette.dark.$200,
                } : {})),
            };
        },
        dropdownIndicator: (base) => {
            return {
                ...base,
                padding: '0',
                display: 'inline',
                marginRight: '20px',
            };
        },
        control: (base, state) => {
            const { stateColor, activeColor, borderRadius, font, height, $inline, theme: { palette }, } = state.theme.vars;
            return ({
                ...base,
                boxShadow: 'none',
                outline: 'none',
                transitionTimingFunction: 'ease-in-out',
                transitionDuration: '100ms',
                transitionProperty: 'box-shadow, border',
                minHeight: height,
                padding: 0,
                ...font,
                borderRadius,
                color: palette.neutral.$900,
                ...(state.isDisabled ? {
                    placeholderColor: palette.neutral.$400,
                    color: palette.neutral.$900,
                    backgroundColor: palette.neutral.$50,
                    borderColor: palette.neutral.$100,
                } : {
                    backgroundColor: palette.white,
                    border: $inline ? 'none' : `1px solid ${(state.menuIsOpen
                        ? activeColor.$500
                        : stateColor?.$500) ?? palette.neutral.$100}`,
                    '&:hover': {
                        borderColor: `${stateColor?.$500 ?? palette.neutral.$800}`,
                        '&:focus-within': {
                            borderColor: `${stateColor?.$500 ?? activeColor.$500}`,
                            '&:hover': $inline ? {} : {
                                boxShadow: `0 0 0 4px ${stateColor?.$500 ?? activeColor.$500}40`,
                            },
                        },
                    },
                }),
            });
        },
        input: (base, state) => {
            const { paddingX, paddingY } = state.theme.vars;
            if (state.isMulti) {
                return {
                    ...base,
                    margin: '0 2px',
                    position: 'absolute',
                    ...(state.selectProps?.controlShouldRenderValue && state.hasValue ? {
                        padding: `${paddingY}px 0 ${paddingY}px ${paddingY}px`,
                        '*:focus-within > &': {
                            position: 'static',
                        },
                    } : {
                        padding: `${paddingY}px ${paddingX}px`,
                        '&:first-of-type': {
                            position: 'static',
                        },
                    }),
                };
            }
            return {
                ...base,
                margin: '0 2px',
                padding: `${paddingY}px ${paddingX}px`,
                position: 'absolute',
                '&:first-of-type': {
                    position: 'static',
                },
            };
        },
        placeholder: (base, state) => {
            const { paddingX, paddingY, theme: { palette } } = state.theme.vars;
            return {
                ...base,
                color: palette.dark.$200,
                whiteSpace: 'nowrap',
                padding: `${paddingY}px ${paddingX}px`,
            };
        },
        valueContainer: (base, state) => {
            const { paddingY, useMaxContentWidth, theme: { rem } } = state.theme.vars;
            const valuePadding = paddingY * .5;
            return {
                ...base,
                display: 'flex',
                padding: `${valuePadding}px ${rem(3)} ${valuePadding}px 0`,
                flexWrap: useMaxContentWidth ? 'nowrap' : 'wrap',
                opacity: state.isDisabled ? .5 : undefined,
            };
        },
        singleValue: (base, state) => {
            const { paddingX, paddingY, theme: { palette } } = state.theme.vars;
            return {
                ...base,
                color: palette.neutral.$900,
                padding: `${paddingY}px ${paddingY}px ${paddingY}px ${paddingX}px`,
            };
        },
        multiValue: (base, state) => {
            const { paddingY } = state.theme.vars;
            return {
                ...base,
                marginLeft: `${paddingY}px`,
                marginRight: 0,
                '& + input': {
                    position: 'absolute',
                },
            };
        },
    },
};
