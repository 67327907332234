import { GenderType, StringType } from '@f2w/form';
import { translate } from 'Services/Translator';
import { AccountType } from './AccountType';
import { isStorybookEnv } from '@f2w/utils';
import { MembershipType } from "Pages/Registration/enum/MembershipType";
import { SalesPartner } from "Pages/Registration/enum/SalesPartner";
export class RegistrationType extends AccountType {
    static create(partnerType, partnerReference) {
        const commonProps = {
            partnerReference: partnerReference
        };
        switch (partnerType) {
            case MembershipType.HEV:
                return new HevRegistrationType(commonProps);
            case SalesPartner.WWIMMO:
                return new WWRegistrationType(commonProps);
            case SalesPartner.EXTENSO:
                return new ExtensoRegistrationType(commonProps);
        }
        return new RegistrationType(commonProps);
    }
    getFieldGroups() {
        const { gender, firstName, lastName, email, terms, password, cellPhoneNumber, accountType, accountName, website, nbObjectsPlanned, nbMandatesPlanned, nbUsersPlanned, contact, billingContact, ...restFields } = this.children;
        return {
            register: {
                gender,
                firstName,
                lastName,
                email,
                terms,
            },
            step_1: {
                password
            },
            step_2: {
                accountName,
                nbObjectsPlanned,
                nbMandatesPlanned,
                nbUsersPlanned,
            },
            step_3: {
                contact,
                billingContact,
            },
        };
    }
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            title: translate('account.create'),
        };
    }
    get templateProps() {
        return this.getTemplateProps();
    }
    getTemplateProps() {
        return {
            title: translate('account.create'),
            success: {
                title: translate('account.register.email.confirm'),
                emailSent: translate('account.register.email.activation.sent'),
                requestSent: translate('account.register.email.activation.request'),
            },
        };
    }
    isPartner() {
        return this instanceof PartnerRegistrationType;
    }
    getDefaultValue() {
        if (isStorybookEnv()) {
            return {
                ...super.getDefaultValue(),
                ...this.getTestData(),
            };
        }
        return {
            ...super.getDefaultValue(),
            billingContact: {
                sameAsCustomerContact: true,
            }
        };
    }
    getTestData() {
        return {
            firstName: 'Aj',
            lastName: 'Doe',
            gender: GenderType.MALE,
            email: 'andraz+doe@fairwalter.ch',
            accountName: 'Asd',
            cellPhoneNumber: '+41723312333',
            password: 'aaaaaaaa',
            repeatPassword: 'aaaaaaaa',
        };
    }
}
class PartnerRegistrationType extends RegistrationType {
    getTemplateProps() {
        return {
            success: super.getTemplateProps().success,
        };
    }
}
export class HevRegistrationType extends PartnerRegistrationType {
    getTemplateProps() {
        return {
            ...super.getTemplateProps(),
            logo: {
                src: '/build/assets/media/logo/hev-logo-white.png',
                alt: 'HEV logo',
                height: '80px',
            },
            lead: translate('registration.hev.lead'),
        };
    }
    buildFields() {
        return {
            membershipNumber: new StringType({
                label: translate('registration.hev.membershipNumber'),
                required: true,
                constraints: { max: 255 },
            }),
            ...super.buildFields(),
        };
    }
    getGroupData(key, ...args) {
        return {
            ...super.getGroupData(key, ...args),
            membershipType: MembershipType.HEV
        };
    }
    getFieldGroups() {
        const { gender, firstName, lastName, email, terms, password, cellPhoneNumber, accountType, accountName, website, nbObjectsPlanned, nbMandatesPlanned, nbUsersPlanned, membershipNumber, contact, billingContact, ...restFields } = this.children;
        return {
            register: {
                membershipNumber,
                gender,
                firstName,
                lastName,
                email,
                terms,
            },
            step_1: {
                password,
            },
            step_2: {
                accountName,
                nbObjectsPlanned,
                nbMandatesPlanned,
                nbUsersPlanned,
            },
            step_3: {
                contact,
                billingContact,
            },
        };
    }
    getTestData() {
        return {
            ...super.getTestData(),
            membershipNumber: '123',
            lastName: 'Hev',
            email: 'andraz+hev@fairwalter.ch',
        };
    }
}
export class WWRegistrationType extends PartnerRegistrationType {
    getTemplateProps() {
        const props = super.getTemplateProps();
        return {
            ...props,
            lead: translate('registration.ww.salesPartner'),
            logo: {
                src: '/build/assets/media/logo/ww-logo.png',
                alt: 'W&W logo',
                width: '103px',
                height: '103px',
            },
        };
    }
    getResolvedValue() {
        return {
            ...super.getResolvedValue(),
            partnerReference: SalesPartner.WWIMMO
        };
    }
    getFieldGroups() {
        const { gender, firstName, lastName, email, terms, password, cellPhoneNumber, accountType, accountName, website, nbObjectsPlanned, nbMandatesPlanned, nbUsersPlanned, salesPartner, salesPerson, contact, billingContact, ...restFields } = this.children;
        ;
        return {
            register: {
                salesPartner,
                salesPerson,
                gender,
                firstName,
                lastName,
                email,
                terms,
            },
            step_1: {
                password,
            },
            step_2: {
                accountName,
                nbObjectsPlanned,
                nbMandatesPlanned,
                nbUsersPlanned,
            },
            step_3: {
                contact,
                billingContact,
            },
        };
    }
    buildFields() {
        return {
            salesPartner: new StringType({
                label: translate('registration.ww.salesPartner'),
                required: true,
                constraints: { max: [16, translate('form.validation.noLongerThan', { length: 16 })] },
                defaultValue: SalesPartner.WWIMMO,
                isVisible: () => false,
            }),
            salesPerson: new StringType({
                label: translate('registration.salesPerson'),
                required: true,
                constraints: { max: [16, translate('form.validation.noLongerThan', { length: 16 })] },
                defaultValue: this.options.partnerReference,
                getProps: () => ({ disabled: this.options.partnerReference != null }),
                isVisible: () => this.options.partnerReference == null,
            }),
            ...super.buildFields(),
        };
    }
    getTestData() {
        return {
            ...super.getTestData(),
            salesPartner: 'WWIMMO',
            salesPerson: '123',
            firstName: 'Andraz',
            lastName: 'Tester',
            email: 'andraz+tester@fairwalter.ch',
        };
    }
}
export class ExtensoRegistrationType extends PartnerRegistrationType {
    getTemplateProps() {
        const props = super.getTemplateProps();
        return {
            ...props,
            lead: translate('registration.extenso.salesPartner'),
            logo: {
                src: '/build/assets/media/logo/extenso.jpg',
                alt: 'Extenso logo',
                width: '200px',
                height: '100px',
            },
        };
    }
    getResolvedValue() {
        return {
            ...super.getResolvedValue(),
            partnerReference: SalesPartner.EXTENSO
        };
    }
    getFieldGroups() {
        const { gender, firstName, lastName, email, terms, password, cellPhoneNumber, accountType, accountName, website, nbObjectsPlanned, nbMandatesPlanned, nbUsersPlanned, salesPartner, salesPerson, contact, billingContact, ...restFields } = this.children;
        ;
        return {
            register: {
                salesPartner,
                salesPerson,
                gender,
                firstName,
                lastName,
                email,
                terms,
            },
            step_1: {
                password,
            },
            step_2: {
                accountName,
                nbObjectsPlanned,
                nbMandatesPlanned,
                nbUsersPlanned,
            },
            step_3: {
                contact,
                billingContact,
            },
        };
    }
    buildFields() {
        return {
            salesPartner: new StringType({
                label: translate('registration.extenso.salesPartner'),
                required: true,
                constraints: { max: [16, translate('form.validation.noLongerThan', { length: 16 })] },
                defaultValue: SalesPartner.EXTENSO,
                isVisible: () => false,
            }),
            salesPerson: new StringType({
                label: translate('registration.salesPerson'),
                required: true,
                constraints: { max: [16, translate('form.validation.noLongerThan', { length: 16 })] },
                defaultValue: this.options.partnerReference,
                getProps: () => ({ disabled: this.options.partnerReference != null }),
                isVisible: () => this.options.partnerReference == null,
            }),
            ...super.buildFields(),
        };
    }
}
