import { SubscriptionTableModel } from 'Pages/Subscription/models/SubscriptionTableModel';
import React from 'react';
export const useSubscriptionTable = ({ listSubscriptions }) => {
    const [subscriptions, setSubscriptions] = React.useState([]);
    React.useEffect(() => {
        listSubscriptions().then(response => setSubscriptions(response));
    }, []);
    const tableModel = React.useMemo(() => SubscriptionTableModel.create({}), []);
    const instance = tableModel.create({
        data: subscriptions
    });
    return {
        instance
    };
};
