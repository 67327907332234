import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef, useContext } from 'react';
import { useUncontrolled } from 'uncontrollable';
import useEventCallback from '@restart/hooks/useEventCallback';
import ReactDropdown from 'react-overlays/Dropdown';
import classNames from 'classnames';
import styled from 'styled-components';
import { renderChildren } from '@f2w/utils';
import SelectableContext from 'Components/Base/SelectableContext';
import { ThemeProvider, useTheme } from '@f2w/theme';
import { DropdownDivider, DropdownHeader, DropdownItem, DropdownItemText, } from './components/DropdownItem';
import { DropdownMenu, } from './components/DropdownMenu';
import { DropdownToggle } from './components/DropdownToggle';
import { dropdownSlot } from './Dropdown.theme';
const prefix = 'fw-dropdown';
const DropdownRoot = styled.div ``;
const BaseDropdown = forwardRef(({ as2: Component = DropdownRoot, innerRef, variant: initialVariant, scrollable, container, fixed, drop, flip, alignRight, offset, maxHeight, maxWidth, focusFirstItemOnShow, className, isNested, navbar: _4, defaultShow = false, ...pProps }, ref) => {
    const { show, onSelect, onToggle, ...props } = useUncontrolled(pProps, { show: 'onToggle' });
    const onSelectCtx = useContext(SelectableContext);
    const handleToggle = useEventCallback((nextShow, event, source = event.type) => {
        if (event.currentTarget === document &&
            (source !== 'keydown' || event.key === 'Escape'))
            source = 'rootClose';
        if (onToggle) {
            onToggle(nextShow, event, { source });
        }
    });
    const handleSelect = useEventCallback((key, event) => {
        if (onSelectCtx)
            onSelectCtx(key, event);
        if (onSelect)
            onSelect(key, event);
        handleToggle(false, event, 'select');
    });
    const forwardedProps = {
        flip,
        scrollable,
        isNested,
        container,
        fixed,
        alignRight,
        offset,
        maxHeight,
        maxWidth,
    };
    props.children = renderChildren(props.children, (child, { displayName }) => {
        if (DropdownMenu.displayName === displayName)
            return React.cloneElement(child, { ...forwardedProps, ...child?.props });
        return child;
    });
    const theme = useTheme();
    const variant = initialVariant ?? theme.mode ?? 'light';
    const content = (_jsx(SelectableContext.Provider, { value: handleSelect, children: _jsx(ReactDropdown, { defaultShow: defaultShow, drop: drop, show: show, alignEnd: alignRight, onToggle: handleToggle, focusFirstItemOnShow: focusFirstItemOnShow, itemSelector: `.${prefix}-item:not(.disabled):not(:disabled)`, children: _jsx(Component, { ...dropdownSlot.attr('root'), ref: innerRef || ref, className: classNames(className, prefix, {
                    [`${prefix}--${variant}`]: variant,
                    'show': show,
                    'fw-dropdown-wrap': true,
                    'fw-dropdown--root': !isNested,
                    'fw-dropup': drop === 'up',
                    'fw-dropright': drop === 'right',
                    'fw-dropleft': drop === 'left',
                }), ...props }) }) }));
    if (initialVariant) {
        return (_jsx(ThemeProvider, { mode: initialVariant, children: content }));
    }
    return content;
});
BaseDropdown.displayName = 'BaseDropdown';
const slots = {
    Divider: DropdownDivider,
    Header: DropdownHeader,
    ItemText: DropdownItemText,
    Item: DropdownItem,
    Menu: DropdownMenu,
    Toggle: DropdownToggle,
    Link: DropdownItem,
};
export const Dropdown = Object.assign(BaseDropdown, slots);
