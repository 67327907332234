import { numeral } from './Translator';
import { isNumLike } from 'Utils';
export default numeral;
export const formatAmount = (v, format = "0,0.00") => {
    return isNumLike(v) ? numeral(v).format(format) : v;
};
export const formatMoney = (v, format = "$ -0,0.00") => {
    return isNumLike(v) ? numeral(v).format(format) : v;
};
export const formatMoneyWithCurrency = (value, currency) => {
    return currency + ' ' + formatAmount(value);
};
export const formatPercentage = (v, format = "0.0%") => {
    if (typeof v === "number" && isFinite(v) && Math.abs(v) > Number.EPSILON) {
        return numeral(v).format(format);
    }
    return "0%";
};
