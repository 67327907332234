import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { translate } from 'Services/Translator';
import { formatMoneyWithCurrency } from 'Services/NumberHelper';
import { formatDateShort } from 'Services/DateHelper';
import { Table } from '@f2w/data-table/table-types';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { Td, Tr } from 'Atoms/Table';
import moment from 'moment';
import { dump } from 'Utils/logger';
import { TransactionStatusType } from '../types';
import { BankingFileService, BankingTransactionService, useTransactionManager } from '../services';
import { AssignmentTypeEdit, assignmentTypeOptions, BulkActions, InvoiceEdit, PropertyEdit, StatusBadge, StatusFilter, } from './components';
import { Text } from 'Atoms/Text';
import { Select } from 'Atoms/Select';
import { Div, FlexCol, FlexRow } from 'Atoms/Layout';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { AmountRangeFilter } from 'Pages/Accounting/AccountingRecord/components/AmountRangeFilter';
import BankAccountServices from 'Pages/BankAccount/services/bankAccountServices';
import { BankAccountType } from "Pages/BankAccount/enums/BankAccountType";
const baseModel = Table.factory()(() => {
    return {
        tableId: 'BankingTransaction',
        manualControl: true,
        useGlobalFilter: {},
        useSortBy: {},
        usePagination: {
            pageSiblingCount: 2,
        },
        initialState: {
            pageSize: 25,
            sortBy: [
                { id: 'id', desc: false },
            ],
        },
        renderRow: ({ row, instance, children }) => {
            const colSize = row.cells.length - 1;
            const colSpan = instance.updateApi.getData(row.id)?.assignmentType ? 2 : 0;
            return _jsxs(_Fragment, { children: [children, _jsxs(Tr, { bodyRow: false, children: [_jsx(Td, {}), _jsx(Td, { colSpan: colSize - colSpan, valign: 'middle', children: _jsx(Text, { neutral: 300, children: row?.original?.description }) }), colSpan > 0 && _jsx(Td, { colSpan: colSpan, children: _jsx(InvoiceEdit, { updateApi: instance.updateApi, rowId: row.id }) })] })] });
        },
        FiltersLayout: ({ renderFilter }) => (_jsxs(FlexCol, { gap: 10, children: [_jsxs(FlexRow, { gap: 5, children: [renderFilter('upload', { minw: 180, maxw: 300 }), renderFilter('status'), _jsx(Div, { w: 300, children: renderFilter('assignmentType') })] }), _jsxs(FlexRow, { gap: 5, children: [_jsx(Div, { minw: 300, children: renderFilter('property') }), _jsx(Div, { minw: 300, children: renderFilter('bank') })] }), _jsxs(FlexRow, { gap: 10, children: [renderFilter('date'), renderFilter('amount')] })] })),
        columns: {
            $actions: {},
            upload: {
                Header: translate('bankingReconciliation.upload'),
                cellProps: { noWrap: true },
                hide: true,
                useFilters: {
                    Filter: ({ column: { setFilter, filterValue }, uploadOptions }) => {
                        return _jsx(Select, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, useMaxContentWidth: true, options: uploadOptions, onChange: (newValue) => {
                                setFilter(newValue?.value != null ? newValue?.value : undefined);
                            } });
                    },
                },
            },
            date: {
                Header: translate('bankingReconciliation.date'),
                accessor: (({ date }) => formatDateShort(moment(date))),
                Cell: ({ value }) => _jsx(_Fragment, { children: value }),
                cellProps: { noWrap: true },
                useSortBy: {
                    sortDescFirst: true,
                },
                useFilters: {
                    Filter: ({ column: { filterValue, setFilter } }) => {
                        return _jsx(Table.filters.DateRangeFilter, { isClearable: true, filterValue: filterValue, onChange: setFilter });
                    },
                },
            },
            bank: {
                Header: translate('bankingReconciliation.bank'),
                cellProps: { noWrap: true },
                width: '30%',
                useFilters: {
                    Filter: ({ column: { filterValue, setFilter } }) => {
                        const [bankAccounts, setBankAccounts] = useState([]);
                        useEffect(() => {
                            BankAccountServices.getBankAccounts(BankAccountType.RENT)
                                .then(response => setBankAccounts(response.map(ba => ({
                                value: ba.id,
                                label: ba.name
                            }))));
                        }, []);
                        return _jsx(Select, { options: bankAccounts, isClearable: true, useMaxContentWidth: true, value: filterValue, onChange: (newValue) => setFilter(newValue?.value) });
                    },
                },
            },
            amount: {
                Header: translate('bankingReconciliation.amount'),
                Cell: ({ value: { value, currency } }) => _jsx("span", { children: formatMoneyWithCurrency(value, currency) }),
                useSortBy: {},
                cellProps: { noWrap: true },
                useFilters: {
                    Filter: AmountRangeFilter
                },
            },
            status: {
                Header: translate('bankingReconciliation.status'),
                Cell: ({ row, updateApi }) => _jsx(StatusBadge, { status: updateApi.getData(row.id)?.status }),
                useFilters: {
                    Filter: StatusFilter,
                },
            },
            property: {
                Header: translate('bankingReconciliation.property'),
                Cell: ({ row, updateApi }) => _jsx(PropertyEdit, { updateApi: updateApi, rowId: row.id }),
                width: '50%',
                minWidth: 220,
                useFilters: {
                    Filter: ({ column: { filterValue, setFilter } }) => {
                        const portfolio = usePortfolio();
                        return _jsx(Select, { options: portfolio.getProperties().map(p => ({
                                value: p.id,
                                label: p.name,
                            })), isClearable: true, value: filterValue, onChange: (newValue) => setFilter(newValue?.value) });
                    },
                },
            },
            assignmentType: {
                Header: translate('bankingReconciliation.type'),
                Cell: ({ row, updateApi }) => _jsx(AssignmentTypeEdit, { updateApi: updateApi, rowId: row.id }),
                cellProps: { noWrap: true },
                width: '100%',
                minWidth: 180,
                useFilters: {
                    Filter: ({ column: { filterValue, setFilter } }) => {
                        return _jsx(Select, { options: assignmentTypeOptions, isClearable: true, value: filterValue, onChange: (newValue) => setFilter(newValue?.value) });
                    },
                },
            },
        },
    };
});
const EditableTable = baseModel.extend(() => ({
    useRowState: {
        createStateManager: (props, { dataResponse }) => {
            return useTransactionManager({
                ...props,
                updateLoadedData: dataResponse.update,
            });
        },
        initialRowStateAccessor: (row) => {
            return useTransactionManager.initialStateAccessor(row?.original);
        },
    },
    useRowSelect: {
        showSelectAll: true,
        getAllIds: async (request) => BankingTransactionService.getIds(request),
    },
    RowActions: ({ row, updateApi, }) => {
        return (updateApi.isEditable(row.id) && _jsxs(ActionsDropdown, { id: row.id, children: [updateApi.isIgnored(row.id) ?
                    _jsx(ActionsDropdown.Link, { label: translate('bankingReconciliationTransactionsTable.actions.unignore'), onClick: () => updateApi.unignore(row.id) })
                    :
                        _jsx(ActionsDropdown.Link, { label: translate('bankingReconciliationTransactionsTable.actions.ignore'), onClick: () => updateApi.ignore(row.id) }), _jsx(ActionsDropdown.Link, { label: translate('bankingReconciliationTransactionsTable.actions.assign'), icon: 'save', disabled: !updateApi.canSave(row.id), onClick: () => updateApi.assign(row.id) }), _jsx(ActionsDropdown.Link, { label: translate('bankingReconciliationTransactionsTable.actions.discard'), icon: 'refresh-cw', disabled: !updateApi.isDirty(row.id), onClick: () => updateApi.reset(row.id) })] }));
    },
    BulkActions,
    columns: {
        $rowSelect: {},
    },
}));
export default function useBRTransactionsTable({ transactionServices = BankingTransactionService, getAllUploads = BankingFileService.getAllUploads } = {}) {
    const [uploadOptions, setUploadOptions] = useState([]);
    const loadUploadOptions = useCallback(async () => {
        const data = await getAllUploads();
        setUploadOptions(data.map(({ id, label }) => ({ value: id, label })));
    }, []);
    useEffect(() => {
        loadUploadOptions();
    }, []);
    return EditableTable.useTable({
        uploadOptions,
        loadUploadOptions,
        transactionServices,
    }, () => ({
        manualControl: true,
        getData: async ({ request }) => BankingTransactionService.listTransactions(request),
        initialState: {
            filters: [
                {
                    id: 'status',
                    value: [TransactionStatusType.PRE_ASSIGNED, TransactionStatusType.PENDING, TransactionStatusType.IGNORED]
                },
            ],
            sortBy: [
                { id: 'date', desc: true },
            ],
        },
    }));
}
;
const DebugTable = EditableTable.extend(() => {
    function dumpInfo(id, updateApi) {
        const { property, assignmentType, status, ...assignmentData } = updateApi.getData(id);
        return dump({
            property,
            assignmentType,
            status,
            assignmentData,
        }, false);
    }
    return {
        useExpanded: {
            renderSubRow: ({ row, instance }) => {
                return _jsxs("div", { style: { padding: '0 0 20px 0', color: '#999BA1' }, children: [row?.original?.description, _jsx("pre", { children: dumpInfo(row?.id, instance?.updateApi) })] });
            },
        },
    };
});
