import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import AssetTableModel from "Pages/Asset/models/AssetTableModel";
import { useModalDispatcherConfirm, useToastDispatcherApi } from "Components/Dispatcher";
import { translate } from "Services/Translator";
import { getGlobalContext } from "Services/GlobalContext";
import { Select } from "Atoms/Select";
import { usePortfolio } from "Components/Base/PortfolioContext";
export const useAssetsTable = ({ updateRoomsRef, filters, onCreateAsset, onCreateRoom, onViewAsset, onViewRoom, onLinkCreditorInvoice, assetServices: { listAssets, deleteAsset, getAssetsDropdownData, deleteAssets } }) => {
    const { scope } = getGlobalContext();
    const portfolio = usePortfolio();
    const tableModel = React.useMemo(() => {
        if (scope?.level === 'property') {
            return AssetTableModel.create({
                manualControl: true,
                initialState: {
                    filters: filters
                        ? [
                            ...Object.keys(filters).map(key => ({ id: key, value: filters[key] })),
                            {
                                id: 'property',
                                value: scope.id
                            }
                        ]
                        : []
                },
                columns: {
                    building: {
                        hide: true,
                        Header: translate('building.title'),
                        useFilters: {
                            Filter: ({ column: { filterValue, setFilter }, columns }) => {
                                return _jsx(Select, { value: filterValue != undefined ? filterValue : null, isSearchable: true, isClearable: true, options: portfolio.getBuildingsByPropertyId(scope.id).map(building => ({
                                        label: building.name,
                                        value: building.id
                                    })), onChange: newValue => {
                                        setFilter(newValue?.value);
                                        columns.forEach(column => {
                                            if (['rentalObject', 'room'].includes(column.id)) {
                                                column.setFilter(undefined);
                                            }
                                        });
                                    } });
                            },
                        }
                    },
                    rentalObject: {
                        hide: true,
                        Header: translate('object'),
                        useFilters: {
                            Filter: ({ column: { filterValue, setFilter }, columns }) => {
                                const selectedBuildingId = columns.find(c => c.id === 'building').filterValue;
                                return _jsx(Select, { value: filterValue !== undefined ? filterValue : null, isSearchable: true, isClearable: true, options: portfolio.getRentalObjectsByBuildingId(selectedBuildingId).map(rentalObject => ({
                                        label: rentalObject.name,
                                        value: rentalObject.id
                                    })), onChange: newValue => {
                                        setFilter(newValue?.value);
                                        columns.forEach(column => {
                                            if ('room' === column.id) {
                                                column.setFilter(undefined);
                                            }
                                        });
                                    } });
                            },
                        }
                    },
                    room: {
                        useFilters: {
                            Filter: ({ column: { filterValue, setFilter }, columns }) => {
                                const selectedRentalObjectId = columns.find(c => c.id === 'rentalObject').filterValue;
                                const [roomMap, setRoomMap] = React.useState(new Map());
                                React.useEffect(() => {
                                    getAssetsDropdownData().then(response => {
                                        const roomMap = new Map();
                                        response.rooms.forEach(room => {
                                            if (roomMap.has(room.rentalObjectId)) {
                                                const roomsInMap = roomMap.get(room.rentalObjectId);
                                                roomsInMap.push({
                                                    value: room.id,
                                                    label: room.name
                                                });
                                                roomMap.set(room.rentalObjectId, roomsInMap);
                                            }
                                            else {
                                                roomMap.set(room.rentalObjectId, [{
                                                        value: room.id,
                                                        label: room.name
                                                    }]);
                                            }
                                        });
                                        setRoomMap(roomMap);
                                        updateRoomsRef.current = setRoomMap;
                                    });
                                }, []);
                                return _jsx(Select, { value: filterValue != undefined ? filterValue : null, isSearchable: true, isClearable: true, options: roomMap.get(selectedRentalObjectId) ?? [], onChange: newValue => setFilter(newValue?.value) });
                            },
                        }
                    }
                }
            });
        }
        else if (scope?.level === 'rentalObject') {
            return AssetTableModel.create({
                manualControl: true,
                initialState: {
                    filters: filters
                        ? [
                            ...Object.keys(filters).map(key => ({ id: key, value: filters[key] })),
                            {
                                id: 'property',
                                value: portfolio.getPropertyByRentalObjectId(scope.id).id
                            },
                            {
                                id: 'building',
                                value: portfolio.getBuildingByRentalObjectId(scope.id).id
                            },
                            {
                                id: 'rentalObject',
                                value: scope.id
                            }
                        ]
                        : []
                },
                columns: {
                    room: {
                        useFilters: {
                            Filter: ({ column: { filterValue, setFilter }, columns }) => {
                                const [roomMap, setRoomMap] = React.useState(new Map());
                                React.useEffect(() => {
                                    getAssetsDropdownData().then(response => {
                                        const roomMap = new Map();
                                        response.rooms.forEach(room => {
                                            if (roomMap.has(room.rentalObjectId)) {
                                                const roomsInMap = roomMap.get(room.rentalObjectId);
                                                roomsInMap.push({
                                                    value: room.id,
                                                    label: room.name
                                                });
                                                roomMap.set(room.rentalObjectId, roomsInMap);
                                            }
                                            else {
                                                roomMap.set(room.rentalObjectId, [{
                                                        value: room.id,
                                                        label: room.name
                                                    }]);
                                            }
                                        });
                                        setRoomMap(roomMap);
                                        updateRoomsRef.current = setRoomMap;
                                    });
                                }, []);
                                return _jsx(Select, { value: filterValue != undefined ? filterValue : null, isSearchable: true, isClearable: true, options: roomMap.get(scope.id) ?? [], onChange: newValue => setFilter(newValue?.value) });
                            },
                        }
                    }
                }
            });
        }
        const objectFilters = [];
        const properties = portfolio.getProperties();
        if (properties.length === 1) {
            objectFilters.push({
                id: 'property',
                value: properties[0].id
            });
        }
        const buildings = portfolio.getBuildings();
        if (buildings.length === 1) {
            objectFilters.push({
                id: 'building',
                value: buildings[0].id
            });
        }
        const rentalObjects = portfolio.getRentalObjects();
        if (rentalObjects.length === 1) {
            objectFilters.push({
                id: 'rentalObject',
                value: rentalObjects[0].id
            });
        }
        return AssetTableModel.create({
            manualControl: true,
            initialState: {
                filters: filters
                    ? [
                        ...Object.keys(filters).map(key => ({ id: key, value: filters[key] })),
                        ...objectFilters
                    ]
                    : []
            },
            columns: {
                property: {
                    Header: translate('property'),
                    useFilters: {
                        Filter: ({ column: { filterValue, setFilter }, columns }) => {
                            return _jsx(Select, { value: filterValue != undefined ? filterValue : null, isSearchable: true, isClearable: true, options: portfolio.getProperties().map(property => ({
                                    label: property.name,
                                    value: property.id
                                })), onChange: newValue => {
                                    setFilter(newValue?.value);
                                    columns.forEach(column => {
                                        if (['building', 'rentalObject', 'room'].includes(column.id)) {
                                            column.setFilter(undefined);
                                        }
                                    });
                                } });
                        },
                    }
                },
                building: {
                    hide: true,
                    Header: translate('building.title'),
                    useFilters: {
                        Filter: ({ column: { filterValue, setFilter }, columns }) => {
                            const selectedPropertyId = columns.find(c => c.id === 'property').filterValue;
                            return _jsx(Select, { value: filterValue != undefined ? filterValue : null, isSearchable: true, isClearable: true, options: (portfolio.getBuildingsByPropertyId(selectedPropertyId) ?? []).map(building => ({
                                    label: building.name,
                                    value: building.id
                                })), onChange: newValue => {
                                    setFilter(newValue?.value);
                                    columns.forEach(column => {
                                        if (['rentalObject', 'room'].includes(column.id)) {
                                            column.setFilter(undefined);
                                        }
                                    });
                                } });
                        },
                    }
                },
                rentalObject: {
                    hide: true,
                    Header: translate('object'),
                    useFilters: {
                        Filter: ({ column: { filterValue, setFilter }, columns }) => {
                            const selectedBuildingId = columns.find(c => c.id === 'building').filterValue;
                            return _jsx(Select, { value: filterValue != undefined ? filterValue : null, isSearchable: true, isClearable: true, options: portfolio.getRentalObjectsByBuildingId(selectedBuildingId).map(rentalObject => ({
                                    label: rentalObject.name,
                                    value: rentalObject.id
                                })), onChange: newValue => {
                                    setFilter(newValue?.value);
                                    columns.forEach(column => {
                                        if ('room' === column.id) {
                                            column.setFilter(undefined);
                                        }
                                    });
                                } });
                        },
                    }
                },
                room: {
                    useFilters: {
                        Filter: ({ column: { filterValue, setFilter }, columns }) => {
                            const selectedRentalObjectId = columns.find(c => c.id === 'rentalObject').filterValue;
                            const [roomMap, setRoomMap] = React.useState(new Map());
                            React.useEffect(() => {
                                getAssetsDropdownData().then(response => {
                                    const roomMap = new Map();
                                    response.rooms.forEach(room => {
                                        if (roomMap.has(room.rentalObjectId)) {
                                            const roomsInMap = roomMap.get(room.rentalObjectId);
                                            roomsInMap.push({
                                                value: room.id,
                                                label: room.name
                                            });
                                            roomMap.set(room.rentalObjectId, roomsInMap);
                                        }
                                        else {
                                            roomMap.set(room.rentalObjectId, [{
                                                    value: room.id,
                                                    label: room.name
                                                }]);
                                        }
                                    });
                                    setRoomMap(roomMap);
                                    updateRoomsRef.current = setRoomMap;
                                });
                            }, []);
                            return _jsx(Select, { value: filterValue != undefined ? filterValue : null, isSearchable: true, isClearable: true, options: roomMap.get(selectedRentalObjectId) ?? [], onChange: newValue => setFilter(newValue?.value) });
                        },
                    }
                }
            }
        });
    }, []);
    const getData = React.useCallback(async ({ request, }) => {
        return await listAssets(request);
    }, []);
    const { error, success } = useToastDispatcherApi();
    const onDeleteConfirm = useModalDispatcherConfirm({
        onConfirm: async ({ id }, refresh) => {
            try {
                await deleteAsset(id);
            }
            catch (e) {
                error({
                    message: translate('assets.delete.error'),
                    timeout: 5,
                    showRemove: true
                });
            }
            finally {
                refresh();
            }
        },
        message: translate('assets.delete.message'),
        help: translate('assets.delete.help'),
    });
    const onDeleteAsset = async (asset, refresh) => {
        await onDeleteConfirm(asset, refresh);
    };
    const onDeleteAssetsConfirm = useModalDispatcherConfirm({
        onConfirm: async (ids, refresh) => {
            await deleteAssets(ids)
                .then(() => success({
                message: translate('assets.delete.multiple.success')
            }))
                .catch(() => {
                error({
                    message: translate('assets.delete.error'),
                    timeout: 5,
                    showRemove: true
                });
            })
                .finally(refresh);
        },
        message: translate('assets.delete.multiple.message'),
        help: translate('assets.delete.help'),
    });
    return tableModel.useTable({
        getData,
        onCreateAsset,
        onCreateRoom,
        onDeleteAsset,
        onDeleteAssets: onDeleteAssetsConfirm,
        onViewAsset,
        onViewRoom,
        onLinkCreditorInvoice
    });
};
