import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { Badge } from "Atoms/Badge";
import { RentState } from "Pages/Rent/RentListType";
const RentStateBadge = ({ state, isVacancy }) => {
    if (isVacancy === true) {
        return _jsx(VacancyRentBadge, { state: state });
    }
    else {
        return _jsx(ContractRentBadge, { state: state });
    }
};
const ContractRentBadge = ({ state }) => {
    const colorMap = {
        [RentState.Upcoming]: 'primary',
        [RentState.Inactive]: 'dark',
        [RentState.Active]: 'success',
    };
    const color = colorMap[state] || 'primary';
    return (_jsx(Badge, { "$color": color, children: state ? translate(`rent.state.${state}`) : '' }));
};
const VacancyRentBadge = ({ state }) => {
    const colorMap = {
        [RentState.Upcoming]: 'primaryOutline',
        [RentState.Inactive]: 'darkOutline',
        [RentState.Active]: 'dangerOutline',
    };
    const color = colorMap[state] || 'primary';
    return (_jsx(Badge, { "$color": color, children: state ? translate(`rent.state.${state}`) : '' }));
};
export default RentStateBadge;
