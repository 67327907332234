import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import { cssVars, styledModule } from '@f2w/theme';
import styled from 'styled-components';
import { ButtonTheme } from 'Atoms/Button/Button';
const BaseButtonToolbar = React.forwardRef(({ size, color, variant, className, gap, role = 'toolbar', ...props }, ref) => {
    const prefix = 'btn-toolbar';
    return (_jsx(ButtonTheme, { ...{ size, color, variant }, children: _jsx(ButtonToolbar.Root, { ...props, style: {
                ...cssVars({
                    '--fw-toolbar-gap': gap
                }),
                ...props.style,
            }, role: role, ref: ref, className: classNames(className, prefix) }) }));
});
export const ButtonToolbar = Object.assign(BaseButtonToolbar, styledModule(() => {
    const Root = styled.div `
        display: inline-flex;
        align-items: center;
        gap: var(--fw-toolbar-gap, 6px);
    `;
    const Item = styled.div `
        display: inline-flex;
        gap: 10px;
        align-items: center;
    `;
    const Text = styled.div `
      //font-size: 1rem;
      //line-height: var(--btn-line-height, 1.25em);
      padding: 0 4px;
      flex-wrap: nowrap;
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      //min-height: var(--btn-min-height, auto);
      //min-width: var(--btn-min-height, auto);
    `;
    return {
        Root,
        Text,
    };
}));
ButtonToolbar.displayName = 'ButtonToolbar';
