import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import LaunchpadSearchBar from './components/LaunchpadSearchBar';
import LaunchpadSearchResultItem from './components/LaunchpadSearchResultItem';
import { transChoice, translate } from 'Services/Translator';
import { Tab } from 'Atoms/Tabs';
import OutsideClickHandler from 'react-outside-click-handler';
import useDropdown from 'Hooks/useDropdown';
import { useTheme } from '@f2w/theme';
import styled, { css } from 'styled-components';
import classes from './index.module.scss';
import { Nav } from 'Components/Atoms/Nav';
import Spinner from 'Components/Spinner';
function groupSearchResultsByType(searchResults) {
    let grouppedSearchResults = {
        person: [],
        contract: [],
        debitorInvoice: [],
        creditorInvoice: [],
    };
    searchResults.forEach((searchResult) => {
        const type = searchResult.type;
        grouppedSearchResults[type].push(searchResult);
    });
    return grouppedSearchResults;
}
const LaunchpadBase = styled.div(({ isExpanded, backgroundColor }) => {
    const { rem, palette } = useTheme();
    return !isExpanded ? css `
      background-color: ${backgroundColor ? backgroundColor : palette.neutral.$50};
      border-radius: ${rem(8)};
      color: #6C7487;
      box-shadow: none;

      display: block;
      width: 100%;
      height: 100%;

      padding: ${rem(11)} ${rem(24)};
    ` : css `
      background-color: ${palette.neutral.$700};
      border-radius: ${rem(8)};
      color: #6C7487;
      box-shadow: 0 56px 64px 0 #11131780;

      padding: ${rem(20)} ${rem(32)};
      margin-top: -9px;
      margin-left: -8px;
      //margin: 0;

      display: flex;
      flex-direction: column;

      //flex: 1;
      width: 100%;
      max-width: 750px;
      min-width: 600px;

      height: 100%;
      min-height: 300px;
      max-height: calc(100vh - 30px);
    `;
});
const LaunchpadDropdownActions = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        margin: ${rem(16)} 0;

        h3 {
            font-size: ${rem(10)};
            font-weight: 500;
            letter-spacing: 0.03em;
            flex: 1;
            text-transform: uppercase;
        }

        a {
            font-size: ${rem(12)};
            font-weight: 400;
            line-height: 150%;
            margin-left: ${rem(24)};
            color: ${palette.primary.$200};
            span {
                margin-left: ${rem(8)};
            }

            &:hover, &:focus {
                color: ${palette.primary.$300};
            }
        }
    `;
});
const NoResults = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        font-size: ${rem(16)};
        font-weight: 400;
        margin-top: ${rem(30)};
        text-align: center;
    `;
});
const Launchpad = ({ isLoading, hasSearched, searchResults, performSearch, onSelectResultItem: baseOnSelectResultItem, isExpanded: initialIsExpanded, placeholder, backgroundColor }) => {
    const [isExpanded, setIsExpanded] = useState(initialIsExpanded);
    const [activeTab, setActiveTab] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const nbSearchResults = searchResults.length;
    const grouppedSearchResults = groupSearchResultsByType(searchResults);
    let tabs = [
        { key: 'all', title: translate('launchpad.section.all') },
        ...Object.keys(grouppedSearchResults).map((type) => ({
            key: type,
            title: translate(`launchpad.section.${type}s`),
        }))
    ];
    const dropdown = useDropdown("left");
    const searchInputRef = useRef();
    const focusSearchInput = () => {
        searchInputRef.current?.focus();
    };
    const blurSearchInput = () => {
        searchInputRef.current?.blur();
    };
    const keyDownHandler = (e) => {
        if (isExpanded) {
            if (e.key === 'Escape' || e.key === 'Backspace') {
                setIsExpanded((isExpanded) => {
                    if (isExpanded) {
                        focusSearchInput();
                    }
                    return isExpanded;
                });
            }
            if (e.key === 'ArrowLeft') {
                e.preventDefault();
                e.stopPropagation();
                activatePreviousTab();
            }
            if (e.key === 'ArrowRight') {
                e.preventDefault();
                e.stopPropagation();
                activateNextTab();
            }
            if (e.key === "ArrowUp") {
                e.preventDefault();
                e.stopPropagation();
                focusSearchInput();
            }
            if (e.key === "ArrowDown" || e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
                focusFirstResult();
            }
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [isExpanded]);
    const onSelectResultItem = (item) => {
        baseOnSelectResultItem(item);
        setIsExpanded(false);
        setSearchQuery(item ? item.name : '');
    };
    const getTabIndex = (tab) => tabs.findIndex(({ key }) => tab === key);
    const activatePreviousTab = () => {
        setActiveTab((activeTab) => {
            const tabIndex = getTabIndex(activeTab);
            if (tabIndex <= 0) {
                return activeTab;
            }
            return tabs[tabIndex - 1].key;
        });
    };
    const activateNextTab = () => {
        setActiveTab((activeTab) => {
            const tabIndex = getTabIndex(activeTab);
            if (tabIndex === -1 || (tabIndex + 1 >= tabs.length)) {
                return activeTab;
            }
            return tabs[tabIndex + 1].key;
        });
    };
    const focusFirstResult = () => {
        const firstResultItem = document
            .querySelector('.tab-pane.active .LaunchpadSearchResultItem:first-child');
        if (firstResultItem) {
            firstResultItem.focus();
            firstResultItem.scrollTo();
        }
        else {
            document.querySelector("[role='tab'].active")?.focus();
        }
    };
    return (_jsx(OutsideClickHandler, { display: 'block', onOutsideClick: (e) => {
            if (e.target.closest('#launchpadDropdown')) {
                return;
            }
            setIsExpanded(false);
            dropdown.hideDropdown();
        }, children: _jsxs(LaunchpadBase, { ref: dropdown.triggerRef, isExpanded: isExpanded, backgroundColor: backgroundColor, children: [_jsx("div", { children: _jsx(LaunchpadSearchBar, { inputRef: searchInputRef, initialSearchQuery: searchQuery, onSearchQueryChange: performSearch, isExpanded: isExpanded, placeholder: placeholder || translate('launchpad.search'), onReset: () => onSelectResultItem(null), onFocus: () => {
                            setIsExpanded(true);
                            dropdown.showDropdown();
                        }, onDone: () => {
                            focusFirstResult();
                        }, onCancel: () => {
                            setIsExpanded(false);
                            blurSearchInput();
                        } }) }), isExpanded && _jsxs(_Fragment, { children: [_jsx(LaunchpadDropdownActions, { children: _jsxs("h3", { children: [_jsx("span", { style: { marginRight: 8 }, children: hasSearched
                                            ? transChoice('launchpad.nbResultsFound', nbSearchResults)
                                            : translate('launchpad.lastSearchResults') }), _jsx(Spinner, { display: "inline-block", isVisible: isLoading })] }) }), _jsxs(Tab.Container, { id: "launchpad-tabs", activeKey: activeTab, onSelect: (newTab) => setActiveTab(newTab), children: [_jsx("div", { className: classes.launchpadDropdownTabsWrapper, children: _jsx(Nav, { variant: "tabs", onKeyDown: (e) => false, children: tabs.map(({ key, title }) => (_jsx(Nav.Link, { eventKey: key, children: title }, key))) }) }), _jsx("div", { style: { flex: 1, overflowY: "auto", marginRight: -20, paddingRight: 20 }, children: _jsxs(Tab.Content, { style: { paddingTop: 20 }, children: [_jsx(Tab.Pane, { eventKey: "all", children: searchResults.length > 0 ? searchResults.map((item, index) => (_jsx(LaunchpadSearchResultItem, { item: item, onSelectResultItem: onSelectResultItem, onGoBack: () => focusSearchInput() }, index))) : _jsx(NoResults, { children: translate('launchpad.nothingFound') }) }), Object.keys(grouppedSearchResults).map((type) => (_jsx(Tab.Pane, { eventKey: type, children: grouppedSearchResults[type].length > 0 ? grouppedSearchResults[type].map((item, index) => (_jsx(LaunchpadSearchResultItem, { item: item, onSelectResultItem: onSelectResultItem, onGoBack: () => focusSearchInput() }, index))) : _jsx(NoResults, { children: translate('launchpad.nothingFound') }) }, type)))] }) })] })] })] }) }));
};
export default Launchpad;
