import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import { RentalObjectCard } from 'Components/App/card';
import { Button, ButtonToolbar, Icon } from 'Components/Atoms';
import Actions from '../components/AssetDataTableActions';
const model = Table.factory()(() => ({
    tableId: 'Asset',
    Actions: ({ onCreateAsset, onCreateRoom }) => (_jsxs(ButtonToolbar, { children: [_jsxs(Button, { color: 'primary', variant: 'outline', title: translate('asset.room.new'), onClick: () => onCreateRoom(), children: [_jsx(Icon, { icon: 'plus' }), " ", _jsx("span", { style: { verticalAlign: 'sub' }, children: translate('asset.room.new') })] }), _jsxs(Button, { color: 'primary', title: translate('asset.new'), onClick: () => onCreateAsset(), children: [_jsx(Icon, { icon: 'plus' }), " ", _jsx("span", { style: { verticalAlign: 'sub' }, children: translate('asset.new') })] })] })),
    BulkActions: ({ selectedFlatRows, onDeleteAssets, refresh }) => (_jsxs(Button, { "$size": 'md', variant: 'outline', color: 'error', title: translate('btn.deleteSelected'), disabled: selectedFlatRows.length === 0, onClick: () => onDeleteAssets(selectedFlatRows.map(row => row.original.id), refresh), children: [_jsx(Icon, { icon: 'trash' }), " ", _jsx("span", { style: { verticalAlign: 'sub' }, children: translate('btn.deleteSelected') })] })),
    RowActions: Actions,
    useSortBy: {},
    useRowSelect: {},
    useGlobalFilter: {},
    usePagination: {},
    initialState: {
        sortBy: [
            { id: 'id', desc: true }
        ]
    },
    columns: {
        $rowSelect: {},
        $actions: {
            sticky: 'left',
        },
        property: {
            Header: translate('contract.table.column.rentalObject'),
            accessor: ({ building }) => building.name,
            width: 400,
            Cell: ({ row: { original: { building, rentalObject } } }) => {
                return (_jsx(RentalObjectCard, { rentalObjectName: rentalObject.name, rentalObjectId: rentalObject.id, buildingName: building.name }));
            },
            useSortBy: {},
        },
        building: {
            hide: true
        },
        rentalObject: {
            hide: true
        },
        room: {
            Header: translate('asset.room'),
            accessor: ({ room }) => room.name,
            Cell: ({ value, onViewRoom, row: { original: { room, rentalObject } } }) => _jsx("a", { href: '#', onClick: (e) => {
                    e.preventDefault();
                    onViewRoom(room.id, rentalObject.id);
                }, children: value }),
            useSortBy: {},
        },
        asset: {
            Header: translate('asset.name'),
            accessor: ({ name }) => name,
            useSortBy: {},
            Cell: ({ row: { original: asset }, onViewAsset }) => _jsx(_Fragment, { children: _jsx("a", { href: "#", onClick: (e) => { e.preventDefault(); onViewAsset(asset); }, children: asset.name }) })
        },
    },
}));
export default model;
