import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { forwardRef, useMemo } from 'react';
import { Palette, tokenFromProps, typographyTokens, useTheme, } from '@f2w/theme';
import { textContent, cleanObj, isNum, isString } from '@f2w/utils';
import { useLayoutProps } from 'Atoms/Layout/hooks/useLayoutProps';
export const TextRoot = styled('span')(useTextStyles);
const TextRef = forwardRef((props, ref) => _jsx(TextRoot, { ...useTextProps(props), ref: ref }));
const components = {
    Block: (props) => _jsx(TextRef, { as: 'p', ...props }),
};
export const Text = Object.assign(TextRef, components);
const DefaultComponents = {
    h1: { as: 'h1' },
    h2: { as: 'h2' },
    h3: { as: 'h3' },
    h4: { as: 'h4' },
    h5: { as: 'h5' },
    h6: { as: 'h6' },
};
export function useTextStyles({ size: _size, colorName: _colorName, weight: _weight, align: textAlign, ellipsis, center, nowrap, }) {
    const { typography, palette } = useTheme();
    const styles = useMemo(() => {
        const color = _colorName ? palette.color(_colorName.split('.')) : undefined;
        const font = typography.variants[_size];
        const fontSizeStyles = font?.fontStyles;
        if (isNum(_weight)) {
            _weight = `$${_weight}`;
        }
        return {
            ...fontSizeStyles,
            fontWeight: typography.fontWeight[_weight] || fontSizeStyles?.fontWeight,
            color,
            ...((ellipsis || nowrap) && { whiteSpace: 'nowrap', overflow: 'hidden' }),
            ...(ellipsis && { textOverflow: 'ellipsis', display: 'block' }),
        };
    }, [_size, _colorName, _weight]);
    const alignStyles = {};
    if (center) {
        textAlign = alignStyles.alignItems = alignStyles.justifyContent = 'center';
    }
    return {
        ...styles,
        textAlign,
    };
}
export function useTextProps({ size, colorName, weight, value, transform, children, ...initialProps }) {
    const paletteToken = tokenFromProps(Palette.tokenMap, initialProps);
    const [props, tokenKeys] = useMemo(() => {
        const props = cleanObj({
            size,
            colorName,
            weight,
        });
        const tokenKeys = [];
        if (paletteToken) {
            tokenKeys.push(paletteToken);
            props.colorName = paletteToken;
            if (initialProps[paletteToken] > 1) {
                props.colorName = `${paletteToken}.$${initialProps[paletteToken]}`;
            }
        }
        const typographyToken = tokenFromProps(typographyTokens, initialProps);
        if (typographyToken) {
            tokenKeys.push(typographyToken);
            props.size = size ?? typographyToken;
            if (!initialProps.as && DefaultComponents[typographyToken]) {
                props.as = typographyToken;
            }
            if (initialProps[typographyToken] > 1) {
                props.weight = initialProps[typographyToken];
            }
        }
        else if (!props.size && isString(initialProps.as) && typographyTokens[initialProps.as]) {
            props.size = initialProps.as;
        }
        return [props, tokenKeys];
    }, [initialProps.as, size, colorName, weight, initialProps[paletteToken]]);
    tokenKeys.forEach(propName => {
        initialProps[propName] = null;
        delete initialProps[propName];
    });
    const initialProps2 = useLayoutProps(initialProps);
    return {
        title: initialProps.ellipsis ? (value ?? textContent(children)) : undefined,
        ...initialProps2,
        style: { ...initialProps2.style, textTransform: transform },
        ...props,
        children: children ?? value,
    };
}
