import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Transition from 'react-transition-group/Transition';
import React from 'react';
import { transitionEndListener } from 'Components/Transition';
import { map } from 'Components/Base/ElementChildren';
import * as Theme from './components/Carousel.theme';
import { carouselSlotAttr } from './components/Carousel.theme';
import { CarouselItem } from './components/CarouselItem';
import { useCarousel } from './useCarousel';
import { Text } from 'Atoms/Text';
import { Box } from 'Atoms/Layout';
const SWIPE_THRESHOLD = 40;
const defaultProps = {
    defaultActiveIndex: 0,
    pause: 'hover',
    prevIcon: 'chevron-left',
    nextIcon: 'chevron-right',
    prevLabel: 'Previous',
    nextLabel: 'Next',
};
export const CarouselRoot = React.forwardRef(function CarouselFunc({ as: Component = 'div', prevIcon, prevLabel, nextIcon, nextLabel, className, controls = true, indicators = true, renderActions, ...uncontrolledProps }, ref) {
    const manager = useCarousel(uncontrolledProps, ref);
    const { direction, slideDirection, renderedActiveIndex, handleEnter, handleEntered, numChildren, fade, activeIndex, loop, children, rootProps } = manager;
    const prefix = 'fw-carousel';
    const showToolbars = numChildren > 1;
    return (_jsxs(Component, { ...carouselSlotAttr('root'), ...rootProps, className: classNames(className, {
            [`fade`]: fade,
            [`${prefix}-fade`]: fade
        }), children: [renderActions && (_jsx("div", { ...carouselSlotAttr('actions'), children: renderActions(activeIndex) })), (showToolbars && indicators) && (_jsx("div", { ...carouselSlotAttr('indicator-group'), children: _jsxs(Box, { as: Text, alignSelf: 'stretch', alignContent: 'center', bg: 'white', py: 5, px: 10, children: [activeIndex + 1, " / ", numChildren] }) })), (showToolbars && controls) && (_jsxs("div", { ...carouselSlotAttr('controls'), children: [(loop || activeIndex !== 0) && (_jsx(Theme.PrevTrigger, { icon: prevIcon, label: prevLabel, onClick: manager.prev })), (loop || activeIndex !== numChildren - 1) && (_jsx(Theme.NextTrigger, { icon: nextIcon, label: nextLabel, onClick: manager.next }))] })), _jsx("div", { ...carouselSlotAttr('inner'), children: map(children, (child, index) => {
                    const isActive = index === renderedActiveIndex;
                    const commonProps = {
                        ...carouselSlotAttr('item'),
                    };
                    if (!fade) {
                        return (_jsx(Transition, { in: isActive, onEnter: isActive ? handleEnter : undefined, onEntered: isActive ? handleEntered : undefined, addEndListener: transitionEndListener, children: (status) => {
                                const active = status === 'entered' || status === 'exiting';
                                return React.cloneElement(child, {
                                    ...commonProps,
                                    'aria-current': active,
                                    className: classNames(child.props.className, {
                                        [`fw-${direction}`]: isActive && status !== 'entered',
                                        [`fw-${slideDirection}`]: status === 'entering' || status === 'exiting',
                                        'active': active,
                                    }),
                                });
                            } }));
                    }
                    return React.cloneElement(child, {
                        ...commonProps,
                        'aria-current': isActive,
                        className: classNames(child.props.className, {
                            'active': isActive,
                        }),
                    });
                }) })] }));
});
CarouselRoot.displayName = 'Carousel';
CarouselRoot.defaultProps = defaultProps;
const blocks = {
    Root: CarouselRoot,
    Item: CarouselItem,
    ...Theme
};
for (let [key, val] of Object.entries(blocks)) {
    CarouselRoot[key] = val;
}
export const Carousel = CarouselRoot;
