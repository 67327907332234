import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './ProgressBar.styles.scss';
import { toPx } from '@fw/theme';
export function ProgressBar({ value, maxValue, size = 40, borderSize = 5 }) {
    const progress = (100 * value) / maxValue;
    return (_jsxs("svg", { width: size, height: size, viewBox: `0 0 ${size} ${size}`, className: "circular-progress", style: {
            '--progress': progress,
            '--size': toPx(size),
            '--stroke-width': toPx(borderSize),
        }, children: [_jsx("circle", { className: "bg" }), _jsx("circle", { className: "fg" })] }));
}
