import React from 'react';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
export const useOpenRentalObject = ({ rentalObjectId, handoverProtocolId }) => {
    const { openViewModalObj } = useObjectViewModalContext();
    React.useEffect(() => {
        if (handoverProtocolId !== null) {
            openViewModalObj({
                id: rentalObjectId,
                view: ActiveView.RENTAL_OBJECT,
                handoverId: handoverProtocolId,
            });
        }
    }, [handoverProtocolId]);
};
