import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { BaseTheme } from '../base';
export function ObjectField({ field, Label: labelProps, Error: errorProps, children, ...props }) {
    const commonProps = { field };
    return (_jsxs(ObjectField.Row, { id: `${field.id}--row`, ...commonProps, ...props, children: [_jsx(ObjectField.Label, { ...commonProps, ...labelProps }), children, _jsx(ObjectField.Error, { ...commonProps, ...errorProps })] }));
}
(function (ObjectField) {
    ObjectField.Label = styled(BaseTheme.Label) `
        ${p => p.theme.typography.h6.fontSizeStyles};
        margin: 1em 0 .75em;
    `;
    ObjectField.Error = styled(BaseTheme.Error) ``;
    ObjectField.Row = styled(BaseTheme.Row) ``;
})(ObjectField || (ObjectField = {}));
