import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { useCallback, useEffect, useState } from "react";
import { Button, FlexCol, FlexRow, Icon, OverlayTrigger, SpinnerNew, Tooltip } from "Components/Atoms";
import { Title } from "@f2w/data-table/layout/PageOverviewStyles";
import { useToastDispatcherApi } from "Components/Dispatcher";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import { QuickschildPropertiesTable } from "Pages/Quickschild/table/QuickschildPropertiesTable";
const QuickschildIntegrationPage = ({ api }) => {
    const [activationInProgress, setActivationInProgress] = useState(false);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [activationButtonEnabled, setActivationButtonEnabled] = useState(false);
    const [integration, setIntegration] = useState(null);
    const { success, error } = useToastDispatcherApi();
    const onActivate = useCallback(async () => {
        setActivationInProgress(true);
        try {
            await api.activateIntegration(selectedProperties);
            setIntegration({
                ...integration,
                active: true,
            });
            success({ message: translate('quickschild.integrationView.message.activated') });
        }
        catch (message) {
            error({ message: translate('quickschild.integrationView.message.activationFailed') });
        }
        finally {
            setActivationInProgress(false);
        }
    }, [selectedProperties]);
    const propertiesTable = QuickschildPropertiesTable.use();
    useEffect(() => {
        const flat = Object.keys(propertiesTable.state.selectedRowIds)
            .filter(key => propertiesTable.state.selectedRowIds[key])
            .map(key => parseInt(key));
        setSelectedProperties(flat);
    }, [propertiesTable.state.selectedRowIds]);
    useEffect(() => {
        if (integration === null) {
            return;
        }
        setActivationButtonEnabled(integration.available && selectedProperties.length > 0);
    }, [selectedProperties, integration]);
    useEffect(() => {
        api.getIntegration()
            .then((data) => {
            if (data.active) {
                data.properties.map((propertyId) => propertiesTable.toggleRowSelected(propertyId.toString(), true));
            }
            else {
                propertiesTable.selectAllRows();
            }
            setIntegration(data);
        });
    }, []);
    const SpinnerWrapper = ({ children }) => {
        if (integration === null) {
            return _jsx(SpinnerNew, {});
        }
        return children;
    };
    const ActivateButtonWrapper = ({ children }) => {
        if (!activationButtonEnabled) {
            return (_jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: 'quickschild-button-tooltip', children: translate('quickschild.integrationView.activationNotAvailableTooltip') }), children: children }));
        }
        return children;
    };
    return (_jsx(SpinnerWrapper, { children: integration !== null && _jsxs("div", { children: [_jsxs(FlexRow, { justifyContent: "space-between", alignItems: "flex-start", minh: 50, mb: 5, gap: 24, children: [_jsxs(FlexCol, { gap: 24, children: [_jsx(Title, { children: translate('quickschild.integrationView.title') }), _jsxs("div", { children: [_jsx("p", { children: translate('quickschild.integrationView.description.aboutQuickschild') }), _jsx("p", { children: translate('quickschild.integrationView.description.integrationBenefits') }), integration.active && _jsx("p", { children: translate('quickschild.integrationView.description.alreadyActivated') }), !integration.active && _jsx("p", { dangerouslySetInnerHTML: { __html: translate('quickschild.integrationView.description.terms') } })] })] }), _jsxs(FlexRow, { gap: 16, children: [_jsx(FlexCol, { style: { justifyContent: 'center' }, children: _jsxs("a", { style: { whiteSpace: 'nowrap' }, target: '_blank', href: "https://help.fairwalter.com/quickschild-namensschilder", children: [_jsx(Icon, { icon: 'help-circle' }), " Help Center"] }) }), _jsx(FlexCol, { children: _jsx(ActivateButtonWrapper, { children: _jsxs(Button, { onClick: onActivate, "$size": 'lg', color: 'primary', disabled: !activationButtonEnabled, loading: activationInProgress, children: [" ", translate(integration.active ? 'quickschild.integrationView.save' : 'quickschild.integrationView.activate')] }) }) })] })] }), _jsx(FlexRow, { children: _jsx(PageOverview, { instance: propertiesTable, rootStyles: { width: '100%' } }) })] }) }));
};
export default QuickschildIntegrationPage;
