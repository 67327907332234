import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CalendarIcon, ContractIcon, CreditCardIcon, InvoiceIcon, MailIcon, PhoneIcon, UserIcon, } from "Components/App/Icons";
import { formatMoney } from "Services/NumberHelper";
import { useTheme } from "@f2w/theme";
import styled, { css } from "styled-components";
import moment from "moment";
import { translate } from "Services/Translator";
import { ContractStatus } from "Types/ContractStatus";
import { InvoiceStatus } from "Types/InvoiceStatus";
const LaunchpadSearchResultItemBaseWrapper = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        align-items: baseline;
        padding: ${rem(16)} ${rem(16)};
        cursor: pointer;
        border-radius: ${rem(8)};
        &:hover, &:focus {
            outline: none;
            background-color: #20252E;
        }
    `;
});
const LaunchpadSearchResultItemBaseIcon = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        color: #fff;
        margin-right: ${rem(24)};
    `;
});
const LaunchpadSearchResultItemBaseContent = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        flex: 1;
        overflow: hidden;
        color: ${palette.neutral.$300};

        svg {
            margin-right: ${rem(10)};
            margin-top: -2px;
        }
    `;
});
const LaunchpadSearchResultItemBaseHead = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        font-size: ${rem(16)};
        letter-spacing: 0.02em;
        line-height: 150%;
        display: flex;
        gap: ${rem(8)};

        ${LaunchpadSearchResultItemPrimary} {
            font-weight: 500;
            color: ${palette.neutral.$50};
        }

        ${LaunchpadSearchResultItemSecondary} {
            justify-content: space-between;
        }

        & > * {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    `;
});
const LaunchpadSearchResultItemPrimary = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        width: 30%;
    `;
});
const LaunchpadSearchResultItemSecondary = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        width: 70%;
        display: flex;
        gap: ${rem(12)};

        & > * {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    `;
});
const LaunchpadSearchResultItemId = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        text-align: right;
        width: ${rem(90)};
        overflow: revert;
    `;
});
const LaunchpadSearchResultItemBaseDetails = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        display: flex;
        align-items: baseline;
        font-size: ${rem(14)};
        font-weight: 400;
        line-height: 150%;
        margin-top: ${rem(4)};
        gap: ${rem(8)};

        & > * {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    `;
});
const StatusBadge = styled.div(({ color }) => {
    const { rem } = useTheme();
    return css `
        display: inline-block;
        padding: ${rem(4)} ${rem(8)};
        border: 1px solid;
        color: ${color};
        border-color: ${color};
        font-size: ${rem(12)};
        font-weight: 400;
        line-height: 150%;
        border-radius: ${rem(24)};
    `;
});
const getContractStatusColor = (status) => {
    const { palette } = useTheme();
    const colors = {
        [ContractStatus.Active]: palette.success.$300,
        [ContractStatus.Upcoming]: palette.warning.$300,
        [ContractStatus.Terminated]: palette.neutral.$300,
        [ContractStatus.Noticed]: palette.neutral.$300
    };
    return colors[status] ?? palette.success.$50;
};
const ContractStatusBadge = ({ status }) => {
    const color = getContractStatusColor(status);
    return (_jsx(StatusBadge, { color: color, children: translate(`contract.state.${status}`) }));
};
const LaunchpadSearchResultItemPerson = ({ item }) => {
    const { rem } = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(LaunchpadSearchResultItemBaseIcon, { children: _jsx(UserIcon, { size: 21 }) }), _jsxs(LaunchpadSearchResultItemBaseContent, { children: [_jsxs(LaunchpadSearchResultItemBaseHead, { children: [_jsx(LaunchpadSearchResultItemPrimary, { children: item.name }), _jsxs(LaunchpadSearchResultItemSecondary, { children: [_jsx("div", { children: [item.details.street, item.details.city, item.details.postalCode].filter(Boolean).join(', ') || translate('notAvailable') }), _jsxs(LaunchpadSearchResultItemId, { children: ["#", item.id] })] })] }), _jsxs(LaunchpadSearchResultItemBaseDetails, { children: [_jsx(LaunchpadSearchResultItemPrimary, { children: _jsxs("div", { children: [_jsx(PhoneIcon, { size: 16 }), _jsx("span", { children: item.details.phoneNumber || translate('notAvailable') })] }) }), _jsx(LaunchpadSearchResultItemSecondary, { children: _jsxs("div", { children: [_jsx(MailIcon, { size: 16 }), _jsx("span", { children: item.details.emailAddress || translate('notAvailable') })] }) })] })] })] }));
};
const LaunchpadSearchResultItemContract = ({ item }) => {
    const { rem } = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(LaunchpadSearchResultItemBaseIcon, { children: _jsx(ContractIcon, { size: 21 }) }), _jsxs(LaunchpadSearchResultItemBaseContent, { children: [_jsxs(LaunchpadSearchResultItemBaseHead, { children: [_jsx(LaunchpadSearchResultItemPrimary, { children: item.details['personName'] }), _jsxs(LaunchpadSearchResultItemSecondary, { children: [_jsxs("div", { children: [item.details.rentalObjectName, " \u2219 ", item.details.addressLine1, ", ", item.details.city, ", ", item.details.zip] }), _jsxs(LaunchpadSearchResultItemId, { children: ["#", item.id] })] })] }), _jsxs(LaunchpadSearchResultItemBaseDetails, { children: [_jsx(LaunchpadSearchResultItemPrimary, { children: _jsx(ContractStatusBadge, { status: item.details.status }) }), _jsx(LaunchpadSearchResultItemSecondary, { children: _jsxs("div", { children: [_jsx(CalendarIcon, { size: 16 }), _jsxs("span", { children: [moment(item.details.starts).format('L'), " \u2013 ", item.details.ends ? moment(item.details.ends).format('L') : '∞'] })] }) })] })] })] }));
};
const getInvoiceStatusColor = (status) => {
    const { palette } = useTheme();
    const colors = {
        [InvoiceStatus.Draft]: palette.neutral.$300,
        [InvoiceStatus.Sent]: palette.primary.$300,
        [InvoiceStatus.PartiallyPaid]: palette.warning.$300,
        [InvoiceStatus.Paid]: palette.success.$300,
        [InvoiceStatus.Overpaid]: palette.error.$300,
    };
    return colors[status] ?? palette.success.$50;
};
const InvoiceStatusBadge = ({ status }) => {
    const color = getInvoiceStatusColor(status);
    return (_jsx(StatusBadge, { color: color, children: translate(`invoice.state.${status}`) }));
};
const LaunchpadSearchResultItemDebitorInvoice = ({ item }) => {
    const { rem } = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(LaunchpadSearchResultItemBaseIcon, { children: _jsx(InvoiceIcon, { size: 21 }) }), _jsxs(LaunchpadSearchResultItemBaseContent, { children: [_jsxs(LaunchpadSearchResultItemBaseHead, { children: [_jsx(LaunchpadSearchResultItemPrimary, { children: item.details.personName || translate('notAvailable') }), _jsxs(LaunchpadSearchResultItemSecondary, { children: [_jsxs("div", { children: [item.details.rentalObjectName, " \u2219 ", item.details.addressLine1, ", ", item.details.city, ", ", item.details.zip] }), _jsxs(LaunchpadSearchResultItemId, { children: ["#", item.id] })] })] }), _jsxs(LaunchpadSearchResultItemBaseDetails, { children: [_jsx(LaunchpadSearchResultItemPrimary, { children: _jsx(InvoiceStatusBadge, { status: item.details.status }) }), _jsxs(LaunchpadSearchResultItemSecondary, { children: [_jsxs("div", { style: { width: rem(110) }, children: [_jsx(CalendarIcon, { size: 16 }), _jsx("span", { children: moment(item.details.createdAt).format('L') })] }), _jsx("div", { children: formatMoney(item.details.amount) })] })] })] })] }));
};
const LaunchpadSearchResultItemCreditorInvoice = ({ item }) => {
    const { rem } = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(LaunchpadSearchResultItemBaseIcon, { children: _jsx(CreditCardIcon, { size: 21 }) }), _jsxs(LaunchpadSearchResultItemBaseContent, { children: [_jsxs(LaunchpadSearchResultItemBaseHead, { children: [_jsx(LaunchpadSearchResultItemPrimary, { children: item.name }), _jsxs(LaunchpadSearchResultItemSecondary, { children: [_jsxs("div", { children: [item.details.propertyName, " \u2219 ", item.details.city, ", ", item.details.zip] }), _jsxs(LaunchpadSearchResultItemId, { children: ["#", item.id] })] })] }), _jsxs(LaunchpadSearchResultItemBaseDetails, { children: [_jsx(LaunchpadSearchResultItemPrimary, { children: _jsx("div", { children: _jsx(InvoiceStatusBadge, { status: item.details.status }) }) }), _jsxs(LaunchpadSearchResultItemSecondary, { children: [_jsxs("div", { style: { width: rem(110) }, children: [_jsx(CalendarIcon, { size: 16 }), _jsx("span", { children: item.details.createdAt ? moment(item.details.createdAt).format('L') : translate('notAvailable') })] }), _jsx("div", { style: { overflow: "revert" }, children: formatMoney(item.details.amount) }), _jsx("div", { children: item.details.creditorName })] })] })] })] }));
};
const typeItems = {
    'person': LaunchpadSearchResultItemPerson,
    'contract': LaunchpadSearchResultItemContract,
    'debitorInvoice': LaunchpadSearchResultItemDebitorInvoice,
    'creditorInvoice': LaunchpadSearchResultItemCreditorInvoice,
};
const getNextSibling = (target) => {
    if (!target) {
        return null;
    }
    if (!target.nextSibling) {
        return null;
    }
    if (!(target.nextSibling instanceof HTMLElement)) {
        return null;
    }
    return target.nextSibling;
};
const getPreviousSibling = (target) => {
    if (!target) {
        return null;
    }
    if (!target.previousSibling) {
        return null;
    }
    if (!(target.previousSibling instanceof HTMLElement)) {
        return null;
    }
    return target.previousSibling;
};
const LaunchpadSearchResultItem = ({ item, onSelectResultItem, onGoBack }) => {
    const LaunchpadSearchResultItemDetail = typeItems[item.type];
    return (_jsx(LaunchpadSearchResultItemBaseWrapper, { className: "LaunchpadSearchResultItem", tabIndex: 0, onClick: () => onSelectResultItem(item), onKeyDown: (e) => {
            let newFocus;
            if (e.key === "ArrowUp") {
                e.preventDefault();
                e.stopPropagation();
                newFocus = getPreviousSibling(e.target);
                if (!newFocus) {
                    onGoBack();
                }
            }
            else if (e.key === "ArrowDown") {
                e.preventDefault();
                e.stopPropagation();
                newFocus = getNextSibling(e.target);
            }
            else if (e.key === "Enter") {
                e.stopPropagation();
                onSelectResultItem(item);
            }
            if (newFocus) {
                newFocus.focus();
            }
        }, children: _jsx(LaunchpadSearchResultItemDetail, { item: item }) }));
};
export default LaunchpadSearchResultItem;
