import { FormField } from './FormField';
export class ArrayField extends FormField {
    isCompound = true;
    manager;
    constructor(type, props) {
        super(type, props);
    }
    setError(errors) {
        this.forChildren(async (child) => {
            if (errors?.[child.key]) {
                child.setError(errors[child.key]);
            }
        });
    }
    async _validate(value) {
        const errors = [];
        const children = Object.values(this._type.children);
        for (const child of children) {
            const error = await child.field._validate(value);
            errors.push(error);
        }
        return errors;
    }
    __dump() {
        return this.forChildren(child => child.__dump());
    }
    forChildren(cb) {
        const value = {};
        const children = this._type.children;
        for (const key in children) {
            value[key] = cb(children[key].field, key);
        }
        return Object.values(value);
    }
}
