import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ToastDispatcher } from './ToastDispatcher';
import { ModalDispatcher } from './ModalDispatcher';
import { UploadDispatcher } from './UploadManager';
import { AppContext } from '../Base/AppContext';
export const DispatcherProvider = ({ children, }) => {
    const toastApi = React.useRef();
    const modalApi = React.useRef();
    const uploadManager = React.useRef();
    return (_jsxs(AppContext.Provider, { value: {
            toastApi,
            modalApi,
            uploadManager
        }, children: [_jsx(ToastDispatcher, { innerRef: toastApi }), _jsx(ModalDispatcher, { ref: modalApi }), _jsx(UploadDispatcher, { ref: uploadManager }), children] }));
};
