import { isObjectLike } from '@f2w/utils';
export var UseOption;
(function (UseOption) {
    UseOption.isOptionGroup = (v) => v['options'];
    function findOptions(value, options) {
        const selectedOptions = [];
        if (value != null) {
            const selectedValues = getValues(value);
            mapOptions(options, (opt) => {
                if (selectedValues.includes(opt.value)) {
                    selectedOptions.push(opt);
                }
            });
        }
        return selectedOptions;
    }
    UseOption.findOptions = findOptions;
    function mapOptions(options, cb) {
        for (let opt of options) {
            if (UseOption.isOptionGroup(opt)) {
                mapOptions(opt.options, cb);
                continue;
            }
            if (cb(opt))
                return true;
        }
    }
    UseOption.mapOptions = mapOptions;
    function getValue(value, cb) {
        if (isObjectLike(value)) {
            if (UseOption.isOptionGroup(value))
                value = value.options;
            if (Array.isArray(value))
                return value.flatMap(opt => {
                    const val = getValue(opt, cb);
                    return val != null ? val : [];
                });
            if ('value' in value)
                value = value.value;
            else
                return;
        }
        cb?.(value);
        return value;
    }
    UseOption.getValue = getValue;
    function getValues(value) {
        const selectedValues = [];
        getValue(value, val => {
            if (!selectedValues.includes(val)) {
                selectedValues.push(val);
            }
        });
        return selectedValues;
    }
    UseOption.getValues = getValues;
    function getValueMap(values) {
        const selectedValues = {};
        getValue(values, _id => {
            if (_id != null)
                selectedValues[_id] = true;
        });
        return selectedValues;
    }
    UseOption.getValueMap = getValueMap;
})(UseOption || (UseOption = {}));
