import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
const StepperWrapper = styled.div `
    display: flex;
`;
const StepperButtonLine = styled('div') `
  width: ${p => p.theme.rem(37)};
  height: 50%;
  border-bottom: 1px solid ${p => p.isVisitedStep ? p.theme.palette.primary.$500 : p.theme.palette.dark.$100};
`;
const StepperButtonBase = styled('button') `
    border-radius: 50%;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    width: 2.5em;
    height:  2.5em;
    color: ${p => p.theme.palette.white};
    border: none;
    background: ${p => p.isVisitedStep ? p.theme.palette.primary.$500 : p.theme.palette.dark.$100};
`;
const StepperButton = ({ hasPrevStep, hasNextStep, ...props }) => (_jsxs(StepperWrapper, { children: [hasPrevStep && _jsx(StepperButtonLine, { isVisitedStep: props.isVisitedStep }), _jsx(StepperButtonBase, { ...props }), hasNextStep && _jsx(StepperButtonLine, { isVisitedStep: props.isVisitedStep })] }));
const Root = styled('div')(({ theme: { rem } }) => css `
    margin-bottom: ${rem(72)};
    display: flex;
    justify-content: center;
`);
export const FlowTemplate = ({ className, nbSteps, currentStep, lastFinishedStep, onSetStep }) => {
    const stepNumbers = [...Array(nbSteps).keys()];
    return (_jsx(Root, { className: className, children: stepNumbers.map((index) => {
            const targetStep = index + 1;
            const isCurrentStep = targetStep === currentStep;
            const hasPrevStep = targetStep > 1;
            const hasNextStep = targetStep < nbSteps;
            const isVisitedStep = targetStep <= currentStep;
            return (_jsx(StepperButton, { isCurrentStep: isCurrentStep, hasPrevStep: hasPrevStep, hasNextStep: hasNextStep, isVisitedStep: isVisitedStep, onClick: (() => onSetStep(targetStep)), disabled: !isCurrentStep && targetStep > lastFinishedStep, children: targetStep }, index));
        }) }));
};
