import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ModalNew } from 'Components/Modal';
import { Spinner } from 'Components/Atoms';
import { useFormModal } from './hooks';
import { FormProvider } from './FormContext';
import { FormModalTemplate } from './components/FormTemplate';
export function FormModal({ modal: _modal, config, apiRef, size = 'form', onClose, noPadding }) {
    const modal = config ? useFormModal(config) : _modal;
    if (apiRef)
        apiRef.current = modal;
    return _jsx(_Fragment, { children: _jsx(ModalNew, { enforceFocus: false, autoFocus: false, show: modal.show || modal.loading, onHide: () => {
                onClose?.();
                modal.closeForm();
            }, size: size, children: modal.loading || !modal.activeData
                ? _jsx(ModalNew.Body, { paddingY: 80, children: _jsx(Spinner, { align: 'center' }) })
                : _jsx(FormModalProvider, { noPadding: noPadding, modal: modal }) }) });
}
const FormModalProvider = ({ modal, noPadding }) => {
    const form = modal.useForm();
    return _jsx(FormProvider, { form: form, children: _jsx(FormModalTemplate, { noPadding: noPadding, modal: modal, form: form }) });
};
