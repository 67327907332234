import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTabView } from "@f2w/view";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { SafeAnchor } from "Atoms/SafeAnchor";
import { translateAdmin } from "Services/Translator";
import { UserIcon } from "Components/App/Icons";
import { UseStyled } from "@fw/theme";
import { css } from "styled-components";
import { Text } from "Atoms/Text";
import { getStatusBadge } from "Pages/Administration/Customers/helpers/badges";
import { Button, ButtonToolbar } from "Components/Atoms";
import { Body, Container } from "Atoms/Layout/components";
import { useActiveViewContext } from "Pages/Administration/context/ActiveViewContext";
import { ActiveView } from "Pages/Administration/enums";
import { getGlobalContext } from "Services/GlobalContext";
import { UserType } from "Types/User";
import { CustomerServices } from "Pages/Administration/Customers/services";
import { formatBytes } from "@f2w/utils";
const IconWrapper = UseStyled.styled('div', ({ theme: { palette } }) => (css `
        border-radius: 50%;
        background: ${palette.primary.$50};
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${palette.primary.$500};
    `));
const VerticalLine = UseStyled.styled('div', ({ theme: { palette } }) => ({
    borderLeft: `1px solid ${palette.neutral.$100}`,
    height: '100%'
}));
const HeaderTemplate = (props) => {
    const { view, } = props;
    const data = view.data;
    const { openEdit } = view.options.actions;
    const { setActiveView } = useActiveViewContext();
    const { user } = getGlobalContext();
    return (_jsxs(FlexCol, { gap: 40, children: [_jsxs(Div, { children: [_jsx(SafeAnchor, { onClick: () => {
                            setActiveView(ActiveView.CUSTOMERS);
                        }, children: translateAdmin('customers') }), " / ", data.name] }), _jsxs(FlexRow, { justifySpaceBetween: true, children: [_jsxs(FlexRow, { gap: 16, alignCenter: true, children: [_jsx(IconWrapper, { children: _jsx(UserIcon, {}) }), _jsx(Text, { body: 500, style: { fontWeight: 'bold' }, children: data.name }), _jsx(Div, { children: getStatusBadge(data.status) })] }), user.userType === UserType.FAIRWALTER && (_jsxs(ButtonToolbar, { children: [_jsx(Button, { variant: 'outline', onClick: () => {
                                    return CustomerServices.toggleCustomerStatus({ ids: [data.id], disable: !data.disabledAt })
                                        .then((response) => {
                                        view.updateData({
                                            disabledAt: response[data.id].disabledAt,
                                            status: response[data.id].status
                                        });
                                    });
                                }, children: data.disabledAt
                                    ? translateAdmin('btn.enable')
                                    : translateAdmin('btn.disable') }), _jsx(Button, { icon: 'edit', onClick: openEdit, children: translateAdmin('btn.edit') })] }))] }), _jsxs(FlexRow, { justifySpaceAround: true, children: [_jsxs(FlexCol, { p: 16, alignCenter: true, gap: 5, children: [_jsxs(Text, { h6: 500, neutral: 500, children: [data.limits.currentObjects, "/", data.limits.maxObjects] }), _jsx(Text, { medium: 400, neutral: 200, children: translateAdmin('customer.objects') })] }), _jsx(FlexCol, { pl: 10, pr: 10, children: _jsx(VerticalLine, {}) }), _jsxs(FlexCol, { p: 16, alignCenter: true, gap: 5, children: [_jsxs(Text, { h6: 500, neutral: 500, children: [data.limits.currentUsers, "/", data.limits.maxUsers] }), _jsx(Text, { medium: 400, neutral: 200, children: translateAdmin('customer.users') })] }), _jsx(FlexCol, { pl: 10, pr: 10, children: _jsx(VerticalLine, {}) }), _jsxs(FlexCol, { p: 16, alignCenter: true, gap: 5, children: [_jsxs(Text, { h6: 500, neutral: 500, children: [formatBytes(data.limits.currentSpace), "/", formatBytes(data.limits.maxSpace)] }), _jsx(Text, { medium: 400, neutral: 200, children: translateAdmin('customer.storage') })] })] })] }));
};
export const CustomerTemplate = (props) => {
    const [renderLeft] = useTabView({
        ...props,
        groups: ['contactData'],
        Header: HeaderTemplate,
        stateKey: 'leftActiveTab'
    });
    const [renderRight] = useTabView({
        ...props,
        groups: ['subscriptions', 'integrations'],
        stateKey: 'rightActiveTab'
    });
    return (_jsxs(Body, { split: true, style: { height: '100%' }, children: [_jsx(Container, { style: { flexBasis: '60%' }, children: renderLeft() }), _jsx(Container, { style: { flexBasis: '40%' }, children: renderRight() })] }));
};
