import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
export const RangePicker = ({ onChange, ...props }) => {
    return (_jsx(RangePickerTheme.Root, { type: 'range', ...props, onChange: (e) => onChange?.(e.target.value, e) }));
};
export var RangePickerTheme;
(function (RangePickerTheme) {
    RangePickerTheme.Root = styled.input(({ theme: { rem, palette }, $size = "lg" }) => {
        const $color = "primary";
        const vars = {
            height: '15px',
        };
        return css `
        `;
    });
})(RangePickerTheme || (RangePickerTheme = {}));
