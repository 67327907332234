import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { ModalNew } from 'Components/Modal';
import { CreditorUploadDataTable } from 'Pages/Creditor/table/CreditorUploadDataTable';
import { translate } from 'Services/Translator';
import { DataTable } from '@f2w/data-table';
import { Button } from 'Atoms/Button';
import { ButtonToolbar } from 'Components/Atoms';
import { useToastDispatcherApi } from "Components/Dispatcher";
export const CreditorUploadModal = ({ onClose, createCreditorsUsingUploads }) => {
    const [submitting, setSubmitting] = useState(false);
    const { success, error } = useToastDispatcherApi();
    const instance = CreditorUploadDataTable.use({
        onFilesRejected: () => error({
            message: translate('creditor.upload.invalidFilesProvided')
        })
    });
    const onSave = useCallback(() => {
        setSubmitting(true);
        createCreditorsUsingUploads(instance.data)
            .then((values) => {
            const successIndexes = [];
            const failedIndexes = [];
            values.forEach((value, index) => {
                if (value.status === 'rejected') {
                    failedIndexes.push(index);
                }
                if (value.status === 'fulfilled') {
                    successIndexes.push(index);
                }
            });
            success({
                message: translate('creditor.upload.success', {
                    numberOfInvoices: successIndexes.length
                })
            });
            if (failedIndexes.length > 0) {
                error({
                    message: translate('creditor.upload.error')
                });
                failedIndexes.forEach((value, index) => {
                    error({
                        message: instance.data[index].fileName,
                    });
                });
            }
            setSubmitting(false);
            onClose();
        })
            .catch(() => {
            error({
                message: translate('creditor.upload.error')
            });
            setSubmitting(false);
        });
    }, [instance.data]);
    return (_jsxs(ModalNew, { show: true, onHide: !submitting && onClose, scrollable: true, size: { minHeight: '50%', maxWidth: '1200px' }, children: [_jsx(ModalNew.Header, { onHide: !submitting && onClose, closeButton: true, children: _jsx(ModalNew.Title, { children: translate('creditor.upload.title') }) }), _jsx(ModalNew.Body, { children: _jsx(DataTable.SimpleTable, { instance: instance, showEmpty: true }) }), _jsx(ModalNew.Footer, { children: _jsxs(ButtonToolbar, { children: [_jsx(Button, { disabled: submitting, color: 'secondary', variant: 'outline', onClick: onClose, children: translate('btn.cancel') }), _jsx(Button, { disabled: submitting, onClick: onSave, children: translate('btn.save') })] }) })] }));
};
