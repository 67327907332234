import { generate } from "Services/Router";
import client from "Utils/client";
import axios from "axios";
export const getUploads = (request) => {
    const route = generate('api.bankingReconciliation.uploads.list');
    return client
        .get(route, { params: request })
        .then(res => res.data);
};
export const getAllUploads = async () => {
    const route = generate('api.bankingReconciliation.uploads.options');
    const { data } = await client.get(route);
    return data;
};
export const upload = async (files) => {
    const route = generate('api.bankingReconciliation.uploads.upload');
    const form = new FormData();
    for (const file of files) {
        form.append('files[]', file, file.name);
    }
    return axios
        .post(route, form, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(res => res.data);
};
export const deleteUpload = (uploadId) => {
    const route = generate('api.bankingReconciliation.uploads.delete', {
        upload: uploadId
    });
    return client.delete(route);
};
