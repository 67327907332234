import { useModalContext } from '../ModalContext';
import useEventCallback from '@restart/hooks/useEventCallback';
export function useModalCloseHandler(onHide) {
    const context = useModalContext();
    return useEventCallback(() => {
        if (context)
            context.onHide();
        if (onHide)
            onHide();
    });
}
