import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useViewType } from "@f2w/view";
import { translate, translateAdmin } from "Services/Translator";
import { ContactDataTab } from "Pages/Administration/Customers/components/ContactDataTab";
import { CustomerView } from "Pages/Administration/Customers/types/CustomerView";
import { CustomerTemplate } from "Pages/Administration/Customers/components/CustomerTemplate";
import { SubscriptionsTab } from "Pages/Administration/Customers/components/SubscriptionsTab";
import { DropdownButton } from "Atoms/Button";
import { getGlobalContext } from "Services/GlobalContext";
import { UserType } from "Types/User";
import { IntegrationsTab } from "Pages/Administration/Customers/components/IntegrationsTab";
import { Dropdown } from "Atoms/Dropdown";
export const useCustomerView = ({ getCustomerDetails, openEdit, openCreateSubscription, openUpgradeSubscription, openDowngradeSubscription, openSubscriptionDetails }) => useViewType(() => new CustomerView(), {
    id: 'customer-view',
    defaultState: {
        leftActiveTab: 'contactData',
        rightActiveTab: 'subscriptions',
    },
    DefaultTemplate: (props) => _jsx(CustomerTemplate, { ...props }),
    load: ({ id }) => getCustomerDetails(id),
    actions: {
        openEdit,
        openSubscriptionDetails
    }
}, () => ({
    contactData: {
        title: translateAdmin('customer.contactData'),
        Content: (props) => _jsx(ContactDataTab, { ...props }),
    },
    subscriptions: {
        title: translateAdmin('customer.subscriptions'),
        Content: (props) => _jsx(SubscriptionsTab, { ...props }),
        Form: (props) => _jsx(_Fragment, {}),
        Actions: ({ view }) => {
            const { user } = getGlobalContext();
            if (user.userType !== UserType.FAIRWALTER) {
                return _jsx(_Fragment, {});
            }
            return (_jsxs(DropdownButton, { label: translate('btn.new'), children: [_jsx(Dropdown.Item, { onClick: openCreateSubscription, children: translateAdmin('customer.btn.newSubscription') }), _jsx(Dropdown.Item, { disabled: view.data.subscriptions.length === 0, onClick: openUpgradeSubscription, children: translateAdmin('customer.btn.subscriptionUpgrade') }), _jsx(Dropdown.Item, { disabled: view.data.subscriptions.length === 0, onClick: openDowngradeSubscription, children: translateAdmin('customer.btn.subscriptionDowngrade') })] }));
        },
    },
    integrations: {
        title: translateAdmin('customer.integrations'),
        Content: (props) => _jsx(IntegrationsTab, { ...props }),
    }
}));
