import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { Select } from "Atoms/Select";
const options = [
    {
        value: 0,
        label: translate('all')
    },
    {
        value: 1,
        label: translate('dunning.onlyOverdueTenants')
    }
];
export function OverdueTenantsFilter({ column: { filterValue, setFilter }, }) {
    return _jsx(Select, { isSearchable: true, defaultValue: 1, value: filterValue, options: options, onChange: (value) => setFilter(value.value) });
}
