import { translate } from 'Services/Translator';
export const optionsPresets = {
    confirm: (props) => ({
        message: `Are you sure?`,
        btnCancel: translate('Cancel'),
        btnConfirm: translate('Confirm'),
        ...props,
    }),
    delete: ({ itemName = 'this', ...props }) => ({
        message: `Are you sure you want to delete <b>${itemName}?</b>`,
        btnCancel: translate('Cancel'),
        btnConfirm: translate('Delete'),
        ...props,
    }),
    deleteRecord: ({ title = 'this', }) => ({
        message: `Are you sure you want to delete ${title}?`,
        help: 'This action can not be undone.',
        btnCancel: translate('Cancel'),
        btnConfirm: translate('Delete'),
    }),
};
