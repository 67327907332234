import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useFinancialAccountGroupTable } from "Pages/Accounting/AccountingRecord/hooks/useFinancialAccountGroupTable";
import { DataTable } from '@f2w/data-table';
export const FinancialAccountGroupTable = ({ accountGroup, fiscalYear, dateFilter, filterEmpty = false }) => {
    const { instance } = useFinancialAccountGroupTable(accountGroup, fiscalYear, dateFilter);
    useEffect(() => {
        instance.setFilter('isEmpty', filterEmpty);
    }, [filterEmpty]);
    return (_jsx(DataTable.Table, { instance: instance, variant: 'content', scroll: true }));
};
export default FinancialAccountGroupTable;
