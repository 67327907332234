import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { translate } from 'Services/Translator';
import { BarChart, StackedBarChart } from 'Components/Chart';
import { Text } from 'Atoms/Text';
import { formatMoney } from 'Services/NumberHelper';
import { setDebugVariable } from '@f2w/utils';
export const RefinancingChart = ({ loadData, group, ...props }) => {
    const [chart, setChart] = useState({ stacks: [], data: [] });
    useEffect(() => {
        loadData({ group })
            .then(({ stats }) => {
            const stackData = {};
            stats.forEach(({ items }) => items.forEach(({ itemId, property: { name }, mortgage, }) => {
                stackData[itemId] = {
                    itemId,
                    name,
                    label: mortgage.label,
                    effectiveInterest: mortgage?.effectiveInterest ? (mortgage.effectiveInterest / 100) : 0,
                };
            }));
            const stacks = Object.values(stackData);
            const data = stats.map(({ group: groupName, items }) => ({
                name: groupName,
                stacks: stacks.map(({ itemId }) => items.find(v => v.itemId === itemId)?.mortgage?.value ?? 0),
            }));
            setChart({ data, stacks });
        });
    }, [group]);
    setDebugVariable({ mortgageChart: chart });
    if (chart.data.length === 0) {
        return _jsx("div", { className: "text-center", children: translate('chart.noData') });
    }
    return _jsx(StackedBarChart, { ...{
            ...props,
            config: { stacks: chart.stacks },
            data: chart.data,
        } });
};
export const PaymentVsRentChart = ({ loadData, ...props }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        loadData()
            .then(({ stats }) => {
            setData(stats.map(({ property: { name }, mortgageAmount, rentAmount, }) => ({
                name, in: rentAmount, out: mortgageAmount,
            })));
        });
    }, []);
    if (data.length === 0) {
        return _jsx("div", { className: "text-center", children: translate('chart.noData') });
    }
    return (_jsx(BarChart, { ...{
            ...props,
            barColor: 'secondary',
            variant: 'fill',
            config: {
                bars: [
                    {
                        name: translate('mortgage.stats.mortgage'),
                        getValue: data => _jsx(Text, { body: 500, children: formatMoney(data.out) }),
                    },
                    {
                        name: translate('mortgage.stats.rent'),
                        getValue: data => _jsx(Text, { body: 500, children: formatMoney(data.in) }),
                    },
                    {
                        name: translate('mortgage.stats.spread'),
                        getValue: data => _jsx(Text, { body: 500, success: 400, children: formatMoney(data.in - data.out) }),
                    },
                ],
            },
            data,
        } }));
};
