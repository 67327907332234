import { closest, remove } from 'dom-helpers';
import { isFunction, isString } from 'Utils';
import { useEffect, useRef } from 'react';
export function useTargetContainer({ container, scrollable, }, deps = []) {
    const elRef = useRef();
    useEffect(() => {
        let menuEl, targetEl;
        if (scrollable && (!container || container === true)) {
            targetEl = useTargetContainer.resolve(elRef.current, true);
        }
        else {
            targetEl = useTargetContainer.resolve(elRef.current, container);
        }
        if (targetEl) {
            targetEl.appendChild(menuEl = elRef.current);
        }
        return () => {
            if (menuEl) {
                remove(menuEl);
            }
        };
    }, [container, scrollable, ...deps]);
    return elRef;
}
(function (useTargetContainer) {
    useTargetContainer.resetClassname = 'fw-dropdown-reset-container';
    useTargetContainer.resetSelector = `.${useTargetContainer.resetClassname}`;
    const __ = (val) => val[0] === '.' ? val : `.${val}`;
    useTargetContainer.find = (el, selector = useTargetContainer.resetClassname) => (el?.parentElement && closest(el.parentElement, __(selector)));
    useTargetContainer.resolve = (el, target) => {
        if (!el)
            return;
        if (target === true) {
            target = closest(el.parentElement.parentElement, useTargetContainer.resetSelector);
        }
        else if (isFunction(target)) {
            target = target(el);
        }
        else if (isString(target)) {
            target = closest(el, target);
        }
        if (target instanceof HTMLElement) {
            return target;
        }
    };
})(useTargetContainer || (useTargetContainer = {}));
