import { jsx as _jsx } from "react/jsx-runtime";
import { translateAdmin } from "Services/Translator";
import { CustomerType } from "Pages/Administration/Customers/enums";
import { Select as DropdownSelect } from "Atoms/Select";
const options = [
    { value: CustomerType.PENDING, label: translateAdmin('customer.type.pending') },
    { value: CustomerType.TRIAL, label: translateAdmin('customer.type.trial') },
    { value: CustomerType.PAID, label: translateAdmin('customer.type.paid') },
    { value: CustomerType.TEST, label: translateAdmin('customer.type.test') },
    { value: CustomerType.DEMO, label: translateAdmin('customer.type.demo') },
];
export function TypeFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: options, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
}
