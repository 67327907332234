import { formatBytes, inObject, isObject, objectPick } from '@f2w/utils';
import { translate } from 'Services/Translator';
export var UploadError;
(function (UploadError) {
    UploadError.types = {
        server: ({}, { error }) => translate('uploadManager.error.server'),
        fileType: ({}) => `${translate('uploadManager.error.fileType')}.`,
        fileMaxSize: ({ maxSize }) => translate('uploadManager.error.fileMaxSize', { size: formatBytes(maxSize) }),
        fileMinSize: ({ minSize }) => translate('uploadManager.error.fileMinSize'),
        maxFiles: ({ maxFiles: count }) => translate('uploadManager.error.maxFiles', { count }),
    };
})(UploadError || (UploadError = {}));
export class UploadFile {
    file;
    _id;
    _state;
    error;
    constructor(file) {
        this.file = file;
    }
    get id() {
        return this._id ?? this.name;
    }
    get name() {
        return this.file.name;
    }
    get size() {
        return this.file.size;
    }
    get formattedSize() {
        return formatBytes(this.size);
    }
    get type() {
        return this.file.type;
    }
    get isCompleted() {
        return this.error || this.isLoaded;
    }
    get isLoading() {
        return this._state === 'loading';
    }
    get isLoaded() {
        return this._state === 'loaded';
    }
    get isValid() {
        return !this.error;
    }
    getProps() {
        return objectPick(this, ['name', 'id', 'isLoading', 'isLoaded', 'error', 'type']);
    }
    setId(id) {
        this._id = id;
        return this;
    }
    setError(code, message) {
        this.error = isObject(code) ? code : code ? { code, message } : undefined;
        return this;
    }
    setLoading() {
        this._state = 'loading';
        return this;
    }
    setLoaded() {
        this._state = 'loaded';
        return this;
    }
}
(function (UploadFile) {
    UploadFile.getId = (value) => inObject(value, 'id') ? value.id : value;
    UploadFile.getIds = (...values) => values.flat(4).map(UploadFile.getId);
    UploadFile.resolve = (value) => {
        if (value instanceof UploadFile) {
            return value;
        }
        const props = (inObject(value, 'file') ? value : { file: value });
        const { file, errors, error = errors?.[0]?.code ?? null, } = props;
        return (new UploadFile(file)).setError(error);
    };
    UploadFile.resolveList = (...files) => files.flat(5).map(UploadFile.resolve);
})(UploadFile || (UploadFile = {}));
