import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Title, SpinnerNew } from 'Components/Atoms';
import { generateDocumentAndGetLink } from 'Services/DocumentGenerator';
import { Content } from 'Atoms/Layout/components';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import useTimeout from '@restart/hooks/useTimeout';
const extractUUIDRegex = /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/;
export const DocumentPreviewTemplate = ({ view, }) => {
    const [documentLink, setDocumentLink] = React.useState();
    const { set } = useTimeout();
    React.useEffect(() => {
        let req;
        set(() => {
            req = generateDocumentAndGetLink('document.debitorInvoice.invoice', 'App\\Entity\\Financial\\Invoice\\DebitorInvoice', String(view.data.id));
            req.then((response) => {
                setDocumentLink(generateRoute('api_preview_document', {
                    document: extractUUIDRegex.exec(response)[0],
                }));
            });
        }, 200);
        return () => {
            if (req?.state() === 'pending') {
                req.abort();
            }
        };
    }, []);
    return (_jsx(Content, { style: { overflow: 'hidden' }, children: documentLink
            ? _jsx("embed", { className: "document-viewer", src: documentLink, width: "100%", height: "100%" })
            : _jsx(SpinnerNew, { alignItems: 'center', message: _jsx(Title, { as: 'h3', children: translate('debitor.details.documentPreview') }) }) }));
};
