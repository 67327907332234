import { jsx as _jsx } from "react/jsx-runtime";
import { convertOptionsToValues } from "Utils/select";
import { Select } from 'Atoms/Select';
import { translate } from "Services/Translator";
const options = [
    { value: 'RENT', label: translate("bank.type.rent") },
    { value: 'CREDIT', label: translate("bank.type.credit") },
    { value: 'TENANT', label: translate("bank.type.tenant") },
    { value: 'DEPOSIT', label: translate("bank.type.deposit") },
];
export function BankAccountTypeFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(Select, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: options, onChange: (newValue) => setFilter(convertOptionsToValues(newValue)) }));
}
