import { useFormModal } from "@f2w/form";
import { UpsertFeatureFlagType } from "Pages/Administration/FeatureFlag/form/UpsertFeatureFlagType";
import { translateAdmin } from "Services/App";
export const useUpsertFeatureFlagForm = ({ createFeatureFlag, updateFeatureFlag, onSave }) => useFormModal({
    type: () => new UpsertFeatureFlagType(),
    name: 'upsert-feature-flag-form',
    title: ({ values }) => values.id
        ? translateAdmin('featureFlag.edit.title')
        : translateAdmin('featureFlag.new.title'),
    onLoad: async (data) => {
        if (data) {
            return data;
        }
        return undefined;
    },
    onSave: (values) => {
        if (values.id) {
            return updateFeatureFlag(values.id, values)
                .then(onSave);
        }
        return createFeatureFlag(values)
            .then(onSave);
    }
});
