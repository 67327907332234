import { jsx as _jsx } from "react/jsx-runtime";
import { CircularProgressbar as CircularProgressbarBase, buildStyles as baseBuildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export const buildStyles = baseBuildStyles;
const CircularProgressbar = (props) => {
    return (_jsx(CircularProgressbarBase, { background: true, styles: buildStyles({
            strokeLinecap: 'round',
            pathColor: 'var(--color-primary-main)',
            trailColor: 'var(--color-grey-1)',
            backgroundColor: 'var(--color-grey-3)'
        }), ...props }));
};
export default CircularProgressbar;
