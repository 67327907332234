import generateRoute from 'Services/Router';
import client from 'Utils/client';
import { mapIds, toObject } from 'Utils/types';
import { UpdateTransactionDtoAction, } from '../types';
export var ExceptionType;
(function (ExceptionType) {
    ExceptionType[ExceptionType["ALREADY_IGNORED"] = 0] = "ALREADY_IGNORED";
    ExceptionType[ExceptionType["LOCKED_FISCAL_YEAR"] = 1] = "LOCKED_FISCAL_YEAR";
    ExceptionType[ExceptionType["MISSING_PROPERTY"] = 2] = "MISSING_PROPERTY";
    ExceptionType[ExceptionType["MISSING_INVOICE"] = 3] = "MISSING_INVOICE";
    ExceptionType[ExceptionType["MISSING_BANK_ACCOUNT"] = 4] = "MISSING_BANK_ACCOUNT";
    ExceptionType[ExceptionType["MISSING_FINANCIAL_ACCOUNT"] = 5] = "MISSING_FINANCIAL_ACCOUNT";
    ExceptionType[ExceptionType["UNKNOWN"] = 6] = "UNKNOWN";
    ExceptionType[ExceptionType["NOT_EDITABLE"] = 7] = "NOT_EDITABLE";
    ExceptionType[ExceptionType["SKIPPED"] = 8] = "SKIPPED";
})(ExceptionType || (ExceptionType = {}));
export const listTransactions = async (params) => {
    const url = generateRoute('api.bankingReconciliation.transactions.list');
    const { data } = await client.get(url, { params });
    return data;
};
export const loadInfo = async (filters) => {
    return getInfo({
        filters: filters,
    });
};
export const getInfo = async (params) => {
    const url = generateRoute('api.bankingReconciliation.transactions.info');
    const { data } = await client.get(url, { params });
    return data;
};
export const getIds = async (params) => {
    const url = generateRoute('api.bankingReconciliation.transactions.ids');
    const { data: ids } = await client.get(url, { params });
    return ids;
};
export const ignore = async (ids) => {
    return send(mapIds(ids, {
        action: UpdateTransactionDtoAction.IGNORE,
    }));
};
export const update = async (request) => {
    return send(request);
};
export const approveAllPreAssgned = async () => {
    const url = generateRoute('api.bankingReconciliation.transaction.approvePreAssigned');
    return client.post(url);
};
export const send = async (request) => {
    const { data, status } = await _send(request);
    return data;
};
export const _send = (request) => {
    if (Array.isArray(request) && request.length > 1) {
        const url = generateRoute('api.bankingReconciliation.transaction.updateBatch');
        return client.post(url, { updates: request });
    }
    const { id, ...data } = toObject(Array.isArray(request) ? request.shift() : request);
    const url = generateRoute('api.bankingReconciliation.transaction.update', { id });
    return client.post(url, data);
};
