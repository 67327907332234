import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, AddressType, BooleanType, CollectionType, IbanType, MoneyType, SelectType, StringType, } from '@f2w/form';
import { BankAccountType } from 'Pages/BankAccount/enums';
import { translate } from 'Services/Translator';
import React, { useState } from 'react';
import FinancialAccountServices from 'Services/FinancialAccountServices';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import PersonServices from 'Services/PersonServices';
import { Card } from "Atoms/Card";
import { UseStyled } from "@fw/theme";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { css } from "styled-components";
import { Text } from "Atoms/Text";
import useStableMemo from "@restart/hooks/useStableMemo";
import { Spinner } from "Atoms/Spinner";
class OwnerType extends AbstractFormType {
    buildFields() {
        return {
            ownerName: new StringType({
                label: translate('bank.ownerName')
            }),
            ownerAddress: new AddressType({
                label: false,
                houseNumber: {
                    required: true
                }
            })
        };
    }
    _renderWidget(iProps) {
        return _jsx(Card, { style: { marginBottom: '21px' }, children: super._renderWidget(iProps) });
    }
}
const WarningBlock = UseStyled.styled(Div, ({ theme: { palette } }) => (css `
        background: ${palette.warning.$50};
        padding: 16px;
        border-radius: 8px;
    `));
class QrIntegrationType extends AbstractFormType {
    buildFields() {
        return {
            qrIban: new IbanType({
                label: translate('bank.qrIban'),
                required: false,
                forceQrIban: true
            }),
            qrCustomerId: new StringType({
                label: translate('qrBillIntegration.form.besrCustomerId'),
                required: false,
                nullable: true,
                constraints: {
                    matches: [/^\d{6,8}$/, translate('qrBillIntegration.form.errors.wrongBesrCustomerId')],
                }
            })
        };
    }
    _renderWidget(_iProps) {
        return _jsx(Card, { style: { marginBottom: '21px' }, children: _jsxs(FlexCol, { gap: 24, children: [_jsx(Text, { large: 500, dark: 900, children: translate('qrBillIntegration.title') }), this.options.showWarning?.() && (_jsx(WarningBlock, { children: _jsx(Text, { medium: 400, warning: 500, children: translate('qrBillIntegration.modificationWarning') }) })), this.renderChild('qrIban'), this.renderChild('qrCustomerId')] }) });
    }
}
class CreatePropertyRefType extends AbstractFormType {
    properties = [];
    _useField(props) {
        super._useField(props);
        const otherPropertyRefFields = this.parent
            ? Object.entries(this.parent.children)
                .filter(([childName]) => childName !== this.field.props.name)
                .map(([, field]) => field.children.propertyId.field.value)
            : [];
        this.properties = useStableMemo(() => {
            return this.options?.getPropertiesOptions?.().map(property => ({
                ...property,
                isDisabled: otherPropertyRefFields.includes(property.value)
            })) ?? [];
        }, [otherPropertyRefFields]);
    }
    buildFields() {
        return {
            isPrimary: new BooleanType({
                label: translate('bank.primary'),
                required: false,
                defaultValue: false,
                onChange: value => {
                    if (value && this.parent) {
                        Object.entries(this.parent.children)
                            .filter(([childName]) => childName !== String(this.field.props.name))
                            .forEach(([, field]) => field.children.isPrimary.field.setValue(false));
                    }
                }
            }),
            propertyId: new SelectType({
                label: translate('property'),
                choiceOptions: () => this.properties,
                searchable: true,
            }),
            financialAccountId: new SelectType({
                label: translate('bank.financialAccount'),
                choiceOptions: () => this.options.getFinancialAccountsOptions?.(),
                searchable: true,
            }),
        };
    }
}
export class BaseBankAccountType extends AbstractFormType {
    persons = [];
    properties = [];
    financialAccounts = [];
    loading = true;
    _useField(_props) {
        const initialLoad = React.useRef(true);
        const [loading, setLoading] = useState(true);
        const [persons, setPersons] = React.useState([]);
        const [personMap, setPersonMap] = React.useState(new Map());
        const [properties, setProperties] = React.useState([]);
        const [financialAccounts, setFinancialAccounts] = React.useState([]);
        this.loading = loading;
        this.persons = persons;
        this.properties = properties;
        this.financialAccounts = financialAccounts;
        const portfolio = usePortfolio();
        React.useEffect(() => {
            if (this.options?.initialIban) {
                this.children.iban.field.setValue(this.options?.initialIban, true);
            }
            setProperties(portfolio.getProperties().map(property => ({
                label: property.name,
                value: property.id
            })));
            Promise.all([
                PersonServices.listPersonsForDropdown(),
                FinancialAccountServices.listFinancialAccountsForDropdown()
            ]).then(responses => {
                const persons = responses[0];
                setPersons(persons.map(person => ({
                    label: person.name,
                    value: person.id
                })));
                const personMap = new Map();
                persons.forEach(person => personMap.set(person.id, person));
                setPersonMap(personMap);
                const financialAccounts = responses[1];
                const financialAccountMap = new Map();
                financialAccounts.forEach(financialAccount => {
                    if (financialAccountMap.has(financialAccount.category)) {
                        const baseFinancialAccount = financialAccountMap.get(financialAccount.category);
                        financialAccountMap.set(financialAccount.category, {
                            ...baseFinancialAccount,
                            options: [
                                ...baseFinancialAccount.options,
                                {
                                    label: financialAccount.label,
                                    value: financialAccount.id
                                }
                            ]
                        });
                    }
                    else {
                        financialAccountMap.set(financialAccount.category, {
                            label: financialAccount.category,
                            options: [
                                {
                                    label: financialAccount.label,
                                    value: financialAccount.id
                                }
                            ]
                        });
                    }
                });
                setFinancialAccounts(Array.from(financialAccountMap.values()));
            }).finally(() => setLoading(false));
        }, []);
        const { type, personId } = this.field.value;
        React.useEffect(() => {
            if (personMap.size === 0) {
                return;
            }
            if (this.field.value.id && initialLoad.current) {
                initialLoad.current = false;
                return;
            }
            if (type === BankAccountType.TENANT && personId) {
                const person = personMap.get(personId);
                this.children.label.field.setValue(translate('bank.tenant.label', {
                    name: person.name
                }), false);
                this.children.owner.children.ownerName.field.setValue(person.name, false);
                this.children.owner.children.ownerAddress.children.street.field.setValue(person.address?.street, false);
                this.children.owner.children.ownerAddress.children.houseNumber.field.setValue(person.address?.houseNumber, false);
                this.children.owner.children.ownerAddress.children.city.field.setValue(person.address?.city, false);
                this.children.owner.children.ownerAddress.children.country.field.setValue(person.address?.country, false);
                this.children.owner.children.ownerAddress.children.zip.field.setValue(person.address?.postalCode, false);
            }
        }, [type, personId, personMap]);
    }
    buildFields() {
        return {
            type: new SelectType({
                label: translate('bank.type'),
                defaultValue: () => this.options.fixedType ?? BankAccountType.RENT,
                isVisible: () => !this.options.fixedType,
                choices: {
                    [BankAccountType.RENT]: translate('bank.type.rent'),
                    [BankAccountType.CREDIT]: translate('bank.type.credit'),
                    [BankAccountType.TENANT]: translate('bank.type.tenant'),
                    [BankAccountType.DEPOSIT]: translate('bank.type.deposit'),
                }
            }),
            personId: new SelectType({
                label: translate('bank.persons'),
                clearable: true,
                searchable: true,
                required: false,
                menuPlacement: 'top',
                choiceOptions: () => this.persons,
                isVisible: () => [BankAccountType.TENANT, BankAccountType.DEPOSIT].includes(this.children?.type?.field?.value),
            }),
            label: new StringType({
                label: translate('bank.label'),
                required: true,
            }),
            iban: new IbanType({
                required: true,
                forceIban: () => this.children?.type?.field?.value === BankAccountType.RENT
            }),
            owner: new OwnerType({
                inherit: true
            }),
            creditorFinancialAccountId: new SelectType({
                label: translate('bankAccount.financialAccount'),
                choiceOptions: () => this.financialAccounts,
                searchable: true,
                required: false,
                clearable: true,
                help: translate('bankAccount.financialAccount.help'),
                isVisible: () => BankAccountType.CREDIT === this.children?.type?.field?.value,
            }),
            qrIntegration: new QrIntegrationType({
                inherit: true,
                showWarning: () => !!this.field.value.id,
                isVisible: () => BankAccountType.RENT === this.children?.type?.field?.value,
            }),
            openingBalance: new MoneyType({
                label: translate('bank.detailView.openingBalance'),
                defaultValue: () => 0,
                required: false,
                isVisible: () => [BankAccountType.RENT].includes(this.children?.type?.field?.value),
            }),
            properties: new CollectionType({
                label: translate('properties'),
                actions: {
                    add: true,
                    delete: true
                },
                defaultValue: () => [
                    {
                        isPrimary: true
                    }
                ],
                prototype: () => new CreatePropertyRefType({
                    label: false,
                    getFinancialAccountsOptions: () => this.financialAccounts,
                    getPropertiesOptions: () => this.properties,
                }),
                isVisible: () => this.children?.type?.field?.value === BankAccountType.RENT,
            }),
        };
    }
    _renderWidget(iProps) {
        if (this.loading) {
            return (_jsx(Div, { w: '100%', h: 300, children: _jsx(FlexRow, { h: '100%', alignCenter: true, justifyCenter: true, children: _jsx(Spinner, {}) }) }));
        }
        return super._renderWidget(iProps);
    }
}
