import { AbstractFormType, FormError, MoneyType, useForm } from "@f2w/form";
import { translate } from "Services/Translator";
const handleError = (error) => {
    const status = error.response.status;
    if (status === 422) {
        throw new FormError(error.response.data.message);
    }
    else if (status == 400) {
        throw new FormError(error.response.data);
    }
    throw new FormError("");
};
class FixCostType extends AbstractFormType {
    buildFields() {
        return {
            amount: new MoneyType({
                label: translate('calculation.distributions.fixCostShare'),
                constraints: {
                    min: [0, translate('form.validation.noLessThan', { length: 0 })],
                    max: [
                        this.options?.maxAmount?.(),
                        translate('form.validation.noLongerThan', { length: this.options?.maxAmount?.() })
                    ],
                }
            })
        };
    }
}
export const useFixCostForm = (accountId, calculationId, maxAmount, previousAmount, onClose, createFixCostDistribution) => useForm({
    name: 'fix-cost-form',
    type: () => new FixCostType({
        maxAmount: () => maxAmount
    }),
    initialValues: {
        amount: previousAmount
    },
    onSave: async ({ amount }) => {
        const requestData = {
            amount: Number(amount),
            accountId: accountId
        };
        return createFixCostDistribution(calculationId, requestData)
            .then(onClose);
    }
});
