import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { ButtonToolbar } from 'Atoms/Button';
import { Icon } from 'Atoms/Icon';
import { useTheme } from '@f2w/theme';
import { FlexRow } from 'Atoms/Layout/Flex';
import classnames from 'classnames';
import { useEventCallback } from '@restart/hooks';
import { closest } from 'dom-helpers';
export function Toast({ message, children, actions = children, type, ...props }) {
    const { color: _color, icon: _icon, onClose, ...rest } = Toast.variants.use(type, props);
    const closeAction = useEventCallback((e) => {
        if (e.target && e.currentTarget !== e.target && closest(e.target, '.fw-btn,a,.fw-prevent-click', e.currentTarget)) {
            return;
        }
        onClose && onClose();
    });
    return (_jsxs(Toast.Root, { ...rest, "data-fw-message": type, onClick: closeAction, className: classnames({
            'fw-toast--close': !!onClose
        }), style: {
            '--toast-color': _color,
        }, children: [_jsxs(FlexRow, { inline: true, gap: 24, children: [_jsx("div", { className: 'fw-toast--icon', children: _jsx(Icon, { icon: _icon }) }), _jsx("div", { className: 'fw-toast--message fw-prevent-click', children: message })] }), actions && _jsx(ButtonToolbar, { variant: 'fill', color: 'dark', children: actions }), onClose && _jsx("div", { className: 'fw-toast--close_icon', children: _jsx(Icon, { icon: 'x' }) })] }));
}
(function (Toast) {
    Toast.variants = useTheme.variantFactory((theme) => ({
        color: theme.palette.dark.$300,
    }), {
        info: t => ({
            icon: 'alert-circle',
            color: t.palette.dark.$300,
        }),
        success: t => ({
            icon: 'check',
            color: t.palette.success.$300,
        }),
        warning: t => ({
            icon: 'alert-circle',
            color: t.palette.warning.$300,
        }),
        error: t => ({
            icon: 'alert-circle',
            color: t.palette.error.$300,
        }),
    });
    Toast.Root = styled.div(({ onClick: close, theme: { palette, typography }, }) => {
        return css `
            position: relative;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;

            filter: drop-shadow(0px 8px 16px rgba(36, 41, 51, 0.2));
            border-left: 16px solid var(--toast-color, var(--fw-color-primary-500));
            padding: ${close ? '24px 40px 24px 24px' : '24px 32px 24px 24px'};
            max-width: 100%;
            gap: 24px;

            color: ${palette.white};
            background: ${palette.dark.$700};
            transition: background-color ease-in-out 250ms;

            > .fw-toast--close_icon {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 100;
                padding: 6px 6px 15px 15px;
                font-size: 24px;
                color: ${palette.neutral.$500};
                transition: color ease-in-out 250ms;
            }

            ${ButtonToolbar.Root} {
                align-items: end;
                align-self: end;
            }

            &.fw-toast--close {
                &:hover {
                    background: ${palette.dark.$800};
                    cursor: pointer;

                    > .fw-toast--close_icon {
                        color: ${palette.neutral.$300};
                    }
                }
            }

            > p {
                margin: 0
            }

            .fw-prevent-click {
                cursor: default;
            }

            .fw-toast--message {
                flex: 1 1 auto;
                ${typography.body.fontStyles};
            }

            .fw-toast--icon {
                flex: 0;
                font-size: 24px;
                height: 1em;
                width: 1em;
                align-self: start;
            }
        `;
    });
})(Toast || (Toast = {}));
