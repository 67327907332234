import client from "Utils/client";
import generateRoute from "Services/Router";
const downloadFile = (response) => {
    const fileName = response.headers['X-Download-File-Name'.toLowerCase()];
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURI(fileName));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};
const exportAccountingSheet = async (propertyId, fiscalYearId, dateRange) => {
    const response = await client.get(generateRoute('api.accounting.export.sheet', {
        propertyId: propertyId,
        fiscalYearId: fiscalYearId,
        dateRange: dateRange,
    }), { responseType: 'blob' });
    downloadFile(response);
};
const exportAccountingReport = async (propertyId, fiscalYearId, dateRange) => {
    const response = await client.get(generateRoute('api.accounting.export.report', {
        propertyId: propertyId,
        fiscalYearId: fiscalYearId,
        dateRange: dateRange,
    }), { responseType: 'blob' });
    downloadFile(response);
};
export { exportAccountingSheet, exportAccountingReport, };
