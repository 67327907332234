import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTable } from '@f2w/data-table';
import { translate } from 'Services/Translator';
import { Button, SafeAnchor, Select, Table, Tbody, Td, Th, Thead, Tr } from 'Atoms/index';
import { ActiveView, useOpenObjectViewModal } from 'Components/ObjectViewModal';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { generate as generateRoute } from 'Services/Router';
import { RentalObjectStatus } from '../components/RentalObjectStatusBadges';
export const PropertyDataTableType = DataTable.factory()(() => ({
    tableId: 'BaseData',
    Actions: ({}) => (_jsx(Button, { href: generateRoute('baseData.createProperty'), color: 'primary', label: translate('baseData.table.action.new'), icon: 'plus' })),
    usePagination: {},
    columns: {
        name: {
            Header: translate('baseData.table.column.property'),
            Cell: ({ value, row: { original: p } }) => {
                const openView = useOpenObjectViewModal(ActiveView.PROPERTY);
                return (_jsx(SafeAnchor, { onClick: () => openView(p.id), children: value }));
            },
            useSortBy: {},
            useGlobalFilter: {},
        },
        mandateName: {
            Header: translate('baseData.table.column.mandate'),
            useFilters: {
                Filter: ({ column: { filterValue, setFilter } }) => {
                    const portfolio = usePortfolio();
                    return _jsx(Select, { value: filterValue || '', isSearchable: true, isClearable: true, options: portfolio.getMandates().map(mandate => ({
                            label: mandate.name,
                            value: mandate.id
                        })), onChange: newValue => setFilter(newValue?.value) });
                },
            },
            useSortBy: {},
        },
        buildingsCount: {
            Header: translate('baseData.table.column.nbBuildings'),
        },
        rentalObjectsCount: {
            Header: translate('baseData.table.column.nbRentalObjects'),
        },
        occupancyStats: {
            Header: translate('baseData.table.column.contractsStatus'),
            Cell: ({ value }) => (_jsxs(RentalObjectStatus, { children: [_jsx(RentalObjectStatus.VacantBadge, { title: translate('baseData.rentalObject.vacant'), children: value.vacant }), _jsx(RentalObjectStatus.UpcomingBadge, { title: translate('baseData.rentalObject.upcoming'), children: value.upcoming }), _jsx(RentalObjectStatus.NoticedBadge, { title: translate('baseData.rentalObject.noticed'), children: value.noticed }), _jsx(RentalObjectStatus.RentedOutBadge, { title: translate('baseData.rentalObject.active'), children: value.rentedOut })] }))
        },
        $rowExpand: {}
    },
    useExpanded: {
        renderSubRow: ({ row: { original: { buildings, manager } } }) => {
            return _jsx("div", { style: { padding: "16px 8px" }, children: _jsx(BuildingsTable, { buildings: buildings, manager: manager }) });
        }
    }
}));
const BuildingsTable = ({ manager, buildings }) => {
    const openView = useOpenObjectViewModal(ActiveView.BUILDING);
    return (_jsxs(Table, { variant: "content", borderRadius: false, border: false, rowDivider: false, children: [_jsxs(Thead, { children: [_jsx(Th, { children: translate('baseData.table.column.building') }), _jsx(Th, { children: translate('baseData.table.column.buildingDate') }), _jsx(Th, { children: translate('baseData.table.column.lastRenovation') }), _jsx(Th, { children: translate('baseData.table.column.responsible') }), _jsx(Th, { children: translate('baseData.table.column.contractsStatus') })] }), _jsx(Tbody, { children: buildings.map((building, i) => (_jsxs(Tr, { children: [_jsx(Td, { children: _jsx(SafeAnchor, { onClick: () => openView(building.id), children: building.name }) }), _jsx(Td, { children: building.buildYear || translate('notAvailable') }), _jsx(Td, { children: building.lastRenovation || translate('notAvailable') }), _jsx(Td, { children: manager ? `${manager.firstName} ${manager.lastName}` : translate('notAvailable') }), _jsx(Td, { children: _jsxs(RentalObjectStatus, { children: [_jsx(RentalObjectStatus.VacantBadge, { title: translate('baseData.rentalObject.vacant'), children: building.occupancyStats.vacant }), _jsx(RentalObjectStatus.UpcomingBadge, { title: translate('baseData.rentalObject.upcoming'), children: building.occupancyStats.upcoming }), _jsx(RentalObjectStatus.RentedOutBadge, { title: translate('baseData.rentalObject.active'), children: building.occupancyStats.rentedOut })] }) })] }, `${building.id}-${i}-row`))) })] }));
};
