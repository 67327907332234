import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Icon } from './Icon';
const mimeIconMap = {
    'application/pdf': 'pdfFile',
    'application/msword': 'wordFile',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'wordFile',
    'application/vnd.ms-excel': 'excelFile',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excelFile',
    'application/vnd.ms-powerpoint': 'pptFile',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptFile',
};
export const MimeTypeIcon = ({ mimeType, ...props }) => {
    const iconName = useMemo(() => {
        if (mimeIconMap[mimeType]) {
            return mimeIconMap[mimeType];
        }
        return mimeType?.startsWith?.('image/') ? 'image' : 'file';
    }, [mimeType]);
    return _jsx(Icon, { icon: iconName, ...props });
};
