import { useForm } from "@f2w/form";
import { ResetPasswordType } from "Pages/Auth/types/ResetPasswordType";
import { translate } from "Services/App";
export const useAcceptInvitationForm = ({ acceptInvitation, verificationToken, onSave }) => useForm({
    name: 'accept-invitation-form',
    type: () => new ResetPasswordType(),
    onSave: (values) => {
        return acceptInvitation(values, verificationToken).then(onSave);
    },
    successMessage: translate('account.password.changed'),
});
