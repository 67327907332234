import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useObjectViewModal } from '../ObjectViewModalContext';
import { ActiveView } from '../enums';
import { ContractView } from 'Pages/Contract/components/DetailView/ContractView';
import { PropertyView } from 'Components/ObjectViewModal/components/PropertyView/PropertyView';
import { BuildingView } from 'Components/ObjectViewModal/components/BuildingView/BuildingView';
import { RentalObjectView } from 'Components/ObjectViewModal/components/RentalObjectView/RentalObjectView';
import { RentDetailView } from 'Pages/Rent/components/DetailView/RentDetailView';
import { AssetDetailView } from 'Pages/Asset/components/DetailView/AssetDetailView';
import { RoomView } from 'Components/ObjectViewModal/components/RoomView/RoomView';
export const ModalBody = ({ objectViewModalServices, waitingListServices }) => {
    const { getPropertyDetails, updateProperty, getBuildingDetails, updateBuilding, getRentalObjectDetails, updateRentalObject, getContractDetails, editContractGeneralData, editContractAncillaryExpensesData, editContractPersonsData, getRentalObjectRoomDetails, updateRentalObjectRoom } = objectViewModalServices;
    const { activeView, setContractData } = useObjectViewModal();
    return React.useMemo(() => {
        if (!activeView) {
            return _jsx(_Fragment, {});
        }
        switch (activeView.view) {
            case ActiveView.PROPERTY: {
                return _jsx(PropertyView, { getPropertyDetails: getPropertyDetails, updateProperty: updateProperty, propertyId: activeView.id });
            }
            case ActiveView.BUILDING: {
                return _jsx(BuildingView, { getBuildingDetails: getBuildingDetails, updateBuilding: updateBuilding, buildingId: activeView.id });
            }
            case ActiveView.RENTAL_OBJECT: {
                return _jsx(RentalObjectView, { getRentalObjectDetails: getRentalObjectDetails, updateRentalObject: updateRentalObject, waitingListServices: waitingListServices, rentalObjectId: activeView.id, handoverId: activeView.handoverId });
            }
            case ActiveView.RENTAL_OBJECT_ROOM: {
                return _jsx(RoomView, { roomId: activeView.id, rentalObjectId: activeView.parentRentalObjectId, getRentalObjectRoomDetails: getRentalObjectRoomDetails, updateRentalObjectRoom: updateRentalObjectRoom });
            }
            case ActiveView.CONTRACT: {
                return _jsx(ContractView, { getContractDetails: getContractDetails, editContractGeneralData: editContractGeneralData, editContractAncillaryExpensesData: editContractAncillaryExpensesData, editContractPersonsData: editContractPersonsData, setContractData: setContractData, contractId: activeView.id });
            }
            case ActiveView.RENT: {
                return _jsx(RentDetailView, { rentId: activeView.id });
            }
            case ActiveView.ASSET: {
                return _jsx(AssetDetailView, { assetId: activeView.id });
            }
            default: {
                return _jsx(_Fragment, {});
            }
        }
    }, [activeView]);
};
