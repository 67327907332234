import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { translate } from 'Services/Translator';
import { withDataTable } from '@f2w/data-table/DataTableProvider';
import { Button, ButtonToolbar } from 'Atoms/Button';
import { FlexRow } from 'Atoms/Layout';
import { Select } from 'Atoms/Select';
import { Text } from 'Atoms/Text';
import styled from 'styled-components';
const Root = styled.div `
  display: flex;
  margin: 0;
  padding: 20px 0;
  justify-content: space-between;
  flex-wrap: wrap;

  &:empty {
    display: none;
  }

  .fw-btn {
    --btn-padding-x: var(--btn-padding-y);
  }

  ${p => p.$loading ? {
    pointerEvents: 'none'
} : null}
`;
export const Pagination = withDataTable((props) => {
    const { instance, } = props;
    if (!instance?.hasPlugin('usePagination')) {
        return null;
    }
    const { gotoPage, nextPage, previousPage, canNextPage, canPreviousPage, setPageSize, paginationShowFirstLast, isLoading, hideInfo, state: { pageSize }, pageCount, pageIndex, pageTotalElements, } = instance;
    const { pageSizeOptions, pageItems, } = usePageSize(props);
    return (_jsxs(Root, { "$loading": isLoading, children: [pageCount < 2 ? null : (_jsxs(ButtonToolbar, { size: 'sm', variant: 'textFill', color: 'primary', children: [paginationShowFirstLast && _jsx(Button, { icon: 'chevrons-left', disabled: !canPreviousPage, title: translate('table.firstPage'), onClick: () => canPreviousPage && gotoPage(0) }), _jsx(Button, { icon: 'chevron-left', disabled: !canPreviousPage, title: translate('table.previousPage'), onClick: () => canPreviousPage && previousPage() }), _jsx(_Fragment, { children: pageItems.map(pagePosition => {
                            if (typeof pagePosition === 'number') {
                                const index = pagePosition - 1;
                                return (_jsx(Button, { active: pageIndex === index, title: String(pagePosition), onClick: () => gotoPage(index), label: pagePosition }, `page-${pagePosition}`));
                            }
                            return (_jsx(ButtonToolbar.Text, { children: _jsx("span", { children: "..." }) }, `page-${pagePosition}`));
                        }) }), _jsx(Button, { icon: 'chevron-right', disabled: !canNextPage, title: translate('table.nextPage'), onClick: () => canNextPage && nextPage() }), paginationShowFirstLast && _jsx(Button, { icon: 'chevrons-right', disabled: !canNextPage, title: translate('table.lastPage'), onClick: () => canNextPage && gotoPage(pageCount - 1) })] })), hideInfo ? null : _jsxs(FlexRow, { alignCenter: true, gap: 10, mr: 0, ml: 'auto', children: [_jsx(PageInfo, { pageSize: pageSize, pageCount: pageCount, pageIndex: pageIndex, totalElements: pageTotalElements }), pageSizeOptions?.length > 1 && _jsx(SizeSelect, { pageSize: pageSize, sizeOptions: pageSizeOptions, onChange: setPageSize })] })] }));
});
const SizeSelect = ({ pageSize, sizeOptions, onChange, }) => {
    if (sizeOptions?.length <= 1)
        return null;
    return _jsx(Select, { "$size": 'sm', container: true, defaultValue: pageSize, placeholder: translate('table.recordsPerPage'), options: sizeOptions, onChange: ({ value }) => {
            onChange(Number(value));
        } });
};
const PageInfo = ({ pageIndex, pageSize, totalElements, pageCount, }) => {
    const content = useMemo(() => {
        const offset = pageIndex * pageSize;
        const end = Math.min(totalElements, offset + pageSize);
        const start = Math.min(offset + 1, end);
        return translate('table.info2', {
            start,
            end,
            total: totalElements,
        });
    }, [pageIndex, pageSize, totalElements]);
    return (_jsx(Text, { small: true, children: content }));
};
function usePageSize(props) {
    const { pageCount, pageIndex, pageSizes: userPageSizes, pageBoundaryCount, pageSiblingCount, } = props.instance;
    const { pageSizes = userPageSizes || [10, 25, 50, 100], boundaryCount = pageBoundaryCount, siblingCount = pageSiblingCount, } = props;
    const pageSizeOptions = React.useMemo(() => {
        return pageSizes.map(size => ({ value: size, label: size }));
    }, [pageSizes]);
    const pageItems = React.useMemo(() => {
        return calculateItems({
            pageCount,
            currentPage: pageIndex + 1,
            boundaryCount,
            siblingCount,
        });
    }, [pageCount, pageIndex, boundaryCount, siblingCount]);
    return {
        pageItems,
        pageSizeOptions,
    };
}
function calculateItems({ pageCount = 1, currentPage, boundaryCount = 1, siblingCount = 1, }) {
    const range = (start, end) => {
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i);
    };
    const startPages = range(1, Math.min(boundaryCount, pageCount));
    const endPages = range(Math.max(pageCount - boundaryCount + 1, boundaryCount + 1), pageCount);
    const siblingsStart = Math.max(Math.min(currentPage - siblingCount, pageCount - boundaryCount - siblingCount * 2 - 1), boundaryCount + 2);
    const siblingsEnd = Math.min(Math.max(currentPage + siblingCount, boundaryCount + siblingCount * 2 + 2), endPages.length > 0 ? endPages[0] - 2 : pageCount - 1);
    return [
        ...startPages,
        ...(siblingsStart > boundaryCount + 2
            ? ['ellipsis-start']
            : boundaryCount + 1 < pageCount - boundaryCount
                ? [boundaryCount + 1]
                : []),
        ...range(siblingsStart, siblingsEnd),
        ...(siblingsEnd < pageCount - boundaryCount - 1
            ? ['ellipsis-end']
            : pageCount - boundaryCount > boundaryCount
                ? [pageCount - boundaryCount]
                : []),
        ...endPages,
    ];
}
export default Pagination;
