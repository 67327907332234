import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import * as BankAccount from './BankAccountStyled';
import moment from 'moment';
import { PieChart } from 'Components/Chart';
import { toNum } from 'Utils/types';
import { formatMoney } from 'Services/NumberHelper';
import { translate } from 'Services/Translator';
import { DatePicker } from 'Components/Atoms/DatePicker';
import { BalancePieChart } from 'Pages/BankAccount/components/DetailView/BalancePieChart';
import { BankAccountType } from 'Pages/BankAccount/enums';
import { Section } from 'Atoms/Layout/components';
import BankAccountServices from "Pages/BankAccount/services/bankAccountServices";
import { DateRangePicker } from 'Atoms/Input/controls/DatePicker/DateRangePicker';
const IncomePieChart = ({ data }) => {
    const config = useMemo(() => {
        const radius = 97;
        return {
            dataKey: 'income',
            outerRadius: radius,
            innerRadius: radius - 22
        };
    }, []);
    const totalInfo = React.useMemo(() => {
        const value = data.reduce((prev, item) => prev + toNum(item.income), 0);
        return {
            title: translate('bank.detailView.totalIncome'),
            value: formatMoney(value),
            percent: 1,
        };
    }, [data]);
    return (_jsx(PieChart, { colors: ["#45BDA0", "#63C7AF", "#94D9C8", "#E8F7F3"], data: data, config: config, Legend: () => null, totalInfo: totalInfo }));
};
const ExpenditurePieChart = ({ data }) => {
    const config = useMemo(() => {
        const radius = 97;
        return {
            dataKey: 'expenditure',
            outerRadius: radius,
            innerRadius: radius - 22
        };
    }, []);
    const totalInfo = React.useMemo(() => {
        const value = data.reduce((prev, item) => prev + toNum(item.expenditure), 0);
        return {
            title: translate('bank.detailView.totalExpenditure'),
            value: formatMoney(value),
            percent: 1,
        };
    }, [data]);
    return (_jsx(PieChart, { colors: ["#FC7B7B", "#FDA4A4", "#FDC2C2", "#FFEBEB"], data: data, config: config, Legend: () => null, totalInfo: totalInfo }));
};
export const ConnectionsTab = ({ group }) => {
    const { data, } = group;
    const [balance, setBalance] = useState();
    const [income, setIncome] = useState();
    const [expenditure, setExpenditure] = useState();
    const [date, setDate] = useState(new Date());
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').toDate());
    const [toDate, setToDate] = useState(new Date());
    useEffect(() => {
        const bankAccountId = data.id;
        if (!fromDate || !toDate) {
            return;
        }
        BankAccountServices.getIncomeAndExpenditure(bankAccountId, fromDate, toDate)
            .then(({ income, expenditure }) => {
            setIncome(income);
            setExpenditure(expenditure);
        });
    }, [fromDate, toDate]);
    useEffect(() => {
        const bankAccountId = data.id;
        if (data.type !== BankAccountType.RENT || !date) {
            return;
        }
        BankAccountServices.getBalance(bankAccountId, date)
            .then(({ balance }) => {
            setBalance(balance);
        });
    }, [date, data.propertiesRefs]);
    const balanceChartData = useMemo(() => {
        if (!balance) {
            return [];
        }
        return balance
            .values
            .map(({ property, amount }) => ({
            balance: amount,
            name: property.name
        }))
            .sort((el1, el2) => el2.balance - el1.balance);
    }, [balance?.values]);
    const incomeChartData = income ? income.values.map(({ property, amount }) => ({
        income: amount,
        name: property.name
    })) : [];
    const expenditureChartData = expenditure ? expenditure.values.map(({ property, amount }) => ({
        expenditure: amount,
        name: property.name
    })) : [];
    return (_jsxs(Section, { children: [data.type === BankAccountType.RENT && _jsxs(BankAccount.Balance, { children: [_jsxs(BankAccount.BalanceHeader, { children: [_jsxs("div", { children: [_jsx("h2", { children: translate('bank.detailView.overallBalance') }), _jsxs("p", { children: [translate('bank.detailView.openingBalance'), ": ", formatMoney(data.openingBalance)] })] }), _jsx("div", { children: _jsx(DatePicker, { value: date, style: { flexGrow: 1 }, onChange: (date) => setDate(date) }) })] }), _jsx(BalancePieChart, { data: balanceChartData, totalValue: balance?.total || 0 })] }), _jsxs(BankAccount.IncomeExpenditureHeader, { children: [_jsxs("h2", { children: [translate('bank.detailView.income'), " | ", translate('bank.detailView.expenditure')] }), _jsx(DateRangePicker, { startDate: fromDate, endDate: toDate, onChange: (date) => {
                            setFromDate(date.startDate);
                            setToDate(date.endDate);
                        } })] }), _jsxs(BankAccount.IncomeExpenditureCharts, { children: [_jsx(BankAccount.IncomeExpenditureChart, { children: _jsx(IncomePieChart, { data: incomeChartData }) }), _jsx(BankAccount.IncomeExpenditureChartsSeparator, {}), _jsx(BankAccount.IncomeExpenditureChart, { children: _jsx(ExpenditurePieChart, { data: expenditureChartData }) })] })] }));
};
