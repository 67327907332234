import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, CollectionType, fieldsFactory, MoneyType, SelectType, StringType } from "@f2w/form";
import { translate } from "Services/Translator";
import React from "react";
import * as DebitorServices from 'Pages/Debitor/services/debitorServices';
import { formatAmount, formatMoney } from "Services/NumberHelper";
import moment from "moment";
class SubPaymentType extends AbstractFormType {
    buildFields() {
        return fieldsFactory()({
            amount: new MoneyType({
                label: translate('invoice.position.amount'),
                required: true
            }),
            debitorInvoiceId: new SelectType({
                label: translate('invoice'),
                required: true,
                choiceOptions: () => this.options.debitorInvoices()
            }),
            note: new StringType({
                label: translate('splitPayment.payment.note'),
                required: false,
            }),
        });
    }
}
export class SplitPaymentType extends AbstractFormType {
    paymentMap = new Map();
    payments = [];
    debitorInvoices = [];
    _useField(props) {
        super._useField(props);
        const [paymentMap, setPaymentMap] = React.useState(new Map());
        this.paymentMap = paymentMap;
        const [payments, setPayments] = React.useState([]);
        this.payments = payments;
        const [debitorInvoices, setDebitorInvoices] = React.useState([]);
        this.debitorInvoices = debitorInvoices;
        const [propertyId, setPropertyId] = React.useState();
        const debitorInvoiceId = this.context.data.debitorInvoiceId;
        React.useEffect(() => {
            DebitorServices.getDebitorInvoiceById(debitorInvoiceId)
                .then(({ propertyId }) => setPropertyId(propertyId));
            DebitorServices.getPayments(debitorInvoiceId)
                .then(({ payments }) => {
                if (payments.length === 1) {
                    this.children.paymentId.field.setValue(payments[0].id);
                }
                const paymentMap = new Map();
                payments
                    .filter(payment => payment.subPaymentsTotal !== null)
                    .forEach(payment => {
                    paymentMap.set(payment.id, payment.subPaymentsTotal);
                });
                setPaymentMap(paymentMap);
                return payments;
            })
                .then((data) => {
                return data.map((payment) => ({
                    label: moment(payment.paidAt).format('DD.MM.YYYY') + ' — CHF ' + formatAmount(payment.amount),
                    value: payment.id
                }));
            })
                .then((options) => setPayments(options));
        }, [debitorInvoiceId]);
        React.useEffect(() => {
            if (!propertyId) {
                return;
            }
            const params = {
                pageSize: 100000,
                filters: {
                    propertyId: propertyId
                }
            };
            DebitorServices.getDebitorInvoices(params)
                .then(({ results }) => {
                return results.map((debitorInvoice) => ({
                    label: debitorInvoice.label,
                    value: debitorInvoice.id
                }));
            })
                .then((options) => options.filter((({ value }) => value !== this.context.data.debitorInvoiceId)))
                .then((options) => setDebitorInvoices(options));
        }, [propertyId]);
    }
    _renderWidget(_iProps) {
        const selectedPaymentId = this.children.paymentId.value;
        return (_jsxs(_Fragment, { children: [this.renderChild('paymentId'), this.paymentMap.has(selectedPaymentId)
                    && (_jsx("div", { children: translate('splitPayment.existingSubPayments.help', {
                            splitAmount: formatMoney(this.paymentMap.get(selectedPaymentId))
                        }) })), this.renderChild('subPayments')] }));
    }
    buildFields() {
        return {
            paymentId: new SelectType({
                label: translate('payment'),
                required: true,
                choiceOptions: () => this.payments
            }),
            subPayments: new CollectionType({
                label: translate('payment.subPayments'),
                actions: { add: true, delete: true },
                widget: 'default',
                defaultValue: [{}],
                prototype: () => new SubPaymentType({
                    label: false,
                    debitorInvoices: () => this.debitorInvoices,
                }),
            }),
        };
    }
}
