const subscribeToCustomEvent = (eventName, listener) => {
    document.addEventListener(eventName, listener);
};
const unsubscribeFromCustomEvent = (eventName, listener) => {
    document.removeEventListener(eventName, listener);
};
const dispatchCustomEvent = (eventName, data) => {
    const event = new CustomEvent(eventName, {
        detail: data
    });
    document.dispatchEvent(event);
};
export { dispatchCustomEvent, subscribeToCustomEvent, unsubscribeFromCustomEvent, };
