import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { globalSearch, globalSearchHistory, globalSearchStoreSelection } from 'Services/GlobalSerachManager';
import Launchpad from 'Components/Launchpad/Launchpad';
import { useAsyncDebounce } from 'react-table';
const LaunchpadController = ({ accountId, onSelectItem, placeholder, backgroundColor }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const debouncedOptions = useAsyncDebounce(async (inputValue, accountId) => {
        let results = [];
        if (inputValue) {
            results = await globalSearch(inputValue, accountId);
        }
        else {
            results = await globalSearchHistory();
        }
        return { hasSearched: !!inputValue, results };
    }, 350);
    const performSearch = async (query) => {
        setIsLoading(true);
        try {
            const { hasSearched, results } = await debouncedOptions(query, accountId);
            setHasSearched(hasSearched);
            setSearchResults(results);
        }
        finally {
            setIsLoading(false);
        }
    };
    const onSelectResultItem = async (item) => {
        if (item) {
            await globalSearchStoreSelection(item.id, item.type);
        }
        if (onSelectItem) {
            onSelectItem(item);
        }
        else if (item) {
            window.location.href = item.url;
        }
    };
    return (_jsx(Launchpad, { isLoading: isLoading, placeholder: placeholder, hasSearched: hasSearched, searchResults: searchResults, performSearch: performSearch, onSelectResultItem: onSelectResultItem, backgroundColor: backgroundColor }));
};
export default LaunchpadController;
