import { jsx as _jsx } from "react/jsx-runtime";
import { Select as DropdownSelect } from "Atoms/Select";
import { translate } from "Services/App";
const options = [
    { value: '0', name: 'no' },
    { value: '1', name: 'yes' },
].map(filter => ({
    value: filter.value,
    label: translate(`contract.overview.filter.showVacancy.values.${filter.name}`)
}));
export function ContractVacancyFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : 1, isSearchable: true, alignMenu: 'right', options: options, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
}
