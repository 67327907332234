import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import { ButtonTheme } from 'Atoms/Button/Button';
import './ButtonGroup.styles.scss';
export const ButtonGroup = React.forwardRef(({ size, color, variant, toggle, vertical, className, stacked, condense, role = 'group', as: Component = 'div', ...rest }, ref) => {
    const prefix = 'fw-btn-group';
    let baseClass = prefix;
    if (vertical)
        baseClass = `${prefix}-vertical`;
    return (_jsx(ButtonTheme, { ...{ size, color, variant }, children: _jsx(Component, { ...rest, ref: ref, role: role, className: classNames(className, baseClass, {
                [`${prefix}-toggle`]: toggle,
                [`${prefix}-condense`]: condense,
                [`${prefix}-stacked`]: stacked ?? true,
            }) }) }));
});
ButtonGroup.displayName = 'ButtonGroup';
