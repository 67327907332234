export default function getFirstLetters(value) {
    if (typeof value !== "string")
        return "";
    return value
        .split(' ')
        .filter(v => typeof v === 'string')
        .map(w => w.charAt(0))
        .join('')
        .toUpperCase();
}
