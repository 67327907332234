import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table } from "@f2w/data-table/table-types";
import { translate } from "Services/App";
import { Badge } from "Atoms/Badge";
import { Td, Tr } from "Atoms/Table";
import { useMemo } from "react";
import { Div, FlexRow } from "Atoms/Layout";
import { Select } from "Atoms/Select";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { useFinancialAccountsHook } from "Hooks/useFinancialAccountsHook";
import { Checkbox } from "Atoms/Input/controls";
import { Button } from "Atoms/Button";
import { Icon } from "Atoms/Icon";
const BaseTable = Table.factory()(() => ({
    tableId: 'property-refs',
    columns: {
        property: {
            Header: translate('property'),
            Cell: ({ value, row: { index }, isEdit, setPropertiesRefs, portfolio, rows }) => {
                const properties = portfolio.getProperties();
                const propertyOptions = useMemo(() => {
                    return properties.map(property => ({
                        label: property.name,
                        value: property.id,
                        isDisabled: !!rows.find(row => row.original.property.id === property.id && row.index !== index)
                    }));
                }, [properties, rows, index]);
                if (!isEdit) {
                    return _jsx(Div, { children: value.name });
                }
                return _jsx(Select, { menuPortalTarget: document.body, value: value.id, isSearchable: true, options: propertyOptions, onChange: (option) => {
                        setPropertiesRefs(prev => {
                            const newRefs = [...prev];
                            newRefs[index] = {
                                ...newRefs[index],
                                property: {
                                    id: option.value,
                                    name: option.label
                                }
                            };
                            return newRefs;
                        });
                    } });
            }
        },
        financialAccount: {
            Header: translate('financialAccount.caption'),
            Cell: ({ value, isEdit, setPropertiesRefs, row: { index }, groupedFinancialAccountOptions }) => {
                if (!isEdit) {
                    return _jsx(Div, { children: value.label });
                }
                return _jsx(Select, { menuPortalTarget: document.body, value: value.id, options: groupedFinancialAccountOptions, isSearchable: true, onChange: (option) => {
                        setPropertiesRefs(prev => {
                            const newRefs = [...prev];
                            newRefs[index] = {
                                ...newRefs[index],
                                financialAccount: {
                                    id: option.value,
                                    label: option.label
                                }
                            };
                            return newRefs;
                        });
                    } });
            }
        },
        isPrimary: {
            Header: translate('bank.primary'),
            Cell: ({ value, isEdit, setPropertiesRefs, row: { index } }) => {
                if (!isEdit) {
                    return value ? _jsx(Badge, { "$color": 'primary', children: translate('bank.primary') }) : _jsx(Div, {});
                }
                return (_jsxs(FlexRow, { justifySpaceBetween: true, children: [_jsx(Checkbox, { checked: value, onChange: () => {
                                setPropertiesRefs(prev => {
                                    return prev.map((ref, refIndex) => {
                                        if (refIndex === index) {
                                            return {
                                                ...ref,
                                                isPrimary: true
                                            };
                                        }
                                        return {
                                            ...ref,
                                            isPrimary: false
                                        };
                                    });
                                });
                            } }), _jsx(Button.Delete, { onClick: () => setPropertiesRefs(prev => prev.filter((_, refIndex) => refIndex !== index)), icon: 'trash', label: '' })] }));
            }
        }
    },
    Footer: ({ isEdit, setPropertiesRefs, portfolio, groupedFinancialAccountOptions, rows }) => {
        if (!isEdit) {
            return _jsx(_Fragment, {});
        }
        return _jsx(Tr, { children: _jsx(Td, { style: { background: 'white', borderTop: '1px solid #D7D8DB' }, colSpan: true, children: _jsxs(Button, { variant: 'link', onClick: () => {
                        setPropertiesRefs(prev => {
                            const usedPropertyIds = rows.map(row => row.original.property.id);
                            const properties = portfolio.getProperties();
                            const availableProperties = properties.filter(property => !usedPropertyIds.includes(property.id));
                            if (availableProperties.length === 0) {
                                return [...prev];
                            }
                            return [
                                ...prev,
                                {
                                    property: {
                                        id: availableProperties[0].id,
                                        name: availableProperties[0].name
                                    },
                                    financialAccount: {
                                        id: groupedFinancialAccountOptions[0].options[0].value,
                                        label: groupedFinancialAccountOptions[0].options[0].label
                                    },
                                    isPrimary: rows.length === 0
                                }
                            ];
                        });
                    }, children: [_jsx(Icon, { icon: 'plus' }), " ", translate('btn.add')] }) }) });
    },
}));
export const PropertyRefsDataTable = Object.assign(BaseTable, {
    use: ({ propertiesRefs, setPropertiesRefs, isEdit }) => {
        const portfolio = usePortfolio();
        const { groupedFinancialAccountOptions } = useFinancialAccountsHook({});
        return PropertyRefsDataTable.useCreateTable({
            data: propertiesRefs,
            setPropertiesRefs,
            isEdit,
            portfolio,
            groupedFinancialAccountOptions
        });
    }
});
