import generateRoute from "Services/Router";
import client from "Utils/client";
const getRentCostIncreaseOptions = () => {
    const route = generateRoute('rents.costIncreaseOptions');
    return client
        .get(route)
        .then(response => response.data);
};
export default {
    getRentCostIncreaseOptions
};
