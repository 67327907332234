import { useFormModal } from "@f2w/form";
import { EditCustomerType } from "Pages/Administration/Customers/types/EditCustomerType";
import { translateAdmin } from "Services/Translator";
export const useEditCustomerForm = ({ getData, editCustomer, onSave }) => useFormModal({
    name: "edit-customer-form",
    title: translateAdmin('customer.edit'),
    type: () => new EditCustomerType(),
    onLoad: async () => ({
        ...(getData())
    }),
    onSave: (values) => {
        const updateValues = {
            ...values,
            billingContact: values.billingContact.sameAsCustomerContact
                ? {
                    ...values.contact
                }
                : values.billingContact
        };
        return editCustomer(values.id, updateValues)
            .then(response => {
            onSave({
                ...updateValues,
                status: response.status
            });
        });
    }
});
