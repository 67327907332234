import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { slotAttr, slotCss, UseStyled } from '@f2w/theme';
import { SafeAnchor } from 'Atoms/SafeAnchor';
import { Icon } from 'Atoms/Icon';
import { inObject } from '@f2w/utils';
import '../Carousel.styles.scss';
export const carouselSlotAttr = (slot) => slotAttr('carousel', slot);
export const carouselSlotCss = (...slots) => slotCss('carousel', ...slots);
const Ico = UseStyled.Fn(Icon, css `
  && {
        font-size: 80px;
        pointer-events: all;
        transition-timing-function: ease-in-out;
        transition: stroke-width ease-in-out 300ms, opacity ease-in-out 300ms, transform ease-in-out 300ms;
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, .4));
        color: rgba(255, 255, 255, 1);
        opacity: var(--fw-carousel-ico--opacity, .8);
        stroke-width: 1.5;

        *:is(:hover, :focus-within, :focus) > & {
          stroke-width: 1;
          opacity: var(--fw-carousel-ico--opacity-hover, 1);
          transform: scale(var(--fw-carousel-ico--scale, 1.3));
          //opacity: var(--fw-carousel-control--hover-opacity);
        }
  }
`);
export const Controls = styled.div.attrs((p) => (carouselSlotAttr('controls'))) ``;
const Trigger = UseStyled.Fn(({ type, label = type, icon: icon, ...props }) => {
    return _jsxs(SafeAnchor, { ...carouselSlotAttr(`trigger-${type}`), ...props, tabIndex: -1, children: [_jsx(Ico, { ...inObject(icon, 'icon') ? icon : { icon } }), _jsx("span", { className: "sr-only", children: label ?? type })] });
});
export const PrevTrigger = (props) => (_jsx(Trigger, { type: 'prev', icon: 'chevron-left', ...props }));
export const NextTrigger = (props) => (_jsx(Trigger, { type: 'next', icon: 'chevron-right', ...props }));
export const FullscreenTrigger = (props) => (_jsx(Trigger, { type: 'fullscreen', icon: 'maximize', ...props }));
