import { generate } from "Services/Router";
import client from "Utils/client";
export var WaitingListServices;
(function (WaitingListServices) {
    WaitingListServices.getPotentialPersons = async (rentalObjectId) => {
        const route = generate('api.waitingList.getPotentialPersons', {
            rentalObject: rentalObjectId
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    WaitingListServices.save = async (rentalObjectId, personId) => {
        const route = generate('api.waitingList.addPerson', {
            rentalObject: rentalObjectId
        });
        return client
            .post(route, { personId })
            .then(response => response.data);
    };
    WaitingListServices.deleteWaitingList = (id) => {
        const route = generate('api.waitingList.delete', {
            waitingList: id
        });
        return client.delete(route);
    };
    WaitingListServices.getBuildingsForWaitingList = async (personId, params) => {
        const route = generate('api.waitingList.getBuildingsForPerson', {
            person: personId
        });
        return client
            .get(route, { params })
            .then(response => response.data);
    };
    WaitingListServices.addPersonToMultipleWaitingList = async (rentalObjectIds, personId) => {
        const route = generate('api.waitingList.addPersonToMultiple');
        return client
            .post(route, {
            personId,
            rentalObjectIds
        })
            .then(res => res.data);
    };
    WaitingListServices.removeMultiple = (waitingListIds) => {
        const route = generate('api.waitingList.deleteMultiple');
        return client.delete(route, {
            data: {
                waitingListIds
            }
        });
    };
})(WaitingListServices || (WaitingListServices = {}));
