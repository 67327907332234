import { jsx as _jsx } from "react/jsx-runtime";
import { convertOptionsToValues } from "Utils/select";
import { Select } from 'Atoms/Select';
import { translate } from "Services/Translator";
import { ProcessState } from "Pages/Process/enums";
const options = [
    { value: ProcessState.CREATED, label: translate(`process.state.${ProcessState.CREATED}`) },
    { value: ProcessState.DOCUMENTS_SENT, label: translate(`process.state.${ProcessState.DOCUMENTS_SENT}`) },
    { value: ProcessState.DOCUMENTS_RECEIVED, label: translate(`process.state.${ProcessState.DOCUMENTS_RECEIVED}`) },
    { value: ProcessState.COMPLETED, label: translate(`process.state.${ProcessState.COMPLETED}`) },
    { value: ProcessState.DRAFT, label: translate(`process.state.${ProcessState.DRAFT}`) },
    { value: ProcessState.CANCELED, label: translate(`process.state.${ProcessState.CANCELED}`) },
];
export function ProcessStateFilter({ column: { filterValue, setFilter }, }) {
    return (_jsx(Select, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: options, onChange: (newValue) => setFilter(convertOptionsToValues(newValue)) }));
}
