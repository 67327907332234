export var CreditorState;
(function (CreditorState) {
    CreditorState[CreditorState["QR_PENDING"] = 10] = "QR_PENDING";
    CreditorState[CreditorState["QR_COMPLETED"] = 11] = "QR_COMPLETED";
    CreditorState[CreditorState["INFO_PENDING"] = 20] = "INFO_PENDING";
    CreditorState[CreditorState["INFO_CONFIRMED"] = 21] = "INFO_CONFIRMED";
    CreditorState[CreditorState["INVOICE_ASSIGNED"] = 22] = "INVOICE_ASSIGNED";
    CreditorState[CreditorState["READY_FOR_PAYMENT"] = 23] = "READY_FOR_PAYMENT";
    CreditorState[CreditorState["BANK_FILE_CREATED"] = 30] = "BANK_FILE_CREATED";
    CreditorState[CreditorState["PAID"] = 42] = "PAID";
    CreditorState[CreditorState["OVERPAID"] = 41] = "OVERPAID";
    CreditorState[CreditorState["PARTIALLY_PAID"] = 40] = "PARTIALLY_PAID";
})(CreditorState || (CreditorState = {}));
(function (CreditorState) {
    function isEditable(state) {
        return [
            CreditorState.QR_COMPLETED,
            CreditorState.INFO_PENDING,
            CreditorState.INFO_CONFIRMED,
            CreditorState.INVOICE_ASSIGNED,
            CreditorState.READY_FOR_PAYMENT,
        ].includes(state);
    }
    CreditorState.isEditable = isEditable;
    function isDeletable(state) {
        return ![
            CreditorState.PAID,
            CreditorState.OVERPAID,
            CreditorState.PARTIALLY_PAID
        ].includes(state);
    }
    CreditorState.isDeletable = isDeletable;
    function isPayable(state) {
        return [
            CreditorState.PARTIALLY_PAID,
            CreditorState.READY_FOR_PAYMENT,
        ].includes(state);
    }
    CreditorState.isPayable = isPayable;
})(CreditorState || (CreditorState = {}));
export var BookingType;
(function (BookingType) {
    BookingType[BookingType["COMBINED"] = 1] = "COMBINED";
    BookingType[BookingType["SEPARATED"] = 2] = "SEPARATED";
})(BookingType || (BookingType = {}));
export var CreditorProcessStep;
(function (CreditorProcessStep) {
    CreditorProcessStep[CreditorProcessStep["PROCESSING"] = 0] = "PROCESSING";
    CreditorProcessStep[CreditorProcessStep["INVOICE_POSITIONS"] = 1] = "INVOICE_POSITIONS";
    CreditorProcessStep[CreditorProcessStep["APPROVAL"] = 2] = "APPROVAL";
})(CreditorProcessStep || (CreditorProcessStep = {}));
(function (CreditorProcessStep) {
    function fromState(state) {
        switch (state) {
            case CreditorState.INFO_PENDING:
            case CreditorState.QR_COMPLETED:
                return CreditorProcessStep.PROCESSING;
            case CreditorState.INFO_CONFIRMED:
                return CreditorProcessStep.INVOICE_POSITIONS;
            case CreditorState.READY_FOR_PAYMENT:
            case CreditorState.INVOICE_ASSIGNED:
                return CreditorProcessStep.APPROVAL;
        }
    }
    CreditorProcessStep.fromState = fromState;
})(CreditorProcessStep || (CreditorProcessStep = {}));
export var InvoiceRelevancy;
(function (InvoiceRelevancy) {
    InvoiceRelevancy["ANCILLARY_EXPENSE"] = "ANCILLARY_EXPENSE";
    InvoiceRelevancy["NORMAL_EXPENDITURE"] = "NORMAL_EXPENDITURE";
})(InvoiceRelevancy || (InvoiceRelevancy = {}));
