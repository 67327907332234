import { UseStyled, useTheme } from "@f2w/theme";
import { css } from "styled-components";
const SelectUpgradeSubscriptionBase = UseStyled.styled('div', () => ({}));
const Body = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        flex: 1;
        flex-direction: column;
    `;
});
const BottomPart = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: ${rem(32)};
        color:  ${palette.neutral.$500};
        ${typography.medium.fontStyles};
        gap: ${rem(24)};
        text-align: center;

    `;
});
const BottomPartTitle = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        ${typography.h5.fontStyles};
        color:  ${palette.neutral.$500};
    `;
});
const Meters = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        display: flex;
        flex:1;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 ${rem(32)} ${rem(30)} ${rem(32)};
        background: ${palette.neutral.$50};
    `;
});
const MeterSection = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        flex:1;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:not(:last-child)::after {
            content: " ";
            height: ${rem(56)};
            display: block;
            width: 1px;
            background: ${palette.neutral.$100};
        }
    `;
});
const MeterForm = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${rem(8)};
        padding: ${rem(24)};
        color:  ${palette.neutral.$500};
    `;
});
const MeterCaption = UseStyled.styled('div', () => {
    const { rem, palette, typography } = useTheme();
    return css `
        ${typography.medium.css({ fontWeight: 600 })};
        text-align: center;
    `;
});
const UpgradeSubscriptionStyled = Object.assign(SelectUpgradeSubscriptionBase, {
    Body,
    BottomPart,
    BottomPartTitle,
    Meters,
    MeterSection,
    MeterForm,
    MeterCaption
});
export default UpgradeSubscriptionStyled;
