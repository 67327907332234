import styled, { css } from 'styled-components';
import { styledModule } from '@f2w/theme';
export const ChartTooltip = Object.assign({}, styledModule(() => {
    const Root = styled.div `
      background: ${p => p.theme.palette.neutral.$800};
      color: ${p => p.theme.palette.white};
      border: 1px solid ${p => p.theme.palette.neutral.$600};
      box-shadow: 0 12px 12px rgba(26, 34, 51, 0.15);
      padding: 12px;
      border-radius: 8px;
      min-width: 150px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      transform-origin: center center;
      transform: translateY(-50%);

      .recharts-tooltip-wrapper-top & {
        transform: translateY(50%);
      }

      opacity: 0;
      ${p => p.active && css `
        transition-timing-function: ease-in;
        transition-property: opacity;
        //transition-delay: 150ms;
        transition-duration: 250ms;
        opacity: 1;
      `}

      p { margin: 0; }
      table {
        width: 100%;
        margin: 0;
        td, th {
          padding: 4px 0;
          vertical-align: baseline;
        }
        th {
          //text-align: left;
          vertical-align: middle;
          padding-right: 30px;
          ${p => p.theme.typography.small.fontStyles};
        }
        td {
          //text-align: right;
          ${p => p.theme.typography.medium.css({ fontWeight: 500 })};
        }
      }
    `;
    const Title = styled.h4 `
      color: ${p => p.theme.palette.neutral.$100};
      ${p => p.theme.typography.small.css({ textTransform: 'uppercase' })};
      margin: 0;
      padding: 0;
    `;
    const Content = styled.div `
    `;
    return {
        Root,
        Title,
        Content,
    };
}));
