import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Div, FlexRow, OverlayTrigger, Select, Text, Tooltip } from "Components/Atoms";
import { formatMoney } from "Services/NumberHelper";
import { DataTable } from "@f2w/data-table";
import { CalculationDistributionFixedTable } from "../../../table";
import { translate } from "Services/Translator";
import Checkbox from "Atoms/Checkbox";
export const FixedDistributionTable = ({ getDistributionKeysForProperty, overviewData, loadData, distributionAccount, updateDistributionKey, updateHeatingDaysShare }) => {
    const [distributionKeys, setDistributionKeys] = React.useState([]);
    React.useEffect(() => {
        getDistributionKeysForProperty()
            .then(response => setDistributionKeys(response.map(({ id, title }) => ({ label: title, value: id }))));
    }, []);
    const table = CalculationDistributionFixedTable.use({
        loadData,
        distributionAccount,
        overviewData,
        data: distributionAccount.positions ?? [],
        updateDistributionKey,
        updateHeatingDaysShare
    });
    return (_jsxs(_Fragment, { children: [_jsxs(FlexRow, { gap: 20, alignCenter: true, pt: 20, children: [_jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsx(Text, { large: 300, style: { margin: 0 }, children: translate('ancillaryExpenseAccount.settlement.allocationFormula') }), _jsx(Text, { primary: true, children: formatMoney(distributionAccount.totalCost) })] }), _jsx(Div, { w: 200, children: _jsx(Select, { ...{
                                isLoading: table.isLoading,
                                isDisabled: table.isLoading,
                                options: distributionKeys,
                                value: distributionAccount?.propertyDistributionKeyId,
                                placeholder: translate('ancillaryExpenses.distributionKey.select'),
                                onChange: (opt) => {
                                    if (opt?.value)
                                        table.updateDistributionKey(opt?.value);
                                },
                            } }) }), _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: `tooltip-vla`, children: translate('ancillaryExpenseCalculation.heatingShare.callout') }), children: _jsxs(Div, { style: { display: 'flex', flexDirection: 'column', gap: 5 }, children: [_jsx(Checkbox, { name: "isHeatingDaysShare", checked: !distributionAccount?.isHeatingDaysShare, radio: true, onChange: () => table.updateHeatingDaysShare(false), children: translate('ancillaryExpenseCalculation.duration') }), _jsx(Checkbox, { name: "isHeatingDaysShare", checked: distributionAccount?.isHeatingDaysShare, radio: true, onChange: () => table.updateHeatingDaysShare(true), children: translate('ancillaryExpenseCalculation.heating') })] }) })] }), _jsx(DataTable.SimpleTable, { filter: 'inline', instance: table })] }));
};
