import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Header, Section } from 'Atoms/Layout/components';
import { Tbody, Td, TdActions, Th, Thead, Tr } from "Atoms/Table";
import { translate } from "Services/Translator";
import { DateType, MoneyType } from "@f2w/form";
import generateRoute from "Services/Router";
import { CreditorInvoiceState } from "Pages/Creditor/components/CreditorInvoiceState";
import { Title } from "Atoms/Text";
import { useModalDispatcherConfirm, useToastDispatcherApi } from "Components/Dispatcher";
import { unlinkAssetCreditorInvoice } from "Services/AssetManager";
export const CreditorInvoicesTab = ({ group: { data: { creditorInvoices = [], id: assetId, ...rest }, view }, }) => {
    const toastApi = useToastDispatcherApi();
    const onUnlink = useModalDispatcherConfirm({
        onConfirm: async (invoice) => {
            try {
                await unlinkAssetCreditorInvoice(invoice.id, assetId);
                toastApi.success({ message: translate('assets.unlink.success') });
                await view.load(assetId);
            }
            catch (e) {
                toastApi.error({ message: translate('assets.unlink.error'), timeout: 5, showRemove: true });
                console.error(e);
            }
        },
        message: translate('assets.unlink.message'),
        help: translate('assets.unlink.help'),
        btnConfirm: translate('assets.unlink.btnConfirm')
    });
    return _jsxs(_Fragment, { children: [_jsx(Header.Sticky, { children: _jsx(Title, { h6: true, as: 'h3', children: translate('asset.details.invoices.title') }) }), _jsxs(Section.Table, { stickyHead: 0, colSpan: 4, children: [_jsxs(Thead, { children: [_jsx(Th, { children: translate('asset.details.invoices.columns.status') }), _jsx(Th, { children: translate('asset.details.invoices.columns.dueDate') }), _jsx(Th, { children: translate('asset.details.invoices.columns.amount') }), _jsx(Th, { children: translate('asset.details.invoices.columns.label') }), _jsx(Th, { children: " " })] }), _jsx(Tbody, { children: creditorInvoices.map(invoice => (_jsxs(Tr, { children: [_jsx(Td, { children: _jsx(CreditorInvoiceState, { state: invoice.state }) }), _jsx(Td, { children: invoice.dueTo
                                        ? DateType.format(invoice.dueTo)
                                        : translate('notAvailable') }), _jsx(Td, { children: MoneyType.format(invoice.amount) }), _jsx(Td, { children: invoice.label }), _jsx(TdActions, { id: invoice.id, sticky: 'right', quickActions: [
                                        {
                                            icon: 'eye',
                                            title: translate('btn.view'),
                                            href: generateRoute('creditorInvoices.details', { invoiceId: invoice.id }),
                                            target: '_blank'
                                        },
                                        {
                                            icon: 'link-2',
                                            title: translate('btn.unlink'),
                                            onClick: () => { onUnlink(invoice); }
                                        },
                                    ] })] }, `invoice-table-row-${invoice.id}`))) })] })] });
};
