import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { SimpleType } from '@f2w/form';
import { FlexCol, FlexRow } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { Checkbox } from 'Atoms/Input/controls';
export var AlignmentTypeEnum;
(function (AlignmentTypeEnum) {
    AlignmentTypeEnum["LEFT"] = "left";
    AlignmentTypeEnum["CENTER"] = "center";
    AlignmentTypeEnum["RIGHT"] = "right";
    AlignmentTypeEnum["CUSTOM"] = "custom";
})(AlignmentTypeEnum || (AlignmentTypeEnum = {}));
export class AlignmentType extends SimpleType.ExtendString {
    choices = Object.values(AlignmentTypeEnum).map(value => ({
        value,
        label: translate(`form.type.alignment.options.${value}`),
    }));
    constructor(options, parent) {
        super({
            label: translate('form.type.alignment.label'),
            ...options,
            constraints: {
                ...options?.constraints,
                oneOf: [
                    Object.values(AlignmentTypeEnum),
                    null
                ],
            }
        }, parent);
        if (options?.includeCenter === false) {
            this.choices = this.choices.filter(({ value }) => value !== AlignmentTypeEnum.CENTER);
        }
        if (!options?.includeCustom) {
            this.choices = this.choices.filter(({ value }) => value !== AlignmentTypeEnum.CUSTOM);
        }
    }
    _renderWidget(props) {
        const field = this.field;
        return (_jsx(_Fragment, { children: _jsx(FlexRow, { gap: 30, children: this.choices.map(({ label, value }) => (_jsx(FlexCol, { children: _jsx(Checkbox, { name: field.name, value: value, radio: true, checked: field.value === value, onChange: () => {
                            field.setTouched(true, true);
                            field.setValue(value);
                        }, onBlur: () => {
                            field.setTouched(true, true);
                        }, children: label }) }, `${field.id}--widget--${value}`))) }) }));
    }
}
