import { AbstractFormType, BooleanType, DateType } from "@f2w/form";
import { PortfolioSelectType } from "Pages/Rent/forms/PortfolioSelectType";
import { translate } from "Services/Translator";
export class RenterMirrorDateRangeExportFormType extends AbstractFormType {
    buildFields() {
        return {
            startDate: new DateType({
                label: translate('rent.exportDateFrom'),
            }),
            endDate: new DateType({
                label: translate('rent.exportDateTo'),
            }),
            portfolio: new PortfolioSelectType({
                label: false,
                inheritData: true,
            }),
            anonymized: new BooleanType({
                label: translate('rent.anonymized'),
                required: false,
                defaultValue: false
            }),
        };
    }
}
