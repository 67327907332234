import { jsx as _jsx } from "react/jsx-runtime";
import { Select as DropdownSelect } from 'Atoms/Select';
import { translate } from "Services/Translator";
import { DebitorState } from "Pages/Debitor/enums";
export function DebitorInvoiceStateFilter({ column: { filterValue, setFilter }, }) {
    const options = [
        { value: '', label: translate('invoice.state.all') },
        { value: DebitorState.DRAFT, label: translate(`invoice.state.${DebitorState.DRAFT}`) },
        { value: DebitorState.SENT, label: translate(`invoice.state.${DebitorState.SENT}`) },
        { value: DebitorState.PAID, label: translate(`invoice.state.${DebitorState.PAID}`) },
        { value: DebitorState.OVERPAID, label: translate(`invoice.state.${DebitorState.OVERPAID}`) },
        { value: DebitorState.PARTIALLY_PAID, label: translate(`invoice.state.${DebitorState.PARTIALLY_PAID}`) },
    ];
    return (_jsx(DropdownSelect, { value: filterValue || '', isSearchable: true, useMaxContentWidth: true, options: options, onChange: (option) => {
            setFilter(option ? option.value : undefined);
        } }));
}
