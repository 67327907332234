import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "Atoms/Button";
import { translate } from "Services/Translator";
import generateRoute from "Services/Router";
import { OverlayTrigger, Tooltip } from "Atoms/Overlay";
import { useEffect, useState } from "react";
import { hasRole } from "Services/UserManager";
import { QuickschildIntegrationApi } from "Pages/Quickschild/services/QuickschildIntegrationService";
import { FormModal } from "@f2w/form";
import { useQuickschildOrderForm } from "Pages/Quickschild/hooks/useQuickschildOrderForm";
const QuickschildButtonWrapper = ({ tooltipText, buttonAction, onOpenForm }) => {
    const displayTooltip = buttonAction === 'disabled';
    const buttonProps = {
        outline: true,
        label: translate('contract.details.actions.nameplate'),
        disabled: buttonAction === 'disabled',
    };
    if (buttonAction === 'redirect') {
        buttonProps.href = generateRoute('quickschild.integration.overview');
    }
    if (buttonAction === 'form') {
        buttonProps.onClick = () => {
            onOpenForm();
        };
    }
    if (displayTooltip) {
        return (_jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: 'quickschild-button-tooltip', children: tooltipText }), children: _jsx(Button, { ...buttonProps }) }));
    }
    return (_jsx(Button, { ...buttonProps }));
};
const QuickschildBlock = ({ propertyId, contractId }) => {
    const [integration, setIntegration] = useState();
    const [buttonAction, setButtonAction] = useState('disabled');
    const [tooltipText, setTooltipText] = useState('');
    const isAdmin = hasRole('ROLE_ADMINISTRATOR');
    const orderForm = useQuickschildOrderForm();
    useEffect(() => {
        const loadIntegration = async () => {
            const integration = await QuickschildIntegrationApi.getIntegration();
            setIntegration(integration);
        };
        loadIntegration().catch(console.error);
    }, []);
    useEffect(() => {
        if (!integration)
            return;
        if (integration.active === true) {
            if (integration.properties.includes(propertyId)) {
                setButtonAction('form');
            }
            else {
                setButtonAction('disabled');
                setTooltipText(translate('quickschild.integrationView.contractView.tooltip.propertyNotActivated'));
            }
        }
        else {
            if (isAdmin) {
                setButtonAction('redirect');
            }
            else {
                setButtonAction('disabled');
                setTooltipText(translate('quickschild.integrationView.contractView.tooltip.integrationNotActivated'));
            }
        }
    }, [integration, isAdmin]);
    return (_jsxs(_Fragment, { children: [_jsx(QuickschildButtonWrapper, { buttonAction: buttonAction, tooltipText: tooltipText, onOpenForm: () => {
                    orderForm.openForm(contractId);
                } }), _jsx(FormModal, { modal: orderForm })] }));
};
export default QuickschildBlock;
