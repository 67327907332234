import { AbstractFormType, BooleanType, SelectType } from '@f2w/form';
import { translate } from 'Services/Translator';
import React from 'react';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { AccountServices } from '../index';
class FinancialAccountsType extends AbstractFormType {
    buildFields() {
        return {
            financialAccountId: new SelectType({
                label: translate('ancillaryExpenseAccount.accountingAccount'),
                choiceOptions: () => this.options.financialAccounts?.(),
                required: true,
            }),
        };
    }
}
const groupProperties = (properties) => {
    const groupedProperties = new Map();
    properties.forEach(property => {
        const mandateId = property.mandate.id;
        if (groupedProperties.has(mandateId)) {
            groupedProperties.set(mandateId, {
                ...groupedProperties.get(mandateId),
                options: [
                    ...groupedProperties.get(mandateId).options,
                    {
                        label: property.name,
                        value: property.id
                    }
                ]
            });
        }
        else {
            groupedProperties.set(mandateId, {
                label: property.mandate.name,
                options: [{
                        label: property.name,
                        value: property.id
                    }]
            });
        }
    });
    return Array.from(groupedProperties.values());
};
export class AncillaryExpenseDuplicateAccountsType extends AbstractFormType {
    properties = [];
    ancillaryExpenseAccounts = [];
    _useField(props) {
        super._useField(props);
        const [properties, setProperties] = React.useState([]);
        this.properties = properties;
        const [ancillaryExpenseAccounts, setAncillaryExpenseAccounts] = React.useState([]);
        this.ancillaryExpenseAccounts = ancillaryExpenseAccounts;
        const portfolio = usePortfolio();
        React.useEffect(() => {
            const { sourcePropertyId } = this.getInitialData();
            const properties = portfolio.getProperties()
                .filter(({ id }) => id !== sourcePropertyId);
            setProperties(groupProperties(properties));
            AccountServices.listAccountsForDropdownByProperty(sourcePropertyId)
                .then((options) => setAncillaryExpenseAccounts(options));
        }, []);
    }
    buildFields() {
        return {
            propertyId: new SelectType({
                label: translate('property'),
                required: true,
                choiceOptions: () => this.properties
            }),
            ancillaryExpenseAccountIds: new SelectType({
                label: translate('ancillaryExpenseAccounts'),
                required: true,
                multiple: true,
                choiceOptions: () => this.ancillaryExpenseAccounts
            }),
            copyFinancialAccounts: new BooleanType({
                label: translate('ancillaryExpenseAccount.doCopyFinancialAccount'),
                required: false,
                defaultValue: true
            }),
        };
    }
}
