import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from 'Atoms/Badge';
import { translate } from 'Services/Translator';
import { TransactionStatusType } from '../../types';
export const StatusBadge = ({ status }) => {
    let color = 'primary';
    switch (status) {
        case TransactionStatusType.PENDING:
            color = 'warning';
            break;
        case TransactionStatusType.PRE_ASSIGNED:
            color = 'warning';
            break;
        case TransactionStatusType.ASSIGNED:
            color = 'success';
            break;
        case TransactionStatusType.IGNORED:
            color = 'darkOutline';
            break;
        case TransactionStatusType.MANUAL:
            color = 'success';
            break;
    }
    return _jsx(Badge, { "$size": "sm", "$color": color, children: translate(`bankingReconciliationTransactionsTable.status.${status}`) });
};
