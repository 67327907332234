import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button } from 'Components/Atoms';
import { FormProvider, useForm } from '@f2w/form';
import { Form } from 'formik';
import CheckoutSubscriptionCart from './CheckoutSubscriptionCart';
import CheckoutSubscriptionPlanStyled from './CheckoutSubscriptionPlanStyled';
import { ArrowLeftIcon, InvoiceIcon } from 'Components/App/Icons';
import { translate } from 'Services/Translator';
import { ContactType } from 'Pages/Administration/Customers/types/EditCustomerType';
import { CustomerApi } from 'Pages/Customer/services/CustomerApi';
const { Body, Sections, SectionPayInfo, SectionTitle, SectionPayInfoStrongText, InvoiceSection, SectionYourOrder, SectionYourOrderFrame, ConfirmButtonSection } = CheckoutSubscriptionPlanStyled;
const CheckoutSubscriptionPlan = ({ subscriptionPlan, limits, onCheckout, onGoBack }) => {
    const [billingDetails, setBillingDetails] = useState();
    useEffect(() => {
        CustomerApi.getBillingContact()
            .then((billingDetails) => {
            setBillingDetails(billingDetails);
        });
    }, []);
    const billingForm = useForm({
        name: 'billing-form',
        initialValues: billingDetails ? billingDetails : {},
        enableReinitialize: true,
        type: () => new ContactType({
            label: false,
            isBillingContact: false,
        }),
        onSubmit: async (billingDetails) => {
            await onCheckout(subscriptionPlan, limits, billingDetails);
            return null;
        }
    });
    return (_jsx(FormProvider, { form: billingForm, children: _jsx(Form, { id: "checkout-subscription-form", noValidate: true, children: _jsxs(Body, { children: [_jsxs(Button, { onClick: () => onGoBack(), "$size": 'sm', variant: 'link', color: 'primary', children: [_jsx(ArrowLeftIcon, {}), " ", translate('subscriptions.backButton')] }), _jsxs(Sections, { children: [_jsxs(SectionPayInfo, { children: [_jsx(SectionTitle, { children: translate('subscriptions.sectionPayInfoTitle') }), _jsx(SectionPayInfoStrongText, { children: translate('subscriptions.paymentMethod') }), _jsxs(InvoiceSection, { children: [_jsx(InvoiceIcon, { size: 24 }), " ", _jsx("div", { children: translate('subscriptions.invoice') })] }), _jsx(SectionPayInfoStrongText, { children: translate('subscriptions.billingAddress') }), !!billingDetails && billingForm.render()] }), _jsx(SectionYourOrder, { children: _jsxs(SectionYourOrderFrame, { children: [_jsx(CheckoutSubscriptionCart, { subscriptionPlan: subscriptionPlan, limits: limits }), _jsx(ConfirmButtonSection, { children: _jsx(Button, { type: "submit", variant: 'fill', color: 'primary', children: translate('subscriptions.confirmOrder') }) })] }) })] })] }) }) }));
};
export default CheckoutSubscriptionPlan;
