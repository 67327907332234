import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import classes from '../styles';
import { translate } from 'Services/Translator';
import { BlackStarIcon, WhiteStarIcon } from '../../App/Icons';
import { Text } from 'Atoms/Text';
import styled, { css } from 'styled-components';
import { Div } from 'Atoms/Layout';
export const ListGroup = ({ staticSize = false, title, emptyContent, children, ...props }) => {
    const hasChildren = React.Children.count(children) > 0;
    return (_jsxs(ListGroupRoot, { ...props, children: [title && _jsx("div", { className: classes.sectionHead, children: _jsx(Text, { as: 'h6', body: 500, children: title }) }), _jsx(Text, { as: 'div', body: 400, className: classes.sectionBody, children: hasChildren ? (_jsx("ul", { className: classes.list, children: children })) : emptyContent ?? (_jsx(Div, { font: 'body', color: 'neutral.$10', children: translate('focusSelector.nothingFound') })) })] }));
};
const ListGroupRoot = styled.div(({ theme }) => css `
  ${theme.typography.body.css()};
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${theme.palette.white};
`);
export const FavouriteToggle = ({ active = false, onToggle, }) => {
    return (_jsx("div", { className: classes.actionIcon, onClick: (e) => {
            e.stopPropagation();
            onToggle(active);
        }, children: active ?
            _jsxs(_Fragment, { children: [_jsx("span", { className: classes.favoriteIcon, children: _jsx(BlackStarIcon, {}) }), _jsx("span", { className: classes.favoriteIconHover, children: _jsx(WhiteStarIcon, {}) })] })
            :
                _jsxs(_Fragment, { children: [_jsx("span", { className: classes.favoriteIcon, children: _jsx(WhiteStarIcon, {}) }), _jsx("span", { className: classes.favoriteIconHover, children: _jsx(BlackStarIcon, {}) })] }) }));
};
