import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ObjectViewModalProvider, useObjectViewModal } from './ObjectViewModalContext';
import * as ObjectViewModalServices from './services/objectViewModalServices';
import { ModalNew as Modal } from 'Components/Modal';
import { ModalHeader } from './components/ModalHeader';
import { ModalBody } from './components/ModalBody';
import { WaitingListServices } from "Services/waitingList";
export * from './ObjectViewModalProvider';
export const ObjectViewModal = ({ activeView, objectViewModalServices = ObjectViewModalServices, onClose, }) => {
    return (_jsx(ObjectViewModalProvider, { initialActiveView: activeView, onClose: onClose, children: _jsx(ModalBase, { objectViewModalServices: objectViewModalServices }) }));
};
const ModalBase = ({ objectViewModalServices = ObjectViewModalServices, waitingListServices = WaitingListServices }) => {
    const { modalOpen, closeModal } = useObjectViewModal();
    return (_jsxs(Modal, { show: modalOpen, onHide: closeModal, size: "detailView", children: [_jsx(ModalHeader, { objectViewModalServices: objectViewModalServices }), _jsx(ModalBody, { objectViewModalServices: objectViewModalServices, waitingListServices: waitingListServices })] }));
};
