import { translate } from 'Services/Translator';
import { useFormModal } from '@f2w/form';
import { createMandate, getMandateById, updateMandate } from './MandateServices';
import { MandateType } from './types';
export const useMandateForm = (props) => useFormModal({
    name: 'mandate-form',
    type: () => new MandateType(),
    title: ({ values }) => {
        return values?.id ? translate('mandate.edit') : translate('mandates.create');
    },
    onLoad: async (id) => {
        if (id) {
            return getMandateById(id);
        }
        return null;
    },
    onSave: async (data, utils, type) => {
        const promise = data.id
            ? updateMandate(data.id, data)
            : createMandate(data);
        return promise
            .then(d => {
            return props?.onSave(data, utils, type);
        });
    },
});
