import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalNew } from "Components/Modal";
import { Button } from "Components/Atoms";
import { translate } from "Services/Translator";
import { DataTable } from '@f2w/data-table';
import { PositionsInfoDataTable } from "Pages/Creditor/table/PositionsInfoDataTable";
export const PositionsInfoModal = ({ positions, modalOpen, onClose, onRentalObjectCLick, onPropertyClick }) => {
    const instance = PositionsInfoDataTable.use({
        data: positions,
        onCloseModal: onClose,
        onRentalObjectCLick,
        onPropertyClick
    });
    if (!modalOpen) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(ModalNew, { show: modalOpen, size: { maxWidth: '600px', maxHeight: '600px' }, children: [_jsx(ModalNew.Header, { onHide: onClose, closeButton: true, children: _jsx(ModalNew.Title, { children: translate('invoice.positions') }) }), _jsx(ModalNew.Body, { children: _jsx(DataTable.Table, { instance: instance, variant: 'content' }) }), _jsx(ModalNew.Footer, { children: _jsx(Button, { onClick: onClose, children: translate('btn.close') }) })] }));
};
