import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { CreditorTable } from 'Pages/Creditor/components/CreditorTable';
import { BankFilesTable } from './BankFilesTable';
import { Button, ButtonToolbar, FlexRow, Tab, Tabs } from 'Components/Atoms';
import { useToastDispatcherApi } from "Components/Dispatcher";
import { translate } from 'Services/Translator';
import { Title } from '@f2w/data-table/layout/PageOverviewStyles';
import { DownloadIcon } from 'Components/App/Icons';
import { hasFeature } from 'Services/FeatureFlagManager';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { useBulkManualPaymentFormModal } from 'Pages/Creditor/hooks/useBulkManualPaymentFormModal';
import { useManualPaymentForm } from 'Pages/Creditor/hooks/useManualPaymentForm';
import { BulkInvoicesErrorModal } from 'Pages/Creditor/components/BulkInvoicesErrorModal';
import { CreditorView } from 'Pages/Creditor/components/CreditorView';
import { CreditorDataTable } from '../table/CreditorDataTable';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { PositionsInfoModal } from "Pages/Creditor/PositionsInfoModal";
import { CreditorUploadModal } from "Pages/Creditor/components/CreditorUploadModal";
import { BankFilesDataTable } from "Pages/Creditor/table/BankFilesDataTable";
import { subscribeToCustomEvent, unsubscribeFromCustomEvent } from "Services/EventManager";
export const CreditorPage = ({ creditorServices, assetServices, invoiceId }) => {
    const { openViewModal } = useObjectViewModalContext();
    const [positionsModalOpen, setPositionModalOpen] = React.useState(false);
    const [uploadModalOpen, setUploadModalOpen] = React.useState(false);
    const [bulkInvoicesErrorModal, setBulkInvoicesErrorModal] = React.useState(false);
    const [bankFileErrorModalData, setBankFileErrorModalData] = React.useState();
    const [formData, setFormData] = React.useState(undefined);
    const [positions, setPositions] = React.useState([]);
    const [creditorInvoiceInfoData, setCreditorInvoiceInfoData] = React.useState();
    const [creditorInvoiceDetailsData, setCreditorInvoiceDetailsData] = React.useState();
    const { success } = useToastDispatcherApi();
    const onExportAttachments = React.useCallback(() => {
        creditorServices.exportAttachments()
            .then(() => {
            success({
                timeout: 2,
                message: translate('customerDocuments.exportSuccess.body')
            });
        });
    }, []);
    useEffect(() => {
        if (invoiceId !== null) {
            setCreditorInvoiceDetailsData({ creditorInvoiceId: invoiceId, receipt: null });
        }
    }, [invoiceId]);
    const creditorTable = CreditorDataTable.use({
        onPropertyClick: openViewModal(ActiveView.PROPERTY),
        onBuildingClick: openViewModal(ActiveView.RENTAL_OBJECT),
        creditorServices: creditorServices,
        openBulkPaymentModal: (creditorInvoiceIds) => {
            bulkManualPaymentsForm.openForm(creditorInvoiceIds);
        },
        openEditForm: setFormData,
        openManualPaymentForm: (creditorInvoiceId) => {
            manualPaymentForm.openForm(creditorInvoiceId);
        },
        onRefresh: () => onRefresh(),
        openBulkInvoicesErrorModal: async (numberOfInvoicesInPast, numberOfCorrectInvoices, invalidBankLinks, invoiceIds) => {
            await setBankFileErrorModalData({
                numberOfInvoicesInPast,
                numberOfCorrectInvoices,
                invalidBankLinks,
                invoiceIds
            });
            await setBulkInvoicesErrorModal(true);
        },
        openCreditorDetailsModal: (creditorInvoiceId, receipt) => setCreditorInvoiceDetailsData({
            creditorInvoiceId,
            receipt
        }),
        openPositionsModal: (positions) => {
            setPositions(positions);
            setPositionModalOpen(true);
        }
    });
    const bankFilesTable = BankFilesDataTable.use({
        creditorServices,
        openCreditorInfoModal: (bankFileId, creditorInvoices) => {
            setCreditorInvoiceInfoData({
                bankFileId,
                creditorInvoices
            });
        }
    });
    const onRefresh = React.useCallback(() => {
        creditorTable.refresh();
        bankFilesTable.refresh();
    }, [creditorTable, bankFilesTable]);
    const bulkManualPaymentsForm = useBulkManualPaymentFormModal(creditorServices, onRefresh);
    const manualPaymentForm = useManualPaymentForm(creditorServices.createPayment, creditorServices.loadPaymentData, onRefresh);
    const updateTableOnQrReaderCompleted = (e) => {
        creditorTable.refresh();
    };
    useEffect(() => {
        subscribeToCustomEvent('fw.qrReaderCompleted', updateTableOnQrReaderCompleted);
        return () => {
            unsubscribeFromCustomEvent('fw.qrReaderCompleted', updateTableOnQrReaderCompleted);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(FlexRow, { justifyContent: "space-between", alignItems: "center", minh: 50, mb: 5, children: [_jsx(Title, { children: translate('creditor.title') }), _jsxs(ButtonToolbar, { children: [_jsx(Button, { onClick: () => setUploadModalOpen(true), variant: 'fill', "$size": 'md', title: translate('creditor.actions.newUsingUploads'), children: translate('creditor.actions.newUsingUploads') }), _jsx(ActionsDropdown, { id: 'page-overview-actions-dropdown', drop: 'down', style: { marginLeft: 10 }, buttonProps: {
                                    variant: 'outline',
                                    color: 'primary',
                                    rounded: false,
                                    label: translate('table.actions.title')
                                }, dropdownActions: ({ Link: Button }) => {
                                    return (_jsxs(_Fragment, { children: [_jsxs(Button, { onClick: onExportAttachments, variant: 'outline', title: translate('creditor.actions.export'), children: [_jsx(DownloadIcon, {}), " ", translate('creditor.actions.export')] }), hasFeature('CREDITOR_CREATE_BLANK') && (_jsx(_Fragment, { children: _jsx(Button, { variant: 'fill', title: translate('creditor.actions.newBlank'), onClick: () => {
                                                        creditorServices.createBlankCreditor()
                                                            .then(() => {
                                                            creditorTable.refresh();
                                                            success({
                                                                timeout: 2,
                                                                message: translate('creditor.created')
                                                            });
                                                        });
                                                    }, children: translate('creditor.actions.newBlank') }) }))] }));
                                } })] })] }), _jsxs(Tabs, { sticky: 'top', id: "creditor-tabs", defaultActiveKey: "creditor-table", fill: true, children: [_jsx(Tab, { eventKey: "creditor-table", title: translate('creditor.allInvoices'), children: _jsx(CreditorTable, { instance: creditorTable, bulkManualPaymentsForm: bulkManualPaymentsForm, manualPaymentForm: manualPaymentForm, services: creditorServices, formData: formData, setFormData: setFormData, onRefresh: onRefresh }) }), _jsx(Tab, { eventKey: "bank-file-table", title: translate('creditor.process.bankFiles'), children: _jsx(BankFilesTable, { instance: bankFilesTable, creditorInvoiceInfoData: creditorInvoiceInfoData, dropCreditorFromBankFile: creditorServices.dropCreditorFromBankFile, onModalClose: () => {
                                setCreditorInvoiceInfoData(undefined);
                                bankFilesTable.refresh();
                                creditorTable.refresh();
                            } }) })] }), bulkInvoicesErrorModal && _jsx(BulkInvoicesErrorModal, { bulkBankFiles: creditorServices.bulkBankFiles, ...bankFileErrorModalData, bulkInvoicesErrorModalOpen: bulkInvoicesErrorModal, onClose: () => {
                    setBankFileErrorModalData(undefined);
                    setBulkInvoicesErrorModal(false);
                }, onRefresh: onRefresh }), _jsx(CreditorView, { creditorInvoiceId: creditorInvoiceDetailsData?.creditorInvoiceId, onClose: () => {
                    setCreditorInvoiceDetailsData(undefined);
                    creditorTable.refresh();
                    bankFilesTable.refresh();
                }, deleteCreditorPayment: creditorServices.deleteCreditorPayment, getCreditorInvoiceDetails: creditorServices.getCreditorInvoiceDetails, updateCreditorObjectData: creditorServices.updateCreditorObjectData, getAssetsByRentalObjectForDropdown: assetServices.getAssetsByRentalObjectForDropdown, createManualPayment: creditorServices.createManualPayment, refreshTable: creditorTable.refresh, openProcessForm: setFormData }), _jsx(PositionsInfoModal, { positions: positions, modalOpen: positionsModalOpen, onClose: () => {
                    setPositions([]);
                    setPositionModalOpen(false);
                }, onPropertyClick: openViewModal(ActiveView.PROPERTY), onRentalObjectCLick: openViewModal(ActiveView.RENTAL_OBJECT) }), uploadModalOpen && (_jsx(CreditorUploadModal, { onClose: () => {
                    setUploadModalOpen(false);
                    creditorTable.refresh();
                }, createCreditorsUsingUploads: creditorServices.createCreditorsUsingUploads }))] }));
};
