import { isObject } from '@f2w/utils';
import { defaultTheme } from '../defaultTheme';
export function tokenFromProps(tokens, props) {
    return Object.keys(tokens).find(name => !!props[name]);
}
export function tokenGetStyles(tokens, variant, defaultValue = undefined) {
    if (isObject(variant)) {
        variant = tokenFromProps(tokens, variant) || variant['variant'];
    }
    return tokens?.[variant] || tokens?.[defaultValue];
}
export function tokenBuilder() {
    return function createTokens(_tokens) {
        const _keys = Object.keys(_tokens);
        const _defaultKey = _keys.find(key => _tokens[key]?.isDefault) || _keys[0];
        const findTokenKey = (...args) => {
            let key = args.shift();
            if (key === true)
                return _defaultKey;
            if (_tokens[key])
                return key;
            if (isObject(key) && (key = _keys.find(k => !!key[k])))
                return key;
            if (args.length)
                return findTokenKey(...args);
        };
        function getToken(...keys) {
            const name = findTokenKey(...keys);
            if (!_tokens[name])
                return null;
            const { styles, ...tokenProps } = _tokens[name];
            return {
                ...tokenProps,
                $variant: name,
                styles,
                use: (opts) => {
                    const props = styles && styles(defaultTheme, opts);
                    return {
                        ...tokenProps,
                        ...props,
                    };
                },
            };
        }
        function getTokenStyles(key, props) {
            const { theme = defaultTheme, defaultKey = true, ...options } = { ...props };
            const token = getToken(key, defaultKey);
            return token?.styles(theme, options);
        }
        const api = {
            get tokens() {
                return _tokens;
            },
            get keys() {
                return _keys;
            },
            get(key, fallback) {
                return getToken(key, fallback);
            },
            getVariant(...keys) {
                return getToken(...keys);
            },
            map: (fn) => {
                return _keys.map(key => fn(key, _tokens[key]));
            },
            getStyles: (key, props) => {
                const { fonts, ...vars } = getTokenStyles(key, props);
                return { ...fonts, ...vars };
            },
            extend: (tokens) => {
                const newTokens = {};
                Object.keys(tokens).forEach(key => {
                    const prevStyles = _tokens[key]?.styles;
                    const newStyles = tokens[key]?.styles;
                    newTokens[key] = {
                        ..._tokens[key],
                        ...tokens[key],
                        styles: (theme, ...args) => {
                            return newStyles(prevStyles ? prevStyles(theme, ...args) : {}, theme, ...args);
                        }
                    };
                });
                return createTokens(newTokens);
            },
        };
        return api;
    };
}
