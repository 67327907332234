import generateRoute from "Services/Router";
import client from "Utils/client";
export const getProtocols = (rentalObjectId) => {
    const route = generateRoute('api.handovers.list.forRentalObject', { rentalObjectId: rentalObjectId });
    return client
        .get(route)
        .then(response => response.data);
};
export const getHandoverDetails = (id) => {
    const route = generateRoute('api.handovers.detail', {
        id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export default {
    getProtocols,
    getHandoverDetails,
};
