import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from "Atoms/Layout/components";
import { FlexCol, FlexRow } from "Atoms/Layout";
import { Icon } from "Atoms/Icon";
import { Text } from "Atoms/Text";
import { UseStyled } from "@f2w/theme/helpers/styled";
import { css } from "styled-components";
import { ActiveView, useObjectViewModal } from "Components/ObjectViewModal";
const Row = UseStyled.styled(FlexRow, ({ theme: { palette } }) => css `
    border-bottom: 1px solid ${palette.neutral.$100};
    cursor: pointer;
`);
export const AssetsTab = ({ view: { data } }) => {
    const { setCurrentActiveView } = useObjectViewModal();
    return (_jsx(Section, { children: _jsx(FlexCol, { gap: 0, children: data.assets.map(asset => (_jsxs(Row, { justifySpaceBetween: true, pt: 16, pb: 16, onClick: () => setCurrentActiveView(ActiveView.ASSET, asset.id), children: [_jsx(Text, { body: 400, children: asset.name }), _jsx(Icon, { icon: 'chevron-right' })] }, asset.id))) }) }));
};
