import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FlexRow } from 'Atoms/Layout';
import { translate } from 'Services/App';
import { DatePicker } from './DatePicker';
export function DateRangePicker({ minDate: min, maxDate: max, onChange, isClearable, ...iProps }) {
    const props = {
        minWidth: true,
        ...iProps,
    };
    return (_jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsx("span", { children: translate('from') }), _jsx("div", { children: _jsx(DatePicker, { ...props, isClearable: !!(isClearable && props.startDate != null), selectsStart: true, minDate: min, maxDate: props.endDate ?? max, onChange: (date, e, meta) => {
                        onChange?.({
                            startDate: date,
                            endDate: meta.endDate,
                        }, meta);
                    } }) }), _jsx("span", { children: translate('to') }), _jsx("div", { children: _jsx(DatePicker, { ...props, isClearable: !!(isClearable && props.endDate != null), selectsEnd: true, minDate: props.startDate ?? min, maxDate: max, onChange: (date, e, meta) => {
                        onChange?.({
                            startDate: meta.startDate,
                            endDate: date,
                        }, meta);
                    } }) })] }));
}
