import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { Div, FlexCol } from 'Atoms/Layout';
import { UseStyled } from '@fw/theme';
import { css } from 'styled-components';
import moment from 'moment/moment';
import { AbstractFormType, DateType } from '@f2w/form';
const WarningBlock = UseStyled.styled(Div, ({ theme: { palette, typography, rem } }) => (css `
    background: ${palette.warning.$100};
    color: ${palette.warning.$900};
    border: 1px solid ${palette.warning.$500};
    border-radius: ${rem(8)};
    padding: ${rem(18)} ${rem(26)};
    margin-bottom: ${rem(10)};
    ${typography.medium.fontStyles}
`));
export class AutomaticInvoicingType extends AbstractFormType {
    buildFields() {
        return {
            period: new DateType({
                label: translate('automaticInvoices.selectPeriod'),
                type: 'month',
                min: moment(this.options?.periodMin, 'YYYY'),
                max: moment(this.options?.periodMax, 'YYYY').endOf('year'),
            })
        };
    }
    _renderWidget(_iProps) {
        return (_jsxs(FlexCol, { gap: 5, children: [_jsx(Div, { w: 250, children: this.renderChild('period') }), _jsx(WarningBlock, { children: _jsxs(FlexCol, { gap: 5, children: [_jsx(Div, { children: translate('helper.automaticInvoices.info') }), _jsx(Div, { children: translate('automaticInvoices.helper.description') })] }) })] }));
    }
}
