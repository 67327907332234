import { generate } from "Services/Router";
import client from "Utils/client";
export var MaintenanceServices;
(function (MaintenanceServices) {
    MaintenanceServices.getMaintenance = async () => {
        const route = generate('api.admin.maintenance.get');
        const { data } = await client.get(route);
        return data;
    };
    MaintenanceServices.updateMaintenance = (id, data) => {
        const route = generate('api.admin.maintenance.update', {
            maintenance: id,
        });
        return client.post(route, data);
    };
    MaintenanceServices.toggleMaintenanceVisibility = (id) => {
        const route = generate('api.admin.maintenance.toggleVisibility', {
            maintenance: id,
        });
        return client.get(route);
    };
})(MaintenanceServices || (MaintenanceServices = {}));
