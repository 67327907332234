import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Button } from 'Atoms/Button/Button';
import { CopyToClipboard } from 'Atoms/Clipboard/CopyToClipboard';
import { Tooltip2 } from 'Atoms/Overlay';
import useTimeout from '@restart/hooks/useTimeout';
import { translate } from 'Services/App';
import { isString } from '@f2w/utils';
export const ClipboardButton = ({ clipboard, as: Component = Button, ...props }) => {
    const [copied, setCopied] = useState(false);
    const { set, clear } = useTimeout();
    const onCopy = (e) => {
        if (!copied) {
            setCopied(true);
            set(() => setCopied(false), 2000);
        }
    };
    const key = copied ? 'atoms.clipboard.copied' : 'atoms.clipboard.copy';
    const clipboardProps = isString(clipboard) ? { value: clipboard } : clipboard;
    return (_jsx(Tooltip2, { size: 'sm', placement: 'top', flip: true, content: translate(key), children: _jsx(CopyToClipboard, { ...clipboardProps, onCopy: onCopy, children: _jsx(Component, { ...props, "data-trigger": 'clipboard', icon: copied ? 'check' : 'copy' }) }) }, key));
};
