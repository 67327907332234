import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import AccountingRecordJournalTableModel from '../models/AccountingRecordJournalTableModel';
import { Col, Row } from 'Components/Atoms';
import { useModalDispatcherConfirm, useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
export const useAccountingRecordJournalTable = (fiscalYearId, initialDateRange, onNewAccountingRecordClicked, onEditClicked, accountingRecordServices, searchQuery) => {
    const tableModel = React.useMemo(() => AccountingRecordJournalTableModel.create({
        manualControl: true,
        FiltersLayout: ({ renderFilter }) => (_jsxs(Row, { children: [_jsx(Col, { md: 12, lg: 6, children: renderFilter('date') }), _jsx(Col, { md: 12, lg: 6, children: renderFilter('amount') })] })),
        initialState: {
            filters: [
                {
                    id: 'fiscalYear',
                    value: fiscalYearId
                },
                {
                    id: 'date',
                    value: initialDateRange
                }
            ],
            sortBy: [
                {
                    id: 'date',
                    desc: true,
                }
            ],
            globalFilter: searchQuery,
        }
    }), []);
    const getData = React.useCallback(({ request, }) => {
        const mappedRequest = { ...request };
        if (request.filters.date) {
            mappedRequest.filters.dateRange = request.filters.date;
            request.filters.date = undefined;
        }
        if (request.filters.amount) {
            mappedRequest.filters.amountRange = request.filters.amount;
            request.filters.amount = undefined;
        }
        return accountingRecordServices.listAccountingRecords(mappedRequest);
    }, []);
    const { success, error } = useToastDispatcherApi();
    const onDeleteFiscalYear = React.useCallback((accountingRecordId, refresh) => {
        accountingRecordServices.deleteAccountingRecord(accountingRecordId)
            .then(() => {
            success({
                timeout: 2,
                message: translate('flash.accounting.journal.recordDeletionSuccess')
            });
            refresh();
        })
            .catch(({ response }) => {
            const errorMessage = (response.status === 400)
                ? response.data.message
                : translate('flash.accounting.journal.recordDeletionFailure');
            error({
                timeout: 5,
                message: errorMessage
            });
        });
    }, []);
    const onDeleteConfirmation = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: onDeleteFiscalYear,
        message: translate('accountingRecord.delete.areYouSure'),
        help: translate('accountingRecord.delete.confirmation'),
        btnConfirm: translate('btn.confirm')
    });
    const instance = tableModel.useTable({
        getData,
        onDeleteClicked: onDeleteConfirmation,
        onEditClicked: onEditClicked,
        onNewAccountingRecordClicked
    });
    return {
        instance
    };
};
