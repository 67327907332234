import * as rt from 'react-table';
export const useGlobalFilter = {
    initOptions: (opts, table) => {
        return {
            disableGlobalFilter: false,
            autoResetGlobalFilter: false,
            defaultColumn: {
                disableGlobalFilter: false,
            },
        };
    },
    createOptions: (opts, table) => {
        return {
            manualGlobalFilter: !!table?.manualControl,
        };
    },
    createColumnOptions: ({ enabled, ...options }, column, defaultColumn) => {
        const _defaults = {};
        if (column.isCustom)
            _defaults.disableGlobalFilter = options.disableGlobalFilter ?? true;
        return {
            ..._defaults,
            ...options,
        };
    },
    hook: hooks => {
        rt.useGlobalFilter(hooks);
    },
};
