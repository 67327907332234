import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import usePopperMarginModifiers from 'Components/Base/usePopperMarginModifiers';
import useWrappedRefWithWarning from 'Components/Base/useWrappedRefWithWarning';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import { useDropdownMenu } from 'react-overlays/DropdownMenu';
import warning from 'warning';
import { remove } from 'dom-helpers';
import classNames from 'classnames';
import { useTheme } from '@f2w/theme';
import { useTargetContainer } from 'Hooks/useTargetContainer';
import { dropdownSlot } from '../Dropdown.theme';
export const DropdownMenu = forwardRef(({ variant: initialVariant, show: _initialShow, flip = true, fixed, container, scrollable = true, alignRight: _initialAlignEnd = false, align = 'left', offset, maxWidth, maxHeight, className, rootCloseEvent, renderOnMount, isNested, popperConfig, as: Component, children, ...props }, ref) => {
    const [popperRef, marginModifiers] = usePopperMarginModifiers({ offset });
    const theme = useTheme();
    const alignClasses = [];
    if (align) {
        if (typeof align === 'object') {
            const keys = Object.keys(align);
            warning(keys.length === 1, 'There should only be 1 breakpoint when passing an object to `align`');
            if (keys.length) {
                const brkPoint = keys[0];
                const direction = align[brkPoint];
                _initialAlignEnd = direction === 'left';
                alignClasses.push(`${prefix}-${brkPoint}-${direction}`);
            }
        }
        else if (align === 'right') {
            _initialAlignEnd = true;
        }
    }
    const [menuProps, { hasShown, popper, show, alignEnd, toggle, arrowProps },] = useDropdownMenu({
        show: _initialShow,
        flip,
        fixed,
        rootCloseEvent,
        alignEnd: _initialAlignEnd,
        usePopper: alignClasses.length === 0,
        offset,
        popperConfig: {
            ...popperConfig,
            modifiers: marginModifiers.concat(popperConfig?.modifiers || []),
        },
    });
    const elRef = useRef();
    useEffect(() => {
        let menuEl, targetEl;
        if (elRef.current && container === true) {
            if (!isNested) {
                targetEl = useTargetContainer.find(elRef.current);
            }
            else {
                targetEl = useTargetContainer.find(elRef.current, '.fw-menu--root');
            }
        }
        targetEl && targetEl.appendChild(menuEl = elRef.current);
        return () => {
            menuEl && remove(menuEl);
        };
    }, [hasShown, renderOnMount, container, scrollable]);
    const menuRef = useMergedRefs(popperRef, useMergedRefs(elRef, useMergedRefs(useWrappedRefWithWarning(ref, 'DropdownMenu'), menuProps.ref)));
    if (!hasShown && !renderOnMount)
        return null;
    if (typeof Component !== 'string') {
        menuProps.show = show;
        menuProps.close = () => toggle?.(false);
        menuProps.alignRight = alignEnd;
    }
    if (show && props.style?.opacity == 1) {
        menuProps.style.opacity = 1;
    }
    let style = {
        ...props.style,
    };
    if (popper?.placement) {
        style = { ...style, ...menuProps.style };
        props['x-placement'] = popper.placement;
    }
    const variant = theme.mode ?? 'light';
    return (_jsx(DropdownMenuRoot, { ...dropdownSlot.attr('menu'), ...props, ...menuProps, ref: menuRef, as: Component, style: style, className: classNames(className, prefix, 'fw-menu', {
            [`fw-menu--${variant}`]: variant,
            'fw-menu--scrollable': scrollable,
            'fw-menu--root': !isNested,
            'show': show,
            [`${prefix}-right`]: alignEnd,
        }, ...alignClasses), children: _jsx("div", { style: { maxWidth, maxHeight }, children: children }) }));
});
const prefix = 'fw-dropdown-menu';
export const DropdownMenuRoot = styled.div(({ theme: { palette, typography }, }) => css `
  && {
    ${typography.small.fontStyles};

    .fw-dropdown-header {
      padding: 7px 6px;
      ${typography.pretitle.css({ fontFamily: undefined, fontWeight: 600 })};
      color: var(--menu-header-color);
    }
  }
`);
DropdownMenu.displayName = 'DropdownMenu';
