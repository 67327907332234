import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Body, Container, Div, FlexRow, HeaderBase } from 'Atoms/Layout';
import { Title } from 'Atoms/Text';
import { Button } from 'Atoms/Button';
import styled from 'styled-components';
const PageHeaderRoot = styled(HeaderBase) `
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    flex-wrap: wrap;
    gap: 10px;
`;
export const PageHeader = ({ title, side, backLink, ...props }) => {
    if (!(title || side))
        return null;
    const _title = title && _jsx(Title, { as: 'h1', size: 'h3', children: title });
    return (_jsxs(PageHeaderRoot, { ...props, children: [backLink ? (_jsxs(FlexRow, { alignCenter: true, justifyStart: true, children: [_jsx(Button, { icon: 'arrow-left', variant: 'link', color: 'neutral', href: backLink, style: { padding: 0, fontSize: '23px', justifyContent: 'start', marginLeft: -4 } }), _title] })) : (_title), side && _jsx(Div, { ml: 'auto', children: side })] }));
};
function PageLayout({ children, preview, }) {
    const hasPreview = !!preview;
    return _jsxs(Body, { split: hasPreview, style: { height: '100%' }, paddingX: '25px', children: [_jsx(Container, { maxWidth: '800px', children: children }), hasPreview && (_jsx(Container, { bg: 'neutral.$50', style: { borderLeft: '1px solid #d7d8db' }, children: preview }))] });
}
(function (PageLayout) {
    PageLayout.Header = PageHeader;
})(PageLayout || (PageLayout = {}));
export { PageLayout };
