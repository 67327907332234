import generateRoute from "Services/Router";
import client from "Utils/client";
export var FiscalYearServices;
(function (FiscalYearServices) {
    FiscalYearServices.listFiscalYears = (params) => {
        const route = generateRoute('fiscalYear.list');
        return client
            .get(route, { params })
            .then(response => response.data);
    };
    FiscalYearServices.getCreateFiscalYearSuggestion = async (propertyId) => {
        const route = generateRoute('api.fiscalYears.getCreateSuggestion', {
            property: propertyId
        });
        const response = await client.get(route);
        return await response.data;
    };
    FiscalYearServices.getFiscalYearById = async (id) => {
        const route = generateRoute('api.fiscalYear.details', {
            fiscalYearId: id
        });
        const response = await client.get(route);
        return await response.data;
    };
    FiscalYearServices.updateFiscalYear = (id, data) => {
        const route = generateRoute('api.fiscalYear.update', { fiscalYear: id });
        return client.post(route, data);
    };
    FiscalYearServices.createFiscalYear = (data) => {
        return client
            .put(generateRoute('api.fiscalYear.create'), data)
            .then(response => response.data);
    };
    FiscalYearServices.deleteFiscalYear = (id) => {
        const route = generateRoute('api.fiscalYear.delete', {
            fiscalYearId: id
        });
        return client.delete(route);
    };
    FiscalYearServices.toggleLockFiscalYear = (id) => {
        const route = generateRoute('api.fiscalYear.toggleLock', {
            fiscalYear: id
        });
        return client.post(route);
    };
    FiscalYearServices.refreshOpeningBalances = (fiscalYearId) => {
        const route = generateRoute('api.fiscalYears.refreshOpeningBalances', {
            fiscalYear: fiscalYearId
        });
        return client.patch(route);
    };
})(FiscalYearServices || (FiscalYearServices = {}));
export default FiscalYearServices;
