import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { ExportCustomerDocumentsType } from "Pages/CustomerDocuments/types/ExportCustomerDocumentsType";
import { translate } from "Services/App";
import { Button, ButtonToolbar } from "Components/Atoms";
export const useExportCustomerDocuments = ({ exportCustomerDocuments }) => useFormModal({
    name: 'export-customer-documents-type',
    type: () => new ExportCustomerDocumentsType(),
    title: translate('customerDocuments.export.title'),
    successMessage: translate('customerDocuments.exportSuccess.body'),
    onLoad: async (data) => {
        return {
            ...data
        };
    },
    onSave: (values) => {
        return exportCustomerDocuments({
            mandateId: values.mandateId,
            propertyId: values.propertyId,
            buildingId: values.buildingId,
            rentalObjectId: values.rentalObjectId,
            contractId: values.contractId,
            category: values.category,
            createdAtRange: !values.createdAtRange?.start && !values.createdAtRange?.end
                ? null
                : values.createdAtRange
        });
    },
    Footer: ({ saveForm, closeForm, form: { loading, values } }) => {
        return _jsxs(ButtonToolbar, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, onClick: async () => await saveForm(true), children: translate('btn.export') })] });
    },
});
