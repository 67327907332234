import { jsx as _jsx } from "react/jsx-runtime";
import { ContractState } from "Enums/ContractState";
import { Badge } from "Atoms/Badge";
import { translate } from "Services/App";
export const ContractStateBadge = ({ state, isVacancy }) => {
    if (isVacancy === true) {
        return _jsx(VacancyContractBadge, { state: state });
    }
    else {
        return _jsx(OccupiedContractBadge, { state: state });
    }
};
const OccupiedContractBadge = ({ state }) => {
    const colorMap = {
        [ContractState.ACTIVE]: 'success',
        [ContractState.UPCOMING]: 'primary',
        [ContractState.TERMINATED]: 'dark',
        [ContractState.NOTICED]: 'warning'
    };
    const color = colorMap[state] || 'primary';
    return _jsx(Badge, { "$color": color, children: translate(`contract.state.${state}`) });
};
const VacancyContractBadge = ({ state }) => {
    const colorMap = {
        [ContractState.ACTIVE]: 'dangerOutline',
        [ContractState.UPCOMING]: 'primaryOutline',
        [ContractState.TERMINATED]: 'darkOutline',
    };
    const color = colorMap[state] || 'primaryOutline';
    return _jsx(Badge, { "$color": color, children: translate(`contract.overview.vacancy`) });
};
