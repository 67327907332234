import { useFormModal } from "@f2w/form";
import { AncillaryExpenseDuplicateAccountsType } from "../types/AncillaryExpenseDuplicateAccountsType";
import { translate } from "Services/Translator";
import { AccountServices } from "../index";
export const useAncillaryExpenseDuplicateAccountsForm = ({ onSaved, accountServices = AccountServices, }) => {
    return useFormModal({
        name: 'duplicate-ancillary-expense-accounts-form',
        type: () => new AncillaryExpenseDuplicateAccountsType(),
        title: translate('ancillaryExpenseAccount.duplicate'),
        onLoad: async (propertyId) => {
            if (!propertyId) {
                return undefined;
            }
            return { sourcePropertyId: propertyId };
        },
        onSave: (values) => {
            accountServices
                .duplicateAccount(values)
                .then(onSaved);
        }
    });
};
