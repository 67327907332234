import { jsx as _jsx } from "react/jsx-runtime";
import { Header } from "Atoms/Layout/components";
import React from "react";
import { FlexCol, Icon } from "Components/Atoms";
export const Step = ({ onStepClick, isPassed, stepNumber, ...rest }) => {
    const styles = React.useMemo(() => {
        const styles = {
            margin: 0,
            cursor: 'pointer'
        };
        if (isPassed) {
            styles.color = '#FFFFFF';
            styles.background = '#16AC88';
        }
        else if (!onStepClick) {
            styles.color = '#FFFFFF';
            styles.background = '#D7D8DB';
            styles.cursor = 'default';
        }
        return styles;
    }, [onStepClick, isPassed]);
    return (_jsx(FlexCol, { ...rest, children: _jsx(Header.AvatarRoot, { style: styles, onClick: onStepClick, children: isPassed ? _jsx(Icon, { icon: "check" }) : stepNumber }) }));
};
