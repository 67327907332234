import { ProfileEditType } from "Pages/Profile/Profile/ProfileEditType";
import { SelectType } from "@f2w/form";
import { translate } from "Services/Translator";
import React, { useState } from "react";
import { CustomerServices } from "Pages/Administration/Customers/services";
export class EditCustomerUserType extends ProfileEditType {
    roles = [];
    mandates = [];
    _useField(props) {
        super._useField(props);
        const [roles, setRoles] = useState([]);
        const [mandates, setMandates] = React.useState([]);
        this.roles = roles;
        this.mandates = mandates;
        React.useEffect(() => {
            CustomerServices.getCustomerUserRoles(this.field.value.id)
                .then(data => {
                setRoles(data.map(role => ({
                    label: role.name,
                    value: role.id
                })));
            });
            CustomerServices.getCustomerUserMandates(this.field.value.id)
                .then(data => {
                setMandates(data.map(mandate => ({
                    label: mandate.name,
                    value: mandate.id,
                })));
                if (this.children.mandates.field.value.length === 0) {
                    this.children.mandates.field.setValue(data.map(mandate => mandate.id));
                }
            });
        }, []);
    }
    buildFields() {
        const { gender, firstName, lastName, email, cellPhoneNumber, dateOfBirth, jobTitle, preferredLanguage } = super.buildFields();
        return {
            gender,
            firstName,
            lastName,
            email,
            cellPhoneNumber,
            dateOfBirth,
            jobTitle,
            preferredLanguage,
            assignedRoles: new SelectType({
                label: translate('user.roles'),
                required: true,
                multiple: true,
                choiceOptions: () => this.roles
            }),
            mandates: new SelectType({
                label: translate('user.mandates'),
                required: false,
                nullable: false,
                multiple: true,
                choiceOptions: () => this.mandates,
            }),
        };
    }
}
