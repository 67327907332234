import { jsx as _jsx } from "react/jsx-runtime";
import { NumberValueType } from '../../core';
import { InputField } from '../../theme';
import { SimpleType } from './BaseSimpleType';
import { FormControl } from 'Atoms/Input';
import { FloorsRange } from "Pages/BaseData/types";
export class NumberType extends SimpleType {
    specs = this._getSpecs();
    constructor(options) {
        super(options);
        this.__construct();
    }
    _getSpecs() {
        return new NumberValueType({});
    }
    _renderForm(valueType, props) {
        const controlProps = {
            step: valueType.options.step,
            min: valueType.options.min,
            max: valueType.options.max,
        };
        return _jsx(InputField, { ...props, Widget: ({ field, ...props }) => {
                if (valueType.options.step > 0) {
                    return _jsx(FormControl.Number, { ...props, ...controlProps });
                }
                return _jsx(FormControl.Input, { type: 'number', ...props, ...controlProps });
            } });
    }
}
export class FloorType extends NumberType {
    constructor(options) {
        super({
            ...options,
            min: -99,
            max: 99,
            step: 1,
        });
    }
    _getSpecs() {
        const specs = super._getSpecs();
        specs.options.min = FloorsRange.MIN;
        specs.options.max = FloorsRange.MAX;
        specs.options.step = 1;
        return specs;
    }
}
