import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useViewType } from "@f2w/view";
import { FormError } from "@f2w/form";
import { translate } from "Services/Translator";
import { GeneralTab } from "Pages/BankAccount/components/DetailView/GeneralTab";
import { PropertiesTab } from "Pages/BankAccount/components/DetailView/PropertiesTab";
import { ConnectionsTab } from "Pages/BankAccount/components/DetailView/ConnectionsTab";
import { BankAccountTemplate } from "Pages/BankAccount/components/DetailView/BankAccountView";
import { BankAccountType } from "../enums";
import { BankAccountViewFormType } from "../types/BankAccountViewFormType";
import { Button } from "Atoms/Button";
export const useBankAccountView = (reloadTable, getBankAccountDetails, updateRentBankAccount, updatePersonBankAccount, updateCreditBankAccount, updateBankAccountProperties) => useViewType(() => new BankAccountViewFormType(), {
    id: 'bank-account-view',
    defaultState: {
        leftActiveTab: 'general',
        editPropertiesOpen: false,
        onSave: async (view) => { },
        onClose: (view) => { }
    },
    DefaultTemplate: (props) => _jsx(BankAccountTemplate, { ...props }),
    load: async ({ id }) => {
        const data = await getBankAccountDetails(id);
        return {
            ...data,
            propertiesRefs: data.propertiesRefs.map(propertyRef => ({
                ...propertyRef,
                propertyId: propertyRef.property.id,
                financialAccountId: propertyRef.financialAccount.id
            })),
            personId: data.person?.id,
            creditorFinancialAccountId: data.creditorFinancialAccount?.id
        };
    },
    saveError: (error, view) => {
        const { data, status } = error?.response ?? {};
        if (status === 422 || data?.message) {
            return new FormError(data.message);
        }
        return error;
    },
}, () => ({
    general: {
        name: translate('bank.detailView.generalTab'),
        Content: (props) => _jsx(GeneralTab, { ...props }),
        Form: ({ type, data }) => {
            switch (data.type) {
                case BankAccountType.CREDIT: return type.renderChild('generalCredit');
                case BankAccountType.TENANT: return type.renderChild('generalTenant');
                case BankAccountType.DEPOSIT: return type.renderChild('generalDeposit');
                case BankAccountType.RENT: return type.renderChild('generalRent');
            }
        },
        save: async (values, view) => {
            switch (values.type) {
                case BankAccountType.CREDIT: {
                    const data = view.type.getChild('generalCredit').getResolvedValue();
                    return updateCreditBankAccount(values.id, data)
                        .then(data => {
                        view.updateData({
                            ...data,
                            creditorFinancialAccountId: data.creditorFinancialAccount?.id
                        });
                        reloadTable();
                    });
                }
                case BankAccountType.DEPOSIT: {
                    const data = view.type.getChild('generalDeposit').getResolvedValue();
                    return updatePersonBankAccount(values.id, data)
                        .then(data => {
                        view.updateData({
                            ...data,
                            personId: data.person?.id
                        });
                        reloadTable();
                    });
                }
                case BankAccountType.TENANT: {
                    const data = view.type.getChild('generalTenant').getResolvedValue();
                    return updatePersonBankAccount(values.id, data)
                        .then(data => {
                        view.updateData({
                            ...data,
                            personId: data.person?.id
                        });
                        reloadTable();
                    });
                }
                case BankAccountType.RENT: {
                    const data = view.type.getChild('generalRent').getResolvedValue();
                    return updateRentBankAccount(values.id, data)
                        .then(data => {
                        view.updateData(data);
                        reloadTable();
                    });
                }
            }
        },
    },
    properties: {
        name: translate('bank.detailView.propertiesTab'),
        Content: (props) => _jsx(PropertiesTab, { ...props, updateBankAccountProperties: updateBankAccountProperties }),
        ContentHeader: ({ view }) => {
            if (!view.state.editPropertiesOpen) {
                return (_jsx(Button.Edit, { onClick: () => view.setState({
                        ...view.state,
                        editPropertiesOpen: true
                    }) }));
            }
            return (_jsxs(_Fragment, { children: [_jsx(Button.Cancel, { variant: 'link', onClick: () => view.state.onClose(view) }), _jsx(Button.Save, { icon: 'save', onClick: () => view.state.onSave(view) })] }));
        },
    },
    connections: {
        name: translate('bank.detailView.connectionsTab'),
        Content: (props) => _jsx(ConnectionsTab, { ...props })
    },
}));
