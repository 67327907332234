export function _getObjectTag(value) {
    return value != null
        ? Object.prototype.toString.call(value)
        : value === undefined ? '[object Undefined]' : '[object Null]';
}
export function classInterface(defaults) {
    return class {
        constructor() {
            Object.assign(this, defaults || {});
        }
    };
}
export function classExtendInterface(base) {
    return function (defaults) {
        return class extends base {
            constructor(...a) {
                super(...a);
                Object.assign(this, defaults || {});
            }
        };
    };
}
export var Prototype;
(function (Prototype) {
    Prototype.get = (value) => Object.getPrototypeOf(value);
    const parents = (proto) => {
        const result = [];
        while (Prototype.get(proto) !== null)
            result.push(proto = Prototype.get(proto));
        return result;
    };
    Prototype.has = (proto, filter) => {
        while (Prototype.get(proto) !== null) {
            if (filter(proto))
                return true;
            proto = Prototype.get(proto);
        }
        return false;
    };
    Prototype.getRoot = (proto) => {
        while (Prototype.get(proto) !== null)
            proto = Prototype.get(proto);
        return proto;
    };
})(Prototype || (Prototype = {}));
