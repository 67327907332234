import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, FlexRow, Icon } from 'Components/Atoms';
import { Header, Section } from 'Atoms/Layout/components';
import { translate } from 'Services/Translator';
import { CreditorInvoiceState } from 'Pages/Creditor/components/CreditorInvoiceState';
import { CreditorDetails } from 'Pages/Creditor/components/CreditorView/components/CreditorDetails';
import { CreditorPayments } from 'Pages/Creditor/components/CreditorView/components/CreditorPayments';
import { CreditorState } from 'Pages/Creditor/enums';
import { CreditorAccountingRecords } from 'Pages/Creditor/components/CreditorView/components/CreditorAccountingRecords';
export const CreditorPaymentContent = ({ creditorInvoice, updateCreditorObjectData, deleteCreditorPayment, refreshDetails, getAssetsByRentalObjectForDropdown, onOpenNewPaymentForm, onOpenEditForm }) => {
    return (_jsxs(_Fragment, { children: [_jsxs(Header, { children: [_jsx(Header.Avatar, { title: _jsx(_Fragment, { children: translate('creditorInvoice') }), actions: _jsx(_Fragment, { children: _jsx(CreditorInvoiceState, { state: creditorInvoice.state }) }) }), _jsx(FlexRow, { justifyFlexEnd: true, mt: 20, children: _jsxs(Button, { variant: 'outline', onClick: onOpenEditForm, disabled: [
                                CreditorState.QR_PENDING,
                                CreditorState.BANK_FILE_CREATED,
                                CreditorState.PAID,
                                CreditorState.OVERPAID,
                                CreditorState.PARTIALLY_PAID
                            ].includes(creditorInvoice.state), children: [_jsx(Icon, { icon: 'edit' }), translate('btn.edit')] }) })] }), _jsx(Section, { children: _jsx(CreditorDetails, { creditorInvoice: creditorInvoice, updateCreditorObjectData: updateCreditorObjectData, getAssetsByRentalObjectForDropdown: getAssetsByRentalObjectForDropdown, refreshDetails: refreshDetails }) }), _jsx(Section, { children: _jsx(CreditorAccountingRecords, { accountingRecords: creditorInvoice.accountingRecords }) }), _jsx(Section, { children: _jsx(CreditorPayments, { creditorInvoiceId: creditorInvoice.id, hasBankFile: creditorInvoice.hasBankFile, payments: creditorInvoice.payments, deleteCreditorPayment: deleteCreditorPayment, refreshDetails: refreshDetails, onOpenNewPaymentForm: onOpenNewPaymentForm, creditorState: creditorInvoice.state }) })] }));
};
