import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormDebugInfo } from '@f2w/form';
import { Body, Container } from 'Atoms/Layout/components';
import { FormView as BaseFormTemplate } from './templates';
import { useTabView } from '../hooks';
export function SplitViewTemplate(props) {
    const { formChildren, leftSide, rightSide, FormTemplate, ...defaultContentProps } = props;
    const [renderLeft] = useTabView({
        ...defaultContentProps,
        ...leftSide,
        stateKey: 'leftActiveTab',
    });
    const [renderRight] = useTabView({
        ...defaultContentProps,
        ...rightSide,
        stateKey: 'rightActiveTab',
    });
    return (_jsxs(Body, { split: true, children: [_jsx(Container, { children: SplitViewTemplate.renderForm(props, renderLeft) }), _jsx(Container, { children: SplitViewTemplate.renderDebug(props, renderRight) })] }));
}
SplitViewTemplate.renderForm = ({ view, formChildren, FormTemplate = BaseFormTemplate }, render) => !view.isEditingEnabled ? render() : formChildren
    ? _jsx(FormTemplate, { view: view, formChildren: formChildren })
    : view.renderForm(FormTemplate);
SplitViewTemplate.renderDebug = ({ view, formChildren }, render) => view.isDebugging && formChildren
    ? _jsx(FormDebugInfo, { form: view.form, styles: { maxh: '100%' }, additionalTabs: [
            {
                key: 'Content',
                content: render
            }
        ] })
    : render();
