import { getOrientation } from 'get-orientation/browser';
import { createImage, readFile } from '@f2w/utils';
function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}
function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);
    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
}
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false }) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return null;
    }
    const rotRad = getRadianAngle(rotation);
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);
    ctx.drawImage(image, 0, 0);
    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');
    if (!croppedCtx) {
        return null;
    }
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;
    croppedCtx.drawImage(canvas, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);
    return new Promise((resolve, reject) => {
        croppedCanvas.toBlob((file) => {
            resolve(file);
        }, 'image/jpeg');
    });
}
export async function getImageSrc(file, options) {
    let imageSrc = await readFile(file);
    try {
        const orientation = await getOrientation(file);
        return getImageSrc.resolveRotation(imageSrc, orientation);
    }
    catch (e) {
        console.warn('failed to detect the orientation');
    }
    return Promise.resolve(imageSrc);
}
(function (getImageSrc) {
    const ORIENTATION_TO_ANGLE = {
        '3': 180,
        '6': 90,
        '8': -90,
    };
    async function resolveRotation(imageSrc, orientation = null) {
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (!rotation) {
            return Promise.resolve(imageSrc);
        }
        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
        if (orientationChanged) {
            canvas.width = image.height;
            canvas.height = image.width;
        }
        else {
            canvas.width = image.width;
            canvas.height = image.height;
        }
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
        return new Promise((resolve) => {
            canvas.toBlob((file) => {
                resolve(URL.createObjectURL(file));
            }, 'image/png');
        });
    }
    getImageSrc.resolveRotation = resolveRotation;
})(getImageSrc || (getImageSrc = {}));
