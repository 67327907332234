import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { translate } from 'Services/App';
import { BaseDataServices } from './services';
import { RentalObjectDataTableType } from './table/RentalObjectDataTableType';
import { useOpenRentalObject } from './hooks/useOpenRentalObject';
import { useObjectViewModalContext } from "Components/ObjectViewModal";
export const RentalObjectPage = (props) => {
    useOpenRentalObject(props);
    const table = RentalObjectDataTableType.useTable({}, () => ({
        manualControl: true,
        getData: ({ request }) => BaseDataServices.getBaseDataRentalObjectsList(request),
    }));
    const { activeView } = useObjectViewModalContext();
    const initialLoad = React.useRef(true);
    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        if (!activeView) {
            table.refresh();
        }
    }, [activeView]);
    return (_jsx(PageOverview, { instance: table, variant: 'content', title: translate("rentalObjectPage.title"), inlineFilters: true }));
};
