function isPlainObject(item) {
    return item !== null && typeof item === 'object' && item.constructor === Object;
}
export function extend(...args) {
    let options, name, src, copy, copyIsArray, clone, target = args[0] || {}, i = 1, length = args.length, deep = false;
    if (typeof target === "boolean") {
        deep = target;
        target = args[1] || {};
        i = 2;
    }
    if (typeof target !== "object" && !(typeof target === 'function')) {
        target = {};
    }
    if (length === i) {
        target = this;
        --i;
    }
    for (; i < length; i++) {
        if ((options = args[i]) != null) {
            for (name in options) {
                src = target[name];
                copy = options[name];
                if (target === copy) {
                    continue;
                }
                if (deep && copy && (isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))) {
                    if (copyIsArray) {
                        copyIsArray = false;
                        clone = src && Array.isArray(src) ? src : [];
                    }
                    else {
                        clone = src && isPlainObject(src) ? src : {};
                    }
                    target[name] = extend(deep, clone, copy);
                }
                else if (copy !== undefined) {
                    target[name] = copy;
                }
            }
        }
    }
    return target;
}
export function deepmerge(target, source, options = { clone: false }) {
    const output = options.clone ? { ...target } : target;
    if (isPlainObject(target) && isPlainObject(source)) {
        Object.keys(source).forEach((key) => {
            if (key === '__proto__') {
                return;
            }
            if (isPlainObject(source[key]) && key in target && isPlainObject(target[key])) {
                output[key] = deepmerge(target[key], source[key], options);
            }
            else {
                output[key] = source[key];
            }
        });
    }
    return output;
}
