import { parseJson } from 'Utils/parseJson';
import { useMemo } from 'react';
import { LocalStorage } from '@f2w/utils';
export class NavigationSettings {
    storageId;
    collapsed;
    constructor(storageId) {
        this.storageId = `${storageId || 'nav'}/collapsed`;
        this.collapsed = parseJson(localStorage.getItem(this.storageId)) ?? false;
        this.collapsed = LocalStorage.get(this.storageId, false);
    }
    isCollapsed() {
        return this.collapsed;
    }
    setCollapsed(collapsed) {
        if (this.isCollapsed() === collapsed)
            return;
        this.collapsed = collapsed;
        LocalStorage.set(this.storageId, collapsed);
    }
    static use(key) {
        return useMemo(() => new NavigationSettings(key), [key]);
    }
}
