import { toString } from 'Utils';
import { mimeTypes } from './mimeDb';
export class Mime {
    types;
    extensions;
    unknownTypes;
    constructor(...types) {
        const [contentTypes, unknownTypes] = resolveList(types);
        this.unknownTypes = unknownTypes;
        this.types = Object.keys(contentTypes);
        this.extensions = Object.values(contentTypes).flat(2);
    }
    hasContentType(type) {
        return this.types.includes(type);
    }
    hasExtension(type) {
        return this.types.includes(type);
    }
    accept(file) {
        return Mime.accept(file, this.types);
    }
    static accept({ name: fileName = '', type = '' }, ...types) {
        const acceptedTypes = types.flat(4);
        if (!acceptedTypes.length)
            return true;
        const mimeType = type.toLowerCase();
        const baseMimeType = mimeType.replace(/\/.*$/, '');
        return acceptedTypes.some((type) => {
            const validType = type.trim().toLowerCase();
            if (validType.charAt(0) === '.') {
                return fileName.toLowerCase().endsWith(validType);
            }
            else if (validType.endsWith('/*')) {
                return baseMimeType === validType.replace(/\/.*$/, '');
            }
            return mimeType === validType;
        });
    }
}
const resolveList = (...args) => {
    const _types = mimeTypes;
    const types = {};
    const addType = (type) => (types[type] ?? (types[type] = []));
    const unknownTypes = args
        .flat(5)
        .map((val) => {
        let match = val = toString(val).toLowerCase().trim();
        if (match in _types) {
            addType(match).push(..._types[match]);
            return;
        }
        if (match.endsWith('/*')) {
            addType(match);
            match = match.slice(0, -1);
        }
        if (Object
            .entries(_types)
            .map(([type, ext]) => ((type.startsWith(match) || ext.includes(match)) && addType(type).push(...ext)))
            .filter(v => v)
            .length)
            return;
        return val;
    })
        .filter(v => v);
    return [
        types, unknownTypes
    ];
};
