import { DataTable } from '@f2w/data-table';
import { translate } from "Services/Translator";
const BaseTable = DataTable.factory()(() => ({
    columns: {
        name: {
            Header: translate('ancillaryExpenseAccount.table.title'),
            Cell: ({ value }) => value,
            useSortBy: {}
        },
        unit: {
            Header: translate('ancillaryExpenseAccount.table.unit'),
            Cell: ({ value }) => translate('unit.' + value),
            useSortBy: {}
        },
        costType: {
            Header: translate('ancillaryExpenseAccount.costType'),
            Cell: ({ value }) => translate('ancillaryExpenseAccount.costType.' + value),
            useSortBy: {}
        },
    },
}));
export const ImportAccountsTable = Object.assign(BaseTable, {
    use: ({ importAccounts }) => {
        return BaseTable.useCreateTable({
            data: importAccounts,
        });
    },
});
