import { generate } from "Services/Router";
import client from "Utils/client";
export var FeatureFlagServices;
(function (FeatureFlagServices) {
    FeatureFlagServices.createFeatureFlag = (data) => {
        const route = generate('api.admin.featureFlags.create');
        return client.post(route, data);
    };
    FeatureFlagServices.updateFeatureFlag = (id, data) => {
        const route = generate('api.admin.featureFlags.update', {
            featureFlag: id
        });
        return client.put(route, data);
    };
    FeatureFlagServices.deleteFeatureFlag = (id) => {
        const route = generate('api.admin.featureFlags.delete', {
            featureFlag: id
        });
        return client.delete(route);
    };
    FeatureFlagServices.listFeatureFlags = async (params) => {
        const route = generate('api.admin.featureFlags.list');
        return client
            .get(route, { params })
            .then(res => res.data);
    };
    FeatureFlagServices.getAvailableFeatures = async () => {
        const route = generate('api.admin.featureFlags.availableFeatures');
        return client
            .get(route)
            .then(res => res.data);
    };
})(FeatureFlagServices || (FeatureFlagServices = {}));
