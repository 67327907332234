import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Schema, SimpleType } from '../core';
import { FormControl } from 'Atoms/Input';
import * as Yup from '../core/schema';
import { OrFunction, range, setDebugVariable } from '@f2w/utils';
import { Button } from 'Atoms/Button';
import { translate } from 'Services/Translator';
setDebugVariable({ Yup });
export class MonthType extends SimpleType {
    constructor(options, parent) {
        super(options, parent, Schema.array(Schema.number()));
    }
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            selectAllLabel: () => translate('btn.selectAll'),
            deselectAllLabel: () => translate('btn.unselectAll'),
        };
    }
    cast(value, ignoreDefaults) {
        if (this.options.multiple !== false) {
            if (!Array.isArray(value) || !value.length) {
                if (this.isNullable)
                    return null;
                if (!ignoreDefaults) {
                    return this.cast(this.schema.getDefault(), true);
                }
                return (this.isRequired ? undefined : []);
            }
            return value;
        }
        return super.cast(value, true);
    }
    _renderLabel(props) {
        const values = this.field.value;
        const length = values?.length ?? 0;
        const allMonths = useMemo(() => {
            return (this.options.getAllSelectableMonths?.() ?? range(12, 1));
        }, []);
        const actions = [];
        this.options.showSelectAll && actions.push(_jsx(Button, { link: true, color: 'primary', "$size": 'md', disabled: values && values.every((val, idx) => val === allMonths[idx]), label: OrFunction(this.options.selectAllLabel), onClick: () => this.field.setValue(allMonths) }));
        this.options.showDeselectAll && actions.push(_jsx(Button, { link: true, color: 'primary', "$size": 'md', disabled: length === 0, label: OrFunction(this.options.deselectAllLabel), onClick: () => this.field.setValue([]) }));
        return super._renderLabel({
            ...props,
            style: {
                flexDirection: 'row',
                alignItems: 'end',
                justifyContent: 'space-between'
            },
            children: _jsx(_Fragment, { children: _jsxs("div", { children: [actions.shift(), actions.length && _jsxs(_Fragment, { children: [_jsx("span", { children: "/" }), actions.shift()] })] }) }),
        });
    }
    WidgetComponent({ value, ...props }) {
        return _jsx(FormControl.MonthSelect, { value: this.field.value, disabledMonths: this.options.disabledMonths, "$size": 'sm', ...props });
    }
}
