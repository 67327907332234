import { AbstractFormType } from "@f2w/form";
import { AssetType } from "Pages/Asset/forms/AssetType";
import { AssetCreditorInvoicesType } from "Pages/Asset/forms/AssetCreditorInvoicesType";
export class AssetViewForm extends AbstractFormType {
    buildFields() {
        return {
            general: new AssetType({
                inheritData: false,
                label: false
            }),
            creditorInvoices: new AssetCreditorInvoicesType({
                inheritData: true
            }),
        };
    }
}
