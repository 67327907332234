import { AbstractFormType, DateType } from "@f2w/form";
import { PortfolioSelectType } from "Pages/Rent/forms/PortfolioSelectType";
import { translate } from "Services/Translator";
export class RenterMirrorTenantListExportFormType extends AbstractFormType {
    buildFields() {
        return {
            date: new DateType({
                label: translate('accounting.reportingDate'),
            }),
            portfolio: new PortfolioSelectType({
                label: false,
                inheritData: true,
            })
        };
    }
}
