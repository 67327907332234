import generateRoute from "Services/Router";
import client from "Utils/client";
export const getAccount = (id) => {
    const route = generateRoute('api.ancillaryExpenseAccount', {
        id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const deleteAccount = (id) => {
    const route = generateRoute('api.ancillaryExpenseAccount.delete', { id: id });
    return client.delete(route);
};
export const createAccount = (data) => {
    const route = generateRoute('api.ancillaryExpenseAccount.create');
    return client.post(route, data);
};
export const updateAccount = (id, data) => {
    const route = generateRoute('api.ancillaryExpenseAccount.update', {
        id
    });
    return client.put(route, data);
};
export const duplicateAccount = (data) => {
    const { propertyId, ...payload } = data;
    const route = generateRoute('api.ancillaryExpenseAccount.duplicate', { propertyId });
    return client.post(route, payload);
};
export const listAccounts = (params) => {
    const route = generateRoute('ancillaryExpenseAccounts.list');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export const listAllAccounts = (params) => {
    const route = generateRoute('ancillaryExpenseAccounts.list.all');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export const listAccountsForDropdownByProperty = (propertyId) => {
    const route = generateRoute('ancillaryExpenseAccounts.dropdown');
    return client
        .get(route, { params: { property: propertyId } })
        .then(response => response.data)
        .then((data) => data.map(({ id, name }) => ({ value: id, label: name })));
};
export const listExportableAccountsForProperty = (propertyId) => {
    const route = generateRoute('api.ancillaryExpenseAccounts.exportable', { propertyId });
    return client
        .get(route)
        .then(response => response.data)
        .then(data => data.accounts);
};
