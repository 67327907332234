import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { CartesianGrid, Legend, Line, LineChart as BaseLineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { useTheme } from '@f2w/theme';
import styled from 'styled-components';
import { LegendListContent } from '../components/LegendListContent';
import { translate } from 'Services/Translator';
import { ChartTooltip } from 'Components/Chart/components/ChartTooltip';
import { isUndefined, toObject } from 'Utils';
import { SvgSymbol } from 'Components/Chart/components/SvgSymbol';
import { formatPercentage } from 'Services/NumberHelper';
import { Text } from 'Atoms/Text';
const LineChartRoot = styled.div `
  .recharts-text.recharts-label,
  .fwa-chart-label {
    color: red;
    text-anchor: middle;
    text-align: center;
    ${p => p.theme.typography.pretitle.css()};
  }
`;
export const LineChart = ({ data, legendData, config, Legend: LegendContent = LegendListContent, Tooltip: _TooltipContent = BaseTooltipContent, }) => {
    const { palette, typography } = useTheme();
    const [activeKey, setActiveKey] = React.useState(null);
    const configPayload = useMemo(() => {
        return {
            ...config,
            payload: config.payload.map(p => ({
                dataKey: `${p.key}.value`,
                ...p
            }))
        };
    }, [config]);
    const legendPayload = useMemo(() => {
        return config.payload.map((p, i) => ({
            dataKey: `${p.key}.value`,
            ...p,
            ...(legendData && legendData[p.key]),
            payload: legendData,
        }));
    }, [legendData]);
    const handleMouseEnter = (e) => setActiveKey(e.dataKey);
    const handleMouseLeave = () => setActiveKey(null);
    const renderLines = () => {
        return configPayload.payload.map(({ color, key, dataKey, ...props }) => (_jsx(Line, { type: "linear", dataKey: dataKey, stroke: color, strokeOpacity: !activeKey || activeKey === dataKey ? 1 : .3, strokeWidth: 2, dot: false, activeDot: { r: 4 }, ...props }, `char-line-${key}`)));
    };
    return (_jsx(LineChartRoot, { children: _jsx(ResponsiveContainer, { width: "100%", height: 340, children: _jsxs(BaseLineChart, { data: data, margin: { top: 0, right: 0, left: 0, bottom: 0 }, children: [_jsx(CartesianGrid, { stroke: palette.dark.$100 }), _jsx(XAxis, { dataKey: "date", tick: true, axisLine: true, minTickGap: 0, dy: 5, interval: "preserveStartEnd", label: "" }), _jsx(YAxis, { tick: false, axisLine: false, domain: [0, 1], label: { value: translate('dashboard.sections.vacancyQuota.data.percentage') + ' (%)', offset: -30, angle: -90, position: 'left' } }), _jsx(ReferenceLine, { x: legendPayload[0]?.payload['date'], stroke: "#FA3A3A", label: "", alwaysShow: true }), !_TooltipContent ? null : _jsx(Tooltip, { cursor: { stroke: palette.dark.$100, strokeWidth: 1 }, wrapperStyle: { outline: 'none', border: 'none' }, isAnimationActive: false, content: _TooltipContent }), _jsx(Legend, { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, align: "left", payload: legendPayload, verticalAlign: "middle", layout: "vertical", width: 180, content: _jsx(LegendContent, {}) }), renderLines()] }) }) }));
};
export function BaseTooltipContent(props = {}) {
    const { active, payload, label } = props;
    const activeData = payload?.length > 0 ? payload : null;
    return (_jsxs(ChartTooltip.Root, { active: !!activeData, children: [_jsx(ChartTooltip.Title, { children: label }), activeData && _jsx("table", { children: _jsx("tbody", { children: activeData.map((entry, i) => {
                        const { payload: data, dataKey, name, color, value, unit } = entry;
                        const key = dataKey.split('.')[0];
                        const dataGroup = toObject(data[key]);
                        return (_jsxs("tr", { children: [_jsxs("th", { children: [_jsx(SvgSymbol, { color: color, size: 9 }), " ", name] }), isUndefined(dataGroup.formattedValue) ? _jsx(_Fragment, { children: _jsxs("td", { align: 'left', children: [_jsx(Text, { nowrap: true, body: 500, style: { color }, children: dataGroup.value }), _jsx(Text, { nowrap: true, small: 400, children: unit })] }) })
                                    : _jsx("td", { align: 'left', children: _jsx(Text, { nowrap: true, body: 500, style: { color }, children: dataGroup.formattedValue }) }), !(dataGroup && dataGroup.percent) ? null :
                                    _jsx("td", { align: 'left', style: { paddingLeft: 10 }, children: _jsx(Text, { nowrap: true, small: 500, neutral: 50, children: formatPercentage(dataGroup.percent, '0%') }) })] }, `item-${i}`));
                    }) }) })] }));
}
