import { SelectType } from '@f2w/form';
import { translate } from 'Services/Translator';
import React, { useState } from 'react';
import UserServices from 'Pages/User/Overview/services/userServices';
import MandateServices from 'Pages/Mandate/MandateServices';
import { ProfileType } from 'Pages/Profile/Profile/ProfileType';
export class UserType extends ProfileType {
    roles = [];
    mandates = [];
    _useField(props) {
        super._useField(props);
        const [roles, setRoles] = useState([]);
        const [mandates, setMandates] = React.useState([]);
        this.roles = roles;
        this.mandates = mandates;
        React.useEffect(() => {
            UserServices.getAccountWideAvailableRoles()
                .then(data => {
                setRoles(data.map(role => ({
                    label: role.name,
                    value: role.id
                })));
            });
            MandateServices.getPaginatedMandates({
                columns: ['id', 'name'],
                pageIndex: 1,
                pageSize: 100
            })
                .then(data => {
                setMandates(data.results.map(mandate => ({
                    label: mandate.name,
                    value: mandate.id,
                })));
                if (this.children.mandates.field.value.length === 0) {
                    this.children.mandates.field.setValue(data.results.map(mandate => mandate.id));
                }
            });
        }, []);
    }
    buildFields() {
        const fields = {
            ...super.buildFields(),
            assignedRoles: new SelectType({
                label: translate('user.roles'),
                required: true,
                multiple: true,
                choiceOptions: () => this.roles
            }),
            mandates: new SelectType({
                label: translate('user.mandates'),
                required: false,
                nullable: false,
                multiple: true,
                choiceOptions: () => this.mandates,
            }),
        };
        delete fields.image;
        return fields;
    }
}
