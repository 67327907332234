import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import { translate } from "Services/Translator";
const options = [
    { value: 1, label: translate('month.january') },
    { value: 2, label: translate('month.february') },
    { value: 3, label: translate('month.march') },
    { value: 4, label: translate('month.april') },
    { value: 5, label: translate('month.may') },
    { value: 6, label: translate('month.june') },
    { value: 7, label: translate('month.july') },
    { value: 8, label: translate('month.august') },
    { value: 9, label: translate('month.september') },
    { value: 10, label: translate('month.october') },
    { value: 11, label: translate('month.november') },
    { value: 12, label: translate('month.december') },
];
const MonthSelect = ({ ...props }) => {
    return (_jsx(Select, { placeholder: translate('Month'), options: options, ...props }));
};
export default MonthSelect;
