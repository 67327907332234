import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteIcon, LinkIcon, } from "Components/App/Icons";
import { translate } from "Services/Translator";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
const Actions = ({ cell: { value: asset }, row, refresh, onViewAsset, onDeleteAsset, onLinkCreditorInvoice }) => {
    return (_jsxs(ActionsDropdown, { id: row.id, quickActions: [
            {
                icon: 'eye',
                title: translate('asset.view'),
                onClick: () => {
                    onViewAsset(asset);
                },
            },
        ], children: [_jsxs(ActionsDropdown.Link, { onClick: () => onLinkCreditorInvoice(asset), children: [_jsx(LinkIcon, {}), " ", _jsx("span", { children: translate('asset.linkCreditorInvoice') })] }), _jsxs(ActionsDropdown.Link, { onClick: () => onDeleteAsset(asset, refresh), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('asset.delete') })] })] }));
};
export default Actions;
