import { match } from 'Utils';
import { MembershipType } from 'Pages/Registration/enum/MembershipType';
import { SalesPartner } from 'Pages/Registration/enum/SalesPartner';
import { isDevEnv } from '@f2w/utils';
function fwGtm(args) {
    if (isDevEnv())
        console.debug('dataLayer.push', args);
    if (window.dataLayer) {
        window.dataLayer.push(args);
    }
}
export function sendEvents(events) {
    if (Array.isArray(events)) {
        for (let event of events) {
            fwGtm(event);
        }
    }
}
export function sendRegistrationStep({ path, title, partner, }) {
    fwGtm({
        event: 'trial_registration_step',
        trial_registration_path: `${location.origin}/${path}`,
        trial_registration_title: `Trial - Registration ${title}`,
        trial_registration_partner: match(partner, {
            [MembershipType.HEV]: 'HEV',
            [SalesPartner.WWIMMO]: 'W&W',
            [SalesPartner.EXTENSO]: 'EXTENSO',
        }) ?? 'Fairwalter',
    });
}
