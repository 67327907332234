import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import { formatMoney } from 'Services/NumberHelper';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { DeleteIcon, EditIcon, IconPlus } from 'Components/App/Icons';
import { AmountRangeFilter } from 'Pages/Accounting/AccountingRecord/components/AmountRangeFilter';
import { Button, FlexRow } from 'Components/Atoms';
import { useAccountingPageContext } from '../../AccountingPageContext';
import { DateType } from '@f2w/form';
const model = Table.factory()(() => ({
    tableId: 'AccountingRecordJournal',
    RowActions: ({ row: { original: { id, invoiceData, availableActions } }, refresh, onDeleteClicked, onEditClicked }) => (_jsxs(ActionsDropdown, { id: id, quickActions: [
            {
                icon: 'invoice',
                href: invoiceData?.link,
                target: '_blank',
                hidden: invoiceData === null,
            },
        ], children: [availableActions.includes('edit') && _jsxs(ActionsDropdown.Link, { onClick: () => onEditClicked(id), title: translate('accountingRecord.edit'), children: [_jsx(EditIcon, {}), " ", _jsx("span", { children: translate('accountingRecord.actions.edit') })] }), availableActions.includes('delete') && _jsxs(ActionsDropdown.Link, { onClick: () => onDeleteClicked(id, refresh), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('accountingRecord.actions.delete') })] })] })),
    Actions: ({ onNewAccountingRecordClicked }) => (_jsx("div", { children: _jsxs(Button, { "$size": 'lg', color: 'primary', onClick: onNewAccountingRecordClicked, title: translate('accountingRecord.actions.new'), children: [_jsx(IconPlus, {}), " ", _jsx("span", { style: { verticalAlign: 'sub' }, children: translate('new') })] }) })),
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    columns: {
        $actions: {
            sticky: 'left',
        },
        date: {
            Header: translate('accountingRecord.date'),
            useSortBy: {},
            Cell: ({ value }) => DateType.useFormat(value),
            useFilters: {
                Filter: ({ column: { filterValue, setFilter } }) => {
                    const { fiscalYear } = useAccountingPageContext();
                    return _jsx(Table.filters.DateRangeFilter, { filterValue: filterValue, onChange: setFilter, min: fiscalYear?.starts, max: fiscalYear?.ends });
                },
            }
        },
        customizableId: {
            Header: translate('accountingRecord.customizableId'),
            Cell: ({ value, row: { original: { invoiceData } } }) => invoiceData?.reference
                ? _jsx("a", { href: invoiceData.link, target: '_blank', children: invoiceData.reference })
                : value
        },
        invoiceRecipient: {
            Header: translate('accountingRecord.invoiceRecipient'),
            accessor: originalRow => originalRow.invoiceData,
            Cell: ({ value }) => (_jsx("div", { children: value && value.personId
                    ? _jsx("a", { href: generateRoute('person.list', { personId: value.personId }), target: '_blank', children: value.personName })
                    : translate('notAvailable') }))
        },
        label: {
            Header: translate('accountingRecord.label'),
        },
        debitAccount: {
            Header: translate('accountingRecord.debitAccount'),
            Cell: ({ value }) => (_jsx("div", { children: value
                    ? `${value.position} - ${value.label}`
                    : translate('notAvailable') }))
        },
        creditAccount: {
            Header: translate('accountingRecord.creditAccount'),
            Cell: ({ value }) => _jsxs("div", { children: [value.position, " - ", value.label] })
        },
        amount: {
            Header: translate('accountingRecord.amount'),
            Cell: ({ value }) => _jsx(FlexRow, { justifyFlexEnd: true, children: formatMoney(value) }),
            useFilters: {
                Filter: AmountRangeFilter,
            }
        }
    }
}));
export default model;
