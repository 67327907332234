import moment from 'moment';
import { Yup } from '../base';
import { SimpleValue, SimpleValueType, } from './base';
import { DateManager } from 'Atoms/Input/controls/DatePicker';
export class DateValueType extends SimpleValueType {
    __fw_type_name = 'date';
    static YEAR = 'yyyy';
    static MONTH = 'yyyy-MM';
    static DATE = 'yyyy-MM-DD';
    static FORMATS = {
        day: DateValueType.DATE,
        month: DateValueType.MONTH,
        year: DateValueType.YEAR,
        quarter: DateValueType.DATE,
    };
    constructor(props) {
        super(props);
    }
    createValue(props) {
        return new SimpleValue(this._type, props);
    }
    get schema() {
        return super.schema;
    }
    _createSchema() {
        return new Yup.DateSchema();
    }
    getDateFormat() {
        return DateValueType.FORMATS[this.options.type] ?? DateValueType.FORMATS.day;
    }
    format(value, format = this.getDateFormat()) {
        return DateValueType.format(value, format) || '';
    }
    _toLocaleString(value) {
        return this.format(value, 'L');
    }
    toJSONString(value) {
        return this.format(value, this.getDateFormat());
    }
    _parse(value) {
        return DateValueType.cast(value, { nullable: this.isNullable, format: this.getDateFormat() });
    }
    _typeCheck(v) {
        return DateValueType.isDate(v) && !isNaN(v.getTime());
    }
    static isDate(obj) {
        return Object.prototype.toString.call(obj) === '[object Date]';
    }
    static cast(value, options) {
        if ((value = DateValueType.format(value, options?.format ?? 'L')))
            return value;
        if (options?.nullable)
            return null;
    }
    static format(value, format = 'L') {
        return DateValueType.castMoment(value)?.format(format) || '';
    }
    static castMoment(value, defaultValue) {
        if (value && (value = moment(value)) && value.isValid())
            return value;
        if (defaultValue)
            return DateValueType.castMoment(defaultValue);
    }
    getOptionsModifiers(p) {
        const getMinMax = ({ minDate, maxDate }) => DateManager({
            minDate: minDate || p.self.min,
            maxDate: maxDate || p.self.max,
        });
        return {
            ...super.getOptionsModifiers(p),
            min: {
                getDefault: () => new Date(DateManager.MIN_DATE),
                set: (value) => getMinMax({ minDate: value }).minDate,
            },
            max: {
                getDefault: () => new Date(DateManager.MAX_DATE),
                set: (value) => getMinMax({ maxDate: value }).maxDate,
            },
        };
    }
    get minDate() {
        return this.options.min;
    }
    get maxDate() {
        return this.options.max;
    }
    getConstraints(props) {
        const { mutate } = props;
        return {
            ...super.getConstraints(props),
            min(value, message) {
                mutate(s => s.min(value, message));
                return value;
            },
            max(value, message) {
                mutate(s => s.max(value, message));
                return value;
            },
        };
    }
}
