import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useAsyncDebounce } from 'react-table';
import { translate } from 'Services/Translator';
import { withDataTable } from '@f2w/data-table/DataTableProvider';
import { Input } from "Atoms/Input";
import { useTheme } from '@f2w/theme';
import { Box } from 'Components/Atoms';
export const GlobalSearch = withDataTable((props) => {
    const { instance: { preGlobalFilteredRows, disableGlobalFilter, state: { globalFilter }, setGlobalFilter, hasPlugin } = {}, instance, className = "", placeholder = (translate('search') + '...'), } = props;
    if (disableGlobalFilter || !hasPlugin('useGlobalFilter')) {
        return null;
    }
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, instance.manualControl ? 400 : 250);
    const theme = useTheme();
    return (_jsx(Box, { flex: 1, children: _jsx(Input, { value: value || "", "$size": 'md', prefixIcon: 'search', onChange: value => {
                setValue(value);
                onChange(value);
            }, placeholder: placeholder }) }));
});
export default GlobalSearch;
