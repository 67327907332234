import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { ArrayValueType, BaseType, } from '../../core';
import { ArrayField, CollectionList, CollectionTable } from '../../theme';
export class BaseArrayType extends BaseType {
    constructor(options) {
        super(options);
    }
    createValue(props) {
        return this.specs.createValueType(this, props);
    }
}
export class ArrayType extends BaseArrayType {
    specs;
    constructor(options) {
        super(options);
        this.specs = new ArrayValueType(this, {});
        this.__construct();
    }
    createValue(props) {
        return this.specs.createValueType(this, props);
    }
    _renderWidget(props) {
        const valueType = props.field;
        return _jsx(_Fragment, { children: valueType.options.Widget ? valueType.options.Widget(valueType) : valueType.renderChildren() });
    }
    _renderForm(valueType, props) {
        return _jsx(ArrayField, { children: this._renderWidget(props), ...props });
    }
}
export class CollectionType extends ArrayType {
    static Widgets = {
        list: CollectionList,
        table: CollectionTable,
        accordion: CollectionTable,
    };
    constructor(options) {
        super(options);
    }
    _renderWidget(props) {
        const Widget = CollectionType.Widgets[this.specs.options.widget];
        return Widget ? Widget(props) : super._renderWidget(props);
    }
}
