import { SlotFactory, sizeToken, useTheme } from '@f2w/theme';
export const badgeSlot = new SlotFactory('badge');
export const badgeSizeToken = sizeToken.extend({
    xs: {
        styles: (prev) => ({
            ...prev,
            paddingX: 6,
            paddingY: 2,
        }),
    },
    sm: {
        styles: (prev) => ({
            ...prev,
            paddingX: 8,
            paddingY: 4,
        }),
    },
    md: {
        styles: (prev) => ({
            ...prev,
            paddingX: 12,
            paddingY: 6,
        }),
    },
    lg: {
        styles: (prev) => ({
            ...prev,
            paddingX: 16,
            paddingY: 8,
        }),
    },
});
const BadgeColor = {
    '': (t) => ({ base: {} }),
    primary: ({ palette: { primary: color, white } }) => ({
        base: { background: color.$50, borderColor: color.$50, color: color.$500 },
        hover: { background: color.$500, borderColor: color.$500, color: white },
    }),
    primaryOutline: ({ palette: { primary: color, white } }) => ({
        base: { background: white, borderColor: color.$500, color: color.$500 },
        hover: { background: color.$50, borderColor: color.$500, color: color.$600 },
    }),
    secondary: ({ palette: { secondary: color, white } }) => ({
        base: { background: white, borderColor: color.$500, color: color.$500 },
        hover: { background: color.$50, borderColor: color.$500, color: color.$600 },
    }),
    dark: ({ palette: { dark: color, white } }) => ({
        base: { background: color.$50, borderColor: color.$50, color: color.$500 },
        hover: { background: color.$500, borderColor: color.$500, color: white },
    }),
    darkOutline: ({ palette: { dark: color, white } }) => ({
        base: { background: white, borderColor: color.$500, color: color.$500 },
        hover: { background: color.$50, borderColor: color.$500, color: color.$600 },
    }),
    danger: ({ palette: { error: color, white } }) => ({
        base: { background: color.$50, borderColor: color.$50, color: color.$500 },
        hover: { background: color.$500, borderColor: color.$500, color: white },
    }),
    dangerOutline: ({ palette: { error: color, white } }) => ({
        base: { background: white, borderColor: color.$500, color: color.$500 },
        hover: { background: color.$50, borderColor: color.$500, color: color.$600 },
    }),
    warning: ({ palette: { warning: color, white } }) => ({
        base: { background: color.$50, borderColor: color.$50, color: color.$600 },
        hover: { background: color.$500, borderColor: color.$500, color: white },
    }),
    success: ({ palette: { success: color, white } }) => ({
        base: { background: color.$600, borderColor: color.$600, color: white },
        hover: { background: color.$500, borderColor: color.$500, color: white },
    }),
    successOutline: ({ palette: { success: color, white } }) => ({
        base: { background: white, borderColor: color.$500, color: color.$500 },
        hover: { background: color.$50, borderColor: color.$500, color: color.$600 },
    }),
};
const BadgeToken = {
    $color: BadgeColor,
    useColorProps: (tokenName) => {
        const tokens = BadgeColor;
        const theme = useTheme();
        const tokenFn = (tokens[tokenName] ?? tokens.primary);
        return {
            base: {},
            ...(tokenFn?.(theme) || undefined),
        };
    },
    color: (tokenName) => {
        const { base, hover, focus, disabled } = BadgeToken.useColorProps(tokenName);
        return {
            ...base,
            '&:focus, &.focus': focus || hover || base,
            '&[aria-disabled=true], &:disabled, &.disabled': {
                pointerEvents: 'none',
                opacity: .8,
                ...(disabled || base)
            },
            [badgeSlot.css('content')]: {
                '&:hover': hover || base,
            },
        };
    },
};
export default BadgeToken;
