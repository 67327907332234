import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { DetailViewModal } from '@f2w/view';
import { translate } from 'Services/Translator';
import { useHandoverView } from 'Pages/Handover/components/HandoverView/hooks/useHandoverView';
export const HandoverView = ({ handoverId, onClose, ...props }) => {
    const handoverView = useHandoverView(props);
    React.useEffect(() => {
        handoverView.load(handoverId);
    }, [handoverId]);
    return _jsx(DetailViewModal, { view: handoverView, title: translate('handovers.detailView.title'), onClose: onClose });
};
