import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AbstractFormType, DateType, NumberType, SelectType } from '@f2w/form';
import { translate } from 'Services/Translator';
import { Col, Div, Icon, Row } from 'Components/Atoms';
import React from "react";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { CalculationServices } from "../index";
import moment from "moment";
const ImagePlaceholder = () => _jsxs("div", { style: { width: "100%", alignSelf: "center", textAlign: "center", color: "#737A86" }, children: [_jsx(Icon, { size: "8em", icon: "image" }), _jsx("div", { children: translate('noImageAvailable') })] });
const ImageBox = ({ src, alt = "", onClick, style, ...props }) => {
    return (_jsx(_Fragment, { children: src ? _jsx("img", { src: src, style: style, alt: alt, ...props }) : _jsx(ImagePlaceholder, {}) }));
};
export class CalculationWizardBasicsType extends AbstractFormType {
    properties = [];
    propertyDetails = undefined;
    __getDefaultOptions() {
        return {
            getPropertyDetails: CalculationServices.getCalculationPropertyDetails,
            ...super.__getDefaultOptions(),
        };
    }
    _useField(props) {
        super._useField(props);
        const [properties, setProperties] = React.useState([]);
        this.properties = properties;
        const [propertyDetails, setPropertyDetails] = React.useState();
        this.propertyDetails = propertyDetails;
        const portfolio = usePortfolio();
        React.useEffect(() => {
            const allProperties = portfolio.getProperties();
            const groupedProperties = new Map();
            allProperties?.forEach(property => {
                const mandateId = property?.mandate?.id;
                if (!mandateId)
                    return;
                if (groupedProperties.has(mandateId)) {
                    groupedProperties.set(mandateId, {
                        ...groupedProperties.get(mandateId),
                        options: [
                            ...groupedProperties.get(mandateId).options,
                            {
                                label: property.name,
                                value: property.id
                            }
                        ]
                    });
                }
                else {
                    groupedProperties.set(mandateId, {
                        label: property.mandate.name,
                        options: [{
                                label: property.name,
                                value: property.id
                            }]
                    });
                }
            });
            setProperties(Array.from(groupedProperties.values()));
        }, []);
        React.useEffect(() => {
            const propertyId = this.field.value.propertyId;
            if (!propertyId) {
                return;
            }
            this.options?.getPropertyDetails(propertyId)
                .then((propertyDetails) => {
                setPropertyDetails(propertyDetails);
                if (propertyDetails.calculationPeriod) {
                    this.children.periodFrom.field.setValue(propertyDetails.calculationPeriod.from, false);
                    this.children.periodTo.field.setValue(propertyDetails.calculationPeriod.to, false);
                }
                if (propertyDetails.administrativeCost) {
                    this.children.administrativeCost.field.setValue(propertyDetails.administrativeCost * 100);
                }
            });
        }, [this.field.value.propertyId]);
    }
    _renderWidget() {
        const primaryImagePath = this.propertyDetails?.primaryImage?.path;
        return _jsx(_Fragment, { children: _jsxs(Row, { children: [_jsxs(Col, { gap: 10, children: [this.renderChild('propertyId'), this.propertyDetails && _jsx(Div, { mt: 32, children: this.renderChildren([
                                    'periodFrom',
                                    'periodTo',
                                    'administrativeCost'
                                ]) })] }), this.propertyDetails && _jsx(_Fragment, { children: _jsx(Col, { md: 6, maxw: 800, children: _jsx(Div, { mb: 32, style: {
                                    width: "100%",
                                    minHeight: 300,
                                    background: "#F7F7F7",
                                    borderRadius: 6,
                                    display: "flex"
                                }, children: _jsx(ImageBox, { style: { minHeight: 300, width: '100%', objectFit: "contain", borderRadius: 6 }, src: primaryImagePath }) }) }) })] }) });
    }
    buildFields() {
        return {
            propertyId: new SelectType({
                label: translate('property'),
                required: true,
                searchable: true,
                choiceOptions: () => this.properties
            }),
            periodFrom: new DateType({
                type: 'month',
                label: translate('ancillaryExpenseCalculation.periodFrom'),
                required: true,
                inline: true,
                constraints: {
                    test: ['is-greater-than-period-to', translate('ancillaryExpenseCalculation.error.cannotBeLargerThanPeriodTo'), (currentValue) => {
                            return this.field.value.periodTo
                                ? moment(this.field.value.periodTo).isSameOrAfter(moment(currentValue))
                                : true;
                        }]
                },
                deps: () => [this.children.periodTo],
            }),
            periodTo: new DateType({
                type: 'month',
                selectsEnd: true,
                inline: true,
                label: translate('ancillaryExpenseCalculation.periodTo'),
                required: true,
                constraints: {
                    test: ['is-less-than-period-from', translate('ancillaryExpenseCalculation.error.cannotBeSmallerThanPeriodFrom'), (currentValue) => {
                            return this.field.value.periodFrom
                                ? moment(currentValue).isSameOrAfter(moment(this.field.value.periodFrom))
                                : true;
                        }]
                },
                deps: () => [this.children.periodFrom],
            }),
            administrativeCost: new NumberType({
                label: translate('ancillaryExpenseCalculation.administrativeCost'),
                required: true,
                constraints: {
                    min: [0, translate('form.validation.positiveValue')]
                },
                getProps: () => ({
                    prefix: '%'
                })
            }),
        };
    }
}
