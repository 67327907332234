import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import styled, { css } from "styled-components";
import { UseStyled, useTheme } from "@f2w/theme";
import { Button } from "Components/Atoms/Button";
import GettingStartedProgressBar from "./components/GettingStartedProgressBar";
import { GettingStartedSection } from "./components/GettingStartedSection";
import { translate } from 'Services/Translator';
import data from "./GettingStartedSectionData";
import generateRoute from "Services/Router";
import { CompleteOnboardingButton } from "Pages/GettingStartedPage/components/CompleteOnboardingButton";
import { Div } from "Atoms/Layout";
import { DateType } from "@f2w/form";
const Wrapper = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        background-color: ${palette.white};
        padding: 0 ${rem(30)} ${rem(30)} ${rem(30)};
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        @media (min-width: 1280px) {
            padding: 0 ${rem(40)} ${rem(40)} ${rem(40)};
        }
    `;
});
const Heading = UseStyled.styled('div', ({ theme: { rem } }) => ({
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    flexDirection: 'column',
    marginBottom: rem(34),
    gap: rem(40),
    '@media (min-width: 1280px)': {
        flexDirection: 'row'
    }
}));
const HeadingWelcomeTop = UseStyled.styled('div', ({ theme: { rem } }) => ({
    flex: 1,
    gap: rem(32),
    justifyContent: 'space-between',
    '@media (min-width: 1280px)': {
        display: 'flex',
    },
}));
const HeadingWelcome = UseStyled.styled('div', ({ theme: { palette, rem } }) => ({
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: rem(32),
    border: `2px solid ${palette.dark.$50}`,
    borderRadius: rem(16),
    gap: rem(16)
}));
const HeadingTitle = UseStyled.styled('div', ({ theme: { rem, palette, typography } }) => ({
    ...typography.h3.fontStyles,
    color: palette.dark.$900,
}));
const HeadingSubtitle = styled.div(() => {
    const { rem, palette } = useTheme();
    return css `
        color: ${palette.dark.$400};
        @media (max-width: 1280px) {
            margin-bottom: ${rem(25)};
        }
    `;
});
const HeadingTrialMessage = styled.div(() => {
    const { palette, typography } = useTheme();
    return css `
        color: ${palette.dark.$400};
        font-weight: ${typography.fontWeight.$600};
    `;
});
const HeadingButtons = styled.div(() => {
    const { rem } = useTheme();
    return css `
        ${HeadingSubtitle} {
            margin-top: ${rem(10)};
        }

        text-align: right;
        @media (max-width: 1280px) {
            text-align: left;
        }
    `;
});
const Contact = UseStyled.styled('div', ({ theme: { rem, palette, typography } }) => ({
    flex: 1,
    minWidth: rem(490),
    padding: rem(32),
    border: `2px solid ${palette.dark.$50}`,
    borderRadius: rem(16),
    'h3': {
        ...typography.h6.fontStyles,
        color: palette.dark.$900,
    },
    'address': {
        display: 'flex',
        marginTop: rem(28),
    }
}));
const ContactMedia = UseStyled.styled('figure', ({ theme: { rem, palette, typography } }) => ({
    width: 48,
    height: 48,
    minWidth: 48,
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: rem(16),
    'img': {
        width: '100%',
        height: '100%',
    }
}));
const ContactInfo = UseStyled.styled('div', ({ theme: { rem, palette, typography } }) => ({
    ...typography.small.fontStyles,
    'h6': {
        ...typography.body.css({ fontWeight: 600 }),
        color: palette.dark.$700,
        margin: 0
    },
    'div': {
        display: 'flex',
        alignItems: 'center',
        marginTop: rem(16),
    }
}));
const GettingStartedPage = ({ showCustomerSuccessInfo, salesPartnerInfo, onboardingTasks, onboardingProgress, trial = null, isTrialCustomer = false }) => {
    return (_jsxs(Wrapper, { children: [_jsxs(Heading, { children: [_jsxs(HeadingWelcome, { children: [_jsxs(HeadingWelcomeTop, { children: [_jsxs("div", { children: [_jsxs(HeadingTitle, { children: [translate("start.title"), " \uD83C\uDF89"] }), trial !== null && _jsx(HeadingTrialMessage, { children: translate('subscription.trialRunsUntil', { date: DateType.format(trial.end) }) }), _jsx(HeadingSubtitle, { children: translate("start.subtitle") })] }), isTrialCustomer && _jsxs(HeadingButtons, { children: [_jsx(Button, { href: generateRoute('subscription.subscribe'), children: translate("start.action.activateProduct") }), _jsx(HeadingSubtitle, { children: translate("subscription.trial.isExpiring.title") })] })] }), _jsx(GettingStartedProgressBar, { progress: onboardingProgress })] }), showCustomerSuccessInfo && (_jsxs(Contact, { children: [_jsx("h3", { children: translate("start.contact.title") }), _jsxs("address", { children: [salesPartnerInfo.isLogoAvatar
                                        ? (_jsx(ContactMedia, { children: _jsx("img", { src: salesPartnerInfo.logoPath, alt: "" }) }))
                                        : (_jsx("div", { style: { paddingRight: '20px' }, children: _jsx("img", { width: 100, height: 50, src: salesPartnerInfo.logoPath, alt: "" }) })), _jsxs(ContactInfo, { children: [_jsx("h6", { children: salesPartnerInfo.supportName }), _jsx("a", { href: `mailto:${salesPartnerInfo.supportEmail}`, children: salesPartnerInfo.supportEmail }), !salesPartnerInfo.supportPartner && (_jsxs(_Fragment, { children: [_jsxs(Div, { style: { display: 'block' }, children: ["Sollten Sie noch Fragen haben oder Hilfe brauchen, stehen wir Ihnen gerne zur Verf\u00FCgung. Zus\u00E4tzlich finden Sie online viele Hilfsmittel, darunter unsere FAQ, das ", _jsx("a", { target: '_blank', href: 'https://help.fairwalter.com/', children: "Help-Center" }), ", unseren ", _jsx("a", { target: '_blank', href: 'https://blog.fairwalter.com/', children: "Blog" }), " und unsere\u00A0", _jsx("a", { target: '_blank', href: 'https://www.youtube.com/@fairwalterdotcom', children: "Videos" }), " f\u00FCr n\u00FCtzliche Tipps & Tricks."] }), _jsxs(Div, { style: { display: 'block' }, children: ["Gerne k\u00F6nnen Sie auch ", _jsx("a", { target: '_blank', href: 'https://meetings.hubspot.com/dina-linsin/demo-bookings', children: "hier" }), " einen Demotermin mit uns vereinbaren, damit wir Ihnen die Software online in 30 Minuten kurz vorstellen und Ihre individuellen Fragen beantworten k\u00F6nnen."] })] }))] })] })] }))] }), data.map((section, index) => {
                return (_jsx(GettingStartedSection, { section: section, tasksCompletion: onboardingTasks }, index));
            }), _jsx("div", { style: { marginTop: 24 }, children: _jsx(CompleteOnboardingButton, {}) })] }));
};
export default GettingStartedPage;
