import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { translate } from 'Services/Translator';
import { Icon } from 'Atoms/Icon';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input/input';
import { StringValueType } from '../../core';
import { InputField } from '../../theme';
import { SimpleType } from './BaseSimpleType';
import { FormControl } from 'Atoms/Input';
import classnames from 'classnames';
import { OrFunction } from '@f2w/utils';
export class StringType extends SimpleType {
    specs = this._getSpecs();
    constructor(options) {
        super(options);
        this.__construct();
    }
    _getSpecs() {
        return StringValueType.define({
            defaultOptions: () => ({
                constraints: {
                    max: 255,
                },
            }),
        });
    }
    _renderForm(valueType, props) {
        return _jsx(InputField, { ...props, Widget: p => this._renderWidget(p) });
    }
    _renderWidget({ field: valueType, ...props }) {
        const autocomplete = useMemo(() => OrFunction(valueType.options.autocomplete, valueType), []);
        const controlProps = {
            type: 'text',
            key: `${valueType.id}--simple-widget`,
            autoComplete: 'off',
            placeholder: valueType.options.placeholder,
            className: classnames('fw-form_control', 'fw-form_widget', props?.['className']),
            ...props
        };
        if (autocomplete) {
            return _jsx(FormControl.Autocomplete, { ...controlProps, ...autocomplete });
        }
        return _jsx(FormControl.Input, { ...controlProps });
    }
}
export class EmailType extends StringType {
    _getSpecs() {
        return StringValueType.define({
            getSchema: (s) => s.email(translate('account.invalidEmail')),
            defaultOptions: () => ({
                label: translate('form.type.email.label'),
                constraints: {
                    max: 255,
                },
            }),
        });
    }
    _renderWidget(props) {
        return super._renderWidget({
            type: 'email',
            prefix: _jsx(Icon, { icon: 'mail' }),
            ...props,
        });
    }
}
export class PhoneType extends StringType {
    _getSpecs() {
        return StringValueType.define({
            defaultOptions: () => ({
                label: translate('form.type.phone.label'),
                constraints: {
                    max: 255,
                },
            }),
            validate: (value, { createError }) => (value && !isValidPhoneNumber(value.toString(), { defaultCountry: 'CH' })
                ? createError(translate('form.type.phone.error'))
                : ''),
            cast: (val) => {
                if (val) {
                    if (val.startsWith('00'))
                        val = `+${val.substring(2)}`;
                    else if (val.startsWith('+00'))
                        val = `+${val.substring(3)}`;
                    else if (val.startsWith('+0'))
                        val = `+${val.substring(2)}`;
                    return val.replaceAll(' ', '');
                }
                return val;
            },
            format: (val) => val ? formatPhoneNumberIntl(val) : '',
        });
    }
    _renderWidget(props) {
        return super._renderWidget({
            prefix: _jsx(Icon, { icon: 'phone' }),
            inputAs: PhoneInput,
            type: 'tel',
            ...props,
        });
    }
}
