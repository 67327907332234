import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import useCallbackRef from '@restart/hooks/useCallbackRef';
import useEventCallback from '@restart/hooks/useEventCallback';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import BaseModal from 'react-overlays/Modal';
import warning from 'warning';
import BootstrapModalManager from './BootstrapModalManager';
import { useTargetContainer } from 'Hooks/useTargetContainer';
import { ClassName, modalSlot } from './Modal.theme';
import ModalContext from './ModalContext';
import { ModalBackdrop, ModalBody, ModalDialog, ModalDivider, ModalFooter, ModalHeader, ModalRoot as ModalDialogRoot, ModalTitle, } from './components';
import { AnimateCss } from 'Components/Transition';
import { isString } from 'lodash';
let manager;
const ModalRoot = forwardRef(function ModalFunc({ zIndex = 10060, animation = true, fullScreen, locked, offset, position, scrollable = true, size, variant, dialogAs: dialogAs, children, ...props }, ref) {
    const [modal, setModalRef] = useCallbackRef();
    const handleHide = useEventCallback(props.onHide);
    const context = useMemo(() => ({
        onHide: handleHide,
        zIndex,
        animation: animation ?? true,
        fullScreen,
        locked,
        offset,
        position,
        scrollable,
        variant: (variant ?? (isString(size) ? size : undefined)),
        size: fullScreen ? 'fullscreen' : size,
    }), [handleHide, zIndex, animation, fullScreen, locked, offset, position, scrollable, size, variant]);
    const transitionProps = useMemo(() => {
        if (!animation)
            return;
        return {
            transition: p => _jsx(AnimateCss, { ...p }),
            backdropTransition: p => _jsx(AnimateCss, { ...p }),
        };
    }, [animation]);
    useImperativeHandle(ref, () => ({
        get _modal() {
            warning(false, 'Accessing `_modal` is not supported and will be removed in a future release');
            return modal;
        },
    }), [modal]);
    function getModalManager() {
        if (props.manager)
            return props.manager;
        if (!manager)
            manager = new BootstrapModalManager();
        return manager;
    }
    const renderBackdrop = (p) => _jsx(ModalBackdrop, { ...p });
    const renderDialog = ({ ...dialogProps }) => {
        return (_jsx(ModalDialogRoot, { className: classNames(dialogProps.className, useTargetContainer.resetClassname), role: "dialog", as: dialogAs, "data-variant": context.variant, "data-fullscreen": fullScreen || undefined, ...dialogProps, style: {
                ...dialogProps.style,
                zIndex: zIndex,
            }, children: children }));
    };
    return (_jsx(ModalContext.Provider, { value: context, children: _jsx(BaseModal, { ref: setModalRef, keyboard: true, ...props, ...transitionProps, onHide: props.onHide, manager: getModalManager(), containerClassName: ClassName.open, renderBackdrop: renderBackdrop, renderDialog: renderDialog }) }));
});
ModalRoot.displayName = 'ModalNew';
const slots = {
    slotAttr: modalSlot.attr,
    slotCss: modalSlot.css,
    Body: ModalBody,
    Header: ModalHeader,
    Title: ModalTitle,
    Footer: ModalFooter,
    Dialog: ModalDialog,
    Divider: ModalDivider,
};
export const Modal = Object.assign(ModalRoot, slots);
