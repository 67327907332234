import { AbstractFormType, AddressType, BooleanType, SelectType, StringType, TextType } from '@f2w/form';
import { translate } from "Services/Translator";
import React from "react";
import { ObjectViewModalServices } from "Components/ObjectViewModal";
import { getGlobalContext } from "Services/GlobalContext";
export class PropertyViewFormType extends AbstractFormType {
    buildFields() {
        return {
            generalLeft: new PropertyType({
                getServiceProviders: () => this.field.value.serviceProviders
            }),
        };
    }
}
export class PropertyType extends AbstractFormType {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: false,
        };
    }
    availableManagers = [];
    mandates = [];
    facilityManagers = [];
    bankAccounts = [];
    isAdmin = false;
    _useField(props) {
        super._useField(props);
        const selectedMandateId = this.children.mandateId.field.value;
        const selectedManagerId = this.children.managerId.field.value;
        const [managers, setManagers] = React.useState([]);
        const [availableManagers, setAvailableManagers] = React.useState(managers.filter((user) => user.mandates.includes(selectedMandateId)));
        this.availableManagers = availableManagers;
        const [mandates, setMandates] = React.useState([]);
        this.mandates = mandates;
        const [bankAccounts, setBankAccounts] = React.useState([]);
        this.bankAccounts = bankAccounts;
        const [facilityManagers, setFacilityManagers] = React.useState([]);
        this.facilityManagers = facilityManagers;
        const { roles } = getGlobalContext();
        this.isAdmin = roles.includes('ROLE_ADMINISTRATOR');
        React.useEffect(() => {
            ObjectViewModalServices
                .getPropertyViewDropdownData()
                .then(response => {
                response?.mandates?.map && setMandates(response.mandates.map(mandate => ({
                    label: mandate.name,
                    value: mandate.id
                })));
                response?.users?.map && setManagers(response.users);
                const mainBankAccountGroup = {
                    label: this.field.value.name,
                    options: []
                };
                const otherBankAccountGroup = {
                    label: translate('bankAccounts.group.other'),
                    options: []
                };
                response?.bankAccounts?.forEach(bankAccount => {
                    const foundProperty = bankAccount.properties.find(property => property.id === this.parent.field.value.id);
                    if (foundProperty) {
                        mainBankAccountGroup.options.push({
                            value: bankAccount.id,
                            label: bankAccount.name
                        });
                    }
                    else {
                        otherBankAccountGroup.options.push({
                            value: bankAccount.id,
                            label: bankAccount.name
                        });
                    }
                });
                setBankAccounts([
                    mainBankAccountGroup,
                    otherBankAccountGroup
                ]);
                setFacilityManagers(this.options?.getServiceProviders?.()?.map(serviceProvider => ({
                    label: serviceProvider.name,
                    value: serviceProvider.id
                })));
            });
        }, []);
        React.useEffect(() => {
            if (!selectedMandateId || managers.length === 0) {
                return;
            }
            const availableManagersLocal = managers.filter((user) => user.mandates.includes(selectedMandateId));
            setAvailableManagers(availableManagersLocal);
            this.children.managerId.field.setError(null);
            if (!availableManagersLocal.find(user => user.id === selectedManagerId)) {
                if (availableManagersLocal.length > 0) {
                    this.children.managerId.field.setValue(1);
                }
                this.children.managerId.field.setValue(null, availableManagersLocal.length > 0);
            }
            if (availableManagersLocal.length === 0) {
                this.children.managerId.field.setError(translate('baseData.property.mandate.noUsersAssignedToMandate'));
            }
        }, [selectedMandateId, selectedManagerId, managers]);
    }
    buildFields() {
        return {
            name: new StringType({
                label: translate('baseData.property.name'),
                required: true
            }),
            egrid: new StringType({
                label: translate('baseData.createProperty.parcel.egrid'),
                constraints: {
                    max: [14, translate('baseData.property.egrid.lengthError')]
                }
            }),
            description: new StringType({
                label: translate('baseData.property.viewDetails'),
                required: false
            }),
            hasVat: new BooleanType({
                label: translate('baseData.property.vatObligation')
            }),
            active: new BooleanType({
                label: translate('baseData.property.active')
            }),
            quickschildEnabled: new BooleanType({
                label: translate('baseData.property.quickschildIntegration'),
                isVisible: () => this.value.quickschildIntegrationEnabled === true,
            }),
            address: new AddressType({
                inherit: true,
                hideStreet: true,
                city: {
                    label: translate('baseData.property.city'),
                    required: true
                },
                zip: {
                    label: translate('baseData.property.zip'),
                    required: true
                },
                country: {
                    allowed: ['CH', 'LI']
                }
            }),
            defaultRentBankAccountId: new SelectType({
                label: translate('baseData.property.defaultRentBankAccount'),
                help: translate('baseData.property.defaultRentBankAccount.info'),
                choiceOptions: () => this.bankAccounts,
                searchable: true,
                required: false,
                clearable: true
            }),
            ancillaryCostSettlementDate: new StringType({
                label: translate('baseData.property.ancillaryCostSettlement'),
                help: translate('baseData.property.ancillaryCostSettlement.info'),
                required: false,
                constraints: {
                    matches: [
                        /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])$/,
                        translate('baseData.property.ancillaryCostSettlement.invalidFormat')
                    ]
                }
            }),
            mandateId: new SelectType({
                label: translate('baseData.property.mandate'),
                required: true,
                choiceOptions: () => this.mandates,
                isVisible: () => this.isAdmin
            }),
            managerId: new SelectType({
                label: translate('baseData.property.manager'),
                required: true,
                choiceOptions: () => this.availableManagers.map(user => ({
                    label: user.name,
                    value: user.id,
                })),
                isVisible: () => this.isAdmin
            }),
            facilityManagerId: new SelectType({
                label: translate('baseData.property.facilityManager'),
                required: false,
                choiceOptions: () => this.facilityManagers,
            }),
            notes: new TextType({
                label: translate('baseData.property.notes'),
                required: false,
                nullable: false,
            }),
        };
    }
}
