import { AbstractFormType, DateType, MoneyType, SelectType, StringType } from "@f2w/form";
import { translate } from "Services/Translator";
import moment from "moment";
import React from "react";
import { useFinancialAccountsHook } from "Hooks/useFinancialAccountsHook";
export class ManualPaymentType extends AbstractFormType {
    financialAccounts = [];
    DEFAULT_POSITION = 1020;
    DEFAULT_MANUAL_PAYMENT_POSITION = 1000;
    _useField(props) {
        super._useField(props);
        const { groupedFinancialAccountOptions, financialAccounts } = useFinancialAccountsHook({
            propertyId: this.options.propertyId?.()
        });
        this.financialAccounts = groupedFinancialAccountOptions;
        React.useEffect(() => {
            if (!this.field.value.creditAccountId && financialAccounts.length > 0) {
                const defaultPosition = this.field.value.payerBankAccountId
                    ? this.DEFAULT_POSITION
                    : this.DEFAULT_MANUAL_PAYMENT_POSITION;
                const defaultSelectedAccount = financialAccounts
                    .find(financialAccount => financialAccount.position === defaultPosition);
                console.log(defaultPosition, defaultSelectedAccount);
                this.children.creditAccountId.field.setValue(defaultSelectedAccount?.id);
            }
        }, [financialAccounts]);
    }
    buildFields() {
        return {
            label: new StringType({
                label: translate('creditor.label'),
                required: true
            }),
            amount: new MoneyType({
                label: translate('payment.amount'),
                required: true
            }),
            paidAt: new DateType({
                label: translate('payment.paidAt'),
                required: true,
                defaultValue: () => moment().format('YYYY-MM-DD')
            }),
            creditAccountId: new SelectType({
                label: translate('creditor.payment.position'),
                required: true,
                searchable: true,
                choiceOptions: () => this.financialAccounts
            })
        };
    }
}
