import { useForm } from "@f2w/form";
import { CalculationWizardEditBasicsType } from "Pages/AncillaryExpense/types/CalculationWizardEditBasicsType";
export const useCalculationWizardEditBasicsForm = ({ initialValues, onSubmit, }) => useForm({
    name: 'calculation-wizard-edit-basics-form',
    type: () => new CalculationWizardEditBasicsType(),
    initialValues: initialValues,
    successMessage: null,
    onSubmit: (values) => {
        onSubmit(values);
    },
});
