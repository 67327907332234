import { AbstractFormType } from "@f2w/form";
import { DateRangeType } from "Pages/Accounting/Export/DateRangeType";
import moment from "moment/moment";
import { useEffect } from "react";
export class AccountingExportType extends AbstractFormType {
    _useField(props) {
        super._useField(props);
        useEffect(() => {
            this.children.dateRange.field.setValue({
                start: moment(this.options.maxDateRange.start).format('YYYY-MM-DD'),
                end: moment(this.options.maxDateRange.end).format('YYYY-MM-DD'),
            });
        }, []);
    }
    buildFields() {
        return {
            dateRange: new DateRangeType({
                label: false,
                nullable: false,
                maxDateRange: this.options.maxDateRange,
            }),
        };
    }
}
