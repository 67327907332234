import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { generate as generateRoute, hasRoute } from 'Services/Router/Router';
import { Icon } from 'Atoms/Icon';
import { useContext, useMemo } from 'react';
import NavigationContext from './NavigationContext';
import { isString } from '@f2w/utils';
import { SafeAnchor } from 'Atoms/SafeAnchor';
import classNames from 'classnames';
import classes from './Navigation.module.scss';
import { Tooltip2 } from 'Atoms/Overlay';
export const NavigationItem = ({ url, route, title, icon: _icon, target, Extra, className, }) => {
    const { activeRoute, isFullMenu } = useContext(NavigationContext);
    const { icon, href, isActiveItem } = useMemo(() => {
        const icon = isString(_icon) ? _jsx(Icon, { icon: _icon }) : _icon;
        if (url)
            return { icon, href: url };
        if (route && hasRoute(route))
            return {
                icon,
                href: generateRoute(route),
                isActiveItem: activeRoute && activeRoute === route || activeRoute.startsWith(route + '.'),
            };
        console.error('The route "' + route + '" does not exist.');
        return { icon, href: null };
    }, [url, route, activeRoute, _icon]);
    return !href ? null : (_jsx(Tooltip2, { placement: 'right', content: isFullMenu ? null : title, children: _jsxs(SafeAnchor, { href: href, target: target, "aria-current": isActiveItem ? 'page' : undefined, className: classNames(classes.sectionItem, className, {
                [`${classes.active}`]: isActiveItem
            }), children: [icon && _jsx("div", { className: classes.sectionIcon, children: icon }), _jsxs("div", { className: classes.sectionItemText, children: [_jsx("span", { children: title }), Extra && _jsx(Extra, {})] })] }) }));
};
