import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { EditCustomerDocumentType } from "Pages/CustomerDocuments/types/EditCustomerDocumentType";
import { translate } from "Services/App";
import { Button, ButtonToolbar } from "Atoms/Button";
import { Icon } from "Atoms/Icon";
export const useEditCustomerDocumentForm = ({ onSave, updateCustomerDocument }) => useFormModal({
    name: 'edit-customer-document-form',
    type: () => new EditCustomerDocumentType(),
    title: translate('document.edit'),
    onLoad: async (data) => {
        return {
            ...data
        };
    },
    onSave: ({ id, ...data }) => {
        return updateCustomerDocument(id, {
            fileName: data.fileName,
            category: data.category,
            propertyId: data.propertyId,
            buildingId: data.buildingId,
            rentalObjectId: data.rentalObjectId,
            contractId: data.contractId
        }).then(onSave);
    },
    Footer: ({ saveForm, closeForm, form: { loading, values } }) => {
        return _jsxs(ButtonToolbar, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsxs(Button, { loading: loading, onClick: async () => await saveForm(true), children: [_jsx(Icon, { icon: 'save' }), " ", translate('btn.save')] }), _jsx(Button, { variant: 'outline', href: values.filePath, download: values.fileName, children: translate('btn.download') })] });
    },
});
