import { useForm } from "@f2w/form";
import { PasswordForgetType } from "Pages/Auth/types/PasswordForgetType";
export const usePasswordForgetForm = ({ forgotPassword, onSave, initialEmail }) => useForm({
    name: 'password-forget-form',
    type: () => new PasswordForgetType(),
    initialValues: {
        email: initialEmail
    },
    onSave: (values) => {
        return forgotPassword(values).then(onSave);
    },
    successMessage: null
});
