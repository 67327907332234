export var AuditEvent;
(function (AuditEvent) {
    AuditEvent["AUTH_LOGGED_IN"] = "AUTH_LOGGED_IN";
    AuditEvent["AUTH_LOGGED_OUT"] = "AUTH_LOGGED_OUT";
    AuditEvent["AUTH_LOGIN_FAILED"] = "AUTH_LOGIN_FAILED";
    AuditEvent["CONTRACT_CREATED"] = "CONTRACT_CREATED";
    AuditEvent["CONTRACT_UPDATED"] = "CONTRACT_UPDATED";
    AuditEvent["CONTRACT_DELETED"] = "CONTRACT_DELETED";
    AuditEvent["PERSON_CREATED"] = "PERSON_CREATED";
    AuditEvent["PERSON_UPDATED"] = "PERSON_UPDATED";
    AuditEvent["PERSON_DELETED"] = "PERSON_DELETED";
    AuditEvent["DEBITOR_INVOICE_CREATED"] = "DEBITOR_INVOICE_CREATED";
    AuditEvent["DEBITOR_INVOICE_UPDATED"] = "DEBITOR_INVOICE_UPDATED";
    AuditEvent["DEBITOR_INVOICE_DELETED"] = "DEBITOR_INVOICE_DELETED";
    AuditEvent["CREDITOR_INVOICE_CREATED"] = "CREDITOR_INVOICE_CREATED";
    AuditEvent["CREDITOR_INVOICE_UPDATED"] = "CREDITOR_INVOICE_UPDATED";
    AuditEvent["CREDITOR_INVOICE_DELETED"] = "CREDITOR_INVOICE_DELETED";
    AuditEvent["BANK_ACCOUNT_CREATED"] = "BANK_ACCOUNT_CREATED";
    AuditEvent["BANK_ACCOUNT_UPDATED"] = "BANK_ACCOUNT_UPDATED";
    AuditEvent["BANK_ACCOUNT_DELETED"] = "BANK_ACCOUNT_DELETED";
    AuditEvent["BANKING_RECONCILIATION_UPLOAD_CREATED"] = "BANKING_RECONCILIATION_UPLOAD_CREATED";
    AuditEvent["BANKING_RECONCILIATION_UPLOAD_UPDATED"] = "BANKING_RECONCILIATION_UPLOAD_UPDATED";
    AuditEvent["BANKING_RECONCILIATION_UPLOAD_DELETED"] = "BANKING_RECONCILIATION_UPLOAD_DELETED";
})(AuditEvent || (AuditEvent = {}));
export var AuditEventGroup;
(function (AuditEventGroup) {
    AuditEventGroup["Auth"] = "auth";
    AuditEventGroup["Contract"] = "contract";
    AuditEventGroup["Person"] = "person";
    AuditEventGroup["DebitorInvoice"] = "debitorInvoice";
    AuditEventGroup["CreditorInvoice"] = "creditorInvoice";
    AuditEventGroup["BankAccount"] = "bankAccount";
    AuditEventGroup["BankingReconciliation"] = "bankingReconciliation";
    AuditEventGroup["TwoFactorAuth"] = "twoFactorAuth";
})(AuditEventGroup || (AuditEventGroup = {}));
