import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Div } from "Atoms/Layout";
import { generate } from "Services/Router";
import { SafeAnchor } from "Atoms/SafeAnchor";
import { OrArray } from '@f2w/utils';
export const PersonsField = ({ persons: _persons }) => {
    const persons = OrArray(_persons);
    return (_jsx(Div, { children: persons.map((person, index) => (_jsxs(React.Fragment, { children: [_jsx(SafeAnchor, { href: generate('person.list', { personId: person.id }), target: '_blank', children: person.name }), index + 1 === persons.length ? '' : ', '] }, person.id))) }));
};
