import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalNew } from 'Components/Modal';
import { Button, Div } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { DataTable } from '@f2w/data-table';
import { CalculationServices } from 'Pages/AncillaryExpense';
import { downloadFile } from '@f2w/utils';
import AncillaryExpenseVkaExportDataTable from './table/AncillaryExpenseVkaExportTableModel';
import { useToastDispatcherApi } from 'Components/Dispatcher';
export const VKAExportModal = ({ calculationId, exportAccounts, modalOpen, onClose }) => {
    const { error } = useToastDispatcherApi();
    const table = AncillaryExpenseVkaExportDataTable.use({
        exportAccounts,
        onExport: async (changes) => {
            CalculationServices
                .vkaExport(calculationId, changes)
                .then((blob) => {
                downloadFile(blob, 'DTA-VKA.xml');
                onClose();
            })
                .catch(async (data) => {
                let errorMessage = null;
                const json = await data.response?.data?.text();
                if (json) {
                    try {
                        errorMessage = JSON.parse(json).message;
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                error({
                    timeout: 10,
                    message: errorMessage ?? translate('vka.export.error.general')
                });
            });
        }
    });
    if (!modalOpen) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(ModalNew, { show: modalOpen, onHide: onClose, size: "form", children: [_jsx(ModalNew.Header, { onHide: onClose, closeButton: true, children: _jsx(ModalNew.Title, { children: translate('ancillaryExpense.vkaExport.title') }) }), _jsxs(ModalNew.Body, { children: [_jsxs(Div, { style: { marginBottom: 20 }, children: [_jsx(Div, { children: translate('ancillaryExpense.vkaExport.description') }), _jsx("div", { dangerouslySetInnerHTML: { __html: translate('ancillaryExpense.vkaExport.help')
                                        .replace('{link}', '<a href="https://help.fairwalter.com/">')
                                        .replace('{/link}', '</a>')
                                } })] }), _jsx(DataTable.Table, { instance: table, variant: 'content' })] }), _jsxs(ModalNew.Footer, { children: [_jsx(Button, { link: true, color: 'dark', onClick: onClose, children: translate('btn.cancelEdit') }), _jsx(Button, { onClick: () => table.exportVkaAction(), children: translate('btn.export') })] })] }));
};
