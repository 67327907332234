import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OverlayTrigger, Tooltip } from "Atoms/Overlay";
import classes from "./index.module.scss";
import { Div } from "Atoms/Layout";
import { SafeAnchor } from "Atoms/SafeAnchor";
export const NavigationItem = ({ title, Icon, onClick, className = null, disabled = false, showTooltip = false, isActiveItem = false }) => {
    const navigationItemClassNames = classes.sectionItem
        + (className ? " " + className : "")
        + (isActiveItem ? " " + classes.active : "");
    if (!showTooltip) {
        return (_jsx(Div, { children: _jsxs(SafeAnchor, { disabled: disabled, onClick: (e) => {
                    e.preventDefault();
                    onClick();
                }, className: navigationItemClassNames, children: [_jsx("div", { className: classes.sectionIcon, children: Icon }), _jsx("div", { className: classes.sectionItemText, children: _jsx("span", { children: title }) })] }) }));
    }
    return (_jsx(OverlayTrigger, { placement: 'right', overlay: _jsx(Tooltip, { id: title, children: title }), children: _jsx(Div, { children: _jsxs(SafeAnchor, { disabled: disabled, onClick: (e) => e.preventDefault(), className: navigationItemClassNames, children: [_jsx("div", { className: classes.sectionIcon, children: Icon }), _jsx("div", { className: classes.sectionItemText, children: _jsx("span", { children: title }) })] }) }) }));
};
