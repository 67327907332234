import { AbstractFormType, PasswordRepeatType } from "@f2w/form";
export class ResetPasswordType extends AbstractFormType {
    buildFields() {
        return {
            password: new PasswordRepeatType({
                min: 6
            })
        };
    }
}
