import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import Checkbox from 'Atoms/Checkbox';
import { Icon } from 'Components/Atoms/Icon';
import { Button, OverlayTrigger, Tooltip } from 'Components/Atoms';
import { cssVars } from '@f2w/theme';
export function createActionsColumn({ ...props }) {
    return {
        id: '$actions',
        sticky: 'left',
        type: 'actions',
        isCustom: true,
        accessor: (d) => d,
        CustomHeader: (props) => {
            const { getToggleAllRowsSelectedProps, getToggleAllPageRowsSelectedProps, hasPlugin } = props;
            let selectProps;
            if (props.hasPlugin('useRowSelect')) {
                const p = (hasPlugin('usePagination')
                    ? getToggleAllPageRowsSelectedProps
                    : getToggleAllRowsSelectedProps)();
                selectProps = {
                    ...p,
                    onChange: (e) => {
                        if (props.onAllRowsSelectChange) {
                            props.onAllRowsSelectChange({ ...props }, e.target.checked);
                        }
                        p.onChange(e);
                    }
                };
            }
            return _jsx(_Fragment, { children: selectProps && _jsx(Checkbox, { ...selectProps }) });
        },
        Cell: (props) => {
            let selectProps;
            if (props.hasPlugin('useRowSelect')) {
                const p = props.row.getToggleRowSelectedProps();
                selectProps = {
                    ...p,
                    onChange: (e) => {
                        if (props.onRowSelectChange) {
                            props.onRowSelectChange({ ...props }, e.target.checked);
                        }
                        p.onChange(e);
                    }
                };
            }
            const status = props.row?.$status?.type;
            const statusMessage = status && props.row?.$status?.message;
            const ref = useRef();
            return _jsxs(_Fragment, { children: [selectProps && _jsx(Checkbox, { ...selectProps }), props.RowActions?.(props), statusMessage && _jsx(OverlayTrigger, { placement: 'bottom-start', delay: { hide: 500, show: 0 }, flip: true, rootClose: true, overlay: _jsx(Tooltip, { style: cssVars({
                                '--fw-tooltip\\offset-left': -40
                            }), id: "overlay-example", children: statusMessage }), children: _jsx(Button, { link: true, ref: ref, color: props.row?.$status.type ?? 'primary', icon: 'alert-circle' }) })] });
        },
    };
}
export const RowExpand = {
    Cell: ({ row: { getToggleRowExpandedProps, canExpand, isExpanded }, renderSubRow, }) => {
        return (canExpand || renderSubRow) ? (_jsx("span", { ...getToggleRowExpandedProps(), children: isExpanded ? _jsx(Icon, { icon: "chevron-up" }) : _jsx(Icon, { icon: "chevron-down" }) })) : null;
    },
};
const $rowExpand = (options) => ({
    ...RowExpand,
    ...options,
    sticky: 'right',
    cellProps: { min: true },
    useExpanded: {},
});
export const columnTypes = {
    $rowExpand,
};
