import { UseStyled } from "@f2w/theme";
import { css } from "styled-components";
export const EditDamageFormStyled = UseStyled.styled('div', ({ theme: { palette, typography, rem } }) => (css `
  ${typography.body.fontStyles};

  ${EditDamageFormStyled.Label} {
    display: block;
    font-weight: 400;
    color: ${palette.neutral.$600};
    margin-bottom: ${rem(8)};
    line-height: 150%;

    &.required {
        &::after {
            content: "*",
            color: ${palette.colors.error.$600};
            margin-left: 1rem;
        }
    }
  }

  ${EditDamageFormStyled.FormGroup} {
    margin-bottom: 2rem;
  }
`));
EditDamageFormStyled.Label = UseStyled.styled('label');
EditDamageFormStyled.FormGroup = UseStyled.styled('div');
