import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { getGlobalContext, translate } from 'Services/App';
import { Div, FlexRow } from 'Atoms/Layout';
import { MimeTypeIcon } from 'Atoms/Icon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { CategorySelect, TagPicker } from '../components/Picker';
import { DateType } from '@f2w/form';
import { Select } from 'Atoms/Select';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { DeleteIcon, DownloadIcon } from 'Components/App/Icons';
import { ButtonToolbar } from 'Atoms/ButtonToolbar';
import { Button } from 'Atoms/Button';
import { hasRole } from 'Services/UserManager';
import DropdownModal from 'Components/DropdownModal/DropdownModal';
import useDropdown from 'Hooks/useDropdown';
import { formatBytes } from '@f2w/utils';
import { categoryOptions } from 'Pages/CustomerDocuments/utils';
import { EllipsisText } from 'Atoms/Text';
import { SafeAnchor } from 'Atoms/SafeAnchor';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import moment from 'moment';
const mapContractDropdownToOption = (c) => ({
    value: c.id,
    label: `${DateType.format(c.starts)}
     - ${c.ends ? DateType.format(c.ends) : translate('contract.details.unlimited')}
     - ${c.contractParties.map(cp => cp.name).join(', ')}`
});
const paddingSize = 4;
const BaseTable = Table.factory()(() => ({
    manualControl: true,
    usePagination: {},
    useGlobalFilter: {},
    useDropzone: {
        dropzoneOptions: {
            accept: [
                'text/plain',
                'image/jpeg',
                'image/png',
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-powerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.oasis.opendocument.text',
                'application/vnd.oasis.opendocument.spreadsheet',
                'application/vnd.oasis.opendocument.presentation',
            ]
        },
        onDropAccepted: (files, { addFiles, refresh, setLoading, toggleRowSelected, state, setDropzoneLimits, dispatcher }) => {
            setLoading(true);
            addFiles(files)
                .then(async (responses) => {
                refresh()
                    .then(() => {
                    dispatcher.toast.success({
                        message: translate('customerDocuments.uploadSuccess', { count: responses.length })
                    });
                    let size = state.spaceUsed || 0;
                    responses.forEach((response) => {
                        size += response.data.document?.upload?.size || 0;
                        toggleRowSelected(String(response.data.documentId), true);
                    });
                    setDropzoneLimits(size);
                });
            })
                .catch((e) => {
                setLoading(false);
                dispatcher.toast.error({
                    message: translate('customerDocuments.uploadError')
                });
                throw e;
            });
        },
        onDropRejected: (files, { onFilesRejected }) => onFilesRejected(files),
        onDrop: () => { },
    },
    useRowSelect: {},
    useSortBy: {
        initialState: {
            sortBy: [
                {
                    id: 'createdAt',
                    desc: true
                }
            ]
        }
    },
    Actions: ({ onExportDocuments, columns }) => {
        if (!hasRole('ROLE_ADMINISTRATOR')) {
            return _jsx(_Fragment, {});
        }
        return (_jsx(Button, { onClick: () => onExportDocuments(columns), children: translate('customerDocuments.button.export') }));
    },
    BulkActions: ({ exportSelectedDocuments, onDeleteMultiple, bulkUpdateCategory, openReAssignModal, selectedFlatRows }) => {
        const dropdown = useDropdown();
        return (_jsxs(ButtonToolbar, { children: [_jsx(Button.Delete, { confirm: translate('documentsManager.actions.deleteMultiple.confirm'), variant: 'outline', disabled: selectedFlatRows.length === 0, onClick: () => onDeleteMultiple(selectedFlatRows.map(row => Number(row.id))) }), _jsxs(Div, { children: [_jsx(Button, { ref: dropdown.triggerRef, variant: 'outline', disabled: selectedFlatRows.length === 0, onClick: dropdown.onTrigger, children: translate('documentsManager.documents.assignCategory') }), _jsx(DropdownModal, { dropdown: dropdown, children: _jsx(CategorySelect, { selectedCategory: undefined, categories: categoryOptions, onChange: (option) => {
                                    dropdown.hideDropdown();
                                    bulkUpdateCategory(selectedFlatRows.map(row => Number(row.id)), option.category.value);
                                } }) })] }), _jsx(Button, { disabled: selectedFlatRows.length === 0, onClick: () => openReAssignModal(selectedFlatRows.map(row => Number(row.id))), children: translate('documentsManager.documents.assign') }), _jsx(Button, { disabled: selectedFlatRows.length === 0, onClick: async () => await exportSelectedDocuments(selectedFlatRows.map(row => Number(row.id))), children: translate('customerDocuments.button.exportSelected') })] }));
    },
    RowActions: ({ row: { original }, onDelete, openEditModal }) => (_jsxs(ActionsDropdown, { id: original.id, quickActions: [
            {
                icon: 'edit',
                onClick: () => openEditModal({
                    id: original.id,
                    category: original.category,
                    filePath: original.upload.path,
                    fileName: original.upload.name,
                    fileType: original.upload.type,
                    fileSize: formatBytes(original.upload.size),
                    propertyId: original.property?.id,
                    buildingId: original.building?.id,
                    rentalObjectId: original.rentalObject?.id,
                    contractId: original.contract?.id,
                })
            }
        ], children: [_jsxs(ActionsDropdown.Link, { href: original.upload.path, download: original.upload.name, children: [_jsx(DownloadIcon, {}), " ", _jsx("span", { children: translate('documentsManager.actions.download') })] }), _jsxs(ActionsDropdown.Link, { confirm: translate('documentsManager.actions.delete.confirm'), onClick: () => onDelete(original.id), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('documentsManager.actions.delete') })] })] })),
    FiltersLayout: ({ renderFilter }) => (_jsxs(FlexRow, { gap: 10, w: '100%', children: [renderFilter('building', { flexGrow: 1 }), renderFilter('rentalObject', { flexGrow: 1 }), renderFilter('contract', { flexGrow: 1 }), renderFilter('category', { flexGrow: 1 }), renderFilter('createdAt', { minw: 200 })] })),
    columns: {
        $rowSelect: {},
        $actions: {
            sticky: 'left',
        },
        name: {
            accessor: row => row.upload.name,
            minWidth: 300,
            width: 500,
            Header: translate('documentsManager.documents.name'),
            Cell: ({ value, row: { original }, openEditModal }) => {
                return (_jsxs(EllipsisText, { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(MimeTypeIcon, { size: 21, mimeType: original.upload.type }), " \u00A0", _jsx(SafeAnchor, { onClick: () => openEditModal({
                                id: original.id,
                                category: original.category,
                                filePath: original.upload.path,
                                fileName: original.upload.name,
                                fileType: original.upload.type,
                                fileSize: formatBytes(original.upload.size),
                                propertyId: original.property?.id,
                                buildingId: original.building?.id,
                                rentalObjectId: original.rentalObject?.id,
                                contractId: original.contract?.id,
                            }), children: value })] }));
            }
        },
        category: {
            Header: translate('documentsManager.documents.category'),
            useFilters: {
                Filter: ({ column: { filterValue, setFilter } }) => {
                    return _jsx(Select, { isClearable: true, options: categoryOptions, value: filterValue || '', onChange: (newValue) => setFilter(newValue?.value) });
                }
            },
            Cell: ({ value, row: { original: { id } }, updateCategory }) => {
                const options = useMemo(() => categoryOptions
                    .map((c) => ({
                    value: c.value ?? c.id,
                    label: c.label ?? c.name,
                })), [categoryOptions]);
                return _jsx(TagPicker, { value: value, options: options, isClearable: false, onChange: (o) => updateCategory(id, o?.value), badgeProps: {
                        $color: 'secondary'
                    } });
            }
        },
        property: {
            Header: translate('documentsManager.documents.property'),
            style: { paddingRight: paddingSize },
            Cell: ({ value, row: { original: { id } }, updateProperty }) => {
                const portfolio = usePortfolio();
                const options = portfolio.getProperties().map((property) => ({
                    value: property.id,
                    label: property.name,
                    property,
                }));
                return (_jsx(TagPicker, { value: value?.id, options: options, onChange: (selected) => {
                        updateProperty(id, selected?.value);
                    } }));
            }
        },
        building: {
            Header: translate('documentsManager.documents.building'),
            style: { paddingLeft: paddingSize, paddingRight: paddingSize },
            useFilters: {
                Filter: ({ column: { filterValue, setFilter }, columns }) => {
                    const { scope } = getGlobalContext();
                    const portfolio = usePortfolio();
                    const options = useMemo(() => {
                        switch (scope?.level) {
                            case "portfolio": return portfolio.getBuildings().map(b => ({
                                value: b.id,
                                label: b.name
                            }));
                            case "property": return portfolio.getBuildingsByPropertyId(scope.id).map(b => ({
                                value: b.id,
                                label: b.name
                            }));
                            case "rentalObject": return portfolio.getBuildingsByPropertyId(portfolio.getPropertyByRentalObjectId(scope.id).id).map(b => ({
                                value: b.id,
                                label: b.name
                            }));
                            default:
                                return [];
                        }
                    }, [scope, portfolio]);
                    return _jsx(Select, { value: filterValue != undefined ? filterValue : null, isClearable: true, options: options, onChange: (option) => {
                            setFilter(option?.value);
                            columns
                                .filter(column => column.id === 'rentalObject' || column.id === 'contract')
                                .forEach(column => column.setFilter(undefined));
                        } });
                }
            },
            Cell: ({ value, row: { original }, updateBuilding }) => {
                const portfolio = usePortfolio();
                const { property } = original;
                const options = useMemo(() => {
                    return (property?.id ? portfolio.getBuildingsByPropertyId(property.id) : portfolio.getBuildings())
                        .map((building) => ({
                        value: building.id,
                        label: building.name,
                        building,
                    }));
                }, [property?.id]);
                return (_jsx(TagPicker, { value: value?.id, options: options, onChange: (selected) => {
                        updateBuilding(original.id, selected?.value);
                    } }));
            }
        },
        rentalObject: {
            Header: translate('documentsManager.documents.rentalObject'),
            useFilters: {
                Filter: ({ column: { filterValue, setFilter }, columns }) => {
                    const buildingFilterValue = columns.find(c => c.id === 'building').filterValue;
                    const portfolio = usePortfolio();
                    const { scope } = getGlobalContext();
                    const options = useMemo(() => {
                        if (buildingFilterValue) {
                            return portfolio.getRentalObjectsByBuildingId(buildingFilterValue).map(b => ({
                                value: b.id,
                                label: b.name
                            }));
                        }
                        return [];
                    }, [scope, portfolio, buildingFilterValue]);
                    return _jsx(Select, { value: filterValue != undefined ? filterValue : null, isClearable: true, options: options, onChange: (option) => {
                            setFilter(option?.value);
                            columns
                                .filter(column => column.id === 'contract')
                                .forEach(column => column.setFilter(undefined));
                        } });
                }
            },
            style: { paddingLeft: paddingSize, paddingRight: paddingSize },
            Cell: ({ value, row: { original }, updateRentalObject }) => {
                const portfolio = usePortfolio();
                const { property, building } = original;
                const options = useMemo(() => {
                    let options;
                    if (building?.id)
                        options = portfolio.getRentalObjectsByBuildingId(building.id);
                    else if (property?.id)
                        options = portfolio.getRentalObjectsByPropertyId(property.id);
                    else
                        options = portfolio.getRentalObjects();
                    return options.map((rentalObject) => ({
                        value: rentalObject.id,
                        label: rentalObject.name,
                        rentalObject,
                    }));
                }, [property?.id, building?.id]);
                return (_jsx(TagPicker, { value: value?.id, options: options, onChange: (selected) => {
                        updateRentalObject(original.id, selected?.value);
                    } }));
            }
        },
        contract: {
            Header: translate('documentsManager.documents.contract'),
            useFilters: {
                Filter: ({ column: { filterValue, setFilter }, columns, contracts }) => {
                    const rentalObjectFilterValue = columns.find(c => c.id === 'rentalObject').filterValue;
                    const options = useMemo(() => {
                        if (!rentalObjectFilterValue) {
                            return [];
                        }
                        return contracts
                            .filter(c => c.rentalObjectId === rentalObjectFilterValue)
                            .map(mapContractDropdownToOption);
                    }, [contracts, rentalObjectFilterValue]);
                    return _jsx(Select, { value: filterValue != undefined ? filterValue : null, isClearable: true, options: options, onChange: (option) => setFilter(option?.value) });
                }
            },
            style: { paddingLeft: paddingSize },
            Cell: ({ value, row: { original }, updateContract, contracts, optionsLoading }) => {
                const portfolio = usePortfolio();
                const { property, building, rentalObject } = original;
                const options = useMemo(() => {
                    let options = contracts;
                    if (rentalObject?.id) {
                        options = contracts.filter(c => c.rentalObjectId === rentalObject?.id);
                    }
                    else if (building?.id) {
                        const ids = portfolio.getRentalObjectsByBuildingId(building.id).map(ro => ro.id);
                        options = contracts.filter(c => ids.includes(c.rentalObjectId));
                    }
                    else if (property?.id) {
                        const ids = portfolio.getRentalObjectsByPropertyId(property.id).map(ro => ro.id);
                        options = contracts.filter(c => ids.includes(c.rentalObjectId));
                    }
                    return options
                        .map(mapContractDropdownToOption)
                        .map((c) => ({
                        value: c.value ?? c.id,
                        label: c.label ?? `
                                ${moment(c.starts).format('DD.MM.YYYY')}
                                - ${c.ends ? moment(c.ends).format('DD.MM.YYYY') : translate('contract.details.unlimited')}
                                - ${c.tenants?.join(', ')}
                            `,
                        contract: c,
                    }));
                }, [contracts, rentalObject?.id, building?.id, property?.id]);
                return (_jsx(TagPicker, { value: value?.id ?? value?.value, options: options, onChange: (selected) => {
                        updateContract(original.id, selected?.value);
                    } }));
            }
        },
        createdAt: {
            Header: translate('documentsManager.documents.dateAdded'),
            accessor: row => DateType.format(row.createdAt),
            useSortBy: {},
            useFilters: {
                Filter: ({ column: { filterValue, setFilter } }) => {
                    return (_jsx(Table.filters.DateRangeFilter, { filterValue: filterValue, onChange: setFilter }));
                }
            }
        }
    }
}));
export const CustomerDocumentsDataTable = Object.assign(BaseTable, {
    use({ customerDocumentServices, spaceUsed, spaceAllowed, openReAssignModal, openEditModal, openExportModal, getContractsForDropdown }) {
        const { scope } = getGlobalContext();
        const portfolio = usePortfolio();
        const updateCategory = useCallback((id, category) => {
            table.setLoading(true);
            return customerDocumentServices.updateCustomerDocumentCategory(id, category)
                .then(table.refresh)
                .catch((e) => {
                table.setLoading(false);
                console.error(e);
            });
        }, []);
        const bulkUpdateCategory = useCallback((ids, category) => {
            table.setLoading(true);
            return customerDocumentServices.bulkUpdateCustomerDocumentCategory(ids, category)
                .then(table.refresh)
                .catch((e) => {
                table.setLoading(false);
                console.error(e);
            });
        }, []);
        const updateProperty = useCallback((id, propertyId) => {
            const prom = propertyId
                ? customerDocumentServices.updateCustomerDocumentProperty(id, propertyId)
                : customerDocumentServices.clearCustomerDocumentProperty(id);
            table.setLoading(true);
            return prom
                .then(table.refresh)
                .catch((e) => {
                table.setLoading(false);
                console.error(e);
            });
        }, []);
        const updateBuilding = useCallback((id, buildingId) => {
            const prom = buildingId
                ? customerDocumentServices.updateCustomerDocumentBuilding(id, buildingId)
                : customerDocumentServices.clearCustomerDocumentBuilding(id);
            table.setLoading(true);
            return prom
                .then(table.refresh)
                .catch((e) => {
                table.setLoading(false);
                console.error(e);
            });
        }, []);
        const updateRentalObject = useCallback((id, rentalObjectId) => {
            const prom = rentalObjectId
                ? customerDocumentServices.updateCustomerDocumentRentalObject(id, rentalObjectId)
                : customerDocumentServices.clearCustomerDocumentRentalObject(id);
            table.setLoading(true);
            return prom
                .then(table.refresh)
                .catch((e) => {
                table.setLoading(false);
                console.error(e);
            });
        }, []);
        const updateContract = useCallback((id, contractId) => {
            const prom = contractId
                ? customerDocumentServices.updateCustomerDocumentContract(id, contractId)
                : customerDocumentServices.clearCustomerDocumentContract(id);
            table.setLoading(true);
            return prom
                .then(table.refresh)
                .catch((e) => {
                table.setLoading(false);
                console.error(e);
            });
        }, []);
        const { success } = useToastDispatcherApi();
        const exportSelectedDocuments = useCallback(async (ids) => {
            await customerDocumentServices.exportSelectedCustomerDocuments(ids).then(() => {
                success({
                    message: translate('customerDocuments.exportSuccess.body')
                });
            });
        }, []);
        const addFiles = useCallback(async (files) => {
            const params = {};
            switch (scope?.level) {
                case "property": {
                    params.propertyId = scope?.id;
                    break;
                }
                case "rentalObject": {
                    params.rentalObjectId = scope?.id;
                    break;
                }
            }
            return Promise.all(files.map(file => customerDocumentServices.uploadCustomerDocument(file, params)));
        }, []);
        const { error } = useToastDispatcherApi();
        const onFilesRejected = useCallback((files) => {
            error({
                message: `${translate('uploadManager.error.fileType')}: ${files.map(file => file.file.name).join(', ')}`,
            });
        }, []);
        const onDelete = useCallback((id) => {
            table.setLoading(true);
            return customerDocumentServices.deleteCustomerDocument(id)
                .then(table.refresh)
                .catch((e) => {
                table.setLoading(false);
                console.error(e);
            });
        }, []);
        const onDeleteMultiple = useCallback((ids) => {
            table.setLoading(true);
            return customerDocumentServices.deleteMultipleCustomerDocuments(ids)
                .then(table.refresh)
                .catch((e) => {
                table.setLoading(false);
                console.error(e);
            });
        }, []);
        const onExportDocuments = useCallback(async (columns) => {
            const buildingId = columns.find(c => c.id === 'building').filterValue;
            let propertyId = buildingId ? portfolio.getBuildingById(buildingId).propertyId : null;
            let mandateId = propertyId ? portfolio.getPropertyById(propertyId).mandate.id : null;
            const createdAtFilterValue = columns.find(c => c.id === 'createdAt').filterValue;
            if (!propertyId && scope.level === 'property') {
                propertyId = scope.id;
                mandateId = portfolio.getPropertyById(propertyId).mandate.id;
            }
            const data = {
                mandateId,
                propertyId,
                buildingId,
                rentalObjectId: columns.find(c => c.id === 'rentalObject').filterValue,
                contractId: columns.find(c => c.id === 'contract').filterValue,
                category: columns.find(c => c.id === 'category').filterValue,
                createdAtRange: createdAtFilterValue ? {
                    start: createdAtFilterValue.from,
                    end: createdAtFilterValue.to
                } : undefined
            };
            openExportModal(data);
        }, []);
        const initialFilters = useMemo(() => {
            switch (scope?.level) {
                case "portfolio":
                case "property": return [];
                case "rentalObject": return [
                    {
                        id: 'building',
                        value: portfolio.getBuildingByRentalObjectId(scope.id).id,
                    },
                    {
                        id: 'rentalObject',
                        value: scope.id,
                    }
                ];
                default:
                    return [];
            }
        }, []);
        const [optionsLoading, setOptionsLoading] = useState(true);
        const [contracts, setContracts] = useState([]);
        useEffect(() => {
            getContractsForDropdown()
                .then(data => {
                setContracts(data);
                setOptionsLoading(false);
            });
        }, []);
        const table = CustomerDocumentsDataTable.useTable({
            addFiles,
            onFilesRejected,
            updateCategory,
            bulkUpdateCategory,
            updateProperty,
            updateBuilding,
            updateRentalObject,
            updateContract,
            onDelete,
            onDeleteMultiple,
            onExportDocuments,
            exportSelectedDocuments,
            openReAssignModal,
            openEditModal,
            contracts,
            optionsLoading
        }, {
            initialState: {
                filters: initialFilters,
                spaceUsed,
                spaceAllowed
            },
            getData: ({ request }) => {
                return customerDocumentServices.listCustomerDocuments(request);
            }
        });
        return table;
    }
});
