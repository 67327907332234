export var DocumentArea;
(function (DocumentArea) {
    DocumentArea["ContractCommercial"] = "contract.commercial";
    DocumentArea["ContractResidential"] = "contract.residential";
    DocumentArea["ContractParking"] = "contract.parking";
    DocumentArea["ContractModification"] = "contract.modification";
    DocumentArea["DebitorInvoice"] = "debitor_invoice";
    DocumentArea["Termination"] = "termination";
    DocumentArea["AncillaryExpense"] = "ancillary_expense";
})(DocumentArea || (DocumentArea = {}));
