const createToken = (props) => ({
    ...props,
});
export const typographyTokens = {
    h1: createToken({
        fontSize: 46,
        lineHeight: '60px',
        fontWeight: '$700',
        letterSpacing: -2,
    }),
    h2: createToken({
        fontSize: 40,
        lineHeight: '52px',
        fontWeight: '$700',
        letterSpacing: 0,
    }),
    h3: createToken({
        fontSize: 36,
        lineHeight: '50px',
        fontWeight: '$600',
        letterSpacing: 1,
    }),
    h4: createToken({
        fontSize: 32,
        lineHeight: '45px',
        fontWeight: '$600',
        letterSpacing: 1,
    }),
    h5: createToken({
        fontSize: 28,
        lineHeight: '41px',
        fontWeight: '$500',
        letterSpacing: 2,
    }),
    h6: createToken({
        fontSize: 24,
        lineHeight: '35px',
        fontWeight: '$500',
        letterSpacing: 2,
    }),
    pretitle: createToken({
        fontSize: 10,
        lineHeight: '15px',
        fontWeight: '$500',
        letterSpacing: 3,
        textTransform: 'uppercase',
    }),
    large: createToken({
        fontSize: 20,
        lineHeight: '30px',
        fontWeight: '$400',
        letterSpacing: 2,
    }),
    body: createToken({
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: '$400',
        letterSpacing: 2,
    }),
    medium: createToken({
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: '$400',
        letterSpacing: 0,
    }),
    small: createToken({
        fontSize: 12,
        lineHeight: '18px',
        fontWeight: '$400',
        letterSpacing: 0,
    }),
};
