import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FlexCol, FlexRow, Title } from "Components/Atoms";
import { useEffect, useState } from "react";
import { HandoverProtocolsTable } from "Pages/Handover/components/HandoverProtocolList/HandoverProtocolsTable";
import { DataTable } from "@f2w/data-table";
import HandoverService from "Services/HandoverService";
import { HandoverView } from "Pages/Handover/components/HandoverView/HandoverView";
import { Header, Section } from 'Atoms/Layout/components';
import { getHandoverIntegration } from "Pages/Handover/services/HandoverIntegrationService";
import { hasRole } from "Services/UserManager";
import generateRoute from "Services/Router";
import { translate } from "Services/Translator";
import { useMounted } from '@restart/hooks';
const HandoverProtocolListBlock = ({ rentalObjectId, currentHandoverId }) => {
    const [handoverId, setHandoverId] = useState(currentHandoverId);
    const [integration, setIntegration] = useState();
    const [hasProtocols, setHasProtocols] = useState(true);
    const isMounted = useMounted();
    useEffect(() => {
        getHandoverIntegration().then(integration => {
            isMounted() && setIntegration(integration);
        });
    }, []);
    const handoverProtocolsTable = HandoverProtocolsTable.use({
        handoverServices: HandoverService,
        onViewHandoverClicked: setHandoverId,
        onEmptyResult: setHasProtocols,
        rentalObjectId,
    });
    const isAdmin = hasRole('ROLE_ADMINISTRATOR');
    const displayActivationButton = isAdmin && integration && !integration.active;
    const displayRegistrationButton = integration
        && integration.active
        && integration.currentUser
        && !integration.currentUser.isRegistered;
    return (_jsxs(_Fragment, { children: [_jsx(Header.Sticky, { children: _jsxs(FlexRow, { gap: 16, style: { width: '100%' }, justifySpaceBetween: true, children: [_jsx(FlexCol, { alignItems: 'center', children: _jsx(Title, { style: { display: 'block', margin: 'auto 0' }, h6: true, as: 'h3', children: translate('handovers.listView.title') }) }), _jsxs(FlexCol, { alignItems: 'center', children: [_jsx("a", { href: 'https://help.fairwalter.com/abnahme-erstellen', style: { fontSize: 12, display: 'block', margin: 'auto 0' }, target: "_blank", children: translate('handovers.listView.helpLink') }), displayActivationButton
                                    && _jsx(Button, { href: generateRoute('handover.integration.overview'), children: translate('handovers.listView.activateIntegration') }), displayRegistrationButton
                                    && _jsx(Button, { href: integration.currentUser.registrationLink, target: '_blank', children: translate('handovers.listView.register') })] })] }) }), _jsxs(Section, { children: [hasProtocols && _jsx(DataTable.SimpleTable, { instance: handoverProtocolsTable, variant: 'default', options: {
                            hover: false,
                            border: false,
                        } }), !hasProtocols &&
                        _jsx(FlexRow, { justifyCenter: true, children: translate('handovers.listView.noProtocolsAvailable') }), handoverId && _jsx(HandoverView, { handoverId: handoverId, onClose: () => setHandoverId(undefined), getHandoverDetails: HandoverService.getHandoverDetails })] }), _jsx(Section.Divider, {})] }));
};
export default HandoverProtocolListBlock;
