import { jsx as _jsx } from "react/jsx-runtime";
import { Table } from "@f2w/data-table/table-types";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
import { translate } from "Services/Translator";
import { DateType, MoneyType } from "@f2w/form";
import React from "react";
import { useModalDispatcherConfirm } from "Components/Dispatcher";
import { BankFileCreditorInvoiceState } from "Pages/Creditor/components/CreditorInvoiceState";
const BaseTable = Table.factory()(() => ({
    RowActions: ({ row: { original: { id } }, deleteCreditorFromBankFile }) => (_jsx(ActionsDropdown, { id: id, scrollable: true, quickActions: [
            {
                icon: 'trash',
                color: 'error',
                onClick: () => deleteCreditorFromBankFile(id)
            },
        ] })),
    columns: {
        reference: {
            Header: translate('creditor.reference')
        },
        label: {
            Header: translate('creditor.label')
        },
        recipient: {
            Header: translate('creditor.payment.recipient'),
            Cell: ({ value }) => value ?? '-'
        },
        amount: {
            Header: translate('creditor.amount'),
            Cell: ({ value }) => MoneyType.useFormat(value)
        },
        dueDate: {
            Header: translate('creditor.dueDate'),
            Cell: ({ value }) => DateType.useFormat(value)
        },
        state: {
            Header: translate('creditor.state'),
            Cell: ({ value }) => _jsx(BankFileCreditorInvoiceState, { state: value })
        }
    }
}));
export const CreditorInfoDataTable = Object.assign(BaseTable, {
    use: ({ onLastCreditorDeleted, bankFileId, creditorInvoices: _creditorInvoices, dropCreditorFromBankFile }) => {
        const [creditorInvoices, setCreditorInvoices] = React.useState(_creditorInvoices);
        const deleteCreditorFromBankFile = React.useCallback((id) => {
            dropCreditorFromBankFile(bankFileId, id)
                .then(() => {
                setCreditorInvoices(prev => {
                    const filteredCreditors = prev.filter(creditor => creditor.id !== id);
                    if (filteredCreditors.length === 0)
                        onLastCreditorDeleted();
                    return filteredCreditors;
                });
            });
        }, []);
        const deleteCreditorFromBankFileConfirm = useModalDispatcherConfirm({
            icon: 'alert-triangle',
            message: translate('creditor.bankFile.attention'),
            help: translate('creditor.bankFile.deleteCreditor.warning'),
            btnConfirm: translate('creditor.bankFile.deleteCreditor.continue'),
            onConfirm: deleteCreditorFromBankFile
        });
        return CreditorInfoDataTable.useCreateTable({
            data: creditorInvoices,
            deleteCreditorFromBankFile: deleteCreditorFromBankFileConfirm,
        });
    }
});
