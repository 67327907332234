import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import Dashboard from "Pages/Dashboard/Dashboard";
import { getDashboardData } from "Services/DashboardManager";
const DashboardPage = (props) => {
    const [dashboardData, setDashboardData] = useState(props);
    const [datePeriod, setDatePeriod] = useState('quarter');
    const [isLoading, setLoading] = useState(false);
    const refreshData = async (datePeriod) => {
        setLoading(true);
        const data = await getDashboardData(datePeriod);
        setDashboardData(data);
        setLoading(false);
    };
    const onDatePeriodChange = (datePeriod) => {
        setDatePeriod(datePeriod);
        refreshData(datePeriod);
    };
    return (_jsx(Dashboard, { ...props, onDatePeriodChange: onDatePeriodChange, datePeriod: datePeriod, isLoading: isLoading, ...dashboardData }));
};
export default DashboardPage;
