import { jsx as _jsx } from "react/jsx-runtime";
import { completeUserOnboarding } from "Services/UserServices";
import { Button } from "Components/Atoms";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { useState } from "react";
import generateRoute from "Services/Router";
export const CompleteOnboardingButton = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { error: errorToast, success: successToast } = useToastDispatcherApi();
    return (_jsx(Button, { loading: isLoading, onClick: async () => {
            setIsLoading(true);
            await completeUserOnboarding();
            successToast({
                timeout: 5,
                message: 'Einleitung ausgeblendet',
                showRemove: true
            });
            setIsLoading(false);
            window.location.href = generateRoute('dashboard');
        }, children: "Einf\u00FChrung ausblenden" }));
};
