import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled, { css } from "styled-components";
import { useTheme } from "@fw/theme";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
import { Dropdown } from "Atoms/Dropdown";
const Wrapper = styled.div(() => {
    return css `
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        gap: 112px;
        align-items: flex-start;
    `;
});
const TextBlock = styled.div(() => {
    return css `
        display: flex;
        flex-direction: column;
        gap: 16px;
    `;
});
const ButtonBlock = styled.div(() => {
    return css `
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-content: flex-start;
        gap: 16px;
    `;
});
const Heading = styled.h1(() => {
    const { typography } = useTheme();
    return css `
        ${typography.h6.fontStyles};
    `;
});
const SubTitle = styled.div(() => {
    const { typography } = useTheme();
    return css `

    `;
});
const EnabledStateSection = ({ onClick }) => (_jsx(_Fragment, { children: _jsxs(Wrapper, { children: [_jsxs(TextBlock, { children: [_jsx(Heading, { children: translate('2fa.status.deactivate.title') }), _jsx(SubTitle, { dangerouslySetInnerHTML: { __html: translate('2fa.status.deactivate.text') } })] }), _jsx(ButtonBlock, { children: _jsx(Button, { "$size": "lg", color: "error", onClick: onClick, children: translate('2fa.status.deactivate.action') }) })] }) }));
const DownloadAppDropdown = () => {
    const downloadAppLinks = [
        {
            title: 'Android',
            link: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1',
        },
        {
            title: 'iOS',
            link: 'https://apps.apple.com/ch/app/google-authenticator/id388497605',
        },
    ];
    return (_jsxs(Dropdown, { children: [_jsx(Dropdown.Toggle, { "$size": 'lg', variant: 'outline', children: "Download Google Authenticator" }), _jsx(Dropdown.Menu, { children: downloadAppLinks.map((row) => {
                    return (_jsx(Dropdown.Item, { href: row.link, target: '_blank', children: row.title }, row.title));
                }) })] }));
};
const DisabledStateSection = ({ onClick }) => {
    return (_jsxs(Wrapper, { children: [_jsxs(TextBlock, { children: [_jsx(Heading, { children: translate('2fa.status.activate.title') }), _jsx(SubTitle, { dangerouslySetInnerHTML: { __html: translate('2fa.status.activate.text') } })] }), _jsxs(ButtonBlock, { children: [_jsx(DownloadAppDropdown, {}), _jsx(Button, { "$size": "lg", color: "primary", onClick: onClick, children: translate('2fa.status.activate.action') })] })] }));
};
export const TwoFactorAuthStatusView = ({ has2FA, activateClicked, deactivateClicked }) => {
    return (_jsx(_Fragment, { children: has2FA
            ? _jsx(EnabledStateSection, { onClick: deactivateClicked })
            : _jsx(DisabledStateSection, { onClick: activateClicked }) }));
};
