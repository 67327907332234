import { isFunction } from 'Utils/types';
import { TableRow } from 'Atoms/Table';
import { columnsToArray, forColumns } from './utils';
import { useDataTable, useDataTableUncontrolled } from '../core/tableFactory';
import mergeOptions, { mergeColumnPluginOptions, mergePluginOptions } from './mergeOptions';
import { createActionsColumn } from './columnTypes';
import { forPlugins } from '../plugins';
function InstanceBuilder(opts) {
    const tableProps = resolveOptions(opts);
    const builder = Object.assign(tableProps, {
        useTable: (initialOpts) => useDataTable({ ...tableProps.options, ...initialOpts }, tableProps.hooks),
        create(opts) {
            return useDataTableUncontrolled({
                ...tableProps.options,
                ...opts,
            }, tableProps.hooks);
        },
        createRemote: (o) => builder.useTable(o),
    });
    return builder;
}
function resolveOptions(table) {
    const loadedPlugins = {};
    let hooks = [];
    const { columns: { $actions, $rowSelect, ...initialColumns }, ..._table } = { ...table };
    if ($rowSelect && !_table.useRowSelect) {
        _table.useRowSelect = { enabled: true };
    }
    const columns = {
        $actions: createActionsColumn({
            ...$rowSelect,
            ...$actions,
        }),
        ...initialColumns,
    };
    forColumns(columns, (id, column) => {
        const _column = { ...column };
        if (_column.size) {
            if (typeof _column.size === 'number') {
            }
        }
        forPlugins((pluginName, plugin) => {
            if (column[pluginName]?.enabled) {
                mergeColumnPluginOptions(plugin, _column, _column[pluginName], plugin.createColumnOptions ? plugin.createColumnOptions(_column[pluginName], column, table[pluginName]?.defaultColumn) : {});
            }
            _column[pluginName] = !!column[pluginName]?.enabled;
        });
        return _column;
    });
    const pluginOptions = {};
    const cacheStateSet = new Set();
    forPlugins((pluginName, plugin) => {
        if (_table[pluginName]?.enabled) {
            mergePluginOptions(plugin, pluginOptions, _table[pluginName], plugin.createOptions ? plugin.createOptions(_table[pluginName], table) : {});
            if (!_table.disableCache && plugin.stateCache) {
                if (Array.isArray(plugin.stateCache)) {
                    plugin.stateCache.forEach(v => {
                        cacheStateSet.add(v);
                    });
                }
            }
            loadedPlugins[pluginName] = pluginName;
            if (plugin.hook) {
                plugin.hook.pluginName = pluginName;
                hooks.push(plugin.hook);
            }
        }
        _table[pluginName] = !!table[pluginName]?.enabled;
    });
    const options = mergeOptions(pluginOptions, _table);
    options.columns = columnsToArray(columns);
    options.stateCachedKeys = [...cacheStateSet.values()];
    if (options.preHook) {
        hooks.unshift(options.preHook);
        options.preHook = null;
        delete options.preHook;
    }
    if (options.hooks) {
        hooks.push(options.hooks);
        options.hooks = null;
        delete options.hooks;
    }
    if (options.postHook) {
        hooks.push(options.postHook);
        options.postHook = null;
        delete options.postHook;
    }
    if (isFunction(options.Footer)) {
        options.Footer['displayName'] = TableRow.displayName;
    }
    options.hasPlugin = (name) => !!loadedPlugins[name];
    return {
        options,
        hooks,
        loadedPlugins,
    };
}
export default InstanceBuilder;
