export var MortgageType;
(function (MortgageType) {
    MortgageType["FIXED"] = "fixed";
    MortgageType["FLOATING"] = "floating";
    MortgageType["SARON"] = "saron";
})(MortgageType || (MortgageType = {}));
export var MortgageStatus;
(function (MortgageStatus) {
    MortgageStatus["INACTIVE"] = "inactive";
    MortgageStatus["ACTIVE"] = "active";
    MortgageStatus["UPCOMING"] = "upcoming";
    MortgageStatus["EXPIRED"] = "expired";
})(MortgageStatus || (MortgageStatus = {}));
export var MortgageStatsGroup;
(function (MortgageStatsGroup) {
    MortgageStatsGroup["Annually"] = "annually";
    MortgageStatsGroup["SemiAnnually"] = "semiannually";
})(MortgageStatsGroup || (MortgageStatsGroup = {}));
