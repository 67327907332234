import generateRoute from "Services/Router";
import client from "Utils/client";
export const getMandateStats = async (mandateId) => {
    const route = generateRoute('stats_mandate', { mandate: mandateId });
    const { data } = await client.get(route);
    return data;
};
export const getPropertyStats = async (propertyId) => {
    const route = generateRoute('stats_property', { property: propertyId });
    const { data } = await client.get(route);
    return data;
};
export const getBuildingStats = async (buildingId) => {
    const route = generateRoute('stats_building', { building: buildingId });
    const { data } = await client.get(route);
    return data;
};
export const getRentalObjectStats = async (rentalObjectId) => {
    const route = generateRoute('stats_rentalObject', { rentalObject: rentalObjectId });
    const { data } = await client.get(route);
    return data;
};
