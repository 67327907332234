import * as rt from 'react-table';
export const useSortBy = {
    initOptions: (options, table) => ({
        disableSortBy: !options.enabled,
        defaultCanSort: false,
        disableMultiSort: true,
        autoResetSortBy: false,
        disableSortRemove: true,
        maxMultiSortColCount: 3,
        initialState: {},
        defaultColumn: {
            disableSortBy: true,
            defaultCanSort: false,
            hideSort: false,
        },
    }),
    initColumnOptions: (options, column) => {
        return options;
    },
    createOptions: (opts, { manualControl }) => ({
        manualSortBy: !!manualControl,
    }),
    createColumnOptions: ({ enabled, ...options }, column) => {
        return {
            disableSortBy: column.isCustom || !enabled,
            ...options,
        };
    },
    hook: hooks => {
        rt.useSortBy(hooks);
    },
};
