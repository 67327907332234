import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { inObject } from 'Utils';
import { renderContent } from 'Components/Base';
import { FlexRow } from 'Atoms/Layout';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
import { Icon } from 'Atoms/Icon';
import classNames from 'classnames';
export var BaseTheme;
(function (BaseTheme) {
    function Label({ field, children, ...props }) {
        return _jsxs(BaseTheme.LabelRoot, { id: `${field.id}--label`, htmlFor: field.widgetId, "data-required": field.isRequired, ...props, className: classNames(`fw-form__label--${field.typeName}`, {
                'sr-only': !field.showLabel,
            }, props?.className), children: [_jsx("span", { children: field.label }), children] });
    }
    BaseTheme.Label = Label;
    const LabelContent = ({ help, field, children }) => {
        const { message, ...props } = (inObject(help, 'message') ? help : help ? { message: help } : {});
        const messageContent = message && renderContent(message, { field }) || message || null;
        if (!messageContent) {
            return children;
        }
        return (_jsxs(FlexRow, { gap: 10, children: [children, _jsx(OverlayTrigger, { placement: 'top', flip: true, ...props, overlay: (_jsx(Tooltip, { id: `${field.id}--help`, children: messageContent })), children: _jsx(Icon, { "data-form-help": true, size: 20, icon: 'help-circle' }) })] }));
    };
    BaseTheme.LabelRoot = styled.label.attrs(() => ({
        className: 'fw-form__label',
    })) `
        width: 100%;
        margin: 0 0 8px;

        ${p => p.theme.typography.small.fontStyles};
        color: ${p => p.theme.palette.neutral.$600};

        &[data-required=true] > span:first-child {
            display: inline-block;
            vertical-align: bottom;
            &:after {
                content: '*';
                font-size: 16px;
                line-height: 1em;
                //line-height: 18px;
                color: ${p => p.theme.palette.error.$600};
                margin-left: 3px;
                vertical-align: bottom;
            }
        }
    `;
    function Error({ field, ...props }) {
        if (!field.meta.hasError || field._isCompound())
            return null;
        return _jsx(BaseTheme.ErrorRoot, { id: `${field.id}--error`, ...props, children: Array.isArray(field.error) ? field.error.map((error, key) => (_jsx("p", { children: error }, `error-${key}`))) : field.error });
    }
    BaseTheme.Error = Error;
    BaseTheme.ErrorRoot = styled.div.attrs(() => ({
        className: 'fw-form__error',
    })) `
      color: var(--state-color, ${p => p.theme.palette.error.$500});
      margin: 6px 0 0;
    `;
    BaseTheme.Row = styled.div.attrs(() => ({
        className: 'fw-form__row',
    })) `
      display: block;
      position: relative;
      margin-bottom: ${p => p.compound ? '0' : 'var(--form-row-spacing, 1.5rem)'};
    `;
})(BaseTheme || (BaseTheme = {}));
