import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from 'Atoms/Layout/components';
import { FlexCol, FlexRow } from 'Atoms/Layout';
import { translate } from 'Services/Translator';
import { formatAmount, formatPercentage } from 'Services/NumberHelper';
import moment from 'moment/moment';
import { BillingCycle } from 'Components/ObjectViewModal';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
import { Icon } from 'Atoms/Icon';
import { Card } from 'Atoms/Card';
import { MoneyType } from '@f2w/form';
const getBillingCycleText = (billingCycle) => {
    switch (billingCycle) {
        case BillingCycle.MONTHLY:
            return translate('monthly');
        case BillingCycle.QUARTERLY:
            return translate('quarterly');
        case BillingCycle.HALF_ANNUALLY:
            return translate('half-yearly');
        case BillingCycle.ANNUALLY:
            return translate('yearly');
    }
};
export const GeneralTab = ({ group }) => {
    const { data } = group ?? {};
    const rent = data;
    return (_jsx(Section, { children: _jsx(FlexCol, { gap: 16, children: _jsxs(FlexRow, { gap: 16, children: [_jsxs(FlexCol, { gap: 16, flex: '50%', children: [_jsxs(Card, { children: [rent.hasVat && _jsx(Card.Value, { title: translate('rent.details.grossAmountWithVat'), children: MoneyType.useFormat(rent.grossAmountWithVat) }), _jsx(Card.Value, { title: translate('contract.details.rent.grossAmount'), children: MoneyType.useFormat(rent.grossAmount) || null }), _jsx(Card.Value, { title: translate('contract.details.rent.netAmount'), children: MoneyType.useFormat(rent.netAmount) || null })] }), _jsx(Card, { children: _jsx(Card.Value, { title: translate('contract.details.rent.paymentMode'), children: getBillingCycleText(rent.billingCycle) }) }), _jsx(Card, { children: _jsx(Card.Value, { title: _jsxs(FlexRow, { gap: 8, alignCenter: true, children: [_jsx("span", { children: translate('contract.details.rent.vatPayable') }), _jsx(OverlayTrigger, { overlay: _jsx(Tooltip, { id: 'vat', children: translate('rent.details.vatInfo') }), children: _jsx(Icon, { size: 17, icon: 'info' }) })] }), children: rent.hasVat ? translate('yes') : translate('no') }) }), _jsx(Card, { children: _jsx(Card.Value, { title: translate('rent.reservation'), children: rent.reservation }) })] }), _jsx(FlexCol, { gap: 16, flex: '50%', children: _jsxs(Card, { children: [_jsx(Card.Value, { title: translate('contract.details.rent.indexBase'), children: rent.indexBase && `${rent.indexBase.month}/${rent.indexBase.year}` }), _jsx(Card.Value, { title: translate('contract.details.rent.inflationIndex'), children: rent.inflationIndex
                                        ? `${rent.inflationIndex.month}/${rent.inflationIndex.year}: ${formatAmount(rent.inflationIndex.amount)}`
                                        : translate('notAvailable') }), _jsx(Card.Value, { title: translate('contract.details.rent.referenceInterestRate'), children: rent.referenceInterestRate
                                        ? `${rent.referenceInterestRate.month}/${rent.referenceInterestRate.year}: ${formatPercentage(rent.referenceInterestRate.rate, "0.00%")}`
                                        : translate('notAvailable') }), _jsx(Card.Value, { title: translate('contract.details.rent.dateOfLastCostSettlement'), children: rent.lastCostSettlement
                                        ? moment(rent.lastCostSettlement, 'MMYYYY').format('M/Y')
                                        : translate('notAvailable') }), _jsx(Card.Value, { title: translate('contract.details.rent.flatRate'), children: rent.flatRate
                                        ? formatPercentage(rent.flatRate, "0.00%")
                                        : translate('notAvailable') })] }) })] }) }) }));
};
