import { jsx as _jsx } from "react/jsx-runtime";
import truncate from "Utils/truncate";
const TruncatedText = ({ text, maxWidth = 100, maxLines = undefined }) => {
    return (_jsx("span", { title: text, style: maxLines ? {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: maxLines,
            lineClamp: maxLines,
            WebkitBoxOrient: "vertical",
        } : {}, children: truncate(text, maxWidth) }));
};
export default TruncatedText;
