import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Div } from 'Atoms/Layout';
import { isNum, isString } from 'Utils/types';
import styled from 'styled-components';
import { isArray, isObject, isPlainObject } from 'lodash';
export const useJsonFormat = (data, indent, undefinedAsNull) => {
    if (isString(data))
        return data;
    return JSON.stringify(data, (key, value) => {
        if (undefinedAsNull && value === undefined)
            return '{undefined}';
        if (isObject(value) && !isArray(value)) {
            if (!isPlainObject(value))
                return `{${value?.toString() ?? 'unknown'}}`;
        }
        return value;
    }, isNum(indent) ? indent : indent && 2);
};
export const JsonCode = ({ title, value, color = true, indent, undefinedAsNull, ...props }) => {
    let json = useJsonFormat(value, indent, undefinedAsNull);
    if (color && json)
        json = syntaxHighlight(json);
    return (_jsxs(Div, { ...props, children: [isString(title) ? _jsx("h5", { children: title }) : title, _jsx(PreRoot, { style: {}, dangerouslySetInnerHTML: { __html: json } })] }));
};
const PreRoot = styled.pre `
  //outline: 1px solid #ccc;
  padding: 5px;
  //margin: 5px;
  margin: 0;
  font-size: 11px;
  line-height: 1.5;

  .string {
    color: #136c13;
  }

  .number {
    //color: #fd7e14;
    color: #1717e6;
  }

  .boolean {
    color: #1717e6;
  }

  .null {
    color: magenta;
  }

  .key {
    color: #cc0030;
    //color: #9876aa;
  }
`;
function syntaxHighlight(json) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            }
            else {
                cls = 'string';
            }
        }
        else if (/true|false/.test(match)) {
            cls = 'boolean';
        }
        else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}
