import { jsx as _jsx } from "react/jsx-runtime";
import { Input } from "Atoms/Input";
import { getIn } from 'formik';
const FormInput = ({ field, form: { touched, errors }, input, ...props }) => {
    const isFieldTouched = getIn(touched, field.name);
    const isFieldErrored = !!getIn(errors, field.name);
    const variant = isFieldTouched
        ? (isFieldErrored ? 'error' : 'default')
        : 'default';
    const InputComponent = (input ? input : Input);
    return (_jsx(InputComponent, { "$variant": variant, ...field, ...props, onChange: (value, event) => {
            field?.onChange?.(event);
        } }));
};
export default FormInput;
