import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { NotificationEventApi } from "Components/Notifications/NotificationEventApi";
import { dispatchCustomEvent } from "Services/EventManager";
const NotificationEvents = () => {
    useEffect(() => {
        const fetchData = async () => {
            try {
                const subscription = await NotificationEventApi.getSubscription();
                const eventStreamUrl = new URL(subscription.url);
                subscription.topics.map((topic) => eventStreamUrl.searchParams.append('topic', topic));
                const eventSource = new EventSource(eventStreamUrl, {
                    withCredentials: true
                });
                eventSource.onmessage = (event) => {
                    const eventData = JSON.parse(event.data);
                    dispatchCustomEvent('fw.' + eventData.name, eventData.payload);
                };
            }
            catch (error) {
                console.error('NotificationEvents: %o', error);
            }
        };
        fetchData();
    }, []);
    return _jsx(_Fragment, {});
};
export default NotificationEvents;
